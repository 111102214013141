define("sky-router-3/pods/components/portal/manage/device-parameters/types/d410/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F97FyGOs",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"enabledSetting\",\"minuteSetting\",\"disabled\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[1,[30,[36,8],null,[[\"enabledSetting\",\"conserveAllSetting\",\"conserveReportsRadiusSetting\",\"conserveReportsCountSetting\",\"disabled\"],[[35,7],[35,6],[35,5],[35,4],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"timeReportingMinutes\",\"timeReportingEnabled\",\"portal/manage/device-parameters/controls/nal-time-based-reporting\",\"conserveReportsCount\",\"conserveReportsRadius\",\"conserveAllReports\",\"conserveReportsEnabled\",\"portal/manage/device-parameters/controls/nal-report-conservation\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/types/d410/template.hbs"
  });

  _exports.default = _default;
});