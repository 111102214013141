define("sky-router-3/pods/portal/manage/device-profiles/assignment/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset'),
        assignments: this.store.findAll('asset-profile-set-asset'),
        sets: this.store.findAll('asset-profile-set')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('search', null);
      }
    },
    actions: {
      upgradeAssignment: function upgradeAssignment(assignment, revision) {
        var _this = this;

        this.send('blockApp', true, {
          message: this.intl.t('profiles.assignment.upgradeSaving')
        });
        assignment.set('profileSetRevision', revision);
        assignment.save().then(function () {
          _this.send('blockApp', false);

          _this.send('sendRevision', assignment.get('asset'));
        }).catch(function () {
          _this.send('blockApp', false);

          _this.notifications.error(_this.intl.t('profiles.assignment.upgradeFail'));
        });
      },
      sendRevision: function sendRevision(asset) {
        var _this2 = this;

        this.send('blockApp', true, {
          message: this.intl.t('profiles.assignment.sendingSaving')
        });
        this.get('api').sendProfileRevision(asset.get('id')).then(function (r) {
          if (r.Result === 0) {
            _this2.notifications.success(_this2.intl.t('profiles.assignment.sendingSuccess'));
          } else {
            _this2.notifications.warning(_this2.intl.t('profiles.assignment.sendingRejected'));
          }
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('profiles.assignment.sendingFailed'));
        }).finally(function () {
          _this2.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});