define("sky-router-3/instance-initializers/check-browser", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /*global Modernizr*/

  /**
   * This executes when the app boots and checks to make sure the users browser
   * has the capability to run the application.
   */
  function initialize()
  /*instance*/
  {
    // Check for local storage
    if (!Modernizr.localstorage) {
      alert("Looks like you do not have localStorage enabled in your browser.\nIn order for SkyRouter to operate fully you must enable localStorage.");
    } // Check for cookies


    if (!Modernizr.cookies) {
      alert("Looks like you do not have cookies enabled in your browser.\nIn order for SkyRouter to operate fully you must enable cookies.");
    } // Check for all the display based stuff
    // The flexboxtweener checks for IE 10's version of flexbox.


    if (!Modernizr.cssanimations || !Modernizr.flexbox && !Modernizr.flexboxtweener || !Modernizr.svg) {
      if (confirm("Looks like you are using an unsupported browser.\nIn order for SkyRouter to operate you should use a modern browser or use the standalone version. Please contact support if this is not possible.\nFor more information on this application's browser requirements and for links to the standalone version please go to support.skyrouter.com or click \"OK\" to be automatically redirected.")) {
        window.location.href = "https://support.blueskynetwork.com/applications-utilities/";
      }
    }
  }

  var _default = {
    name: 'check-browser',
    initialize: initialize
  };
  _exports.default = _default;
});