define("sky-router-3/models/asset-destination", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    isEditing: false,
    // local not stored property
    asset: (0, _model.attr)('string'),
    latitudeMilliarcSeconds: (0, _model.attr)('number'),
    longitudeMilliarcSeconds: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    infoBoxOpen: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});