define("sky-router-3/pods/components/portal/manage/device-parameters/controls/serial-port-dseries/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'transmitIntervalSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.transmitIntervalSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.transmitIntervalSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Passed in settings
     * @type {[type]}
     */
    disabled: false,
    enabledSetting: null,
    bitsSetting: null,
    paritySetting: null,
    baudrateSetting: null,
    transmitIntervalSetting: null,
    commandSetting: null,
    portNumber: 1,

    /**
     * Powers Data-bits dropdown
     */
    bitsSelected: function () {
      return this.get('bitsOptions').findBy('key', this.get('bitsSetting.value'));
    }.property('bitsSetting.value'),
    bitsOptions: function () {
      var intl = this.get('intl');
      return [{
        key: '0',
        label: intl.t('parameter.serial.bitsCount', {
          count: 8
        })
      }, {
        key: '1',
        label: intl.t('parameter.serial.bitsCount', {
          count: 7
        })
      }];
    }.property(),

    /**
     * Powers Parity dropdown
     */
    paritySelected: function () {
      return this.get('parityOptions').findBy('key', this.get('paritySetting.value'));
    }.property('paritySetting.value'),
    parityOptions: function () {
      var intl = this.get('intl');
      return [{
        key: '0',
        label: intl.t('parameter.serial.parityNone')
      }, {
        key: '1',
        label: intl.t('parameter.serial.parityEven')
      }, {
        key: '2',
        label: intl.t('parameter.serial.parityOdd')
      }];
    }.property(),

    /**
     * Powers baudrate dropdown
     */
    baudrateOptions: ['2400', '4800', '9600', '19200', '38400', '57600', '115200'],
    actions: {
      updateBits: function updateBits(selected) {
        this.set('bitsSetting.value', selected.key);
      },
      updateParity: function updateParity(selected) {
        this.set('paritySetting.value', selected.key);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('bitsSetting').rollbackAttributes();
          this.get('paritySetting').rollbackAttributes();
          this.get('baudrateSetting').rollbackAttributes();
          this.get('transmitIntervalSetting').rollbackAttributes();
          this.get('commandSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});