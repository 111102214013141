define("sky-router-3/adapters/group", ["exports", "sky-router-3/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Let the adapter make multiple requests at once.
     * @type {Boolean}
     */
    coalesceFindRequests: true
  });

  _exports.default = _default;
});