define("sky-router-3/models/asset-profile-set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the profile set.
     * This is the only setting that is updateable.
     */
    name: (0, _model.attr)('string'),

    /**
     * The associated type of this profile set
     */
    type: (0, _model.belongsTo)('asset-profile-set-type'),

    /**
     * The revisions associated with this set
     */
    revisions: (0, _model.hasMany)('asset-profile-set-revision'),

    /**
     * The current revision associated with this set.
     * This represents the sets 'actual' settings.
     */
    revisionLatest: (0, _model.belongsTo)('asset-profile-set-revision'),

    /**
     * The date the profile was created
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * Indicates that the set cannot be updated by the current user.
     * @type {Boolean}
     */
    isReadOnly: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});