define("sky-router-3/helpers/compare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(prop, value, op) {// switch (op) {
      //     'eq': return v
      // }
    }
  });

  _exports.default = _default;
});