define("sky-router-3/utils/parse-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseDate;

  /**
   * Parse pasted dates or dates entered by hand and not the picker.
   * @param  {String} dateString Incoming input value by user.
   * @param  {String} locale The 'en-us','pt-br' locale string
   * @return {Date}            Parsed Date
   */
  function parseDate(dateString, locale) {
    // first try standard parsiing
    var date = (0, _moment.default)(dateString);

    if (date.isValid()) {
      return date.toDate();
    } // Then try locale parsing with strict enabled
    // This will walk down the line looking for valid dates
    // Most of these are what the convert-date util outputs


    date = (0, _moment.default)(dateString, ["l", "l H:mm", "l H:mm:s", "l h:mmA", "l h:mm:sA", _moment.default.HTML5_FMT.DATETIME_LOCAL, _moment.default.HTML5_FMT.DATETIME_LOCAL_SECONDS, _moment.default.HTML5_FMT.DATE], locale, true);

    if (date.isValid()) {
      return date.toDate();
    } // Alright getting a bit more despirate here. Match anything now.


    date = (0, _moment.default)(dateString, [_moment.default.ISO_8601, "l h:mm:sA", "l h:mmA", "l H:mm:s", "l H:mm", "l", _moment.default.HTML5_FMT.DATETIME_LOCAL, _moment.default.HTML5_FMT.DATETIME_LOCAL_SECONDS, _moment.default.HTML5_FMT.DATE], locale, false);

    if (date.isValid()) {
      return date.toDate();
    }

    return new Date();
  }
});