define("sky-router-3/utils/computed-find-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedFindBy;

  /**
   * Takes in a property that represents an array and returns
   * a computed property representing the findBy.
   * @param  {String} property String that points to an array property
   * @param  {String} key      Key to lookup on object
   * @param  {String} value    The value that the key must match.
   * @param  {Boolean} isProp  Is the value provided a property that must be looked up.
   * @return {Ember.computedProperty}          A computed property that re-evaulates
   *                                             every time the array changes.
   */
  function computedFindBy(property, key, value) {
    var isProp = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

    if (isProp) {
      return Ember.computed("".concat(property, ".[]"), "".concat(property, ".@each.").concat(key), value, function () {
        return this.get(property).findBy(key, this.get(value));
      });
    } else {
      return Ember.computed("".concat(property, ".[]"), "".concat(property, ".@each.").concat(key), function () {
        return this.get(property).findBy(key, value);
      });
    }
  }
});