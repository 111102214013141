define("sky-router-3/pods/components/input-latlng/component", ["exports", "sky-router-3/utils/lat-lng-converter"], function (_exports, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays an editing box for latitude or longitude,
   *
   * @example {{input-latlng value=MilliArcSeconds }}
   */
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    onchange: function onchange() {},

    /**
     * Disable the wrapper tag
     */
    tagName: '',

    /**
     * This parameter is the incoming bound value, It is expected to be
     * in milliArcSeconds.
     */
    value: Ember.computed({
      get: function get() {
        return this._value;
      },
      set: function set(key, value) {
        Ember.run.once(this, this.onchange, value);
        return this._value = value;
      }
    }),

    /**
     * Holds the operating value for the input
     */
    decimal: Ember.computed('value', {
      get: function get() {
        var value = _latLngConverter.default.milliArcSecondsToDecimalDeg(this.get('value'));

        return _latLngConverter.default.truncate(value, 4);
      },
      set: function set(prop, newValue) {
        if (!isNaN(newValue)) {
          this.set('value', _latLngConverter.default.decimalDegToMilliArcSecond(newValue));
        }

        return newValue;
      }
    }),
    degree: Ember.computed('value', {
      get: function get() {
        var value = _latLngConverter.default.truncate(_latLngConverter.default.milliArcSecondsToDecimalDeg(this.get('value')));

        if (value === 0 && _latLngConverter.default.isNegative(value)) {
          return '-0';
        }

        return value;
      },
      set: function set(prop, newValue) {
        if (!isNaN(newValue)) {
          this.set('value', _latLngConverter.default.degreeMinuteSecondsToMilliArc(newValue, this.get('minute'), this.get('second')));
        }

        return newValue;
      }
    }),
    minute: Ember.computed('value', {
      get: function get() {
        return Math.floor(Math.abs(_latLngConverter.default.milliArcSecondsToMinutes(this.get('value'))));
      },
      set: function set(prop, newValue) {
        if (!isNaN(newValue)) {
          this.set('value', _latLngConverter.default.degreeMinuteSecondsToMilliArc(this.get('degree'), newValue, this.get('second')));
        }

        return newValue;
      }
    }),
    minuteDecimal: Ember.computed('value', {
      get: function get() {
        return Math.abs(_latLngConverter.default.milliArcSecondsToMinutes(this.get('value')));
      },
      set: function set(prop, newValue) {
        if (!isNaN(newValue)) {
          this.set('value', _latLngConverter.default.degreeMinuteSecondsToMilliArc(this.get('degree'), newValue, 0));
        }

        return newValue;
      }
    }),
    second: Ember.computed('value', {
      get: function get() {
        return Math.floor(Math.abs(_latLngConverter.default.milliArcSecondsToSeconds(this.get('value'))) * 1000) / 1000;
      },
      set: function set(prop, newValue) {
        if (!isNaN(newValue)) {
          this.set('value', _latLngConverter.default.degreeMinuteSecondsToMilliArc(this.get('degree'), this.get('minute'), newValue));
        }

        return newValue;
      }
    })
  });

  _exports.default = _default;
});