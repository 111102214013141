define("sky-router-3/helpers/display-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayDuration = displayDuration;
  _exports.default = void 0;

  /**
   * Takes a number of seconds and converts it to xD yH zM
   * @see https://www.geeksforgeeks.org/converting-seconds-into-days-hours-minutes-and-seconds/
   * @return {String}
   */
  function displayDuration(params, hash) {
    if (params[0] == undefined || params[0] == null) {
      return;
    }

    var raw = Math.abs(params[0]);
    var seconds = Math.abs(params[0]);
    var days = Math.floor(seconds / (24 * 3600));
    raw %= 24 * 3600;
    var hours = Math.floor(raw / 3600);
    raw %= 3600;
    var minutes = Math.floor(raw / 60); // Print

    var str = "";

    if (days) {
      str += "".concat(days, "d");
    }

    if (hours) {
      if (str) {
        str += " ";
      }

      str += "".concat(hours, "h");
    }

    if (minutes) {
      if (str) {
        str += " ";
      }

      str += "".concat(minutes, "m");
    }

    if (hash.showSeconds || !str) {
      if (str) {
        str += " ";
      }

      str += "".concat(parseInt(seconds % 60), "s");
    }

    return str;
  }

  var _default = Ember.Helper.helper(displayDuration);

  _exports.default = _default;
});