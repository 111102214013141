define("sky-router-3/pods/components/portal/manage/device-parameters/controls/nal-report-conservation/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'conserveReportsRadiusSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.conserveReportsRadiusSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.conserveReportsRadiusSetting.validationMinNumber')
    })],
    'conserveReportsCountSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.conserveReportsCountSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.conserveReportsCountSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,
    conserveAllSetting: null,
    conserveReportsRadiusSetting: null,
    conserveReportsCountSetting: null,
    actions: {
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('conserveAllSetting').rollbackAttributes();
          this.get('conserveReportsRadiusSetting').rollbackAttributes();
          this.get('conserveReportsCountSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});