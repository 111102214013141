define("sky-router-3/pods/components/portal/track/map/overlays/kml-layer/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object"], function (_exports, _googleObjectMapping, _computedJsonObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Instance of the KML layer object
     * @return {google.maps.KmlLayer}
     */
    googlePropertyObject: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }],

    /**
     * Constructs an options object that can be used to initialize the overlay
     * @return {[type]} [description]
     */
    _getOptions: function _getOptions() {
      var options = Object.assign({}, this.dataObject);

      if (options.url[0] == '/') {
        options.url = window.location.origin + options.url;
      } // Append a random number so that caching is broken


      if (options.url.includes("?")) {
        options.url += "&r=".concat(Math.random());
      } else {
        options.url += "?r=".concat(Math.random());
      }

      return options;
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.KmlLayer(this._getOptions()));
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Responsible for destroying the KML layer
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this = this;

      var dataObject = this.get('dataObject');

      if (dataObject.refreshSeconds) {
        this.scheduleReloadTimer = Ember.run.later(this, function () {
          _this.get('googlePropertyObject').setOptions(_this._getOptions());

          _this.scheduleReload();
        }, dataObject.refreshSeconds * 1000);
      }
    }
  });

  _exports.default = _default;
});