define("sky-router-3/pods/components/input-token/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Implements the `bootstrap-tokenfield` plugin in the same mannor as the cli addon
   * @see https://sliptree.github.io/bootstrap-tokenfield/.
   * @see https://github.com/aethermx/ember-cli-bootstrap-tokenfield/blob/master/addon/components/input-tokenfield.js
   * @example {{input-token tokens=addressList addedToken="added" removedToken="removed"}}
   */
  var _default = Ember.TextField.extend({
    classNames: ['form-control'],
    addedToken: function addedToken() {},
    removedToken: function removedToken() {},

    /**
     * The tokens for this input. This can be string[] or an array of objects
     * like [{ value: "one", label: "Einz" }, { value: "two", label: "Zwei" }]
     * @type {String[]}
     */
    tokens: null,

    /**
     * Number of tokens to limit it to
     * @type {Number}
     */
    limit: 0,

    /**
     * Automatically create tokens when this character is encountered.
     * @type {Array}
     */
    delimiter: [',', ';'],

    /**
     * The HTML type attribute for the token input.
     * @type {String}
     */
    inputType: 'text',

    /**
     * Whether to insert spaces after each token when getting a comma-separated
     * list of tokens.
     * @type {Boolean}
     */
    beautify: false,

    /**
     * Locking flag that prevents events from firing while the the tokens are
     * being set from an outside source. This is like this because the events
     * would fire incorrectly when the tokens were being set.
     * @type {Boolean}
     */
    _settingTokens: false,

    /**
     * Watches for changes to the options and applies the changes to the field.
     * This fires when the components attributes change.
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.setOptions();
    },

    /**
     * watches for changes to the attached array and applies changes to the tokens
     */
    tokenObserver: function () {
      Ember.run.scheduleOnce('actions', this, this.setTokens);
    }.observes('tokens.[]'),

    /**
     * Setsup the tokenfield
     * @return {Node}
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      // initialize the tokenfield
      Ember.run.schedule('afterRender', function () {
        _this.setOptions();

        _this.setTokens();

        _this.setupTokenChangeListeners();
      });
    },

    /**
     * Attaches the listeners to the token field.
     * @return {[type]} [description]
     */
    setupTokenChangeListeners: function setupTokenChangeListeners() {
      var _this2 = this;

      (0, _jquery.default)(this.element).on('tokenfield:createdtoken', function (e) {
        if (!_this2.get('_settingTokens')) {
          _this2.addedToken({
            sender: _this2,
            addedToken: e.attrs,
            currentTokens: $(e.target).tokenfield('getTokens')
          });
        }
      });
      (0, _jquery.default)(this.element).on('tokenfield:removedtoken', function (e) {
        if (!_this2.get('_settingTokens')) {
          _this2.removedToken({
            sender: _this2,
            removedToken: e.attrs,
            currentTokens: Ember.A($(e.target).tokenfield('getTokens'))
          });
        }
      });
      (0, _jquery.default)(this.element).on('tokenfield:editedtoken', function (e) {
        if (!_this2.get('_settingTokens')) {
          _this2.removedToken({
            sender: _this2,
            removedToken: e.attrs,
            currentTokens: Ember.A($(e.target).tokenfield('getTokens'))
          });
        }
      });
    },

    /**
     * Sets tokens upon the token field and makes sure that events don't fire
     * while this is running.
     */
    setTokens: function setTokens() {
      var _this3 = this;

      var tokens = this.get('tokens');
      this.set('_settingTokens', true);

      if (tokens && tokens.then) {
        tokens.then(function (values) {
          (0, _jquery.default)(_this3.element).tokenfield('setTokens', values);
        });
      } else {
        (0, _jquery.default)(this.element).tokenfield('setTokens', tokens);
      }

      this.set('_settingTokens', false);
    },

    /**
     * Sets the token field with token options
     */
    setOptions: function setOptions() {
      (0, _jquery.default)(this.element).tokenfield({
        limit: this.get('limit'),
        delimiter: this.get('delimiter'),
        inputType: this.get('inputType'),
        beautify: this.get('beautify')
      });
    }
  });

  _exports.default = _default;
});