define("sky-router-3/pods/portal/track/altitude/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['asset', 'expanded'],
    asset: null,
    expanded: false,
    sessionAccount: Ember.inject.service(),
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      }
    }
  });

  _exports.default = _default;
});