define("sky-router-3/pods/components/portal/track/map/static-label/component", ["exports", "sky-router-3/pods/components/portal/track/map/info-box/component", "sky-router-3/pods/components/portal/track/map/lib/html-box"], function (_exports, _component, _htmlBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global google */

  /**
   * This overrides the sky-router-3/pods/components/portal/track/map/info-box
   * to create a static label that can be placed anywhere on the map
   */
  var _default = _component.default.extend({
    /**
     * Placement of the box based on the anchor or position.
     * OPTIONS: 'CENTER', 'BOTTOM_CENTER', 'TOP_RIGHT'
     * @type {String}
     */
    placement: 'CENTER',

    /**
     * The X offset that the box appears at relative to the anchor
     * @type {Number}
     */
    offsetX: 0,

    /**
     * Gets the html box to be used
     */
    getBox: function getBox() {
      return new _htmlBox.default({
        placement: this.get('placement'),
        offset: new google.maps.Size(this.get('offsetX'), this.get('offsetY')),
        visible: true
      });
    }
  });

  _exports.default = _default;
});