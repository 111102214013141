define("sky-router-3/pods/public/login/activate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.intl.t('login.activateHeading');
    },

    /**
     * Set from query string but don't let what the user entered show up at the top
     */
    afterModel: function afterModel(model, transition) {
      if (transition.to.queryParams) {
        var controller = this.controllerFor(this.routeName);
        var username = transition.to.queryParams.username;
        var password = transition.to.queryParams.password;
        controller.set('user', username);
        controller.set('password', password);
      }
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller.reset();
      }
    }
  });

  _exports.default = _default;
});