define("sky-router-3/models/form-template", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The given name of the form
     */
    name: (0, _model.attr)('string'),

    /**
     * The description of the form
     */
    description: (0, _model.attr)('string'),

    /**
     * The currently active file for this form template.
     */
    activeTemplateFile: (0, _model.belongsTo)('form-template-file'),

    /**
     * The type of this template.
     */
    type: (0, _model.belongsTo)('form-template-type'),

    /**
     * Specifies whether the form is enabled or not
     */
    enabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Date it was created
     */
    createdDateUtc: (0, _model.attr)('date'),

    /**
     * Can this user edit this template
     */
    canEdit: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * List of emails that forms will be sent to when received
     */
    recipientEmails: (0, _attributes.array)(),

    /**
     * The type of this template.
     */
    emailAttachmentType: (0, _model.belongsTo)('form-template-email-attachment-type')
  });

  _exports.default = _default;
});