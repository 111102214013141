define("sky-router-3/helpers/array-matches", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper compares arrays and produces an array of the matches present in the arrayToFilter
   *
   * @example {{#if (in-array arrayToFilter arrayToFilterBy)}} .... {{/if}}
   */
  var _default = Ember.Helper.extend({
    /**
     * Actually performs the computation.
     */
    compute: function compute(params
    /*, hash*/
    ) {
      // Validate
      if (params.length !== 2 || !Ember.isArray(params[0]) || !Ember.isArray(params[1])) {
        return false;
      } // Return


      return params[0].filter(function (x) {
        return params[1].includes(x);
      });
    }
  });

  _exports.default = _default;
});