define("sky-router-3/pods/portal/index/route", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The session service that holds the user account information.
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The session account service
     */
    session: Ember.inject.service(),

    /**
     * Checks the users permissions and redirects them to the appropriate
     * page that they have access to.  This is called anytime the user goes
     * to the root route.
     */
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'portal.index') {
        var permissions = this.get('sessionAccount.user.permissions'); // Navigate the user to the closest page they have access to

        if ((0, _hasPermission.default)(permissions, 'track')) {
          this.replaceWith('portal.track');
        } else if ((0, _hasPermission.default)(permissions, 'manage')) {
          this.replaceWith('portal.manage');
        } else if ((0, _hasPermission.default)(permissions, 'report')) {
          this.replaceWith('portal.report');
        } else if ((0, _hasPermission.default)(permissions, 'communicate')) {
          this.replaceWith('portal.communicate');
        } else {
          this.replaceWith('portal.settings');
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});