define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    onEnabled: function onEnabled() {},

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    gprsStrategy: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    dynamicMode: null,

    /**
     * The selected gprs strategy
     */
    gprsStrategySelected: function () {
      var options = this.get('gprsStrategyOptions');
      var option = options.findBy('value', this.get('gprsStrategy.value'));

      if (!option) {
        option = options.findBy('value', '2');
      }

      return option;
    }.property('gprsStrategy.value'),

    /**
     * The options for the gprs strategy dropdown
     */
    gprsStrategyOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '0',
        label: intl.t('parameter.rockseven.gprsStrategyOptions.never')
      }, {
        value: '1',
        label: intl.t('parameter.rockseven.gprsStrategyOptions.always')
      }, {
        value: '2',
        label: intl.t('parameter.rockseven.gprsStrategyOptions.preferred')
      }];
    }.property(),

    /**
     * The selected dynamic mode
     */
    dynamicModeSelected: function () {
      var options = this.get('dynamicModeOptions');
      var option = options.findBy('value', this.get('dynamicMode.value'));

      if (!option) {
        option = options.findBy('value', '3');
      }

      return option;
    }.property('dynamicMode.value'),

    /**
     * The options for the dynamic mode dropdown
     */
    dynamicModeOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '0',
        label: intl.t('parameter.rockseven.dynamicModeOptions.portable')
      }, {
        value: '1',
        label: intl.t('parameter.rockseven.dynamicModeOptions.automotive')
      }, {
        value: '2',
        label: intl.t('parameter.rockseven.dynamicModeOptions.marine')
      }, {
        value: '3',
        label: intl.t('parameter.rockseven.dynamicModeOptions.airborne')
      }];
    }.property(),
    actions: {
      gpsStrategyChange: function gpsStrategyChange(selected) {
        this.set('gprsStrategy.value', selected.value);
      },
      dynamicModeChange: function dynamicModeChange(selected) {
        this.set('dynamicMode.value', selected.value);
      }
    }
  });

  _exports.default = _default;
});