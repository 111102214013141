define("sky-router-3/pods/components/portal/operations/trips/custom-field-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gd+nDEvz",
    "block": "{\"symbols\":[\"opt\",\"@type\",\"@field\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"form-group\"],[17,4],[12],[2,\"\\n    \"],[8,\"input-label\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2,[\"type\"]],\"text\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\"],[[32,3,[\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,2,[\"type\"]],\"textarea\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"textarea\",[[24,0,\"form-control\"]],[[\"@value\"],[[32,3,[\"value\"]]]],null],[2,\"\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,2,[\"type\"]],\"checkbox\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"input-checkbox\",[],[[\"@checked\"],[[32,3,[\"value\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"input-select\",[],[[\"@options\",\"@selected\",\"@allowClear\",\"@onChange\"],[[32,2,[\"options\"]],[32,3,[\"value\"]],\"true\",[30,[36,1],[[32,0],[30,[36,0],[[32,3,[\"value\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"eq\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/operations/trips/custom-field-input/template.hbs"
  });

  _exports.default = _default;
});