define("sky-router-3/utils/geo-conversion-tools", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants", "sky-router-3/utils/geoconversiontools/utm-conversions", "sky-router-3/utils/geoconversiontools/mgrs-conversions"], function (_exports, _geoconstants, _utmConversions, _mgrsConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var geoConversionTools = {
    getUTMCoordinates: function getUTMCoordinates(latitude, longitude) {
      var lat_radians = _geoconstants.default.getRadians(latitude);

      var lon_radians = _geoconstants.default.getRadians(longitude);

      return _utmConversions.default.convertGeodeticToUTM(lat_radians, lon_radians, _utmConversions.default.getUTMDefaultValues());
    },
    getGeodeticCoordinatesFromUTM: function getGeodeticCoordinatesFromUTM(zone, hemisphere, easting, northing) {
      return _utmConversions.default.convertUTMToGeodetic(zone, hemisphere, easting, northing, _utmConversions.default.getUTMDefaultValues());
    },
    getMGRSCoordinates: function getMGRSCoordinates(latitude, longitude) {
      var lat_radians = _geoconstants.default.getRadians(latitude);

      var lon_radians = _geoconstants.default.getRadians(longitude);

      return _mgrsConversions.default.convertGeodeticToMGRS(lat_radians, lon_radians, 5, _mgrsConversions.default.getMGRSDefaultValues());
    },
    getGeodeticCoordinates: function getGeodeticCoordinates(mgrsstring) {
      return _mgrsConversions.default.convertMGRSToGeodetic(mgrsstring, _mgrsConversions.default.getMGRSDefaultValues());
    }
  };
  var _default = geoConversionTools;
  _exports.default = _default;
});