define("sky-router-3/pods/portal/settings/map/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.map');
    },

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),
    model: function model() {
      var _model$user$assetDisp, _model$user$assetDisp2;

      var model = this.get('sessionAccount');
      model.originalDisplayName = (_model$user$assetDisp = (_model$user$assetDisp2 = model.user.assetDisplayNameFormat) === null || _model$user$assetDisp2 === void 0 ? void 0 : _model$user$assetDisp2.slice(0)) !== null && _model$user$assetDisp !== void 0 ? _model$user$assetDisp : '';
      return model;
    },
    actions: {
      willTransition: function willTransition() {
        var user = this.modelFor(this.routeName).get('user');

        if (user.get('hasDirtyAttributes')) {
          user.save();
        }
      }
    }
  });

  _exports.default = _default;
});