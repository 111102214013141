define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-29/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    valueVolts: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: 30,
      gte: 0
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    operations: [{
      label: '>',
      id: 0
    }, {
      label: '<',
      id: 1
    }],
    valueVolts: Ember.computed('model.value1', {
      get: function get() {
        return this.get('model.value1') / 1000;
      },
      set: function set(key, value) {
        this.set('model.value1', value * 1000);
        return value;
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('model.relationalOperator') === 2) {
        this.set('model.relationalOperator', 0);
      }
    },
    actions: {
      setOperation: function setOperation(op) {
        this.set('model.relationalOperator', op.id);
      }
    }
  });

  _exports.default = _default;
});