define("sky-router-3/pods/portal/manage/geofences/assignment-by-device/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selected: [],
    options: Ember.computed('model.geofences', 'model.asset', 'model.asset.geofences.[]', function () {
      var _this = this;

      return this.model.geofences.map(function (geofence) {
        return {
          geofence: geofence,
          disabled: geofence.assets.isAny('asset.id', _this.model.asset.id) || !geofence.isOwnedByCaller
        };
      });
    }),
    optionsSorted: Ember.computed.sort('options', 'optionsSortedBy'),
    optionsSortedBy: ['geofence.name'],
    geofenceCount: Ember.computed('model.asset.geofences.[]', 'model.asset', 'selected.[]', function () {
      return this.model.asset.get('geofences.length') + this.get('selected.length') || 0;
    }),
    geofenceCountLimitReached: Ember.computed('geofenceCount', 'model.asset.product.geofenceLimit', function () {
      return this.geofenceCount >= this.model.asset.get('product.geofenceLimit');
    }),
    actions: {
      setSelected: function setSelected(newSelected) {
        if (this.geofenceCountLimitReached && newSelected.length > this.selected.length) {
          return;
        }

        this.set('selected', newSelected);
      },
      saveSelected: function saveSelected(asset, selected) {
        this.send('createAssignments', selected.map(function (s) {
          return {
            asset: asset,
            geofence: s.geofence
          };
        }));
        this.transitionToRoute('portal.manage.geofences.assignment-by-device');
      }
    }
  });

  _exports.default = _default;
});