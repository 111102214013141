define("sky-router-3/pods/components/portal/manage/devices/activate-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UDnw+Q39",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"canActivate\"]]],null,[[\"default\"],[{\"statements\":[[10,\"a\"],[14,0,\"btn btn-default\"],[14,\"target\",\"_BLANK\"],[15,6,[31,[[34,0]]]],[12],[10,\"i\"],[14,0,\"fa fa-check-circle text-success\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"device.activateService\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"url\",\"t\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/devices/activate-link/template.hbs"
  });

  _exports.default = _default;
});