define("sky-router-3/pods/components/portal/manage/device-profiles/canned-messages/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The passed in settings object
     * @type {Array<sky-router-3/models/asset-profile-set-revision-setting>}
     */
    settings: null,

    /**
     * Is this form disabled from the outside
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Holds a mapping of all the settings that have to be created
     * @type {Array}
     */
    cannedMessages: [{
      key: 'cannedmsg_1',
      index: 1
    }, {
      key: 'cannedmsg_2',
      index: 2
    }, {
      key: 'cannedmsg_3',
      index: 3
    }, {
      key: 'cannedmsg_4',
      index: 4
    }, {
      key: 'cannedmsg_5',
      index: 5
    }, {
      key: 'cannedmsg_6',
      index: 6
    }, {
      key: 'cannedmsg_7',
      index: 7
    }, {
      key: 'cannedmsg_8',
      index: 8
    }, {
      key: 'cannedmsg_9',
      index: 9
    }, {
      key: 'cannedmsg_10',
      index: 10
    }, {
      key: 'cannedmsg_11',
      index: 11
    }, {
      key: 'cannedmsg_12',
      index: 12
    }, {
      key: 'cannedmsg_13',
      index: 13
    }, {
      key: 'cannedmsg_14',
      index: 14
    }, {
      key: 'cannedmsg_15',
      index: 15
    }, {
      key: 'cannedmsg_16',
      index: 16
    }, {
      key: 'cannedmsg_17',
      index: 17
    }, {
      key: 'cannedmsg_18',
      index: 18
    }, {
      key: 'cannedmsg_19',
      index: 19
    }, {
      key: 'cannedmsg_20',
      index: 20
    }],

    /**
     * Initialize the component and create the computed properties and validations
     * for all the phone number setting blocks.
     */
    init: function init() {
      var _this = this;

      this.get('cannedMessages').forEach(function (prop) {
        Ember.defineProperty(_this, prop.key, (0, _computedFindBy.default)('settings', 'key', prop.key));
      });

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});