define("sky-router-3/utils/geoconversiontools/polar-conversions", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants"], function (_exports, _geoconstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var polarconversions = {
    getPolarDefaultValues: function getPolarDefaultValues() {
      return {
        Polar_a: 6378137.0,
        Polar_f: 1 / 298.257223563,
        es: 0.08181919084262188000,
        es_OVER_2: .040909595421311,
        Southern_Hemisphere: 0,
        tc: 1.0,
        e4: 1.0033565552493,
        Polar_a_mc: 6378137.0,
        two_Polar_a: 12756274.0,

        /* Polar Stereographic projection Parameters */
        Polar_Origin_Lat: _geoconstants.default.getPI() * 90 / 180,
        Polar_Origin_Long: 0.0,
        Polar_False_Easting: 0.0,
        Polar_False_Northing: 0.0,

        /* Maximum variance for easting and northing values for WGS 84. */
        Polar_Delta_Easting: 12713601.0,
        Polar_Delta_Northing: 12713601.0
      };
    },
    polarPower: function polarPower(essin, polarparams) {
      return Math.pow((1.0 - essin) / (1.0 + essin), polarparams.es_OVER_2);
    },
    setPolarParameters: function setPolarParameters(a, f, latitudeOfTrueScale, longitudeDownFromPole, falseeasting, falsenorthing) {
      var polarconstants = _geoconstants.default.getPolarConstants();

      var polarparams = this.getPolarDefaultValues();
      var errors = [];

      if (a <= 0.0) {
        errors.push({
          code: 0x0040,
          desc: 'Semi-major axis less than or equal to zero'
        });
      }

      if (1 / f < 250 || 1 / f > 350) {
        errors.push({
          code: 0x0080,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (Math.abs(latitudeOfTrueScale) > polarconstants.PI_OVER_2) {
        errors.push({
          code: 0x0004,
          desc: 'Latitude of true scale outside of valid range (-90 to 90 degrees)'
        });
      }

      if (longitudeDownFromPole < -polarconstants.PI || longitudeDownFromPole > polarconstants.TWO_PI) {
        errors.push({
          code: 0x0008,
          desc: 'Longitude down from pole outside of valid range (-180 to 360 degrees)'
        });
      }

      if (errors.length == 0) {
        polarparams.Polar_a = a;
        polarparams.two_Polar_a = 2 * a;
        polarparams.Polar_f = f;

        if (longitudeDownFromPole > polarconstants.PI) {
          longitudeDownFromPole -= polarconstants.TWO_PI;
        }

        if (latitudeOfTrueScale < 0) {
          polarparams.Southern_Hemisphere = 1;
          polarparams.Polar_Origin_Lat = -latitudeOfTrueScale;
          polarparams.Polar_Origin_Long = -longitudeDownFromPole;
        } else {
          polarparams.Southern_Hemisphere = 0;
          polarparams.Polar_Origin_Lat = latitudeOfTrueScale;
          polarparams.Polar_Origin_Long = longitudeDownFromPole;
        }

        polarparams.Polar_False_Easting = falseeasting;
        polarparams.Polar_False_Northing = falsenorthing;
        var es2 = 2 * polarparams.Polar_f - polarparams.Polar_f * polarparams.Polar_f;
        var es1 = Math.sqrt(es2);
        polarparams.es_OVER_2 = es1 / 2.0;

        if (Math.abs(Math.abs(polarparams.Polar_Origin_Lat) - polarconstants.PI_OVER_2) > 1.0e-10) {
          var essin = polarparams.es * Math.sin(polarparams.Polar_Origin_Lat);
          polarparams.Polar_a_mc = polarparams.Polar_a * Math.cos(polarparams.Polar_Origin_Lat) / Math.sqrt(1.0 - essin * essin);
          polarparams.tc = Math.tan(polarconstants.PI_Over_4 - polarparams.Polar_Origin_Lat / 2.0) / this.polarPower(essin, polarparams);
        } else {
          polarparams.e4 = Math.sqrt(Math.pow(1.0 + polarparams.es, 1.0 - polarparams.es) * Math.pow(1.0 - polarparams.es, 1.0 - polarparams.es));
        }

        polarparams.Polar_Delta_Northing = this.convertGeodeticToPolarStereographic(0, longitudeDownFromPole, polarparams).output.northing;

        if (!isNaN(polarparams.Polar_False_Northing)) {
          polarparams.Polar_Delta_Northing -= polarparams.Polar_False_Northing;
        }

        if (polarparams.Polar_Delta_Northing < 0) {
          polarparams.Polar_Delta_Northing = Math.abs(polarparams.Polar_Delta_Northing);
        }

        polarparams.Polar_Delta_Northing *= 1.01;
        polarparams.Polar_Delta_Easting = polarparams.Polar_Delta_Northing;
      }

      return {
        parameters: polarparams,
        errorCodes: errors
      };
    },
    convertGeodeticToPolarStereographic: function convertGeodeticToPolarStereographic(latitude, longitude, polarparams) {
      var polarconstants = _geoconstants.default.getPolarConstants();

      var polarresult = {
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];

      if (latitude < -polarconstants.PI_OVER_2 || latitude > polarconstants.PI_OVER_2) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      if (latitude < 0 && polarparams.Southern_Hemisphere == 0) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      if (latitude > 0 && polarparams.Southern_Hemisphere == 1) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      if (longitude < -polarconstants.PI || longitude > polarconstants.TWO_PI) {
        errors.push({
          code: 0x0002,
          desc: 'Longitude outside of valid range (-180 to 360 degrees)'
        });
      }

      if (errors.length == 0) {
        /* no errors */
        if (Math.abs(Math.abs(latitude) - polarconstants.PI_OVER_2) < 1.0e-10) {
          polarresult.easting = polarparams.Polar_False_Easting;
          polarresult.northing = polarparams.Polar_False_Northing;
        } else {
          if (polarparams.Southern_Hemisphere != 0) {
            longitude *= -1.0;
            latitude *= -1.0;
          }

          var dlam = longitude - polarparams.Polar_Origin_Long;

          if (dlam > polarconstants.PI) {
            dlam -= polarconstants.TWO_PI;
          }

          if (dlam < -polarconstants.PI) {
            dlam += polarconstants.TWO_PI;
          }

          var slat = Math.sin(latitude);
          var essin = polarparams.es * slat;
          var pow_es = this.polarPower(essin, polarparams);
          var tan1 = Math.tan(polarconstants.PI_OVER_4 - latitude / 2.0) / pow_es;
          var rho = polarparams.two_Polar_a * tan1 / polarparams.e4;

          if (Math.abs(Math.abs(polarparams.Polar_Origin_Lat) - polarconstants.PI_OVER_2) > 1.0e-10) {
            rho = polarparams.Polar_a_mc * tan1 / polarparams.tc;
          }

          if (polarparams.Southern_Hemisphere != 0) {
            polarresult.easting = -(rho * Math.sin(dlam) - polarparams.Polar_False_Easting);
            polarresult.northing = rho * Math.cos(dlam) + polarparams.Polar_False_Northing;
          } else {
            polarresult.easting = rho * Math.sin(dlam) + polarparams.Polar_False_Easting;
            polarresult.northing = -rho * Math.cos(dlam) + polarparams.Polar_False_Northing;
          }
        }
      }

      return {
        output: polarresult,
        parameters: polarparams,
        errorCodes: errors
      };
    },
    convertPolarStereographicToGeodetic: function convertPolarStereographicToGeodetic(easting, northing, polarparams) {
      var polarconstants = _geoconstants.default.getPolarConstants();

      var polarresult = {
        latitude: 0e0,
        longitude: 0e0
      };
      var errors = [];
      var min_easting = polarparams.Polar_False_Easting - polarparams.Polar_Delta_Easting;
      var max_easting = polarparams.Polar_False_Easting + polarparams.Polar_Delta_Easting;
      var min_northing = polarparams.Polar_False_Northing - polarparams.Polar_Delta_Northing;
      var max_northing = polarparams.Polar_False_Northing + polarparams.Polar_Delta_Northing;

      if (easting > max_easting || easting < min_easting) {
        errors.push({
          code: 0x0010,
          desc: 'Easting outside of valid range, depending on ellipsoid and projection parameters'
        });
      }

      if (northing > max_northing || northing < min_northing) {
        errors.push({
          code: 0x0020,
          desc: 'Northing outside of valid range, depending on ellipsoid and projection parameters'
        });
      }

      if (errors.length == 0) {
        var dx = easting - polarparams.Polar_False_Easting;
        var dy = northing - polarparams.Polar_False_Northing;
        var rho = Math.sqrt(dx * dx + dy * dy);
        var delta_radius = Math.sqrt(polarparams.Polar_Delta_Easting * polarparams.Polar_Delta_Easting + polarparams.Polar_Delta_Northing * polarparams.Polar_Delta_Northing);

        if (rho > delta_radius) {
          errors.push({
            code: 0x0100,
            desc: 'Coordinates too far from pole, depending on ellipsoid and projection parameters'
          });
        } else {
          if (dy == 0.0 && dx == 0.0) {
            polarresult.latitude = polarconstants.PI_OVER_2;
            polarresult.longitude = polarparams.Polar_Origin_Long;
          } else {
            if (polarparams.Southern_Hemisphere === 1) {
              dy *= -1.0;
              dx *= -1.0;
            }

            var t = rho * polarparams.e4 / polarparams.two_Polar_a;

            if (Math.abs(Math.abs(polarparams.Polar_Origin_Lat) - polarconstants.PI_OVER_2) > 1.0e-10) {
              t = rho * polarparams.tc / polarparams.Polar_a_mc;
            }

            var phi = polarconstants.PI_OVER_2 - 2.0 * Math.atan(t);
            var tempphi = 0.0;

            while (Math.abs(phi - tempphi) > 1.0e-10) {
              tempphi = phi;
              var sin_phi = Math.sin(phi);
              var essin = polarparams.es * sin_phi;
              var pow_es = this.polarPower(essin);
              phi = polarconstants.PI_OVER_2 - 2.0 * Math.atan(t * pow_es);
            }

            polarresult.latitude = phi;
            polarresult.longitude = polarparams.Polar_Origin_Long + Math.atan2(dx, -dy);

            if (polarresult.longitude > polarconstants.PI) {
              polarresult.longitude -= polarconstants.TWO_PI;
            } else if (polarresult.longitude < -polarconstants.PI) {
              polarresult.longitude += polarconstants.TWO_PI;
            }

            if (polarresult.latitude > polarconstants.PI_OVER_2) {
              polarresult.latitude = polarconstants.PI_OVER_2;
            } else if (polarresult.latitude < -polarconstants.PI_OVER_2) {
              polarresult.latitude = -polarconstants.PI_OVER_2;
            }

            if (polarresult.longitude > polarconstants.PI) {
              polarresult.longitude = polarconstants.PI;
            } else if (polarresult.longitude < -polarconstants.PI) {
              polarresult.longitude = -polarconstants.PI;
            }
          }

          if (polarparams.Southern_Hemisphere === 1) {
            polarresult.latitude = polarresult.latitude * -1.0;
            polarresult.longitude = polarresult.longitude * -1.0;
          }
        }
      }

      return {
        output: polarresult,
        parameters: polarparams,
        errorCodes: errors
      };
    }
  };
  var _default = polarconversions;
  _exports.default = _default;
});