define("sky-router-3/pods/components/portal/track/geofence-list/geofence-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents an geofence that is of the model type
   * {sky-router-3/models/geofence}
   */
  var _default = Ember.Component.extend({
    classNames: ['pointer'],

    /**
     * Incoming geofence to display
     * @type {sky-router-3/models/geofence}
     */
    geofence: null
  });

  _exports.default = _default;
});