define("sky-router-3/utils/computed-json-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedAliasNot;

  /**
   * Creates a computed function that takes a json string and turns it into a
   * full object.
   * @param  {String} property the property to observe
   * @return {Ember.computed}
   */
  function computedAliasNot(property) {
    return Ember.computed(property, function () {
      return JSON.parse(this.get(property));
    });
  }
});