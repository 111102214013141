define("sky-router-3/pods/components/play-audio/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Changes this tag to the audio tag
     * @type {String}
     */
    tagName: 'audio',

    /**
     * The attributes for this tag
     * @type {Array}
     */
    attributeBindings: ['loop', 'preload'],

    /**
     * This is a static array buffer that is shared between all the instances
     * of this component. It is used to hold records for sounds that are
     * currently playing.
     * @type {Array}
     */
    bufferArray: [],

    /**
     * The incoming sound model that dictates what sound to play
     * @type {sky-router-3/models/sound}
     */
    sound: null,

    /**
     * Indicates if this sound should loop while the component is present or
     * if this should only play once.
     * @type {Boolean}
     */
    loop: false,

    /**
     * Potentially fixes any problems that occur
     * @type {String}
     */
    preload: 'auto',

    /**
     * Indicates that if this sound is already playing for less than this number
     * of milliseconds the sound should not be played again. This helps prevent
     * spam of sounds.
     * @type {Number}
     */
    bufferTimeout: 0,

    /**
     * The identifier used to determine if the sound is already playing
     * @type {Number}
     */
    bufferId: null,

    /**
     * Holds an instance of the Ember.run.later timer that can be canceled.
     * @type {Timer Information}
     */
    bufferTimer: null,

    /**
     * Adds the sound to the buffer and starts playing the sound
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.setupCanPlayBuffer()) {
        // Start playing the sound
        var promise = this.element.play(); // Catch scenario where browser stopped playback, this can happen
        // because the tab is backgrounded or hasn't finished loading
        // see: https://stackoverflow.com/questions/40276718/how-to-handle-uncaught-in-promise-domexception-the-play-request-was-interru

        if (promise && promise.catch) {
          promise.catch(function () {
            return _this.element.play();
          });
        }
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Cancels the timer and removes the object from the buffer
     * @return {[type]} [description]
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('bufferTimer'));
      this.get('bufferArray').removeObject(this.get('bufferId'));
    },

    /**
     * Check to see if the buffer is enabled and if it is it check to see if
     * the sound is already playing in the buffer.
     * @return {Boolean} returns false if the buffer currently contains the
     *                           bufferId. Returns true if the buffer is not
     *                           enabled or does not contain the Id.
     */
    setupCanPlayBuffer: function setupCanPlayBuffer() {
      var buffer = this.get('bufferArray');
      var bufferTimeout = this.get('bufferTimeout');
      var bufferId = this.get('bufferId');
      var loop = this.get('loop'); // Check to see if buffer is enabled

      if (!bufferTimeout) {
        return true;
      } // Check to see if the sound is already in the buffer


      if (buffer.includes(bufferId)) {
        return false;
      } // The sound is not already in the buffer add it to the buffer


      buffer.addObject(bufferId); // setup a timer to remove it from the buffer only if the sound is
      // not a looping type of sound.

      if (!loop) {
        var bufferTimer = Ember.run.later(this, function () {
          buffer.removeObject(bufferId);
        }, bufferTimeout);
        this.set('bufferTimer', bufferTimer);
      }

      return true;
    }
  });

  _exports.default = _default;
});