define("sky-router-3/models/device-history-entry-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model describes the device history entry types that exist in the database.
   *
   * This is used to display as a device history report parameter dropdown
   */
  var model = _model.default.extend({
    /**
     * The name of the entry type
     */
    name: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});