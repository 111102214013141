define("sky-router-3/pods/portal/manage/users/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.user.firstName': (0, _emberCpValidations.validator)('presence', true),
    'model.user.lastName': (0, _emberCpValidations.validator)('presence', true),
    'model.user.email': (0, _emberCpValidations.validator)('email'),
    'model.user.username': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 20
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z0-9]*$/,
      messageKey: 'errors.onlyLettersNumbers'
    }), (0, _emberCpValidations.validator)('username-available', {
      disabled: Ember.computed('model.model.user.isNew', function () {
        var user = this.get('model.model.user');
        return !user.get('isNew') || user.get('isLoading') || user.get('isSaving');
      }),
      debounce: 300
    })]
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    eventOptions: Ember.computed.filterBy('model.alertTriggerEvents', 'supportAlertAction'),
    actions: {
      onAdminChange: function onAdminChange(user, makeAdmin) {
        if (makeAdmin) {
          user.set('hideAssetAltitude', false);
          user.set('hideAssetSpeed', false);
        }
      }
    }
  });

  _exports.default = _default;
});