define("sky-router-3/serializers/application", ["exports", "@ember-data/serializer/json", "ember-inflector"], function (_exports, _json, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This serializes and deserializes payload data from the BsnWebApi.
   * The BsnWebApi follows creates payloads that has the following rules.
   *
   * 1. All properties are PascalCase including acronyms (ex. UTC => Utc)
   * 2. Singular objects must live off the root objects
   * 3. Arrays can live off the root or can live off a property with the name
   * 	  Of the model pluralized.
   * 4. "Meta" objects can be specified either for arrays or for singluar objects.
   * 5. Related records must use the ID of the related record.
   * 6. Including a record in its entirety can be done using the EmbeddedRecordsMixin
   * 7. Included records sit off a property by the name of the included record type.
   *
   * ex.
   * {
   *    DateCreatedUtc: "2015-08-05T15:47:42.8279469Z"
   *    DateDismissedUtc: null
   *    Description: "Alert 2 Description"
   *    DismissedByUser: "Dismissed by user"
   *    Id: 3
   *    Title: "Alert 3 title"
   *  }
   *
   * ex. asset-position model
   *
   * {
   * 	  AssetPositions: [
   * 	  	{
   * 	  	  Asset: 12312312312312,
   * 	  	  ....
   * 	  	}, ....
   * 	  ],
   *
   * 	  Meta: {
   * 	  	....
   * 	  }
   * }
   */
  var _default = _json.default.extend({
    /**
     * What property is always the primaryKey property
     * @type {String}
     */
    primaryKey: 'Id',
    metaKey: 'Meta',
    metaQueryKey: 'QueryData',

    /**
     * Overrides the default serialize method to force
     * the ID to be included every time.
     * @see http://emberjs.com/api/data/classes/DS.JSONSerializer.html#method_serialize
     */
    serialize: function serialize(record, options) {
      options = options || {};
      options.includeId = true;
      return this._super(record, options);
    },

    /**
     * Adds the ability to pass options to the request via the format
     * save({
     *     adapterOptions: {
     *         data: { "key": "value" }
     *     }
     * })
     */
    serializeIntoHash: function serializeIntoHash(hash, type, record
    /*, options*/
    ) {
      var resp = this._super.apply(this, arguments);

      if (Ember.get(record, 'adapterOptions.data')) {
        Ember.merge(hash, record.adapterOptions.data);
      }

      return resp;
    },

    /**
     * Converts keys back and forth.
     * @see http://emberjs.com/api/data/classes/DS.JSONSerializer.html#method_keyForAttribute
     */
    keyForAttribute: function keyForAttribute(key
    /*, method*/
    ) {
      return Ember.String.classify(key);
    },

    /**
     * Converts keys back and forth
     * @see http://emberjs.com/api/data/classes/DS.JSONSerializer.html#method_keyForRelationship
     */
    keyForRelationship: function keyForRelationship(key
    /*, relationship, method */
    ) {
      return Ember.String.classify(key);
    },

    /**
     * Overrides the default normalizeArrayResponse to allow for objects with the
     * structure { typePlurlized: [], meta: {} }
     * @see http://emberjs.com/api/data/classes/DS.JSONSerializer.html#method_normalizeArrayResponse
     */
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      var pluralized = this.keyForAttribute((0, _emberInflector.pluralize)(primaryModelClass.modelName));

      if (payload.hasOwnProperty(pluralized) || payload.hasOwnProperty(this.metaQueryKey)) {
        var hash = this._super(store, primaryModelClass, payload[pluralized] || payload[this.metaQueryKey], id, requestType);

        var meta = this.extractMeta(store, primaryModelClass, payload);

        if (meta) {
          hash.meta = meta;
        }

        return hash;
      } else {
        return this._super.apply(this, arguments);
      }
    },

    /**
     * Overrides the default extractMeta method to apply our property name transforms
     * @see https://github.com/emberjs/data/blob/v2.0.0/packages/ember-data/lib/serializers/json-serializer.js#L1216
     */
    extractMeta: function extractMeta(store, modelClass, payload) {
      if (payload && payload.hasOwnProperty(this.metaKey)) {
        var meta = payload[this.metaKey];
        delete payload[this.metaKey];
        return meta;
      }
    },

    /**
     * Overrides the default behavior (that changed in ed > 2.12)
     * where empty relationships are not encoded and instead forces relationships
     * to always be defined as an empty array.
     * @see https://emberjs.com/api/ember-data/3.1/classes/DS.JSONSerializer/methods/serializeHasMany?anchor=serializeHasMany
     */
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      this._super.apply(this, arguments);

      var key = this.keyForRelationship(relationship.key);

      if (json[key] === undefined) {
        json[key] = [];
      }
    }
  });

  _exports.default = _default;
});