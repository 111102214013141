define("sky-router-3/utils/map-by-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mapByArray;

  /**
   * Flattens an array by the property
   * This is essentialy a deep mapBy
   * @see https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays-in-javascript
   * @param  {Array} array    array to flatten
   * @param  {String} property property to map each sub array by
   * @return {Array}          the flatten deep mayBy
   */
  function mapByArray(array, property) {
    return array.reduce(function (flat, toFlatten) {
      return flat.concat(toFlatten.get(property).toArray());
    }, []);
  }
});