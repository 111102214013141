define("sky-router-3/pods/components/portal/operations/trips/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Mglm80m",
    "block": "{\"symbols\":[\"bar\"],\"statements\":[[10,\"div\"],[14,0,\"progress\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"progress-bar progress-bar-min-word position-type-\",[32,1,[\"positionType\",\"id\"]],\" text-center\"]]],[15,5,[32,1,[\"style\"]]],[12],[2,\"\\n            \"],[1,[32,1,[\"status\",\"label\"]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"seconds\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"em\"],[12],[2,\"(\"],[1,[30,[36,2],[[32,1,[\"seconds\"]]],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,3],[[30,[36,7],[[35,0,[\"isActive\"]],[35,0,[\"leg\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"progress-bar progress-bar-min-word progress-bar-remainder text-center\"],[12],[2,\"\\n\"],[6,[37,3],[[35,0,[\"leg\",\"remainingEnrouteSeconds\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,2],[[35,0,[\"leg\",\"remainingEnrouteSeconds\"]]],null]],[2,\" /\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0,[\"leg\",\"remainingDistanceCm\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,1],null,[[\"cm\"],[[35,0,[\"leg\",\"remainingDistanceCm\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"trip\",\"display-distance\",\"display-duration\",\"if\",\"bars\",\"-track-array\",\"each\",\"and\"]}",
    "moduleName": "sky-router-3/pods/components/portal/operations/trips/progress-bar/template.hbs"
  });

  _exports.default = _default;
});