define("sky-router-3/pods/components/portal/manage/device-profiles/flight-plans/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Does the component implementor want this component disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Array of settings
     * @type {Array<sky-router-3/models/asset-profile-set-revision-setting>}
     */
    settings: null,

    /**
     * Should all the inputs be disabled
     */
    disableInput: function () {
      return this.get('disabled') || !this.get('enabledSetting.valueBoolean');
    }.property('disabled', 'enabledSetting.valueBoolean'),

    /**
     * Boolean that enables showing or hiding of fields
     */
    showFlightRule: true,
    showCruisingAlt: true,
    showOrigin: true,
    showDistance: true,
    showDestination: true,
    showFuel: true,
    showPob: true,
    showEte: true,
    showDeparture: true,

    /**
     * actualsettings of the profile
     */
    enabledSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_enabled'),
    flightRuleSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_flightrule_enabled'),
    cruisingAltSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_cruisingalt_enabled'),
    originSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_origin_enabled'),
    destinationSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_destination_enabled'),
    fuelSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_fuel_enabled'),
    pobSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_pob_enabled'),
    eteSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_ete_enabled'),
    departureSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_departure_enabled'),
    distanceSetting: (0, _computedFindBy.default)('settings', 'key', 'flightplan_distance_enabled')
  });

  _exports.default = _default;
});