define("sky-router-3/adapters/application", ["exports", "@ember-data/adapter/rest", "sky-router-3/config/environment"], function (_exports, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rest.default.extend({
    namespace: _environment.default.API.namespace,
    session: Ember.inject.service(),

    /**
     * Handles responses from the server.
     * If a 401 is returned it kills the users session.
     * @see http://emberjs.com/api/data/classes/DS.RESTAdapter.html#method_handleResponse
     */
    handleResponse: function handleResponse(status
    /*, headers, payload, requestData */
    ) {
      if ([401, 402].indexOf(status) != -1 && this.get('session.isAuthenticated')) {
        this.get('session').invalidate();
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Takes the exceptions that are spit out by the API and makes them readable by ember.
     */
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      var error = {};
      error.status = status;
      error.headers = headers;
      error.payload = payload;
      return [error];
    },

    /**
     * Overrides the generate detailed message because its super broken in their implementation
     */
    generatedDetailedMessage: function generatedDetailedMessage(status, headers, payload, requestData) {
      headers["Content-Type"] = headers["content-type"];
      return this._super(status, headers, JSON.stringify(payload), requestData);
    },

    /**
    * Overrides the delete record request as we sometimes want to post data in DELETE requests.
    * This is allowed via the HTML 1.1 spec
    * See "https://github.com/emberjs/data/blob/master/addon/adapters/rest.js#L764" for default implementation
    */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'deleteRecord');
      return this.ajax(url, 'DELETE', {
        data: Ember.get(snapshot, 'adapterOptions.data')
      });
    }
  });

  _exports.default = _default;
});