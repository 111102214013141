define("sky-router-3/pods/portal/manage/device-parameters/assignment/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sortProperty', 'sortDesc', 'search'],
    sortProperty: 'asset.name',
    sortDesc: false,
    search: null,

    /**
     * Filtering
     */
    filteredAssignments: Ember.computed.filter('model.assignments', ['search', 'model.assignments.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('asset.name'), search) || (0, _stringSearch.default)(item.get('asset.registration'), search) || (0, _stringSearch.default)(item.get('parameterSetRevision.parameterSet.name'), search);
      }

      return true;
    }),

    /**
     * Sort functions
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),
    sortedAssignments: Ember.computed.sort('filteredAssignments', 'sortBy'),

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('sortedAssignments.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100],
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      }
    }
  });

  _exports.default = _default;
});