define("sky-router-3/pods/components/portal/track/map/info-box/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/pods/components/portal/track/map/lib/html-box"], function (_exports, _googleObjectMapping, _htmlBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component governs the tool-tip window that appears when the user hovers
   * or clicks on a marker.
   *
   * This is its own component as opposed to living inside the marker for
   * performance reasons as this object will not take up memory unless it needs to
   * be displayed.
   * @see https://google-maps-utility-library-v3.googlecode.com/svn/trunk/infobox/docs/reference.html
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    googlePropertyObject: Ember.computed.alias('infoBox'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'anchor',
      setFunc: 'setAnchor'
    }, {
      property: 'position',
      setFunc: 'setPosition'
    }],

    /**
     * The instance of the Google maps infoBox that defines this location
     * @type {google.maps.InfoBox}
     */
    infoBox: null,

    /**
     * Holds a reference to the Google map object that the
     * infoBox is attached to.
     */
    map: null,

    /**
     * The object associated with this infoBox that this
     * infoBox will use to get its position from. It will
     * become locked to that anchor.
     */
    anchor: null,

    /**
     * The Y offset that the box appears at relative to the anchor
     * @type {Number}
     */
    offsetY: 0,

    /**
     * Gets the html box to be used
     */
    getBox: function getBox() {
      return new _htmlBox.default({
        placement: 'BOTTOM_CENTER',
        offset: new google.maps.Size(0, 15 + this.get('offsetY')),
        visible: true
      });
    },

    /**
     * Initializes the Google Maps infoBox control.
     */
    init: function init() {
      this.set('infoBox', this.getBox());

      this._super.apply(this, arguments);
    },

    /**
     * Attaches the content to the created box.
     * Schedules the render of the box
     */
    didInsertElement: function didInsertElement() {
      this.infoBox.setContent(this.element.firstElementChild);
    },

    /**
     * Removes the listeners added above
     */
    willDestroyElement: function willDestroyElement() {
      this.element.appendChild(this.infoBox.getContent());
      this.infoBox.setMap(null);
    }
  });

  _exports.default = _default;
});