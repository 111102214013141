define("sky-router-3/utils/geoconversiontools/geoconstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var geoconstants = {
    /***************************************************
     * Universal getPI call - so all conversions start *
     * here.                                           *
     ***************************************************/
    getPI: function getPI() {
      return Math.PI;
    },
    getRadians: function getRadians(degrees) {
      return degrees * this.getPI() / 180;
    },
    getDegrees: function getDegrees(radians) {
      return radians * 180 / this.getPI();
    },
    getTMConstants: function getTMConstants() {
      return {
        PI: this.getPI(),
        TWO_PI: 2 * this.getPI(),
        PI_OVER_2: this.getPI() / 2,
        MAX_LAT: this.getPI() * 89.99 / 180,
        MAX_DELTA_LONG: this.getPI() * 90 / 180,
        MIN_SCALE_FACTOR: 0.3,
        MAX_SCALE_FACTOR: 3.0
      };
    },
    getUTMConstants: function getUTMConstants() {
      return {
        PI: this.getPI(),
        MIN_LAT: -80.5 * this.getPI() / 180.0,
        MAX_LAT: 84.5 * this.getPI() / 180.0,
        MAX_DELTA_LONG: this.getPI() * 90 / 180.0,
        MIN_EASTING: 100000,
        MAX_EASTING: 900000,
        MIN_NORTHING: 0,
        MAX_NORTHING: 10000000
      };
    },
    getPolarConstants: function getPolarConstants() {
      return {
        PI: this.getPI(),
        PI_OVER_2: this.getPI() / 2,
        TWO_PI: 2 * this.getPI(),
        PI_OVER_4: this.getPI() / 4
      };
    },
    getUPSConstants: function getUPSConstants() {
      return {
        PI: this.getPI(),
        PI_OVER_2: this.getPI() / 2.0e0,
        MAX_LAT: this.getPI() * 90 / 180.0,
        MAX_ORIGIN_LAT: 81.114528 * this.getPI() / 180.0,
        MIN_NORTH_LAT: 83.5 * this.getPI() / 180.0,
        MIN_SOUTH_LAT: -79.5 * this.getPI() / 180.0,
        MIN_EAST_NORTH: 0,
        MAX_EAST_NORTH: 4000000
      };
    },
    getMGRSConstants: function getMGRSConstants() {
      return {
        DEG_TO_RAD: this.getPI() / 180,
        RAD_TO_DEG: 180 / this.getPI(),
        LETTER_A: 0,
        LETTER_B: 1,
        LETTER_C: 2,
        LETTER_D: 3,
        LETTER_E: 4,
        LETTER_F: 5,
        LETTER_G: 6,
        LETTER_H: 7,
        LETTER_I: 8,
        LETTER_J: 9,
        LETTER_K: 10,
        LETTER_L: 11,
        LETTER_M: 12,
        LETTER_N: 13,
        LETTER_O: 14,
        LETTER_P: 15,
        LETTER_Q: 16,
        LETTER_R: 17,
        LETTER_S: 18,
        LETTER_T: 19,
        LETTER_U: 20,
        LETTER_V: 21,
        LETTER_W: 22,
        LETTER_X: 23,
        LETTER_Y: 24,
        LETTER_Z: 25,
        MGRS_LETTERS: 3,
        ONEHT: 100000.0e0,
        TWOMIL: 2000000.0e0,
        PI: this.getPI(),
        PI_OVER_2: this.getPI() / 2.0e0,
        MIN_EASTING: 100000,
        MAX_EASTING: 900000,
        MIN_NORTHING: 0,
        MAX_NORTHING: 10000000,
        MAX_PRECISION: 5,
        MIN_UTM_LAT: -80 * this.getPI() / 180.0,
        MAX_UTM_LAT: 84 * this.getPI() / 180.0,
        MIN_EAST_NORTH: 0,
        MAX_EAST_NORTH: 4000000,
        // Ellipsoid codes
        CLARKE_1866: "CC",
        CLARKE_1880: "CD",
        BESSEL_1841: "BR",
        BESSEL_1841_NAMIBIA: "BN"
      };
    }
  };
  var _default = geoconstants;
  _exports.default = _default;
});