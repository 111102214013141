define("sky-router-3/serializers/asset-compound-rule-revision", ["exports", "@ember-data/serializer/rest", "sky-router-3/serializers/application"], function (_exports, _rest, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      /**
       * Allows for the embedding of records going to the server.
       * @type {Object}
       */
      outputs: {
        embedded: 'always'
      },
      conditionGroups: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});