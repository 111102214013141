define("sky-router-3/pods/components/position-graph/component", ["exports", "sky-router-3/utils/convert-unit", "sky-router-3/utils/convert-date", "sky-router-3/models/asset-position-type"], function (_exports, _convertUnit, _convertDate, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Chart */

  /**
   * This helper takes an speed value in centimeters and turns it into the units the user
   * uses to view speed with. The format of the output is determined by the users profile.
   */
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    user: Ember.computed.alias('sessionAccount.user'),
    unit: null,
    asset: null,
    type: null,
    // either 'speed' or 'altitude'
    property: Ember.computed('type', function () {
      return this.type == 'speed' ? 'speedCms' : 'altitudeCentimeters';
    }),
    data: Ember.computed('unit.id', 'asset.id', 'positions.[]', 'asset.positionsSorted.[]', function () {
      var _this = this;

      return {
        labels: this.asset.get('positionsSorted').map(function (x) {
          return (0, _convertDate.default)(x.get('utc'), _this.user, {
            hideDate: true
          });
        }),
        datasets: [{
          fill: false,
          pointBackgroundColor: this.asset.get('positionsSorted').map(function (x) {
            return x.get('type.color') || Chart.defaults.global.defaultColor;
          }),
          pointRadius: this.asset.get('positionsSorted').map(function (x) {
            return _assetPositionType.POSITION_TYPES.isNormalType(x.belongsTo('type').id()) ? 0 : 3;
          }),
          // don't show if normal
          label: this.asset.get('name'),
          borderColor: this.asset.get('color'),
          data: this.asset.get('positionsSorted').map(function (pos) {
            return {
              y: (0, _convertUnit.default)(_this.unit, pos.get(_this.property), false),
              x: pos.get('utc'),
              pos: pos
            };
          })
        }]
      };
    }),
    options: Ember.computed('unit.name', function () {
      return {
        responsive: true,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{}],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.unit.get('name')
            }
          }]
        },
        elements: {
          line: {
            tension: 0 // disables bezier curves

          }
        },
        tooltips: {
          callbacks: {
            title: Ember.run.bind(this, this.getTitle),
            beforeBody: Ember.run.bind(this, this.getBeforeBody),
            label: Ember.run.bind(this, this.getLabel)
          }
        }
      };
    }),
    getTitle: function getTitle(tooltipItems, data) {
      var item = tooltipItems[0];
      var dataset = data.datasets[item.datasetIndex];
      var pos = dataset.data[item.index].pos;
      return (0, _convertDate.default)(pos.get('utc'), this.user);
    },
    getBeforeBody: function getBeforeBody(tooltipItems, data) {
      var item = tooltipItems[0];
      var dataset = data.datasets[item.datasetIndex];
      var pos = dataset.data[item.index].pos;
      return pos.get('description');
    },
    getLabel: function getLabel(tooltipItem, data) {
      var dataset = data.datasets[tooltipItem.datasetIndex];
      var pos = dataset.data[tooltipItem.index].pos;
      return (0, _convertUnit.default)(this.unit, pos.get(this.property));
    }
  });

  _exports.default = _default;
});