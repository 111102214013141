define("sky-router-3/pods/portal/operations/global-traveler/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['skip', 'take'],
    skip: 0,
    take: 10,
    takeSelections: [10, 20, 50],
    pages: Ember.computed('model.messages.meta', 'skip', 'take', function () {
      var meta = this.model.messages.get('meta');
      var pageCount = Math.ceil(meta.CountPossible / this.take);
      var result = [];

      for (var i = 0; i < pageCount; i++) {
        result.push({
          number: i,
          skip: i * this.take,
          isActive: i * this.take == this.skip
        });
      }

      return result;
    }),
    pageLastItemIndex: Ember.computed('model.messages.meta', function () {
      var meta = this.model.messages.get('meta');
      return meta.CountSkipped + meta.CountReturned;
    }),
    actions: {
      countChanged: function countChanged(val) {
        this.set('skip', 0);
        this.set('take', val);
      }
    }
  });

  _exports.default = _default;
});