define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-power-loss/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7aTTzSmO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,3],[\"parameter.rockseven.alertsPowerLoss.title\"],null]],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,3],[\"parameter.rockseven.alertsPowerLoss.help\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,4],[[35,1,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,2],null,[[\"checked\",\"disabled\"],[[35,1,[\"valueBoolean\"]],[35,0]]]]],[1,[30,[36,3],[\"parameter.misc.enableFeature\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"enabledSetting\",\"input-checkbox\",\"t\",\"if\",\"input-label\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-power-loss/template.hbs"
  });

  _exports.default = _default;
});