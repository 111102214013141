define("sky-router-3/pods/portal/operations/global-traveler/new/controller", ["exports", "sky-router-3/utils/lat-lng-converter", "ember-cp-validations"], function (_exports, _latLngConverter, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    assetsSelected: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.assetsSelected.[]']
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * Powers the assets on the map
     */
    assets: Ember.computed.mapBy('model.travelers', 'asset'),

    /**
     * The assets selected for sending.
     * This is happening here as opposed to the model as its more complicated to create sub records and manage them vs just do it here easy peasy
     */
    assetsSelected: [],

    /**
     * Called by map-by helper to convert from Coords to LatLngLiteral
     * @param {Object} coord 
     */
    coordsToLatLngLiteral: function coordsToLatLngLiteral(coord) {
      return {
        lat: coord.latitude,
        lng: coord.longitude
      };
    },

    /**
     * Polgons drawn by the user on the screen
     */
    drawnPolygons: [],
    actions: {
      drawingCompleted: function drawingCompleted(_ref) {
        var polygon = _ref.polygon;
        this.drawnPolygons.addObject(polygon);
        this.send('selectArea', polygon);
      },
      addTravelers: function addTravelers(assets) {
        this.assetsSelected.addObjects(assets);
      },
      selectArea: function selectArea(polygon) {
        this.assetsSelected.addObjects(this.model.travelers.filter(function (traveler) {
          var point = _latLngConverter.default.modelToGoogleLatLng(traveler.asset.get('positionLatest'));

          return google.maps.geometry.poly.containsLocation(point, polygon);
        }));
      },
      clearDrawnPolygons: function clearDrawnPolygons() {
        this.drawnPolygons.forEach(function (p) {
          return p.setMap(null);
        });
        this.set('drawnPolygons', []);
      },
      mapAction: function mapAction(map, action, args) {
        if (action == 'loaded') {
          // Center on all the assets visible and their positions
          var positions = this.assets.mapBy('positionLatest').filterBy('pointIsValid');

          var bounds = _latLngConverter.default.getBounds(positions);

          this.model.mapConfig.set('fitBounds', bounds);
        }
      }
    }
  });

  _exports.default = _default;
});