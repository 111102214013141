define("sky-router-3/models/asset-compound-rule", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the parameter set.
     * This is the only setting that is updateable.
     */
    name: (0, _model.attr)('string'),

    /**
     * The date the rule was created
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * Revisions associated with this rule
     * @type {sky-router-3/models/asset-compound-rule-revision}
     */
    revisions: (0, _model.hasMany)('asset-compound-rule-revision'),

    /**
     * The current revision associated with this rule.
     * This represents the rules 'actual' settings.
     */
    revisionLatest: (0, _model.belongsTo)('asset-compound-rule-revision'),

    /**
     * Indicates that the set cannot be updated by the current user.
     * @type {Boolean}
     */
    isReadOnly: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});