define("sky-router-3/pods/components/portal/manage/device-profiles/phone-numbers/component", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'speeddial1_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial2_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial3_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial4_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial5_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial6_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial7_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial8_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial9_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })],
    'speeddial0_number.value': [(0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^0{2}/,
      messageKey: 'errors.startWith',
      startsWith: '00'
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * The passed in settings object
     * @type {Array<sky-router-3/models/asset-profile-set-revision-setting>}
     */
    settings: null,

    /**
     * Is this form disabled from the outside
     * @type {Boolean}
     */
    disabled: false,

    /**
     * The settings
     * @type {[type]}
     */
    speeddial1_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial1_number'),
    speeddial1_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial1_text'),
    speeddial2_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial2_number'),
    speeddial2_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial2_text'),
    speeddial3_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial3_number'),
    speeddial3_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial3_text'),
    speeddial4_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial4_number'),
    speeddial4_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial4_text'),
    speeddial5_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial5_number'),
    speeddial5_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial5_text'),
    speeddial6_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial6_number'),
    speeddial6_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial6_text'),
    speeddial7_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial7_number'),
    speeddial7_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial7_text'),
    speeddial8_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial8_number'),
    speeddial8_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial8_text'),
    speeddial9_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial9_number'),
    speeddial9_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial9_text'),
    speeddial0_number: (0, _computedFindBy.default)('settings', 'key', 'speeddial0_number'),
    speeddial0_text: (0, _computedFindBy.default)('settings', 'key', 'speeddial0_text'),

    /**
     * Converts the property to a workable computed property
     */
    freedial_enabled: (0, _computedFindBy.default)('settings', 'key', 'freedial_enabled'),
    speeddial_enabled: (0, _computedFindBy.default)('settings', 'key', 'speeddial_enabled'),

    /**
     * Marks the form as disabled if the feature is disabled or if the
     * component is forced to be disabled.
     * @return {boolean}
     */
    disableInput: function () {
      return this.get('disabled') || !this.get('speeddial_enabled.valueBoolean');
    }.property('speeddial_enabled.valueBoolean', 'disabled')
  });

  _exports.default = _default;
});