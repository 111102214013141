define("sky-router-3/models/asset", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Main model that describes a device that communicates and
   * belongs to the system.
   */
  var model = _model.default.extend({
    /**
     * Name of the asset that is suitable for display
     * to the user.
     */
    name: (0, _model.attr)('string'),

    /**
     * Secondary name field, some people use this for tail numbers
     * others use it for license plate.
     */
    registration: (0, _model.attr)('string'),

    /**
     * Groups the asset belongs to. There may be many
     * or there may be none.
     */
    groups: (0, _model.hasMany)('group', {
      async: true
    }),

    /**
     * Contracts the asset belongs to. There may be many
     * or there may be none.
     */
    contracts: (0, _model.hasMany)('contract', {
      async: true
    }),

    /**
     * The array of trip start trigger events for the asset.
     */
    tripStartEvents: (0, _model.hasMany)('alert-trigger-event', {
      async: false
    }),

    /**
     * The array of trip stop trigger events for the asset.
     */
    tripStopEvents: (0, _model.hasMany)('alert-trigger-event', {
      async: false
    }),

    /**
     * Groups that are active
     * @return {sky-router-3/models/group}
     */
    activeGroups: Ember.computed.filterBy('groups', 'isActive', true),

    /**
     * IMEI of the asset, this is essentially its identifier
     * in the system. It is used to lookup devices and communicate
     * with them.
     */
    imei: (0, _model.attr)('number'),

    /**
     * An array of locations on a globe this a asset has been at.
     *
     * WARNING: There is no guarantee that this is in any order
     */
    positions: (0, _model.hasMany)('asset-position', {
      async: false
    }),

    /**
     * Orders the positions in the correct format for displaying
     * with oldest dates closest to 0.
     * @return {sky-router-3/models/asset-position[]}  ordered array of asset positions
     */
    positionsSorted: Ember.computed.sort('positions', 'positionsSortedBy'),
    positionsSortedBy: ['utc:asc'],

    /**
     * Indicates the latest position of the asset.
     * This walks back from the latest position
     */
    positionLatest: Ember.computed('positionsSorted.[]', function () {
      for (var i = this.positionsSorted.length; i > 0; i--) {
        var position = this.positionsSorted.objectAt(i - 1);

        if (position.get('pointIsValid')) {
          return position;
        }
      }

      return null;
    }),

    /**
     * When true all the assets breadcrumbs should be connected via a line
     */
    connectBreadcrumbs: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * CSS Hex color that is used whenever the asset is to be displayed.
     */
    color: (0, _model.attr)('string', {
      defaultValue: '#800517'
    }),

    /**
     * The vehicle type of the asset. Make and model of vehicle.
     */
    type: (0, _model.belongsTo)('asset-type', {
      async: true
    }),

    /**
     * The category of the type asset. This basically repeats the type.category
     * mapping but avoids the need to load the `type` record.
     */
    category: (0, _model.belongsTo)('asset-category', {
      async: true
    }),

    /**
     * The product record for this asset.
     */
    product: (0, _model.belongsTo)('asset-product', {
      async: true
    }),

    /**
     * The icon associated with this asset
     */
    icon: (0, _model.belongsTo)('asset-icon'),

    /**
     * Alias property for easier access and to prevent deprecation warnings with multi deep @each
     */
    supportsMessages: Ember.computed.alias('product.supportMessages'),

    /**
     * The BSN serial for a device.
     */
    serial: (0, _model.attr)('string'),

    /**
     * If the unit has an electronic serial number it wil be here.
     * @note most units do not have one of these.
     */
    esn: (0, _model.attr)('string'),

    /**
     * Presents the identifier that is presented to the user.
     * This is either the ESN (if present) or the IMEI.
     */
    identifier: Ember.computed.or('esn', 'imei'),

    /**
     * Serial number for the embedded SIM card if the device has one.
     */
    simSerial: (0, _model.attr)('string'),

    /**
     * A user defined comment for the device
     */
    comment: (0, _model.attr)('string'),

    /**
     * The firmware version reported by the device.
     * @note this only occurs during power on and only for some units.
     */
    reportedFirmware: (0, _model.attr)('string'),
    reportedFirmwareUpdateDateUtc: (0, _model.attr)('date'),

    /**
     * A custom field containing key-value pairs
     */
    properties: (0, _model.attr)('ember-object-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * A phone number that can be used to contact the device.
     */
    phoneNumber: (0, _model.attr)('string'),

    /**
     * The name of the driver of the device.
     */
    driverName: (0, _model.attr)('string'),

    /**
     * The id of the driver of the device.
     */
    driverId: (0, _model.attr)('string'),

    /**
     * The email address for the asset. This is used to send messages to and
     * from the device. All devices have an address but not all devices are
     * capable of receiving messages. See the product.supportMessages.
     */
    email: (0, _model.attr)('string'),

    /**
     * The expiration of the warranty for the device
     */
    warrantyExpirationDate: (0, _model.attr)('date'),

    /**
     * How long the device has to go without sending a report to skyrouter before
     * the device is considered 'inactive'
     * Negative values indicate that the timer is disabled
     */
    inactiveTimerMinutes: (0, _model.attr)('number'),

    /**
     * Indicates that the reverse 'inactive' that occurs as soon as an asset becomes
     * live after it was innactive is enabled. This requires `inactiveTimerMinutes` enabled.
     */
    activeEventEnabled: (0, _model.attr)('boolean'),

    /**
     * Photo of the asset in question. This is optional.
     */
    photoSrc: (0, _model.attr)('string'),

    /**
     *  ExtremeHash1. (This is the code that device displays)
     */
    extremeHash1: (0, _model.attr)('string'),

    /**
     * ExtremeHash2. (This is the code that the customer will enter during registration process)
     */
    extremeHash2: (0, _model.attr)('string'),

    /**
     * Comcenter - Manual Position Latitude in degrees
     */
    manualPosLatitude: (0, _model.attr)('number'),

    /**
     * Comcenter - Manual Position Longitude in degrees
     */
    manualPosLongitude: (0, _model.attr)('number'),

    /**
     * Comcenter - ManualPosition Diameter in Km
     */
    manualPosGeofenceDiameterInKm: (0, _model.attr)('number'),

    /**
     * Vehicle self reported odometer (if supported)
     */
    odometerMeters: (0, _model.attr)('number'),

    /**
     * The last date that the odometer value was updated via message from vehicle
     */
    odometerUpdateDateUtc: (0, _model.attr)('date'),

    /**
     * HasMany relationship that holds references to flight plans that are
     * associated with this asset. This relationship is not returned when assets
     * are pulled but is returned when the flight plans themselves are queried.
     */
    flightPlans: (0, _model.hasMany)('flight-plan', {
      async: false
    }),

    /**
     * Gets the current flight plan from the flights plans the asset has.
     * This is any flight plan that is currently marked active. If no flight
     * plan is currently marked as active then it returns the plan marked as pending
     * if nothing is marked as pending then it returns the soonest flight plan
     * to UTC now.
     * @return {sky-router-3/models/flight-plan}
     */
    currentFlightPlan: function () {
      var flightPlans = this.get('flightPlans');

      if (flightPlans.get('length') > 0) {
        // Try and get the active plan because there can only be 1 active plan
        // at a time.
        var active = flightPlans.find(function (item) {
          return item.belongsTo('status').id() === '1';
        });

        if (active) {
          return active;
        } // Try and get the pending plan which is the oldes one marked as
        // pending for this asset.


        var filtered = flightPlans.filter(function (item) {
          return item.belongsTo('status').id() === '4';
        });
        var sorted = filtered.sortBy('startDate');

        if (sorted) {
          return sorted.get('firstObject');
        } // Just get the latest plan at this point


        return flightPlans.sortBy('startDate').get('lastObject');
      }
    }.property('flightPlans.[]', 'flightPlans.@each.status', 'flightPlans.@each.startDate'),

    /**
     * Gets the current active flight plan if there is one.
     * @return {sky-router-3/models/flight-plan}
     */
    activeFlightPlan: function () {
      return this.get('flightPlans').findBy('status', 1);
    }.property('flightPlans.[]', 'flightPlans.@each.status'),

    /**
     * This is a reflexive relationship that is populated by EmberData when
     * geofence records are loaded. This property is not returned when this
     * model is loaded from the server and is dependent on geofence models being
     * loaded.
     */
    geofences: (0, _model.hasMany)('geofence-asset', {
      async: false
    }),

    /**
     * This is reflexive relationship that is populated by EmberData when
     * voicePlan records are loaded. This property is not returned when this
     * model is loaded from the server and is dependent on voicePlan models being
     * loaded.
     */
    voicePlan: (0, _model.belongsTo)('voice-plan', {
      async: false
    }),

    /**
     * This is reflexive relationship that is populated by EmberData when
     * voicePlan records are loaded. This property is not returned when this
     * model is loaded from the server and is dependent on voicePlan models being
     * loaded.
     */
    servicePlan: (0, _model.belongsTo)('service-plan', {
      async: false
    }),

    /**
     * Gets the fullname of the asset in the format of name(registration)
     */
    fullName: function () {
      var name = this.get('name');
      var reg = this.get('registration');

      if (reg) {
        return "".concat(name, " (").concat(reg, ")");
      }

      return name;
    }.property('name', 'registration'),

    /**
     * This is reflexive relationship that is populated by EmberData when
     * asset-compound-rule-asset records are loaded. This property is not returned when this
     * model is loaded from the server and is dependent on asset-compound-rule-asset models being
     * loaded.
     */
    compoundRules: (0, _model.hasMany)('asset-compound-rule-asset'),

    /**
     * Indicates how often the device is supposed to report in seconds under
     * normal trip conditions. This is a user enterable field that may or may not
     * be populated. It is used for timers, events, and analytics.
     */
    expectedReportingIntervalSeconds: (0, _model.attr)('number', {
      defaultValue: 120
    }),

    /**
     * When enabled the system watches for when the device is supposed to report
     * and if it doesn't report as expected it triggers a report.
     * Negative values indicate disabled.
     */
    missedReportingIntervalMinutes: (0, _model.attr)('number', {
      defaultValue: -6
    }),
    missedReportingIntervalWarnMinutes: (0, _model.attr)('number', {
      defaultValue: -9
    }),
    missedReportingIntervalDangerMinutes: (0, _model.attr)('number', {
      defaultValue: -12
    }),

    /**
     * The satellite service provider for the asset
     */
    serviceProvider: (0, _model.attr)('string'),
    serviceProviderContactName: (0, _model.attr)('string'),
    serviceProviderContactPhone: (0, _model.attr)('string'),
    serviceProviderContactEmail: (0, _model.attr)('string'),

    /**
     * Async relationship that is not returned when the asset is returned but
     * loaded by looking up latest trips
     */
    trips: (0, _model.hasMany)('trip', {
      async: false
    }),
    currentTrip: Ember.computed('trips.@each.startDateUtc', function () {
      return this.trips.filterBy('isRecent').sortBy('startDateUtc').lastObject;
    })
  });

  var _default = model;
  _exports.default = _default;
});