define("sky-router-3/models/authy-user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    confirmed: (0, _model.attr)('boolean'),
    countryCode: (0, _model.attr)('number'),
    createdDateUtc: (0, _model.attr)('date'),
    phoneNumber: (0, _model.attr)('string'),
    twoFactorFrequency: (0, _model.attr)('number')
  });

  _exports.default = _default;
});