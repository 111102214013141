define("sky-router-3/pods/portal/operations/burst-services/messenger/controller", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map", "sky-router-3/utils/filter-by-in-array", "ember-cp-validations", "sky-router-3/utils/constants", "moment"], function (_exports, _create, _stateTrackMap, _filterByInArray, _emberCpValidations, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EXPIRATION_TYPE = _exports.SCHEDULE_TYPE = void 0;
  var SCHEDULE_TYPE = {
    NOW: 1,
    FUTURE: 2,
    DELAYDED: 3
  };
  _exports.SCHEDULE_TYPE = SCHEDULE_TYPE;
  var EXPIRATION_TYPE = {
    DATE: 1,
    DURATION: 2
  };
  _exports.EXPIRATION_TYPE = EXPIRATION_TYPE;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    burstServiceSeleted: (0, _emberCpValidations.validator)('presence', true),
    abstract: (0, _emberCpValidations.validator)('presence', true),
    body: (0, _emberCpValidations.validator)('presence', true),
    timeNowDurationSeconds: (0, _emberCpValidations.validator)('number', {
      gte: 30,
      allowString: true,
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.greaterThanOrEqualTo', {
          gte: '30'
        });
      }
    }),
    timeNowRepeatCount: (0, _emberCpValidations.validator)('number', {
      gte: 1,
      allowString: true,
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.greaterThanOrEqualTo', {
          gte: '1'
        });
      }
    }),
    timeNowExpireSeconds: (0, _emberCpValidations.validator)('number', {
      gte: 30,
      allowString: true,
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.greaterThanOrEqualTo', {
          gte: '30'
        });
      }
    }),
    timeNowStartDateObject: (0, _emberCpValidations.validator)('inline', {
      validate: function validate(date) {
        var inputDate = (0, _moment.default)(date);
        var maxDate = (0, _moment.default)().add(+8, 'days');

        if (inputDate.isAfter(maxDate)) {
          var intl = Ember.getOwner(this).lookup('service:intl');
          return intl.t('burstServices.messenger.startDateError', {
            value: '8'
          });
          ;
        }

        return true;
      }
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    geoAreaService: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    enableScheduling: false,
    mapConfig: Ember.computed(function () {
      return (0, _create.default)(_stateTrackMap.default);
    }),
    abstract: null,
    body: null,
    burstServiceSeleted: null,
    bcas: [],
    bcasSelected: (0, _filterByInArray.default)('model.bcas', 'id', 'bcas'),
    // bcasList: computedCsv('events'),
    geoAreaGroups: [],
    geoAreaGroupsSelected: (0, _filterByInArray.default)('model.geoAreaGroups', 'id', 'geoAreaGroups'),
    //geoAreasGroupsList: computedCsv('events'),
    geoAreasToDisplay: Ember.computed('geoAreaGroupsSelected', {
      get: function get() {
        var geoAreasGroups = this.get('geoAreaGroupsSelected');
        var geoAreas = Ember.A();
        geoAreasGroups.forEach(function (group) {
          group.geoAreas.forEach(function (geoArea) {
            geoAreas.pushObject(geoArea);
          });
        });
        this.geoAreaService.fitMapToGeoAreas(geoAreas, this.model.mapConfig);
        return geoAreas;
      }
    }),
    // Options
    options: {},
    timeNowDurationSeconds: null,
    timeNowExpireSeconds: null,
    // useTimeNow: computed('useTimeNowExpiration','useTimeNowRepeat', function () {
    //     return this.useTimeNowExpiration || this.useTimeNowRepeat ;
    //  }),
    isNow: Ember.computed('enableScheduling', 'scheduleTimeType', function () {
      return this.enableScheduling && this.scheduleTimeType == SCHEDULE_TYPE.NOW;
    }),
    isScheduledDate: Ember.computed('enableScheduling', 'scheduleTimeType', function () {
      return this.enableScheduling && this.scheduleTimeType == SCHEDULE_TYPE.FUTURE;
    }),
    useTimeNowExpiration: false,
    useTimeNowRepeat: false,
    timeNowRepeatCount: null,
    disableTimeNowExactDate: Ember.computed('timeNowExpirationType', 'useTimeNowExpiration', function () {
      return !this.useTimeNowExpiration || this.timeNowExpirationType != 1;
    }),
    disableTimeNowExpirationDuration: Ember.computed('timeNowExpirationType', 'useTimeNowExpiration', function () {
      return !this.useTimeNowExpiration || this.timeNowExpirationType != 2;
    }),
    timeNowExpirationType: EXPIRATION_TYPE.DATE,
    scheduleTimeType: SCHEDULE_TYPE.NOW,
    timeNowExactDate: null,
    timeNowExactDateObject: Ember.computed('timeNowExactDate', {
      get: function get() {
        var date = (0, _moment.default)(this.timeNowExactDate);

        if (!date.isValid()) {
          date = (0, _moment.default)();
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('timeNowExactDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeNowStartDate: null,
    timeNowStartDateObject: Ember.computed('timeNowStartDate', {
      get: function get() {
        var date = (0, _moment.default)(this.timeNowStartDate);

        if (!date.isValid()) {
          date = (0, _moment.default)();
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('timeNowStartDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeNowMaxExactDate: null,
    timeNowMaxExactDateObject: Ember.computed('timeNowMaxExactDate', {
      get: function get() {
        var date = (0, _moment.default)(this.timeNowMaxExactDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().add(+8, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('timeNowMaxExactDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZoneExact: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),
    actions: {
      selectBcas: function selectBcas(list, selected, items) {
        var ids = items.mapBy('id');

        if (selected) {
          list.addObjects(ids);
        } else {
          list.removeObjects(ids);
        }
      },
      toggleScheduling: function toggleScheduling() {
        if (!this.enableScheduling) {
          this.set('timeNowExpirationType', EXPIRATION_TYPE.DATE);
          this.set('scheduleTimeType', SCHEDULE_TYPE.NOW);
          this.set('useTimeNowRepeat', false);
          this.set('useTimeNowExpiration', false);
          this.set('timeNowDurationSeconds', null);
          this.set('timeNowRepeatCount', null);
          this.set('timeNowExpireSeconds', null);
          return;
        }
      },
      toggleUseTimeNowRepeat: function toggleUseTimeNowRepeat() {
        if (!this.useTimeNowRepeat) {
          this.set('timeNowDurationSeconds', null);
          this.set('timeNowRepeatCount', null);
        } else {
          this.set('timeNowDurationSeconds', 0);
          this.set('timeNowRepeatCount', 0);
        }
      },
      toggleUseTimeNowExpiration: function toggleUseTimeNowExpiration() {
        if (this.timeNowExpirationType == EXPIRATION_TYPE.DURATION) {
          if (!this.useTimeNowExpiration) {
            this.set('timeNowExpireSeconds', null);
          } else {
            this.set('timeNowExpireSeconds', 0);
          }
        } else if (this.timeNowExpirationType == EXPIRATION_TYPE.DATE) {
          this.set('timeNowExpireSeconds', null);
        }
      },
      selectGeoAreaGroup: function selectGeoAreaGroup(list, selected, items) {
        var ids = items.mapBy('id');

        if (selected) {
          list.addObjects(ids);
        } else {
          list.removeObjects(ids);
        }
      },
      changeBurstService: function changeBurstService(selected) {
        this.set('burstServiceSeleted', selected);
      }
    }
  });

  _exports.default = _default;
});