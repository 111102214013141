define("sky-router-3/helpers/display-date", ["exports", "sky-router-3/utils/convert-date"], function (_exports, _convertDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Takes a date object and displays the date in a format the user can understand based on their locale
   * It also takes in several options on how the date will be transformed to be shown to the user.
   *
   * All dates encountered are assumed to be UTC. If they represent another timezone use the `isTimeZone`
   * option.
   *
   * @see http://momentjs.com/docs
   * @see https://github.com/stefanpenner/ember-moment/blob/master/addon/helpers/moment.js
   *
   * @param {date} - the initial date object to translate
   * @param {boolean} showUTC     - The UTC offset of the date will be show instead of the user defined offset.
   * @param {string} format       - Overrides the default formatting based on the users locale setting.
   * @param {boolean} hideSeconds - When flipped to true this will not show the seconds in the formatted time.
   * @param {boolean} fromNow     - Indicates that instead of showing the static date it should display a phrase that
   *                                  indicates how far away from the current date it is.
   * @param {date} fromDate       - The date at which to calculate the time different and show the user.
   * @param {boolean} calendar    - Show the date based on a calendar style display from the current date
   * @param {boolean} calendarFrom- The date the calendar calculation will reference off of.
   * @param {boolean} hideTime    - Will the time be hidden from the output
   * @param {int} timeZone        - Use the specified timezone instead of the user's.
   */
  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service(),
    compute: function compute(params, hash) {
      // Validate
      console.assert(params.length > 0, 'An argument must be supplied'); // Return from helper function

      return (0, _convertDate.default)(params[0], this.sessionAccount.user, hash);
    },

    /**
     * Watches for changes to the users time settings and recomputes
     */
    userPropertiesChanged: function () {
      this.recompute();
    }.observes('sessionAccount.user.timeZone', 'sessionAccount.user.timeFormat')
  });

  _exports.default = _default;
});