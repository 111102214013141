define("sky-router-3/pods/components/portal/track/map/marker/marker-point/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a marker that is used to display a point
   * @example {{portal/track/map/marker/marker-point point=point map=map }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    tagName: '',
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('marker'),
    googlePropertyMap: [{
      event: 'position_changed',
      property: 'point',
      setFunc: 'setPosition',
      getFunc: 'getPosition'
    }, {
      property: 'draggable',
      setFunc: 'setDraggable'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      event: 'drag',
      eFunc: 'ondrag'
    }, {
      property: 'icon',
      setFunc: 'setIcon'
    }, {
      event: 'mouseover',
      eFunc: '_markerMouseOver'
    }, {
      event: 'mouseout',
      eFunc: '_markerMouseOut'
    }, {
      event: 'click',
      eFunc: 'onClick'
    }],

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * The instance of the Google maps marker that defines this location
     * @type {google.maps.Marker}
     */
    marker: null,

    /**
     * The point item that this marker runs off of
     */
    point: null,

    /**
     * Indicates that the marker is draggable.
     * @type {Boolean}
     */
    draggable: false,

    /**
     * Action fired when the point is clicked
     */
    onClick: function onClick() {},
    onMouseOver: function onMouseOver() {},
    onMouseOut: function onMouseOut() {},
    ondrag: function ondrag() {},

    /**
     * Indicates if the marker is currently being hovered over
     * @type {Boolean}
     */
    hovered: false,

    /**
     * Sets the marker in hover-mode if the marker is not behind an open infoBox
     * @private
     */
    _markerMouseOver: function _markerMouseOver(marker, mouseEvent) {
      this.set('hovered', true);
      this.onMouseOver({
        latLng: mouseEvent.latLng
      });
    },

    /**
     * Starts a timer to close turn off the marker hovered mode.
     * @private
     */
    _markerMouseOut: function _markerMouseOut(marker, mouseEvent) {
      this.set('hovered', false);
      this.onMouseOut({
        latLng: mouseEvent.latLng
      });
    },

    /**
     * Get the icon for the location to use
     * @return {google.maps.icon} [description]
     */
    iconUrl: undefined,
    iconSize: 16,
    icon: function () {
      if (this.iconUrl) {
        return {
          url: this.iconUrl,
          anchor: new google.maps.Point(this.iconSize / 2, this.iconSize / 2),
          scaledSize: new google.maps.Size(this.iconSize, this.iconSize),
          size: new google.maps.Size(this.iconSize, this.iconSize)
        };
      }
    }.property('iconUrl', 'iconSize'),

    /**
     * Initializes the marker for this point and after the render is complete.
     */
    init: function init() {
      var marker = new google.maps.Marker();
      this.set('marker', marker);

      this._super.apply(this, arguments);
    },

    /**
     * Removes the marker from the map and destroys it.
     */
    willDestroyElement: function willDestroyElement() {
      this.marker.setMap(null);
    }
  });

  _exports.default = _default;
});