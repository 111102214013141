define("sky-router-3/models/burst-service-message-schedule-now", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    /**
     * now
     */
    now: (0, _model.belongsTo)('burst-service-message-schedule-now'),

    /**
     * Servie Code (this is the name register with Iridium SPNet)
     */
    code: (0, _model.attr)('string'),

    /**
     * Description
     */
    serviceDescription: (0, _model.attr)('string')
  });

  _exports.default = _default;
});