define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-3/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model.relationalOperator', 2);
    }
  });

  _exports.default = _default;
});