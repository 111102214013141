define("sky-router-3/utils/geoconversiontools/gars-conversions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertGeodeticToGARS = convertGeodeticToGARS;
  _exports.convertGARStoGeodetic = convertGARStoGeodetic;
  var ALPHA_BANDS = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  var ALPHA_BANDS_LENGTH = ALPHA_BANDS.length; // These are confusing until you remember that index are 0 based
  // And the first [] is the longitude from W -> E
  // And the second [] is the latitude from S -> N (even though it is represented as N ->S on charts)

  var QUADRANT_MATRIX = [['3', '1'], ['4', '2']];
  var AREA_MATRIX = [['7', '4', '1'], ['8', '5', '2'], ['9', '6', '3']]; // The order of direction is reversed when moving from coordinates back to GARS

  var QUADRANT_MATRIX_REVERSE = [['3', '4'], ['1', '2']];
  var AREA_MATRIX_REVERSE = [['7', '8', '9'], ['4', '5', '6'], ['1', '2', '3']]; // KEYPAD is a 3x3 grid inside 5x5, since that doesn't divide, don't hard code it.

  var KEYPAD_DIVISOR = 5 / 3;
  /**
   * Converts Lat/Lng to GARS
   * @see https://earth-info.nga.mil/index.php?dir=coordsys&action=coordsys#tab_gars
   * @param {number} latitiude 
   * @param {number} longitude 
   * @returns {string} GARS formatted string
   */

  function convertGeodeticToGARS(latitiude, longitude) {
    var gars = ""; //console.log("Input", longitude, latitiude);
    // GARS runs from longitude 180 W -> E & latitude 90 S -> N so make it positive
    // And also convert it to minutes

    var working_lng = parseInt((longitude + 180) * 60);
    var working_lat = parseInt((latitiude + 90) * 60); //console.log('Minutes', working_lng, working_lat);
    // Get the 30x30 cell (1 to 720)

    var lng_30x30 = parseInt(working_lng / 30) + 1; // starts a 1

    var lng_30x30_remainder = working_lng % 30;
    var lat_30x30 = parseInt(working_lat / 30);
    var lat_30x30_remainder = working_lat % 30; //console.log('30x30', lng_30x30, lat_30x30);
    //console.log('30x30 Remainder', lng_30x30_remainder, lat_30x30_remainder);

    gars += _zeroPad(lng_30x30, 3) + _numericToAlpha(lat_30x30); // Get the 15x15 quadrant (1 to 4)

    var lng_15x15 = parseInt(lng_30x30_remainder / 15);
    var lng_15x15_remainder = lng_30x30_remainder % 15;
    var lat_15x15 = parseInt(lat_30x30_remainder / 15);
    var lat_15x15_remainder = lat_30x30_remainder % 15; //console.log('15x15', lng_15x15, lat_15x15);
    //console.log('15x15 Remainder', lng_15x15_remainder, lat_15x15_remainder);

    gars += QUADRANT_MATRIX[lng_15x15][lat_15x15]; // Get the 5x5 cell 

    var lng_5x5 = parseInt(lng_15x15_remainder / 5);
    var lng_5x5_remainder = lng_15x15_remainder % 5;
    var lat_5x5 = parseInt(lat_15x15_remainder / 5);
    var lat_5x5_remainder = lat_15x15_remainder % 5;
    gars += AREA_MATRIX[lng_5x5][lat_5x5]; // Get the KEYPAD cell 3x3 grid inside of 5x5 cell

    var lng_KEYPAD = Math.round(lng_5x5_remainder / KEYPAD_DIVISOR);
    var lat_KEYPAD = Math.round(lat_5x5_remainder / KEYPAD_DIVISOR);
    gars += "-".concat(AREA_MATRIX[lng_KEYPAD][lat_KEYPAD]); //console.log('LATLNG to GARS', latitiude, longitude, gars);

    return gars;
  }

  function convertGARStoGeodetic(gars) {
    var bound_minutes = {
      sw: {
        lat: -90,
        lng: -180
      },
      ne: {
        lat: 90,
        lng: 180
      },
      error: 'Invalid GARS Value'
    };

    if (!/^\d{1,3}[ABCDEFGHJKLMNPQRSTUVWXYZ]{2}?\d?\d?-?\d?$/.test(gars)) {
      return bound_minutes;
    }

    var lng_string = parseInt(gars.substring(0, 3));
    var lat_string = gars.substring(3, 5);

    if (lng_string > 720) {
      return bound_minutes;
    }

    var lng_30minutes = (lng_string - 1) * 30;
    var lat_30minutes = _alphaToNumeric(lat_string) * 30; //console.log("input", gars, lng_string, lat_string);
    //console.log("30x30", lng_30minutes, lat_30minutes);

    bound_minutes.sw = {
      lat: lat_30minutes,
      lng: lng_30minutes
    };
    bound_minutes.ne = {
      lat: lat_30minutes + 30,
      lng: lng_30minutes + 30
    }; // If Quadrant is specified

    if (gars.length >= 6) {
      var quadrant = gars[5];

      if (quadrant > 4 || quadrant == 0) {
        return bound_minutes;
      }

      var quadrant_location = _findMatrixIndex(quadrant, QUADRANT_MATRIX_REVERSE); //console.log(quadrant, quadrant_location);


      bound_minutes.sw.lat += quadrant_location.row * 15;
      bound_minutes.sw.lng += quadrant_location.column * 15;
      bound_minutes.ne.lat = bound_minutes.sw.lat + 15;
      bound_minutes.ne.lng = bound_minutes.sw.lng + 15;
    } // If Area is specified 


    if (gars.length >= 7) {
      var area = gars[6];

      if (area == 0) {
        return bound_minutes;
      }

      var area_location = _findMatrixIndex(area, AREA_MATRIX_REVERSE); //console.log(area, area_location);


      bound_minutes.sw.lat += area_location.row * 5;
      bound_minutes.sw.lng += area_location.column * 5;
      bound_minutes.ne.lat = bound_minutes.sw.lat + 5;
      bound_minutes.ne.lng = bound_minutes.sw.lng + 5;
    } // If KEYPAD is specified


    if (gars.length >= 9) {
      var keypad = gars[8];

      var keypad_location = _findMatrixIndex(keypad, AREA_MATRIX_REVERSE);

      bound_minutes.sw.lat += keypad_location.row * KEYPAD_DIVISOR;
      bound_minutes.sw.lng += keypad_location.column * KEYPAD_DIVISOR;
      bound_minutes.ne.lat = bound_minutes.sw.lat + KEYPAD_DIVISOR;
      bound_minutes.ne.lng = bound_minutes.sw.lng + KEYPAD_DIVISOR;
    } // Convert from minutes back to degrees


    return {
      sw: {
        lat: bound_minutes.sw.lat / 60 - 90,
        lng: bound_minutes.sw.lng / 60 - 180
      },
      ne: {
        lat: bound_minutes.ne.lat / 60 - 90,
        lng: bound_minutes.ne.lng / 60 - 180
      }
    };
  }
  /**
   * Converts a number into a ALPHA designator
   * There can only be max of two letters so we can make shortcuts
   * @param {number} number 
   * @returns 
   */


  function _numericToAlpha(number) {
    var leading_index = parseInt(number / ALPHA_BANDS_LENGTH);
    var trailing_index = number % ALPHA_BANDS_LENGTH;
    return ALPHA_BANDS[leading_index] + ALPHA_BANDS[trailing_index];
  }
  /**
   * Converts a ALPHA string of AA -> QZ back into number that generated it.
   * @param {String} alpha 
   * @returns 
   */


  function _alphaToNumeric(alpha) {
    var first_index = ALPHA_BANDS.indexOf(alpha[0]);
    var second_index = ALPHA_BANDS.indexOf(alpha[1]);
    return first_index * ALPHA_BANDS_LENGTH + second_index;
  }

  function _zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  function _findMatrixIndex(item, matrix) {
    for (var i = 0; i < matrix.length; i++) {
      var index_column = matrix[i].indexOf(item);

      if (index_column > -1) {
        return {
          row: i,
          column: index_column
        };
      }
    }

    return {
      row: -1,
      column: -1
    };
  }
});