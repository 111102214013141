define("sky-router-3/pods/portal/manage/global-traveler/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozAWT6IO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"globalTraveler.title\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"globalTraveler.tagline\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.global-traveler.assets\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"globalTraveler.travelers.title\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.global-traveler.areas\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"globalTraveler.areas.title\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.global-traveler.contacts\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"globalTraveler.contacts.title\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.global-traveler.orgs\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"globalTraveler.orgs.title\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.operations.global-traveler\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"globalTraveler.message.title\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"active-link\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/global-traveler/template.hbs"
  });

  _exports.default = _default;
});