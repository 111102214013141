define("sky-router-3/pods/components/tz-picker/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.timeZones = void 0;
  // enum export of supported timezone values
  var timeZones = {
    LOCAL: 'local',
    UTC: 'utc',
    ADJUSTED: 'adj'
  };
  _exports.timeZones = timeZones;

  /**
   * Creates a component selector that can be used to display a timezone picker to the user.
   */
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    tagName: '',

    /**
     * If the local timezone selection is enabled or not
     * @type {Boolean}
     */
    showLocal: true,

    /**
     * The selected timezone
     * @type {string}
     */
    selected: timeZones.ADJUSTED,

    /**
     * Action placeholder that is fired when an option is picked
     * @return {[type]} [description]
     */
    onpick: function onpick() {},
    // Private properties that power the picker
    _selected: (0, _computedFindBy.default)('_options', 'id', 'selected', true),
    _options: Ember.computed('showLocal', 'sessionAccount.user.timeZone', function () {
      var utz = this.sessionAccount.user.get('timeZone');
      var opts = [{
        id: timeZones.ADJUSTED,
        offset: utz,
        label: "Adjusted (GMT".concat(utz, ")"),
        description: 'Show time based on your account settings.'
      }, {
        id: timeZones.UTC,
        offset: 0,
        label: 'UTC (GMT+0)',
        description: 'Show time based on the UTC timezone.'
      }];

      if (this.showLocal) {
        opts.pushObject({
          id: timeZones.LOCAL,
          label: 'Local (GPS)',
          description: 'Show time based on the timezone at the associated GPS cordinates.'
        });
      }

      return opts;
    })
  });

  _exports.default = _default;
});