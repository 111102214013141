define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-21/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'typeCustom': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    types: function () {
      var intl = this.get('intl');
      return [{
        groupName: intl.t('compoundrules.condition.accelerometer.air.label'),
        options: [{
          label: intl.t('compoundrules.condition.accelerometer.air.transport', {
            value: '1.5g'
          }),
          value: '150'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.air.general', {
            value: '2g'
          }),
          value: '200'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.air.hardLanding', {
            value: '1g'
          }),
          value: '100'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.air.turbMod', {
            value: '1.5g'
          }),
          value: '40'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.air.sevTurb', {
            value: '0.6g'
          }),
          value: '60'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.air.exceeds', {
            value: '4g'
          }),
          value: '400'
        }]
      }, {
        groupName: intl.t('compoundrules.condition.accelerometer.land.label'),
        options: [{
          label: intl.t('compoundrules.condition.accelerometer.land.hardBrake', {
            value: '0.5g'
          }),
          value: '50'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.land.largeBump', {
            value: '0.3g'
          }),
          value: '30'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.land.accident', {
            value: '1g'
          }),
          value: '100'
        }, {
          label: intl.t('compoundrules.condition.accelerometer.land.badCrash', {
            value: '4g'
          }),
          value: '400'
        }]
      }, {
        label: intl.t('compoundrules.condition.accelerometer.custom'),
        value: '-1'
      }];
    }.property(),
    typeCustomVisible: Ember.computed.equal('typeSelected.value', '-1'),
    typeCustom: Ember.computed('model.value1', {
      get: function get() {
        return this.get('model.value1') / 100;
      },
      set: function set(key, value) {
        return this.set('model.value1', value * 100);
      }
    }),
    typeSelected: function () {
      var val = this.get('model.value1');
      var types = this.get('types');
      return types[0].options.findBy('value', val) || types[1].options.findBy('value', val) || types[2];
    }.property('model.value1'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model.relationalOperator', 0); // Must be initialized to '>'
    },
    actions: {
      setExceedence: function setExceedence(op) {
        if (op.value === '-1') {
          this.set('model.value1', '');
        } else {
          this.set('model.value1', op.value);
        }
      }
    }
  });

  _exports.default = _default;
});