define("sky-router-3/pods/portal/manage/personnel/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.record.name': (0, _emberCpValidations.validator)('presence', true),
    'model.record.email': (0, _emberCpValidations.validator)('email', {
      allowEmpty: true
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    queryParams: ['tab'],
    tab: 'info'
  });

  _exports.default = _default;
});