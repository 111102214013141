define("sky-router-3/pods/components/timezone-info/component", ["exports", "sky-router-3/utils/lat-lng-converter", "moment"], function (_exports, _latLngConverter, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This compoment takes in a latitude and longitude and yields a object that holds the following properties
   * {
          "sunrise":"2015-05-21T05:05:35+00:00",
          "sunset":"2015-05-21T19:22:59+00:00",
          "solar_noon":"2015-05-21T12:14:17+00:00",
          "day_length":51444,
          "civil_twilight_begin":"2015-05-21T04:36:17+00:00",
          "civil_twilight_end":"2015-05-21T19:52:17+00:00",
          "nautical_twilight_begin":"2015-05-21T04:00:13+00:00",
          "nautical_twilight_end":"2015-05-21T20:28:21+00:00",
          "astronomical_twilight_begin":"2015-05-21T03:20:49+00:00",
          "astronomical_twilight_end":"2015-05-21T21:07:45+00:00"
        }
      To access the data just use the component and read the yeilded value
      @example
          {{#sunrise-sunset-times latitude=lat longitude=lng as |dates|}}
              {{dates.sunrise}}
          {{/sunrise-sunset-times}}
   */
  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    latitude: null,
    longitude: null,
    currentDate: (0, _moment.default)().utc(),
    data: Ember.computed('latitude', 'longitude', {
      get: function get() {
        var _this = this;

        if (this.latitude && this.longitude) {
          $.get('https://api.sunrise-sunset.org/json', {
            lat: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.latitude),
            lng: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.longitude),
            formatted: 0
          }, function (data) {
            if (data.status == "OK") {
              data.results.day_length_hours = Math.floor(data.results.day_length / 3600);
              data.results.day_length_minutes = Math.floor(data.results.day_length % 3600 / 60);

              _this.set('data', data.results);
            }
          });
        }

        return {};
      },
      set: function set(key, value) {
        return value;
      }
    }),
    timeZone: Ember.computed('latitude', 'longitude', {
      get: function get() {
        var _this2 = this;

        if (this.latitude && this.longitude) {
          this.api.request('geocode/timezone', {
            data: {
              latitude: this.latitude,
              longitude: this.longitude
            }
          }).then(function (r) {
            return _this2.set('timeZone', r);
          });
        }

        return {};
      },
      set: function set(key, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});