define("sky-router-3/pods/portal/manage/users/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * The API service
     */
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Binding the query parameters to the search field
     * @type {Array}
     */
    queryParams: ['search', 'sortProperty', 'sortDesc', 'includeInactive'],

    /**
     * Holds a text value that is being used for searching
     * @type {String}
     */
    search: null,

    /**
     * Indicates if inactive users should be shown
     * @type {Boolean}
     */
    includeInactive: false,

    /**
     * Filters the model based on the search and the other filter criteria
     */
    filteredUsers: Ember.computed.filter('model', ['search', 'model.[]', 'includeInactive'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('fullName'), search) || (0, _stringSearch.default)(item.get('username'), search) || (0, _stringSearch.default)(item.get('email'), search) || (0, _stringSearch.default)(item.get('company'), search);
      }

      return true;
    }),

    /**
     * The property by which locations are sorted
     * @type {String}
     */
    sortProperty: 'username',

    /**
     * The direction that the list is sorted
     * @type {Boolean}
     */
    sortDesc: false,

    /**
     * Returns a string used by sortedLocations to sort the collection
     * @return {Array<string>}
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),

    /**
     * The sorted users output list
     */
    sortedUsers: Ember.computed.sort('filteredUsers', 'sortBy'),

    /**
     * The users that have been selected
     * @type {Array}
     */
    selectedUsers: [],

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('sortedUsers.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100],

    /**
     * Actually performs the password reset against the API
     * @param {sky-router-3/models/user} user User to reset the password for
     */
    performPasswordReset: function performPasswordReset(user) {
      var _this = this;

      return this.get('api').userResetPassword(user.get('username'), user.get('email')).then(function (a) {
        if (a.BooleanResult) {
          _this.notifications.success(_this.intl.t('users.resetPasswordSuccess', {
            username: user.get('username')
          }));
        } else {
          throw a;
        }
      }).catch(function () {
        _this.notifications.error(_this.intl.t('users.resetPasswordFail', {
          username: user.get('username')
        }));
      });
    },
    actions: {
      changeSort: function changeSort() {
        this.toggleProperty('sortDesc');
      },
      selectUsers: function selectUsers(users, checked) {
        var selected = this.get('selectedUsers');

        if (checked) {
          selected.addObjects(users);
        } else {
          selected.removeObjects(users);
        }
      },
      selectUser: function selectUser(user) {
        var selected = this.get('selectedUsers');

        if (selected.includes(user)) {
          selected.removeObject(user);
        } else {
          selected.addObject(user);
        }
      },
      unlock: function unlock(user) {
        var _this2 = this;

        this.set('unlockInProgress', true);
        this.get('api').userAdminUnlock(user.get('username')).then(function () {
          _this2.notifications.success(_this2.intl.t('users.unlockSuccess', {
            username: user.get('username')
          }));

          user.reload();
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('users.unlockFail', {
            username: user.get('username')
          }));
        });
      },
      resetPasswords: function resetPasswords(users) {
        var _this3 = this;

        var message = this.intl.t('users.resetMultiPasswordConfirm', {
          users: users.mapBy('username').join('\n')
        });

        if (confirm(message)) {
          this.set('resetInProgress', true);
          var promises = [];
          users.forEach(function (usr) {
            promises.pushObject(_this3.performPasswordReset(usr));
          });
          Ember.RSVP.allSettled(promises).then(function () {
            _this3.set('resetInProgress', false);
          });
        }
      },
      forcePasswordChange: function forcePasswordChange(users) {
        var _this4 = this;

        var message = this.intl.t('users.forcePasswordConfirm', {
          users: users.mapBy('username').join('\n')
        });

        if (confirm(message)) {
          this.set('forceInProgress', true);
          var promises = [];
          users.forEach(function (usr) {
            usr.set('passwordExpired', true);
            promises.pushObject(usr.save());
          });
          Ember.RSVP.allSettled(promises).then(function () {
            _this4.set('forceInProgress', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});