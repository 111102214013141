define("sky-router-3/pods/portal/manage/location/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nLdNlW16",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"locations.editLocationTitle\"],null]],[10,\"small\"],[12],[1,[35,1,[\"location\",\"name\"]]],[13],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"map\"],[14,1,\"location-map\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"config\",\"locations\",\"locationMarkerAction\",\"mapAction\",\"locationEditId\"],[[35,1,[\"mapConfig\"]],[30,[36,3],[[35,1,[\"location\"]]],null],[30,[36,2],[\"locationMapAction\"],null],[30,[36,2],[\"mapAction\"],null],[35,1,[\"location\",\"id\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,6],null,[[\"types\",\"isEditing\",\"location\",\"save\",\"saveCancel\",\"savePhoto\",\"deletePhoto\",\"isSaving\"],[[35,1,[\"locationTypes\"]],true,[35,1,[\"location\"]],[30,[36,2],[\"save\"],null],[30,[36,2],[\"cancel\"],null],[30,[36,2],[\"savePhoto\"],null],[30,[36,2],[\"deletePhoto\"],null],[35,5]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"route-action\",\"make-array\",\"portal/track/map/map-google\",\"isSaving\",\"portal/track/location-form\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/location/edit/template.hbs"
  });

  _exports.default = _default;
});