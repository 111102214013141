define("sky-router-3/pods/portal/communicate/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This route exists to prevent the user from traveling to just /communicate
   * if they land on /communicate it forwards them to the inbox route.
   */
  var _default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        this.replaceWith('portal.communicate.folder', 'INBOX');
      }
    }
  });

  _exports.default = _default;
});