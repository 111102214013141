define("sky-router-3/pods/portal/track/location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nSOptJCZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"well track-page-form\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"location\",\"types\",\"isEditing\",\"isSaving\",\"saveCancel\",\"save\",\"savePhoto\",\"deletePhoto\"],[[35,2,[\"location\"]],[35,2,[\"types\"]],[35,2,[\"isEditing\"]],[35,1],[30,[36,0],[\"cancelForm\"],null],[30,[36,0],[\"saveForm\"],null],[30,[36,0],[\"savePhoto\"],null],[30,[36,0],[\"deletePhoto\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"isSaving\",\"model\",\"portal/track/location-form\"]}",
    "moduleName": "sky-router-3/pods/portal/track/location/template.hbs"
  });

  _exports.default = _default;
});