define("sky-router-3/pods/portal/manage/overlays/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.overlay.name': (0, _emberCpValidations.validator)('presence', true),
    termsAgree: (0, _emberCpValidations.validator)('acceptance', {
      disabled: Ember.computed.not('model.kmlFile')
    }),
    kmlFile: (0, _emberCpValidations.validator)('file', {
      allowEmpty: Ember.computed.not('model.model.overlay.isNew'),
      inputId: 'kmlFile',
      sizeLimit: 10000000,
      allowTypes: /(.kml|.kml\+xml|.kmz)$/
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    reset: function reset() {
      this.set('kmlFile', null);
      this.set('termsAgree', null);
    }
  });

  _exports.default = _default;
});