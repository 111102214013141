define("sky-router-3/pods/portal/manage/device-compound-rules/edit/controller", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.revision.rule.name': (0, _emberCpValidations.validator)('presence', true),
    'model.revision.outputs': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'compoundrules.output.presentError',
      dependentKeys: ['model.model.revision.outputs.[]']
    })],
    'model.revision.conditionGroups': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'compoundrules.condition.presentError',
      dependentKeys: ['model.model.revision.conditionGroups.[]']
    })]
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    selectedEnterDelayTime: (0, _computedFindBy.default)('model.delayTimes', 'value', 'model.revision.entranceDelay', true),
    selectedExitDelayTime: (0, _computedFindBy.default)('model.delayTimes', 'value', 'model.revision.exitDelay', true),
    selectedPriority: (0, _computedFindBy.default)('model.priorities', 'value', 'model.revision.priority', true),
    revisionHistorySorted: Ember.computed.sort('model.revision.rule.revisions', 'revisionHistorySortedBy'),
    revisionHistorySortedBy: ['createdDate:desc'],

    /**
     * Indicates if this form is read only and should not allow edits
     * @type {Boolean}
     */
    isHistorical: function () {
      return this.get('model.revision.rule.revisionLatest.id') !== this.get('model.revision.id');
    }.property('model.revision.rule.revisionLatest.id', 'model.revision.id'),

    /**
     * Should the input form be disabled.
     */
    disableForm: function () {
      return !this.get('model.revision.isNew') && this.get('isHistorical');
    }.property('isHistorical', 'model.revision.isNew'),

    /**
     * count of total number of conditions present
     */
    conditions: Ember.computed.mapBy('model.revision.conditionGroups', 'conditions'),
    conditionCount: function () {
      return this.get('conditions').reduce(function (p, i) {
        return p + i.get('length');
      }, 0);
    }.property('conditions.@each.length'),
    actions: {
      changeEntranceDelay: function changeEntranceDelay(option) {
        this.set('model.revision.entranceDelay', option.value);
      },
      changeExitDelay: function changeExitDelay(option) {
        this.set('model.revision.exitDelay', option.value);
      },
      changePriority: function changePriority(option) {
        this.set('model.revision.priority', option.value);
      },
      setLogicOperation: function setLogicOperation(item, option) {
        item.set('logic', option.value);
      },
      setOutputType: function setOutputType(output, option) {
        output.set('type', option);
        output.set('value1', option.get('value1DefaultValue'));
        output.set('value2', option.get('value2DefaultValue'));
      }
    }
  });

  _exports.default = _default;
});