define("sky-router-3/helpers/is-any", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      // Validate
      if (!Ember.isArray(params[0])) {
        return undefined;
      }

      var prop = hash.prop === undefined ? params[1] : hash.prop;
      var value = hash.value === undefined ? params[2] : hash.value; // Return

      return params[0].isAny(prop, value);
    }
  });

  _exports.default = _default;
});