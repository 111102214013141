define("sky-router-3/helpers/sort-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function sortBy(params
  /*, hash*/
  ) {
    if (params.length && params[0] && params[0].sortBy) {
      return params[0].sortBy.apply(params[0], params.slice(1));
    }

    return params[0];
  });

  _exports.default = _default;
});