define("sky-router-3/pods/portal/route", ["exports", "sky-router-3/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "sky-router-3/utils/load-google-map", "sky-router-3/utils/has-permission"], function (_exports, _environment, _authenticatedRouteMixin, _loadGoogleMap, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'public.login',

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The service that handles push notifications when on a cordova app
     */
    pushNotification: Ember.inject.service(),

    /**
     * The login session service
     */
    session: Ember.inject.service(),
    touchId: Ember.inject.service(),

    /**
     * Service for contacting the API in ways ember data can't.
     * @return {sky-router-3/services/API}
     */
    api: Ember.inject.service(),

    /**
     * The service that handles automatic data updates from the API.
     * @return {sky-router-3/services/signalr}
     */
    signalr: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Make sure the google map API is up and ready to go
     * @return {Ember.RSVP.Promise} Promise that is resolved when google maps API
     *                              is up and ready to go.
     */
    beforeModel: function beforeModel() {
      var _this = this;

      var sup = Ember.RSVP.resolve(this._super.apply(this, arguments));
      return sup.then(function () {
        return (0, _loadGoogleMap.default)({
          language: _this.get('intl.locale.firstObject')
        });
      });
    },

    /**
     * Loads the users object at the application level since most of the rest
     * of the application depends on it being present.
     */
    model: function model() {
      return Ember.RSVP.hash({
        systemNotifications: this.store.findAll('system-notification'),
        mailFolder: this.getMailSummary()
      });
    },

    /**
     * Check to see if the user needs to change their password.
     * If they do then redirect them to the password reset screen.
     */
    afterModel: function afterModel()
    /*model , transition*/
    {
      // Check if the user must change their password
      if (this.get('sessionAccount.user.passwordExpired')) {
        this.transitionTo('account.password');
      }

      this.pushNotification.setup();
      return this.initializeSignalR();
    },
    getMailSummary: function getMailSummary() {
      var user = this.get('sessionAccount.user');
      var permissions = user.get('permissions');

      if ((0, _hasPermission.default)(permissions, 'communicate')) {
        return this.store.find('mail-folder', 'INBOX');
      }
    },

    /**
     * Takes the title being generated and passed around and injects it into
     * the model for this route as its needed for the tool-bar-mobile
     */
    title: function title(tokens) {
      Ember.set(this.modelFor(this.routeName), 'title', tokens.get('lastObject'));
      return tokens.join(' > ');
    },

    /**
     * Renders the templates into their respective containers.
     * The tool-bar-mobile template is a default portal template for mobile
     * devices. It can be overridden in child routes.
     */
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);

      this.render('portal.tool-bar-mobile', {
        into: 'portal',
        outlet: 'tool-bar-mobile'
      });
    },

    /**
     * Responsible for starting up the signalR connection to the server
     * that is used to deliver updated data to the client.
     */
    initializeSignalR: function initializeSignalR() {
      var _this2 = this;

      var signalr = this.get('signalr'); // Attach the signalR events

      if (_environment.default.API.signalR.verbosity >= 1) {
        signalr.on('disconnected', function () {
          _this2.notifications.clearAll();

          _this2.send('blockApp', true, {
            title: _this2.intl.t('network.disconnectedTitle'),
            message: _this2.intl.t('network.reconnecting')
          });

          Ember.run.later(_this2, function () {
            signalr.init();
            signalr.start().then(function () {
              _this2.send('blockApp', false);

              _this2.refresh();
            }).catch(function () {
              return location.reload(true);
            });
          }, 5000);
        });
        signalr.on('error', function (error) {
          if ([401, 402].indexOf(Ember.get(error, 'context.status')) !== -1) {
            _this2.get('session').invalidate();
          }
        });
      }

      if (_environment.default.API.signalR.verbosity >= 2) {
        signalr.on('connectionSlow', function () {
          _this2.notifications.warning(_this2.intl.t('network.connectionSlow'), {
            clearDuration: 30000
          });
        });
      }

      if (_environment.default.API.signalR.verbosity >= 3) {
        signalr.on('reconnecting', function () {
          _this2.notifications.info(_this2.intl.t('network.reconnecting'));
        });
        signalr.on('reconnected', function () {
          _this2.notifications.clearAll();

          _this2.notifications.success(_this2.intl.t('network.reconnected'));
        });
      } // Attach the handler that restarts signalR on session update


      var lastToken = this.get('session.data.authenticated.token');
      this.get('session').addObserver('data.authenticated.token', this, function () {
        var token = _this2.get('session.data.authenticated.token');

        if (!!token && lastToken !== token) {
          lastToken = token;
          Ember.run.debounce(signalr, 'restart', 1000);
        }
      }); // Startup the connection

      return signalr.start().catch(function () {
        alert(_this2.intl.t('network.startError'));
        Ember.run.later(function () {
          return document.location.reload(true);
        }, 15000);
      });
    },

    /**
     * Stops the signalR service as the user leaves this route.
     */
    stopSignalR: Ember.on('deactivate', function () {
      this.get('signalr').stop();
    }),
    actions: {
      /**
       * Main application logout for users
       */
      logUserOut: function logUserOut() {
        this.pushNotification.disable();
        this.touchId.clear();
        this.session.invalidate();
      },

      /**
       * Navigates the page back
       */
      navigateBack: function navigateBack() {
        history.back();
      },

      /**
       * Forces the menu to close if the user navigates.
       */
      willTransition: function willTransition() {
        this.controllerFor(this.routeName).send('slideMobileMenu', false);
      },

      /**
       * Sends a ping message to the asset supplied. After the ping has been
       * processed then it displays a notification to the user on if the
       * ping succeeded or failed.
       * @param  {sky-router-3/models/asset} asset to ping
       * @return {Ember.RSVP.Promise}        Promise that is resolved after the
       *                                     the notification has been shown.
       */
      pingAsset: function pingAsset(asset) {
        var _this3 = this;

        if (!this.sessionAccount.hasPermission('device.ping')) {
          return;
        } // Get a list of all the assets that can actually be ping'd


        return asset.get('product').then(function (product) {
          // Check if the user can perform the ping
          if (!product.get('supportPing')) {
            _this3.notifications.warn(_this3.intl.t('ping.notSupported'));

            return;
          } // Check if user wants to do this


          var name = asset.get('name');

          if (!confirm(_this3.intl.t('ping.confirmation', {
            name: name
          }))) {
            return;
          } // Ping the asset


          return _this3.get('api').pingAsset(asset.get('id')).then(function (result) {
            if (result.PingResult === 0) {
              _this3.notifications.info(_this3.intl.t('ping.success', {
                name: name
              }));
            } else {
              _this3.notifications.error(_this3.intl.t('ping.fail', {
                name: name
              }));
            }

            return result;
          }).catch(function () {
            _this3.notifications.error(_this3.intl.t('ping.fail', {
              name: name
            }));
          });
        });
      }
    }
  });

  _exports.default = _default;
});