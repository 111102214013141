define("sky-router-3/pods/components/input-errors/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Shows validation error messages for a give field based of ember-cp-validations
   * When placed inside a form-group it has the .has-errors class to it as well.
   *
   * @property errorClass - Can be used to set the class that is set when errors happen, bootstrap supports 'has-error','has-warning','has-success'
   * @property errors - Reference to an array of validation error messages for the property
   * @property showErrors - Gives the ability to hide errors even if they are present
   *
   * @example {{input-errors errors=errors.email showErrors=true errorClass='has-warning'}}
   */
  var _default = Ember.Component.extend({
    /**
     * Tag name since the template is a li
     */
    tagName: 'ul',

    /**
     * User settable class that will be added to form groups
     * and error block
     */
    errorClass: 'has-error',

    /**
     * Contains the array of errors
     */
    errors: null,

    /**
     * Base class name for the element
     */
    classNames: ['error-block'],

    /**
     * Class that is added when an error is to be shown
     */
    classNameBindings: ['errorClassNameBinding'],

    /**
     * Allows the ability to show errors to be bound to a property
     */
    showErrors: true,

    /**
     * Used to get the error class for the classnamebindings since it needs to be computed
     */
    errorClassNameBinding: Ember.computed('errorClass', 'errors.length', 'showErrors', function () {
      if (this.get('errors.length') && this.get('showErrors')) {
        return this.get('errorClass');
      }

      return null;
    }),

    /**
     * Adds the errors class to any form group if there are any errors
     */
    updateFormGroup: function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateUi);
    }.observes('errors.length', 'showErrors'),
    didInsertElement: function didInsertElement() {
      this._updateUi();
    },
    willDestroyElement: function willDestroyElement() {
      this._updateUi(true);
    },
    _updateUi: function _updateUi() {
      var forceHide = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      if (this.element && this.get('errors')) {
        (0, _jquery.default)(this.element).parent().toggleClass(this.get('errorClass'), this.get('errors.length') !== 0 && this.get('showErrors') && !forceHide);
      }
    }
  });

  _exports.default = _default;
});