define("sky-router-3/pods/components/portal/track/map/overlays/user-events/component", ["exports", "sky-router-3/models/overlay-user-event-type", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/browser-detect"], function (_exports, _overlayUserEventType, _googleObjectMapping, _computedJsonObject, _latLngConverter, _browserDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Temporary user events
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each record downloaded.
     */
    style: function style(feature) {
      var size = 30;
      var icon = {
        url: (0, _overlayUserEventType.getTypeUrl)(feature.getProperty('typeId')),
        scaledSize: new google.maps.Size(size, size),
        size: new google.maps.Size(size, size),
        anchor: new google.maps.Point(size / 2, size / 2)
      };
      return {
        optimized: !(0, _browserDetect.isIE)(),
        // undocumented IE FIX
        icon: icon
      };
    },

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getFeatureInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout()
    /*dataObject, mouseEvent*/
    {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
    },

    /**
     * Launches the FAA link for the TFR.
     */
    _click: function _click(dataObject, mouseEvent) {
      this.set('popupBody', this._getFeatureInfo(mouseEvent.feature));
      this.set('popupOpen', true);
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getFeatureInfo: function _getFeatureInfo(feature) {
      return {
        id: feature.getProperty('id'),
        name: feature.getProperty('name'),
        nameDisplay: "".concat(feature.getProperty('name'), " (").concat(feature.getProperty('type'), ")"),
        description: feature.getProperty('description'),
        created: new Date(feature.getProperty('created')),
        expires: new Date(feature.getProperty('expires')),
        type: feature.getProperty('type'),
        latitude: feature.getProperty('latitude') * 3600000,
        longitude: feature.getProperty('longitude') * 3600000
      };
    },

    /**
     * Creates a bounds listener so that the overlay boots when the map finally loads.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.autoReloadGeoJson));
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function loadGeoJson() {
      var _this = this;

      Ember.run.join(function () {
        var map = _this.get('map');

        var bounds = map.getBounds();

        if (!bounds) {
          return;
        }

        var url = _this.get('dataObject.url');

        if (!url) {
          return;
        }

        var opts = {
          data: {
            bbox: _latLngConverter.default.getBBoxString(bounds)
          }
        };

        _this.get('api').request(url, opts).then(function (x) {
          var obj = _this.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    },

    /**
     * Forces the reloading of the metar data when the map moves or is zoomed.
     */
    autoReloadGeoJson: function () {
      Ember.run.debounce(this, this.loadGeoJson, 500);
    }.observes('mapConfig.center', 'mapConfig.zoom'),

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this2 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this2.loadGeoJson();

        _this2.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      this.mapBoundsListener.remove();
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },
    actions: {
      closePopup: function closePopup() {
        this.set('popupOpen', false);
        this.set('popupBody', null);
      }
    }
  });

  _exports.default = _default;
});