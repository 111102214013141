define("sky-router-3/pods/portal/track/emergency/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service()
  });

  _exports.default = _default;
});