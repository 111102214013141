define("sky-router-3/pods/portal/forms/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('forms.view.title');
    },
    model: function model(params) {
      return this.store.findRecord('form', params.form_id);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('loaded', false);
      }
    }
  });

  _exports.default = _default;
});