define("sky-router-3/pods/public/login/two-factor/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    serialize: function serialize(model
    /*, params*/
    ) {
      return {
        'user_id': model.username
      };
    },
    // This route can only be accessed through login page with a passed in model
    model: function model()
    /*params, transition*/
    {
      this.replaceWith('public.login');
    }
  });

  _exports.default = _default;
});