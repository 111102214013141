define("sky-router-3/pods/components/portal/track/event-list/component", ["exports", "sky-router-3/models/asset-position-type", "sky-router-3/utils/filter-by-in-array"], function (_exports, _assetPositionType, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is a track window list that holds all the position report type events
   * that appear in the system.
   *
   * It takes in a list of events and displays them on the page in chronological order.
   *
   * If the user scrolls to the bottom of the list a button appears allowing them to
   * pull more events from the server which will then appear in the list of events
   * after they have been downloaded from the server.
   */
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    tagName: 'article',
    classNames: ['track-window'],

    /**
     * This action fires anytime that an event happens inside this component.
     * Actions are grouped for brevity and defined by a first parameter `action`.
     *
     * @event 'onWindowAction'
     * @param {string} action - the name of the action that was fired.
     *
     *      sort - sorting of the list
     *      filter - filtering of the list
     *      eventClicked - when an item is clicked.
     *      shown - When this event list is shown
     *      loadMoreEvents - request that more events be loaded into the event list
     *
     * @param {object} actionValue - the value of the action provided.
     *
     *      sort - { {string}property, {boolean}desc }
     *      filter -{
     *               {boolean} showIridium,
     *               {boolean} showGsm,
     *               {boolean} showAdsb,
     *               {boolean} showQuickLynk,
     *               {array.<number>} assetIds,
     *               {array.<number>} assetPositionTypeIds
     *               {boolean} hideNPRs
     *      }
     *      eventClicked - { eventId: eventId } of the event clicked
     *      shown - none
     *      loadMoreEvents - none
     */

    /**
     * This holds all the UI configuration information for the window.
     * @type {sky-router-3/objects/state-track/state-track-event-list[]}
     */
    config: null,

    /**
     * Passed in array that holds all the events that this list is
     * supposed to display in the UI. This is the master list.
     * @type {array:sky-router-3/models/asset-position[]}
     */
    events: null,

    /**
     * Filters the incoming events based on the properties of the
     * config.
     */
    eventsFiltered: function () {
      var _this = this;

      return this.events.filter(function (event) {
        if (!_this.get('config.filterShowIridium') && event.get('isIridium')) {
          return false;
        }

        if (!_this.get('config.filterShowGsm') && event.get('isGSM')) {
          return false;
        }

        if (!_this.get('config.filterShowAdsb') && event.get('isADSB')) {
          return false;
        }

        if (!_this.get('config.filterShowQuickLynk') && event.get('isQuickLynk')) {
          return false;
        }

        if (_this.get('config.filterHideNPRs') && _assetPositionType.POSITION_TYPES.isNormalType(event.belongsTo('type').id())) {
          return false;
        }

        if (_this.get('config.filterAssetIds.length') > 0) {
          if (_this.get('config.filterAssetIds').indexOf(event.get('asset.id')) === -1) {
            return false;
          }
        }

        if (_this.get('config.filterPositionTypeIds.length') > 0) {
          if (_this.get('config.filterPositionTypeIds').indexOf(event.get('type.id')) === -1) {
            return false;
          }
        }

        return true;
      });
    }.property('events.[]', 'config.filterShowIridium', 'config.filterShowGsm', 'config.filterShowAdsb', 'config.filterShowAdsb', 'config.filterShowQuickLynk', 'config.filterHideNPRs', 'config.filterAssetIds.[]', 'config.filterPositionTypeIds.[]'),

    /**
     * Sorts the filtered events based on the properties of the config
     * @see http://emberjs.com/api/classes/Ember.computed.html#method_sort
     */
    eventsSorted: Ember.computed.sort('eventsFiltered', 'eventsSortedBy'),

    /**
     * How the `eventsSorted` is sorted by. Its essentially an array of keys to
     * sort by with their direction attached at the end.
     * @see http://emberjs.com/api/classes/Ember.computed.html#method_sort
     */
    eventsSortedBy: function () {
      return [this.get('config.sortProperty') + (this.get('config.sortDesc') ? ':desc' : ':asc')];
    }.property('config.sortProperty', 'config.sortDesc'),

    /**
     * Creates a subset of events that can be used to lazy load the event list
     * @return {[type]} [description]
     */
    eventsSubset: function () {
      return this.get('eventsSorted').slice(0, this.get('eventsCount'));
    }.property('eventsCount', 'eventsSorted.[]'),

    /**
     * Number of items to show in the UI
     * @type {Number}
     * @default 200
     */
    eventsCount: 100,

    /**
     * Passed in array that holds all the event types that this list
     * is capable of representing in the UI.
     * @type {sky-router-3/models/asset-position-type[]}
     */
    eventTypes: null,

    /**
     * Passed in array that holds a list of all the assets in the system
     * so that they can be used to display a filter select dropdown.
     * @type {sky-router-3/models/asset[]}
     */
    assets: null,

    /**
     * Indicates that the user clicked the search button icon
     * and it wasn't automatically opened because there is currently
     * a value in the search field. This value is not conveyed into storage
     * because when you come back to the page, search should only be shown
     * if there is a current search term.
     */
    _showSearch: false,

    /**
     * Indicates that the search box should be shown, this combines the users
     * request for it to be shown with the requirement it be open if there is
     * a value being searched.
     */
    showSearch: Ember.computed.or('config.searchValue', '_showSearch'),

    /**
     * Sets the displayed event count back to the default value.
     * This is usually called after a sort or filter which resets the limit.
     */
    resetDisplayedEventsCount: function resetDisplayedEventsCount() {
      this.set('eventsCount', 100);
    },

    /**
     * Gets an array of assets that are currently filtered by the config.
     * It is used by the select to mark assets as selected.
     * @return {sky-router-3/models/asset[]}
     */
    filteredAssets: (0, _filterByInArray.default)('assets', 'id', 'config.filterAssetIds'),

    /**
     * Gets an array of asset position types that are currently filtered by the
     * config. It is used by the select to mark events as selected.
     * @return {sky-router-3/models/asset-position-type[]}
     */
    filteredEvents: (0, _filterByInArray.default)('eventTypes', 'id', 'config.filterPositionTypeIds'),

    /**
     * Returns a boolean that indicates if filters are not using their defaults
     * @return {Boolean}
     */
    filtersSet: function () {
      return this.get('config.filterShowIridium') !== true || this.get('config.filterShowGsm') !== true || this.get('config.filterShowAdsb') !== true || this.get('config.filterShowQuickLynk') !== true || this.get('config.filterHideNPRs') !== true || this.get('config.filterAssetIds.length') !== 0 || this.get('config.filterPositionTypeIds.length') !== 0;
    }.property('config.filterShowIridium', 'config.filterShowGsm', 'config.filterShowAdsb', 'config.filterShowQuickLynk', 'config.filterHideNPRs', 'config.filterAssetIds.[]', 'config.filterPositionTypeIds.[]'),

    /**
     * Trigger a load of events when this window is shown if no
     * @return {[type]} [description]
     */
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        _this2.onWindowAction('shown');
      });
    },

    /**
     * Actions fired from the template
     */
    actions: {
      filterChanged: function filterChanged() {
        this.onWindowAction('filter', {
          showIridium: this.get('config.filterShowIridium'),
          showGsm: this.get('config.filterShowGsm'),
          showAdsb: this.get('config.filterShowAdsb'),
          showQuickLynk: this.get('config.filterShowQuickLynk'),
          assetIds: this.get('config.filterAssetIds'),
          assetPositionTypeIds: this.get('config.filterPositionTypeIds'),
          hideNPRs: this.get('config.filterHideNPRs')
        });
        this.resetDisplayedEventsCount();
      },
      resetFilter: function resetFilter() {
        this.onWindowAction('filter', {
          showIridium: true,
          showGsm: true,
          showAdsb: true,
          showQuickLynk: true,
          assetIds: [],
          assetPositionTypeIds: [],
          hideNPRs: true
        });
        this.resetDisplayedEventsCount();
      },
      sortChanged: function sortChanged() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: this.get('config.sortDesc')
        });
        this.resetDisplayedEventsCount();
      },
      orderChanged: function orderChanged() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: !this.get('config.sortDesc')
        });
        this.resetDisplayedEventsCount();
      },
      toggleSearch: function toggleSearch() {
        this.toggleProperty('_showSearch');
      },
      onEventClicked: function onEventClicked(eventId) {
        this.onWindowAction('eventClicked', {
          eventId: eventId
        });
      },
      onEventScrollAtBottom: function onEventScrollAtBottom() {
        this.incrementProperty('eventsCount', 100);
      },
      onLoadMoreEvents: function onLoadMoreEvents() {
        this.onWindowAction('loadMoreEvents');
        jQuery(this.element).children('.window-body').scrollTop(0);
        this.resetDisplayedEventsCount();
      },
      assetFilterChanged: function assetFilterChanged(selections) {
        this.set('config.filterAssetIds', selections.mapBy('id'));
        this.send('filterChanged');
      },
      eventFilterChanged: function eventFilterChanged(selections) {
        this.set('config.filterPositionTypeIds', selections.mapBy('id'));
        this.send('filterChanged');
      }
    }
  });

  _exports.default = _default;
});