define("sky-router-3/pods/components/custom-properties/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Passed in properties which are key, value pairs
     * { key: key, value: value }
     */
    properties: null,

    /**
     * Action holders from the component parent. This is a placeholder so
     * explosions don't happen
     */
    onAdd: function onAdd() {},
    onRemove: function onRemove() {},
    actions: {
      addProperty: function addProperty(key) {
        if (key) {
          this.set('addPropertyKey', null);
          this.onAdd(Ember.Object.create({
            key: key.trim(),
            value: null
          }));
        }
      }
    }
  });

  _exports.default = _default;
});