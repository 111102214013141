define("sky-router-3/pods/components/portal/manage/device-parameters/controls/nal-time-based-reporting/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'minuteSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.minuteSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.minuteSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,
    minuteSetting: null,
    actions: {
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('minuteSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});