define("sky-router-3/models/asset-compound-rule-revision-condition-group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The rule revision that this compound rule group belongs to.
     * @type {sky-router-3/models/asset-compound-rule-revision}
     */
    revision: (0, _model.belongsTo)('asset-compound-rule-revision'),

    /**
     * Any conditions that are associated with this group.
     * There must be at least 1 of these per group.
     * @type {sky-router-3/models/asset-compound-rule-revision-condition}
     */
    conditions: (0, _model.hasMany)('asset-compound-rule-revision-condition', {
      async: false
    }),

    /**
     * The logic operation that this group performs upon the next group (if one exists).
     * 0 = AND
     * 1 = OR
     * @type {number}
     */
    logic: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});