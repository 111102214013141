define("sky-router-3/pods/components/input-focus/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    focus: false,
    didInsertElement: function didInsertElement() {
      if (this.get('focus')) {
        (0, _jquery.default)(this.element).focus();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});