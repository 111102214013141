define("sky-router-3/initializers/boostrap-overrides", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // overrides the default bootstrap behavior to close dropdowns upon click so that we can put checkboxes and other form controls inside
    // Just add the attribute data-autoclose="false" and the close will be prevented
    $('body').on('click', '.dropdown-menu[data-autoclose="false"]', function (e) {
      e.stopPropagation();
    });
    $('body').on('click', '#ember-basic-dropdown-wormhole .ember-basic-dropdown-content', function (e) {
      if ($(".dropdown-menu[data-autoclose=\"false\"] [aria-owns=\"".concat(e.currentTarget.id, "\"]")).length) {
        e.stopPropagation();
      }
    });
    $('body').on('click', '.flatpickr-calendar', function (e) {
      if ($(".dropdown-menu[data-autoclose=\"false\"] .flatpickr-input.active").length) {
        e.stopPropagation();
      }
    }); // switch a drop-down to a drop-up if the user is within 300 pixels of the bottom of the page.
    // This helps prevent dropdown windows from rolling off the bottom of the page.
    // This also checks for dropdowns running off the side of the page.

    $('body').on('click', '.dropdown:not(.open) > .dropdown-toggle, .btn-group:not(.open) > .dropdown-toggle', function (e) {
      if ($(window).height() - e.clientY < 300) {
        var parent = $(e.currentTarget).parent();
        parent.removeClass('dropdown').addClass('dropup');
        $('body').one('click', function () {
          parent.removeClass('dropup').addClass('dropdown');
        });
      }

      var menu = $(e.currentTarget).siblings('.dropdown-menu');
      var maxWidth = menu.hasClass('dropdown-lg') ? 260 : 160;

      if ($(window).width() - e.clientX < maxWidth) {
        menu.addClass('pull-right');
        $('body').one('click', function () {
          menu.removeClass('pull-right');
        });
      }
    });
  }

  var _default = {
    name: 'boostrap-js-overrides',
    initialize: initialize
  };
  _exports.default = _default;
});