define("sky-router-3/mixins/permission-required-mixin", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a mixin that should be mixed into routes.
   * It checks that the user has the permissions necessary in order to access
   * the route. If the user's permissions do not match the request is killed and
   * the user is redirected to the route as defined in `redirectRoute`
   *
   * Note. this MUST come after the
   */
  var _default = Ember.Mixin.create({
    /**
     * The session account service that is needed to get at the user's permissions.
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * An array of permissions that the user must have at least one of with their
     * account in order to access this route. When the user's permissions do not match
     * the request is killed and the user is redirected to the route as defined
     * @type {Array<string>}
     */
    permissions: null,

    /**
     * The route which the user is redirected to if the user does not have the
     * correct permissions.
     * @default 'portal'
     * @type {String}
     */
    redirectRoute: 'portal',

    /**
     * Override the before model hook to execute the permission check code.
     * @return {Ember.RSVP.Promise}
     */
    beforeModel: function beforeModel() {
      var permissions = this.get('permissions');

      if (Ember.isArray(permissions) && permissions.length > 0) {
        var userPermissions = this.get('sessionAccount.user.permissions');

        if (!(0, _hasPermission.default)(userPermissions, permissions)) {
          this.replaceWith(this.get('redirectRoute'));
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});