define("sky-router-3/authenticators/web-api", ["exports", "ember-simple-auth/authenticators/base", "ember-ajax/errors"], function (_exports, _base, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This object is used by the simple-auth system to control authentication and session
   * It is the master control for users session and establishes the login access control mechanism
   *
   *
   * The web api authenticates using cookie based authentication. When a user is
   * authenticated the following information is stored in the `session.store.authenticated`
   * {
   * username {String} ,
   * expiration {Date},
   * token {String},
   * expirationMinutes {Number},
   * userId {Number},
   * cookieName {String}
   * }
   *
   * There is also an associated cookie on the users machine who's name matches
   * the `cookieName` property above.
   *
   * This authenticator is based off of the ember-simple-auth oauth authenticator
   * @see https://github.com/simplabs/ember-simple-auth/blob/1.0.1/addon/authenticators/oauth2-password-grant.js
   * @see http://ember-simple-auth.com/
   */
  var _default = _base.default.extend({
    /**
     * Holds a reference to the api service which does all the API requests
     */
    api: Ember.inject.service('api'),

    /**
     * The session account
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Holds the expiration warning run timer that executes when the users
     * authentication is about to expire
     * @type {Timer}
     * @private
     */
    _refreshAuthTimer: null,

    /**
     * Indicates how often the authentication is renewed
     * @default 300000 milliseconds / 5 minutes
     * @type {Number}
     */
    _refreshAuthTimeout: 300000,

    /**
     * Schedules the authentication token refresh to occur.
     * It calculates 30 seconds before the ticket times out and if thats sooner
     * than the _refreshAuthTimeout then it uses that time.
     * @param {Object} authData authentication data as described by _getSessionObjFromResponse
     */
    _scheduleAuthRefresh: function _scheduleAuthRefresh(authData, authTimeout) {
      Ember.run.cancel(this._refreshAuthTimer);
      var timeout = Math.min(authData.expiration - Date.now() - 30000, authTimeout);
      this._refreshAuthTimer = Ember.run.later(this, this._refreshAuth, authData, timeout);
    },

    /**
     * Calls the API to refresh the authentication data and cookie.
     * If the refresh fails it attempts every 15 seconds until successful.
     * @param {Object} authData     Authentication data as described by _getSessionObjFromResponse
     * @return {Ember.RSVP.Promise} Promise to complete the work
     */
    _refreshAuth: function _refreshAuth(authData) {
      var _this = this;

      return this._refreshAuthData(authData).then(function (x) {
        return _this.trigger('sessionDataUpdated', x);
      }).catch(function (e) {
        if (e instanceof _errors.UnauthorizedError) {
          _this.trigger('sessionDataInvalidated');
        } else {
          _this._scheduleAuthRefresh(authData, 15000);
        }
      });
    },

    /**
     * Calls the API to refresh the authentication data and cookie.
     * @param {Object} authData     Authentication data as described by _getSessionObjFromResponse
     * @return {Ember.RSVP.Promise} The data that to be refreshed
     */
    _refreshAuthData: function _refreshAuthData(authData) {
      var _this2 = this;

      return this.get('api').userAuthenticateRefresh().then(function (response) {
        var data = _this2._getSessionObjFromResponse(authData.username, response);

        _this2._scheduleAuthRefresh(data, _this2._refreshAuthTimeout);

        return data;
      });
    },

    /**
     * Called when the users session needs to be validated / restored
     * This is called when the user comes back to the App after a while or opens a new tab
     *
     * The method checks the session expiration and checks to see if it has expired or not.
     *
     * @param {object} - secure session object containing the property `expiration` which
     *                   is a string date representation of when the session will expire
     * @returns a promise that resolves for good restore or rejects for invalid session
     * @see http://ember-simple-auth.com/api/classes/BaseAuthenticator.html#method_restore
     */
    restore: function restore(authData) {
      var _this3 = this;

      // Check to make sure that our user didn't change in a different tab by checking
      // to see if a user account is already loaded and if it matches the ID.
      // NOTE: the users id will be a string and the authdata ID will be a number
      // (i.e. from backoffice login as user feature)
      var userId = this.get('sessionAccount.user.id');

      if (userId && userId != authData.userId) {
        // eslint ignore:line
        window.location.reload();
      } // Check the authentication against the server


      return this.get('api').userAuthenticateCheck(authData.userId, authData.token).then(function () {
        _this3._scheduleAuthRefresh(authData, _this3._refreshAuthTimeout);

        return authData;
      });
    },

    /**
     * Authenticates a user to create a session.
     *
     * An object is passed in with the following properties
     * @property - username
     * @property - password
     *
     * @returns a promise that is resolved or rejected.
     *
     * Resolve:
     * The promise will be resolved if the user passes authentication and SHOULD be granted access
     * The promise will resolve with an object that contains the following
     *
     *  - username          = username of the user that was authenticated
     *  - expiration        = the expiration of the users authenticated session local to the users machine.
     *                        When expired the users session will invalidate. This is a number representing
     *                        the number of seconds since January 1st, 1970.
     *  - token             = the access token that is to be passed with each request
     *  - expirationMinutes = expiration of the users session from now in minutes.
     *
     * Reject:
     * The promise will be rejected if the user fails to authenticate and SHOULD NOT be granted access
     * an error object will be returned containing the following
     *
     * {
     *      reason: '<the reason>',
     * }
     *
     * reason will contain any of the following string reasons:
     *  - 'lockedOut'   = the user is locked out and cannot authenticate again until they are unlocked
     *  - 'badAuth'     = the user failed to send the correct username/password combo
     *  - 'disabled'    = the user is disabled and cannot be logged in
     *  - 'blocked'     = the user was blocked because of IP access restrictions
     *  @see http://ember-simple-auth.com/api/classes/BaseAuthenticator.html#method_authenticate
     */
    authenticate: function authenticate(options) {
      var _this4 = this;

      if (options.refresh) {
        return this.get('sessionAccount.session.store').restore().then(function (a) {
          return _this4._refreshAuthData(a);
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this4.get('api').userAuthenticate(options.username, options.password).then(function (resp) {
            var authData = _this4._getSessionObjFromResponse(options.username, resp);

            _this4._scheduleAuthRefresh(authData, _this4._refreshAuthTimeout);

            resolve(authData);
          }, function (resp) {
            switch (Ember.get(resp, 'payload.ErrorReason')) {
              case 2:
                return reject({
                  reason: 'lockedOut'
                });

              case 3:
                return reject({
                  reason: 'disabled'
                });

              case 4:
                return reject({
                  reason: 'blocked'
                });

              case 5:
                return reject({
                  reason: 'needs2FA'
                });

              case 7:
                return reject({
                  reason: 'accountSuspended'
                });

              case 8:
                return reject({
                  reason: 'accountDeactivated'
                });

              default:
                return reject({
                  reason: 'badAuth'
                });
            }
          });
        });
      }
    },

    /**
     * Overrides the base implementation to log the user out by killing their
     * session cookie.
     * @return {Ember.RSVP.Promise}
     * @see http://ember-simple-auth.com/api/classes/BaseAuthenticator.html#method_invalidate
     */
    invalidate: function invalidate() {
      var _this5 = this;

      Ember.run.cancel(this._refreshAuthTimer);
      return new Ember.RSVP.Promise(function (resolve) {
        _this5.get('api').userAuthenticateLogout().then(function () {
          resolve();
        }, function () {
          resolve();
        });
      });
    },

    /**
     * Creates an object that matches the definition for what this authenticator returns
     * from extend or authenticate based on the BsnWebApi response.
     * @function
     * @param {string} the username of the user
     * @param {BsnWebApi.ApiToken} token object returned from the server
     * @see BsnWebApi/Help/ResourceModel?modelName=ApiToken
     */
    _getSessionObjFromResponse: function _getSessionObjFromResponse(username, responseObject) {
      var localDate = new Date();
      localDate.setMinutes(localDate.getMinutes() + responseObject.ExpirationMinutes);
      return {
        username: username,
        expiration: localDate.getTime(),
        token: responseObject.AccessToken,
        expirationMinutes: responseObject.ExpirationMinutes,
        userId: responseObject.ForUserId,
        cookieName: responseObject.CookieName
      };
    }
  });

  _exports.default = _default;
});