define("sky-router-3/pods/portal/manage/index/route", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The session service that holds the user account information.
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The session account service
     */
    session: Ember.inject.service(),

    /**
     * Checks the users permissions and redirects them to the appropriate
     * page that they have access to.  This is called anytime the user goes
     * to the root route.
     */
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'portal.manage.index') {
        var permissions = this.get('sessionAccount.user.permissions'); // Navigate the user to the closest page they have access to

        if ((0, _hasPermission.default)(permissions, 'manage.device')) {
          this.replaceWith('portal.manage.devices');
        } else if ((0, _hasPermission.default)(permissions, 'manage.deviceparameter')) {
          this.replaceWith('portal.manage.device-parameters');
        } else if ((0, _hasPermission.default)(permissions, 'manage.deviceprofile')) {
          this.replaceWith('portal.manage.device-profiles');
        } else if ((0, _hasPermission.default)(permissions, 'manage.location')) {
          this.replaceWith('portal.manage.location');
        } else if ((0, _hasPermission.default)(permissions, 'manage.group')) {
          this.replaceWith('portal.manage.groups');
        } else if ((0, _hasPermission.default)(permissions, 'manage.user')) {
          this.replaceWith('portal.manage.users');
        } else if ((0, _hasPermission.default)(permissions, 'manage.alert')) {
          this.replaceWith('portal.manage.alerts');
        } else if ((0, _hasPermission.default)(permissions, 'manage.application')) {
          this.replaceWith('portal.manage.applications');
        } else if ((0, _hasPermission.default)(permissions, 'manage.event')) {
          this.replaceWith('portal.manage.events');
        } else if ((0, _hasPermission.default)(permissions, 'manage.flightplan')) {
          this.replaceWith('portal.operations.flightplans');
        } else if ((0, _hasPermission.default)(permissions, 'manage.datafeed')) {
          this.replaceWith('portal.manage.datafeeds');
        }
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});