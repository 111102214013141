define("sky-router-3/pods/components/portal/manage/device-parameters/controls/gsm-polling/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hLIeRZNs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"parameter.polling.title\"],null]],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,0],[\"parameter.polling.help\"],null]],[13],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,0],[\"parameter.polling.help1\"],null]],[13],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,0],[\"parameter.polling.help2\"],null]],[13],[2,\"\\n        \"],[10,\"li\"],[14,0,\"warn\"],[12],[1,[30,[36,0],[\"parameter.polling.help3\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,2],[[35,1,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.polling.label\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"class\",\"value\",\"disabled\",\"type\",\"min\",\"max\"],[\"form-control\",[35,1,[\"value\"]],[35,4],\"number\",[35,1,[\"validationMinNumber\"]],[35,1,[\"validationMaxNumber\"]]]]]],[2,\"\\n        \"],[1,[30,[36,7],null,[[\"errors\"],[[35,6,[\"attrs\",\"intervalSetting\",\"value\",\"messages\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"intervalSetting\",\"if\",\"input-label\",\"disabled\",\"input\",\"validations\",\"input-errors\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/gsm-polling/template.hbs"
  });

  _exports.default = _default;
});