define("sky-router-3/pods/portal/manage/location/edit-multiple/route", ["exports", "sky-router-3/utils/has-permission", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map"], function (_exports, _hasPermission, _create, _stateTrackMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * The user session object
     */
    sessionAccount: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        locations: this.store.query('location', {
          ids: params.location_ids_csv.split(',')
        }),
        locationTypes: this.store.findAll('location-type'),
        mapConfig: (0, _create.default)(_stateTrackMap.default)
      });
    },
    buildProperties: function buildProperties(controller) {
      var obj = {};
      this.checkAndSetProperty(controller, obj, 'type');
      this.checkAndSetProperty(controller, obj, 'contactName');
      this.checkAndSetProperty(controller, obj, 'contactPhone');
      this.checkAndSetProperty(controller, obj, 'city');
      this.checkAndSetProperty(controller, obj, 'country');
      this.checkAndSetProperty(controller, obj, 'zipCode');
      this.checkAndSetProperty(controller, obj, 'street');
      this.checkAndSetProperty(controller, obj, 'stateProvince');
      this.checkAndSetProperty(controller, obj, 'comment');
      this.checkAndSetProperty(controller, obj, 'fuel');
      this.checkAndSetProperty(controller, obj, 'distanceToShore');
      this.checkAndSetProperty(controller, obj, 'area');
      this.checkAndSetProperty(controller, obj, 'companyName');
      this.checkAndSetProperty(controller, obj, 'radioFrequency');
      this.checkAndSetProperty(controller, obj, 'typeOfLandmark');
      this.checkAndSetProperty(controller, obj, 'block');
      this.checkAndSetProperty(controller, obj, 'address');
      return obj;
    },
    checkAndSetProperty: function checkAndSetProperty(controller, obj, prop) {
      if (controller.get(prop + 'Checked')) {
        obj[prop] = controller.get(prop);
      }

      return obj;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var user = this.get('sessionAccount.user');
      controller.set('type', model.locationTypes.get('firstObject'));
      controller.set('canMarkGlobal', !user.get('isAdmin') && (0, _hasPermission.default)(user.get('permissions'), 'manage.location'));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('contactName', null);
        controller.set('contactPhone', null);
        controller.set('city', null);
        controller.set('country', null);
        controller.set('zipCode', null);
        controller.set('street', null);
        controller.set('stateProvince', null);
        controller.set('comment', null);
        controller.set('fuel', null);
        controller.set('distanceToShore', null);
        controller.set('area', null);
        controller.set('companyName', null);
        controller.set('radioFrequency', null);
        controller.set('typeOfLandmark', null);
        controller.set('block', null);
        controller.set('address', null);
        controller.set('typeChecked', false);
        controller.set('contactNameChecked', false);
        controller.set('contactPhoneChecked', false);
        controller.set('cityChecked', false);
        controller.set('countryChecked', false);
        controller.set('zipCodeChecked', false);
        controller.set('streetChecked', false);
        controller.set('stateProvinceChecked', false);
        controller.set('commentChecked', false);
        controller.set('fuelChecked', false);
        controller.set('distanceToShoreChecked', false);
        controller.set('areaChecked', false);
        controller.set('companyNameChecked', false);
        controller.set('radioFrequencyChecked', false);
        controller.set('typeOfLandmarkChecked', false);
        controller.set('blockChecked', false);
        controller.set('addressChecked', false);
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        if (!confirm(this.intl.t('locations.editMultipleLocationConfirm'))) {
          return;
        }

        var locations = this.modelFor(this.routeName).locations;
        var controller = this.controllerFor(this.routeName);
        var promises = [];
        var obj = this.buildProperties(controller);
        controller.set('isSaving', true);
        locations.forEach(function (loc) {
          loc.setProperties(obj);
          promises.pushObject(loc.save());
        });
        Ember.RSVP.allSettled([promises]).then(function () {
          controller.set('isSaving', false);

          _this.transitionTo('portal.manage.location');
        }).catch(function () {
          controller.set('isSaving', false);

          _this.notifications.error(_this.intl.t('track.locationSaveFail'));
        });
      },
      cancel: function cancel() {
        this.transitionTo('portal.manage.location');
      },
      locationMapAction: function locationMapAction(location, action) {
        var config = this.modelFor(this.routeName).mapConfig;
        var array = config.get('locationInfoBoxOpenArray');

        switch (action) {
          case 'click':
            array.addObject(location.get('location.id'));
            break;

          case 'close':
            array.removeObject(location.get('location.id'));
            break;

          default:
            this.send('showNotAvailableFeature');
        }
      },
      mapAction: function mapAction(map, action) {
        switch (action) {
          case 'center':
          case 'loaded':
            break;

          default:
            this.send('showNotAvailableFeature');
        }
      }
    }
  });

  _exports.default = _default;
});