define("sky-router-3/models/asset-compound-rule-revision-condition", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The operation that this condition performs upon the next condition
     * in the same group (if one exists).
     * 0 = AND
     * 1 = OR
     * @type {number}
     */
    logic: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * The logic operation this condition actions upon the value supplied in based on the type attribute selected.
     * 0 = Greater Than
     * 1 = Less Than
     * 2 = Equals
     * 3 = Not Equals
     * @type {number}
     */
    relationalOperator: (0, _model.attr)('number', {
      defaultValue: 2
    }),

    /**
     * The type of condition that this belongsTo. For example GNSS or Accelerometer.
     * @type {sky-router-3/models/asset-compound-rule-condition-type}
     */
    type: (0, _model.belongsTo)('asset-compound-rule-condition-type'),

    /**
     * The type of attribute based on the the condition type that this condition
     * is action'ing based off of. Valid values are based on the type selected.
     * @type {sky-router-3/models/asset-compound-rule-condition-attribute-type}
     */
    typeAttribute: (0, _model.belongsTo)('asset-compound-rule-condition-attribute-type'),

    /**
     * The group that this condition belongs to.
     * @required
     * @type {sky-router-3/models/asset-compound-rule-revision-group}
     */
    group: (0, _model.belongsTo)('asset-compound-rule-revision-condition-group'),

    /**
     * Optional value field that is utilized based on the attribute-type selected.
     * @type {unknown}
     */
    value1: (0, _model.attr)(),

    /**
     * Optional value field that is utilized based on the attribute-type selected.
     * @type {unknown}
     */
    value2: (0, _model.attr)()
  });

  _exports.default = _default;
});