define("sky-router-3/pods/portal/controller", ["exports", "sky-router-3/mixins/action-helpers"], function (_exports, _actionHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_actionHelpers.default, {
    /**
     * Inject the session account service so the user is accessible.
     */
    sessionAccount: Ember.inject.service(),
    // Controller query params
    queryParams: ['showHelp', 'showNotifications'],
    // View bound properties
    showHelp: false,
    //#region NOTIFICATIONS

    /**
     * Property that is bound to the query parameters for the page.
     * Indicates if the modal is shown or not.
     * @type {boolean}
     */
    showNotifications: false,

    /**
     * Property that indicates that notifications are currently being
     * loaded and the should not be able to force load more.
     * @type {boolean}
     */
    loadingNotifications: false,

    /**
     * Property that reveals hidden side menu on mobile devices.
     * @type {Boolean}
     */
    showMobileMenu: false,

    /**
     * Checks to see if there are any unread system notifications.
     * returns true if there are any unread ones.
     * @type {boolean}
     */
    anyUnreadSystemNotifications: function () {
      return !this.get('model.systemNotifications').isEvery('isDismissed', true);
    }.property('model.systemNotifications.@each.isDismissed'),

    /**
     * Sort system notifications so that the latest appears at the top.
     * @type {Array}
     */
    systemNotificationsSortedBy: ['dateCreatedUTC:desc'],
    systemNotificationsSorted: Ember.computed.sort('model.systemNotifications', 'systemNotificationsSortedBy'),

    /**
     * Returns a summary of the inbox folder.
     * @return {Ember.RSVP.promise}
     */
    inboxFolder: function () {
      return this.get('model.mailFolder');
    }.property('model.mailFolder'),
    // Template actions
    actions: {
      toggleHelp: function toggleHelp() {
        this.toggleProperty('showHelp');
      },
      //#region NOTIFICATIONS

      /**
       * Shows or hides the system notification modal
       */
      toggleSystemNotifications: function toggleSystemNotifications() {
        this.toggleProperty('showNotifications');
      },

      /**
       * When the user wishes to show more alerts. This looks at the
       * current alerts and loads alerts older than the current oldest
       * alert.
       */
      showMoreSystemNotifications: function showMoreSystemNotifications() {
        var _this = this;

        var notifications = this.get('model.systemNotifications');
        var min = Math.min.apply(Math, _toConsumableArray(notifications.map(function (x) {
          return parseInt(x.get('id'));
        })));
        this.set('loadingNotifications', true);
        this.store.query('systemNotification', {
          count: 5,
          beforeId: min
        }).then(function () {
          _this.set('loadingNotifications', false);
        });
      },

      /**
       * When the user wishes to dismiss an alert
       */
      ackSystemNotification: function ackSystemNotification(notification) {
        notification.set('isDismissed', true);
        notification.save();
      },
      //#endregion

      /**
       * Shows and hides the mobile menu
       */
      slideMobileMenu: function slideMobileMenu() {
        var show = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.set('showMobileMenu', show);
      }
    }
  });

  _exports.default = _default;
});