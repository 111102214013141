define("sky-router-3/models/business-continuity", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * SIM
     */
    sim: (0, _model.attr)('string'),

    /**
     * IMEI
     */
    msisdn: (0, _model.attr)('string'),

    /**
     * days since last call (or 'NO')
     */
    days: (0, _model.attr)('string'),

    /**
     * Last Call or 'No Calls'
     */
    lastCall: (0, _model.attr)('string')
  });

  _exports.default = _default;
});