define("sky-router-3/pods/components/display-speed/component", ["exports", "ember-data", "sky-router-3/models/asset-category"], function (_exports, _emberData, _assetCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component takes an altitude value in and turns it into the units the user
   * uses to view altitude with. The format of the output is determined by the users profile.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),

    /**
     * The speed in centimeters per second that will be mutated for display
     * @type {Number}
     */
    cm: 0,

    /**
     * Property that holds a asset record which indicates what
     * unit to transform the the `cm` property into for display.
     * When not present the users `speedOther` setting will be used.
     * @type {sky-router-3/models/asset-category}
     */
    asset: null,

    /**
     * Gets the unit record used for conversion of the unit value
     * @return {Ember.RSVP.Proimse} Promise when resolved contains the unit record.
     * @private
     */
    _unit: function () {
      var user = this.get('sessionAccount.user');
      return Ember.RSVP.resolve(this.get('asset')).then(function (asset) {
        if (asset && asset.get('isLoaded')) {
          switch (asset.belongsTo('category').id()) {
            case _assetCategory.ASSET_CATEGORY.air:
              return user.get('speedAir');

            case _assetCategory.ASSET_CATEGORY.land:
              return user.get('speedLand');

            case _assetCategory.ASSET_CATEGORY.sea:
              return user.get('speedSea');
          }
        }

        return user.get('speedOther');
      });
    }.property('asset.category.id', 'sessionAccount.user.speedOther.id'),

    /**
     * Gets the computed final string for use with display speed.
     * @return {Ember.RSVP.Promise} Promise when resolved holds the final
     *                              speed string.
     */
    speed: function () {
      var cm = this.get('cm');
      return _emberData.default.PromiseObject.create({
        promise: this.get('_unit').then(function (unit) {
          var value = cm * unit.get('centimeterFactor');
          var decimals = Math.pow(10, unit.get('decimalCount'));
          return Math.floor(value * decimals) / decimals + unit.get('shortName');
        })
      });
    }.property('_unit.id', 'cm')
  });

  _exports.default = _default;
});