define("sky-router-3/pods/components/portal/report/groupassociations-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    gridOption: '',

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
    * Waiting on the new report to be generated or not?
    */
    reportGenerated: 0,

    /**
    * The report result set
    **/
    reportData: null,

    /**
    * Creates the applicable pages list
    */
    pages: function () {
      var pages = [];
      var take = this.get('take');
      var totalRecords = this.get('totalRecords');
      var count = Math.ceil(totalRecords / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }.property('totalRecords', 'take'),

    /**
     * On init, generate the report
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this.generateReport();
    },

    /**
     * This is the function that triggers the report to be rendered.
     * @function
     * @private
     */
    onGenerateReportClicked: function () {
      this.generateReport();
    }.observes('generateReportClicked'),

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport() {
      var _this = this;

      // Get report parameters
      var reportSelected = this.get('gridOption');
      this.set('loading', true);

      switch (reportSelected) {
        default:
        case 1:
          return this.get('api').getAssociationGroupReport().then(function (response) {
            _this.dataHandler(response);
          });

        case 2:
          return this.get('api').getAssociationDevicesReport().then(function (response) {
            _this.dataHandler(response);
          });

        case 3:
          return this.get('api').getAssociationUsersReport().then(function (response) {
            _this.dataHandler(response);
          });
      }
    },

    /**
    * Processes the response data from the report queries
    */
    dataHandler: function dataHandler(data) {
      var reportSelected = this.get('gridOption');
      data.rows.forEach(function (item) {
        item.expanded = false;

        if (item.Groups !== undefined) {
          item.Groups.forEach(function (group) {
            group.expanded = false;
          });
        }
      });
      this.set('reportData', data.rows);
      this.set('loading', false);
      this.set('reportGenerated', reportSelected);
    },
    actions: {
      toggleExpand: function toggleExpand(item) {
        Ember.set(item, 'expanded', !item.expanded);

        if (!item.expanded === false && item.Groups !== undefined) {
          item.Groups.forEach(function (group) {
            Ember.set(group, 'expanded', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});