define("sky-router-3/utils/computed-storage-for", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedStorageFor;

  /**
   * Creates a computed property that is backed by a 'storageFor'. If no storage is present, it uses the default value
   * @param {string} storageProp subpath present on the storage that the property name will be appended to
   * @param {value|function} defaultValue a raw value or a callable function that returns the default value 
   */
  function computedStorageFor(storage) {
    var _ref;

    var defaultValue = (_ref = (arguments.length <= 1 ? 0 : arguments.length - 1) - 1 + 1, _ref < 1 || arguments.length <= _ref ? undefined : arguments[_ref]);
    var storageProp = (arguments.length <= 1 ? 0 : arguments.length - 1) == 2 ? arguments.length <= 1 ? undefined : arguments[1] : undefined;
    return Ember.computed({
      get: function get(property) {
        // Calculate the final property based on the input 
        if (storageProp) {
          property = "".concat(storageProp, "-").concat(property);
        } // Get the final value


        var value = Ember.get(this, "".concat(storage, ".").concat(property));

        if (value !== undefined) {
          return value;
        } // Get the value from the defualt value generator that can be a bare prop or function


        if (defaultValue !== undefined) {
          if (defaultValue !== null && defaultValue.call !== undefined) {
            return defaultValue.call(this);
          }

          return defaultValue;
        }

        return undefined;
      },
      set: function set(key, value) {
        // Update the storage prop so it can be the intelligent default in the future
        if (storageProp) {
          key = "".concat(storageProp, "-").concat(key);
        }

        Ember.set(this, "".concat(storage, ".").concat(key), value);
        return value;
      }
    });
  }
});