define("sky-router-3/pods/components/portal/settings/security/login-expiration/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * The user that this component is editing
     * @type {sky-router-3/models/user}
     */
    user: null,
    options: function () {
      var intl = this.get('intl');
      return [{
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        }),
        value: 15
      }, {
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        }),
        value: 20
      }, {
        label: "".concat(intl.t('parameter.misc.countMinutes', {
          count: 30
        }), " (").concat(intl.t('misc.default'), ")"),
        value: 30
      }, {
        label: intl.t('parameter.misc.countHour'),
        value: 60
      }, {
        label: intl.t('parameter.misc.countHours', {
          count: 6
        }),
        value: 360
      }, {
        label: intl.t('parameter.misc.countHours', {
          count: 12
        }),
        value: 720
      }, {
        label: intl.t('parameter.misc.countDay'),
        value: 1440
      }, {
        label: intl.t('parameter.misc.countWeek'),
        value: 10080
      }, {
        label: intl.t('settings.autoLogout.never'),
        value: 525600
      }];
    }.property(),
    actions: {
      setExpire: function setExpire(opt) {
        this.set('user.ticketExpire', opt.value);
      }
    }
  });

  _exports.default = _default;
});