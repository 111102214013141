define("sky-router-3/models/trip-plan-leg", ["exports", "@ember-data/model", "moment", "ember-cp-validations", "ember-data-model-fragments/attributes", "sky-router-3/utils/lat-lng-converter", "ember-local-storage"], function (_exports, _model, _moment, _emberCpValidations, _attributes, _latLngConverter, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEPART_TRIGGER = _exports.D_CALC_METHOD = _exports.E_CALC_METHOD = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    scheduledDepartureTZMinutes: (0, _emberCpValidations.validator)('presence', true),
    scheduledDepartureUtc: (0, _emberCpValidations.validator)('presence', true),
    scheduledEnrouteMinutes: (0, _emberCpValidations.validator)('presence', true),
    originWaypoint: (0, _emberCpValidations.validator)('presence', true),
    destinationWaypoint: (0, _emberCpValidations.validator)('presence', true)
  });
  var E_CALC_METHOD = {
    timeEnroute: 0,
    // scheduled time enroute - time in trip
    distanceRemaining: 1 // distance remaining / current speed

  };
  _exports.E_CALC_METHOD = E_CALC_METHOD;
  var D_CALC_METHOD = {
    scheduledDistance: 0,
    // scheduled distance - traveled distance
    directLine: 1 // direct line to destination

  };
  _exports.D_CALC_METHOD = D_CALC_METHOD;
  var DEPART_TRIGGER = {
    event: 1,
    // wait for start event
    schedule: 2 // start trip purely on schedule

  };
  _exports.DEPART_TRIGGER = DEPART_TRIGGER;

  var _default = _model.default.extend(VALIDATIONS, {
    preferences: (0, _emberLocalStorage.storageFor)('user-preferences'),
    name: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),

    /**
     * The active trip instance for this plan once the trip has started.
     * NOTE: This may not be present if the trip has not started.
     * @type {sky-router-3/models/trip}
     */
    trip: (0, _model.belongsTo)('trip'),

    /**
     * The plan associated with this leg
     */
    plan: (0, _model.belongsTo)('trip-plan'),

    /**
     * The scheduled date of departure
     */
    scheduledDepartureUtc: (0, _model.attr)('date'),
    scheduledDepartureTZMinutes: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * The ammount of time the trip is supposed to take
     */
    scheduledEnrouteMinutes: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    scheduledEnrouteSeconds: Ember.computed('scheduledEnrouteMinutes', {
      get: function get() {
        return this.scheduledEnrouteMinutes * 60;
      },
      set: function set(key, value) {
        this.set('scheduledEnrouteMinutes', Math.floor(value / 60));
        return value;
      }
    }),
    scheduledOverdueArrivalEnabled: (0, _model.attr)('boolean'),
    scheduledOverdueArrivalMinutes: (0, _model.attr)('number', {
      defaultValue: function defaultValue(model) {
        var def = model.get('preferences.tripleg-scheduledOverdueArrivalMinutes');

        if (def == undefined) {
          def = 0;
        }

        return def;
      }
    }),
    scheduledOverdueArrivalSeconds: Ember.computed('scheduledOverdueArrivalMinutes', {
      get: function get() {
        return this.get('scheduledOverdueArrivalMinutes') * 60;
      },
      set: function set(key, value) {
        var minutes = Math.floor(value / 60);
        this.set('scheduledOverdueArrivalMinutes', minutes);
        this.set('preferences.tripleg-scheduledOverdueArrivalMinutes', minutes);
        return value;
      }
    }),

    /**
     * The distance that the unit is scheduled to go.
     * This is dependent on the waypoints and updated by the server only.
     */
    scheduledDistanceCm: (0, _model.attr)('number'),
    calculatedDistanceCm: Ember.computed('waypoints.@each.latitudeMilliarcSeconds', 'waypoints.@each.longitudeMilliarcSeconds', function () {
      return _latLngConverter.default.getPathDistance(this.waypoints) * 100;
    }),
    manuallyScheduleDistance: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * The scheduled date the plan will be completed,
     * This is an alias of enroute minutes
     */
    scheduledArrivalUtc: Ember.computed('scheduledEnrouteMinutes', 'scheduledDepartureUtc', {
      get: function get() {
        return (0, _moment.default)(this.scheduledDepartureUtc).add(this.scheduledEnrouteMinutes, 'minutes').toDate();
      },
      set: function set(key, value) {
        this.set('scheduledEnrouteMinutes', Math.floor((value - this.scheduledDepartureUtc) / 60000));
        return value;
      }
    }),

    /**
     * Describes the path taken on this plan leg
     */
    waypoints: (0, _attributes.fragmentArray)('trip-plan-leg-waypoint'),
    originWaypoint: Ember.computed('waypoints.[]', function () {
      return this.waypoints.firstObject;
    }),
    destinationWaypoint: Ember.computed('waypoints.[]', function () {
      return this.waypoints.lastObject;
    }),

    /**
     * The remaining distance the unit must travel in order to reach the destination.
     */
    remainingDistanceCm: (0, _model.attr)('number'),
    remainingDistanceCalcMethod: (0, _model.attr)('number', {
      defaultValue: function defaultValue(model) {
        var def = model.get('preferences.tripleg-remainingDistanceCalcMethod');

        if (def == undefined) {
          def = D_CALC_METHOD.directLine;
        }

        return def;
      }
    }),

    /**
     * What triggers the start of a trip/leg. Either a start event or at the scheduled time.
     */
    departureTrigger: (0, _model.attr)('number', {
      defaultValue: function defaultValue(model) {
        var def = model.get('preference.tripleg-depatureTrigger');

        if (!def) {
          def = DEPART_TRIGGER.event;
        }

        return def;
      }
    }),
    elapseTimeBySchedule: (0, _model.attr)('boolean'),

    /**
     * The minutes remaining until the unit reaches the destination.
     */
    remainingEnrouteMinutes: (0, _model.attr)('number'),
    remainingEnrouteSeconds: Ember.computed('remainingEnrouteMinutes', 'trip.isCompleted', 'elapseTimeBySchedule', 'trip._now', 'trip.startDateUtc', 'scheduledEnrouteMinutes', function () {
      if (this.elapseTimeBySchedule) {
        var now = this.get('trip._now');
        console.log(now);
        var startDate = this.get('trip.startDateUtc');
        var calcElapse = (now - startDate) / 1000;
        return this.get('scheduledEnrouteMinutes') * 60 - calcElapse;
      }

      return this.remainingEnrouteMinutes * 60;
    }),
    remainingEnrouteCalcMethod: (0, _model.attr)('number', {
      defaultValue: function defaultValue(model) {
        var def = model.get('preferences.tripleg-remainingEnrouteCalcMethod');

        if (def == undefined) {
          def = E_CALC_METHOD.distanceRemaining;
        }

        "";
        return def;
      }
    }),

    /**
     * Overdue calculation provided by the system if the scheduledOverdueArrivalEnabled is set
     */
    calculatedOverdueMinutes: (0, _model.attr)('number', {
      defaultValue: 30
    }),
    calculatedIsOverdue: (0, _model.attr)('boolean'),

    /**
     * Allows the user to force override 'flase' or 'true' the isOverdue status.
     * -1 = Do not Override
     * 0  = Override to 'Not Overdue'
     * 1  = Override to 'Overdue'
     */
    manualIsOverdue: (0, _model.attr)('number', {
      defaultValue: -1
    }),
    canMarkManualIsOverdue: Ember.computed('manualIsOverdue', 'calculatedIsOverdue', function () {
      return !this.calculatedIsOverdue && this.manualIsOverdue != 1 || this.manualIsOverdue == 0;
    }),
    canMarkManualIsNotOverdue: Ember.computed('manualIsOverdue', 'calculatedIsOverdue', function () {
      return this.calculatedIsOverdue && this.manualIsOverdue != 0 || this.manualIsOverdue == 1;
    }),

    /**
     * Indicator for the UI on overdue status
     * @return {boolean}
     */
    isOverdue: Ember.computed('manualIsOverdue', 'calculatedIsOverdue', function () {
      return this.manualIsOverdue == -1 ? this.calculatedIsOverdue : this.manualIsOverdue == 1;
    }),

    /**
     * The estimated date that the unit will reach the destination.
     */
    estimatedArrivalUtc: Ember.computed('remainingEnrouteMinutes', function () {
      return (0, _moment.default)().add(this.remainingEnrouteMinutes, 'minutes').toDate();
    }),

    /**
     * Gets the positive or negative difference from the time elapsed.
     * @return {Number} Negative values mean ahead of schedule, negative behind schedule
     */
    estimatedArrivalSecondsDiff: Ember.computed('trip.timeElapsedSeconds', 'remainingEnrouteSeconds', 'scheduledEnrouteSeconds', function () {
      return this.get('trip.timeElapsedSeconds') + this.remainingEnrouteSeconds - this.scheduledEnrouteSeconds;
    }),

    /**
     * Gets the positive or negative difference from the time elapsed.
     * @return {Number} Negative values mean ahead of schedule positive, behind schedule
     */
    arrivalTimeSecondsDiff: Ember.computed('trip.timeElapsedSeconds', 'scheduledEnrouteSeconds', function () {
      return this.get('trip.timeElapsedSeconds') - this.scheduledEnrouteSeconds;
    }),

    /**
     * Gets the positive or negative difference from the start time to the actual start time.
     * @return {Number} Negative values mean ahead of schedule, positive behind schedule
     */
    departureTimeSecondsDiff: Ember.computed('trip.startDateUtc', 'scheduledDepartureUtc', function () {
      return Math.floor((this.get('trip.startDateUtc') - this.scheduledDepartureUtc) / 1000);
    }),

    /**
     * Gets the positive or negative difference from the distance traveled vs scheduled.
     * @return {Number} Negative values mean less than planned, positive more than planned
     */
    arrivalDistanceCmDiff: Ember.computed('trip.traveledDistanceCm', 'scheduledDistanceCm', function () {
      return this.get('trip.traveledDistanceCm') - this.scheduledDistanceCm;
    }),

    /**
     * Any associated personnel with this trip
     */
    personnel: (0, _attributes.fragmentArray)('trip-plan-leg-personnel'),

    /**
     * Holds values of the custom fields
     */
    customFields: (0, _attributes.fragmentArray)('trip-plan-custom-field')
  });

  _exports.default = _default;
});