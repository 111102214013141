define("sky-router-3/models/-custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This file allows for adding specific rules to the inflector so that
   * things are puluralized properly.
   * @see https://guides.emberjs.com/v3.1.0/models/customizing-adapters/#toc_pluralization-customization
   */
  var inflector = _emberInflector.default.inflector;
  inflector.uncountable('personnel'); // Meet Ember Inspector's expectation of an export

  var _default = {};
  _exports.default = _default;
});