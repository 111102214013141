define("sky-router-3/pods/portal/operations/contracts/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The API service
     */
    api: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),
    queryParams: {
      showExpired: {
        refreshModel: true
      }
    },

    /**
     * The page's model
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        contracts: this.store.query('contract', {
          showExpired: params.showExpired
        })
      });
    }
  });

  _exports.default = _default;
});