define("sky-router-3/instance-initializers/schedule-maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * This starts up the maintenance service schedule.
   */
  function initialize(instance) {
    // Get the session service
    if (!Ember.testing) {
      instance.lookup('service:maintenance').scheduleAll();
    }
  }

  var _default = {
    name: 'schedule-maintenance',
    initialize: initialize
  };
  _exports.default = _default;
});