define("sky-router-3/pods/portal/manage/geofences/assignment-by-device/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset'),
        geofences: this.store.findAll('geofence'),
        assignments: this.store.findAll('geofence-asset')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('search', null);
      }
    }
  });

  _exports.default = _default;
});