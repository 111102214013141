define("sky-router-3/pods/components/display-alt/component", ["exports", "ember-data", "sky-router-3/utils/convert-unit"], function (_exports, _emberData, _convertUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper takes an altitude value in centimeters and turns it into the units the user
   * uses to view altitude with. The format of the output is determined by the users profile.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),

    /**
     * The altitude in centimeters that will be mutated for display
     * @type {Number}
     */
    cm: 0,

    /**
     * Gets the computed final string for use with display altitude.
     * @return {Ember.RSVP.Promise} Promise when resolved holds the final
     *                              altitude string.
     */
    altitude: function () {
      var cm = this.get('cm');

      if (isNaN(cm)) {
        return {
          content: cm
        };
      }

      return _emberData.default.PromiseObject.create({
        promise: this.get('sessionAccount.user.altitude').then(function (unit) {
          return (0, _convertUnit.default)(unit, cm);
        })
      });
    }.property('cm')
  });

  _exports.default = _default;
});