define("sky-router-3/pods/portal/operations/contracts/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),
    queryParams: ['search', 'sortProperty', 'sortDesc', 'showExpired'],

    /**
     * Filtering
     */
    search: null,
    includeInactive: false,
    showExpired: false,
    filteredModel: Ember.computed.filter('model.contracts', ['search', 'model.contracts.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search) || (0, _stringSearch.default)(item.get('notes'), search);
      }

      return true;
    }),

    /**
     * Sorting
     */
    sortProperty: 'startDate',
    sortDesc: false,
    sortedModel: Ember.computed.sort('filteredModel', 'sortBy'),
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),
    actions: {
      changeSort: function changeSort() {
        this.toggleProperty('sortDesc');
      },
      deleteContract: function deleteContract(contract) {
        var _this = this;

        if (confirm(this.intl.t('contracts.deleteContractConfirm', {
          name: contract.get('name')
        }))) {
          contract.destroyRecord().catch(function () {
            _this.notifications.error(_this.intl.t('contracts.deleteContractFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});