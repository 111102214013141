define("sky-router-3/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yeDUgSAk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"blockPage\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"blockText\"],[12],[2,\"\\n            \"],[10,\"h1\"],[12],[1,[34,0]],[13],[2,\"\\n            \"],[10,\"h4\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"h2\"],[14,0,\"loader\"],[12],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"blockTitle\",\"blockMessage\",\"blockLoader\",\"if\",\"blockApp\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/application/template.hbs"
  });

  _exports.default = _default;
});