define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-6/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kx5CQi0z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"compoundrules.condition.gpsQuality.help\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-4 form-group\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"name\",\"value\",\"groupValue\",\"disabled\"],[\"gps-signal\",\"0\",[35,1,[\"value1\"]],[35,0]]]]],[2,\" \"],[1,[30,[36,3],[\"compoundrules.condition.gpsQuality.bad\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-4 form-group\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"name\",\"value\",\"groupValue\",\"disabled\"],[\"gps-signal\",\"1\",[35,1,[\"value1\"]],[35,0]]]]],[2,\" \"],[1,[30,[36,3],[\"compoundrules.condition.gpsQuality.poor\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-lg-4 form-group\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"name\",\"value\",\"groupValue\",\"disabled\"],[\"gps-signal\",\"2\",[35,1,[\"value1\"]],[35,0]]]]],[2,\" \"],[1,[30,[36,3],[\"compoundrules.condition.gpsQuality.good\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"model\",\"radio-button\",\"t\",\"input-label\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-6/template.hbs"
  });

  _exports.default = _default;
});