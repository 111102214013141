define("sky-router-3/pods/components/portal/track/map/geofence-circle/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a geofence as a google.maps.Circle()
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#CircleOptions
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    tagName: '',

    /**
     * @event geofenceAction
     * @description generic action that contains all of the events that are fired.
     *              combining all the actions into one action helps reduce code
     *              duplication.
     * @param the sender of the action, aka. this component.
     * @param the string action that is being performed
     * 'click'  : Fired when the geofence is clicked
     */
    geofenceAction: function geofenceAction() {},

    /**
     * Google maps object
     */
    googlePropertyObject: Ember.computed.alias('instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'center',
      setFunc: 'setCenter',
      event: 'center_changed',
      getFunc: 'getCenter'
    }, {
      property: 'radius',
      setFunc: 'setRadius',
      event: 'radius_changed',
      getFunc: 'getRadius'
    }, {
      property: 'options',
      setFunc: 'setOptions'
    }, {
      event: 'rightclick',
      eFunc: '_onRightClick'
    }, {
      event: 'click',
      eFunc: '_onClick'
    }],

    /**
     * Incoming geofence model
     * @type {sky-router-3/models/geofence}
     */
    geofence: null,

    /**
     * Incoming map object
     */
    map: null,
    mapZoom: null,

    /**
     * When true the geofence should be editable
     */
    isEditing: false,

    /**
     * When the geofence is being created
     */
    isCreating: Ember.computed.equal('geofence.points.length', 0),

    /**
     * Instance of the Google map circle
     * @type {google.maps.Circle}
     */
    instance: null,

    /**
     * Gets the center for the geofence from the first set of lat/lng cords
     * present in the points collection.
     */
    center: Ember.computed('geofence.points.@each.latitudeDegree', 'geofence.points.@each.longitudeDegree', {
      get: function get() {
        var center = this.get('geofence.points.firstObject');

        if (center) {
          var lat = center.get('latitudeDegree');
          var lng = center.get('longitudeDegree');
          return new google.maps.LatLng(lat, lng);
        }
      },
      set: function set(key, value) {
        var geofence = this.get('geofence');
        var center = geofence.get('points.firstObject');

        if (center) {
          center.set('latitudeDegree', value.lat());
          center.set('longitudeDegree', value.lng());
        } else if (value) {
          geofence.points.addObject(Ember.Object.create({
            ordinal: 0,
            latitudeDegree: value.lat(),
            longitudeDegree: value.lng()
          }));
        }

        return value;
      }
    }),

    /**
     * options object that is pushed into the circle instance
     */
    options: function () {
      return {
        fillColor: "#".concat(this.get('geofence.color')),
        fillOpacity: 0.25,
        strokeColor: '#000000',
        strokeOpacity: 0.5,
        editable: this.get('isEditing'),
        draggable: this.get('isEditing')
      };
    }.property('geofence.color', 'isEditing'),

    /**
     * Gets the radius of the circle in meters
     */
    radius: Ember.computed('geofence.diameterInKM', {
      get: function get() {
        return this.get('geofence.diameterInKM') * 500;
      },
      set: function set(key, value) {
        this.set('geofence.diameterInKM', value / 500);
        return value;
      }
    }),

    /**
     * Returns if the name label should be visible on the map or not.
     * This method calculates the viewport in meters and the size of the geofence in meters.
     * Then it sees if the geofence takes up 0.5% of the viewport or greater.
     * That number seems to be a decent breaking point for circle geofences.
     * Different values cause it to appear at higher or lower zoom levels.
     * If not here, names will be larger than the geofence itself on the visible map, which is not good.
     * @return {Boolean}
     */
    isNameLabelVisible: Ember.computed('map', 'mapZoom', 'radius', function () {
      var bounds = this.map.getBounds();

      if (bounds) {
        var mapArea = _latLngConverter.default.getBoundsArea(bounds);

        var cirArea = Math.PI * Math.pow(this.radius, 2);
        return cirArea > mapArea * .005;
      }

      return false;
    }),
    //#region CONTEXT MENU

    /**
     * Show or hide the context menu
     */
    contextMenuShow: false,

    /**
     * The event that triggered the context menu to be shown
     * @type {google.maps.MouseEvent|google.maps.PolyMouseEvent}
     */
    contextMenuEvent: null,
    //#endregion

    /**
     * Init the circle instance
     */
    init: function init() {
      this.set('instance', new google.maps.Circle());

      this._super.apply(this, arguments);
    },

    /**
     * Destroy the circle instance
     */
    willDestroyElement: function willDestroyElement() {
      this.instance.setMap(null);
    },

    /**
     * Triggered when the user right clicks on the circle
     */
    _onRightClick: function _onRightClick(gObject, mouseEvent) {
      // show the context menu
      this.onContextMenuShown();
      this.set('contextMenuShow', true);
      this.set('contextMenuEvent', mouseEvent);
    },

    /**
     * Closes a context menu if it is open.
     */
    _onClick: function _onClick()
    /*gObject, mouseEvent*/
    {
      if (this.geofenceAction) {
        this.geofenceAction(this, 'click');
      }

      this.send('closeContextMenu');
    },
    actions: {
      /**
       * Closes the context menu, triggered from the menu itself
       */
      closeContextMenu: function closeContextMenu() {
        this.set('contextMenuShow', false);
        this.set('contextMenuEvent', null);
      },

      /**
       * Fired when the geofence is to be deleted.
       */
      onGeofenceDelete: function onGeofenceDelete() {
        this.deleteGeofence({
          geofence: this.get('geofence')
        });
      },

      /**
       * Fired from the geofence create manager. It contains the manager
       * and the circle that was created.
       */
      onNewCircleCreated: function onNewCircleCreated(args) {
        // Set our properties
        this.set('radius', args.circle.getRadius());
        this.set('center', args.circle.getCenter()); // Destroy the passed in circle object

        args.circle.setMap(null);
        args.circle = null;
      }
    }
  });

  _exports.default = _default;
});