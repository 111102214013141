define("sky-router-3/pods/portal/manage/alerts/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The API service
     */
    api: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * The page's model
     */
    model: function model() {
      return Ember.RSVP.hash({
        alerts: this.store.findAll('alert-definition', {
          reload: true
        }),
        definitions: this.store.findAll('alert-definition-message-tag'),
        fromNumbers: this.get('api').getAlertDefinitionFromPhoneNumbers().then(function (x) {
          return x.join(', ');
        }),
        timeBetweenAlerts: [{
          id: 0,
          label: this.intl.t('alerts.timeBetweenAlerts.off')
        }, {
          id: 10,
          label: this.intl.t('alerts.timeBetweenAlerts.seconds', {
            count: 10
          })
        }, {
          id: 30,
          label: this.intl.t('alerts.timeBetweenAlerts.seconds', {
            count: 30
          })
        }, {
          id: 60,
          label: this.intl.t('alerts.timeBetweenAlerts.minute')
        }, {
          id: 120,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 2
          })
        }, {
          id: 300,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 5
          })
        }, {
          id: 600,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 10
          })
        }, {
          id: 900,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 15
          })
        }, {
          id: 1800,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 30
          })
        }]
      });
    }
  });

  _exports.default = _default;
});