define("sky-router-3/pods/portal/forms/templates/edit/controller", ["exports", "ember-cp-validations", "sky-router-3/config/environment", "sky-router-3/utils/string-search"], function (_exports, _emberCpValidations, _environment, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.template.name': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 255
    })],
    'model.template.description': (0, _emberCpValidations.validator)('length', {
      max: 255
    }),
    file: (0, _emberCpValidations.validator)('file', {
      inputId: 'template-file',
      sizeLimit: 5000000,
      // 5mb
      allowTypes: /application\/.*zip.*/
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    file: null,
    baseTemplate: "".concat(_environment.default.API.namespace, "/FormTemplates/BaseTemplate.zip"),
    disabled: Ember.computed.not('model.template.canEdit'),
    filterRecipientSearch: null,
    filteredUsers: Ember.computed.filter('model.users', ['filterRecipientSearch'], function (item) {
      return (0, _stringSearch.default)(item.completeName, this.filterRecipientSearch);
    }),
    filteredPersonnel: Ember.computed.filter('model.personnel', ['filterRecipientSearch'], function (item) {
      return (0, _stringSearch.default)(item.name, this.filterRecipientSearch);
    }),
    filteredRecipientOptions: Ember.computed('filteredUsers.[]', 'filteredPersonnel.[]', function () {
      return this.filteredUsers.map(function (u) {
        return {
          type: 'user',
          user: u
        };
      }).concat(this.filteredPersonnel.map(function (p) {
        return {
          type: 'personnel',
          personnel: p
        };
      }));
    }),
    actions: {
      cancel: function cancel() {
        if (this.model.template.get('isNew')) {
          this.model.template.destroyRecord();
        } else {
          this.model.template.rollbackAttributes();
          this.model.template.reload();
        }

        this.transitionToRoute('portal.forms.templates');
      },
      save: function save() {
        var _this = this;

        this.model.template.save().then(function (template) {
          if (_this.file) {
            var file = document.getElementById('template-file').files[0];

            _this.api.uploadFormTemplate(template.id, file).then(function () {
              _this.model.template.reload();
            }).catch(function () {
              _this.notifications.error(_this.intl.t('misc.failedOperation'));
            });
          }

          _this.transitionToRoute('portal.forms.templates');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      },
      addRecipientEmail: function addRecipientEmail(tag) {
        this.model.template.recipientEmails.addObject(tag);
      },
      removeRecipientEmail: function removeRecipientEmail(index) {
        this.model.template.recipientEmails.removeAt(index);
      },
      filterRecipientOptions: function filterRecipientOptions(filter) {
        this.set('filterRecipientSearch', filter);
      },
      selectRecipientOption: function selectRecipientOption(option) {
        switch (option.type) {
          case 'user':
            this.model.template.recipientEmails.addObject(option.user.email);
            break;

          case 'personnel':
            this.model.template.recipientEmails.addObject(option.personnel.email);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});