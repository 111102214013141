define("sky-router-3/pods/components/input-date/component", ["exports", "moment", "sky-router-3/utils/constants", "sky-router-3/utils/parse-date", "sky-router-3/utils/convert-date-timezone", "ember-local-storage"], function (_exports, _moment, _constants, _parseDate, _convertDateTimezone, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component presents the user with a date input field and gives them the
   * ability to specify if the day is in UTC or Local and if it should be displayed in
   * ISO, standard, or readable format.
   *
   * @example {{input-date date=(readonly date) updateDate=(action (mut date)) }}
   */
  var _default = Ember.Component.extend({
    classNames: ['input-group-flex'],
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    preferences: (0, _emberLocalStorage.storageFor)('user-preferences'),

    /**
     * Is the input disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Action placeholder of when a date is changed
     * @argument {Date}
     */
    updateDate: function updateDate()
    /* native js date */
    {},

    /**
     * The passed in date rendered into the component
     * @type {Date}
     */
    date: null,

    /**
     * What mode the input is in, 'single' or 'range'
     * @type {String}
     */
    mode: 'single',

    /**
     * Is the picke in a 24 hour time format or with AM/PM?
     * @type {Boolean}
     */
    timeFormat24Hour: Ember.computed.bool('sessionAccount.user.timeFormat'),

    /**
     * Indicates if the altFormat is shown or not
     * - readable (Fri July 22nd 2018)
     * - standard (2018-10-22 or similar)
     * - iso (ISO_8601)
     * @type {Number}
     */
    displayFormat: Ember.computed('preferences.dateInputDisplayFormat', function () {
      return this.preferences.get('dateInputDisplayFormat') || 'readable';
    }),

    /**
     * Should time be displayed or not.
     * @type {Boolean}
     */
    displayTime: true,
    displayDate: true,

    /**
     * Sets the name used for the radio buttons to be unique
     */
    init: function init() {
      this.set('_formatId', Ember.guidFor(this));

      this._super.apply(this, arguments);
    },

    /**
     * All possible timezones, powers selector dropdown
     */
    timeZones: _constants.timeZones,

    /**
     * The selected timezone that the picker runs off of.
     * By default this is set to the users timezone.
     * @type {[type]}
     */
    timeZone: Ember.computed.reads('_userTimeZone'),

    /**
     * Action that fires when a timezone is selected
     */
    timeZoneSelected: function timeZoneSelected(timeZone) {
      this.set('timeZone', timeZone);
    },

    /**
     * Static holders of the default timezones.
     */
    _utcTimeZone: _constants.timeZoneUtc,
    _userTimeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', function () {
      return _constants.timeZones.findBy('offsetMinutes', this.sessionAccount.user.get('timeZoneMinutes'));
    }),

    /**
     * radio input name field that must be unique to the component
     * @return {String}
     */
    _formatId: null,

    /**
     * The instance of the flatpickr component that is necessary in order to
     * trigger a date update on change of offset type.
     */
    _flatPickrInstance: null,

    /**
     * The working date inside this component. This allows us to translate to and from UTC.
     * @return {Date}
     */
    _date: Ember.computed('date', 'timeZone.offsetMinutes', function () {
      var _this = this;

      if (!this.date) {
        return null;
      }

      if (Array.isArray(this.date)) {
        return this.date.map(function (d) {
          return (0, _convertDateTimezone.default)(d, _this.timeZone.offsetMinutes, false);
        });
      }

      return (0, _convertDateTimezone.default)(this.date, this.timeZone.offsetMinutes, false);
    }),

    /**
     * Picker can't handle es-es or pt-br but can handle es & pt
     * @return {String}
     */
    _locale: Ember.computed('intl.locale.firstObject', function () {
      return this.intl.locale.firstObject.substring(0, 2);
    }),

    /**
     * Gets the date format string if necessary
     * @return {String}
     */
    _getTimeFormat: function _getTimeFormat() {
      if (this.displayTime) {
        return this.timeFormat24Hour ? 'H:mm' : 'h:mm A';
      }

      return '';
    },

    /**
     * Formats a date for display in the picker
     * @param  {Date} date Date to be displayed
     * @return {String}      String showing the date
     */
    _formatDate: function _formatDate(date) {
      switch (this.displayFormat) {
        case 'readable':
          return (0, _moment.default)(date).format("".concat(this.displayDate ? 'ddd LL ' : '').concat(this._getTimeFormat()));

        case 'standard':
          return (0, _moment.default)(date).format("".concat(this.displayDate ? 'l ' : '').concat(this._getTimeFormat()));

        default:
          // iso also
          if (this.displayDate) {
            return (0, _moment.default)(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          }

          return (0, _moment.default)(date).utc().format("HH:mm:ss.SSS") + "Z";
      }
    },
    actions: {
      onready: function onready(dates, dateStrings, instance) {
        this._flatPickrInstance = instance;

        this._flatPickrInstance.set('parseDate', Ember.run.bind(this, _parseDate.default, this.intl.locale));

        this._flatPickrInstance.set('formatDate', Ember.run.bind(this, this._formatDate));
      },
      onchange: function onchange(dates
      /*, dateString, instance*/
      ) {
        var _this2 = this;

        var converted = dates.map(function (d) {
          return (0, _convertDateTimezone.default)(d || new Date(), _this2.timeZone.offsetMinutes, true);
        });
        this.updateDate(this.mode == 'single' ? converted[0] : converted, this.mode);
      },
      updateDisplayFormat: function updateDisplayFormat() {
        if (this._flatPickrInstance) {
          this._flatPickrInstance.setDate(this._date);
        }

        this.preferences.set('dateInputDisplayFormat', this.displayFormat);
      }
    }
  });

  _exports.default = _default;
});