define("sky-router-3/pods/portal/operations/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xsk4wTqD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[\"misc.loadingPlaceholder\"],null]],[1,[34,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"loading-icon\"]}",
    "moduleName": "sky-router-3/pods/portal/operations/loading/template.hbs"
  });

  _exports.default = _default;
});