define("sky-router-3/pods/portal/track/info/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      if (!params.latitude || !params.longitude) {
        this.replaceWith('portal.track');
      }
    },
    actions: {
      close: function close() {
        this.transitionTo('portal.track');
      }
    }
  });

  _exports.default = _default;
});