define("sky-router-3/pods/components/portal/settings/security/support-pin/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    supportpin: [(0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 6
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    })],
    supportpinConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'supportpin'
    })
  });
  /**
   * This component is responsible for handling changing the users password.
   * It is self contained like this to isolate validation rules and other logic.
   *
   * @example
   * {{portal/settings/security/change-password isSaving=false onChangePassword='changePassword'}}
   */

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Service injections.
     */
    sessionAccount: Ember.inject.service(),
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Indicates that the form should be blocked because it is being
     * submitted.
     * @public
     * @property
     * @default false
     */
    isSaving: false,

    /**
     * Field to store the current pin number
     * will only be revealed when user clicks 'reveal'
     * @public
     * @property
     */
    currentSupportPin: '******',

    /**
     * Contains the new users password
     * @private
     * @property
     */
    supportpin: '',

    /**
     * Field used to confirm the users password
     * @private
     * @property
     */
    supportpinConfirmation: '',
    actions: {
      revealSupportPin: function revealSupportPin() {
        var _this = this;

        this.get('api').getUserSupportPin().then(function (pin) {
          _this.set('currentSupportPin', pin);
        });
      },

      /**
       * Fires the password change action and sends the following parameters
       * currentPassword, new password
       */
      submitChangeSupportPin: function submitChangeSupportPin() {
        var _this2 = this;

        this.set('isSaving', true);
        var pin = this.get('supportpin');
        this.get('api').userChangeSupportPin(pin).then(function () {
          _this2.notifications.success(_this2.intl.t('settings.userSupportPin.changeSupportPinSuccess'));

          _this2.set('currentSupportPin', '******');

          _this2.set('supportpin', '');

          _this2.set('supportpinConfirmation', '');
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('settings.userSupportPin.changeSupportPinFail'));
        }).finally(function () {
          _this2.set('isSaving', false);
        });
      }
    }
  });

  _exports.default = _default;
});