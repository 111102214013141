define("sky-router-3/pods/components/portal/manage/device-parameters/controls/time-specified-reporting/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onEnabled: function onEnabled() {},

    /**
     * Passed in settings
     * @type {[type]}
     */
    time1EnabledSetting: null,
    time1MinuteSetting: null,
    time2EnabledSetting: null,
    time2MinuteSetting: null,
    time3EnabledSetting: null,
    time3MinuteSetting: null,
    time4EnabledSetting: null,
    time4MinuteSetting: null,
    disabled: false,

    /**
     * Creates a local time based off of the miniutesSetting value.
     * This is needed for the picker to work.
     * @return {Moment}
     */
    _minutesToMoment: function _minutesToMoment(minutes) {
      var time = (0, _moment.default)();
      time.seconds(0);
      time.minutes(minutes % 60);
      time.hours(Math.floor(minutes / 60));
      return time;
    },
    time1: function () {
      return new Date(this.get('time1MinuteSetting.value') * 60000);
    }.property('time1MinuteSetting.value'),
    time2: function () {
      return new Date(this.get('time2MinuteSetting.value') * 60000);
    }.property('time2MinuteSetting.value'),
    time3: function () {
      return new Date(this.get('time3MinuteSetting.value') * 60000);
    }.property('time3MinuteSetting.value'),
    time4: function () {
      return new Date(this.get('time4MinuteSetting.value') * 60000);
    }.property('time4MinuteSetting.value'),
    actions: {
      setTime: function setTime(setting, value) {
        setting.set('value', value.getTime() / 60000);
      },
      timeEnabled: function timeEnabled(timeSetting, enabled) {
        if (enabled) {
          this.onEnabled();
        } else {
          timeSetting.rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});