define("sky-router-3/utils/lat-lng-converter", ["exports", "proj4"], function (_exports, _proj) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This latLng converter class is the main object used for converting from and to different units
   * Every method here is expected to be static. To use this class import it like any ES6 module.
   */
  var latLngConverter = {
    /**
     * Converts milliArcSeconds to minutes form +-0-59.9999999999
     */
    milliArcSecondsToMinutes: function milliArcSecondsToMinutes(milliArcSeconds) {
      return milliArcSeconds / 60000 % 60;
    },

    /**
     * Converts milliArcSeconds to seconds form +-0-59.9999999999
     */
    milliArcSecondsToSeconds: function milliArcSecondsToSeconds(milliArcSeconds) {
      return milliArcSeconds / 1000 % 60;
    },

    /**
     * Converts milliArcSeconds to seconds form +-0-180.1231231231123
     */
    milliArcSecondsToDecimalDeg: function milliArcSecondsToDecimalDeg(milliArcSeconds) {
      return milliArcSeconds / 3600000;
    },
    minutesToMilliArcSeconds: function minutesToMilliArcSeconds(minutes) {
      return minutes * 60000;
    },
    secondsToMilliArcSeconds: function secondsToMilliArcSeconds(seconds) {
      return seconds * 1000;
    },
    degreesToMilliArcSeconds: function degreesToMilliArcSeconds(degrees) {
      return Math.trunc(degrees * 3600000);
    },
    decimalDegreesToDegree: function decimalDegreesToDegree(decimalDegrees) {
      return this.truncate(decimalDegrees);
    },
    decimalDegreesToMinutes: function decimalDegreesToMinutes(decimalDegrees) {
      return this.truncate((decimalDegrees - this.decimalDegreesToDegree(decimalDegrees)) * 60);
    },
    decimalDegreesToSeconds: function decimalDegreesToSeconds(decimalDegrees) {
      return (decimalDegrees - this.decimalDegreesToDegree(decimalDegrees) - this.decimalDegreesToMinutes(decimalDegrees) / 60.0) * 3600;
    },

    /**
     * Converts decimal degree to milliArcSeconds form
     * @example 44.84029065139799 to 161425046
     */
    decimalDegToMilliArcSecond: function decimalDegToMilliArcSecond(decimalDegrees) {
      return Math.trunc(decimalDegrees * 3600000);
    },

    /**
     * Converts degree minute and seconds to a milliarcSeconds value
     * @param  {Number} degree [description]
     * @param  {Number} minute [description]
     * @param  {Number} second [description]
     * @return {Number}
     */
    degreeMinuteSecondsToMilliArc: function degreeMinuteSecondsToMilliArc(degree, minute, second) {
      var sign = this.isNegative(degree) ? -1 : 1;
      var deg = this.degreesToMilliArcSeconds(degree);
      var min = this.minutesToMilliArcSeconds(minute) * sign;
      var sec = this.secondsToMilliArcSeconds(second) * sign;
      return deg + min + sec;
    },

    /*
     * Converts a model that contains `latitudeMilliarcSeconds` and `longitudeMilliarcSeconds`
     * into a Google maps LatLng object
     * @returns {google.maps.LatLng}
     */
    modelToGoogleLatLng: function modelToGoogleLatLng(model) {
      if (model) {
        var lat = this.milliArcSecondsToDecimalDeg(model.get('latitudeMilliarcSeconds'));
        var lng = this.milliArcSecondsToDecimalDeg(model.get('longitudeMilliarcSeconds'));
        return new google.maps.LatLng(lat, lng);
      }
    },

    /**
     * Converts milliArcSeconds to deg, minutes, seconds
     * @example +-32 56' 3.960"
     */
    milliArcSecondsToDegMinSec: function milliArcSecondsToDegMinSec(milliArcSeconds) {
      var sign = milliArcSeconds < 0 ? "-" : "";
      var deg = Math.abs(this.milliArcSecondsToDecimalDeg(milliArcSeconds));
      var min = Math.abs(this.milliArcSecondsToMinutes(milliArcSeconds));
      var sec = Math.abs(this.milliArcSecondsToSeconds(milliArcSeconds));
      return "".concat(sign).concat(this.truncate(deg), "&deg; ").concat(this.truncate(min), "&#39; ").concat(this.truncate(sec * 1000) / 1000, "&#34;").htmlSafe();
    },
    decimalDegreesToDegMinSec: function decimalDegreesToDegMinSec(decimalDegrees) {
      var sign = decimalDegrees < 0 && decimalDegrees > -1 ? "-" : "";
      var deg = this.decimalDegreesToDegree(decimalDegrees);
      var min = Math.abs(this.decimalDegreesToMinutes(decimalDegrees));
      var sec = Math.abs(this.decimalDegreesToSeconds(decimalDegrees));
      return "".concat(sign).concat(deg, "&deg; ").concat(min, "&#39; ").concat(this.truncate(sec * 1000) / 1000, "&#34;").htmlSafe();
    },

    /**
     * Converts milliArcSeconds to deg, and decimal minutes
     * @example +-40° 26.767′
     * @param {number} milliArcSeconds
     * @returns {string} value ready for display
     */
    milliArcSecondsToDegDecimalMinutes: function milliArcSecondsToDegDecimalMinutes(milliArcSeconds) {
      var sign = milliArcSeconds < 0 ? "-" : "";
      var deg = Math.abs(this.milliArcSecondsToDecimalDeg(milliArcSeconds));
      var min = Math.abs(this.milliArcSecondsToMinutes(milliArcSeconds));
      return "".concat(sign).concat(this.truncate(deg), "&deg; ").concat(this.truncate(min * 1000) / 1000, "&#39;").htmlSafe();
    },

    /**
     * Takes a number and removes the decimals.
     * @param  {Number} number
     * @param {Number} decimals the number of decimal places to use
     * @return {Number}        input number without decimals.
     */
    truncate: function truncate(number, decimals) {
      if (decimals) {
        var places = Math.pow(10, decimals);
        return this.truncate(number * places) / places;
      } else {
        return number > 0 ? Math.floor(number) : Math.ceil(number);
      }
    },

    /**
     * Checks to see if a number is negative including -0
     * @see http://cwestblog.com/2014/02/25/javascript-testing-for-negative-zero/
     * @return {Boolean}   True if negative false if not
     */
    isNegative: function isNegative(n) {
      return ((n = +n) || 1 / n) < 0;
    },

    /**
     * Takes in a golgle maps LatLngBounds and creates a GIS BBOX string.
     * Returns null if the bounds is null
     * @param  {google.maps.latLngBounds} latLngBounds
     * @return {String}
     */
    getBBoxString: function getBBoxString(latLngBounds) {
      if (!latLngBounds) {
        return null;
      }

      var southWest = latLngBounds.getSouthWest();
      var northEast = latLngBounds.getNorthEast();
      var minLat = southWest.lat();
      var minLng = southWest.lng() > northEast.lng() ? -180 : southWest.lng();
      var maxLat = northEast.lat();
      var maxLng = northEast.lng() < southWest.lng() ? 180 : northEast.lng();
      return "".concat(minLng, ",").concat(minLat, ",").concat(maxLng, ",").concat(maxLat);
    },

    /**
     * Calculates a google maps bounds from an array of objects that contains
     * @param  {Array<Object>} positions array containing position objects
     * @return {google.maps.bounds}
     */
    getBounds: function getBounds(positions) {
      var latProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'latitudeMilliarcSeconds';
      var lngProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'longitudeMilliarcSeconds';
      var bounds = new google.maps.LatLngBounds();
      positions.forEach(function (w) {
        var latvalue = latLngConverter.milliArcSecondsToDecimalDeg(Ember.get(w, latProp));
        var lonvalue = latLngConverter.milliArcSecondsToDecimalDeg(Ember.get(w, lngProp));
        bounds.extend(new google.maps.LatLng(latvalue, lonvalue));
      });
      return bounds;
    },
    getBoundsDecimal: function getBoundsDecimal(positions) {
      var latProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'latitudeDegree';
      var lngProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'longitudeDegree';
      var bounds = new google.maps.LatLngBounds();
      positions.forEach(function (point) {
        bounds.extend(new google.maps.LatLng(Ember.get(point, latProp), Ember.get(point, lngProp)));
      });
      return bounds;
    },

    /**
     * Computes the total distance for a array of positions
     * @param  {[type]} positions                            [description]
     * @param  {String} [latProp='latitudeMilliarcSeconds']  [description]
     * @param  {String} [lngProp='longitudeMilliarcSeconds'] [description]
     * @return {[type]}                                      [description]
     */
    getPathDistance: function getPathDistance(positions) {
      var _this = this;

      var latProp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'latitudeMilliarcSeconds';
      var lngProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'longitudeMilliarcSeconds';
      var latLngs = positions.map(function (p) {
        return _this.modelToGoogleLatLng(p, latProp, lngProp);
      });
      return google.maps.geometry.spherical.computeLength(latLngs);
    },

    /**
     * Computes the bounds area in meters
     * @param  {google.maps.bounds} bounds
     * @return {Number}        Meters of the area of the bounds
     */
    getBoundsArea: function getBoundsArea(bounds) {
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();
      var nw = new google.maps.LatLng(ne.lat(), sw.lng()); // If we are zoomed out and can see the entire earth,
      // google maps likes to do shortest distance around the wrong way of the planet.

      if (ne.lng() == 180 && nw.lng() == -180) {
        return Math.pow(40075000, 2); // Circumference of earth in meters
      }

      var width = google.maps.geometry.spherical.computeDistanceBetween(ne, nw);
      var height = google.maps.geometry.spherical.computeDistanceBetween(nw, sw);
      return width * height;
    },

    /**
     * Utilizes the google maps computeOffset function to get a point that is some distance away
     * @param {[type]} position                             Model of position
     * @param {[type]} distance                             Distance in meters
     * @param {Number} [heading=0]                          Degree Heading
     * @param {String} [latProp='latitudeMilliarcSeconds']  [description]
     * @param {String} [lngProp='longitudeMilliarcSeconds'] [description]
     */
    getOffset: function getOffset(position, distance) {
      var heading = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
      var latProp = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'latitudeMilliarcSeconds';
      var lngProp = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'longitudeMilliarcSeconds';
      var latLng = this.modelToGoogleLatLng(position, latProp, lngProp);
      return google.maps.geometry.spherical.computeOffset(latLng, distance, heading);
    },

    /**
     * Converts UTM to Latitude and longitude
     * @see https://stackoverflow.com/a/18621244/1148118
     * @param {*} zone 
     * @param {*} hemisphere 'N' or 'S' 
     * @param {*} easting 
     * @param {*} northing 
     * @returns 
     */
    utmToLatLng: function utmToLatLng(zone, hemisphere, easting, northing) {
      var utm = "+proj=utm +zone=".concat(zone, " +").concat(hemisphere == 'N' ? 'north' : 'south');
      var wgs84 = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
      var result = (0, _proj.default)(utm, wgs84, [parseInt(easting), parseInt(northing)]);
      console.log(result);
      return result;
    }
  };
  var _default = latLngConverter;
  _exports.default = _default;
});