define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-4/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AcGfbZ+7",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.output.flag.help\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.output.flag.help2\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compoundrules.output.flag.label\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],null,[[\"options\",\"selected\",\"disabled\",\"onChange\"],[[35,5],[35,4],[35,3],[30,[36,2],[[32,0],\"flagChanged\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[1,[30,[36,8],null,[[\"errors\"],[[35,7,[\"attrs\",\"model\",\"value1\",\"messages\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"action\",\"disabled\",\"flagSelected\",\"flags\",\"input-select\",\"validations\",\"input-errors\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-4/template.hbs"
  });

  _exports.default = _default;
});