define("sky-router-3/pods/portal/manage/alerts/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Binding the query parameters to the search field
     * @type {Array}
     */
    queryParams: ['search'],

    /**
     * Holds a text value that is being used for searching
     * @type {String}
     */
    search: null,

    /**
     * Filters the alerts based on the search chritera
     */
    filteredAlerts: Ember.computed.filter('model.alerts', ['search', 'model.alerts.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search) || (0, _stringSearch.default)(item.get('alertTriggerEvent.name'), search) || (0, _stringSearch.default)(item.get('subject'), search) || (0, _stringSearch.default)(item.get('message'), search) || item.get('recipients').any(function (x) {
          return (0, _stringSearch.default)(x, search);
        });
      }

      return true;
    }),

    /**
     * The property by which locations are sorted
     * @type {String}
     */
    sortProperty: 'name',

    /**
     * The direction that the list is sorted
     * @type {Boolean}
     */
    sortDesc: false,

    /**
     * Returns a string used by sortedLocations to sort the collection
     * @return {Array<string>}
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),

    /**
     * Locations that have been sorted
     */
    sortedAlerts: Ember.computed.sort('filteredAlerts', 'sortBy'),
    actions: {
      changeSort: function changeSort() {
        this.toggleProperty('sortDesc');
      },
      deleteAlert: function deleteAlert(alert) {
        var _this = this;

        if (confirm(this.intl.t('alerts.deleteAlertConfirm', {
          name: alert.get('name')
        }))) {
          alert.destroyRecord().catch(function () {
            _this.notifications.error(_this.intl.t('alerts.deleteAlertFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});