define("sky-router-3/instance-initializers/log-errors", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * This attaches to the ember on error event and reports errors that occur
   * back to the server. It does not run under any mode but production.
   */
  function initialize(instance) {
    var api = instance.lookup('service:api');

    if (_environment.default.APP.reportErrors) {
      Ember.onerror = function (error) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        api.logException.apply(api, [error].concat(args)).catch(function () {});
      };
    }
  }

  var _default = {
    name: 'log-errors',
    initialize: initialize
  };
  _exports.default = _default;
});