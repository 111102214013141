define("sky-router-3/helpers/map-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Allows for executing a map on an array of objects
   * The second arg can either be a string to mapBy or a function to execute with map
   */
  var _default = Ember.Helper.helper(function mapBy(params
  /*, hash*/
  ) {
    if (Array.isArray(params[0]) && params.length > 1) {
      if (typeof params[1] === "function") {
        return params[0].map(params[1]);
      }

      return params[0].mapBy(params[1]);
    }

    return params[0];
  });

  _exports.default = _default;
});