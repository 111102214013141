define("sky-router-3/utils/map-lat-lng-to-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mapLatLngToPoint;

  /*global google*/

  /**
   * This converts a latLng value into a point value which can be used
   * to figure out its placement on the map
   *
   * @param {google.maps.Map} - the map you want to find pixel cords based on
   * @param {google.maps.LatLng} - the latLng to turn into pixel cords
   * @returns {google.maps.Point} - the pixel cords on the screen.
   * @see https://stackoverflow.com/questions/11880441/how-to-convert-latlng-to-x-y-pixels-and-vice-versa
   */
  function mapLatLngToPoint(map, latLng) {
    var overlay = new google.maps.OverlayView();

    overlay.draw = function () {};

    overlay.setMap(map);
    var proj = overlay.getProjection();
    return proj.fromLatLngToContainerPixel(latLng);
  }
});