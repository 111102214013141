define("sky-router-3/pods/portal/communicate/folder/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This route handles displaying of message summaries in a folder.
   */
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Loads the model folder and properties. If the model fails to load it
     * redirects them back to the root of the route.
     */
    model: function model(params) {
      return this.store.findRecord('mail-folder', params.folder_id, {
        reload: true
      });
    },
    actions: {
      /**
       * When the model rejects because of an error
       */
      error: function error() {
        this.notifications.error(this.intl.t('communicate.errorFolderNotFound'));
        this.replaceWith('portal.communicate');
      },

      /**
       * Reloads the curent routes model
       */
      reloadFolder: function reloadFolder() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});