define("sky-router-3/pods/portal/settings/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HzqHUTwl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[1,[30,[36,3],[\"settings.headerNotifications\"],null]],[13],[2,\" \"],[1,[30,[36,4],null,[[\"user\"],[[35,0,[\"user\"]]]]]],[2,\"\\n\"],[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[10,\"strong\"],[12],[1,[30,[36,3],[\"emailNotification.title\"],null]],[13],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"emailNotification.help\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"checked\"],[[35,0,[\"user\",\"receiveSystemNotifications\"]]]]]],[2,\" \"],[1,[30,[36,3],[\"emailNotification.checkbox\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,8],null,[[\"user\",\"events\",\"eventsSelected\",\"onreset\"],[[35,0,[\"user\"]],[35,7],[35,0,[\"pushAlertTrigger\",\"alertTriggerEvents\"]],[30,[36,6],[\"reset\"],null]]]]],[2,\"\\n\"],[6,[37,10],[[35,9,[\"showAdvancedSettings\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"sounds\",\"assetPositionTypes\"],[[35,0,[\"userSounds\"]],[35,0,[\"assetPositionTypes\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"portal/settings/notification-sounds\",\"input-checkbox\",\"t\",\"portal/settings/event-notification-timeout\",\"input-label\",\"route-action\",\"eventOptions\",\"portal/settings/push-notifications\",\"sessionAccount\",\"if\"]}",
    "moduleName": "sky-router-3/pods/portal/settings/notifications/template.hbs"
  });

  _exports.default = _default;
});