define("sky-router-3/helpers/config-env", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.configEnv = configEnv;
  _exports.default = void 0;

  /**
   * Gets values from the config/environment file
   * @param {Array} params with the first param being the string path to get.
   */
  function configEnv(params, hash) {
    var value = Ember.get(_environment.default, params[0]);

    if (hash.append) {
      value += hash.append;
    }

    return value;
  }

  var _default = Ember.Helper.helper(configEnv);

  _exports.default = _default;
});