define("sky-router-3/pods/components/portal/manage/device-parameters/types/he5300/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,

    /**
     * Alias'd settings that are easier to get at.
     */
    gsmTimeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_time_interval_reporting_enabled'),
    gsmTimeReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'gsm_time_interval_reporting_seconds'),
    gsmDistanceReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_distance_reporting_enabled'),
    gsmDistanceReportingMeter: (0, _computedFindBy.default)('settings', 'key', 'gsm_distance_reporting_meters'),
    gsmRoamingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_roaming_enabled'),
    gsmPollingSeconds: (0, _computedFindBy.default)('settings', 'key', 'gsm_messagepolling_seconds'),
    eventReportingOnlyEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_reporting_only'),
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_enabled'),
    timeReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_seconds'),
    distanceReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_enabled'),
    distanceReportingMeter: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_meters'),
    deviceNetworkMode: (0, _computedFindBy.default)('settings', 'key', 'device_network_mode'),
    deviceNetworkModeTimeout: (0, _computedFindBy.default)('settings', 'key', 'device_network_mode_timeout_seconds'),
    quickReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'quick_position_seconds'),
    quickReportingLimit: (0, _computedFindBy.default)('settings', 'key', 'quick_position_limit'),
    speedEnabled: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_enabled'),
    speedIntervalSeconds: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_interval_seconds'),
    speedTransitionTime: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_transition_seconds'),
    speedLimitKph: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_limit_kph'),
    startMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'start_movement_enabled'),
    startMovementDelaySeconds: (0, _computedFindBy.default)('settings', 'key', 'start_movement_delay_seconds'),
    stopMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_enabled'),
    stopMovementDelaySeconds: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_delay_seconds'),
    stopMovementReportSeconds: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_report_seconds'),
    obdOdometerEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_odometer_enabled'),
    obdFueldEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_fuel_enabled'),
    obdDtcEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_dtc_enabled'),

    /**
     * Powers the network mode dropdown.
     */
    networkModeSelected: function () {
      return this.get('networkModeOptions').findBy('id', this.get('deviceNetworkMode.value'));
    }.property('deviceNetworkMode.value'),
    networkModeOptions: function () {
      var intl = this.get('intl');
      return [{
        id: '0',
        label: intl.t('parameter.he5300.gsmOnly')
      }, {
        id: '1',
        label: intl.t('parameter.he5300.dualMode')
      }, {
        id: '2',
        label: intl.t('parameter.he5300.iridiumOnly')
      }];
    }.property(),

    /**
     * Shows and hides the GSM tab when in the corresponding mode
     */
    showGSM: function () {
      var mode = this.get('deviceNetworkMode.value');
      return mode === '0' || mode === '1';
    }.property('deviceNetworkMode.value'),

    /**
     * Shows and hides the Iridium tab when in the corresponding mode
     */
    showIridium: function () {
      var mode = this.get('deviceNetworkMode.value');
      return mode === '1' || mode === '2';
    }.property('deviceNetworkMode.value'),
    actions: {
      networkChange: function networkChange(selection) {
        this.set('deviceNetworkMode.value', selection.id);
      }
    }
  });

  _exports.default = _default;
});