define("sky-router-3/pods/portal/manage/geofences/edit/3d/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * Displayable altitude bounds.
     */
    lowerAltitudeUnitSelected: Ember.computed.oneWay('sessionAccount.user.altitude'),
    lowerAltitudeEnabled: Ember.computed('model.geofence.lowerAltitudeBoundsCm', {
      get: function get() {
        return this.get('model.geofence.lowerAltitudeBoundsCm') != null;
      },
      set: function set(key, value) {
        this.set('model.geofence.lowerAltitudeBoundsCm', !value ? null : 300);
        return value;
      }
    }),

    /**
     * Displayable upper altitude bounds.
     */
    upperAltitudeUnitSelected: Ember.computed.oneWay('sessionAccount.user.altitude'),
    upperAltitudeEnabled: Ember.computed('model.geofence.upperAltitudeBoundsCm', {
      get: function get() {
        return this.get('model.geofence.upperAltitudeBoundsCm') != null;
      },
      set: function set(key, value) {
        if (!value) {
          this.set('model.geofence.upperAltitudeBoundsCm', null);
        }

        return value;
      }
    })
  });

  _exports.default = _default;
});