define("sky-router-3/pods/portal/manage/device-profiles/index/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        types: this.store.findAll('asset-profile-set-type')
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-profiles');
      },
      create: function create(controller) {
        var _this = this;

        controller.set('blockForm', true);
        this.store.createRecord('asset-profile-set', {
          name: controller.get('name'),
          type: controller.get('profileType')
        }).save().then(function () {
          _this.transitionTo('portal.manage.device-profiles');

          _this.notifications.success(_this.intl.t('profiles.newSuccess'));
        }).catch(function () {
          _this.notifications.error(_this.intl.t('profiles.newFail'));
        }).finally(function () {
          controller.set('blockForm', true);
        });
      }
    }
  });

  _exports.default = _default;
});