define("sky-router-3/models/trip-position", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIP_EVENT_REPORTING_STATUS = void 0;
  var TRIP_EVENT_REPORTING_STATUS = {
    normal: '1',
    MER: '2',
    MER_Warning: '3',
    MER_Danger: '4',
    Emergency: '5'
  };
  _exports.TRIP_EVENT_REPORTING_STATUS = TRIP_EVENT_REPORTING_STATUS;

  var _default = _model.default.extend({
    position: (0, _model.belongsTo)('asset-position'),
    positionType: (0, _model.belongsTo)('asset-position-type'),
    positionDateUtc: (0, _model.attr)('date'),
    status: (0, _model.belongsTo)('trip-status'),

    /**
     * The current reporting phase of the trip.
     * ex. 'Normal', 'Missed Expected Report', 'Emergency'
     * @type {sky-router-3/models/trip-reporting-status}
     */
    reportingStatus: (0, _model.belongsTo)('trip-reporting-status'),

    /**
    * Is the status normal or emergency?
    */
    isReportingStatusNormal: Ember.computed.equal('reportingStatus.id', TRIP_EVENT_REPORTING_STATUS.normal)
  });

  _exports.default = _default;
});