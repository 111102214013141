define("sky-router-3/pods/components/portal/report/deviceinventory-grid/component", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Holds a reference to the API namespace which is the part of
     * the path the api lives at after the rootURL of the app
     */
    namespace: _environment.default.API.namespace,

    /**
     * Indicates if the no records found message is to be shown
     * @type {Boolean}
     */
    showNoRecordMessage: false,

    /**
    * Indicates whether or not the loading message is shown for the report generation
    */
    loading: true,

    /**
    * The report result set
    * @type {EmberObject}
    */
    reportData: null,

    /**
    * The total number of records in the result set
    */
    totalRecords: 0,

    /**
    * The start index of the results
    */
    startIndex: 1,

    /**
    * The end index of the results
    */
    endIndex: 0,

    /**
    * Flags to toggle display of table columns
    */
    showName: true,
    showRegistration: true,
    showType: true,
    showSerial: true,
    showImei: true,
    showPhone: true,
    showPlan: true,
    showStatus: true,
    showDateActivated: true,
    showLastReportDate: true,

    /**
    * Returns user selected columns to display in the report
    * @public
    * @function
    */
    activeDisplayedColumns: function () {
      this.setDisplayedColumns();
      return this.get('displayedColumns').filterBy('checked', true);
    }.property('displayedColumns.@each.checked'),

    /**
    * Toggle the display of user selected columns
    */
    setDisplayedColumns: function setDisplayedColumns() {
      this.set('showName', this.shouldShowCol('AssetName'));
      this.set('showRegistration', this.shouldShowCol('AssetId'));
      this.set('showType', this.shouldShowCol('DeviceType'));
      this.set('showSerial', this.shouldShowCol('DeviceSerialNumber'));
      this.set('showImei', this.shouldShowCol('DeviceIMEI'));
      this.set('showPhone', this.shouldShowCol('DevicePhoneNumber'));
      this.set('showPlan', this.shouldShowCol('ServicePlan'));
      this.set('showStatus', this.shouldShowCol('Status'));
      this.set('showDateActivated', this.shouldShowCol('DateActivated'));
      this.set('showLastReportDate', this.shouldShowCol('LastReportDate'));
    },

    /**
    * Checks columns against user selections to determine if it should be displayed
    */
    shouldShowCol: function shouldShowCol(colName) {
      return this.get('displayedColumns').some(function (col) {
        return col.value === colName && col.checked === true;
      });
    },

    /**
     * On init the report should be generated with default parameters or the ones passed to the route
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this.generateReport();
    },

    /**
     * This is the function that triggers the report to be rendered.
     * @function
     * @private
     */
    onGenerateReportClicked: function () {
      this.generateReport();
    }.observes('generateReportClicked'),

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport() {
      var _this = this;

      // Get report parameters
      var visibleColumnsList = encodeURIComponent(this.get('displayedColumnsList'));
      this.set('loading', true); // Call the API

      this.get('api').getDeviceInventoryReportData(visibleColumnsList).then(function (data) {
        _this.set('reportData', data.rows);

        _this.set('totalRecords', data.records);

        _this.set('endIndex', data.total);

        _this.set('loading', false);
      });
    }
  });

  _exports.default = _default;
});