define("sky-router-3/pods/portal/communicate/route", ["exports", "sky-router-3/models/mail-folder", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _mailFolder, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.communicate');
    },

    /**
     * Only users with communicate permission is allowed to access this route
     * and sub routes. This is a property from the `PermissionRequiredMixin`
     * @type {Array}
     */
    permissions: ['communicate'],

    /**
     * Returns an array of all the folders present for the user
     * @return {sky-router-3/models/mail-folder}
     */
    model: function model() {
      return this.store.findAll('mail-folder');
    },

    /**
     * Generates a new folder name that hasn't been taken yet
     * @return {String} A unique folder name
     */
    generateNewFolderName: function generateNewFolderName() {
      var model = this.modelFor(this.routeName);
      var newFolderName = this.intl.t('communicate.newFolderName').toString();
      var name = newFolderName;
      var count = 0;

      while (model.isAny('name', name)) {
        name = "".concat(newFolderName, " ").concat(++count);
      }

      return name;
    },
    actions: {
      /**
       * Adds a new folder under the reserved INBOX folder
       * @return {Ember.RSVP.Promise}
       */
      addFolder: function addFolder() {
        var _this = this;

        // Check if the user is sure
        if (!confirm(this.intl.t('communicate.createNewFolderConfirm'))) {
          return;
        } // Create the record


        var record = this.store.createRecord('mail-folder', {
          name: this.generateNewFolderName(),
          parentFolder: this.store.peekRecord('mail-folder', _mailFolder.MAIL_FOLDERS.inbox)
        }); // Save the record to the server

        return record.save().catch(function () {
          _this.notifications.error(_this.intl.t('communicate.createNewFolderFail'));

          record.deleteRecord();
        });
      },

      /**
       * Deletes a message from the server permanently
       * @param {sky-router-3/models/mail-message}
       */
      deleteMessage: function deleteMessage(message) {
        var _this2 = this;

        if (confirm(this.intl.t('communicate.deleteMessagePermConfirm'))) {
          var folder = message.get('folder.id');
          message.destroyRecord().then(function () {
            _this2.transitionTo('portal.communicate.folder', folder);
          }).catch(function () {
            _this2.notifications.error(_this2.intl.t('communicate.deleteMessagePermFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});