define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-13/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.value1': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    flags: function () {
      var intl = this.get('intl');
      return [{
        label: intl.t('compoundrules.condition.flags.externalApplied'),
        value: '0'
      }, {
        label: intl.t('compoundrules.condition.flags.batteryLow'),
        value: '1'
      }, {
        label: intl.t('compoundrules.condition.flags.gpsBad'),
        value: '2'
      }, {
        label: intl.t('compoundrules.condition.flags.irdBad'),
        value: '3'
      }, {
        label: intl.t('compoundrules.condition.flags.isqpos'),
        value: '4'
      }, {
        label: intl.t('compoundrules.condition.flags.isMoving'),
        value: '5'
      }, {
        label: intl.t('compoundrules.condition.flags.isSpeeding'),
        value: '6'
      }, {
        label: intl.t('compoundrules.condition.flags.isInAir'),
        value: '7'
      }, {
        label: intl.t('compoundrules.condition.flags.isAboveAlt'),
        value: '9'
      }, {
        label: intl.t('compoundrules.condition.flags.isInConservation'),
        value: '10'
      }, {
        label: intl.t('compoundRules.condition.flags.isRinging'),
        value: '11'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 1
        }),
        value: '21'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 2
        }),
        value: '22'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 3
        }),
        value: '23'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 4
        }),
        value: '24'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 5
        }),
        value: '25'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 6
        }),
        value: '26'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 7
        }),
        value: '27'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 8
        }),
        value: '28'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 9
        }),
        value: '29'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 10
        }),
        value: '30'
      }, {
        label: intl.t('compoundrules.condition.flags.customFlag', {
          number: 11
        }),
        value: '31'
      }];
    }.property(),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model.relationalOperator', 2); // Must be initialized to '='
    },
    actions: {
      setSelection: function setSelection(selection) {
        this.set('model.value1', selection.value);
      }
    }
  });

  _exports.default = _default;
});