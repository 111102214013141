define("sky-router-3/pods/account/password/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The session account service that holds all the information about the
     * users session.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('account.password.title');
    },
    beforeModel: function beforeModel() {
      if (!this.get('sessionAccount.user.passwordExpired')) {
        this.replaceWith('portal.track');
      }
    },
    actions: {
      passwordChanged: function passwordChanged() {
        var _this = this;

        this.get('sessionAccount.user').reload().then(function () {
          _this.replaceWith('portal.track');
        });
      }
    }
  });

  _exports.default = _default;
});