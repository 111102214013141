define("sky-router-3/models/asset-position-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.POSITION_ICONS = _exports.POSITION_TYPES = _exports.default = _exports.POSITION_METADATA_ICON = void 0;

  /* eslint-env node */

  /**
   * This contains a static object that can be used
   * with comparison operations against the position type
   * of a position object.
   *
   * This should be used instead of hard coded === '0' operations.
   */
  var POSITION_TYPES = {
    NormalPositionReport: '0',
    NormalBreadcrumb: '431',
    TakeOff: '13',
    Landing: '14',
    OffGate: '17',
    InGate: '18',
    MinEnrouteAlt: '19',
    AckProfileUpdate: '23',
    PingReply: '24',
    ImagePopint: '25',
    Speeding: '26',
    PowerOn: '27',
    PowerOff: '28',
    Movement: '29',
    Available: '30',
    Start: '31',
    Stop: '32',
    PickingUp: '33',
    DroppingOff: '34',
    PowerActive: '35',
    NotMoving: '36',
    TemperatureLow: '37',
    TemperatureHigh: '38',
    EnterGeoArea: '39',
    ExitGeoArea: '40',
    GeofenceParameterUpdate: '41',
    AckGeofenceParameterUpdate: '42',
    Waypoint: '44',
    LowBattery: '45',
    CheckIn: '46',
    DigitalEvent: '51',
    DTCCode: '55',
    EnterNoGoArea: '56',
    ExitNoGoArea: '57',
    IdCard: '67',
    LbtPowerCycle: '99',
    ShortCode01: '101',
    ShortCode02: '102',
    ShortCode03: '103',
    ShortCode04: '104',
    ShortCode05: '105',
    ShortCode06: '106',
    ShortCode07: '107',
    ShortCode08: '108',
    ShortCode09: '109',
    ShortCode10: '110',
    CompoundRule01: '138',
    CompoundRule02: '139',
    CompoundRule03: '140',
    CompoundRule04: '141',
    CompoundRule05: '142',
    CompoundRule06: '143',
    CompoundRule07: '144',
    CompoundRule08: '145',
    CompoundRule09: '146',
    CompoundRule10: '147',
    CompoundRule11: '148',
    CompoundRule12: '149',
    CompoundRule13: '150',
    CompoundRule14: '151',
    CompoundRule15: '152',
    UnauthorizedMovement: '153',
    SuddenBraking: '154',
    SuddenAcceleration: '155',
    SuddenImpact: '156',
    RollOver: '246',
    DepotEnter: '247',
    DepotExit: '248',
    EngineOff: '249',
    EngineOn: '250',
    Idling: '251',
    PlugAndPlay: '252',
    GPSMark: '253',
    QuickPositionCancel: '254',
    QuickPosition: '255',
    TrackingSuspend: '260',
    OffBlocks: '263',
    OnBlocks: '264',
    Inactive: '300',
    Maintenance: '301',
    Delayed: '302',
    Active: '304',
    ManualPosition: '305',
    MissedExpectedReport: '306',
    MissedExpectedReportWarn: '307',
    MissedExpectedReportDanger: '308',
    QuickPositionAcknowledge: '309',
    TrackingResumedAuto: '413',
    TrackingResumedManual: '414',
    GeofenceDelete: '996',
    AckGeofenceDelete: '997',
    isNormalType: function isNormalType(id) {
      return id == this.NormalPositionReport || id == this.NormalBreadcrumb;
    }
  };
  _exports.POSITION_TYPES = POSITION_TYPES;
  var POSITION_ICONS = {
    0: '/skyrouter3/assets/images/events/event_normalpositionreport.svg',
    431: '/skyrouter3/assets/images/events/event_normalbreadcrumb.svg',
    13: '/skyrouter3/assets/images/events/event_takeoff.svg',
    14: '/skyrouter3/assets/images/events/event_landing.svg',
    17: '/skyrouter3/assets/images/events/event_offgate.svg',
    18: '/skyrouter3/assets/images/events/event_ingate.svg',
    19: '/skyrouter3/assets/images/events/event_minenroutealt.svg',
    24: '/skyrouter3/assets/images/events/event_pingreply.svg',
    26: '/skyrouter3/assets/images/events/event_speeding.svg',
    27: '/skyrouter3/assets/images/events/event_poweron.svg',
    28: '/skyrouter3/assets/images/events/event_poweroff.svg',
    29: '/skyrouter3/assets/images/events/event_movement.svg',
    30: '/skyrouter3/assets/images/events/event_available.svg',
    31: '/skyrouter3/assets/images/events/event_start.svg',
    32: '/skyrouter3/assets/images/events/event_stop.svg',
    33: '/skyrouter3/assets/images/events/event_pickingup.svg',
    34: '/skyrouter3/assets/images/events/event_droppingoff.svg',
    35: '/skyrouter3/assets/images/events/event_active.svg',
    36: '/skyrouter3/assets/images/events/event_notmoving.svg',
    38: '/skyrouter3/assets/images/events/event_temperaturehigh.svg',
    39: '/skyrouter3/assets/images/events/event_entergeofence.svg',
    40: '/skyrouter3/assets/images/events/event_exitgeofence.svg',
    44: '/skyrouter3/assets/images/events/event_waypoint.svg',
    45: '/skyrouter3/assets/images/events/event_lowbattery.svg',
    46: '/skyrouter3/assets/images/events/event_checkin.svg',
    51: '/skyrouter3/assets/images/events/event_digitaleventanalog.svg',
    55: '/skyrouter3/assets/images/events/event_dtc.svg',
    56: '/skyrouter3/assets/images/events/event_enternogoarea.svg',
    57: '/skyrouter3/assets/images/events/event_exitnogoarea.svg',
    59: '/skyrouter3/assets/images/events/event_externalpoweron.svg',
    60: '/skyrouter3/assets/images/events/event_externalpoweroff.svg',
    61: '/skyrouter3/assets/images/events/event_bluetoothconnected.svg',
    62: '/skyrouter3/assets/images/events/event_bluetoothdisconnected.svg',
    67: '/skyrouter3/assets/images/events/event_idcard.svg',
    138: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    139: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    140: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    141: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    142: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    143: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    144: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    145: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    146: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    147: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    148: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    149: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    150: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    151: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    152: '/skyrouter3/assets/images/events/event_compoundrule.svg',
    153: '/skyrouter3/assets/images/events/event_unauthorizedmovement.svg',
    154: '/skyrouter3/assets/images/events/event_hardbraking.svg',
    155: '/skyrouter3/assets/images/events/event_excessiveacceleration.svg',
    156: '/skyrouter3/assets/images/events/event_suddenimpact.svg',
    246: '/skyrouter3/assets/images/events/event_rollover.svg',
    247: '/skyrouter3/assets/images/events/event_depotenter.svg',
    248: '/skyrouter3/assets/images/events/event_depotexit.svg',
    249: '/skyrouter3/assets/images/events/event_engineoff.svg',
    250: '/skyrouter3/assets/images/events/event_engineon.svg',
    254: '/skyrouter3/assets/images/events/event_quickpositioncancel.svg',
    255: '/skyrouter3/assets/images/events/event_quickposition.svg',
    263: '/skyrouter3/assets/images/events/event_offgate.svg',
    264: '/skyrouter3/assets/images/events/event_ingate.svg',
    260: '/skyrouter3/assets/images/events/event_trackingsuspend.svg',
    300: '/skyrouter3/assets/images/events/event_inactive.svg',
    304: '/skyrouter3/assets/images/events/event_active.svg',
    305: '/skyrouter3/assets/images/events/event_manual.svg',
    306: '/skyrouter3/assets/images/events/event_missedexpectedreport.svg',
    307: '/skyrouter3/assets/images/events/event_missedexpectedreportwarn.svg',
    308: '/skyrouter3/assets/images/events/event_missedexpectedreportdanger.svg',
    309: '/skyrouter3/assets/images/events/event_quickpositionacknowledge.svg',
    413: '/skyrouter3/assets/images/events/event_trackingresumed.svg',
    414: '/skyrouter3/assets/images/events/event_trackingresumed.svg',
    426: '/skyrouter3/assets/images/events/event_externalpoweroff.svg',
    428: '/skyrouter3/assets/images/events/event_deadmanswitch.svg',
    429: '/skyrouter3/assets/images/events/event_suddenimpact.svg',
    unknown: '/skyrouter3/assets/images/events/event_miscellaneousevent.svg'
  };
  _exports.POSITION_ICONS = POSITION_ICONS;
  var POSITION_METADATA_ICON = {
    1: POSITION_ICONS[13],
    // Take off
    55: POSITION_ICONS[13],
    // Take off
    2: POSITION_ICONS[14],
    // Landing
    56: POSITION_ICONS[14] // Landing

  };
  /**
   * Holds information on the position report type.
   * This mirrors the database table `dbo.positionReportType`
   *
   * The `id` for this model maps to the `PositionReportType`
   * database field.
   */

  _exports.POSITION_METADATA_ICON = POSITION_METADATA_ICON;

  var model = _model.default.extend({
    /**
     * The display name for this position type.
     * It should NOT be used to compare types.
     */
    name: (0, _model.attr)('string'),

    /**
     * Classification of the event type. This is
     * not really used at the moment and mirrors the
     * name for the most part.
     */
    code: (0, _model.attr)('string'),

    /**
     * Will this type cause a new asset trip to begin (by default)
     */
    isTripStart: (0, _model.attr)('boolean'),

    /**
     * Can this event code start a trip if the user selects it
     */
    canStartTrip: (0, _model.attr)('boolean'),

    /**
     * Will this type cause a current asset trip to end
     */
    isTripStop: (0, _model.attr)('boolean'),

    /**
     * Can this event code stop a trip if the user selects it
     */
    canStopTrip: (0, _model.attr)('boolean'),

    /**
     * Did the message come from a device or did it originate
     * on the server.
     */
    isDeviceMessage: (0, _model.attr)('boolean'),

    /**
     * Can alerts be generated from this event type
     * when it occurs.
     */
    supportAlertAction: (0, _model.attr)('boolean'),

    /**
     * Around 3 letter code name for the type. Not all
     * events have one of these.
     */
    shortName: (0, _model.attr)('string'),

    /**
     * This is a mapped property to the user-sound model. This is not serialized
     * with the type as it comes from the server as it would mess up HTTP caching
     * It is the reverse property of the 'type' property in the userSound model.
     */
    sound: (0, _model.belongsTo)('user-sound', {
      async: true
    }),

    /**
     * Should the sound be looped when played or not
     */
    soundLoop: (0, _model.attr)('boolean'),

    /**
     * Indicates if the event is an emergency type, when an event is emergency
     * type it means different things will happen in terms of alerts and messages.
     * @return {boolean}
     */
    isEmergency: (0, _model.attr)('boolean'),

    /**
     * Presents an URL for the event icon svg file
     * @return {string} URL
     */
    iconUrl: function () {
      var url = POSITION_ICONS[this.get('id')];

      if (!url) {
        return POSITION_ICONS['unknown'];
      }

      return url;
    }.property('id'),

    /**
     * Holds the color value for this event, Not all events
     * have a color value. When they don't null will be returned.
     * @type {string}
     */
    color: function () {
      switch (this.get('id')) {
        case POSITION_TYPES.TakeOff:
        case POSITION_TYPES.Start:
          return '#99CC66';

        case POSITION_TYPES.Movement:
          return '#4F9F76';

        case POSITION_TYPES.Landing:
        case POSITION_TYPES.Stop:
          return '#FFCC99';

        case POSITION_TYPES.NotMoving:
          return '#FFBC5E';

        case POSITION_TYPES.OffGate:
        case POSITION_TYPES.InGate:
        case POSITION_TYPES.PickingUp:
        case POSITION_TYPES.DroppingOff:
        case POSITION_TYPES.OffBlocks:
        case POSITION_TYPES.OnBlocks:
          return '#EAA7A7';

        case POSITION_TYPES.ShortCode01:
        case POSITION_TYPES.ShortCode02:
        case POSITION_TYPES.ShortCode03:
        case POSITION_TYPES.ShortCode04:
        case POSITION_TYPES.ShortCode05:
        case POSITION_TYPES.ShortCode06:
        case POSITION_TYPES.ShortCode07:
        case POSITION_TYPES.ShortCode08:
        case POSITION_TYPES.ShortCode09:
        case POSITION_TYPES.ShortCode10:
        case POSITION_TYPES.MinEnrouteAlt:
        case POSITION_TYPES.Available:
        case POSITION_TYPES.MissedExpectedReport:
          return '#AACCFF';

        case POSITION_TYPES.EnterGeoArea:
        case POSITION_TYPES.ExitGeoArea:
        case POSITION_TYPES.MissedExpectedReportWarn:
        case POSITION_TYPES.DepotEnter:
        case POSITION_TYPES.DepotExit:
          return '#FF851B';

        case POSITION_TYPES.Speeding:
          return '#6487E0';

        case POSITION_TYPES.EnterNoGoArea:
        case POSITION_TYPES.ExitNoGoArea:
        case POSITION_TYPES.MissedExpectedReportDanger:
        case POSITION_TYPES.QuickPosition:
        case POSITION_TYPES.QuickPositionCancel:
          return '#FC320E';

        case POSITION_TYPES.Inactive:
          return '#FFFFAA';

        case POSITION_TYPES.NormalPositionReport:
        case POSITION_TYPES.NormalBreadcrumb:
          return null;

        default:
          return '#D3D3D3';
      }
    }.property('id'),

    /**
     * Is the event a single occurence or one that is opened and closed?
     * @return {Boolean}
     */
    isIncidentSingleEvent: function () {
      return this.get('id') !== POSITION_TYPES.QuickPosition;
    }.property('id')
  });

  var _default = model;
  _exports.default = _default;
});