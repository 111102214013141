define("sky-router-3/pods/public/login/forgot/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    email: (0, _emberCpValidations.validator)('email', {
      dependentKeys: ['model.intl.locale.firstObject']
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * Reference the HTTP Api service
     */
    api: Ember.inject.service(),

    /**
     * locale has to be consumed in order for depndent key in VALIDATIONS
     * to activate properly. This will cause validations to change based on locale.
     */
    intl: Ember.inject.service(),
    init: function init() {
      this.get('intl.locale.firstObject');

      this._super.apply(this, arguments);
    },

    /**
     * Form property
     */
    email: '',

    /**
     * Show or hide the reset success block
     */
    sendUsernameSuccess: false,

    /**
     * Show or hide the reset failure block
     */
    sendUsernameFail: false,

    /**
     * Indicates that server is processing and the form should be blocked
     */
    isLoading: false,

    /**
     * This will reset all the controller properties back to their defaults
     * It is called by the route when the user navigates away from this page
     *
     */
    reset: function reset() {
      this.set('email', '');
      this.set('sendUsernameSuccess', false);
      this.set('sendUsernameFail', false);
      this.set('isLoading', false);
    },
    actions: {
      /**
       * Submits the password reset request
       */
      submitUsernameRequest: function submitUsernameRequest() {
        var _this = this;

        this.set('isLoading', true);
        this.get('api').sendUsernameEmail(this.get('email').trim()).then(function (result) {
          if (result.BooleanResult) {
            _this.set('sendUsernameFail', false);

            _this.set('sendUsernameSuccess', true);
          } else {
            _this.set('sendUsernameFail', true);
          }
        }).catch(function () {
          return _this.set('sendUsernameFail', true);
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});