define("sky-router-3/transforms/google-bounds", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*global google*/

  /**
   * This serializes and deserializes LatLngBound objects. Following
   * the description at the link to do so.
   * @see https://developers.google.com/maps/documentation/javascript/reference?hl=en#LatLngBounds
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        var bounds = serialized.split(',');
        var sw = new google.maps.LatLng(bounds[0], bounds[1]);
        var ne = new google.maps.LatLng(bounds[2], bounds[3]);
        return new google.maps.LatLngBounds(sw, ne);
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return deserialized.toUrlValue();
      }
    }
  });

  _exports.default = _default;
});