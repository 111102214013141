define("sky-router-3/pods/components/input-phone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2+pePTu/",
    "block": "{\"symbols\":[],\"statements\":[[11,\"input\"],[16,1,[31,[[34,2]]]],[24,0,\"form-control\"],[4,[38,3],[[32,0],\"inputUp\"],[[\"on\"],[\"keyUp\"]]],[12],[13],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"errors\"],[[35,0,[\"attrs\",\"isValid\",\"messages\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"validations\",\"input-errors\",\"inputId\",\"action\",\"validate\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/input-phone/template.hbs"
  });

  _exports.default = _default;
});