define("sky-router-3/pods/components/feature-lock/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Shows a lock button that displays a popup with a description of what was locked
   * @type {String}
   */
  var _default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['fa fa-lock'],
    intl: Ember.inject.service(),

    /**
     * Where to place the popup.
     * values are 'top','right','left','bottom'
     * @type {String}
     */
    placement: 'top',

    /**
     * The type of feature lock this is.
     * This controls the title shown
     * Values are 'advanced', 'premium'
     * @type {String}
     */
    type: 'advanced',

    /**
     * The title shown to the user. This can be overriden.
     */
    title: Ember.computed('type', function () {
      switch (this.type) {
        default:
        case 'advanced':
          return this.intl.t('features.titleAdvanced');

        case 'premium':
          return this.intl.t('features.titlePremium');
      }
    })
  });

  _exports.default = _default;
});