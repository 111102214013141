define("sky-router-3/pods/portal/manage/device-parameters/index/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.store.query('asset-parameter-set-type', {
        onlyAssignableTypes: true
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-parameters');
      },
      create: function create(controller) {
        var _this = this;

        this.send('blockApp', true);
        this.store.createRecord('asset-parameter-set', {
          name: controller.get('name'),
          type: controller.get('parameterType')
        }).save().then(function (set) {
          _this.transitionTo('portal.manage.device-parameters.edit', set.belongsTo('revisionLatest').id());
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});