define("sky-router-3/pods/portal/forms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NSZtZkyO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/forms/template.hbs"
  });

  _exports.default = _default;
});