define("sky-router-3/utils/convert-date-timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertDate;

  /**
   * Converts the date to and from system JS time by adding / subtracting offset seconds.
   * @param  {Date}  date                  Input date this can be from the system or from the component
   * @param  {Number} toTimezoneMinutes    The timezone in minutes offset from UTC to convert to or from
   * @param  {Boolean} [toSystemTime=true] True means from the component time to the system,
   *                                       False means from the system to the component
   * @return {Date}                        Date that has been adjusted to account for javascript stupidities with date time zones.
   */
  function convertDate(date, toTimezoneMinutes) {
    var toSystemTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var conversionFactor = toSystemTime ? 1 : -1; // Validate the date

    if (!date) {
      date = new Date();
    } // Get the time as if it was UTC


    var utcTime = date.getTime() - date.getTimezoneOffset() * 60000 * conversionFactor; // Adjust back from UTC if we need to.

    return new Date(utcTime - toTimezoneMinutes * 60000 * conversionFactor);
  }
});