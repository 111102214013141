define("sky-router-3/pods/account/password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fYrCE/61",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[1,[30,[36,0],[\"account.password.title\"],null]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"settings.securityAccountPassword\"],null]],[13],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"showTempPassword\",\"passwordChanged\"],[true,[30,[36,1],[\"passwordChanged\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"route-action\",\"portal/settings/security/change-password\"]}",
    "moduleName": "sky-router-3/pods/account/password/template.hbs"
  });

  _exports.default = _default;
});