define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-collision/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'collitionDuration.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.collitionDuration.validationMaxNumber'),
      gte: Ember.computed.alias('model.collitionDuration.validationMinNumber')
    })],
    'collitionThreshold.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.collitionThreshold.validationMaxNumber'),
      gte: Ember.computed.alias('model.collitionThreshold.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    collisionDuration: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    collisionThreshold: null,

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    collisionDurationSelected: function () {
      var options = this.get('collisionDurationOptions');
      var option = options.findBy('value', this.get('collisionDuration.value'));

      if (!option) {
        option = options.findBy('value', '10');
      }

      return option;
    }.property('collisionDuration.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    collisionThresholdSelected: function () {
      var options = this.get('collisionThresholdOptions');
      var option = options.findBy('value', this.get('collisionThreshold.value'));

      if (!option) {
        option = options.findBy('value', '16');
      }

      return option;
    }.property('collisionThreshold.value'),

    /**
    * The options for the collision duration dropdown
    */
    collisionDurationOptions: function () {
      return [{
        value: '1',
        label: '1 ms'
      }, {
        value: '2',
        label: '2 ms'
      }, {
        value: '5',
        label: '5 ms'
      }, {
        value: '10',
        label: '10 ms'
      }, {
        value: '20',
        label: '10 ms'
      }];
    }.property(),

    /**
    * The options for the collision threshold dropdown 
    */
    collisionThresholdOptions: function () {
      return [{
        value: '1',
        label: '1G'
      }, {
        value: '2',
        label: '2G'
      }, {
        value: '4',
        label: '4G'
      }, {
        value: '8',
        label: '8G'
      }, {
        value: '12',
        label: '12G'
      }, {
        value: '16',
        label: '16G'
      }];
    }.property(),
    actions: {
      collisionDurationChange: function collisionDurationChange(selected) {
        this.set('collisionDuration.value', selected.value);
      },
      collisionThresholdChange: function collisionThresholdChange(selected) {
        this.set('collisionThreshold.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('collisionDuration').rollbackAttributes();
          this.get('collisionThreshold').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});