define("sky-router-3/pods/components/portal/track/location-form/component", ["exports", "sky-router-3/utils/has-permission", "ember-cp-validations"], function (_exports, _hasPermission, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'location.name': (0, _emberCpValidations.validator)('presence', true),
    'location.type': (0, _emberCpValidations.validator)('presence', true),
    imageFile: (0, _emberCpValidations.validator)('file', {
      allowEmpty: true,
      inputId: 'imageFile',
      sizeLimit: 10000000,
      allowTypes: /image\/*/
    })
  });
  /**
   * This component handles editing and creating locations.
   * It has been broken out into another component because
   * Ember doesn't like to have optional dynamic segments.
   */

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Event that fires when the form edit has been canceled.
     * @event onSaveCancel
     * @param {object} the current state of the location object
     */

    /**
     * Event that fires when the form is to be saved.
     * @event onSave
     * @param {object} the location object that is to be saved
     */

    /**
     * Event that fires when an image is to be uploaded.
     * @event savePhoto
     * @param {object} the location objec that is associated with the image
     * @param {object} the HTML file object
     */

    /**
     * Disable the tag since this component uses a modal for an inner component only
     */
    tagName: '',

    /**
     * Main location object that is used to display the form model.
     * This object needs to be passed in from outside
     * @property
     * @default null
     */
    location: null,

    /**
     * Holds the location types that are present.
     * This object needs to be passed in from outside
     */
    types: null,

    /**
     * Defines if the form is in edit mode or create mode
     * @default false
     * @property
     */
    isEditing: false,

    /**
     * Indicates if the form should disable user input
     * because the form is being saved
     * @property
     * @default false
     */
    isSaving: false,

    /**
     * Can the user running this app mark a location as global. Marking a location
     * as global is only effective for sub users who have access to the manage.location
     * since an admin's locations are already global.
     * @return {Boolean}
     */
    canMarkGlobal: function () {
      var user = this.get('sessionAccount.user');
      return !user.get('isRootAdmin') && (0, _hasPermission.default)(user.get('permissions'), 'manage.location');
    }.property('sessionAccount.user'),

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedTabIndex: null,
    actions: {
      performSavePhoto: function performSavePhoto(imageId) {
        this.savePhoto(this.location, document.getElementById(imageId).files[0]);
      }
    }
  });

  _exports.default = _default;
});