define("sky-router-3/pods/components/input-autocomplete-location/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    locations: null,
    value: null,
    onSelect: function onSelect() {},
    disabled: false,
    type: 'location',
    typeSelected: (0, _computedFindBy.default)('types', 'id', 'type', true),
    types: [{
      id: 'location',
      label: 'Locations'
    }, {
      id: 'airport',
      label: 'Airports'
    }, {
      id: 'navaid',
      label: 'Naviads'
    }],
    onTypeChange: function onTypeChange(selection) {
      this.set('type', selection.id);
    },
    actions: {
      filterResults: function filterResults(value) {
        var _this = this;

        if (!value) {
          this.set('locations', null);
        } else {
          return this.get('api').searchGeographicLocations(value, [this.typeSelected.id]).then(function (results) {
            return _this.set('locations', results);
          });
        }
      },
      clearFilter: function clearFilter() {
        this.set('locations', null);
      }
    }
  });

  _exports.default = _default;
});