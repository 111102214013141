define("sky-router-3/pods/portal/manage/device-profiles/assignment/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        assignment: this.store.findRecord('asset-profile-set-asset', params.asset_id),
        sets: this.store.query('asset-profile-set', {
          imei: params.asset_id
        })
      });
    },
    resetController: function resetController(controller) {
      controller.set('selectedSet', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-profiles.assignment');
      },
      save: function save() {
        var _this = this;

        var controller = this.controllerFor(this.routeName);
        var selectedSet = controller.get('selectedSet');
        var assignment = this.modelFor(this.routeName).assignment;
        controller.set('blockForm', true);
        selectedSet.get('revisionLatest').then(function (revision) {
          assignment.set('profileSetRevision', revision);
          assignment.save().then(function () {
            _this.send('sendRevision', assignment.get('asset'));

            _this.transitionTo('portal.manage.device-profiles.assignment');
          }).catch(function () {
            _this.notifications.error(_this.intl.t('profiles.assignment.saveFail'));
          }).finally(function () {
            controller.set('blockForm', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});