define("sky-router-3/pods/portal/communicate/compose/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset'),
        contacts: this.store.findAll('contact')
      });
    },

    /**
     * Set the controller back to default values so that the query string fields
     * are not stored when the user leaves the route.
     * @param {[type]} controller [description]
     */
    resetController: function resetController(controller) {
      controller.set('to', []);
      controller.set('cc', []);
      controller.set('bcc', []);
      controller.set('subject', null);
      controller.set('body', null);
      controller.set('message', null);
    },
    actions: {
      createDraft: function createDraft(args) {
        var record = this.store.createRecord('mail-message', args);
        this.controllerFor(this.routeName).set('message', record);
        this.send('saveDraft', record);
      },
      createMessage: function createMessage(args) {
        var record = this.store.createRecord('mail-message', args);
        this.controllerFor(this.routeName).set('message', record);
        this.send('sendMessage', record);
      }
    }
  });

  _exports.default = _default;
});