define("sky-router-3/pods/portal/operations/burst-services/messenger/route", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map"], function (_exports, _create, _stateTrackMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    geoAreaService: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        mapConfig: (0, _create.default)(_stateTrackMap.default),
        bcas: this.store.findAll('broadcast-coverage-area').then(function (bcas) {
          return bcas.filter(function (bca) {
            return bca.isVisible;
          });
        }).then(function (s) {
          return s.sortBy('region');
        }),
        burstServices: this.store.findAll('burst-service').then(function (s) {
          return s.sortBy('serviceName');
        }),
        geoAreaGroups: this.store.findAll('burst-service-geo-area-group').then(function (s) {
          return s.sortBy('name');
        }),
        burstServiceTo: null,
        useTimeNowRepeat: false
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        // Reset properties on the controller
        controller.set('abstract', null);
        controller.set('body', null);
        controller.set('burstServiceSeleted', null);
        controller.set('useTimeNowExpiration', false);
        controller.set('useTimeNowRepeat', false);
        controller.set('timeNowDurationSeconds', null);
        controller.set('timeNowExpireSeconds', null);
        controller.set('enableTimeNowExactDate', null);
        controller.set('timeNowRepeatCount', null);
        controller.set('options', {});
        controller.set('bcas', []);
        controller.set('geoAreaGroups', []);
        controller.set('timeNowExpirationType', 1);
        controller.set('scheduleTimeType', 1);
        controller.set('enableScheduling', false);
        controller.set('timeNowStartDateObject', new Date());
        controller.set('timeNowExactDateObject', new Date());
      }
    },
    actions: {
      mapAction: function mapAction() {// do nothing
      },
      cancel: function cancel() {
        this.transitionTo('portal.operations.burst-services');
      },
      send: function send(controller) {
        var _controller$burstServ,
            _this = this;

        var geoAreas = controller.geoAreasToDisplay.map(function (geoItem) {
          return {
            latitudeDegree: geoItem.latitudeDegree,
            longitudeDegree: geoItem.longitudeDegree,
            diameterInKm: geoItem.diameterInKm
          };
        });
        var bcaCodes = controller.bcasSelected.map(function (bca) {
          return bca.code.trim();
        });
        var message = {
          serviceName: (_controller$burstServ = controller.burstServiceSeleted) === null || _controller$burstServ === void 0 ? void 0 : _controller$burstServ.code,
          message: controller.body,
          abstract: controller.abstract,
          bcas: bcaCodes,
          geoAreas: geoAreas,
          options: controller.options
        };

        if (controller.isNow) {
          message.schedule = {
            now: {}
          };

          if (controller.useTimeNowRepeat) {
            message.schedule = {
              now: {
                repeat: {
                  count: controller.timeNowRepeatCount,
                  intervalSeconds: controller.timeNowDurationSeconds
                }
              }
            };
          }

          if (controller.useTimeNowExpiration) {
            message.schedule.now.expiration = {};

            if (controller.timeNowExpirationType == 2) {
              message.schedule.now.expiration.expireSeconds = controller.timeNowExpireSeconds;
            } else if (controller.timeNowExpirationType == 1) {
              message.schedule.now.expiration.expireDate = controller.timeNowExactDate;
            }
          }
        } else if (controller.isScheduledDate) {
          message.schedule = {
            exact: []
          };
          var exact = {};

          if (controller.useTimeNowRepeat) {
            exact = {
              repeat: {
                count: controller.timeNowRepeatCount,
                intervalSeconds: controller.timeNowDurationSeconds
              }
            };
          }

          if (controller.useTimeNowExpiration) {
            exact.expiration = {};

            if (controller.timeNowExpirationType == 2) {
              exact.expiration.expireSeconds = controller.timeNowExpireSeconds;
            } else if (controller.timeNowExpirationType == 1) {
              exact.expiration.expireDate = controller.timeNowExactDate;
            }
          }

          exact.startDate = controller.timeNowStartDateObject.toISOString(); // We will only send one entry in the TimeExact

          message.schedule.exact[0] = exact;
        }

        console.error("MESSAGE::: ".concat(JSON.stringify(message)));
        this.get('api').burstMessageSend(message).then(function () {
          _this.notifications.success(_this.intl.t('burstServices.messenger.sentMessageSuccess'));

          _this.transitionTo('portal.operations.burst-services');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('burstServices.messenger.sentMessageFailed'));

          _this.transitionTo('portal.operations.burst-services');
        });
      }
    }
  });

  _exports.default = _default;
});