define("sky-router-3/pods/components/input-markdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0GGdR0gs",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"@onChange\"],\"statements\":[[11,\"textarea\"],[17,1],[16,2,[32,2]],[4,[38,1],[[30,[36,0],[[32,0],[32,0,[\"initialize\"]]],null]],null],[4,[38,2],[\"change\",[32,3]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"on\"]}",
    "moduleName": "sky-router-3/pods/components/input-markdown/template.hbs"
  });

  _exports.default = _default;
});