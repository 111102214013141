define("sky-router-3/models/user-push-notification-alert-trigger", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Maps the user to the available alert trigger events that are used to determine
   * which events trigger push notificaitons.
   */
  var _default = _model.default.extend({
    api: Ember.inject.service(),

    /**
     * The user this record belogns to. This also happens to be the ID of the record
     */
    user: (0, _model.belongsTo)('user'),

    /**
     * The alerts that can trigger a push notification
     */
    alertTriggerEvents: (0, _model.hasMany)('alert-trigger-event'),

    /**
     * Resets the record to its default settings
     */
    reset: function reset() {
      var _this = this;

      var user = this.belongsTo('user').id();
      return this.api.post("UserPushNotificationAlertTriggers/".concat(user, "/Reset")).then(function () {
        return _this.reload();
      });
    }
  });

  _exports.default = _default;
});