define("sky-router-3/pods/portal/report/playback/controller", ["exports", "moment", "sky-router-3/utils/constants"], function (_exports, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['device', 'sDate', 'eDate', 'timeZoneMinutes', 'completedTripsOnly'],

    /**
     * The list of device selected by the user
     * @type {String}
     */
    device: null,
    deviceSelected: Ember.computed('model.devices.[]', 'device', function () {
      return this.model.devices.findBy('id', this.device) || this.model.devices.firstObject;
    }),

    /**
     * The start date selected by the user
     * @type {Date}
     */
    sDate: null,
    sDateObject: Ember.computed('sDate', {
      get: function get() {
        var date = (0, _moment.default)(this.sDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().startOf('day').add(-1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('sDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The end date selected by the user
     * @type {Date}
     */
    eDate: null,
    eDateObject: Ember.computed('eDate', {
      get: function get() {
        var date = (0, _moment.default)(this.eDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().endOf('day').add(1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('eDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),

    /**
     * Flag that is used to disable all export buttons when an export is in progress
     * @type {Boolean}
     */
    exportsDisabled: false,

    /**
     * Flag for the user to toggle the query to show completed trips only or not
     * @type {Boolean}
     */
    completedTripsOnly: false,

    /**
     * Makes the API call to retrive the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName) {
      var _this = this;

      // Disable export buttons
      this.set('exportsDisabled', true); // Get report parameters

      var device = this.deviceSelected.get('id');
      var utcOffset = this.timeZone.offsetMinutes;
      var startDate = this.sDateObject.toISOString();
      var endDate = this.eDateObject.toISOString();
      var completedTrips = this.completedTripsOnly; // Call the API

      this.api.getPlaybackTripsReportExport(fileName, device, startDate, endDate, completedTrips, utcOffset, exportType).catch(function () {
        _this.notifications.error(_this.intl.t('report.exportFailed', {
          exportType: exportType
        }));
      }).finally(function () {
        _this.set('exportsDisabled', false);
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      registerGridApi: function registerGridApi(api) {
        this._gridApi = api;
      },

      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        this._gridApi.generateReport(1);
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport('Excel', this.intl.t('nav.playbackTrips') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport('CSV', this.intl.t('nav.playbackTrips') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport('PDF', this.intl.t('nav.playbackTrips') + '-' + this.getUTCDateString() + '.pdf');
      },
      selectDevice: function selectDevice(asset) {
        this.set('device', asset ? asset.get('id') : null);
      }
    }
  });

  _exports.default = _default;
});