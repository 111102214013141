define("sky-router-3/pods/portal/operations/flightplans/index/status/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        plan: this.store.findRecord('flight-plan', params.plan_id),
        statuses: this.store.findAll('flight-plan-status')
      });
    },
    afterModel: function afterModel(model) {
      model.statusesWithoutActive = model.statuses.filter(function (y) {
        return y.get('id') !== '1';
      });
      model.resetStatus = model.statusesWithoutActive[0];
    },
    actions: {
      cancel: function cancel() {
        this.modelFor(this.routeName).plan.reload();
        this.transitionTo('portal.operations.flightplans');
      },
      save: function save(plan, resetStatus) {
        var _this = this;

        var intl = this.get('intl');
        var msg = plan.get('status.id') === '1' ? intl.t('flightPlans.status.updateConfirm') : intl.t('misc.areYouSureConfirm');

        if (confirm(msg)) {
          this.send('blockApp', true);
          this.get('api').updateFlightPlanStatus(plan.get('id'), plan.get('status.id'), resetStatus.get('id')).then(function () {
            _this.transitionTo('portal.operations.flightplans');
          }).catch(function () {
            _this.notifications.error(_this.get('misc.failedOperation'));
          }).finally(function () {
            _this.send('blockApp', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});