define("sky-router-3/models/form-template-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.types = void 0;
  var types = {
    userForm: '0',
    tripPlanDetail: '1'
  };
  _exports.types = types;

  var _default = _model.default.extend({
    /**
     * The name of the status
     */
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });

  _exports.default = _default;
});