define("sky-router-3/pods/components/portal/report/invoiceusage-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * The selected invoice option for the report. Default to first
     */
    invoiceOption: 1,

    /**
     * The selected report type. Default to first
     */
    reportType: 1,

    /**
     * The selected grid option, Data or Voice. Default to first
     */
    gridOption: 1,

    /**
    * The report that was generated
    */
    reportGenerated: 0,

    /**
    * The report result set
    **/
    reportData: null,

    /**
    * The total number of records in the result set
    */
    totalRecords: 0,

    /**
    * The start index of the results
    */
    startIndex: 1,

    /**
    * The end index of the results
    */
    endIndex: 0,

    /**
    * The current page of the report results
    */
    currentPage: 1,

    /**
    * The amount of returned records
    */
    resultCount: 0,

    /**
    * The amount of records per paged results
    **/
    take: 1000,

    /**
    * The user options for page size
    */
    takeSelections: [1000, 1500, 2000],

    /**
    * Gets the report to generate based on the reportType and gridOption user selections
    */
    reportSelected: Ember.computed('reportType', 'gridOption', function () {
      switch (this.reportType) {
        case 1:
          switch (this.gridOption) {
            case 1:
              return 1;

            case 2:
              return 2;
          }

          break;

        case 2:
          switch (this.gridOption) {
            case 1:
              return 3;

            case 2:
              return 4;
          }

          break;
      }
    }),

    /**
    * Creates the applicable pages list
    */
    pages: function () {
      var pages = [];
      var take = this.get('take');
      var totalRecords = this.get('totalRecords');
      var count = Math.ceil(totalRecords / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }.property('totalRecords', 'take'),

    /**
     * On init, generate the report
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this.generateReport();
    },

    /**
     * This is the function that triggers the report to be rendered.
     * @function
     * @private
     */
    onGenerateReportClicked: function () {
      this.set('currentPage', 1);
      this.generateReport();
    }.observes('generateReportClicked'),

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport() {
      var _this = this;

      // Get report parameters
      var reportSelected = this.get('reportSelected');
      var reportDate = this.get('invoiceOption').date;
      var rowCount = this.get('take');
      var page = this.get('currentPage');
      this.set('loading', true);

      switch (reportSelected) {
        default:
        case 1:
          return this.get('api').getInvoiceUsageSBDSummaryReportData(rowCount, page, reportDate).then(function (response) {
            _this.dataHandler(response);
          });

        case 2:
          return this.get('api').getInvoiceUsageVoiceSummaryReportData(rowCount, page, reportDate).then(function (response) {
            _this.dataHandler(response);
          });

        case 3:
          return this.get('api').getInvoiceUsageSBDReportData(rowCount, page, reportDate).then(function (response) {
            _this.dataHandler(response);
          });

        case 4:
          return this.get('api').getInvoiceUsageVoiceReportData(rowCount, page, reportDate).then(function (response) {
            _this.dataHandler(response);
          });
      }
    },

    /**
    * Processes the response data from the report query
    */
    dataHandler: function dataHandler(data) {
      var reportSelected = this.get('reportSelected');
      var rowCount = this.get('take');
      this.set('reportData', data.rows);
      this.set('totalRecords', data.records);
      this.set('page', data.page);
      this.set('resultCount', data.total);
      this.set('startIndex', (data.page - 1) * rowCount + 1);
      var endIndex = data.page * rowCount;

      if (endIndex > data.records) {
        endIndex = data.records;
      }

      this.set('endIndex', endIndex);
      this.set('loading', false);
      this.set('reportGenerated', reportSelected);
    },
    actions: {
      countChanged: function countChanged(selection) {
        this.set('take', selection);
        this.set('currentPage', 1);
        this.set('totalRecords', 0);
        this.generateReport();
      },
      pageSelected: function pageSelected(page) {
        var currentPage = this.get('currentPage');

        if (page !== currentPage) {
          this.set('currentPage', page);
          this.generateReport();
        }
      }
    }
  });

  _exports.default = _default;
});