define("sky-router-3/models/unit-volume", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var unitSpeed = _model.default.extend({
    /**
     * The name of the unit
     */
    name: (0, _model.attr)('string'),

    /**
     * The unit notation for the unit. ex. km/h
     */
    shortName: (0, _model.attr)('string', {
      defaultValue: ''
    }),

    /**
     * The fractional factor that will transform centimeters to the units measure.
     */
    centimeterFactor: (0, _model.attr)('number', {
      defaultValue: 1
    }),

    /**
     * Holds a value that indicates how many decimal places to show to the user
     */
    decimalCount: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });

  var _default = unitSpeed;
  _exports.default = _default;
});