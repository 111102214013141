define("sky-router-3/pods/portal/track/manual/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.position.asset': (0, _emberCpValidations.validator)('presence', true),
    'model.position.headingDegrees': (0, _emberCpValidations.validator)('presence', true),
    'model.position.description': (0, _emberCpValidations.validator)('presence', true),
    'model.position.utc': (0, _emberCpValidations.validator)('date', {
      onOrBefore: 'now',
      precision: 'day'
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['expanded', 'latitude', 'longitude'],
    expanded: false,
    latitude: null,
    longitude: null,
    dateType: 'now',
    rotateBinding: Ember.computed('model.position.headingDegrees', function () {
      return "transform:rotate(".concat(this.model.position.get('headingDegrees'), "deg)").htmlSafe();
    }),
    altitudeUnitSelected: Ember.computed('sessionAccount.user.altitude', function () {
      return this.sessionAccount.user.get('altitude');
    }),
    speedUnitSelected: Ember.computed('sessionAccount.user.speedOther', function () {
      return this.sessionAccount.user.get('speedOther');
    }),
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      },
      selectType: function selectType(position, type) {
        position.set('type', type);
        position.set('description', type.name);
      }
    }
  });

  _exports.default = _default;
});