define("sky-router-3/pods/components/portal/manage/device-parameters/types/d411/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aXohTkEb",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"enabledSetting\",\"secondsSetting\",\"disabled\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[1,[30,[36,6],null,[[\"intervalSetting\",\"limitSetting\",\"disabled\"],[[35,5],[35,4],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"timeReportingSeconds\",\"timeReportingEnabled\",\"portal/manage/device-parameters/controls/time-based-reporting\",\"quickReportingLimit\",\"quickReportingSeconds\",\"portal/manage/device-parameters/controls/quick-position-reporting\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/types/d411/template.hbs"
  });

  _exports.default = _default;
});