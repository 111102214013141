define("sky-router-3/pods/portal/manage/users/edit-multiple/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    hasAdminUser: Ember.computed('model.users', function () {
      return this.model.users.isAny('isAdmin');
    })
  });

  _exports.default = _default;
});