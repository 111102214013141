define("sky-router-3/pods/components/portal/track/map/overlays/usgs-volcano/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/browser-detect", "sky-router-3/config/environment"], function (_exports, _googleObjectMapping, _computedJsonObject, _latLngConverter, _browserDetect, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Volcanoes based on the USGS database
   * https://volcanoes.usgs.gov/
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each TFR record downloaded.
     * Based on this icon set
     * https://www.aviationweather.gov/airep/help?page=inter
     */
    style: function style(feature) {
      var size = 20;
      var zIndex = 0;

      switch (feature.getProperty('alert_level')) {
        case 'WARNING':
          size = 35;
          zIndex = 10;
          break;

        case 'WATCH':
          size = 30;
          zIndex = 5;
          break;

        case 'ADVISORY':
          size = 25;
          zIndex = 3;
          break;
      }

      var icon = {
        url: "".concat(_environment.default.rootURL, "assets/images/overlays/usgs-volcano/").concat(feature.getProperty('color_code'), "_").concat(feature.getProperty('alert_level'), ".svg"),
        scaledSize: new google.maps.Size(size, size),
        size: new google.maps.Size(size, size),
        anchor: new google.maps.Point(size / 2, size / 2)
      };
      return {
        optimized: !(0, _browserDetect.isIE)(),
        // undocumented IE FIX
        icon: icon,
        zIndex: zIndex
      };
    },
    calculateIconSize: function calculateIconSize(feature) {
      switch (feature.getProperty('alert_level')) {
        case 'WARNING':
          return 35;

        case 'WATCH':
          return 30;

        case 'ADVISORY':
          return 25;

        default:
          return 20;
      }
    },

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getFeatureInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout()
    /*dataObject, mouseEvent*/
    {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
    },

    /**
     * Opens the Smithsonian Institute Global Volcanism Program link on the
     * given volcano.
     */
    _click: function _click(dataObject, mouseEvent) {
      window.open(mouseEvent.feature.getProperty('obslink'), '_blank');
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getFeatureInfo: function _getFeatureInfo(feature) {
      return {
        name: feature.getProperty('volcano_name'),
        alertLevel: feature.getProperty('alert_level'),
        colorCode: feature.getProperty('color_code'),
        elevation: feature.getProperty('elevation_meters'),
        threatLevel: feature.getProperty('nvewsthreat'),
        date: feature.getProperty('date'),
        time: feature.getProperty('time'),
        status: feature.getProperty('status'),
        image: feature.getProperty('image')
      };
    },

    /**
     * Creates a bounds listener so that the overlay boots when the map finally loads.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.autoReloadGeoJson));
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function () {
      var _this = this;

      Ember.run.join(function () {
        var map = _this.get('map');

        var bounds = map.getBounds();

        if (!bounds) {
          return;
        }

        var url = _this.get('dataObject.url');

        if (!url) {
          return;
        }

        var opts = {
          data: {
            bbox: _latLngConverter.default.getBBoxString(bounds),
            count: 50,
            types: _this.getTypes()
          }
        };

        if (_this.get('settings.generic.search')) {
          opts.data.search = _this.get('settings.generic.search');
        }

        _this.get('api').request(url, opts).then(function (x) {
          var obj = _this.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    }.observes('settings.generic.hideRed', 'settings.generic.hideOrange', 'settings.generic.hideYellow', 'settings.generic.hideGreen', 'settings.generic.hideWhite', 'settings.generic.search'),

    /**
     * Returns a type array that can be used for URL formulation
     */
    getTypes: function getTypes() {
      var types = [];

      if (!this.get('settings.generic.hideRed')) {
        types.push('RED_WARNING');
        types.push('RED_WATCH');
      }

      if (!this.get('settings.generic.hideOrange')) {
        types.push('ORANGE_WARNING');
        types.push('ORANGE_WATCH');
      }

      if (!this.get('settings.generic.hideYellow')) {
        types.push('YELLOW_ADVISORY');
      }

      if (!this.get('settings.generic.hideGreen')) {
        types.push('GREEN_NORMAL');
      }

      if (!this.get('settings.generic.hideWhite')) {
        types.push('UNASSIGNED_UNASSIGNED');
      }

      if (types.get('length') == 7) {
        return [];
      }

      return types;
    },

    /**
     * Forces the reloading of the metar data when the map moves or is zoomed.
     */
    autoReloadGeoJson: function () {
      Ember.run.debounce(this, this.loadGeoJson, 500);
    }.observes('mapConfig.center', 'mapConfig.zoom'),

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this2 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this2.loadGeoJson();

        _this2.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      this.mapBoundsListener.remove();
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    }
  });

  _exports.default = _default;
});