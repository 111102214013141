define("sky-router-3/pods/portal/settings/map/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    origDisplayName: '',
    init: function init() {
      this._super.apply(this, arguments);
    },

    /**
    * Timespan[] for dropdown selection, id's are in minutes if positive and
    * in the number of reports if negative. The number of reports is always 1
    * greater than it states because the `last` position is where the asset is.
    */
    assetBreadcrumbTimespans: function () {
      var intl = this.get('intl');
      return [{
        id: 0,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanNone')
      }, {
        id: -2,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReport')
      }, {
        id: -3,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 2
        })
      }, {
        id: -4,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 3
        })
      }, {
        id: -6,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 5
        })
      }, {
        id: -11,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 10
        })
      }, {
        id: -51,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 50
        })
      }, {
        id: -101,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanLastReports', {
          count: 100
        })
      }, {
        id: 15,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastMinutes', {
          count: 15
        })
      }, {
        id: 30,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastMinutes', {
          count: 30
        })
      }, {
        id: 45,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastMinutes', {
          count: 45
        })
      }, {
        id: 60,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHour')
      }, {
        id: 120,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 2
        })
      }, {
        id: 180,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 3
        })
      }, {
        id: 240,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 4
        })
      }, {
        id: 300,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 5
        })
      }, {
        id: 360,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 6
        })
      }, {
        id: 420,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 7
        })
      }, {
        id: 480,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 8
        })
      }, {
        id: 540,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 9
        })
      }, {
        id: 600,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 10
        })
      }, {
        id: 660,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 11
        })
      }, {
        id: 720,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 12
        })
      }, {
        id: 1440,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 24
        })
      }, {
        id: 2880,
        name: intl.t('settings.mapAssetEventBreadcrumbsTimespanPastHours', {
          count: 48
        })
      }];
    }.property(),

    /**
     * Used by the select breadcrumb interval dropdown.
     */
    selectedAssetBreadcrumbTimespan: function () {
      return this.get('assetBreadcrumbTimespans').findBy('id', this.get('model.user.assetBreadcrumbTimespan'));
    }.property('model.user.assetBreadcrumbTimespan'),
    actions: {
      breadcrumbTimespanChanged: function breadcrumbTimespanChanged(selected) {
        this.set('model.user.assetBreadcrumbTimespan', selected.id);
      },
      cancelDisplayName: function cancelDisplayName() {
        this.set('model.user.assetDisplayNameFormat', this.get('model.originalDisplayName').slice());
      }
    }
  });

  _exports.default = _default;
});