define("sky-router-3/pods/components/checkbox-tree/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component provides a tree view based on a model structure.
   * It allows items to be checked and unchecked and expanded / collapsed.
   */
  var _default = Ember.Component.extend({
    init: function init() {
      this.set('expandedTrees', []);
      return this._super.apply(this, arguments);
    },
    onChecked: function onChecked() {},
    disabled: false,

    /**
     * Array containing objects of structure
     *
     * @type {Array} [{
     *   id: {string},
     *   label: {string},
     *   checked: {bolean},
     *   children: {array<of these objects>}
     * }]
     * @example {Array} [{
     *     id: "ALLOW_Communicate",
     *     label: "Communicate",
     *     checked: true,
     *     children: [{
     *         id: "ALLOW_Manage_SendDeviceMessage",
     *         label: "Send Device Message",
     *         checked: true,
     *         children: []
     *     }, {
     *         id: "ALLOW_Manage_SendEmail",
     *         label: "Send Email",
     *         checked: false,
     *         children: []
     *     }]
     * }]
     */
    model: [],

    /**
     * Holds an array that has the ID's of the tree nodes that are 'expanded'
     * @type {Array<string>}
     */
    expandedTrees: null,

    /**
     * Indicates that changes to children automatically check the parent
     * @type {Boolean}
     */
    cascadeUp: true,

    /**
     * Recursivly walks the tree and sets the checked status of the item
     */
    recursiveCheckTree: function recursiveCheckTree(node, checked) {
      var _this = this;

      Ember.set(node, 'checked', checked);

      if (node.children) {
        node.children.forEach(function (x) {
          return _this.recursiveCheckTree(x, checked);
        });
      }
    },
    actions: {
      expandTree: function expandTree(node) {
        this.get('expandedTrees').addObject(node.id);
      },
      collapseTree: function collapseTree(node) {
        this.get('expandedTrees').removeObject(node.id);
      },
      checkTree: function checkTree(node, checked) {
        this.recursiveCheckTree(node, checked);
        this.onChecked(node, checked);
      },
      childrenChecked: function childrenChecked(rootNode, checkedNode, checked) {
        if (checked && this.get('cascadeUp')) {
          Ember.set(rootNode, 'checked', true);
        } else if (!rootNode.children.findBy('checked', false)) {
          Ember.set(rootNode, 'checked', true);
        }

        this.onChecked(rootNode, checked);
      }
    }
  });

  _exports.default = _default;
});