define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/mailbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "koI5PxFc",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"parameter.rockseven.alertsMailbox.title\"],null]],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,0],[\"parameter.rockseven.alertsMailbox.help\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[[35,9,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,10],null,[[\"checked\",\"onChange\",\"disabled\"],[[35,9,[\"valueBoolean\"]],[30,[36,4],[[32,0],\"enabledChanged\"],null],[35,5]]]]],[1,[30,[36,0],[\"parameter.misc.enableFeature\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,9,[\"valueBoolean\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,2],[[35,1,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n            \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.rockseven.alertsMailbox.labelCheckRate\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],null,[[\"options\",\"selected\",\"disabled\",\"onChange\"],[[35,7],[35,6],[35,5],[30,[36,4],[[32,0],\"mailboxCheckRateChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"mailboxCheckRate\",\"if\",\"input-label\",\"action\",\"disabled\",\"mailboxCheckRateSelected\",\"mailboxCheckRateOptions\",\"input-select\",\"enabledSetting\",\"input-checkbox\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/mailbox/template.hbs"
  });

  _exports.default = _default;
});