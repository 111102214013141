define("sky-router-3/pods/portal/manage/nortac/address-book/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nortacAssets: Ember.computed.filterBy('model.assets', 'product.id', 'NWAVE'),
    actions: {
      addEntry: function addEntry() {
        this.model.book.entries.createFragment({
          type: 'email'
        });
      },
      deleteEntry: function deleteEntry(entry) {
        this.model.book.entries.removeObject(entry);
      },
      setType: function setType(entry, selected) {
        entry.set('type', selected.id);
        entry.set('label', null);
        entry.set('value', null);
      },
      setValueUser: function setValueUser(entry, selected) {
        entry.set('label', selected.fullName);
        entry.set('value', selected.id);
      },
      setValuePersonnel: function setValuePersonnel(entry, selected) {
        entry.set('label', selected.name);
        entry.set('value', selected.id);
      },
      setValueContact: function setValueContact(entry, selected) {
        entry.set('label', selected.fullName);
        entry.set('value', selected.id);
      },
      setValueAsset: function setValueAsset(entry, selected) {
        entry.set('label', selected.fullName);
        entry.set('value', selected.id);
      }
    }
  });

  _exports.default = _default;
});