define("sky-router-3/pods/components/portal/manage/burst-services/geo-area-location-map/component", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map", "ember-cp-validations"], function (_exports, _create, _stateTrackMap, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'locationName': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('circle', null);
    },
    onClearLocation: function onClearLocation() {},
    onClose: function onClose() {
      this.set('circle', null);
      this.set('locationName', null);
      this.set('_circle', null);
    },

    /**
     * Called when a the filter shape is good to go.
     * This is a public action.
     * @public
     * @param {Array<LatLng>} Points
     */
    onSetLocation: function onSetLocation()
    /*points*/
    {},
    mapConfig: Ember.computed(function () {
      return (0, _create.default)(_stateTrackMap.default);
    }),

    /**
     * Internal path for manipulation.
     * Necessary to move from creating path -> editing path
     * @type {[type]}
     */
    circle: null,
    locationName: null,
    groupColor: null,
    _circle: Ember.computed.reads('circle'),
    //geoArea: null,
    actions: {
      mapAction: function mapAction(map, action) {
        if (action == 'loaded' && this.circle) {
          this.mapConfig.set('center', this._circle.center);
          this.mapConfig.set('zoom', 4);
        } // Do nothing

      },
      clearLocation: function clearLocation() {
        this.set('_circle', null);
        this.set('circle', null);
      },
      setLocation: function setLocation() {
        this.onSetLocation(this.circle.toJSON ? this.circle.toJSON() : this.circle);
      },
      onCreateCircle: function onCreateCircle(args) {
        this.set('_circle', args.circle); // Destroy the passed in circle object

        args.circle.setMap(null);
        args.circle = null;
      },
      onChangeCircle: function onChangeCircle(circle) {
        console.error("MEA:onChangeCircle:::R= ".concat(circle.radius, " LAT=").concat(circle.center.lat(), " LONG").concat(circle.center.lng(), " }"));
        this.set('circle', circle);
      }
    }
  });

  _exports.default = _default;
});