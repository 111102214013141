define("sky-router-3/serializers/nortac-canned-list-entry", ["exports", "sky-router-3/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This has to be present because fragments don't use the default app serializer,
   * because they suck.. https://github.com/lytics/ember-data-model-fragments
   */
  var _default = _application.default.extend();

  _exports.default = _default;
});