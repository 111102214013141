define("sky-router-3/models/generated-report-time-in-area-area", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model", "sky-router-3/utils/computed-promise"], function (_exports, _fragment, _model, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    type: (0, _model.attr)('string'),
    typeId: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    distanceFromCm: (0, _model.attr)('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    // Looks up a geofence or location based on the type name and id fields
    typeInstance: (0, _computedPromise.default)('store', 'type', 'typeId', function () {
      if (this.type && this.typeId !== null) {
        return this.store.findRecord(this.type, this.typeId);
      }

      return null;
    }, null)
  });

  _exports.default = _default;
});