define("sky-router-3/pods/portal/operations/contracts/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.contract.name': (0, _emberCpValidations.validator)('presence', true),
    'model.contract.startDate': (0, _emberCpValidations.validator)('date', {
      precision: 'day',
      allowBlank: false,
      presence: true,
      disabled: Ember.computed.alias('model.model.startDateDisabled')
    }),
    'model.contract.endDate': (0, _emberCpValidations.validator)('date', {
      after: Ember.computed.alias('model.contract.startDate'),
      precision: 'day',
      allowBlank: false,
      presence: true,
      disabled: Ember.computed.alias('model.model.endDateDisabled')
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * The translation service.
     */
    intl: Ember.inject.service(),

    /**
     * Setup the validations that are used in the UI.
     */
    contract: Ember.computed.alias('model.contract'),
    actions: {
      addProperty: function addProperty(prop) {
        this.model.contract.get('properties').pushObject(prop);
      },
      removeProperty: function removeProperty(prop) {
        this.model.contract.get('properties').removeObject(prop);
      }
    }
  });

  _exports.default = _default;
});