define("sky-router-3/pods/components/portal/track/map/marker-spiderfier/component", ["exports", "overlapping-marker-spiderfier"], function (_exports, _overlappingMarkerSpiderfier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is responsible for wrapping markers so that when clicked they
   * unravel into a spiral shape like Google earth does.
   * @see https://github.com/jawj/OverlappingMarkerSpiderfier
   */
  var _default = Ember.Component.extend({
    tagName: '',

    /**
     * @event markerClicked
     * @description This action is called when a marker has been clicked
     * from the spiderfied view of markers
     * @param {Ember.component} Ember component marker that was clicked
     */

    /**
     * Instance of the associate Google map plugin
     */
    map: null,

    /**
     * Holds an array of all the markers on the map. These can be locations
     * Events, assets, everything that is marker is placed in here so that
     * map level listeners know which component to talk to when a marker
     * event occurs at the map level. This object contains an array with
     * the following definition { gMarker: <obj>, eComponent: <obj> }
     */
    markers: null,

    /**
     * Creates the markers array so it won't be static
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.markers = [];
      this.spiderfierInstance = new _overlappingMarkerSpiderfier.default(this.map, {
        nearbyDistance: 50,
        spiralLengthStart: 15,
        spiralLengthFactor: 7,
        nudgeStackedMarkers: false
      });
      this.spiderfierInstance.addListener('click', Ember.run.bind(this, this.onMarkerClicked));
    },

    /**
     * Responsible for destroying the component.
     */
    willDestroyElement: function willDestroyElement() {
      this.spiderfierInstance.clearListeners('click');
    },

    /**
     * Intercepts the `registerMarker` action and adds the marker to the marker
     * spiderfier instance and then forwards the request to register the marker
     * up the chain.
     */
    registerMarker: function registerMarker(googleMarker, emberComponent) {
      this.markers.addObject({
        gMarker: googleMarker,
        eComponent: emberComponent
      });
      this.spiderfierInstance.addMarker(googleMarker);
    },

    /**
     * Intercepts the `registerMarker` action and removes the marker from the
     * marker spiderfier instance and then forwards the request to unregister
     * the marker up the chain.
     */
    unregisterMarker: function unregisterMarker(googleMarker
    /*, emberComponent */
    ) {
      this.markers.removeObject(this.markers.findBy('gMarker', googleMarker));
      this.spiderfierInstance.removeMarker(googleMarker);
    },

    /**
     * Fired when a marker has been clicked. It fires an action upwards called
     * 'markerClicked' with the argument of the marker instance that was clicked
     */
    onMarkerClicked: function onMarkerClicked(gMarker
    /*,event */
    ) {
      this.markerClicked(this.markers.findBy('gMarker', gMarker).eComponent);
    }
  });

  _exports.default = _default;
});