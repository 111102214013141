define("sky-router-3/utils/computed-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedDate;

  /**
   * Reads a string as a date and returns a computed property
   * @param  {String} property The string property to turn into a date
   * @return {ComputedProperty}          Property that watches the string for changes
   */
  function computedDate(property) {
    return Ember.computed(property, function () {
      if (this.get(property)) {
        return new Date(this.get(property));
      }
    });
  }
});