define("sky-router-3/pods/components/input-search/component", ["exports", "ember-intl"], function (_exports, _emberIntl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a component that contains a search field. It allows the user to clear the search field.
   *
   * @event onChange - triggered when the search field changes its value, args contains the current value of the search box,
   * @event onClear - triggered when the search field is cleared using the x button.
   *
   * @example {{input-search placeholder='Search For....' value=searchValue onChange=(action 'onChange') onClear='onClear'}}
   */
  var _default = Ember.Component.extend({
    tagName: '',
    onChange: function onChange() {},
    onClear: function onClear() {},

    /**
     * The translation service.
     */
    intl: Ember.inject.service(),

    /**
     * String used as the placeholder for the search input box
     *
     * @default 'Search For....'
     * @property
     */
    placeholder: (0, _emberIntl.t)('components.inputSearchPlaceholder'),

    /**
     * Holds the value the searchbox contains.
     * Holds null when empty.
     *
     * @default null
     * @property
     */
    value: null,

    /**
     * Number that indicates how long to debounce user input before triggering the 'onChange' event.
     *
     * @default 400
     * @property
     */
    delay: 400,

    /**
     * Allow the input to be updated from the outside
     * @type {Boolean}
     */
    allowUpdates: true,

    /**
     * Indicates if the input field will automatically gain focus when visible
     * @type {Boolean}
     */
    autoFocus: false,

    /**
     * Observes the value of the debounced input and sends the action.
     */
    onValueChange: function () {
      this.onChange(this.get('value'));
    }.observes('value'),
    actions: {
      clearSearch: function clearSearch() {
        this.set('value', null);
        this.onClear();
      }
    }
  });

  _exports.default = _default;
});