define("sky-router-3/pods/portal/manage/geofences/edit/speed/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * Displayable altitude bounds.
     */
    unitSelected: Ember.computed.oneWay('sessionAccount.user.speedLand'),
    speedLimitCms: Ember.computed('model.geofence.speedLimitKph', {
      get: function get() {
        return this.model.geofence.get('speedLimitKph') * 27.777;
      },
      set: function set(key, value) {
        this.model.geofence.set('speedLimitKph', value / 27.777);
        return value;
      }
    }),
    enabled: Ember.computed('model.geofence.speedLimitKph', {
      get: function get() {
        return this.get('model.geofence.speedLimitKph') != null;
      },
      set: function set(key, value) {
        return !!this.model.geofence.set('speedLimitKph', value ? 100 : null);
      }
    })
  });

  _exports.default = _default;
});