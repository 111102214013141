define("sky-router-3/models/ext-data-feed-selection", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the data feed
     */
    name: (0, _model.attr)('string'),
    assets: (0, _model.attr)('array'),
    isExternalDataFeed: true
  });

  _exports.default = _default;
});