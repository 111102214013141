define("sky-router-3/validators/username-available", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UsernameAvailable = _base.default.extend({
    api: Ember.inject.service(),
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      var _this = this;

      if (value) {
        return this.get('api').getUsernameAvailable(value).then(function (r) {
          return r.BooleanResult ? true : _this.createErrorMessage('usernameInUse', value, options);
        });
      }
    }
  });

  var _default = UsernameAvailable;
  _exports.default = _default;
});