define("sky-router-3/models/asset-category", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ASSET_CATEGORY = _exports.default = void 0;

  /* eslint-env node */

  /**
   * Describes all the asset categories
   * @type {Object}
   */
  var ASSET_CATEGORY = {
    air: '0',
    land: '1',
    sea: '2',
    other: '3'
  };
  /**
   * This describes the possible asset categories that are available to assets.
   * This is land, air, marine, other.
   */

  _exports.ASSET_CATEGORY = ASSET_CATEGORY;

  var model = _model.default.extend({
    /**
     * Injection of the users service for use in helper methods
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Name of the category
     */
    name: (0, _model.attr)('string'),

    /**
     * Description of the category
     */
    description: (0, _model.attr)('string'),

    /**
     * Should altitude be shown for this category of asset
     */
    hideAltitude: Ember.computed('id', function () {
      return this.id == ASSET_CATEGORY.sea || this.id == ASSET_CATEGORY.land;
    }),

    /**
     * Gets the unit record used for conversion of the unit value
     * @return {Ember.RSVP.Proimse} Promise when resolved contains the unit record.
     * @private
     */
    speedUnit: Ember.computed('sessionAccount.user.speedOther.id', function () {
      switch (this.id) {
        case ASSET_CATEGORY.air:
          return this.sessionAccount.user.get('speedAir');

        case ASSET_CATEGORY.land:
          return this.sessionAccount.user.get('speedLand');

        case ASSET_CATEGORY.sea:
          return this.sessionAccount.user.get('speedSea');

        default:
          return this.sessionAccount.user.get('speedOther');
      }
    })
  });

  var _default = model;
  _exports.default = _default;
});