define("sky-router-3/pods/portal/report/report-generator/setup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qB0ktwE+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"report.reportGenerator.setupNewReport\"],null]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"report.reportGenerator.timeInArea.title\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"report.reportGenerator.timeInArea.help\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"btn btn-primary\"]],[[\"@route\",\"@models\"],[\"portal.report.report-generator.report\",[30,[36,1],[\"time-in-area\",\"new\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-plus-circle\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"report.reportGenerator.setupReport\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"array\"]}",
    "moduleName": "sky-router-3/pods/portal/report/report-generator/setup/template.hbs"
  });

  _exports.default = _default;
});