define("sky-router-3/models/burst-service-geo-area", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Name
     */
    name: (0, _model.attr)('string'),

    /**
     * Description
     */
    //burstServiceGeoAreaGroup: belongsTo('burst-service-geo-area-group', { async: true, inverse: 'geoAreas' }),
    burstServiceGeoAreaGroup: (0, _model.attr)('number'),

    /**
     * Latitude in degrees
     */
    latitudeDegree: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Longitued in degrees
     */
    longitudeDegree: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Diametor of geoArea
     */
    diameterInKm: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Indicates if deleted in database
     */
    isDeleted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Color for geoArea shown in the map
     */
    color: (0, _model.attr)('string')
  });

  _exports.default = _default;
});