define("sky-router-3/pods/components/display-dop/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['text-bold'],
    classNameBindings: ['successClass'],
    intl: Ember.inject.service(),
    icon: Ember.computed('dop', function () {
      if (this.dop > 19) return 'ico-alert';
      if (this.dop > 10) return 'ico-warn';
      return 'ico-check';
    }),
    successClass: Ember.computed('dop', function () {
      if (this.dop > 19) return 'text-danger';
      if (this.dop > 10) return 'text-warning';
      return 'text-success';
    }),
    text: Ember.computed('dop', function () {
      if (this.dop > 19) return this.intl.t('dop.bad');
      if (this.dop > 10) return this.intl.t('dop.fair');
      if (this.dop > 2) return this.intl.t('dop.ok');
      return this.intl.t('dop.great');
    })
  });

  _exports.default = _default;
});