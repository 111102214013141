define("sky-router-3/services/maintenance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service is responsible for keeping the ember application healthy
   * while running. It does this by scheduling maintenance tasks.
   * For instance it can schedule a task that removes old or extra records from
   * ember data once they have become stale.
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * The ember-data store service
     * @return {DS.Store}
     */
    store: Ember.inject.service(),

    /**
     * Starts the maintenance scheduling process to perform the tasks at the
     * specified time.
     */
    scheduleAll: function scheduleAll() {
      this.jobExpireAssetPositionsSchedule();
      this.jobReloadAppSchedule();
    },

    /**
     * Called when the service is being destroyed. It should stop all its timers.
     */
    willDestroy: function willDestroy() {
      Ember.run.cancel(this.jobExpireAssetPositionsTimer);
      return this._super.apply(this, arguments);
    },

    /**
     * Holds a timer for the expire events job
     * @type {Timer}
     */
    jobExpireAssetPositionsTimer: null,

    /**
     * Holds a value that indicates how often to run the expire events job.
     * @default 180000 / every 3 minutes
     * @type {Number}
     */
    jobExpireAssetPositionsInterval: 180000,

    /**
     * The number of events that are allowed to exist in the local application.
     * @type {Number}
     */
    jobExpireAssetPositionsLimit: 700,

    /**
     * Schedules the job ExpireAssetPositions
     */
    jobExpireAssetPositionsSchedule: function jobExpireAssetPositionsSchedule() {
      var _this = this;

      this.jobExpireAssetPositionsTimer = Ember.run.later(this, function () {
        _this.jobExpireAssetPositions();

        _this.jobExpireAssetPositionsSchedule();
      }, this.get('jobExpireAssetPositionsInterval'));
    },

    /**
     * Job that checks to see if the number of events in the system exceeds the
     * limit and if it does then it purges events over that limit. This helps
     * keep the system from processing to many events in memory and slowing
     * the users experience down.
     *
     * It gets the number of records and the count to remove and then removes
     * records as long as they aren't the only record for the device.
     *
     * @return {Ember.RSVP.Promise} Promise that is resolved upon completion
     */
    jobExpireAssetPositions: function jobExpireAssetPositions() {
      var limit = this.get('jobExpireAssetPositionsLimit');
      var store = this.get('store');
      var records = store.peekAll('asset-position');
      var countToRemove = records.get('length') - limit;

      if (countToRemove > 0) {
        var orderedBy = records.sortBy('utc').reverse(); // Sorts from newest to oldest

        while (countToRemove > 0) {
          var record = orderedBy.popObject();
          var assetId = record.belongsTo('asset').id();
          var asset = store.peekRecord('asset', assetId);

          if (!asset || asset.get('positions.length') > 1) {
            store.unloadRecord(record);
            countToRemove--;
          }
        }
      }

      this.trigger('jobExpireAssetPositionsCompleted');
      return Ember.RSVP.resolve();
    },

    /**
     * Holds a value that indicates how often to run the reload page job.
     * @default 21600000 / every 6 hours
     * @type {Number}
     */
    jobReloadAppInterval: 21600000,

    /**
     * Schedules the job ReloadApp
     */
    jobReloadAppSchedule: function jobReloadAppSchedule() {
      Ember.run.later(this, function () {
        window.location.reload(true);
      }, this.get('jobReloadAppInterval'));
    }
  });

  _exports.default = _default;
});