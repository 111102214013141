define("sky-router-3/pods/components/portal/track/map/map-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Defines a grid of maps by their map configs
   *
   *
   * {{portal/track/map/map-grid configs=[array] onActiveMapChange='activeMapChanged' activeConfig=activeinstance }}
   */
  var _default = Ember.Component.extend({
    classNames: ['map-grid'],
    classNameBindings: ['mapGridCountClass'],
    mapGridCountClass: function () {
      return 'map-grid-row-count-' + this.get('rowCount');
    }.property('rowCount'),

    /**
     * Event is fired when the active map needs to change
     * @event onActiveMapChange
     * @param {mapConfig} the map config of the map to set active
     */

    /**
     * Config object of the active map
     */
    activeConfig: null,

    /**
     * Array containing instances of `state-track-map` of the maps in the grid
     * Each instance represents another map and its configuration/model
     * @private
     * @property
     */
    configs: null,
    //#region grid row/column size/count handlers
    //-----------------------------------------------------------------

    /**
     * The maximum number of columns a single row can contain.
     * This currently matches the bootstrap column limit as that is used for implementation
     * @constant
     * @property
     * @public
     */
    MAX_COLUMNS: 12,

    /**
     * This indicates the maximum number of maps this control supports
     * Right now this number is set to 24 because that is when grid math breaks down
     * There are no protections in this code to prevent the user from adding too many maps they just won't display properly
     * @constant
     * @property
     * @public
     */
    MAX_MAP_COUNT: 16,

    /**
     * Defines the number of maps to display to the user
     * @public
     * @property
     */
    mapCount: Ember.computed.reads('configs.length'),

    /**
     * Count of the number of columns needed to display the grid
     * @private
     * @property
     */
    columnCount: function () {
      return Math.ceil(Math.sqrt(this.get('mapCount')));
    }.property('mapCount'),

    /**
     * Count of the number of rows needed to display the grid
     * @private
     * @property
     */
    rowCount: function () {
      return Math.ceil(this.get('mapCount') / this.get('columnCount'));
    }.property('mapCount', 'columnCount'),

    /**
     * The width of each column which represents a round number out of 12
     * @private
     * @property
     */
    columnWidth: function () {
      return Math.round(this.get('MAX_COLUMNS') / this.get('columnCount'));
    }.property('columnCount'),

    /**
     * Calculates the width of the last grid item so it will fill up all remaining space
     * @private
     * @property
     */
    lastColumnWidth: function () {
      // Get the number of empty spaces available
      var emptySpaces = this.get('rowCount') * this.get('columnCount') - this.get('mapCount'); // Take the number of spaces available + the last column itself times the column width and return that as the last columns width

      return (emptySpaces + 1) * this.get('columnWidth');
    }.property('rowCount', 'columnCount', 'mapCount', 'columnWidth') //-----------------------------------------------------------------
    //#endregion grid handlers

  });

  _exports.default = _default;
});