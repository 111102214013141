define("sky-router-3/pods/portal/operations/burst-services/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      skip: {
        refreshModel: true
      },
      take: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        messageHistory: this.store.query('burst-service-message-history', {
          skip: params.skip,
          take: params.take
        })
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});