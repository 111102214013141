define("sky-router-3/pods/components/portal/track/map/drawing-manager/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component handles drawing tasks on a map.
   * @see https://developers.google.com/maps/documentation/javascript/drawinglayer
   * @example {{drawing-manager map=map mode=google.maps.drawing.OverlayType.CIRCLE circleCompleted='circle' polygonCompleted='polygon' }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    /**
     * @event circleCompleted
     * Fired when the user finishes drawing a circle
     * @param {object} containing
     *      {google.maps.drawing.DrawingManager}- the manager that created the circle
     *      {google.maps.Circle} - the completed circle
     */
    circleCompleted: function circleCompleted() {},

    /**
     * @event polygonCompleted
     * Fired when the user finishes drawing a polygon
     * @param {object} containing
     *      {google.maps.drawing.DrawingManager}- the manager that created the polygon
     *      {google.maps.Polygon} - the completed polygon
     */
    polygonCompleted: function polygonCompleted() {},

    /**
     * @event rectangleCompleted
     * Fired when the user finishes drawing a rectangle
     * @param {object} containing
     *      {google.maps.drawing.DrawingManager}- the manager that created the rectangle
     *      {google.maps.Polygon} - the completed rectangle
     */
    rectangleCompleted: function rectangleCompleted() {},

    /**
     * Google mapping properties for the mixin
     */
    googlePropertyObject: Ember.computed.alias('_instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'options',
      setFunc: 'setOptions'
    }, {
      event: 'circlecomplete',
      eFunc: '_circleCompleted'
    }, {
      event: 'polygoncomplete',
      eFunc: '_polygonCompleted'
    }, {
      event: 'rectanglecomplete',
      eFunc: '_rectangleCompleted'
    }, {
      property: 'drawingMode',
      setFunc: 'setDrawingMode'
    }],

    /**
     * The instance of the drawing manager
     * @type {google.maps.drawing.DrawingManager}
     */
    _instance: null,

    /**
     * Any shapes that have been drawn
     */
    _shapes: [],

    /**
     * Passed in map instance the drawing manager is associated with
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Is the circle drawing control visible
     * @type {boolean}
     */
    toolCircle: false,

    /**
     * Is the polygon drawing control visible
     * @type {boolean}
     */
    toolPolygon: false,

    /**
     * Is the square drawing control visible
     * @type {Boolean}
     */
    toolRectangle: false,

    /**
     * Options object that is used to configure the instance
     * @type {google.maps.drawing.DrawingManagerOptions}
     */
    options: Ember.computed('toolCircle', 'toolPolygon', 'toolRectangle', function () {
      var opts = {
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: []
        }
      };

      if (this.toolCircle) {
        opts.drawingControlOptions.drawingModes.push(google.maps.drawing.OverlayType.CIRCLE);
      }

      if (this.toolPolygon) {
        opts.drawingControlOptions.drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
      }

      if (this.toolRectangle) {
        opts.drawingControlOptions.drawingModes.push(google.maps.drawing.OverlayType.RECTANGLE);
      }

      return opts;
    }),

    /**
     * The current drawing mode. aka polygon, circle
     * @type {String} of 'circle', 'polygon'
     * @default 'circle'
     */
    mode: 'circle',

    /**
     * Looks at the current drawing mode property and assigns the instance
     * that mode. This is a method and not a property mapping because .hbs
     * files don't like to reference google maps properties.
     */
    drawingMode: Ember.computed('mode', function () {
      switch (this.mode) {
        case 'circle':
          return google.maps.drawing.OverlayType.CIRCLE;

        case 'polygon':
          return google.maps.drawing.OverlayType.POLYGON;

        case 'rectangle':
        default:
          return google.maps.drawing.OverlayType.RECTANGLE;
      }
    }),

    /**
     * Init the instance
     */
    didInsertElement: function didInsertElement() {
      var instance = new google.maps.drawing.DrawingManager(this.options);
      this.set('_instance', instance);
    },

    /**
     * Destroy the instance
     */
    willDestroyElement: function willDestroyElement() {
      this._instance.setMap(null);

      this._shapes.forEach(function (shape) {
        shape.setMap(null);
      });

      this._shapes = [];
    },

    /**
     * Fired when the polygon object has been finished
     */
    _polygonCompleted: function _polygonCompleted(drawingManager, polygonCompleted) {
      this.polygonCompleted({
        manager: drawingManager,
        polygon: polygonCompleted
      });

      this._shapes.push(polygonCompleted);
    },

    /**
     * Fired when the circle object has been finished
     */
    _circleCompleted: function _circleCompleted(drawingManager, circleCompleted) {
      this.circleCompleted({
        manager: drawingManager,
        circle: circleCompleted
      });

      this._shapes.push(circleCompleted);
    },

    /**
     * Fired when the rectangle object has been finished
     */
    _rectangleCompleted: function _rectangleCompleted(drawingManager, rectanglecompleted) {
      this.rectangleCompleted({
        manager: drawingManager,
        rectangle: rectanglecompleted
      });

      this._shapes.push(rectanglecompleted);
    }
  });

  _exports.default = _default;
});