define("sky-router-3/validators/file", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extends the base validator to allow for validation of file-inputs.
   *
   * This object will attempt to lookup the localized error message using the key 'errors.file'
   *
   * The options object used when creating this validator is formatted like This
   *
   * {
   *    allowEmpty: true,
   *    inputId: '', identifier for input (required)
   *    sizeLimit: 100000, // Size in bytes for file limit,
   *    allowTypes: ['image']|/regex/, // Array of allowed types or regex
   * }
   *
   */
  var FileValidator = _base.default.extend({
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      var input = document.getElementById(Ember.get(options, 'inputId'));

      if (!value || input.files.length === 0) {
        if (Ember.getWithDefault(options, 'allowEmpty', true)) {
          return true;
        }

        return this.createErrorMessage('empty', value, options);
      }

      var limit = Ember.get(options, 'sizeLimit');

      if (limit && input.files[0].size > limit) {
        return this.createErrorMessage('tooLarge', value, options);
      }

      var allowTypes = Ember.get(options, 'allowTypes');

      if (allowTypes) {
        if (Ember.isArray(allowTypes)) {
          if (!allowTypes.includes(input.files[0].type)) {
            return this.createErrorMessage('badType', value, options);
          }
        } else {
          if (!allowTypes.test(input.files[0].type)) {
            return this.createErrorMessage('badType', value, options);
          }
        }
      }

      return true;
    }
  });

  var _default = FileValidator;
  _exports.default = _default;
});