define("sky-router-3/pods/components/portal/manage/device-parameters/controls/nal-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    iridiumEnabledSetting: null,
    gsmEnabledSetting: null,
    linkPrimarySetting: null
  });

  _exports.default = _default;
});