define("sky-router-3/pods/components/display-date-relative/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5f0cn/f",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,1]],[2,\"\\n\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"popupDate\",\"dateDisplay\",\"attach-tooltip\"]}",
    "moduleName": "sky-router-3/pods/components/display-date-relative/template.hbs"
  });

  _exports.default = _default;
});