define("sky-router-3/pods/portal/manage/device-parameters/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Indicates if this form is read only and should not allow edits
     * @type {Boolean}
     */
    isHistorical: function () {
      return this.get('model.set.revisionLatest.id') !== this.get('model.revision.id');
    }.property('model.set.revisionLatest.id', 'model.revision.id'),

    /**
     * Gets the path to the component that displays UI for all the settings
     * this revision has.
     * @return {String}
     */
    parameterComponent: function () {
      switch (this.get('model.set.type.id')) {
        case "1":
          return 'portal/manage/device-parameters/types/extreme';

        case "2":
          return 'portal/manage/device-parameters/types/he7200';

        case "3":
          return 'portal/manage/device-parameters/types/he72327';

        case "4":
          return 'portal/manage/device-parameters/types/d411';

        case "5":
          return 'portal/manage/device-parameters/types/d1000';

        case "6":
          return 'portal/manage/device-parameters/types/d1000a';

        case "8":
          return 'portal/manage/device-parameters/types/d2000m';

        case "9":
          return 'portal/manage/device-parameters/types/d2000a';

        case "12":
          return 'portal/manage/device-parameters/types/he5100';

        case "13":
          return 'portal/manage/device-parameters/types/he5200';

        case "14":
          return 'portal/manage/device-parameters/types/he5300';

        case "15":
          return 'portal/manage/device-parameters/types/d410';

        case "16":
          return 'portal/manage/device-parameters/types/he7200restricted';

        case "17":
          return 'portal/manage/device-parameters/types/he72327restricted';

        case "1001":
          return 'portal/manage/device-parameters/types/he3000';

        case "1002":
          return 'portal/manage/device-parameters/types/heptp';

        case "1003":
          return 'portal/manage/device-parameters/types/rockfleet';

        case "1004":
          return 'portal/manage/device-parameters/types/heptns';

        case "1005":
          return 'portal/manage/device-parameters/types/rockair';

        case "1006":
          return 'portal/manage/device-parameters/types/he8100-vehicle';

        case "1007":
          return 'portal/manage/device-parameters/types/echo';

        case "1008":
          return 'portal/manage/device-parameters/types/wave';

        case "1009":
          return 'portal/manage/device-parameters/types/adt';

        case "1010":
          return 'portal/manage/device-parameters/types/shout3g';

        case "1011":
          return 'portal/manage/device-parameters/types/edgesolar';

        case "1012":
          return 'portal/manage/device-parameters/types/comcenter';
      }
    }.property('model.set.type.id'),

    /**
     * A optional comment the user can enter about the revision
     * @type {string}
     */
    revisionComment: null,

    /**
     * Sorts the revision history provided by the model hook for displaying
     * @type {Array}
     */
    revisionHistorySortBy: ['createdDate:desc'],
    revisionHistorySorted: Ember.computed.sort('model.revisions', 'revisionHistorySortBy')
  });

  _exports.default = _default;
});