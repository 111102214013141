define("sky-router-3/pods/portal/communicate/compose/draft/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lx+FjJCh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"communicate.headerEditDraft\"],null]],[13],[2,\"\\n\"],[1,[30,[36,3],null,[[\"assets\",\"contacts\",\"subject\",\"to\",\"cc\",\"bcc\",\"body\",\"onSave\",\"onSend\",\"isSaving\"],[[35,1,[\"assets\"]],[35,1,[\"contacts\"]],[35,1,[\"message\",\"subject\"]],[35,1,[\"message\",\"to\"]],[35,1,[\"message\",\"cc\"]],[35,1,[\"message\",\"bcc\"]],[35,1,[\"message\",\"body\"]],[30,[36,2],[\"updateDraft\"],null],[30,[36,2],[\"sendDraft\"],null],[35,1,[\"message\",\"isSaving\"]]]]]],[2,\"\\n\"],[6,[37,4],null,[[\"class\",\"action\",\"isLoading\"],[\"btn-danger\",[30,[36,2],[\"deleteMessage\",[35,1,[\"message\"]]],null],[35,1,[\"message\",\"isSaving\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"ico-delete\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonDeletePermanently\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"route-action\",\"portal/communicate/compose-message\",\"bs-button\"]}",
    "moduleName": "sky-router-3/pods/portal/communicate/compose/draft/template.hbs"
  });

  _exports.default = _default;
});