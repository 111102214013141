define("sky-router-3/helpers/mod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function mod(params
  /*, hash*/
  ) {
    (true && !(params.length >= 2) && Ember.assert("You need to pass two values to mod.", params.length >= 2));
    (true && !(!isNaN(params[0])) && Ember.assert("Parameter 0 is not a number.", !isNaN(params[0])));
    (true && !(!isNaN(params[1])) && Ember.assert("Parameter 1 is not a number.", !isNaN(params[1])));
    return params[0] % params[1] == 0;
  });

  _exports.default = _default;
});