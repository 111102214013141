define("sky-router-3/pods/portal/forms/index/route", ["exports", "sky-router-3/models/form-template-type"], function (_exports, _formTemplateType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    signalr: Ember.inject.service(),
    queryParams: {
      archived: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.signalr.on('onNewFormEntry', function () {
        _this.refresh();
      });
    },
    model: function model(params) {
      return this.store.query('form', {
        archived: params.archived,
        search: params.search || undefined,
        templateType: _formTemplateType.types.userForm
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('selected', []);
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});