define("sky-router-3/pods/components/portal/track/map/overlays/noaa-gairmet-settings/component", ["exports", "sky-router-3/utils/computed-alias-not"], function (_exports, _computedAliasNot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     * Options based on https://www.aviationweather.gov/help/webservice?page=gairmetjson
     * @type {Array}
     */
    forecastOptions: function () {
      var intl = this.get('intl');
      return [{
        value: 0,
        label: intl.t('parameter.misc.currentTime')
      }, {
        value: 3,
        label: intl.t('parameter.misc.countHours', {
          count: 3
        })
      }, {
        value: 6,
        label: intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: 9,
        label: intl.t('parameter.misc.countHours', {
          count: 9
        })
      }, {
        value: 12,
        label: intl.t('parameter.misc.countHours', {
          count: 12
        })
      }];
    }.property(),
    forecastSelected: function () {
      var opts = this.get('forecastOptions');
      return opts.findBy('value', this.get('settings.generic.forecast')) || opts[0];
    }.property('settings.generic.forecast'),

    /**
     * Checkboxes
     */
    showIcing: (0, _computedAliasNot.default)('settings.generic.hideIcing'),
    showTurbulenceHigh: (0, _computedAliasNot.default)('settings.generic.hideTurbulenceHigh'),
    showTurbulenceLow: (0, _computedAliasNot.default)('settings.generic.hideTurbulenceLow'),
    showIfr: (0, _computedAliasNot.default)('settings.generic.hideIfr'),
    showMountain: (0, _computedAliasNot.default)('settings.generic.hideMountain'),
    showSurfaceWind: (0, _computedAliasNot.default)('settings.generic.hideSurfaceWind'),
    showLowLevelWindShear: (0, _computedAliasNot.default)('settings.generic.hideLowLevelWindShear'),
    showFreezingLevel: (0, _computedAliasNot.default)('settings.generic.hideFreezingLevel'),
    actions: {
      setForecast: function setForecast(sel) {
        this.set('settings.generic.forecast', sel.value);
      }
    }
  });

  _exports.default = _default;
});