define("sky-router-3/pods/portal/manage/device-compound-rules/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('asset-compound-rule');
    },
    actions: {
      delete: function _delete(rule) {
        var _this = this;

        if (confirm(this.intl.t('parameter.deleteSetConfirm', {
          name: rule.get('name')
        }))) {
          rule.destroyRecord().catch(function () {
            _this.notifications.error(_this.intl.t('misc.failedOperation'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});