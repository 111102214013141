define("sky-router-3/pods/components/portal/track/asset-list/component", ["exports", "sky-router-3/utils/filter-by-in-array"], function (_exports, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the component that drives the list of assets the user sees on the map.
   *
   * Assets are recovered from the server via ember-data at the router and then are
   * passed down the chain to the controller where they are filtered then proceed
   * here where they are sorted and then categorized into groups
   * for displaying to the end user.
   *
   * Information about the display characteristics of the UI are stored in the `config`
   * property that is backed by local storage on the users machine.
   */
  var _default = Ember.Component.extend({
    // Generic component options, see ember docs for more info
    classNames: ['track-window'],
    tagName: ['article'],

    /**
     * This action fires anytime that an event happens inside this component.
     * Actions are grouped for brevity and defined by a first parameter `action`.
     *
     * @event 'onWindowAction'
     * @param {string} action - the name of the action that was fired.
     *
     *      sort - sort the list
     *      filter - filter the list
     *      search - search the list
     *      assetEventShow - when the +/- sign in the asset list clicked
     *                      causing the events for the asset to be shown.
     *      assetClicked - When the asset is clicked in the list window.
     *      groupExpanded - When the group is expanded or collapsed in the list
     *      assetFollow -   When the user wishes to follow an asset on the active map
     *      assetsShowBreadcrumbs - Show breadcrumbs for some assets represented by an array.
     *      assetFilterGeofences - Show only geofences that are assigned to these assets.
     *      assetFilterEvents - Show only events that come from these assets
     *      pintAssets - Ping all the assets supplied
     *
     * @param {object} options object structured the following way.
     *
     *      sort - { {string}property, {boolean}desc }
     *      filter - { {boolean} showActive, {boolean} showInactive }
     *      search - { {string} searchValue }
     *      assetEventShow - { {boolean} show , {string} assetId, {string} groupId }
     *      assetClicked - { {string} assetId }
     *      groupExpanded - { {string} groupId, {boolean} show }
     *      assetFollow -  { {string} assetId }
     *      assetsShowBreadcrumbs - { {string[]} assetIds }
     *      assetFilterGeofences - { {string[]} assetIds }
     *      assetFilterEvents - { {string[]} assetIds }
     *      pintAssets - { {string[]} assetIds }
     */

    /**
     * The users sessionAccount service
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Holds the incoming array of assets that is used to build asset list.
     * This is filtered by the asset filter properties.
     * @public
     * @property
     */
    assets: null,

    /**
     * Holds the incoming array of assets that have not been filtered.
     */
    assetUnfiltered: null,

    /**
     * Holds an array of assets that are present in the config filter.
     */
    assetSelected: (0, _filterByInArray.default)('assetUnfiltered', 'id', 'config.filterShowAssets'),

    /**
     * Holds incoming array of all the active groups for the user.
     */
    groups: null,

    /**
     * Holds an array of groups that are present in the config filter.
     */
    groupsSelected: (0, _filterByInArray.default)('groups', 'id', 'config.filterShowGroups'),

    /**
     * Contains the state that this component will have. This state is saved
     * and restored via local-storage.
     */
    config: null,

    /**
     * Properties by which the assets are sorted by. Default is by name.
     *
     * Any property can have ':asc' or ':desc' after the property name that
     * will cause the sort be based on that direction.
     *
     * See Ember.computed.sort for more info on the format of this array.
     * @private
     * @property
     */
    assetsSortedBy: function () {
      return [this.get('config.sortProperty') + (this.get('config.sortDesc') ? ':desc' : ':asc')];
    }.property('config.sortProperty', 'config.sortDesc'),

    /**
     * Sorts the incoming array of assets into the order they will be displayed in
     * @private
     * @property
     */
    assetsSorted: Ember.computed.sort('assets', 'assetsSortedBy'),

    /**
     * Gets an array of groups that the visible assets are members of
     * and also force filters any groups if the filter is set to do so.
     * @return {Array<sky-router-3/models/group>}
     */
    groupsFiltered: Ember.computed('assets.[]', function () {
      var _this = this;

      // Get a reduced list of groups that are visible to the assets
      var groups = this.assets.getEach('activeGroups').reduce(function (flat, toFlatten) {
        return flat.concat(toFlatten.filter(function (x) {
          return !flat.includes(x);
        }));
      }, []); // Filter on groups we have explicitly been told to show

      if (this.groupsSelected.length) {
        return groups.filter(function (x) {
          return _this.groupsSelected.includes(x);
        });
      }

      return groups;
    }),

    /**
     * Sorts the asset groups by their name for display in the UI.
     * @private
     * @property
     */
    groupsFilteredSorted: Ember.computed.sort('groupsFiltered', 'groupsFilteredSortedBy'),
    groupsFilteredSortedBy: ['name'],

    /**
     * Gets a list of displayable assets that are
     * not a member of a valid group at all
     * @return {Array<sky-router-3/models/asset>}
     */
    ungroupedAssets: Ember.computed('assetsSorted.[]', function () {
      return this.assetsSorted.filterBy('activeGroups.length', 0);
    }),

    /**
     * Indicates that the user clicked the search button icon
     * and it wasn't automatically opened because there is currently
     * a value in the search field. This value is not conveyed into storage
     * because when you come back to the page, search should only be shown
     * if there is a current search term.
     */
    _showSearch: false,

    /**
     * Indicates that the search box should be shown, this combines the users
     * request for it to be shown with the requirement it be open if there is
     * a value being searched.
     */
    showSearch: Ember.computed.or('config.searchValue', '_showSearch'),

    /**
     * This holds a value that indicates if the user should be allowed to execute
     * the show all breadcrumbs action. This is placed here because some
     * breadcrumb intervals are just too large to be trying to load at once
     * from the API. The default is the timespan has to be less than 30 minutes.
     * @return {Boolean}
     */
    breadcrumbLimitOutOfRange: Ember.computed.gt('sessionAccount.user.assetBreadcrumbTimespan', 30),

    /**
     * This gets an array of assets that support messaging.
     * @return {sky-router-3/models/asset[]}
     */
    assetsSupportingMessages: Ember.computed.filterBy('assets', 'supportsMessages'),

    /**
     * Returns a boolean that indicates if filters are not using their defaults
     * @return {Boolean}
     */
    filtersSet: function () {
      return this.get('config.filterShowActive') !== true || this.get('config.filterShowInactive') !== true || this.get('config.filterShowGroups.length') !== 0 || this.get('config.filterShowAssets.length') !== 0;
    }.property('config.filterShowActive', 'config.filterShowInactive', 'config.filterShowGroups.[]', 'config.filterShowAssets.[]'),
    actions: {
      resetFilters: function resetFilters() {
        this.onWindowAction('filter', {
          showActive: true,
          showInactive: true,
          showGroups: [],
          showAssets: []
        });
      },
      sortDescChanged: function sortDescChanged() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: !this.get('config.sortDesc')
        });
      },
      sortChanged: function sortChanged(value) {
        this.onWindowAction('sort', {
          property: value,
          desc: this.get('config.sortDesc')
        });
      },
      toggleSearch: function toggleSearch() {
        this.toggleProperty('_showSearch');
      },
      searchChanged: function searchChanged(searchValue) {
        this.onWindowAction('search', {
          searchValue: searchValue
        });
      },
      searchCleared: function searchCleared() {
        this.set('_showSearch', false);
      },
      showActiveChanged: function showActiveChanged(checked) {
        this.onWindowAction('filter', {
          showActive: checked,
          showInactive: this.get('config.filterShowInactive'),
          showGroups: this.get('config.filterShowGroups'),
          showAssets: this.get('config.filterShowAssets')
        });
      },
      showInactiveChanged: function showInactiveChanged(checked) {
        this.onWindowAction('filter', {
          showActive: this.get('config.filterShowActive'),
          showInactive: checked,
          showGroups: this.get('config.filterShowGroups'),
          showAssets: this.get('config.filterShowAssets')
        });
      },
      groupsFilterChanged: function groupsFilterChanged(selection) {
        this.onWindowAction('filter', {
          showActive: this.get('config.filterShowActive'),
          showInactive: this.get('config.filterShowInactive'),
          showGroups: selection.mapBy('id'),
          showAssets: this.get('config.filterShowAssets')
        });
      },
      assetsFilterChanged: function assetsFilterChanged(selection) {
        this.onWindowAction('filter', {
          showActive: this.get('config.filterShowActive'),
          showInactive: this.get('config.filterShowInactive'),
          showGroups: this.get('config.filterShowGroups'),
          showAssets: selection.mapBy('id')
        });
      },
      onGroupExpandToggled: function onGroupExpandToggled(groupId, expanded) {
        this.onWindowAction('groupExpanded', {
          groupId: groupId,
          show: expanded
        });
      },
      onAssetEventsShow: function onAssetEventsShow(show, assetId, groupId) {
        this.onWindowAction('assetEventShow', {
          show: show,
          assetId: assetId,
          groupId: groupId
        });
      },
      onAssetClicked: function onAssetClicked(assetId) {
        this.onWindowAction('assetClicked', {
          assetId: assetId
        });
      },
      onAssetFollow: function onAssetFollow(assetId) {
        this.onWindowAction('assetFollow', {
          assetId: assetId
        });
      },
      onAssetShowBreadcrumbs: function onAssetShowBreadcrumbs(assetIds) {
        this.onWindowAction('assetsShowBreadcrumbs', {
          assetIds: assetIds
        });
      },
      onShowAllBreadcrumbs: function onShowAllBreadcrumbs() {
        var assetIds = this.get('assets').map(function (item) {
          return item.get('id');
        });
        this.onWindowAction('assetsShowBreadcrumbs', {
          assetIds: assetIds
        });
      },
      onAssetFilterGeofences: function onAssetFilterGeofences(assetIds) {
        this.onWindowAction('assetFilterGeofences', {
          assetIds: assetIds
        });
      },
      onAssetFilterEvents: function onAssetFilterEvents(assetIds) {
        this.onWindowAction('assetFilterEvents', {
          assetIds: assetIds
        });
      },
      onPingAsset: function onPingAsset(asset) {
        this.onWindowAction('pingAsset', {
          asset: asset
        });
      },
      onAssetEventClicked: function onAssetEventClicked(assetId, eventId) {
        this.onWindowAction('assetEventClicked', {
          assetId: assetId,
          eventId: eventId
        });
      }
    }
  });

  _exports.default = _default;
});