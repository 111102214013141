define("sky-router-3/models/flight-plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Holds information about a flightplan that an asset has been on, or will be on.
   * Flight plan is basically a log of where the plane went, and what it had with it.
   */
  var _default = _model.default.extend({
    /**
     * The asset this flight plan is associated with.
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * If the origin corresponds to a location the user has access to
     * this will point to that location.
     */
    originLocation: (0, _model.belongsTo)('location'),

    /**
     * If the destination corresponds to a location the user has access to
     * this will point to that location.
     */
    destinationLocation: (0, _model.belongsTo)('location'),

    /**
     * Any user comments about the plan
     */
    remarks: (0, _model.attr)('string'),

    /**
     * The HH:MM of the arrival of the flight
     */
    estimatedArrival: (0, _model.attr)('string'),

    /**
     * The HH:MM of the departure of the flight
     */
    estimatedDeparture: (0, _model.attr)('string'),

    /**
     * The HH:MM of the time of the flight
     */
    estimatedEnroute: (0, _model.attr)('string'),

    /**
     * [The type of the flight rule
     * Valid values are:
     * 0 - VFR
     * 1 - IFR
     */
    flightRuleType: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * The origin of the flight plan.
     * This may correspond to the `code` field of the locations model.
     * when this happens the `originLocation` field will be populated
     * with the link to the location in question.
     */
    origin: (0, _model.attr)('string'),

    /**
     * The destination of the flight plan.
     * This may correspond to the `code` field of the locations model.
     * when this happens the `destinationLocation` field will be populated
     * with the link to the location in question.
     */
    destination: (0, _model.attr)('string'),

    /**
     * The number of people aboard the flight.
     */
    numberAboard: (0, _model.attr)('string'),

    /**
     * The ammount of fule on the flight
     */
    fuelOnboard: (0, _model.attr)('string'),

    /**
     * The flight number for this flight plan.
     */
    flightNumber: (0, _model.attr)('string'),

    /**
     * The date which this plan is supposed to start.
     */
    startDate: (0, _model.attr)('date'),

    /**
     * User defines the meaning of this field
     */
    directFlight: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    aircraftId: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    aircraftModel: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    aircraftColor: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    alternateDestination: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    route: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    destinationContact: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    cruisingAltitude: (0, _model.attr)('string'),

    /**
     * User defines the meaning of this field
     */
    airspeed: (0, _model.attr)('string'),

    /**
     * The date the flight plan was created.
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * The current status of the flight plan.
     * Valid values are:
     * 1 - Active
     * 2 - Canceled
     * 3 - Complete
     * 4 - Pending
     */
    status: (0, _model.belongsTo)('flight-plan-status'),

    /**
     * Information about a pilot
     */
    pilotInfo: (0, _model.attr)('string')
  });

  _exports.default = _default;
});