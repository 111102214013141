define("sky-router-3/utils/computed-local-preference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedLocalPreference;

  /**
   * Creates a computed property that read a stored preference field as default value if the property is not defined, 
   * if that is also not defined it uses the defaultValue prop which can be a function or a property
   * @param {string} property path to the property 
   * @param {string} storageProp path to the backup preference storage prop
   * @param {value|function} defaultValue a raw value or a callable function that returns the default value 
   */
  function computedLocalPreference(property, storageProp, defaultValue) {
    return Ember.computed(property, {
      get: function get() {
        // Get the value straight from the source
        var value = Ember.get(this, property);

        if (value !== undefined) {
          return value;
        } // Get the value from the storage prop


        value = Ember.get(this, storageProp);

        if (value !== undefined) {
          return value;
        } // Get the value from the defualt value generator that can be a bare prop or function


        if (defaultValue !== undefined) {
          if (defaultValue.call !== undefined) {
            return defaultValue.call(this);
          }

          return defaultValue;
        }

        return undefined;
      },
      set: function set(key, value) {
        // Update the source prop
        Ember.set(this, property, value); // Update the storage prop so it can be the intelligent default in the future

        Ember.set(this, storageProp, value);
        return value;
      }
    });
  }
});