define("sky-router-3/models/burst-service-geo-area-group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Name
     */
    name: (0, _model.attr)('string'),

    /**
     * Description
     */
    description: (0, _model.attr)('string'),

    /**
     * Default Color for GeoAreaGroup
     */
    color: (0, _model.attr)('string', {
      defaultValue: 'a200ff'
    }),

    /**
     * Indicates if deleted
     */
    isDeleted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Creation Date
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * UpdateDate
     */
    updatedDate: (0, _model.attr)('date'),

    /**
     * Array of geoAreas belonging to the group
     */
    geoAreas: (0, _model.attr)('ember-object-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }) //geoAreas: hasMany('burst-service-geo-area', { async: true })

  });

  _exports.default = _default;
});