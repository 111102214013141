define("sky-router-3/pods/components/bs-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LGX6upQC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"section\"],[14,0,\"modal block\"],[12],[2,\"\\n        \"],[11,\"div\"],[24,0,\"modal-backdrop fade in\"],[4,[38,1],[[32,0],[32,0,[\"onClose\"]],true],null],[12],[13],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"modal-dialog \",[34,2]]]],[14,5,\"z-index: 1050;\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n                \"],[10,\"header\"],[14,0,\"modal-header\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"modal-buttons\"],[12],[2,\"\\n                        \"],[11,\"button\"],[24,0,\"button button-close\"],[16,\"aria-label\",[31,[[30,[36,3],[\"common.close\"],null]]]],[24,4,\"button\"],[4,[38,1],[[32,0],[32,0,[\"onClose\"]],true],null],[12],[13],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"                            \"],[10,\"i\"],[15,0,[31,[[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                        \"],[1,[34,5]],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"titleIco\",\"action\",\"size\",\"t\",\"if\",\"title\",\"visible\"]}",
    "moduleName": "sky-router-3/pods/components/bs-modal/template.hbs"
  });

  _exports.default = _default;
});