define("sky-router-3/pods/components/portal/manage/device-parameters/types/echo/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,
    // didInsertElement() {
    //     this.set('reportInterval', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('lowPowerTrackingRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('lowPowerMode', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('trackerMode', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('cannedList', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('sound', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('ledControl', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('resetControls', this.store.createRecord('asset-parameter-set-revision-setting'));
    // },

    /**
     * Alias'd settings that are easier to get at.
     */
    reportInterval: (0, _computedFindBy.default)('settings', 'key', 'echo_report_interval'),
    lowPowerTrackingRate: (0, _computedFindBy.default)('settings', 'key', 'echo_low_power_report_rate'),
    lowPowerMode: (0, _computedFindBy.default)('settings', 'key', 'echo_low_power_mode'),
    trackerMode: (0, _computedFindBy.default)('settings', 'key', 'echo_tracker_mode'),
    cannedList: (0, _computedFindBy.default)('settings', 'key', 'echo_canned_selection'),
    sound: (0, _computedFindBy.default)('settings', 'key', 'echo_sound'),
    ledControl: (0, _computedFindBy.default)('settings', 'key', 'echo_led_control'),
    resetControls: (0, _computedFindBy.default)('settings', 'key', 'echo_reset_controls'),
    /////////////////////////////////////////////////////////////////////////////
    reportIntervalSelected: (0, _computedFindBy.default)('reportIntervalOptions', 'value', 'reportInterval.value', true),
    reportIntervalOptions: Ember.computed(function () {
      return [{
        value: '1',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '2',
        label: this.intl.t('parameter.misc.countMinute')
      }, {
        value: '3',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '5',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '6',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '7',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '8',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '9',
        label: this.intl.t('parameter.misc.countHour')
      }, {
        value: '10',
        label: this.intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '11',
        label: this.intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '12',
        label: this.intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '13',
        label: this.intl.t('parameter.misc.countDay')
      }];
    }),
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    lowPowerTrackingRateSelected: (0, _computedFindBy.default)('lowPowerTrackingRateOptions', 'value', 'lowPowerTrackingRate.value', true),
    lowPowerTrackingRateOptions: Ember.computed(function () {
      return [{
        value: '1',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '2',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '3',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '5',
        label: this.intl.t('parameter.misc.countHour')
      }, {
        value: '6',
        label: this.intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '7',
        label: this.intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '8',
        label: this.intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '9',
        label: this.intl.t('parameter.misc.countDay')
      }];
    }),
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    lowPowerModeSelected: (0, _computedFindBy.default)('lowPowerModeOptions', 'value', 'lowPowerMode.value', true),
    lowPowerModeOptions: Ember.computed(function () {
      return [{
        value: '1',
        label: 'ON'
      }, {
        value: '2',
        label: 'OFF'
      }, {
        value: '3',
        label: this.intl.t('parameter.misc.countMinute')
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 1
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '5',
        label: this.intl.t('parameter.misc.countHour')
      }];
    }),
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    trackerModeSelected: (0, _computedFindBy.default)('trackerModeOptions', 'value', 'trackerMode.value', true),
    trackerModeOptions: Ember.computed(function () {
      return [{
        value: '1',
        label: 'Personal Tracker'
      }, {
        value: '2',
        label: 'Vehicle Mode'
      }];
    }),
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    cannedListSelected: (0, _computedFindBy.default)('cannedListOptions', 'value', 'cannedList.value', true),
    cannedListOptions: Ember.computed(function () {
      return [{
        value: '1',
        label: 'Contact - wait out'
      }, {
        value: '2',
        label: 'Emergency situation'
      }, {
        value: '3',
        label: 'Emergency situation send help now'
      }, {
        value: '4',
        label: 'Assistance needed'
      }, {
        value: '5',
        label: 'Assistance not required'
      }, {
        value: '6',
        label: 'Vehicle breakdown'
      }, {
        value: '7',
        label: 'Vehicle breakdown - no casualty'
      }, {
        value: '8',
        label: 'Vehicle breakdown - casualty'
      }, {
        value: '9',
        label: 'Road traffic accident'
      }, {
        value: '10',
        label: 'Call Thuraya'
      }, {
        value: '11',
        label: 'Call GSM Cell'
      }, {
        value: '12',
        label: 'No GSM - monitor tracking and alerts'
      }, {
        value: '13',
        label: 'Departed'
      }, {
        value: '14',
        label: 'Arrived'
      }, {
        value: '15',
        label: 'Illegal vehicle checkpoint'
      }, {
        value: '16',
        label: 'Medical emergency - Send help'
      }, {
        value: '17',
        label: 'Public order situation'
      }, {
        value: '18',
        label: 'Medevac required'
      }, {
        value: '19',
        label: 'We have casualties'
      }, {
        value: '20',
        label: 'Spot'
      }, {
        value: '21',
        label: 'Report Line'
      }, {
        value: '22',
        label: 'Returning to base'
      }, {
        value: '23',
        label: 'Moving to MGRS'
      }, {
        value: '24',
        label: 'Moving to'
      }, {
        value: '25',
        label: 'Moving to spot'
      }, {
        value: '26',
        label: 'Grid reference'
      }, {
        value: '27',
        label: 'Enemy activity at'
      }, {
        value: '28',
        label: 'Helicopter landing site'
      }, {
        value: '29',
        label: 'Estimated time of arrival'
      }, {
        value: '30',
        label: 'Radio frequency'
      }, {
        value: '31',
        label: 'Code 1'
      }, {
        value: '32',
        label: 'Code 2'
      }, {
        value: '33',
        label: 'Code 3'
      }, {
        value: '34',
        label: 'Code 4'
      }, {
        value: '35',
        label: 'Code 5'
      }, {
        value: '36',
        label: 'Code 6'
      }, {
        value: '37',
        label: 'Code 7'
      }, {
        value: '38',
        label: 'Code 8'
      }, {
        value: '39',
        label: 'Code 9'
      }, {
        value: '40',
        label: 'Code 10'
      }, {
        value: '41',
        label: 'Code 11'
      }, {
        value: '42',
        label: 'Code 12'
      }, {
        value: '43',
        label: 'Code 13'
      }, {
        value: '44',
        label: 'Code 14'
      }, {
        value: '45',
        label: 'Code Alpha'
      }, {
        value: '46',
        label: 'Code Bravo'
      }, {
        value: '47',
        label: 'Code Charlie'
      }, {
        value: '48',
        label: 'Code Delta'
      }, {
        value: '49',
        label: 'Code Echo'
      }, {
        value: '50',
        label: 'Code Foxtrot'
      }, {
        value: '51',
        label: 'Code Golf'
      }, {
        value: '52',
        label: 'Code Hote'
      }, {
        value: '53',
        label: 'Code India'
      }, {
        value: '54',
        label: 'Code Juliet'
      }, {
        value: '55',
        label: 'Code Kilo'
      }, {
        value: '56',
        label: 'Code Lima'
      }, {
        value: '57',
        label: 'Code Mike'
      }, {
        value: '58',
        label: 'Code November'
      }, {
        value: '59',
        label: 'Code Oscar'
      }, {
        value: '60',
        label: 'Code Papa'
      }, {
        value: '61',
        label: 'Code Quebec'
      }, {
        value: '62',
        label: 'Code Romeo'
      }, {
        value: '63',
        label: 'Code Sierra'
      }, {
        value: '64',
        label: 'Code Tango'
      }, {
        value: '65',
        label: 'Code Uniform'
      }, {
        value: '66',
        label: 'Code Victor'
      }, {
        value: '67',
        label: 'Code Whiskey'
      }, {
        value: '68',
        label: 'Code X-ray'
      }, {
        value: '69',
        label: 'Code Yankee'
      }, {
        value: '70',
        label: 'Code Zulu'
      }];
    }),
    /////////////////////////////////////////////////////////////////////////////
    actions: {
      slectorChanged: function slectorChanged(key, selection) {
        this.set("".concat(key, ".value"), selection.value);
      }
    }
  });

  _exports.default = _default;
});