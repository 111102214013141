define("sky-router-3/validators/asset-name", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AssetName = _base.default.extend({
    api: Ember.inject.service(),
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      var _this = this;

      if (value) {
        return this.get('api').getValidateAssetNameChange(Ember.get(options, 'assetId'), value).then(function (r) {
          if (r.Code === 1) {
            return _this.createErrorMessage('nameInUse', value, options);
          } else if (r.Code === 2) {
            return _this.createErrorMessage('invalid', value, options);
          }

          return true;
        });
      }

      return true;
    }
  });

  var _default = AssetName;
  _exports.default = _default;
});