define("sky-router-3/pods/portal/communicate/controller", ["exports", "sky-router-3/models/mail-folder"], function (_exports, _mailFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Folders that are reserved.
     * @type {Object}
     */
    reservedFolders: _mailFolder.MAIL_FOLDERS,

    /**
     * Returns an array of mail-folder objects that are considered the
     * top level mailboxes because they are reserved boxes.
     * @return {sky-router-3/models/mail-folder[]}
     */
    topLevelBoxes: Ember.computed.filterBy('model', 'isReservedFolder', true),

    /**
     * Returns an array of all the sub level boxes for this account
     * @return {sky-router-3/models/mail-folder[]}
     */
    subLevelBoxes: Ember.computed.setDiff('model', 'topLevelBoxes')
  });

  _exports.default = _default;
});