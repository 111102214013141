define("sky-router-3/pods/portal/manage/location/type/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('location-type');
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('imageFile', null);
      }
    },
    actions: {
      saveType: function saveType(icon) {
        var _this = this;

        this.modelFor(this.routeName).save().then(function (type) {
          _this.get('api').saveLocationTypeIcon(type.get('id'), icon).then(function () {
            _this.transitionTo('portal.manage.location.type');
          }).catch(function () {
            type.destroyRecord();

            _this.notifications.error(_this.intl.t('locations.locationTypeFail'));
          });
        }).catch(function () {
          _this.notifications.error(_this.intl.t('locations.locationTypeFail'));
        });
      },
      cancel: function cancel() {
        this.transitionTo('portal.manage.location.type');
      },
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.get('isNew')) {
          model.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});