define("sky-router-3/pods/portal/settings/contact/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.firstName': (0, _emberCpValidations.validator)('presence', true),
    'model.lastName': (0, _emberCpValidations.validator)('presence', true),
    'model.email': (0, _emberCpValidations.validator)('email')
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    groupsSorting: ['isActive:desc', 'endDateUtc:desc'],
    groupsSorted: Ember.computed.sort('model.groups', 'groupsSorting')
  });

  _exports.default = _default;
});