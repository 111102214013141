define("sky-router-3/pods/portal/report/positionreporthistory/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the report for position report history. It handles creating the
   * report parameter options which include the data structures for the dropdown lists and date pickers.
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.positionReportHistory');
    },

    /**
     * The session service
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.positionreporthistory'],

    /**
     * The model for the position report history reports route that contains a device list, an events list and a customer reports list.
     **/
    model: function model()
    /*params*/
    {
      // Return the hashed model values
      return Ember.RSVP.hash({
        devices: this.store.findAll('asset'),
        events: this.store.findAll('asset-position-type'),
        altitudeOptions: this.store.findAll('unitAltitude'),
        speedOptions: this.store.findAll('unitSpeed'),
        // Creates a static list of the position report history report column names
        columns: [{
          "value": "IMEI",
          text: this.intl.t('report.positionReportHistory.columns.IMEI')
        }, {
          "value": "Name",
          text: this.intl.t('report.positionReportHistory.columns.Name')
        }, {
          "value": "Serial",
          text: this.intl.t('report.positionReportHistory.columns.Serial')
        }, {
          "value": "Time",
          text: this.intl.t('report.positionReportHistory.columns.Time')
        }, {
          "value": "ServerTime",
          text: this.intl.t('report.positionReportHistory.columns.ServerTime')
        }, {
          "value": "LatencySeconds",
          text: this.intl.t('report.positionReportHistory.columns.LatencySeconds')
        }, {
          "value": "Type",
          text: this.intl.t('report.positionReportHistory.columns.Type')
        }, {
          "value": "Altitude",
          text: this.intl.t('report.positionReportHistory.columns.Altitude')
        }, {
          "value": "Velocity",
          text: this.intl.t('report.positionReportHistory.columns.Velocity')
        }, {
          "value": "Heading",
          text: this.intl.t('report.positionReportHistory.columns.Heading')
        }, {
          "value": "DOP",
          text: this.intl.t('report.positionReportHistory.columns.DOP')
        }, {
          "value": "Latitude",
          text: this.intl.t('report.positionReportHistory.columns.Latitude')
        }, {
          "value": "Longitude",
          text: this.intl.t('report.positionReportHistory.columns.Longitude')
        }, {
          "value": "MGRS",
          text: this.intl.t('report.positionReportHistory.columns.MGRS')
        }, {
          "value": "Source",
          text: this.intl.t('report.positionReportHistory.columns.Source')
        }, {
          "value": "Information",
          text: this.intl.t('report.positionReportHistory.columns.Information')
        }]
      });
    },
    afterModel: function afterModel(model) {
      if (this.sessionAccount.user.get('hideAssetSpeed')) {
        model.columns.removeObjects(model.columns.findBy('value', 'Velocity'));
      }

      if (this.sessionAccount.user.get('hideAssetAltitude')) {
        model.columns.removeObjects(model.columns.findBy('value', 'Altitude'));
      }
    }
  });

  _exports.default = _default;
});