define("sky-router-3/pods/portal/manage/contacts/edit/controller", ["exports", "ember-cp-validations", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/constants"], function (_exports, _emberCpValidations, _latLngConverter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.contact.firstName': (0, _emberCpValidations.validator)('presence', true),
    'model.contact.email': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('email')]
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * Generates the timezone array used by the model
     */
    timeZones: _constants.timeZones,

    /**
     * Gets the currently selected timezone for the user
     */
    selectedTimeZone: function () {
      return this.get('timeZones').findBy('id', this.get('model.contact.timeZone'));
    }.property('model.contact.timeZone'),

    /**
     * Point used by the map to manipulate the lat long corrdinates
     */
    mapPoint: Ember.computed('model.contact.latitudeMilliarcseconds', 'model.contact.longitudeMilliarcseconds', {
      get: function get() {
        return {
          lat: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.get('model.contact.latitudeMilliarcseconds')),
          lng: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.get('model.contact.longitudeMilliarcseconds'))
        };
      },
      set: function set(key, value) {
        this.set('model.contact.latitudeMilliarcseconds', _latLngConverter.default.degreesToMilliArcSeconds(value.lat()));
        this.set('model.contact.longitudeMilliarcseconds', _latLngConverter.default.degreesToMilliArcSeconds(value.lng()));
        return value;
      }
    }),
    actions: {
      changeTimezone: function changeTimezone(zone) {
        this.set('model.contact.timeZone', zone.id);
      }
    }
  });

  _exports.default = _default;
});