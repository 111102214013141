define("sky-router-3/pods/components/portal/track/map/overlays/user-events-context/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jyCw97d0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"showCreate\"],null],[12],[1,[30,[36,1],[\"userEvent.addHere\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/overlays/user-events-context/template.hbs"
  });

  _exports.default = _default;
});