define("sky-router-3/models/data-usage", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the driver
     */
    imei: (0, _model.attr)('number'),

    /**
     * Type of the driver (personnel or user)
     */
    planName: (0, _model.attr)('string'),

    /**
     * Type of the driver phone number or cell phone number
     */
    planTotal: (0, _model.attr)('number'),

    /**
     * Type of the driver employee number
     */
    blocksUsed: (0, _model.attr)('number')
  });

  _exports.default = _default;
});