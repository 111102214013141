define("sky-router-3/pods/components/portal/manage/devices/hept-address-book/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    asset: null,
    addressEntries: function () {
      var promise = this.get('store').query('hept-address', {
        asset: this.get('asset.id')
      }).then(function (x) {
        return x.sortBy('name');
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }.property('asset.id')
  });

  _exports.default = _default;
});