define("sky-router-3/pods/components/portal/report/dynamic-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Indicates if the no records found message is to be shown
     * @type {Boolean}
     */
    showNoRecordMessage: false,

    /**
     * The user selected utc offset. UTC or local
     * @type {Number}
     */
    utcOffset: 0,

    /**
     * The name of the report
     */
    reportName: '',

    /**
     * The API route for the report data
     */
    apiRoute: '',

    /**
     * The identifier parameter
     */
    identifierParam: '',

    /**
     * The columns for the report. These are passed into the component and necessary because for the grid to work properly
     */
    reportCols: null,

    /**
     * Indicates whether or not the loading message is shown for the report generation
     */
    loading: true,

    /**
     * The report result set
     **/
    reportData: null,

    /**
     * The total number of records in the result set
     */
    totalRecords: 0,

    /**
     * The start index of the results
     */
    startIndex: 1,

    /**
     * The end index of the results
     */
    endIndex: 0,

    /**
     * The current page of the report results
     */
    currentPage: 1,

    /**
     * The amount of returned records
     */
    resultCount: 0,

    /**
     * The amount of records per paged results
     **/
    take: 300,

    /**
     * The user options for page size
     */
    takeSelections: [300, 500, 1000],

    /**
     * Creates the applicable pages list
     */
    pages: Ember.computed('totalRecords', 'take', function () {
      var pages = [];
      var take = this.get('take');
      var totalRecords = this.get('totalRecords');
      var count = Math.ceil(totalRecords / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }),

    /**
     * On init the report should be generated with default parameters or the ones passed to the route
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this.generateReport();
    },

    /**
     * This is the function that triggers the report to be rendered.
     * @function
     * @private
     */
    onGenerateReportClicked: function () {
      this.set('currentPage', 1);
      this.generateReport();
    }.observes('generateReportClicked'),

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport() {
      var _this = this;

      // Get report parameters
      var formId = this.get('identifierParam');
      var rowCount = this.get('take');
      var page = this.get('currentPage');
      this.set('loading', true);
      this.get('api').getSubmittedFormsReportData(rowCount, page, formId, this.startDate, this.endDate, this.utcOffset).then(function (data) {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('reportData', data.rows);

          _this.set('totalRecords', data.records);

          _this.set('showNoRecordMessage', data.records === 0 ? true : false);

          _this.set('page', data.page);

          _this.set('resultCount', data.total);

          _this.set('startIndex', (data.page - 1) * rowCount + 1);

          var endIndex = data.page * rowCount;

          if (endIndex > data.records) {
            endIndex = data.records;
          }

          _this.set('endIndex', endIndex);

          _this.set('loading', false);
        }
      }).catch(function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('loading', false);
        }
      });
    },
    actions: {
      countChanged: function countChanged(selection) {
        this.set('take', selection);
        this.set('currentPage', 1);
        this.set('totalRecords', 0);
        this.generateReport();
      },
      pageSelected: function pageSelected(page) {
        var currentPage = this.get('currentPage');

        if (page !== currentPage) {
          this.set('currentPage', page);
          this.generateReport();
        }
      }
    }
  });

  _exports.default = _default;
});