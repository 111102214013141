define("sky-router-3/helpers/in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper checks to see if a value is present in the array.
   *
   * @example {{#if (in-array indexArray indexToCheck)}} .... {{/if}}
   * @see http://emberjs.com/blog/2015/06/12/ember-1-13-0-released.html#toc_new-ember-js-helper-api
   */
  var _default = Ember.Helper.extend({
    /**
     * Actually performs the computation.
     */
    compute: function compute(params
    /*, hash*/
    ) {
      // Validate
      if (!Ember.isArray(params[0]) || params.length !== 2) {
        return false;
      } // Return


      return params[0].indexOf(params[1]) !== -1;
    }
  });

  _exports.default = _default;
});