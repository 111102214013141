define("sky-router-3/models/location", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var model = _model.default.extend({
    type: (0, _model.belongsTo)('location-type', {
      async: true
    }),
    latitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    altitudeCentimeters: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    pointIsValid: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    altitudeDisplayValue: (0, _model.attr)('string', {
      defaultValue: '0'
    }),
    altitudeDisplayUnit: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    createdDate: (0, _model.attr)('date'),
    name: (0, _model.attr)('string'),
    code: (0, _model.attr)('string'),
    city: (0, _model.attr)('string'),
    contactName: (0, _model.attr)('string'),
    contactPhone: (0, _model.attr)('string'),
    stateProvince: (0, _model.attr)('string'),
    zipCode: (0, _model.attr)('string'),
    street: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    isManned: (0, _model.attr)('boolean'),
    companyName: (0, _model.attr)('string'),
    fuel: (0, _model.attr)('string'),
    distanceToShore: (0, _model.attr)('string'),
    area: (0, _model.attr)('string'),
    radioFrequency: (0, _model.attr)('string'),
    block: (0, _model.attr)('string'),
    landmarkType: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    comment: (0, _model.attr)('string'),
    imageSrc: (0, _model.attr)('string'),

    /**
     * Is the location enabled and visible on the map
     */
    enabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Indicates if the user can update or delete the location
     */
    canAlter: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Indicates that the location is visible to everyone in the organization.
     * Only users with the manage location permission can create locations that
     * are organizationWide
     */
    orgGlobal: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Is the location shared from another SkyRouter account (organization)?
     */
    accountShare: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates if the location is owned by a sub-user
     */
    subuserOwned: (0, _model.attr)('boolean')
  });

  var _default = model;
  _exports.default = _default;
});