define("sky-router-3/utils/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasPermission;

  /**
   * Returns a boolean value that indicates if the user has the permissions
   * supplied to it or not. By default it will return true if at least one
   * item from permissions is present in userPermissions.
   * @param  {Array<string>}  userPermissions The permissions the user has
   * @param  {Array<string>}  permissions     The permissions you want to check against.
   * @param  {Boolean}  allRequired     Indicates that unlike normal behavior where
   *                                    at least 1 permission has to be present in
   *                                    userPermissions all `permissions` must be
   *                                    present
   * @return {Boolean}                 True if the conditions are satisfied.
   */
  function hasPermission(userPermissions, permissions, allRequired) {
    if (!userPermissions || !permissions) {
      return false;
    }

    if (!Array.isArray(permissions)) {
      return userPermissions.includes(permissions);
    }

    if (allRequired) {
      var found = true;

      for (var i = 0; i < permissions.length; i++) {
        found &= userPermissions.includes(permissions[i]);
      }

      return found;
    } else {
      for (var _i = 0; _i < permissions.length; _i++) {
        if (userPermissions.includes(permissions[_i])) {
          return true;
        }
      }

      return false;
    }
  }
});