define("sky-router-3/pods/portal/manage/alerts/log/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Bound query params
     * @type {Array}
     */
    queryParams: ['alertId'],

    /**
     * The alert definition that this log is representing
     * @type {[type]}
     */
    alertId: null,

    /**
     * Indicates if the log view is loading records
     * @type {Boolean}
     */
    loadingRecords: false,

    /**
     * Sort the model logs so that they are shown from newest to oldest
     * @type {Array}
     */
    logsSortBy: ['id:desc'],
    logsSorted: Ember.computed.sort('model.logs', 'logsSortBy'),
    actions: {
      showLogBody: function showLogBody(log) {
        alert(log.get('body'));
      }
    }
  });

  _exports.default = _default;
});