define("sky-router-3/pods/components/portal/manage/device-parameters/controls/nal-emergency-time-based-reporting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,
    continuousSetting: null,
    secondsSetting: null,

    /**
     * Controls the NPR grouping
     */
    timeReportingSelected: function () {
      return this.get('timeReportingOptions').findBy('value', this.get('secondsSetting.value'));
    }.property('secondsSetting.value'),
    timeReportingOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '2700',
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 4
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 8
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHours', {
          count: 24
        })
      }];
    }.property(),
    actions: {
      timeReportingChange: function timeReportingChange(selected) {
        this.set('secondsSetting.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('secondsSetting').rollbackAttributes();
          this.get('continuousSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});