define("sky-router-3/models/burst-service-message-schedule-repeat", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = buildValidations({
    count: validator('presence', true),
    intervalSeconds: validator('number', {
      gt: 30,
      allowString: true,
      message: 'Duration must be greater than 30 seconds' //change later
      //disabled: not (useTimeNowRepeat)

    })
  });

  var _default = _fragment.default.extend(VALIDATIONS, {
    /**
     * Servie Code (this is the name register with Iridium SPNet)
     */
    count: (0, _model.attr)('number'),

    /**
     * Description
     */
    intervalSeconds: (0, _model.attr)('number')
  });

  _exports.default = _default;
});