define("sky-router-3/pods/components/portal/track/map/overlays/noaa-airep-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,
    typeOptions: function () {
      var intl = this.get('intl');
      return [{
        value: 'all',
        label: intl.t('overlay.airep.all')
      }, {
        value: 'turb',
        label: intl.t('overlay.airep.turbulence')
      }, {
        value: 'icing',
        label: intl.t('overlay.airep.ice')
      }, {
        value: 'wx',
        label: 'WX'
      }];
    }.property(),
    typeSelected: function () {
      var opts = this.get('typeOptions');
      return opts.findBy('value', this.get('settings.generic.type')) || opts[0];
    }.property('settings.generic.type'),
    aTypeOptions: [{
      value: null,
      label: 'AIREPs & PIREPs'
    }, {
      value: 'AIREP',
      label: 'AIREPs'
    }, {
      value: 'PIREP',
      label: 'PIREPs'
    }],
    aTypeSelected: function () {
      var opts = this.get('aTypeOptions');
      return opts.findBy('value', this.get('settings.generic.aType')) || opts[0];
    }.property('settings.generic.aType'),

    /**
     * age=### - Sets time range/age in hours for returned AIREPs/PIREPs. The default is no 1.5 hours.
     */
    age: Ember.computed('settings.generic.age', {
      get: function get() {
        return this.get('settings.generic.age') || 1.5;
      },
      set: function set(key, value) {
        this.set('settings.generic.age', value);
        return value;
      }
    }),
    actions: {
      setTypeSelection: function setTypeSelection(sel) {
        this.set('settings.generic.type', sel.value);
      },
      setaTypeSelected: function setaTypeSelected(sel) {
        this.set('settings.generic.aType', sel.value);
      }
    }
  });

  _exports.default = _default;
});