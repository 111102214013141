define("sky-router-3/utils/computed-bool-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedBoolString;

  /**
   * Creates a computed function that works off a property that is either "1" or "0".
   * It turns it into a true boolean. Both get and set work.
   * @param  {String} property the property to observe
   * @return {Ember.computed}
   */
  function computedBoolString(property) {
    return Ember.computed(property, {
      get: function get() {
        return this.get(property) === "1";
      },
      set: function set(prop, value) {
        this.set(property, value ? "1" : "0");
        return value;
      }
    });
  }
});