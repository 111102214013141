define("sky-router-3/pods/portal/operations/incident-report/index/controller", ["exports", "sky-router-3/utils/filter-by-in-array", "sky-router-3/utils/computed-date", "sky-router-3/pods/components/tz-picker/component"], function (_exports, _filterByInArray, _computedDate, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['skip', 'take', 'sortDesc', 'filterAssetIds', 'oldestDate', 'soonestDate', 'dateRange'],
    sortDesc: true,
    skip: 0,
    take: 30,
    takeSelections: [30, 60, 100],

    /**
    * The current page of the report results
    */
    currentPage: 1,

    /**
     * The incident item that is being edited
     * @type {sky-router-3/models/asset-position}
     */
    editedReport: null,

    /**
    * The value of the edited record acknowledgment in case of cancel
    */
    revertAck: null,

    /**
    * The value of the edited record note in case of cancel
    */
    revertNote: null,

    /**
    * The index of the first record of the page
    */
    startRecordIndex: Ember.computed('model.incidentReports.meta', function () {
      var skip = this.get('skip');
      return skip + 1;
    }),

    /**
    * The index of the last record of the page
    */
    endRecordIndex: Ember.computed('model.incidentReports.meta', function () {
      var meta = this.model.incidentReports.get('meta');
      var skip = this.get('skip');
      return skip + meta.CountReturned;
    }),

    /**
     * Creates the applicable pages list for the full result list
     */
    pages: Ember.computed('model.incidentReports.meta', function () {
      var meta = this.model.incidentReports.get('meta');
      var pages = [];
      var take = this.get('take');
      var count = Math.ceil(meta.CountPossible / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }),

    /**
     * Powers the assets filter
     * @type {Array}
     */
    filterAssetIds: [],
    filterAssetsSelected: (0, _filterByInArray.default)('model.assets', 'id', 'filterAssetIds'),

    /**
     * Powers the oldestDate date filter
     */
    oldestDate: null,
    oldestDateInstance: (0, _computedDate.default)('oldestDate'),

    /**
     * Powers the newest date filter
     */
    soonestDate: null,
    soonestDateInstance: (0, _computedDate.default)('soonestDate'),

    /**
     * Powers the date range selector
     */
    dateRangeHours: 24,
    dateRangeCustom: Ember.computed.equal('dateRangeSelected.hours', -1),
    dateRangeSelected: Ember.computed('dateRangeHours', function () {
      return this.dateRangeOptions.findBy('hours', this.dateRangeHours);
    }),
    dateRangeOptions: Ember.computed(function () {
      return [{
        hours: 24,
        label: this.intl.t('parameter.misc.countHours', {
          count: 24
        })
      }, {
        hours: 48,
        label: this.intl.t('parameter.misc.countHours', {
          count: 48
        })
      }, {
        hours: 168,
        label: this.intl.t('parameter.misc.countWeek')
      }, {
        hours: 336,
        label: this.intl.t('parameter.misc.countWeeks', {
          count: 2
        })
      }, {
        hours: 672,
        label: this.intl.t('parameter.misc.countWeeks', {
          count: 4
        })
      }, {
        hours: -1,
        label: this.intl.t('parameter.misc.custom')
      }];
    }),

    /**
     * Powers the date display filter
     */
    tz: _component.timeZones.LOCAL,
    tzOffset: null,
    filterSet: function () {
      return this.filterAssetIds.length || this.dateRangeHours != 24;
    }.property('filterAssetIds', 'dateRangeHours'),
    actions: {
      filterDateRangeChanged: function filterDateRangeChanged(selection) {
        this.set('dateRangeHours', selection.hours);
        this.set('skip', 0);
      },
      filterAssetsChanged: function filterAssetsChanged(selection) {
        this.set('filterAssetIds', selection.mapBy('id'));
        this.set('skip', 0);
      },
      filterStatusChanged: function filterStatusChanged(selection) {
        this.set('filterStatus', selection ? selection.get('id') : null);
        this.set('skip', 0);
      },
      changeSort: function changeSort() {
        this.toggleProperty('sortDesc');
      },
      resetFilter: function resetFilter() {
        this.set('filterAssetIds', []);
        this.set('soonestDate', null);
        this.set('oldestDate', null);
        this.set('dateRangeHours', 24);
        this.set('skip', 0);
      },
      updateDate: function updateDate(property, date) {
        this.set(property, date.toISOString());
      },
      countChanged: function countChanged(val) {
        this.set('skip', 0);
        this.set('take', val);
      },
      edit: function edit(report) {
        this.set('editedReport', report);
        this.set('revertAck', report.acknowledged);
        this.set('revertNote', report.note);
      },
      cancel: function cancel(report) {
        var ack = this.get('revertAck');
        var note = this.get('revertNote');
        report.acknowledged = ack;
        report.note = note;
        this.set('editedReport', null);
      },
      save: function save(report) {
        report.save();
        this.set('editedReport', null);
      },
      ontzpick: function ontzpick(selection) {
        this.set('tz', selection.id);
        this.set('tzOffset', selection.offset);
      },
      refresh: function refresh() {
        var editedReport = this.get('editedReport');
        this.set('currentPage', 1);
        this.set('skip', 0);

        if (editedReport != null) {
          this.send('cancel', editedReport);
        }

        this.send('refreshModel');
      },
      pageCountChanged: function pageCountChanged(selection) {
        this.set('take', selection);
        this.set('currentPage', 1);
      },
      pageSelected: function pageSelected(page) {
        var take = this.get('take');
        var currentPage = this.get('currentPage');

        if (page !== currentPage) {
          this.set('currentPage', page);
        }

        this.set('skip', (page - 1) * take);
      }
    }
  });

  _exports.default = _default;
});