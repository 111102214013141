define("sky-router-3/pods/portal/track/emergency/route", ["exports", "sky-router-3/models/asset-position-type", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _assetPositionType, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Required permissions in order to access this route.
     * Part of the `PermissionRequiredMixin`
     * @type {Array}
     */
    permissions: ['device.cancelEmergency'],
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        properties: {
          notes: null,
          contactTime: new Date()
        },
        asset: this.store.findRecord('asset', params.asset_id),
        positionTypeAck: this.store.findRecord('asset-position-type', _assetPositionType.POSITION_TYPES.QuickPositionAcknowledge),
        position: this.store.query('asset-position', {
          count: 1,
          imeis: [params.asset_id]
        }).then(function (p) {
          return p.firstObject;
        })
      });
    },
    // Don't let us transition if we are not in an emergency event
    afterModel: function afterModel(model) {
      var _this = this;

      return model.position.get('type').then(function (type) {
        if (!type.get('isEmergency')) {
          _this.transitionTo('portal.track');
        }
      });
    },
    cancelAssetEmergency: function cancelAssetEmergency(asset) {
      var _this2 = this;

      return asset.product.then(function (prod) {
        if (!prod.supportCancelEmergency) {
          return;
        }

        return _this2.get('api').sendCancelEmergency(asset.get('id')).then(function (result) {
          if (result.SendResult) {
            // 0 = success
            throw new Error();
          }

          _this2.notifications.info(_this2.intl.t('track.cancelEmergencyNotice'));
        });
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.track');
      },
      save: function save() {
        var _this3 = this;

        var model = this.modelFor(this.routeName);
        var position = this.store.createRecord('asset-position', {
          asset: model.asset,
          type: model.positionTypeAck,
          latitudeMilliarcSeconds: model.position.get('latitudeMilliarcSeconds'),
          longitudeMilliarcSeconds: model.position.get('longitudeMilliarcSeconds'),
          altitudeCentimeters: model.position.get('altitudeCentimeters'),
          utc: new Date(),
          description: model.positionTypeAck.get('name'),
          speedCms: model.position.get('speedCms'),
          headingDegrees: model.position.get('headingDegrees'),
          properties: {
            contact_time: model.properties.contactTime,
            notes: model.properties.notes
          }
        });
        this.send('blockApp', true);
        position.save().then(function () {
          _this3.cancelAssetEmergency(model.asset);

          _this3.transitionTo('portal.track');
        }).catch(function () {
          _this3.notifications.error(_this3.intl.t('misc.failedOperation'));
        }).finally(function () {
          return _this3.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});