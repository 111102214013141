define("sky-router-3/pods/portal/settings/units/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This route contains all the users unit settings for the whole application.
   * Unit setting examples are speed, altitude, and velocity.
   *
   * The users information is recovered in the model hook and set on the controller.
   */
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.units');
    },

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        latLongOption: this.store.findAll('unitLatLong', {
          reload: true
        }),
        speedOption: this.store.findAll('unitSpeed', {
          reload: true
        }),
        distanceOption: this.store.findAll('unitDistance', {
          reload: true
        }),
        altitudeOption: this.store.findAll('unitAltitude', {
          reload: true
        }),
        volumeOption: this.store.findAll('unitVolume', {
          reload: true
        }),
        user: this.get('sessionAccount.user'),
        sessionAccount: this.get('sessionAccount')
      });
    },
    actions: {
      /**
       * Loads the stripped model properties back into the model
       * and then triggers the model to be saved against the
       * database.
       */
      willTransition: function willTransition() {
        var user = this.modelFor(this.routeName).user;

        if (user.get('hasDirtyAttributes') || user.hasDirtyRelationships()) {
          this.modelFor(this.routeName).user.save();
        }
      }
    }
  });

  _exports.default = _default;
});