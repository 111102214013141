define("sky-router-3/pods/components/display-speed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wcJUvt/A",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,0,[\"content\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"speed\"]}",
    "moduleName": "sky-router-3/pods/components/display-speed/template.hbs"
  });

  _exports.default = _default;
});