define("sky-router-3/pods/components/feature-lock/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLoTx27d",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],null,[[\"placement\",\"interactive\",\"lazyRender\"],[[35,4],true,true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"panel panel-warning panel-popover\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[1,[34,1]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"panel-footer\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-phone\"],[12],[13],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-envelope\"],[12],[13],[2,\"\\n            \"],[10,\"a\"],[14,6,\"https://blueskynetwork.com/contact-us\"],[14,\"target\",\"_BLANK\"],[12],[1,[30,[36,3],[\"features.contact\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"body\",\"title\",\"if\",\"t\",\"placement\",\"attach-popover\"]}",
    "moduleName": "sky-router-3/pods/components/feature-lock/template.hbs"
  });

  _exports.default = _default;
});