define("sky-router-3/pods/components/display-svg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['embedded-svg'],
    ajax: Ember.inject.service(),
    url: null,
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this._url != this.url) {
        this._url = this.url; // prevent-unecessary-re-render

        this.ajax.request(this.url, {
          dataType: 'text'
        }).then(function (svg) {
          if (!_this.isDestroying && !_this.isDestroyed && _this.element) {
            _this.element.innerHTML = svg;
          }
        });
      }
    }
  });

  _exports.default = _default;
});