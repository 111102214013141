define("sky-router-3/pods/components/portal/track/location-list/component", ["exports", "sky-router-3/utils/filter-by-in-array"], function (_exports, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component contains the logic so filtering, searching and sorting the location list
   *
   *
   * @event onFilteredTypesChanged - triggered when the filter on location type changes, sends an array containing the id's to show
   * @event onSortChanged - triggered when sort property or sort direction changes, sends two args {currentSortProperty}, {currentSortDesc}
   * @event onSearchChanged - triggered when the search value has changed, sends a string containing the search term. null for no search.
   *
   */
  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['track-window'],

    /**
     * This action fires anytime that an event happens inside this component.
     * Actions are grouped for brevity and defined by a first parameter 'action'.
     *
     * @event 'onWindowAction'
     * @param {string} action - the name of the action that was fired
     *
     *      sort - when the sorting has changed
     *      filter - when the filtering parameters changes
     *      search - when the search value changes for filter purposes
     *      delete - When a location is to be deleted
     *      click - When a location item has been clicked in the list
     *
     * @param {object} options object structured the following way per action.
     *
     *      sort - { {string}property, {boolean}desc }
     *      filter - { {string[]}types }
     *      search - { {string}searchValue }
     *      delete - { {object}location }
     *      click -  { {object}location }
     */

    /**
     * Contains state information for the list that is stored inside
     * local storage.
     */
    config: null,

    /**
     * Incoming list of locations that have already been filtered
     */
    locations: null,

    /**
     * How the location list is sorted by.
     */
    locationsSortedBy: function () {
      return [this.get('config.sortProperty') + (this.get('config.sortDesc') ? ':desc' : ':asc')];
    }.property('config.sortProperty', 'config.sortDesc'),

    /**
     * Sorts the incoming locations array into the way it will be shown in the UI.
     */
    locationsSorted: Ember.computed.sort('locations', 'locationsSortedBy'),

    /**
     * Gets a displayable subset of locations that the UI will show.
     * @return {sky-router-3/models/location[]} Subset array of locations
     */
    locationsSubset: function () {
      return this.get('locationsSorted').slice(0, this.get('locationsCount'));
    }.property('locationsCount', 'locationsSorted.[]'),

    /**
     * Number of items to show in the UI.
     * @type {Number}
     * @default 50
     */
    locationsCount: 50,

    /**
     * Sets the `locationsCount` back to the default value of 50.
     * This is usually called after a sort or filter reset's the list.
     */
    resetDisplayedLocationCount: function resetDisplayedLocationCount() {
      this.set('locationsCount', 50);
    },

    /**
     * An array of selected types in the filter dropdown for location types
     * @return {sky-router-3/models/location-type[]}
     */
    selectedFilterTypes: (0, _filterByInArray.default)('types', 'id', 'config.filterOnType'),

    /**
     * Returns a boolean that indicates if filters are not using their defaults
     * @return {Boolean}
     */
    filtersSet: Ember.computed('config.filterOnType.length', 'config.{filterAll,filterShared,filterSubuser}', function () {
      return this.config.filterOnType.length || !this.config.filterShared || !this.config.filterSubuser || !this.config.filterAll;
    }),
    actions: {
      resetFilters: function resetFilters() {
        this.resetDisplayedLocationCount();
        this.onWindowAction('filter', {
          types: []
        });
        this.config.set('filterShared', true);
        this.config.set('filterSubuser', true);
        this.config.set('filterAll', true);
      },
      toggleCurrentSortDesc: function toggleCurrentSortDesc() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: !this.get('config.sortDesc')
        });
      },
      toggleSearch: function toggleSearch() {
        this.toggleProperty('config.showSearch');
      },
      changedSortProperty: function changedSortProperty(value) {
        this.resetDisplayedLocationCount();
        this.onWindowAction('sort', {
          property: value,
          desc: this.get('config.sortDesc')
        });
      },
      searchChanged: function searchChanged(searchValue) {
        this.resetDisplayedLocationCount();
        this.onWindowAction('search', {
          searchValue: searchValue
        });
      },
      onFilterTypeChange: function onFilterTypeChange(values) {
        this.resetDisplayedLocationCount();
        this.onWindowAction('filter', {
          types: values.mapBy('id')
        });
      },
      onDeleteLocation: function onDeleteLocation(location) {
        this.onWindowAction('delete', {
          location: location
        });
      },
      onClickLocation: function onClickLocation(location) {
        this.onWindowAction('click', {
          location: location
        });
      },
      onLocationScrollAtBottom: function onLocationScrollAtBottom() {
        this.incrementProperty('locationsCount', 50);
      }
    }
  });

  _exports.default = _default;
});