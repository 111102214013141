define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-11/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.value1': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.model.typeAttribute.value1ValidationMaxNumber'),
      gte: Ember.computed.alias('model.model.typeAttribute.value1ValidationMinNumber')
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model.relationalOperator', 2);
    }
  });

  _exports.default = _default;
});