define("sky-router-3/pods/components/input-date-range/component", ["exports", "moment", "sky-router-3/utils/constants", "sky-router-3/utils/parse-date", "sky-router-3/utils/convert-date-timezone", "ember-local-storage"], function (_exports, _moment, _constants, _parseDate, _convertDateTimezone, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component presents the user with a date input field and gives them the
   * ability to specify if the day is in UTC or Local and if it should be displayed in
   * ISO, standard, or readable format.
   *
   * @example {{input-date startDate=(readonly date) endDate onUpdateStartDate=(action (mut date)) onUpdateEndDate=(action (mut date)) }}
   */
  var _default = Ember.Component.extend({
    classNames: ['input-group-flex', 'input-date-range'],
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    preferences: (0, _emberLocalStorage.storageFor)('user-preferences'),

    /**
     * Is the input disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Action placeholder of when a date is changed
     * @argument {Date}
     */
    onUpdateStartDate: function onUpdateStartDate()
    /* native js date */
    {},
    onUpdateEndDate: function onUpdateEndDate()
    /* native js date */
    {},

    /**
     * The passed in date rendered into the component
     * @type {Date}
     */
    startDate: null,
    endDate: null,

    /**
     * Is the picke in a 24 hour time format or with AM/PM?
     * @type {Boolean}
     */
    timeFormat24Hour: Ember.computed.bool('sessionAccount.user.timeFormat'),

    /**
     * Indicates if the altFormat is shown or not
     * - readable (Fri July 22nd 2018)
     * - standard (2018-10-22 or similar)
     * - iso (ISO_8601)
     * @type {Number}
     */
    displayFormat: Ember.computed('preferences.dateInputDisplayFormat', function () {
      return this.preferences.get('dateInputDisplayFormat') || 'readable';
    }),

    /**
     * Should time be displayed or not.
     * @type {Boolean}
     */
    displayTime: true,

    /**
     * Sets the name used for the radio buttons to be unique
     */
    init: function init() {
      this.set('_formatId', Ember.guidFor(this));

      this._super.apply(this, arguments);
    },

    /**
     * All possible timezones, powers selector dropdown
     */
    timeZones: _constants.timeZones,

    /**
     * The selected timezone that the picker runs off of.
     * By default this is set to the users timezone.
     * @type {[type]}
     */
    timeZone: Ember.computed.reads('_userTimeZone'),

    /**
     * Action that fires when a timezone is selected
     */
    timeZoneSelected: function timeZoneSelected(timeZone) {
      this.set('timeZone', timeZone);
    },

    /**
     * Static holders of the default timezones.
     */
    _utcTimeZone: _constants.timeZoneUtc,
    _userTimeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', function () {
      return _constants.timeZones.findBy('offsetMinutes', this.sessionAccount.user.get('timeZoneMinutes'));
    }),

    /**
     * radio input name field that must be unique to the component
     * @return {String}
     */
    _formatId: null,

    /**
     * The instance of the flatpickr component that is necessary in order to
     * trigger a date update on change of offset type.
     */
    _flatPickrInstanceStart: null,
    _flatPickrInstanceEnd: null,

    /**
     * The working date inside this component. This allows us to translate to and from UTC.
     * @return {Date}
     */
    _startDate: Ember.computed('startDate', 'timeZone.offsetMinutes', function () {
      if (!this.startDate || !this.timeZone) {
        return null;
      }

      return (0, _convertDateTimezone.default)(this.startDate, this.timeZone.offsetMinutes, false);
    }),
    _endDate: Ember.computed('endDate', 'timeZone.offsetMinutes', function () {
      if (!this.endDate || !this.timeZone) {
        return null;
      }

      return (0, _convertDateTimezone.default)(this.endDate, this.timeZone.offsetMinutes, false);
    }),

    /**
     * Picker can't handle es-es or pt-br but can handle es & pt
     * @return {String}
     */
    _locale: Ember.computed('intl.primaryLocale', function () {
      return this.intl.primaryLocale.substring(0, 2);
    }),

    /**
     * Gets the date format string if necessary
     * @return {String}
     */
    _getTimeFormat: function _getTimeFormat() {
      if (this.displayTime) {
        return this.timeFormat24Hour ? 'H:mm' : 'h:mm A';
      }

      return '';
    },

    /**
     * Formats a date for display in the picker
     * @param  {Date} date Date to be displayed
     * @return {String}      String showing the date
     */
    _formatDate: function _formatDate(date) {
      switch (this.displayFormat) {
        case 'readable':
          return (0, _moment.default)(date).format("ddd LL ".concat(this._getTimeFormat()));

        case 'standard':
          return (0, _moment.default)(date).format("l ".concat(this._getTimeFormat()));

        default:
          // iso also
          return date.toISOString(true);
      }
    },
    actions: {
      onready: function onready(picker, dates, dateStrings, instance) {
        if (picker == 'start') {
          this._flatPickrInstanceStart = instance;
        } else {
          this._flatPickrInstanceEnd = instance;
        }

        instance.set('parseDate', Ember.run.bind(this, _parseDate.default, this.intl.locale));
        instance.set('formatDate', Ember.run.bind(this, this._formatDate));
      },
      onchange: function onchange(picker, dates
      /*, dateString, instance*/
      ) {
        var date = dates[0] || new Date(); // Don't let there not be a date at all

        var converted = (0, _convertDateTimezone.default)(date, this.timeZone.offsetMinutes, true);

        if (picker == 'start') {
          this.onUpdateStartDate(converted);

          if (converted > this.endDate) {
            this.onUpdateEndDate(converted);
          }
        } else {
          this.onUpdateEndDate(converted);

          if (converted < this.startDate) {
            this.onUpdateStartDate(converted);
          }
        }
      },
      updateDisplayFormat: function updateDisplayFormat() {
        if (this._flatPickrInstanceStart) {
          this._flatPickrInstanceStart.setDate(this._startDate);
        }

        if (this._flatPickrInstanceEnd) {
          this._flatPickrInstanceEnd.setDate(this._endDate);
        }

        this.preferences.set('dateInputDisplayFormat', this.displayFormat);
      }
    }
  });

  _exports.default = _default;
});