define("sky-router-3/pods/public/login/reset/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    username: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('email', {
      allowEmpty: true
    })
  }, {
    dependentKeys: ['model.intl.locale.firstObject']
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * Reference the HTTP Api service
     */
    api: Ember.inject.service(),

    /**
     * locale has to be consumed in order for depndent key in VALIDATIONS
     * to activate properly. This will cause validations to change based on locale.
     */
    intl: Ember.inject.service(),
    init: function init() {
      this.get('intl.locale.firstObject');

      this._super.apply(this, arguments);
    },

    /**
     * Form property
     */
    username: '',

    /**
     * Form property
     */
    email: '',

    /**
     * Show or hide the reset success block
     */
    showResetSuccess: false,

    /**
     * Show or hide the reset failure block
     */
    showResetFail: false,

    /**
     * Indicates that server is processing and the form should be blocked
     */
    isLoading: false,

    /**
     * This will reset all the controller properties back to their defaults
     * It is called by the route when the user navigates away from this page
     *
     */
    reset: function reset() {
      this.set('username', '');
      this.set('email', '');
      this.set('showResetSuccess', false);
      this.set('showResetFail', false);
      this.set('isLoading', false);
    },

    /**
     * Block the form because the user has not entered the correct information
     */
    blockSubmit: Ember.computed('username', 'email', function () {
      return !this.username || !this.email || this.validations.isInvalid;
    }),
    actions: {
      /**
       * Submits the password reset request
       */
      submitResetPassword: function submitResetPassword() {
        var _this = this;

        this.set('isLoading', true);
        this.get('api').userResetPassword(this.get('username').trim(), this.get('email').trim()).then(function (result) {
          if (result.BooleanResult) {
            _this.set('showResetFail', false);

            _this.set('showResetSuccess', true);
          } else {
            _this.set('showResetFail', true);
          }
        }).catch(function () {
          return _this.set('showResetFail', true);
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});