define("sky-router-3/pods/components/portal/operations/trips/waypoint-start-end/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    disabled: false,
    start: null,
    end: null,
    actions: {
      autoCompleteWaypoint: function autoCompleteWaypoint(waypoint, location) {
        waypoint.set('latitudeMilliarcSeconds', location.LatitudeMilliarcSeconds);
        waypoint.set('longitudeMilliarcSeconds', location.LongitudeMilliarcSeconds);
        waypoint.set('name', location.Name);
        waypoint.set('description', location.Description);
      }
    }
  });

  _exports.default = _default;
});