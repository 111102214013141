define("sky-router-3/pods/components/language-switcher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is governs language changes within the application
   * @example {{language-switcher language='en-us' onLanguageChange=(action (mut currentLanguage))}}
   */
  var _default = Ember.Component.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Defines all the available languages in the application
     */
    languages: Ember.computed('language', 'intl.localesAvailable', function () {
      var _this = this;

      return this.intl.localesAvailable.map(function (item) {
        return {
          id: item,
          label: _this.intl.t("locales.".concat(item))
        };
      });
    }),

    /**
     * Contains the currently selected language id
     * @property
     */
    language: null,

    /**
     * Holds the currently selected language for use in the dropdown
     */
    currentSelection: Ember.computed('language', 'languages.[]', function () {
      if (this.language) {
        return this.languages.findBy('id', this.language.toLowerCase());
      }

      return null;
    }),
    actions: {
      changeLanguage: function changeLanguage(selected) {
        this.onLanguageChange(selected.id);
      }
    }
  });

  _exports.default = _default;
});