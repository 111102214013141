define("sky-router-3/pods/portal/manage/location/import/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    file: (0, _emberCpValidations.validator)('file', {
      allowEmpty: false,
      inputId: 'csv-file-upload',
      sizeLimit: 100000,
      allowTypes: ['text/comma-separated-values', 'text/csv', 'application/csv', 'application/excel', 'application/vnd.ms-excel', 'application/vnd.msexcel', 'text/anytext']
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    file: null,
    actions: {
      upload: function upload() {
        var _this = this;

        var input = document.getElementById('csv-file-upload');

        if (input.files && input.files.length > 0) {
          this.set('blockUploadButton', true);
          this.get('api').locationBulkUpload(input.files[0]).then(function () {
            _this.set('blockUploadButton', false);

            _this.notifications.success(_this.intl.t('locations.importLocationSuccess'));
          }).catch(function (error) {
            _this.set('blockUploadButton', false);

            if (error.status === 400) {
              if (error.payload.Result === 1) {
                _this.notifications.error(_this.intl.t('locations.importLocationFailParse', {
                  row: error.payload.Line
                }));
              } else if (error.payload.Result === 2) {
                _this.notifications.error(_this.intl.t('locations.importLocationFailValidation'));
              } else {
                _this.notifications.error(_this.intl.t('locations.importLocationFail'));
              }
            } else if (error.status === 413) {
              _this.notifications.warning(_this.intl.t('locations.importLocationFailToManyRows', {
                count: 500
              }));
            } else {
              _this.notifications.error(_this.intl.t('locations.importLocationFail'));
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});