define("sky-router-3/pods/portal/report/report-generator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JPINA/ov",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"report.reportGenerator.title\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"report.reportGenerator.help\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n        \"],[8,\"active-link\",[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"link-to\",[],[[\"@route\"],[\"portal.report.report-generator.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"report.reportGenerator.reportsTitle\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"active-link\",[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"link-to\",[],[[\"@route\"],[\"portal.report.report-generator.setup\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"report.reportGenerator.setupNewReport\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"active-link\",[],[[],[]],[[\"default\"],[{\"statements\":[[8,\"link-to\",[],[[\"@route\"],[\"portal.report.report-generator.history\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"report.reportGenerator.completedReports\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/report/report-generator/template.hbs"
  });

  _exports.default = _default;
});