define("sky-router-3/pods/components/portal/track/map/overlays/user-contacts/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/constants"], function (_exports, _googleObjectMapping, _computedJsonObject, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays the location of a users communicate contacts
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getFeatureInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout()
    /*dataObject, mouseEvent*/
    {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
    },

    /**
     * Opens the Smithsonian Institute Global Volcanism Program link on the
     * given volcano.
     */
    _click: function _click(dataObject, mouseEvent) {
      this.get('router').transitionTo('portal.manage.contacts.edit', mouseEvent.feature.getProperty('ContactID'));
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getFeatureInfo: function _getFeatureInfo(feature) {
      var obj = {};
      feature.forEachProperty(function (value, name) {
        return obj[name] = value;
      });
      obj['TimeZoneDisplay'] = _constants.timeZones.findBy('id', parseInt(obj.TimeZone)).label;
      return obj;
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function loadGeoJson() {
      var _this = this;

      Ember.run.join(function () {
        var url = _this.get('dataObject.url');

        if (!url) {
          return;
        }

        _this.get('api').request(url).then(function (x) {
          var obj = _this.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    }
  });

  _exports.default = _default;
});