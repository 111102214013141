define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/tracking-advanced/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'autoResumeDistance.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.autoResumeDistance.validationMaxNumber'),
      gte: Ember.computed.alias('model.autoResumeDistance.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    autoResumeDistance: null,

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    autoResumeDistanceSelected: function () {
      var options = this.get('autoResumeDistanceOptions');
      var option = options.findBy('value', this.get('autoResumeDistance.value'));

      if (!option) {
        option = options.findBy('value', '2');
      }

      return option;
    }.property('autoResumeDistance.value'),

    /**
    * The options for the resume distance dropdown
    */
    autoResumeDistanceOptions: function () {
      return [{
        value: '2',
        label: '2km'
      }, {
        value: '3',
        label: '3km'
      }, {
        value: '5',
        label: '5km'
      }, {
        value: '10',
        label: '10km'
      }];
    }.property(),
    actions: {
      autoResumeDistanceChange: function autoResumeDistanceChange(selected) {
        this.set('autoResumeDistance.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('autoResumeDistance').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});