define("sky-router-3/pods/portal/manage/data-feeds-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fjl3ug9p",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n\"],[1,[30,[36,2],[\"misc.loadingPlaceholder\"],null]],[1,[34,3]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"t\",\"loading-icon\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/data-feeds-loading/template.hbs"
  });

  _exports.default = _default;
});