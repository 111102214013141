define("sky-router-3/pods/portal/settings/misc/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.misc');
    },
    actions: {
      resetLocalApp: function resetLocalApp() {
        if (confirm(this.intl.t('misc.areYouSureConfirm'))) {
          // clear the storage that is used by the track state and locale settings
          window.localStorage.clear(); // force the page to reload without looking at the cache.
          // Supposedly this works

          window.location.reload(true);
        }
      }
    }
  });

  _exports.default = _default;
});