define("sky-router-3/models/compound-alert-revision-output", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    type: (0, _emberCpValidations.validator)('presence', true),
    properties: (0, _emberCpValidations.validator)('belongs-to', true)
  });

  var _default = _model.default.extend(VALIDATIONS, {
    type: (0, _model.belongsTo)('compound-alert-output-type'),
    properties: (0, _attributes.fragment)('compound-alert-revision-output-properties', {
      polymorphic: true,
      typeKey: '$type'
    }),
    // This is a helper method for the UI
    // TODO: SHOULD THIS BE HERE?
    eventList: Ember.computed('properties.showAgl', 'properties.showAssetInfo', 'properties.showMapInfo', 'properties.showPositionInfo', 'properties.showMapPicture', 'properties.showTimeInfo', 'properties.showGeocodedLocation', 'properties.showTripInfo', function () {
      var events = [];
      var properties = this.get('properties');

      if (properties.showAgl) {
        events.push('compound-alerts.messageSections.agl');
      }

      if (properties.showAssetInfo) {
        events.push('compound-alerts.messageSections.asset');
      }

      if (properties.showGeocodedLocation) {
        events.push('compound-alerts.messageSections.geoLocation');
      }

      if (properties.showMapInfo) {
        events.push('compound-alerts.messageSections.mapInfo');
      }

      if (properties.showMapPicture) {
        events.push('compound-alerts.messageSections.mapPic');
      }

      if (properties.showPositionInfo) {
        events.push('compound-alerts.messageSections.position');
      }

      if (properties.showTimeInfo) {
        events.push('compound-alerts.messageSections.time');
      }

      if (properties.showTripInfo) {
        events.push('compound-alerts.messageSections.trip');
      }

      return events;
    })
  });

  _exports.default = _default;
});