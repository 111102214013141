define("sky-router-3/models/asset-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model describes the type of an asset. The type of an asset pertains to
   * the vehcile type. So for air type assets this will be a make/model of
   * aircraft. For land this will be a type of car.
   *
   * This is used in determining which icon to use for the asset on the track
   * page.
   */
  var model = _model.default.extend({
    /**
     * The category that this asset type belongs to, values are like air, land,
     * sea, other.
     */
    category: (0, _model.belongsTo)('asset-category', {
      async: true
    }),

    /**
     * Around three letter code for the type of vehicle. This is used in
     * conjunction with typeCode to determine the icon to use for the asset.
     */
    code: (0, _model.attr)('string'),

    /**
     * The name of the vehicle, ex. Learjet 35
     */
    name: (0, _model.attr)('string'),

    /**
     * The maker of the vehicle, ex. Canadair Bombardier
     */
    maker: (0, _model.attr)('string'),

    /**
     * This contains a single upper case char.
     * Valid values are:
     *     O - 'Other'
     *     T - 'Truck'
     *     H - 'Helicopter'
     *     A - 'Aircraft'
     *     C - 'Car'
     *     B - 'Boat'
     *
     * It is used to determine what type of vehicle it is for map display
     * purposes. It is used in conjunction with code.
     */
    typeCode: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});