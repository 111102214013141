define("sky-router-3/pods/components/dynamic-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EwUENyTj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"misc.loadingPlaceholder\"],null]],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,0,[\"serve\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"iframe\"],[14,0,\"form\"],[15,\"src\",[31,[[34,0,[\"serve\"]]]]],[15,\"onload\",[30,[36,1],[[32,0],\"templateLoaded\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"file\",\"action\",\"t\",\"loading-icon\",\"loaded\",\"unless\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/dynamic-form/template.hbs"
  });

  _exports.default = _default;
});