define("sky-router-3/pods/portal/track/altitude/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xQHIBX/+",
    "block": "{\"symbols\":[\"asset\"],\"statements\":[[10,\"section\"],[15,0,[31,[\"well track-page-form \",[30,[36,1],[[35,2],\"fullsize\"],null]]]],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n        \"],[6,[37,4],null,[[\"class\",\"action\"],[\"btn-link btn-inline\",[30,[36,3],[[32,0],\"toggleExpanded\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[\"fa \",[30,[36,1],[[35,2],\"fa-compress\",\"fa-expand\"],null]]]],[12],[13]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,5],[\"altitudeGraph.header\"],null]],[2,\" \"],[10,\"small\"],[12],[1,[30,[36,5],[\"altitudeGraph.headerSub\"],null]],[13],[2,\"\\n        \"],[6,[37,6],null,[[\"class\",\"route\"],[\"pull-right button-close\",\"portal.track\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,9],null,[[\"options\",\"selected\",\"searchField\",\"searchEnabled\",\"onChange\"],[[35,8,[\"assets\"]],[35,8,[\"asset\"]],\"name\",true,[30,[36,7],[\"selectAsset\"],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"asset\",\"isInactive\",\"class\",\"size\"],[[32,1],false,\"text-bottom\",\"20px\"]]]],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"registration\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"(\"],[1,[32,1,[\"registration\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"position-graph\",[],[[\"@asset\",\"@type\",\"@unit\"],[[34,8,[\"asset\"]],\"altitude\",[34,10,[\"user\",\"altitude\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon-asset\",\"if\",\"expanded\",\"action\",\"bs-button\",\"t\",\"link-to\",\"route-action\",\"model\",\"input-select\",\"sessionAccount\"]}",
    "moduleName": "sky-router-3/pods/portal/track/altitude/template.hbs"
  });

  _exports.default = _default;
});