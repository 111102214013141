define("sky-router-3/pods/portal/operations/flightplans/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.plan.asset': (0, _emberCpValidations.validator)('presence', true),
    'model.plan.startDate': (0, _emberCpValidations.validator)('presence', true),
    'model.plan.estimatedDeparture': (0, _emberCpValidations.validator)('format', {
      regex: /^[0-2][0-9]:[0-5][0-9]$/,
      allowBlank: true
    }),
    'model.plan.estimatedEnroute': (0, _emberCpValidations.validator)('format', {
      regex: /^[0-2][0-9]:[0-5][0-9]$/,
      allowBlank: true
    }),
    'model.plan.estimatedArrival': (0, _emberCpValidations.validator)('format', {
      regex: /^[0-2][0-9]:[0-5][0-9]$/,
      allowBlank: true
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    hasAlteredPlanAsset: function () {
      return !this.get('model.plan.isNew') && this.get('model.planAssetId') !== this.get('model.plan.asset.id');
    }.property('model.plan.isNew', 'model.planAssetId', 'model.plan.asset.id'),

    /**
     * Powers the input autocomplete filter
     * @type {String}
     */
    filterValue: '',
    filteredLocations: Ember.computed('model.locations.[]', 'filterValue', function () {
      var value = this.filterValue || '';
      return this.get('model.locations').filter(function (x) {
        return x.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
    }),
    actions: {
      setStartDate: function setStartDate(moment) {
        if (moment) {
          this.set('model.plan.startDate', moment.toDate());
        }
      }
    }
  });

  _exports.default = _default;
});