define("sky-router-3/models/global-traveler-message", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberCpValidations, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    title: (0, _emberCpValidations.validator)('presence', true),
    message: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(VALIDATIONS, {
    title: (0, _model.attr)(),
    message: (0, _model.attr)(),
    createdDateUtc: (0, _model.attr)('date'),
    recipients: (0, _attributes.fragmentArray)('global-traveler-message-recipient'),
    secondsToLive: (0, _model.attr)('number', {
      defaultValue: 86400
    }),
    isSent: (0, _model.attr)('boolean'),
    type: (0, _model.attr)('string', {
      defaultValue: 'info'
    }),
    typeDisplay: Ember.computed('type', function () {
      switch (this.type) {
        case 'checkin':
          return "Check In";

        case 'attention':
          return "Attention";

        case 'warning':
          return "Warning";

        default:
        case 'info':
          return "Info";
      }
    }),
    typeColor: Ember.computed('type', function () {
      switch (this.type) {
        case 'checkin':
          return "primary";

        case 'attention':
          return "danger";

        case 'warning':
          return "warning";

        default:
        case 'info':
          return "info";
      }
    })
  });

  _exports.default = _default;
});