define("sky-router-3/pods/components/display-date-relative/component", ["exports", "sky-router-3/utils/convert-date"], function (_exports, _convertDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: null,
    sessionAccount: Ember.inject.service(),
    date: null,

    /**
     * Relative date displayed to the user '3 minutes ago'
     * @return {String}
     */
    dateDisplay: Ember.computed('date', 'sessionAccount.user', function () {
      return (0, _convertDate.default)(this.date, this.sessionAccount.user, {
        fromNow: true
      });
    }),

    /**
     * A full date that is shown to the user in a hover popup
     * @return {String}
     */
    popupDate: Ember.computed('date', 'sessionAccount.user', function () {
      return (0, _convertDate.default)(this.date, this.sessionAccount.user);
    }),
    // Handles reloading the relative date so that it always appears correct
    _reloadTimer: null,
    _reloadTimerFunction: function _reloadTimerFunction() {
      this.notifyPropertyChange('dateDisplay');
      this._reloadTimer = Ember.run.later(this, this._reloadTimerFunction, 60000);
    },
    didInsertElement: function didInsertElement() {
      this._reloadTimer = Ember.run.later(this, this._reloadTimerFunction, 60000);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this._reloadTimer);
    }
  });

  _exports.default = _default;
});