define("sky-router-3/pods/portal/manage/location/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Binding the query parameters to the search field
     * @type {Array}
     */
    queryParams: ['search'],

    /**
     * Holds a text value that is being used for searching
     * @type {String}
     */
    search: null,

    /**
     * Locations that are filtered by the search
     */
    filteredLocations: Ember.computed.filter('model', ['search', 'model.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search) || (0, _stringSearch.default)(item.get('type.name'), search) || (0, _stringSearch.default)(item.get('code'), search) || (0, _stringSearch.default)(item.get('address'), search) || (0, _stringSearch.default)(item.get('city'), search) || (0, _stringSearch.default)(item.get('contactName'), search) || (0, _stringSearch.default)(item.get('contactPhone'), search) || (0, _stringSearch.default)(item.get('stateProvince'), search) || (0, _stringSearch.default)(item.get('zipCode'), search) || (0, _stringSearch.default)(item.get('street'), search) || (0, _stringSearch.default)(item.get('country'), search) || (0, _stringSearch.default)(item.get('companyName'), search) || (0, _stringSearch.default)(item.get('radioFrequency'), search) || (0, _stringSearch.default)(item.get('block'), search) || (0, _stringSearch.default)(item.get('comment'), search);
      }

      return true;
    }),

    /**
     * The property by which locations are sorted
     * @type {String}
     */
    sortProperty: 'name',

    /**
     * The direction that the list is sorted
     * @type {Boolean}
     */
    sortDesc: false,

    /**
     * Returns a string used by sortedLocations to sort the collection
     * @return {Array<string>}
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),

    /**
     * Locations that have been sorted
     */
    sortedLocations: Ember.computed.sort('filteredLocations', 'sortBy'),

    /**
     * Array of location that are selected
     * @type {Array}
     */
    selectedLocations: [],

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('sortedLocations.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100],
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      },
      selectLocation: function selectLocation(location) {
        var locations = this.get('selectedLocations');

        if (locations.includes(location)) {
          locations.removeObject(location);
        } else if (!location.accountShare) {
          locations.addObject(location);
        }
      },
      selectLocations: function selectLocations(locations, checked) {
        var selected = this.get('selectedLocations');

        if (checked) {
          selected.addObjects(locations.filterBy('accountShare', false));
        } else {
          selected.removeObjects(locations);
        }
      },
      deleteLocations: function deleteLocations(locations) {
        var _this = this;

        var names = locations.mapBy('name').join(', ');

        if (confirm("".concat(this.intl.t('locations.deleteLocationConfirm'), "\r\n ").concat(names))) {
          var promises = locations.map(function (rec) {
            return rec.destroyRecord();
          });
          Ember.RSVP.allSettled(promises).then(function () {
            _this.get('selectedLocations').clear();
          }).catch(function () {
            _this.notifications.error(_this.intl.t('locations.deleteLocationsFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});