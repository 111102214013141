define("sky-router-3/models/asset-profile-set-revision", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The set this revision belongs to
     */
    profileSet: (0, _model.belongsTo)('asset-profile-set', {
      inverse: 'revisions'
    }),

    /**
     * The user that created this revision
     */
    creator: (0, _model.belongsTo)('user'),

    /**
     * The date this revision was created
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * Optional comment about the revision
     */
    comment: (0, _model.attr)('string'),

    /**
     * Settings for this revision if they are any present.
     * Settings are not always downloaded. They must be queried.
     */
    settings: (0, _model.hasMany)('asset-profile-set-revision-setting', {
      async: false
    })
  });

  _exports.default = _default;
});