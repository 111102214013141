define("sky-router-3/models/asset-compound-rule-asset", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The asset that this deivce revision record is assigned to
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The compound rule that this rule asset record belongsTo
     */
    rule: (0, _model.belongsTo)('asset-compound-rule'),

    /**
     * The revision that this device revision record is associated with
     */
    revision: (0, _model.belongsTo)('asset-compound-rule-revision'),

    /**
     * The date this record was updated.
     */
    updatedDate: (0, _model.attr)('date'),

    /**
     * The date this record was created.
     */
    createdDate: (0, _model.attr)('date')
  });

  _exports.default = _default;
});