define("sky-router-3/pods/portal/report/devicehistory/controller", ["exports", "moment", "sky-router-3/utils/filter-by-in-array", "sky-router-3/utils/computed-csv", "sky-router-3/utils/constants", "sky-router-3/utils/computed-find-by"], function (_exports, _moment, _filterByInArray, _computedCsv, _constants, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['device', 'sDate', 'eDate', 'timeZoneMinutes', 'entryTypes', 'sourceTypes'],

    /**
     * The list of device selected by the user
     * @type {String}
     */
    device: null,
    deviceSelected: (0, _computedFindBy.default)('model.devices', 'id', 'device', true),

    /**
     * The start date selected by the user
     * @type {Date}
     */
    sDate: null,
    sDateObject: Ember.computed('sDate', {
      get: function get() {
        var date = (0, _moment.default)(this.sDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().startOf('day').add(-1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('sDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The end date selected by the user
     * @type {Date}
     */
    eDate: null,
    eDateObject: Ember.computed('eDate', {
      get: function get() {
        var date = (0, _moment.default)(this.eDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().endOf('day').add(1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('eDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The entry types selected by the user
     * @type {String}
     */
    entryTypes: [],
    entryTypesSelected: (0, _filterByInArray.default)('model.entryTypes', 'id', 'entryTypes'),
    entryTypesList: (0, _computedCsv.default)('entryTypes'),

    /**
     * The source types selected by the user
     * @type {String}
     */
    sourceTypes: [],
    sourceTypesSelected: (0, _filterByInArray.default)('model.sourceTypes', 'id', 'sourceTypes'),
    sourceTypesList: (0, _computedCsv.default)('sourceTypes'),
    sessionAccount: Ember.inject.service(),

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * A flag that is flipped when an export is in progress or completes
     * @type {Boolean}
     */
    exportInProgress: false,

    /**
     * When this is toggled the report will generate
     * @type {Boolean}
     */
    generateReport: false,

    /**
     * Makes the API call to retrive the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName) {
      var _this = this;

      // Disable export buttons
      this.set('exportInProgress', true); // Get report parameters

      var imei = this.get('model.selectedDevice.imei') || 0;
      var entryTypesList = this.get('entryTypesList');
      var sourceTypesList = this.get('sourceTypesList');
      var utcOffset = this.timeZone.offsetMinutes;
      var startDate = this.sDateObject.toISOString();
      var endDate = this.eDateObject.toISOString();
      var sortString = encodeURIComponent(this.get('model.sortString')); // Call the API

      this.api.getDeviceHistoryReportExport(fileName, imei, startDate, endDate, entryTypesList, sourceTypesList, sortString, utcOffset, exportType).catch(function () {
        _this.notifications.error(_this.intl.t('report.exportFailed', {
          exportType: exportType
        }));
      }).finally(function () {
        _this.set('exportInProgress', false);
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        // Triggers the report to generate in the component
        this.toggleProperty('generateReport');
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport('Excel', this.intl.t('nav.deviceHistory') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport('CSV', this.intl.t('nav.deviceHistory') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport('PDF', this.intl.t('nav.deviceHistory') + '-' + this.getUTCDateString() + '.pdf');
      },

      /**
       * Powers the dropdown list for selection
       * @param  {boolean} checked Is the item selected or not
       * @param  {Array<>} items   Items that changed
       */
      select: function select(list, selected, items) {
        var ids = items.mapBy('id');

        if (selected) {
          list.addObjects(ids);
        } else {
          list.removeObjects(ids);
        }
      },
      selectDevice: function selectDevice(asset) {
        this.set('device', asset ? asset.get('id') : null);
      }
    }
  });

  _exports.default = _default;
});