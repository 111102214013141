define("sky-router-3/pods/components/loading-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Loading icon that is generic throughout the application
   */
  var _default = Ember.Component.extend({
    tagName: '',

    /**
     * is the spinner spinning?
     */
    active: true,

    /**
     * The classes that will be applied to the spinner
     */
    'class': ''
  });

  _exports.default = _default;
});