define("sky-router-3/pods/components/portal/track/map/marker/marker-breadcrumb/component", ["exports", "sky-router-3/models/asset-position", "sky-router-3/mixins/google-object-mapping", "sky-router-3/mixins/google-marker-auto-hide", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/map-lat-lng-to-point", "sky-router-3/pods/components/portal/track/map/lib/computed-elevation", "sky-router-3/models/asset-position-type"], function (_exports, _assetPosition, _googleObjectMapping, _googleMarkerAutoHide, _latLngConverter, _mapLatLngToPoint, _computedElevation, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This marker component is responsible for showing an position event on the
   * map. Position events should be associated with an asset and sometimes
   * have lines connecting them together.
   *
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, _googleMarkerAutoHide.default, {
    tagName: '',

    /**
     * @event 'infoBoxClose'
     * This is triggered whenever the infoBox has been closed
     * by the user. The UI has already updated with the change
     * this is just a notification.
     */
    infoBoxClose: function infoBoxClose() {},
    // Mixin properties
    googlePropertyMap: [{
      event: 'position_changed',
      property: 'position',
      setFunc: 'setPosition',
      getFunc: 'getPosition'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'icon',
      setFunc: 'setIcon'
    }, {
      property: 'animation',
      setFunc: 'setAnimation'
    }, {
      property: 'isEditing',
      setFunc: 'setDraggable'
    }, {
      property: 'zIndex',
      setFunc: 'setZIndex'
    }, {
      event: 'mouseover',
      eFunc: '_markerMouseOver'
    }, {
      event: 'mouseout',
      eFunc: '_markerMouseOut'
    }, {
      event: 'click',
      eFunc: '_markerClick'
    }],

    /**
     * The users current logon account session
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * Event that governs the display of this marker
     */
    event: null,

    /**
     * Incoming color of the asset that this event is attached to
     * in hex format.
     */
    assetColor: '#000000',

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedTabIndex: null,

    /**
     * Determines if the circle marker is visible for the breadcrumb
     * by checking to see if the color for the event is populated
     * to a value. When the value is falsy the circle should not be shown.
     */
    cicleVisible: Ember.computed.bool('event.type.color'),

    /**
     * This generates the arrow portion of the icon.
     */
    icon: function () {
      return {
        path: 'M15.4,10.5l4.2,10H11.2Z',
        anchor: new google.maps.Point(15, 15),
        strokeColor: this.get('assetColor') || '#000000',
        fillColor: this.get('event.provider') === _assetPosition.PROVIDERS.iridium ? '#FF0000' : this.get('event.provider') === _assetPosition.PROVIDERS.adsb ? '#CCCC00' : this.get('event.provider') === _assetPosition.PROVIDERS.quickLynk ? '#FF00FF' : '#0000FF',
        //GSM
        fillOpacity: 1,
        rotation: Number.parseFloat(this.get('event.headingDegrees'))
      };
    }.property('event.headingDegrees', 'assetColor', 'event.provider'),

    /**
     * Indicates that the breadcrumb is moveable on the map by hand.
     * @type {Boolean}
     */
    isEditing: Ember.computed.alias('event.isNew'),

    /**
     * Indicates if the bouncing animation is shown for the breadcrumb
     */
    animation: function () {
      return this.get('isEditing') ? google.maps.Animation.BOUNCE : null;
    }.property('isEditing'),

    /**
     * Sets the markers position based on the event point
     */
    position: Ember.computed('event.latitudeMilliarcSeconds', 'event.longitudeMilliarcSeconds', {
      set: function set(key, value) {
        if (this.get('isEditing')) {
          var mLat = _latLngConverter.default.decimalDegToMilliArcSecond(value.lat());

          var mLng = _latLngConverter.default.decimalDegToMilliArcSecond(value.lng());

          this.set('event.latitudeMilliarcSeconds', mLat);
          this.set('event.longitudeMilliarcSeconds', mLng);
        }

        return value;
      },
      get: function get() {
        return _latLngConverter.default.modelToGoogleLatLng(this.get('event'));
      }
    }),

    /**
     * Elevation above 0 MSL based on a sphereoid
     * NOTE: this costs money each time it is used. Be careful on usage
     */
    elevationCM: (0, _computedElevation.default)('position', false),
    elevationShow: false,

    /**
     * Sets the zIndex of the marker. This helps markers with more significance
     * stay ontop of the other markers if they happen to be right near each other.
     * @return {Number}
     */
    zIndex: function () {
      switch (this.get('event').belongsTo('type').id()) {
        case _assetPositionType.POSITION_TYPES.NormalPositionReport:
        case _assetPositionType.POSITION_TYPES.NormalBreadcrumb:
          return 502;

        case _assetPositionType.POSITION_TYPES.Inactive:
          return 500;

        default:
          return 503;
      }
    }.property('event'),
    //#region SETUP AND TEARDOWN

    /**
     * Initializes the marker for this asset.
     */
    init: function init() {
      var marker = new google.maps.Marker();
      this.set('googlePropertyObject', marker);

      this._super.apply(this, arguments);
    },

    /**
     * Removes the marker from the map and destroys it.
     */
    willDestroyElement: function willDestroyElement() {
      this.get('googlePropertyObject').setMap(null);
    },
    //#endregion
    //#region INFOBOX

    /**
     * classs name for the infobox
     */
    infoboxClassName: Ember.computed('event.type.id', function () {
      return "position-type-".concat(this.get('event.type.id'));
    }),

    /**
     * Incoming property that can force the infoBox open.
     * Also can be set internally.
     */
    infoBoxOpen: false,

    /**
     * Indicates if the InfoBox is actually shown in the UI or not.
     */
    _infoBoxOpen: Ember.computed.or('infoBoxOpen', '_markerHovered'),

    /**
     * Sets the marker in hover-mode if the marker is not behind an open infoBox
     * @private
     */
    _markerMouseOver: function _markerMouseOver(marker, mouseEvent) {
      var pix = (0, _mapLatLngToPoint.default)(this.get('map'), mouseEvent.latLng);
      var elm = document.elementFromPoint(pix.x, pix.y);

      if ($(elm).closest('.infobox').length === 0) {
        this.set('_markerHovered', true);
      }
    },

    /**
     * Starts a timer to close turn off the marker hovered mode.
     * @private
     */
    _markerMouseOut: function _markerMouseOut() {
      this.set('_markerHovered', false);
    },

    /**
     * Force the infoBox open and shows the close icon
     */
    _markerClick: function _markerClick() {
      this.set('infoBoxOpen', true);
    },
    //#endregion
    actions: {
      infoBoxCloseClicked: function infoBoxCloseClicked() {
        this.set('infoBoxOpen', false);
        this.infoBoxClose();
      },
      showElevation: function showElevation() {
        this.set('elevationShow', true);
      }
    }
  });

  _exports.default = _default;
});