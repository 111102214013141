define("sky-router-3/utils/computed-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedCsv;

  /**
   * Takes any number of properties and turns them into a CSV of the property values.
   *
   * @example computedCsv('to.[]'),
   */
  function computedCsv() {
    var _Ember;

    for (var _len = arguments.length, properties = new Array(_len), _key = 0; _key < _len; _key++) {
      properties[_key] = arguments[_key];
    }

    return (_Ember = Ember).computed.apply(_Ember, properties.concat([function () {
      var _this = this;

      var value = "";
      properties.forEach(function (prop) {
        var array = _this.get(prop);

        if (array) {
          if (Ember.isArray(array)) {
            value += array.join(',');
          } else if (typeof array === 'string') {
            value += ",";
          }
        }
      });
      return value;
    }])).readOnly();
  }
});