define("sky-router-3/models/overlay-group", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model is responsible for displaying a groupping of overlays.
   * Note that not all overlays in this group can be displayed by this application.
   */
  var _default = _model.default.extend({
    /**
     * The name of the overlay group
     */
    name: (0, _model.attr)('string'),

    /**
     * A short description of the overlay group
     */
    description: (0, _model.attr)('string'),

    /**
     * Is the group editable by the user that downloaded it.
     */
    editable: (0, _model.attr)('boolean'),

    /**
     * HasMany Array of overlays that belong to the group
     */
    overlays: (0, _model.hasMany)('overlay', {
      async: false
    }),

    /**
     * Array of overlays that can be displayed by this application.
     * This is filtered from `overlays`.
     */
    displayableOverlays: Ember.computed('overlays', function () {
      return this.get('overlays').filterBy('canDisplay').sortBy('name');
    })
  });

  _exports.default = _default;
});