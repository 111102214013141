define("sky-router-3/pods/components/display-distance/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper takes a value in centimeters and turns it into the units the user
   * uses to view distance with. The format of the output is determined by the users profile.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),

    /**
     * The altitude in centimeters that will be mutated for display
     * @type {Number}
     */
    cm: 0,

    /**
     * Overrides the units setting for the number of decimals to display.
     * @type {Number}
     */
    decimals: null,

    /**
     * Gets the computed final string for use with display altitude.
     * @return {Ember.RSVP.Promise} Promise when resolved holds the final
     *                              altitude string.
     */
    value: function () {
      var _this = this;

      var cm = this.get('cm');
      return _emberData.default.PromiseObject.create({
        promise: this.get('sessionAccount.user.distanceMeasure').then(function (unit) {
          var value = cm * unit.get('centimeterFactor');
          var decimalCount = _this.decimals != null ? _this.decimals : unit.get('decimalCount');
          var decimals = Math.pow(10, decimalCount);
          return Math.floor(value * decimals) / decimals + unit.get('shortName');
        })
      });
    }.property('cm', 'sessionAccount.user.distanceMeasure')
  });

  _exports.default = _default;
});