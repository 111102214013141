define("sky-router-3/pods/components/portal/manage/device-profiles/types/d2ka/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m9fK9oTI",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"settings\",\"disabled\",\"showDistance\"],[[35,1],[35,0],false]]]],[2,\"\\n\"],[1,[30,[36,4],null,[[\"settings\",\"disabled\",\"type\"],[[35,1],[35,0],[35,3]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"settings\",\"portal/manage/device-profiles/flight-plans\",\"type\",\"portal/manage/device-profiles/short-codes\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-profiles/types/d2ka/template.hbs"
  });

  _exports.default = _default;
});