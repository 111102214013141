define("sky-router-3/pods/components/portal/track/map/line/line-breadcrumb/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This class represents a line between breadcrumbs.
   * It wraps the Google Maps API class `google.maps.Polyline`
   *
   * @example {{line-breadcrumb breadcrumbArray=[] currentPosition=asset.positionLatest color=asset.color}}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('line'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'path',
      setFunc: 'setPath'
    }, {
      property: 'strokeColor',
      setFunc: 'set'
    }],

    /**
     * Incoming object.
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Incoming array of breadcrumbs that represent all of
     * the events of which this line segment can use to
     * position itself.
     * @type {sky-router-3/models/asset-position[]}
     */
    breadcrumbArray: null,

    /**
     * The last and current position of the asset this breadcrumb
     * line is attached to.
     *
     * This is used to bridge the gap from the last event in the
     * `breadcrumbArray` to the assets current position since
     * the assets current position is not present in the array.
     * @type {sky-router-3/models/asset-position}
     */
    currentPosition: null,

    /**
     * Reference to the instance of the Google Polyline
     * @type {google.maps.Polyline}
     */
    line: null,

    /**
     * Incoming HEX color for the line
     * in the format of '#000000'
     */
    color: '#000000',

    /**
     * The options object that is used to set options
     * on the polyline from ember.
     */
    options: function () {
      return {
        geodesic: true,
        clickable: false,
        strokeWeight: 2,
        strokeColor: this.get('color'),
        zIndex: 498
      };
    }.property('color'),

    /**
     * The path which this line takes
     */
    path: function () {
      var crumbArray = this.get('breadcrumbArray');
      var ret = [];

      if (crumbArray.get('length')) {
        ret = crumbArray.map(function (breadcrumbPosition) {
          return _latLngConverter.default.modelToGoogleLatLng(breadcrumbPosition);
        });
        ret.addObject(_latLngConverter.default.modelToGoogleLatLng(this.get('currentPosition')));
      }

      return ret;
    }.property('breadcrumbArray.[]', 'currentPosition.latitudeMilliarcSeconds', 'currentPosition.longitudeMilliarcSeconds', 'breadcrumbArray.@each.latitudeMilliarcSeconds', 'breadcrumbArray.@each.longitudeMilliarcSeconds'),

    /**
     * Creates the polyline Google maps object
     * and sets it.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var line = new google.maps.Polyline(this.get('options'));
      this.set('line', line);
    },

    /**
     * Removes the line from the map and destroys the
     * reference to it.
     */
    willDestroyElement: function willDestroyElement() {
      this.line.setMap(null);
    }
  });

  _exports.default = _default;
});