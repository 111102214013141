define("sky-router-3/pods/portal/report/data-usage/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array} 
     */
    permissions: ['report.devicehistory'],
    // permissions: ['report.datausage'],
    model: function model(params) {
      // return hash({   dataUsage: this.store.findAll('data-usage'),
      //                 dataUsageSummary: this.store.findAll('data-usage-summary')
      //             });
      // return hash({dataUsage :  [{ "id": 1, "imei": "123456789012345", "planName": "Gold 2022", "sbdUsed" : 1100, "totalPlan" : 0, "remaining" : 200}, 
      //  { "id": 2, "imei": "888886789012345", "planName": "Gold 2022", "sbdUsed" : 1500, "totalPlan" : 0, "remaining" : 150}],
      //  dataUsageSummary:  []
      return Ember.RSVP.hash({
        dataUsage: this.store.findAll('data-usage'),
        dataUsageSummary: this.api.getDataUsageSummaryReport()
      });
    },
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      sendEmail: function sendEmail() {
        var _this = this;

        this.send('blockApp', true, {
          message: this.intl.t('report.dataUsage.emailInProgress')
        });
        this.api.sendDataUsageReportEmail().then(function () {
          _this.send('blockApp', false);

          _this.notifications.success(_this.intl.t('report.dataUsage.emailSuccess'));
        }).catch(function () {
          _this.send('blockApp', false);

          _this.notifications.error(_this.intl.t('report.dataUsage.emailFailure'));
        });
      }
    }
  });

  _exports.default = _default;
});