define("sky-router-3/pods/components/portal/manage/api-key-pin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wcF7ilPh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"applicationKeyManager.pin.title\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"applicationKeyManager.pin.help\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"applicationKeyManager.pin.help2\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"warn\"],[12],[1,[30,[36,0],[\"applicationKeyManager.pin.helpWarn\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"min\",\"max\",\"maxlength\",\"placeholder\"],[\"number\",\"form-control\",[35,1,[\"linkedAppPin\"]],\"0\",\"999999\",\"6\",\"1234\"]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"account\",\"input\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/api-key-pin/template.hbs"
  });

  _exports.default = _default;
});