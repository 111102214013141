define("sky-router-3/models/alert-definition", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALERT_DEFINITION_TYPE = void 0;

  /* eslint-env node */

  /**
   * A constant that describes the types of alerts that the UI has access to
   * view, create, and manipulate.
   * @type {Object}
   */
  var ALERT_DEFINITION_TYPE = {
    email: 1,
    sms: 3
  };
  _exports.ALERT_DEFINITION_TYPE = ALERT_DEFINITION_TYPE;

  /**
   * An alert-definition is used by the system to define a set of rules that can
   * send out different types of alerts based on triggering conditions.
   */
  var _default = _model.default.extend({
    /**
     * Holds the type of the alert definition.
     * This value should correspond to the constant ALERT_DEFINITION_TYPE
     */
    type: (0, _model.attr)('number', {
      defaultValue: ALERT_DEFINITION_TYPE.email
    }),

    /**
     * The alert trigger event that triggers this alert
     */
    alertTriggerEvent: (0, _model.belongsTo)('alert-trigger-event'),

    /**
     * HasMany relationship that indicates what flags this alert has set.
     * The flags are used to indicate what information about an event to include
     * in the message.
     */
    messageFlags: (0, _model.hasMany)('alert-definition-message-tag'),

    /**
     * The groups that are associated with this alert
     */
    groups: (0, _model.hasMany)('group'),

    /**
     * Users that will receive the alerts via email.
     */
    users: (0, _model.hasMany)('user'),
    personnel: (0, _model.hasMany)('personnel'),

    /**
     * Holds an array of addresses that this alert will be sent to.
     * If it is type SMS these will be phone numbers.
     * If it is type Email these will be email addresses.
     */
    recipients: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * The name of the alert
     */
    name: (0, _model.attr)('string'),

    /**
     * If the alert supports a subject then this will contain the subject
     * line of the message that is generated.
     */
    subject: (0, _model.attr)('string'),

    /**
     * The body of the message that is generated and sent along with the alert
     */
    message: (0, _model.attr)('string'),

    /**
     * What is the minimum time that must elapse between instances of this alert
     * firing because of the same trigger.
     */
    secondsBetweenAlerts: (0, _model.attr)('number', {
      defaultValue: 30
    }),

    /**
     * The date that this alert-definition was created.
     */
    createdDateUtc: (0, _model.attr)('date'),

    /**
    * The airport id selected for the alertTypes
    **/
    airportId: (0, _model.attr)('string'),

    /**
    * The airport name selected for the alertTypes
    **/
    airportName: (0, _model.attr)('string'),

    /**
    * The location id selected for the alertTypes
    **/
    locationId: (0, _model.attr)('number'),

    /**
     * Geofences associated with this alert.
     * This geofence is used to restrict alert triggers if
     * the triggering event is a geofence violation.
     */
    geofences: (0, _model.hasMany)('geofence')
  });

  _exports.default = _default;
});