define("sky-router-3/pods/portal/manage/personnel/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      includeDisabled: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.query('personnel', {
        includeDisabled: params.includeDisabled
      });
    }
  });

  _exports.default = _default;
});