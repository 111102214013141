define("sky-router-3/models/contact", ["exports", "@ember-data/model", "sky-router-3/utils/constants"], function (_exports, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents a contact that can be used to send messages to
   */
  var _default = _model.default.extend({
    groups: (0, _model.hasMany)('group'),
    title: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    fax: (0, _model.attr)('string'),
    mobile: (0, _model.attr)('string'),
    address: (0, _model.attr)('string'),
    country: (0, _model.attr)('string'),
    region: (0, _model.attr)('string'),
    timeZone: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    latitudeMilliarcseconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcseconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    fullName: function () {
      var lastName = this.get('lastName');
      var firstName = this.get('firstName');

      if (lastName) {
        return "".concat(firstName, " ").concat(lastName);
      } else {
        return this.get("firstName");
      }
    }.property('firstName', 'lastName'),
    timeZoneDisplay: function () {
      return _constants.timeZones.findBy('id', this.get('timeZone')).label;
    }.property('timeZone')
  });

  _exports.default = _default;
});