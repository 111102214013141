define("sky-router-3/pods/components/portal/manage/device-profiles/short-codes/component", ["exports", "ember-data", "sky-router-3/utils/computed-find-by"], function (_exports, _emberData, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),

    /**
     * This holds the mappings for the short codes to their appropriate commands
     * this helps to cleanup the UI by allowing us to run it in a loop
     * @type {Array}
     */
    availableShortCodes: [{
      id: 1,
      commandKey: 'shortcode1_command',
      textKey: 'shortcode1_text'
    }, {
      id: 2,
      commandKey: 'shortcode2_command',
      textKey: 'shortcode2_text'
    }, {
      id: 3,
      commandKey: 'shortcode3_command',
      textKey: 'shortcode3_text'
    }, {
      id: 4,
      commandKey: 'shortcode4_command',
      textKey: 'shortcode4_text'
    }, {
      id: 5,
      commandKey: 'shortcode5_command',
      textKey: 'shortcode5_text'
    }, {
      id: 6,
      commandKey: 'shortcode6_command',
      textKey: 'shortcode6_text'
    }, {
      id: 7,
      commandKey: 'shortcode7_command',
      textKey: 'shortcode7_text'
    }, {
      id: 8,
      commandKey: 'shortcode8_command',
      textKey: 'shortcode8_text'
    }, {
      id: 9,
      commandKey: 'shortcode9_command',
      textKey: 'shortcode9_text'
    }, {
      id: 0,
      commandKey: 'shortcode0_command',
      textKey: 'shortcode0_text'
    }],

    /**
     * Dynamically create the properties we need to handle the short codes
     */
    init: function init() {
      var _this = this;

      this.get('availableShortCodes').forEach(function (code) {
        Ember.defineProperty(_this, code.commandKey, (0, _computedFindBy.default)('settings', 'key', code.commandKey));
        Ember.defineProperty(_this, code.textKey, (0, _computedFindBy.default)('settings', 'key', code.textKey));
      });
      return this._super.apply(this, arguments);
    },

    /**
     * The AssetProfileSetType that this Component is working with
     * @type {String}
     */
    type: null,

    /**
     * The settings array that contains all the settings.
     * @type {Array<sky-router-3/models/asset-profile-set-revision-setting>}
     */
    settings: null,

    /**
     * find all the properties
     */
    featureEnabled: (0, _computedFindBy.default)('settings', 'key', 'shortcode_enabled'),

    /**
     * Has the component been disabled from the outside
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Is the form disabled
     */
    disableForm: function () {
      return this.get('disabled') || this.get('featureEnabled') && !this.get('featureEnabled.valueBoolean');
    }.property('featureEnabled.valueBoolean', 'disabled'),

    /**
     * Queries the API for available short codes to populate the drop-downs.
     * @return {Ember.RSVP.Promise}
     */
    shortCodes: function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get('api').request("AssetProfileSetShortCodes?setType=".concat(this.get('type')))
      });
    }.property('type'),
    actions: {
      setShortCode: function setShortCode(shortCode, selected) {
        var label = selected.Label;

        if (selected.Id === "0" || selected.Id === "153") {
          label = '';
        }

        this.set("".concat(shortCode.commandKey, ".value"), selected.Id);
        this.set("".concat(shortCode.textKey, ".value"), label);
      }
    }
  });

  _exports.default = _default;
});