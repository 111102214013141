define("sky-router-3/pods/components/portal/track/map/marker/marker-asset/component", ["exports", "sky-router-3/models/asset-icon", "ember-data", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter", "sky-router-3/pods/components/portal/track/map/lib/html-marker", "sky-router-3/pods/components/portal/track/map/lib/computed-elevation"], function (_exports, _assetIcon, _emberData, _googleObjectMapping, _latLngConverter, _htmlMarker, _computedElevation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Marker responsible for showing an asset on the map. The markers position is based
   * on the assets latest event and the markers icon is based on the assets type and color
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    store: Ember.inject.service(),

    /**
     * @event markerAction
     * @description generic action that contains all of the events that are fired.
     *              combining all the actions into one action helps reduce code
     *              duplication.
     *
     * @param the sender of the action, aka. this component.
     *
     * @param the string action that is being performed
     *
     * 'close'               : Fired when the marker wishes to close its infoBox
     * 'click'               : Fired when the marker is clicked
     * 'follow'              : Fired When the asset is requesting to be followed
     * 'un-follow'           : Fired when the asset is requesting not to be followed anymore
     * 'show-breadcrumbs'    : Fired when the asset is requesting the breadcrumbs to be shown
     * 'ping'                : Fired when the user wants to ping the asset.
     * 'filter-geofences'    : Fired when the user wants to show geofences associated with This
     * 							asset only.
     * 'show-flightpath'     : Fired when the flight path should be shown for the asset
     *
     * @param object containing arguments which are defined for each action as follows
     *
     * 'close'           : { assetId: <string> }
     * 'click'           : { assetId: <string> }
     * 'follow'          : { assetId: <string> }
     * 'un-follow'       : { assetId: <string> }
     * 'show-breadcrumbs': { assetId: <string> }
     * 'ping'            : { asset: <sky-router-3/models/asset> }
     * 'filter-geofences': { assetId: <string> }
     * 'show-flightpath' : { assetId: <string> }
     * 'hide-flightpath' : { assetId: <string> }
     *
     */
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('marker'),
    googlePropertyMap: [{
      property: 'position',
      setFunc: 'setPosition'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      event: 'mouseover',
      eFunc: '_markerMouseOver'
    }, {
      event: 'mouseout',
      eFunc: '_markerMouseOut'
    }, {
      property: 'size',
      setFunc: 'setSize'
    }],

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * The instance of the Google maps marker that defines this location
     * @type {google.maps.Marker}
     */
    marker: null,

    /**
     * The asset item that this marker runs off of
     */
    asset: null,
    globalTravelerAsset: null,

    /**
     * Type key identifier used to determine the type of marker that this is.
     * @type {String}
     */
    type: 'asset',

    /**
     * Holds a reference to the marker spiderfier that is responsible
     * for group markers on a map a certain way. This is OPTIONAL.
     *
     * Markers should register and un-register themselves from the
     * spiderfier when they want to use them.
     *
     * @default null
     * @type {portal/track/map/marker-spiderfier}
     */
    spiderfier: null,

    /**
     * Indicates that the map is currently following this asset
     * and the menu should reflect the change.
     *
     * When this is true the 'follow asset' menu item changes to
     * 'un-follow asset'
     */
    isFollowing: false,

    /**
     * Injectd users sessionAccount service
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedTabIndex: null,

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedFlightPlanTabIndex: null,

    /**
     * Should the event pulse be shown to the user or not?
     * This is a get and set because a timer is used to reset the value back to false
     * @type {Boolean}
     */
    showEventPulse: Ember.computed('asset.positionLatest', {
      get: function get() {
        var _this = this;

        return _emberData.default.PromiseObject.create({
          promise: Ember.RSVP.resolve(this.get('asset.positionLatest.type')).then(function (type) {
            var value = !!type && !_this.get('asset.positionLatest.isHistorical');

            _this.showEventPulseTimerSetup(value, type);

            return value;
          })
        });
      },
      set: function set(key, value) {
        this.showEventPulseTimerSetup(value, this.get('asset.positionLatest.type'));
        return value;
      }
    }),

    /**
     * This holds a instance of a timer that is used to automatically stop the
     * event pulse when it needs to.
     * @type {Timer}
     */
    showEventPulseTimer: null,
    showEventPulseSize: Ember.computed('asset.icon.sizePx', function () {
      var size = this.get('asset.icon.sizePx') || _assetIcon.ICON_SIZES.DEFAULT.sizePx;

      return "width:".concat(size, ";height:").concat(size);
    }),

    /**
     * This observes the `showEventPulse` property and starts a timer that sets
     * the value back to `false` when the timer period expires.
     *
     * Note that the timer value is a multiple of 5 seconds because that is twice
     * how long the animation runs. The default is 15 seconds if the user setting
     * is to manually dismiss. If the event doesn't support alerts then the animation
     * will only be executed once. (i.e. NormalPositionReport)
     */
    showEventPulseTimerSetup: function showEventPulseTimerSetup(showing, eventType) {
      Ember.run.cancel(this.get('showEventPulseTimer'));

      if (showing) {
        var supportAlert = eventType.get('supportAlertAction');
        var timeout = 5;

        if (supportAlert) {
          var evtTimeout = this.get('sessionAccount.user.eventNotificationTimeout');
          timeout = evtTimeout > 0 ? evtTimeout : 15;
        }

        var timer = Ember.run.later(this, this.set, 'showEventPulse', false, timeout * 1000);
        this.set('showEventPulseTimer', timer);
      }
    },

    /**
     * Gets the Google maps position of the asset point.
     * Also sets the position from a `Google.maps.LatLng` object.
     * If the map moves the marker then this property holds the value
     * of that change until the positionLatest point changes.
     */
    position: Ember.computed('asset.positionLatest.latitudeMilliarcSeconds', 'asset.positionLatest.longitudeMilliarcSeconds', {
      set: function set(key, value) {
        if (this.get('asset.positionLatest.isNew')) {
          var mLat = _latLngConverter.default.decimalDegToMilliArcSecond(value.lat());

          var mLng = _latLngConverter.default.decimalDegToMilliArcSecond(value.lng());

          this.set('asset.positionLatest.latitudeMilliarcSeconds', mLat);
          this.set('asset.positionLatest.longitudeMilliarcSeconds', mLng);
        }

        return value;
      },
      get: function get() {
        var positionLatest = this.get('asset.positionLatest');

        if (positionLatest) {
          return _latLngConverter.default.modelToGoogleLatLng(this.get('asset.positionLatest'));
        } else {
          return new google.maps.LatLng(0, 0);
        }
      }
    }),

    /**
     * Elevation above 0 MSL based on a sphereoid
     * NOTE: this costs money each time it is used. Be careful on usage
     */
    elevationCM: (0, _computedElevation.default)('position', false),
    elevationShow: false,

    /**
     * Gets the size of this html marker
     */
    size: Ember.computed('asset.icon.sizePx', function () {
      var size = parseInt(this.get('asset.icon.sizePx') || _assetIcon.ICON_SIZES.DEFAULT.sizePx);
      return new google.maps.Size(size, size);
    }),

    /**
     * Initializes the marker for this asset.
     */
    init: function init() {
      var marker = new _htmlMarker.default({
        zIndex: 600,
        pane: 'overlayMouseTarget',
        autohide: true
      });
      var spiderfier = this.get('spiderfier');
      this.set('marker', marker);

      if (spiderfier) {
        spiderfier.registerMarker(marker, this);
      }

      this._super.apply(this, arguments); // Get all Global Traveler Assets for this user
      // Match Global Traveler Asset that maps to this Asset ID


      var assetProductId = this.get('asset.product.id');

      if (assetProductId == 'GTRAVEL') {
        var self = this;
        var currentAssetId = this.get('asset.id');
        this.store.findAll('global-traveler-asset', {
          isEnabled: true
        }).then(function (travelers) {
          var gTravelerAsset = travelers.find(function (gAsset) {
            return gAsset.get('asset.id') == currentAssetId;
          });

          if (gTravelerAsset) {
            self.set('globalTravelerAsset', gTravelerAsset);
          }
        }, function (error) {
          console.log("LS: Error: ".concat(error));
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.get('marker').setContent(this.get('element'));
    },

    /**
     * Removes the marker from the map and destroys it.
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var marker = this.get('marker');
      var spiderfier = this.get('spiderfier');
      var timer = this.get('showEventPulseTimer');

      if (timer) {
        Ember.run.cancel(timer);
      }

      if (spiderfier) {
        spiderfier.unregisterMarker(marker);
      }

      marker.setMap(null);
    },
    //#region BREADCRUMBS

    /**
     * Indicates in minutes how many breadcrumbs to show. Negative numbers
     * indicate how many to show instead of a timespan in minutes.
     */
    breadcrumbTimespan: 0,

    /**
     * Indicates that the assets breadcrumbs should be rendered.
     */
    breadcrumbsShown: false,

    /**
     * Limit the number of breadcrumbs being shown to the last x Number
     * @default 200
     * @type {Number}
     */
    breadcrumbLimit: 200,

    /**
     * Holds the array of all the breadcrumbs that should be displayed
     * on the map behind the asset.
     *
     * The output is affected by the `breadcrumbsTimespan` and new
     * positions being added to the positions array.
     *
     * //TODO: since the array is assumed to have order, maybe
     *         move this to a binary search?
     */
    breadcrumbArray: function () {
      var array;
      var timeSpan = this.get('breadcrumbTimespan');
      var positions = this.get('asset.positionsSorted').filterBy('pointIsValid');
      var length = positions.get('length');

      if (!length) {
        array = [];
      } else if (timeSpan < 0) {
        /**
         * Slice the array from the end back, making sure not to slice more
         * elements than exist in the array. Timespan in this case is
         * how many items to take
         */
        array = positions.slice(length - Math.min(length, Math.abs(timeSpan)), length - 1);
      } else {
        /**
         * filter the array based on the timestamp of the positions being
         * newer than what the breadcrumb timespan indicates.
         */
        var utcTimestamp = Date.now() - timeSpan * 60000;
        array = positions.filter(function (item, index, array) {
          return item.get('utc') > utcTimestamp && index !== array.length - 1;
        });
      }

      return array.slice(this.get('breadcrumbLimit') * -1);
    }.property('breadcrumbTimespan', 'asset.positionsSorted.[]'),
    //#endregion

    /**
     * Indicates that the assets flight path should be shown to the user
     * if there is a valid flight plan associated with it.
     * @type {Boolean}
     */
    showFlightPath: false,

    /**
     * Gets a value that indicates that the flight plan path should be shown or not
     * @return {Boolean}
     */
    flightPathShowing: Ember.computed.and('showFlightPath', 'asset.currentFlightPlan.originLocation', 'asset.currentFlightPlan.destinationLocation'),

    /**
     * classs name for the infobox
     */
    infoboxClassName: Ember.computed('asset.positionLatest.type.id', function () {
      return "position-type-".concat(this.get('asset.positionLatest.type.id'));
    }),

    /**
     * Passed in property that indicates that the infobox should
     * be forced open.
     */
    infoBoxOpen: false,

    /**
     * Indicates if the markers pop-up info window is
     * displayed in the UI or not.
     */
    _infoBoxOpen: Ember.computed.or('_markerHovered', 'infoBoxOpen'),

    /**
     * Set to true if the users mouse is hovered over the marker
     */
    _markerHovered: false,

    /**
     * Sets the marker in hover-mode if the marker is not behind an open infoBox
     * @private
     */
    _markerMouseOver: function _markerMouseOver() {
      this.set('_markerHovered', true);
    },

    /**
     * Starts a timer to close turn off the marker hovered mode.
     * @private
     */
    _markerMouseOut: function _markerMouseOut() {
      this.set('_markerHovered', false);
    },
    assetDisplayName: Ember.computed('asset.name', 'asset.registration', 'asset.serial', 'asset.driverName', 'sessionAccount.user.assetDisplayNameFormat', function () {
      if (!this.asset) return '';
      var _this$asset = this.asset,
          name = _this$asset.name,
          registration = _this$asset.registration,
          serial = _this$asset.serial,
          driverName = _this$asset.driverName;
      var format = this.get('sessionAccount.user.assetDisplayNameFormat');
      if (!format || format.trim().length < 1) return name;
      var displayName = format.replaceAll('{{AN}}', name).replaceAll('{{REG}}', registration).replaceAll('{{SN}}', serial).replaceAll('{{DN}}', driverName);
      if (format.trim().length < 1) return name;
      return displayName;
    }),
    actions: {
      infoBoxCloseClicked: function infoBoxCloseClicked() {
        this.markerAction(this, 'close', {
          assetId: this.get('asset.id')
        });
      },
      followAsset: function followAsset() {
        this.markerAction(this, 'follow', {
          assetId: this.get('asset.id')
        });
      },
      unFollowAsset: function unFollowAsset() {
        this.markerAction(this, 'un-follow', {
          assetId: this.get('asset.id')
        });
      },
      showBreadcrumbs: function showBreadcrumbs() {
        this.markerAction(this, 'show-breadcrumbs', {
          assetId: this.get('asset.id')
        });
      },
      hideBreadcrumbs: function hideBreadcrumbs() {
        this.markerAction(this, 'hide-breadcrumbs', {
          assetId: this.get('asset.id')
        });
      },
      pingAsset: function pingAsset() {
        this.markerAction(this, 'ping', {
          asset: this.get('asset')
        });
      },
      toggleFlightPath: function toggleFlightPath(toggle) {
        var action = toggle ? 'show-flightpath' : 'hide-flightpath';
        this.markerAction(this, action, {
          assetId: this.get('asset.id')
        });
      },
      showElevation: function showElevation() {
        this.set('elevationShow', true);
      },
      showTripRoute: function showTripRoute(trip, show) {
        this.markerAction(this, 'toggle-trip-route', {
          tripId: trip.id,
          show: show
        });
      }
    }
  });

  _exports.default = _default;
});