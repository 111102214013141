define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-11/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CpCV8TAn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"compoundrules.condition.iridium.help\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compoundrules.condition.iridium.label\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"class\",\"value\",\"type\",\"min\",\"max\",\"disabled\"],[\"form-control\",[35,3,[\"value1\"]],\"number\",\"0\",\"5\",[35,2]]]]],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"errors\"],[[35,5,[\"attrs\",\"model\",\"value1\",\"messages\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"disabled\",\"model\",\"input\",\"validations\",\"input-errors\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-11/template.hbs"
  });

  _exports.default = _default;
});