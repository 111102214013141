define("sky-router-3/utils/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = analytics;
  _exports.setupGoogleAnalytics = void 0;

  /* global ga */

  /**
   * Uses google analytics GA function to sent events
   * https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
   */
  function analytics() {
    if (typeof ga === "function") {
      return ga.apply(void 0, arguments);
    }
  }
  /**
   * Instructs the google analytics that this session account is the one
   * being reported to google analytics.
   */


  var setupGoogleAnalytics = function setupGoogleAnalytics()
  /*user*/
  {
    return; //analytics('set', 'userid', user.username);
  };

  _exports.setupGoogleAnalytics = setupGoogleAnalytics;
});