define("sky-router-3/pods/components/portal/manage/device-profiles/types/nal/shout-sp/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E/AQ2uUF",
    "block": "{\"symbols\":[],\"statements\":[[8,\"portal/manage/device-profiles/nal-address-book\",[],[[\"@disabled\",\"@settings\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"settings\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-profiles/types/nal/shout-sp/template.hbs"
  });

  _exports.default = _default;
});