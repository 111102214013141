define("sky-router-3/models/burst-service-message-status", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    bcas: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    geoAreas: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    dispositionRequested: (0, _model.attr)('bool'),
    expiresAt: (0, _model.attr)('date'),
    status: (0, _model.attr)('string'),
    payloadSize: (0, _model.attr)('number'),
    percentSent: (0, _model.attr)('number'),
    priority: (0, _model.attr)('number'),
    queueAt: (0, _model.attr)('date'),
    serviceName: (0, _model.attr)('string'),
    submittedAt: (0, _model.attr)('date'),
    totalTransmissionSize: (0, _model.attr)('number')
  });

  _exports.default = _default;
});