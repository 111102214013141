define("sky-router-3/models/overlay-user-event-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.getTypeUrl = void 0;

  var getTypeUrl = function getTypeUrl(id) {
    var baseUrl = "/skyrouter3/assets/images/overlays/user-events";

    switch (id) {
      default:
      case '1':
        return "".concat(baseUrl, "/pirep.svg");

      case '2':
        return "".concat(baseUrl, "/lightning.svg");

      case '3':
        return "".concat(baseUrl, "/thunderstorm.svg");
    }
  };

  _exports.getTypeUrl = getTypeUrl;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    iconUrl: Ember.computed('icon', function () {
      return getTypeUrl(this.get('id'));
    })
  });

  _exports.default = _default;
});