define("sky-router-3/pods/portal/manage/device-compound-rules/assignment/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    search: null,

    /**
     * Filters the assets by the ones capable of sending compound rules
     */
    capableAssets: Ember.computed.filter('model.assets', ['model.assets.@each.product'], function (i) {
      return !!i.get('product.supportCompoundRules');
    }),

    /**
     * Filtering
     */
    filteredAssets: Ember.computed.filter('capableAssets', ['search', 'capableAssets.[]', 'model.assignments.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search) || (0, _stringSearch.default)(item.get('registration'), search) || item.get('compoundRules').any(function (i) {
          return (0, _stringSearch.default)(i.get('rule.name'), search);
        });
      }

      return true;
    }),

    /**
     * Sort functions
     */
    sortBy: ['name:desc'],
    sortedAssets: Ember.computed.sort('filteredAssets', 'sortBy')
  });

  _exports.default = _default;
});