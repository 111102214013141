define("sky-router-3/pods/components/portal/track/map/lib/computed-elevation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedElevation;

  /*global google */

  /**
   * Takes in a google maps LatLng and outputs its elevation above ground
   * NOTE: this costs money each time it is used. Be careful on usage
   * @example computedElevation('event.latLng', false),
   */
  function computedElevation(latLngProp) {
    var showLoadingMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var showSeaFloor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return Ember.computed(latLngProp, {
      get: function get(property) {
        var _this = this;

        var latLng = this.get(latLngProp);

        if (latLng) {
          var service = new google.maps.ElevationService();
          var request = {
            locations: [latLng]
          };
          service.getElevationForLocations(request, function (response, status) {
            if (status == google.maps.ElevationStatus.OK && !_this.isDestroying && !_this.isDestroyed) {
              var elevation = response[0].elevation;

              if (!showSeaFloor) {
                elevation = Math.max(elevation, 0);
              }

              _this.set(property, elevation * 100);
            }
          });
        }

        if (showLoadingMessage) {
          return Ember.getOwner(this).lookup('service:intl').t('misc.loadingPlaceholder');
        }

        return null;
      },
      set: function set(key, value) {
        return value;
      }
    });
  }
});