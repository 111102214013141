define("sky-router-3/pods/components/input-groups/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onChange: function onChange() {},

    /**
     * The groups that the user has the ability to add and remove
     * @type {Array<sky-router-3/models/group}
     */
    groups: null,

    /**
     * The groups that the user current has selected
     * @type {Array<sky-router-3/models/group}
     */
    selected: null,
    actions: {
      groupsChanged: function groupsChanged(selected) {
        this.onChange(selected);
      },
      setAllGroups: function setAllGroups() {
        this.onChange(this.get('groups'));
      },
      setAllActiveGroups: function setAllActiveGroups() {
        this.onChange(this.get('groups').filterBy('isActive', true));
      },
      removeAllGroups: function removeAllGroups() {
        this.onChange([]);
      },
      removeInactiveGroups: function removeInactiveGroups() {
        this.onChange(this.get('selected').filterBy('isActive', true));
      }
    }
  });

  _exports.default = _default;
});