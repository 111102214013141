define("sky-router-3/models/personnel", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),
    isDisabled: (0, _model.attr)('boolean'),
    createdDateUtc: (0, _model.attr)('date'),
    type: (0, _model.belongsTo)('personnel-type'),
    apiKeys: (0, _model.hasMany)('api-key'),

    /**
     * A pin code that the user may be required to enter in order to use some features
     * @type {String}
     */
    linkedAppPin: (0, _model.attr)('string')
  });

  _exports.default = _default;
});