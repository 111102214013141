define("sky-router-3/pods/portal/manage/compound-alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bPAKXAeG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n            \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"linkSelector\",\"tagName\"],[\"a.ember-view\",\"li\"]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[6,[37,1],null,[[\"route\"],[\"portal.manage.compound-alerts.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compound-alerts.manageTab\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[6,[37,1],null,[[\"route\"],[\"portal.report.compound-alert-log\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compound-alerts.alertLogTab\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"active-link\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/compound-alerts/template.hbs"
  });

  _exports.default = _default;
});