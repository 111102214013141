define("sky-router-3/pods/components/portal/manage/device-parameters/types/he8100-vehicle/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,
    enableAdvanced: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,
    store: Ember.inject.service(),
    didInsertElement: function didInsertElement() {//     this.set('stationaryReportingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('stationaryReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('stationaryReportingTimeoutSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('stationarySpeed', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('movingReportingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('movingReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('movingSpeed', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('turnDetectionEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('deviceNetworkMode', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('deviceNetworkModeTimeout', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('quickReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('speedEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('speedIntervalSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('speedTransitionTime', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('speedLimitKph', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('rolloverEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('crashEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('engineEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('idlingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('idlingDelaySeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('idlingIntervalSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('idlingIntervalEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('obdDtcEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('eventFlagsPowerOnEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('eventFlagsPowerOffEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('eventFlagsPowerExtOnEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //     this.set('eventFlagsPowerExtOffEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
      //
      //this.set('shutdownTimeout', this.store.createRecord('asset-parameter-set-revision-setting'));
      //this.set('shutdownReportingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    },

    /**
     * Alias'd settings that are easier to get at.
     */
    stationaryReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_stationary_enabled'),
    stationaryReportingTimeoutSeconds: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_stationary_timeout_seconds'),
    stationaryReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_stationary_interval_seconds'),
    stationarySpeed: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_stationary_speed'),
    movingReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_moving_enabled'),
    movingReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_moving_interval_seconds'),
    movingSpeed: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_moving_speed'),
    turnDetectionEnabled: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_turn_enabled'),
    deviceNetworkMode: (0, _computedFindBy.default)('settings', 'key', 'device_link_mode'),
    // IRD, IRD+GSM, GSM, GSM+IRD
    deviceNetworkModeTimeout: (0, _computedFindBy.default)('settings', 'key', 'device_link_mode_timeout_seconds'),
    quickReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'quick_position_seconds'),
    startMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_moving_enabled'),
    speedEnabled: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_enabled'),
    speedIntervalSeconds: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_interval_seconds'),
    speedTransitionTime: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_transition_seconds'),
    speedLimitKph: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_limit_kph'),
    rolloverEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_rollover_enabled'),
    crashEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_crash_enabled'),
    idlingEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_idling_enabled'),
    idlingDelaySeconds: (0, _computedFindBy.default)('settings', 'key', 'event_idling_delay_seconds'),
    idlingIntervalSeconds: (0, _computedFindBy.default)('settings', 'key', 'event_idling_interval_seconds'),
    idlingIntervalEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_idling_interval_enabled'),
    obdDtcEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_obd_dtc_enabled'),
    shutdownTimeout: (0, _computedFindBy.default)('settings', 'key', 'shutdown_timeout_seconds'),
    shutdownReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'normal_reporting_shutdown_enabled'),
    internalSpeakerEnabled: (0, _computedFindBy.default)('settings', 'key', 'internal_speaker_enabled'),
    bluetoothPairingCode: (0, _computedFindBy.default)('settings', 'key', 'bluetooth_pairing_code'),
    bluetoothPairingCodeEnabled: (0, _computedFindBy.default)('settings', 'key', 'bluetooth_pairing_code_enabled')
  });

  _exports.default = _default;
});