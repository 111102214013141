define("sky-router-3/pods/components/input-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xoIRGoMZ",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[2,\" \"],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"-track-array\",\"each\"]}",
    "moduleName": "sky-router-3/pods/components/input-errors/template.hbs"
  });

  _exports.default = _default;
});