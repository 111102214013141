define("sky-router-3/pods/portal/track/message/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    message: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    queryParams: ['assets']
  });

  _exports.default = _default;
});