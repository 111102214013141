define("sky-router-3/mixins/google-marker-auto-hide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Calculates the markers visibility based on allowed zoom level and hides
   * the marker if the zoom level is not acceptable. There by improving performance.
   * @see https://stackoverflow.com/questions/20044308/google-maps-api-3-show-hide-markers-depending-on-zoom-level
   */
  var _default = Ember.Mixin.create({
    /**
     * Holds the event listener that attaches to the map
     * @type {google.maps.MapsEventListener}
     */
    _boundsVisibilityMapListener: null,

    /**
     * Indicates if the marker is currently in the map viewport
     * @type {Boolean}
     */
    inViewport: null,

    /**
     * The zoom levels in which the markers will be hidden below.
     * This assumes that as you zoom out you want to hide markers
     * to improve performance.
     * @default 4 - Visibile at continential view and below
     * @type {number}
     */
    minZoomLevel: 4,
    didReceiveAttrs: function didReceiveAttrs() {
      this._boundsVisibilityMapListener = this.map.addListener('idle', Ember.run.bind(this, this._calculateBoundsVisibility));

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._boundsVisibilityMapListener != null) {
        this._boundsVisibilityMapListener.remove();

        this._boundsVisibilityMapListener = null;
      }

      this._super.apply(this, arguments);
    },
    _calculateBoundsVisibility: function _calculateBoundsVisibility() {
      var marker = this.googlePropertyObject;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!marker) {
        return;
      }

      var position = marker.getPosition();

      if (!position) {
        return;
      }

      var bounds = this.map.getBounds();

      if (!bounds) {
        return;
      }

      var visible = marker.getVisible();
      var zoom = this.map.getZoom();
      var contains = bounds.contains(position) && zoom >= this.minZoomLevel;

      if (contains && !visible) {
        marker.setVisible(true);
        this.set('inViewport', true);
      } else if (!contains && visible) {
        marker.setVisible(false);
        this.set('inViewport', false);
      }
    }
  });

  _exports.default = _default;
});