define("sky-router-3/pods/components/portal/track/map/overlays/image-tile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8dcd2q42",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"attribution\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"placement\",\"map\"],[\"BOTTOM_RIGHT\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[2,[35,0,[\"attribution\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dataObject\",\"map\",\"portal/track/map/map-button\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/overlays/image-tile/template.hbs"
  });

  _exports.default = _default;
});