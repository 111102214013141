define("sky-router-3/utils/load-google-map", ["exports", "jquery", "sky-router-3/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadGoogleMap;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var promise;
  /**
   * Loads the google map SDK and any additional google map plugin files.
   * This is loosly based off of ember-google-map plugin
   * @see https://github.com/huafu/ember-google-map/blob/master/addon/utils/load-google-map.js
   *
   * @return {Ember.RSVP.Promise}
   */

  function loadGoogleMap(options) {
    // Check if google maps is already loaded
    if ((typeof google === "undefined" ? "undefined" : _typeof(google)) === 'object' && _typeof(google.maps) === 'object') {
      return Ember.RSVP.resolve();
    } // Check if we are already trying to load it.


    if (promise) {
      return promise;
    } // Load the map


    promise = new Ember.RSVP.Promise(function (resolve, reject) {
      // Create the callback that executes when google is ready
      window.__emberGoogleMapLoaded__ = Ember.run.bind(function () {
        // Get the remaining scripts that are dependent on google being present
        _jquery.default.when().done(function () {
          promise = null;
          window.__emberGoogleMapLoaded__ = null;
          resolve();
        }).fail(function (err) {
          promise = null;
          window.__emberGoogleMapLoaded__ = null;
          reject(err);
        });
      }); // Set the default options

      Ember.assign(_environment.default.googleMaps.options, options, {
        callback: '__emberGoogleMapLoaded__'
      });

      var params = _jquery.default.param(_environment.default.googleMaps.options).replace('%2C', ','); // Execute the get


      _jquery.default.getScript(_environment.default.googleMaps.url + '?' + params).fail(function (err) {
        promise = null;
        window.__emberGoogleMapLoaded__ = null;
        reject(err);
      });
    }); // Return the active promise

    return promise;
  }
});