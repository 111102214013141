define("sky-router-3/pods/components/selectall-dropdownlist/component", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Defines a toggle all checkbox drop down list which uses the bootstrap button menu drop down as a base.
   * @property items - Array of objects that contain a text (string), value (string) and checked (boolean) properties to populate the drop down with
   * @property listName - The list type to use for the toggle button of the dropdown
   * @example {{checkbox-dropdownlist id="DisplayedColumnsDropDown" tagName='div' items=model.displayedColumns buttontext='Displayed Columns'}}
   */
  var _default = Ember.Component.extend({
    classNames: ['dropdown'],

    /**
     * When item or items are checked this action is fired
     */
    onChange: function onChange()
    /*items*/
    {},

    /**
     * Are all the items checked
     * @return {Boolean}
     */
    allChecked: Ember.computed('optionsFiltered.[]', 'selected.[]', function () {
      return this.get('optionsFiltered.length') == this.get('selected.length');
    }),

    /**
     * Filter the list of items by a search field
     * @type {[type]}
     */
    searchField: null,
    searchValue: null,

    /**
     * Array of objects
     */
    options: null,
    optionsFiltered: Ember.computed('options.[]', 'searchValue', function () {
      var _this = this;

      if (!this.searchValue) {
        return this.get('options');
      }

      return this.get('options').filter(function (item) {
        return (0, _stringSearch.default)(item.get(_this.searchField), _this.searchValue);
      });
    }),

    /**
     * Contains a list of items that are checked
     * @type {Array<>}
     */
    selected: null,

    /**
     * The list type to use for the toggle button of the dropdown
     * @type {string}
     */
    listName: null,
    actions: {
      /**
       * Event handler for the toggle all checkbox. When clicked, the function will toggle all checkboxes in the dropdown list.
       * @return {void}
       */
      toggleAll: function toggleAll(checked) {
        this.onChange(checked, this.get('optionsFiltered'));
      },
      toggle: function toggle(item, checked) {
        this.onChange(checked, [item]);
      }
    }
  });

  _exports.default = _default;
});