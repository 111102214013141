define("sky-router-3/helpers/type-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function typeOfHelper(params) {
    var type = Ember.typeOf(params[0]);

    if (params.length > 1 && params[1]) {
      return type == params[1];
    }

    return type;
  });

  _exports.default = _default;
});