define("sky-router-3/pods/portal/manage/burst-services/geo-area-group/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    permissions: ['manage.burstservices'],
    intl: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        geoAreaGroups: this.store.findAll('burst-service-geo-area-group')
      });
    },
    actions: {
      deleteGeoAreaGroup: function deleteGeoAreaGroup(geoGroup) {
        var _this = this;

        if (confirm(this.intl.t('burstServices.deleteConfirm', {
          name: geoGroup.get('name')
        }))) {
          geoGroup.destroyRecord().then(function () {
            _this.notifications.warning(_this.intl.t('burstServices.deleteSuccess'), {
              clearDuration: 5000
            });
          }).catch(function () {
            _this.notifications.error(_this.intl.t('misc.failedOperation'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});