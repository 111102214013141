define("sky-router-3/services/api", ["exports", "@ember-data/adapter/error", "sky-router-3/config/environment", "ember-ajax/services/ajax"], function (_exports, _error, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-env node */

  /**
   * This service handles making ajax requests to the BsnWebApi that are
   * not inside the ember-data `store` service.
   */
  var _default = _ajax.default.extend({
    /**
     * Injects the session service that holds authentication information.
     * @return {sky-router-3/models/session}
     */
    session: Ember.inject.service(),

    /**
     * The users session service. Be aware that the user object is not
     * guaranteed to exist.
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Holds a reference to the API namespace which is the part of
     * the path the api lives at after the rootURL of the app
     */
    namespace: _environment.default.API.namespace,

    /**
     * Downloads a file and loads into the browser so it can present the file to the user.
     * @param  {[type]} fileName     [description]
     * @param  {[type]} url          [description]
     * @param  {Object} [options={}] [description]
     * @return {[type]}              [description]
     */
    download: function download(fileName, url) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options.dataType = 'binary';
      return this.request(url, options).then(function (response) {
        // If IE, download the file this way. Security for IE doesn't allow javascript click downloads
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response, fileName);
        } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(response);
          a.download = fileName; // Append anchor to body.

          document.body.appendChild(a);
          a.click(); // Remove anchor from body

          document.body.removeChild(a);
        }

        return response;
      });
    },

    /***
     * Authenticates a user
     * @function
     * @param {string} - username to authenticate
     * @param {string} - password to authenticate
     * @returns {jQuery.Promise} - promise to make the ajax request
     * @see '/Help/Api/POST-Token'
     */
    userAuthenticate: function userAuthenticate(username, password) {
      return this.post('Token', {
        data: {
          username: username,
          password: password
        }
      });
    },

    /**
     * Checks the authentication of a currently authenticated user
     * and returns a new session token.
     * @function
     * @returns {jQuery.Promise} promise to make the request
     * @see '/Help/Api/GET-Token'
     */
    userAuthenticateRefresh: function userAuthenticateRefresh() {
      return this.post('Token/Refresh');
    },

    /**
     * Checks the users authentication token for validity on the server.
     * @return {Ember.RSVP.Promise}
     */
    userAuthenticateCheck: function userAuthenticateCheck(userid, authToken) {
      return this.request("Token/Check?userId=".concat(userid), {
        headers: {
          'X-T': authToken
        }
      });
    },

    /**
     * asks the server to destroy the users login session.
     * @return {Ember.RSVP.Promise}
     */
    userAuthenticateLogout: function userAuthenticateLogout() {
      return this.del('token');
    },

    /**
     * Requests that the users password be reset
     * @function
     * @returns {jQuery.Promise} promise to make the request
     * @see 'Help/Api/POST-User-username-ResetPassword_email'
     */
    userResetPassword: function userResetPassword(username, email) {
      return this.post("User/".concat(encodeURIComponent(username), "/Password/Reset"), {
        data: {
          email: email
        }
      });
    },

    /**
     * Requests that the users username be sent
     * @function
     * @returns {jQuery.Promise} promise to make the request
     * @see 'Help/Api/POST-User-username-SendUsername_email'
     */
    sendUsernameEmail: function sendUsernameEmail(email) {
      return this.post("User/SendUsername", {
        data: {
          email: email
        }
      });
    },

    /**
     * Request 2FA to be activated for a given user
     * @returns {jQuery.Promise} promise to make the request
     */
    userVerifyTwoFactor: function userVerifyTwoFactor(username, password, token) {
      return this.post("AuthyUsers/Validate", {
        data: {
          username: username,
          password: password,
          token: token
        }
      });
    },
    getTwoFactorAuthURL: function getTwoFactorAuthURL() {
      return this.request("AuthyUser/GetUserURL");
    },

    /**
     * Changes a users Password
     * @param  {Number} The id as defined in the user model
     * @param  {string} oldPassword the users old password
     * @param  {string} newPassword the users new password
     * @return {Ember.RSVP.Promise}   Promise to complete the request
     */
    userChangePassword: function userChangePassword(id, oldPassword, newPassword) {
      return this.put("User/".concat(id, "/Password"), {
        data: {
          OldPassword: oldPassword,
          NewPassword: newPassword
        }
      });
    },

    /**
     * Gets the user's support pin
     */
    getUserSupportPin: function getUserSupportPin() {
      return this.request("SupportPin");
    },

    /**
     * Changes the user's support pin
     * User has to be logged in to change this
     */
    userChangeSupportPin: function userChangeSupportPin(pin) {
      return this.put("SupportPin/".concat(pin));
    },

    /**
     * Gets the users unlock question from the API
     * @function
     * @returns {jQuery.Promise} promise to make the request
     * @see TODO
     */
    userGetUnlockQuestion: function userGetUnlockQuestion(username) {
      return this.request("User/".concat(encodeURIComponent(username), "/Unlock"));
    },

    /**
     * Sets the users unlock question into the API
     * @param  {string} username  name of the user to change. This must be the same
     *                            as the current session user.
     * @param  {string} question The new security question for the user.
     * @param  {string} answer   the new security answer for the user.
     * @param  {string} currentPassword     The users current password.
     * @return {Ember.RSVP.Promise}        Promise to complete the request.
     */
    userSetUnlockQuestion: function userSetUnlockQuestion(username, question, answer, currentPassword) {
      return this.put("User/".concat(encodeURIComponent(username), "/Unlock"), {
        data: {
          Question: question,
          Answer: answer,
          Password: currentPassword
        }
      });
    },

    /**
     * Attempts to unlock a user by requesting it from the api
     * @function
     * @returns {jQuery.Promise} promise to make the request
     * @see TODO
     */
    userUnlock: function userUnlock(username, answer) {
      return this.post('User/' + username + '/Unlock/Try', {
        data: {
          Answer: answer
        }
      });
    },

    /**
     * Force unlocks a user that an admin wishes to unlock.
     * @param  {string} username
     * @return {Ember.RSVP.Promise} promise to make the request
     */
    userAdminUnlock: function userAdminUnlock(username) {
      return this.post("User/".concat(encodeURIComponent(username), "/Unlock/AdminForce"));
    },

    /**
     * Put key to update data
     * @param  {BsnWebApi.Models.UserAPIKeyAuth} keydata temp data store for key
     * @return {No Content}
     */
    userPutAPIKey: function userPutAPIKey(keyid, keydata) {
      return this.put("UserAPIKey/".concat(keyid), {
        data: {
          userAPIKey: keydata
        }
      });
    },

    /**
     * Gets a randomly generated user api key
     * @return {int} 6 digit random generated key
     */
    userGenerateAPIKeyAuth: function userGenerateAPIKeyAuth() {
      return this.request("UserAPIKeyAuth/Generate");
    },

    /**
     * Post key to temp data
     * @param  {BsnWebApi.Models.UserAPIKeyAuth} keydata temp data store for key
     * @return {No Content}
     */
    userPostAPIKeyAuth: function userPostAPIKeyAuth(data) {
      return this.post('UserAPIKeyAuth', {
        data: data
      });
    },

    /**
     * Returns the current time from the server.
     * @return {Ember.RSVP.Promise} Resolves with a javascript string ISO date.
     */
    getServerTime: function getServerTime() {
      return this.request('System/Time');
    },

    /**
     * Takes in an HTML File input object and uploads the file to the server
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    uploadSound: function uploadSound(file, description) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();

      if (description) {
        formData.append('description', description);
      }

      formData.append('sound', file, file.name);
      return this.post('UserSounds', {
        data: formData,
        processData: false,
        contentType: false
      });
    },

    /**
     * Takes in an HTML File input object and uploads the file to the server
     * @param  {[type]} file [description]
     * @return {[type]}      [description]
     */
    convertXml: function convertXml(file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('file', file, file.name);
      return this.post('fileconvert/xml-to-json', {
        data: formData,
        processData: false,
        contentType: false
      });
    },

    /**
     * Instructs the API to revert an asset position type back to its default
     * values.
     * @param {String} id sky-router-3/models/asset-position-type identifier
     * @return {Ember.RSVP.Promise} Promise to complete the request
     */
    resetAssetPositionType: function resetAssetPositionType(id) {
      return this.put("AssetPositionTypes/".concat(id, "/Reset"));
    },

    /**
     * Instructs the API to initiate a ping of an asset.
     * @param {String} id sky-router-3/models/asset/id
     */
    pingAsset: function pingAsset(id) {
      return this.post("Assets/".concat(id, "/Ping"));
    },

    /**
     * Renames a folder with the specified id to a new name.
     * Renaming the folder also means that the old folderId is no longer valid.
     * @param  {String} folderId Id as defined in sky-router-3/models/mail-folder
     * @param  {String} name     New name to make the folder
     * @return {Ember.RSVP.Promise}
     */
    mailFolderRename: function mailFolderRename(folderId, name) {
      return this.post("MailFolders/".concat(encodeURIComponent(folderId), "/Rename"), {
        data: {
          Name: name
        }
      });
    },

    /**
     * Moves a message to a different folder.
     * @NOTE: This changes the message identifier.
     * @NOTE: Messages cannot be moved to or from the drafts folder.
     * @NOTE: Messages cannot be moved to or from the sent folder.
     * @param  {String} messageId   Message identifier
     * @param  {String} newFolderId identifier for the new folder
     * @return {Ember.RSVP.Promise}
     */
    mailMessageMove: function mailMessageMove(messageId, newFolderId) {
      return this.post("MailMessages/".concat(encodeURIComponent(messageId), "/Move"), {
        data: {
          Folder: newFolderId
        }
      });
    },

    /**
     * Deletes a mail message
     * @param  {String} messageId   Message identifier
     * @param  {String} newFolderId identifier for the new folder
     * @return {Ember.RSVP.Promise}
     */
    mailMessageDelete: function mailMessageDelete(messageId) {
      return this.del("MailMessages/".concat(encodeURIComponent(messageId)));
    },

    /**
     * Sends a mail message to the the recipients
     * @param  {sky-router-3/models/mail-message} message
     * @return {Ember.RSVP.Promise}
     */
    mailMessageSend: function mailMessageSend(data) {
      return this.post("MailMessages/Send", {
        data: data
      });
    },

    /**
     * Gets the MailFolderIdentifier that maps to the internalId
     * @param {Ember.RSVP.Promise} recordId
     */
    mailFolderFromPrivateId: function mailFolderFromPrivateId(internalId) {
      return this.request("MailFolders/FolderFromInternalId", {
        data: {
          internalId: internalId
        }
      });
    },

    /**
     * Updates the flags on all unread messages in the folder to be read.
     * @return {Ember.RSVP.Promise}
     */
    mailFolderMarkAllAsRead: function mailFolderMarkAllAsRead(folderId) {
      return this.post("MailFolders/".concat(encodeURIComponent(folderId), "/MarkAllAsRead"));
    },

    /**
     * Logs an error up to the API.
     * @param  {Error} error Exception error object
     * @return {Ember.RSVP.Promise}
     */
    logException: function logException(error) {
      if (!_environment.default.APP.reportErrors) {
        return Ember.RSVP.resolve();
      }

      if (this.shouldSuppressException(error)) {
        console.error('Suppressing error', error);
        return Ember.RSVP.resolve();
      }

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return this.post("Error", {
        data: {
          Message: error.message,
          Name: error.name,
          Application: 'SkyRouter3@' + _environment.default.APP.version,
          StackTrace: error.stack,
          ErrorNumber: error.number || error.code,
          UserId: this.get('sessionAccount.userId'),
          Username: this.get('sessionAccount.user.username'),
          LocalDate: new Date().toJSON(),
          Raw: JSON.stringify({
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            args: args,
            location: window.location,
            Config: _environment.default,
            localStorage: localStorage,
            cookies: document.cookie
          })
        }
      });
    },

    /**
     * Logs an support ticker request up to the API.
     * @param  {Error} error Exception error object
     * @return {Ember.RSVP.Promise}
     */
    logTicket: function logTicket(Message, Subject) {
      return this.post("Error/Ticket", {
        data: {
          Message: Message,
          Subject: Subject,
          Application: 'SkyRouter3@' + _environment.default.APP.version,
          LocalDate: new Date().toJSON()
        }
      });
    },

    /**
     * Holds errors that have been let through by the `shouldSuppressException`
     * But should be suppressed on later executions.
     * @type {Array}
     */
    _suppressErrors: [],

    /**
     * Helps the logException method determine what should be reported and what should not be
     * @return {Boolean}   True if the error should be suppressed.
     */
    shouldSuppressException: function shouldSuppressException(error) {
      if (error instanceof _error.AbortError) {
        return true;
      }

      switch (error.status) {
        case 0: // Suppress odd ass sdtatus 0 errors.

        case 401:
        case 402:
        case 403:
        case 404: // Suppress random 404's that can't be correlated to anything

        case 502:
          // Stop proxies from puking errors
          return true;
      }

      switch (Ember.get(error, 'errors.firstObject.status') || Ember.get(error, 'payload.firstObject.status')) {
        case 0: // don't log errors from odd ass status 0 errors

        case 401: // don't log 401 errors because they happen when the user becomes locked out or something else

        case 402: // don't log 402 errors because they happen when the user needs to pass two factor auth

        case 403: // don't log 403 errors because they occur during normal operation.

        case 404: // don't log 404 because its not an error and the API regularly produces these.

        case 502:
          // Stop proxies from puking errors
          return true;
      }

      switch (error.number || error.code) {
        case -2147467263: // don't log errors that are for the "Not implemented" exception

        case -2147023878: // don't log random ass ie error about illegal operation

        case -2147024882: // don't log "Could not complete the operation due to error 8007000e."

        case -2146823281: // don't log "Unable to set property 'seen' of undefined or null reference"

        case -2146828281: // don't log "Out of memory"

        case -2147467259: // don't log "Unspecified error"

        case -2147352573:
          // don't log "Member not found"
          return true;

        default:
          // Prevent being spammed by the same error code over and over
          if (!isNaN(parseInt(error.number || error.code))) {
            if (this._suppressErrors.includes(error.number || error.code)) {
              return true;
            }

            this._suppressErrors.push(error.number || error.code);
          }

          break;
      } // TODO: Supporess this because glimmer needs to fix this. https://github.com/glimmerjs/glimmer-vm/issues/484


      if (error.message == "a glimmer transaction was begun, but one already exists. You may have a nested transaction") {
        return true;
      }

      if (error.message == "Resource was not found.") {
        return true;
      } // Prevent being spammed by the same error code over and over


      if (this._suppressErrors.includes(error.message)) {
        return true;
      }

      this._suppressErrors.push(error.message);

      return false;
    },

    /**
     * Gets asset positions without interacting with ember data.
     * @param {Ember.RSVP.Promise} options
     */
    queryAssetPositions: function queryAssetPositions(options) {
      return this.request('AssetPositions', {
        data: options
      });
    },

    /**
     * Gets the Position History Report Data
     * @param  {int} rowCount              The count of records on the page
     * @param  {int} page                  The page for the result set
     * @param  {String} devicesList        Comma separated list of devices
     * @param  {String} eventsList         Comma separated list of event ids
     * @param  {String} visibleColumnsList Comma separated list of visible columns
     * @param  {Date} startDate            The start date of the query
     * @param  {Date} endDate              The end date of the query
     * @param  {int} altitudeOption        The altitude option to use in the query results
     * @param  {int} speedOption           The speed option to use in the query results
     * @param  {int} utcOffset             The utc offset for the event timestamps
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getPositionReportHistoryData: function getPositionReportHistoryData(rowCount, page, devicesList, eventsList, visibleColumnsList, startDate, endDate, altitudeOption, speedOption, utcOffset, minLat, minLng, maxLat, maxLng) {
      return this.request("PositionReportHistory", {
        data: {
          rows: rowCount,
          page: page,
          devicesList: devicesList,
          eventsList: eventsList,
          visibleColumnsList: visibleColumnsList,
          startDate: startDate,
          endDate: endDate,
          altitudeOption: altitudeOption,
          speedOption: speedOption,
          utcOffset: utcOffset,
          minLat: minLat,
          minLng: minLng,
          maxLat: maxLat,
          maxLng: maxLng
        }
      });
    },

    /**
     * Gets the Position History Report
     * @param  {String} devicesList        Comma separated list of devices
     * @param  {String} eventsList         Comma separated list of event ids
     * @param  {String} visibleColumnsList Comma separated list of visible columns
     * @param  {Date} startDate            The start date of the query
     * @param  {Date} endDate              The end date of the query
     * @param  {int} altitudeOption        The altitude option to use in the query results
     * @param  {int} speedOption           The speed option to use in the query results
     * @param  {int} utcOffset             The utc offset for the event timestamps
     * @param  {String} exportType         The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getPositionReportHistoryReport: function getPositionReportHistoryReport(fileName, devicesList, eventsList, visibleColumnsList, startDateString, endDateString, altitudeOption, speedOption, utcOffset, exportType, minLat, minLng, maxLat, maxLng) {
      return this.download(fileName, 'PositionReportHistory/ExportReport', {
        data: {
          devicesList: devicesList,
          eventsList: eventsList,
          visibleColumnsList: visibleColumnsList,
          startDateString: startDateString,
          endDateString: endDateString,
          altitudeOption: altitudeOption,
          speedOption: speedOption,
          utcOffset: utcOffset,
          exportType: exportType,
          minLat: minLat,
          minLng: minLng,
          maxLat: maxLat,
          maxLng: maxLng
        }
      });
    },

    /**
     * Gets the Group Association Report
     * @param  {String} exportType         The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getGroupAssociationReport: function getGroupAssociationReport(exportType) {
      return this.request("GroupAssociation/ExportReport?exportType=".concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the Group Association Report
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getAssociationGroupReport: function getAssociationGroupReport() {
      return this.request('GroupAssociation/Groups');
    },

    /**
     * Gets the Devices Association Report
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getAssociationDevicesReport: function getAssociationDevicesReport() {
      return this.request('GroupAssociation/Devices');
    },

    /**
     * Gets the Users Association Report
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getAssociationUsersReport: function getAssociationUsersReport() {
      return this.request('GroupAssociation/Users');
    },

    /**
     * Gets the Device Inventory Report Data
     * @param  {String} visibleColumnsList Comma separated list of visible columns
     * @param  {String} sortString         The sort string for the query
     * @param  {String} exportType         The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getDeviceInventoryReportData: function getDeviceInventoryReportData(visibleColumnsList) {
      return this.request("DeviceInventory?visibleColumnsList=".concat(visibleColumnsList));
    },

    /**
     * Gets the Device Inventory Report
     * @param  {String} visibleColumnsList Comma separated list of visible columns
     * @param  {String} sortString         The sort string for the query
     * @param  {String} exportType         The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getDeviceInventoryReport: function getDeviceInventoryReport(visibleColumnsList, sortString, exportType) {
      return this.request("DeviceInventory/ExportReport?visibleColumnsList=".concat(visibleColumnsList, "&sortString=").concat(sortString, "&exportType=").concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the valid Invoice Usage dates
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageDates: function getInvoiceUsageDates() {
      return this.request("UsageInvoiceDates", {
        processData: false
      });
    },

    /**
     * Gets the Invoice Usage SBD Summary Report Data
     * @param  {String} reportDate   The report date of the invoice
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageSBDSummaryReportData: function getInvoiceUsageSBDSummaryReportData(rowCount, page, reportDate) {
      return this.request("InvoiceUsageSummarySBD?rows=".concat(rowCount, "&page=").concat(page, "&reportDate=").concat(reportDate));
    },

    /**
     * Gets the Invoice Usage SBD Summary Report
     * @param  {String} reportDate   The report date of the invoice
     * @param  {String} exportType   The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageSBDSummaryReport: function getInvoiceUsageSBDSummaryReport(reportDate, exportType) {
      return this.request("InvoiceUsageSummarySBD/ExportReport?reportDate=".concat(reportDate, "&exportType=").concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the Invoice Usage Voice Summary Report Data
     * @param  {String} reportDate   The report date of the invoice
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageVoiceSummaryReportData: function getInvoiceUsageVoiceSummaryReportData(rowCount, page, reportDate) {
      return this.request("InvoiceUsageSummaryVoice?rows=".concat(rowCount, "&page=").concat(page, "&reportDate=").concat(reportDate));
    },

    /**
     * Gets the Invoice Usage Voice Summary Report
     * @param  {String} reportDate   The report date of the invoice
     * @param  {String} exportType   The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageVoiceSummaryReport: function getInvoiceUsageVoiceSummaryReport(reportDate, exportType) {
      return this.request("InvoiceUsageSummaryVoice/ExportReport?reportDate=".concat(reportDate, "&exportType=").concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the Invoice Usage SBD Report Data
     * @param  {String} reportDate   The report date of the invoice
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageSBDReportData: function getInvoiceUsageSBDReportData(rowCount, page, reportDate) {
      return this.request("InvoiceUsageSBD?rows=".concat(rowCount, "&page=").concat(page, "&reportDate=").concat(reportDate));
    },

    /**
     * Gets the Invoice Usage SBD Report
     * @param  {String} reportDate   The report date of the invoice
     * @param  {String} exportType   The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageSBDReport: function getInvoiceUsageSBDReport(reportDate, exportType) {
      return this.request("InvoiceUsageSBD/ExportReport?reportDate=".concat(reportDate, "&exportType=").concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the Invoice Usage Voice Report Data
     * @param  {String} reportDate   The report date of the invoice
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageVoiceReportData: function getInvoiceUsageVoiceReportData(rowCount, page, reportDate) {
      return this.request("InvoiceUsageVoice?rows=".concat(rowCount, "&page=").concat(page, "&reportDate=").concat(reportDate));
    },

    /**
     * Gets the Invoice Usage Voice Report
     * @param  {String} reportDate   The report date of the invoice
     * @param  {String} exportType   The type of export
     * @returns {Ember.RSVP.Promise} promise to complete the work.
     */
    getInvoiceUsageVoiceReport: function getInvoiceUsageVoiceReport(reportDate, exportType) {
      return this.request("InvoiceUsageVoice/ExportReport?reportDate=".concat(reportDate, "&exportType=").concat(exportType), {
        dataType: 'binary',
        processData: false
      });
    },

    /**
     * Gets the Device History Report Data
     * @param  {int} rows                    The count of records on the page
     * @param  {int} page                    The page for the result set
     * @param  {long} imei                   The device imei
     * @param  {date} startDate              The start date to query
     * @param  {date} endDate                The end date to query
     * @param  {csv string} entryTypesList   The entry types to include in the results
     * @param  {csv string} sourceTypesList  The source types to include in the results
     * @param  {int} utcOffset               The utc offset for the event timestamps
     * @return {Ember.RSVP.Promise} promise to complete the work.
     */
    getDeviceHistoryReportData: function getDeviceHistoryReportData(rows, page, imei, startDate, endDate, entryTypesList, sourceTypesList, utcOffset) {
      return this.request('DeviceHistoryReport', {
        data: {
          rows: rows,
          page: page,
          startDate: startDate,
          endDate: endDate,
          utcOffset: utcOffset,
          imei: imei,
          entryTypesList: !entryTypesList ? undefined : entryTypesList,
          sourceTypesList: !sourceTypesList ? undefined : sourceTypesList
        }
      });
    },

    /**
     * Gets the Device History Report
     * @param  {long} imei                    The device imei
     * @param  {date} startDate              The start date to query
     * @param  {date} endDate                The end date to query
     * @param  {csv string} entryTypesList   The entry types to include in the results
     * @param  {csv string} sourceTypesList  The source types to include in the results
     * @param  {String} sortString           The sort string for the query
     * @param  {int} utcOffset               The utc offset for the event timestamps
     * @param  {String} exportType           The type of export
     * @return {Ember.RSVP.Promise} promise to complete the work.
     */
    getDeviceHistoryReportExport: function getDeviceHistoryReportExport(fileName, imei, startDate, endDate, entryTypesList, sourceTypesList, sortString, utcOffset, exportType) {
      return this.download(fileName, 'DeviceHistoryReport/ExportReport', {
        data: {
          startDate: startDate,
          endDate: endDate,
          exportType: exportType,
          sortString: sortString,
          utcOffset: utcOffset,
          imei: imei,
          entryTypesList: !entryTypesList ? undefined : entryTypesList,
          sourceTypesList: !sourceTypesList ? undefined : sourceTypesList
        }
      });
    },

    /**
     * Gets the Playback Trips Report Data
     * @param  {int} rows               The count of records on the page
     * @param  {int} page               The page for the result set
     * @param  {String} device          The IMEI of the device for the report
     * @param  {date} startDate         The start date of the query
     * @param  {date} endDate           The end date of the query
     * @param  {bool} completedTrips    Only completed trips or include partial
     * @param  {int} utcOffset          The utc offset for the event timestamps
     * @return {Ember.RSVP.Promise} promise to complete the work.
     */
    getPlaybackTripsReportData: function getPlaybackTripsReportData(rows, page, device, startDate, endDate, completedTrips, utcOffset) {
      return this.request("PlaybackTrips?rows=".concat(rows, "&page=").concat(page, "&IMEI=").concat(device, "&startTime=").concat(startDate, "&endTime=").concat(endDate, "&completedTripsOnly=").concat(completedTrips, "&utcOffset=").concat(utcOffset));
    },

    /**
     * Gets the Playback Trips Report
     * @param  {String} device          The IMEI of the device for the report
     * @param  {date} startDate         The start date of the query
     * @param  {date} endDate           The end date of the query
     * @param  {bool} completedTrips    Only completed trips or include partial
     * @param  {int} utcOffset          The utc offset for the event timestamps
     * @param  {int} exportType         The type of export
     * @return {Ember.RSVP.Promise} promise to complete the work.
     */
    getPlaybackTripsReportExport: function getPlaybackTripsReportExport(fileName, device, startTime, endTime, completedTripsOnly, utcOffset, exportType) {
      return this.download(fileName, 'PlaybackTrips/ExportReport', {
        data: {
          IMEI: device,
          startTime: startTime,
          endTime: endTime,
          completedTripsOnly: completedTripsOnly,
          utcOffset: utcOffset,
          exportType: exportType
        }
      });
    },

    /**
     * Gets the Submitted Forms Report Data
     * @param  {int} rows                    The count of records on the page
     * @param  {int} page                    The page for the result set
     * @param  {int} formId                  The form temlate id for the report
     * @param  {date} startDate              The start date to query
     * @param  {date} endDate                The end date to query
     * @param  {int} utcOffset               The utc offset for the event timestamps
     * @return {Ember.RSVP.Promise}          promise to complete the work.
     */
    getSubmittedFormsReportData: function getSubmittedFormsReportData(rows, page, formId, startDate, endDate, utcOffset) {
      return this.request("SubmittedFormsReport?rows=".concat(rows, "&page=").concat(page, "&startDate=").concat(startDate, "&endDate=").concat(endDate, "&utcOffset=").concat(utcOffset, "&identifier=").concat(formId));
    },

    /**
     * Gets the Submitted Forms Report
     * @param  {int} formId                  The form temlate id for the report
     * @param  {date} startDate              The start date to query
     * @param  {date} endDate                The end date to query
     * @param  {int} utcOffset               The utc offset for the event timestamps
     * @param  {String} exportType           The type of export
     * @return {Ember.RSVP.Promise}          promise to complete the work.
     */
    getSubmittedFormsReportExport: function getSubmittedFormsReportExport(fileName, formId, startDate, endDate, utcOffset, exportType) {
      return this.download(fileName, 'SubmittedFormsReport/ExportReport', {
        data: {
          startDate: startDate,
          endDate: endDate,
          exportType: exportType,
          utcOffset: utcOffset,
          identifier: formId
        }
      });
    },

    /**
     * Gets the Submitted Forms Columns for the report
     * @param  {int} formId                  The form temlate id for the report
     * @return {Ember.RSVP.Promise}          promise to complete the work.
     */
    getSubmittedFormsColumns: function getSubmittedFormsColumns(formId) {
      return this.request("SubmittedFormsReport/Columns/".concat(formId));
    },

    /**
     * Gets the Interaction Log report data
     * @param  {int} rows                    The count of records on the page
     * @param  {int} page                    The page for the result set
     * @param  {int} user                    The user for the report
     * @param  {date} startDate              The start date to query
     * @param  {date} endDate                The end date to query
     * @param  {int} utcOffset               The utc offset for the event timestamps
     */
    getInteractionLogReportData: function getInteractionLogReportData(rows, page, user, startDate, endDate, utcOffset) {
      return this.request('InteractionLogReport', {
        data: {
          rows: rows,
          page: page,
          startDate: startDate,
          endDate: endDate,
          utcOffset: utcOffset,
          user: user
        }
      });
    },

    /**
     * Upload CSV of locations into the system
     */
    locationBulkUpload: function locationBulkUpload(file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('csv', file, file.name);
      return this.post('Locations/BulkImport', {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    saveLocationTypeIcon: function saveLocationTypeIcon(typeId, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('img', file, file.name);
      return this.post("LocationTypes/".concat(typeId, "/Icon"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    uploadFormTemplate: function uploadFormTemplate(templateId, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('template', file, file.name);
      return this.put("FormTemplates/".concat(templateId, "/File"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    uploadAssetIcon: function uploadAssetIcon(iconId, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('icon', file, file.name);
      return this.put("AssetIcons/".concat(iconId, "/Icon"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    uploadLocationImage: function uploadLocationImage(locationId, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('icon', file, file.name);
      return this.put("Locations/".concat(locationId, "/Photo"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    uploadOverlayFile: function uploadOverlayFile(overlay_id, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('file', file, file.name);
      return this.put("Overlays/".concat(overlay_id, "/File"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },
    uploadAssetPhoto: function uploadAssetPhoto(asset_id, file) {
      // Create the FormData upload object
      // See: https://developer.mozilla.org/en-US/docs/Web/API/FormData
      var formData = new FormData();
      formData.append('photo', file, file.name);
      return this.put("Assets/".concat(asset_id, "/Photo"), {
        data: formData,
        processData: false,
        contentType: false
      });
    },

    /**
     * Gets an array of phone numbers that are used to send out alerts.
     * @return {Array} Array of string phone numbers
     */
    getAlertDefinitionFromPhoneNumbers: function getAlertDefinitionFromPhoneNumbers() {
      return this.request("AlertDefinitions/GetFromPhoneNumbers");
    },

    /**
     * Gets the permission roles that the user in question has assigned to them.
     * @param  {Int} id  The user id that you wish to get permission roles for.
     * @param {Boolean} assignableOnly  Only get permission roles that can be assigned
     * @return {Ember.RSVP.Promise<Array>}  An array of roles that the user has assigned
     */
    getUserPermissionRoles: function getUserPermissionRoles(id) {
      var assignableOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      return this.request("Users/".concat(id, "/PermissionRoles?assignableOnly=").concat(assignableOnly));
    },

    /**
     * Sets the permission roles for a user.
     * Note that you cannot set the permission roles for the calling user.
     * @param {Number} id    Identifier for the user to update
     * @param {Array<String>} roles Array of roles to replace the users current roles
     * @return {Ember.RSVP.Promise} Promise to complete the request.
     */
    setUserPermissionRoles: function setUserPermissionRoles(id, roles) {
      return this.put("Users/".concat(id, "/PermissionRoles"), {
        data: {
          roles: roles
        }
      });
    },

    /**
     * Gets the default role permissions for creating a new user.
     * @param  {Number} id  Identifier of the user calling this method
     * @return {Ember.RSVP.Promise}
     */
    getNewUserPermissionRoleDefaults: function getNewUserPermissionRoleDefaults(id) {
      return this.request("Users/".concat(id, "/PermissionRoles/NewUserDefaults"));
    },

    /**
     * Gets if the username in question is in use.
     * @param  {String} username        the username to check
     * @return {Ember.RSVP.Promise}         Promise that returns a `BooleanResult`
     *                                      that indicates if it exists or not
     */
    getUsernameAvailable: function getUsernameAvailable(username) {
      return this.request("Users/".concat(encodeURIComponent(username), "/available"));
    },

    /**
     * Checks to see if the asset name in question is a valid new asset name
     * @param {Number} assetId Identifier for the sky-router-3/models/asset
     * @param {String} name    New proposed name for the asset
     * @return {Ember.RSVP.Promise}     Promise that resolved to a result that
     * has a property 'Code' which has the following valid values.
     *  0 - Passed validation
     *  1 - Name already in use
     *  2 - Name does not meet format requirements
     */
    getValidateAssetNameChange: function getValidateAssetNameChange(assetId, name) {
      if (assetId) {
        return this.request("Assets/".concat(assetId, "/ValidateNameChange?name=").concat(encodeURIComponent(name)));
      } else {
        return this.request("Assets/VAlidateName?name=".concat(encodeURIComponent(name)));
      }
    },

    /**
     * Triggers the API to send the currently assigned profile revision down
     * to the device. The revision can be assigned via the `asset-profile-set-asset` entity.
     * @return {Ember.RSVP.Promise}
     */
    sendProfileRevision: function sendProfileRevision(assetId) {
      return this.post("AssetProfileSetAssets/".concat(assetId, "/Send"));
    },

    /**
     * Triggers the API to send the currently assigned parameter revision down
     * to the device. The revision can be assigned via the `asset-parameter-set-asset` entity.
     * @return {Ember.RSVP.Promise}
     */
    sendParameterRevision: function sendParameterRevision(assetId) {
      return this.post("AssetParameterSetAssets/".concat(assetId, "/Send"));
    },

    /**
     * Triggers the API to send the assigned compound rule revision down to the device.
     * The revision can be assigned via the `asset-compount-rule-asset` entity.
     * This should be called anytime one of those records is modified. (add, update, delete)
     * @return {Ember.RSVP.Promise}
     */
    sendCompoundRuleRevision: function sendCompoundRuleRevision(assetCompoundRuleRecordId) {
      return this.post("AssetCompoundRuleAssets/".concat(assetCompoundRuleRecordId, "/Send"));
    },

    /**
     * Removes a temporary whitelist email from the list and adds it to the permanent email list
     * @param {Number} id The identifier for the temporary whitelist email
     * @param {String} emailAddress The email address for the temporary whitelist email
     * @param {Date} updateDateTime The last updated date time for the email address
     * @return {Ember.RSVP.Promise}
     */
    makeTempWhiteListPermanent: function makeTempWhiteListPermanent(id, emailAddress, updateDateTime) {
      return this.post("TemporalWhitelists/MakeEmailPermanent?Id=".concat(id, "&EmailAddress=").concat(emailAddress, "&UpdateDateTime=").concat(updateDateTime));
    },

    /**
     * Removes a temporary whitelist email from the list
     * @param {String} emailAddress The email address for the temporary whitelist email
     * @return {Ember.RSVP.Promise}
     */
    deleteWhiteListEmail: function deleteWhiteListEmail(emailAddress) {
      return this.post("TemporalWhitelists/DeleteEmailAddress?EmailAddress=".concat(encodeURIComponent(emailAddress)));
    },

    /**
     * Saves the permanent whitelist email addresses
     * @param {String} emailList The email address for the temporary whitelist email
     * @return {Ember.RSVP.Promise}
     */
    savePermanentEmailList: function savePermanentEmailList(emailList) {
      return this.put("Whitelists/SetList?List=".concat(encodeURIComponent(emailList)));
    },

    /**
     * Saves the permanent whitelist email addresses
     * @param {Boolean} isFeatureActive Is the temporal auto whitelist feature enabled or not
     * @return {Ember.RSVP.Promise}
     */
    saveAutoWhitelistEnabled: function saveAutoWhitelistEnabled(isFeatureActive) {
      return this.put("TemporalWhiteListControls/Set?IsFeatureActive=".concat(isFeatureActive));
    },

    /**
     * Updates a flight plan status property wich has some special requirements.
     * @param {Number} planId the identifier of the plan to update
     * @param {Number} statusId the identifier of the status to update the plan to
     * @param {Number} otherStatusId If the status is set to 'active'
     *                               this holds what the other plans will be set to.
     */
    updateFlightPlanStatus: function updateFlightPlanStatus(planId, statusId, otherStatusId) {
      return this.post("FlightPlans/".concat(planId, "/Status"), {
        data: {
          statusId: statusId,
          otherStatusId: otherStatusId
        }
      });
    },

    /**
     * Get all the preset locations available for flight plans.
     * This gets updated frequently anytime a new flight plan is updated so it
     * should be refreshed often.
     */
    getFlightPlanLocations: function getFlightPlanLocations() {
      return this.request('FlightPlanLocations');
    },

    /**
     * Retrieves an authentication token from the analytics server to be granted access to the report URL
     */
    getAnalyticsToken: function getAnalyticsToken() {
      return this.request('AnalyticsToken');
    },

    /**
     * Send the Cancel Emergency command to the unit in question
     */
    sendCancelEmergency: function sendCancelEmergency(assetId) {
      return this.post("assets/".concat(assetId, "/cancelemergency"));
    },

    /**
     * Updates the fire cloud messaging Id
     */
    updateFirebaseRegistration: function updateFirebaseRegistration(registrationId) {
      return this.put('PushNotifications', {
        data: {
          Type: 'FCM',
          Id: registrationId
        }
      });
    },

    /**
     * Removes the fire cloud messaging Id
     */
    removeFirebaseRegistration: function removeFirebaseRegistration(registrationId) {
      return this.del('PushNotifications', {
        data: {
          Type: 'FCM',
          Id: registrationId
        }
      });
    },
    searchGeographicLocations: function searchGeographicLocations(search, types) {
      return this.request('Search/GeographicLocations', {
        data: {
          search: search,
          types: types
        }
      });
    },

    /**
     * Gets a geocode timezone record from a position with a date
     * @return {Object}
     */
    getTimeZonePosition: function getTimeZonePosition(latitude, longitude) {
      var date = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
      return this.request('geocode/timezone', {
        data: {
          latitude: latitude,
          longitude: longitude,
          date: date ? date.toISOString() : undefined
        }
      }).then(function (tz) {
        return {
          id: tz.Id,
          offsetMinutes: tz.OffsetMinutes,
          windowsId: tz.WindowsId,
          supportsDaylightSavings: tz.SupportsDaylightSavings,
          offsetString: tz.OffsetString
        };
      });
    },

    /**
     * Send an SMS to the phone number in question
     * @param {String} phone 
     * @param {String} message 
     */
    sendSms: function sendSms(phone, message) {
      return this.post('sms/send', {
        data: {
          Phone: phone,
          Message: message
        }
      });
    },
    sendBusinessContinuityReportEmail: function sendBusinessContinuityReportEmail() {
      return this.post('businessContinuity/send');
    },

    /**
     * Sends a burst  message
     * @param  {sky-router-3/models/burst-services-message} message
     * @return {Ember.RSVP.Promise}
     */
    burstMessageSend: function burstMessageSend(data) {
      return this.post('burstServiceMessage/Send', {
        data: data
      });
    },

    /**
     * Sends send requests for a MSR
     * @param  {sky-router-3/models/burst-services-message} message
     * @return {Ember.RSVP.Promise}
     */
    burstMessageStatus: function burstMessageStatus(messageId) {
      return this.request("burstServiceMessageStatus/".concat(messageId));
    },
    burstMessageCancel: function burstMessageCancel(messageId) {
      return this.post('burstServiceMessage/Cancel', {
        data: {
          MessageId: messageId
        }
      });
    }
  });

  _exports.default = _default;
});