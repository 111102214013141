define("sky-router-3/pods/components/portal/manage/device-profiles/types/nal/shout-ts/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Indicates if the profile will be readOnly
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Object holding revision settings that can have any number of
     * key and values
     * @type {Object}
     */
    settings: null
  });

  _exports.default = _default;
});