define("sky-router-3/pods/components/portal/track/map/overlays/user-events-context/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Temporary user events
   */
  var _default = Ember.Component.extend({
    tagName: '',
    settings: null,
    menu: null,
    map: null,
    actions: {
      showCreate: function showCreate() {
        this.set('settings.showCreate', true);
        this.set('settings.createLat', this.menu.latMilli);
        this.set('settings.createLng', this.menu.lngMilli);
        this.map.setCenter(this.menu.event.latLng);
      }
    }
  });

  _exports.default = _default;
});