define("sky-router-3/pods/portal/report/invoiceusage/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the invoice usage report. It handles creating the
   * report parameter option which for this report only includes an array option to run the
   * report for SBD or Voice
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.invoiceUsage');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.invoiceusage'],

    /**
     * The model for the association reports route that contains an array of usage type and invoice report options.
     **/
    model: function model(params) {
      // let invOptions = this.get('api').getInvoiceUsageDates();
      var usageTypes = this.getUsageOptions();
      var reportTypes = this.getReportTypes();
      var reportType = params.reportType && $.isNumeric(params.reportType) ? reportTypes.findBy('id', parseInt(params.reportType)) : reportTypes[0];
      var usageType = params.usageTypes && $.isNumeric(params.reportType) ? usageTypes.findBy('id', parseInt(params.usageType)) : usageTypes[0]; // Return the hashed model values

      return Ember.RSVP.hash({
        // The array of invoice options
        invoiceOptions: this.get('api').getInvoiceUsageDates(),
        // The array of report types
        reportTypes: reportTypes,
        selectedReportType: reportType ? reportType : reportTypes[0],
        // The array of usage types
        usageTypes: usageTypes,
        // The selected usage type, defaulted to the first in the list
        selectedUsageType: usageType ? usageType : usageTypes[0]
      });
    },

    /**
     * Creates an array of selectable report types
     * @function
     * @return {Array objects} The report type array
     */
    getReportTypes: function getReportTypes() {
      var intl = this.get('intl');
      return [{
        id: 1,
        text: intl.t('report.reportType.summary')
      }, {
        id: 2,
        text: intl.t('report.reportType.detail')
      }];
    },

    /**
     * Creates an array of selectable usage types
     * @function
     * @return {Array objects} The usage type array
     */
    getUsageOptions: function getUsageOptions() {
      var intl = this.get('intl');
      return [{
        id: 1,
        text: intl.t('report.usageType.data')
      }, {
        id: 2,
        text: intl.t('report.usageType.voice')
      }];
    }
  });

  _exports.default = _default;
});