define("sky-router-3/models/global-traveler-contact", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', true),
    'phone': (0, _emberCpValidations.validator)('presence', true),
    'org': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)(),
    phone: (0, _model.attr)(),
    isEmergency: (0, _model.attr)(),
    isArchived: (0, _model.attr)(),
    areas: (0, _model.hasMany)('global-traveler-area'),
    org: (0, _model.belongsTo)('global-traveler-org')
  });

  _exports.default = _default;
});