define("sky-router-3/pods/playback/view/route", ["exports", "sky-router-3/utils/load-google-map", "sky-router-3/models/overlay-type", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map"], function (_exports, _loadGoogleMap, _overlayType, _latLngConverter, _create, _stateTrackMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.modelFor(this.routeName).assets.mapBy('name').join(', ');
    },

    /**
     * Make sure that we have a clean slate to work with for the report.
     */
    beforeModel: function beforeModel() {
      this.notifications.clearAll();
      this.store.unloadAll('asset-position');
      var google = (0, _loadGoogleMap.default)({
        language: this.get('intl.locale.firstObject')
      });
      var sup = Ember.RSVP.resolve(this._super.apply(this, arguments));
      return Ember.RSVP.allSettled([google, sup]);
    },

    /**
     * Load data from the server and get our positions from the api
     */
    model: function model(params) {
      var assetIds = params.asset_ids.split(',');
      return Ember.RSVP.hash({
        mapConfig: (0, _create.default)(_stateTrackMap.default, {
          assetBreadcrumbsShownArray: assetIds,
          followAssetId: assetIds.length === 1 ? assetIds[0] : null
        }),
        overlays: this.store.query('overlay', {
          contracts: _overlayType.SUPPORTED_CONTRACTS
        }),
        geofences: this.store.query('geofence', {
          forAssets: assetIds
        }),
        locations: this.store.findAll('location'),
        assets: this.store.query('asset', {
          ids: assetIds
        }),
        positionsPlayed: this.store.peekAll('asset-position'),
        positions: this.get('api').queryAssetPositions({
          afterDate: params.after,
          afterDateExclusive: false,
          beforeDate: params.before,
          imeis: assetIds,
          limit: 9897
        }),
        showGeofences: true,
        showUTC: false
      });
    },

    /**
     * Unwraps the positions from the API and sorts them by UTC
     */
    afterModel: function afterModel(model) {
      if (model.positions.Meta.CountReturned === 0) {
        this.notifications.warning(this.intl.t('playback.errorNoReportsFound'));
      }

      model.mapConfig.setupOverlaySettings(model.overlays);
      model.positionCount = model.positions.Meta.CountReturned;
      model.positionOldest = model.positions.Meta.OldestUtc;
      model.positionSoonest = model.positions.Meta.SoonestUtc;
      model.positions = model.positions.AssetPositions.sortBy('Utc'); // Push the first record in the store to initialize the playback

      var p_serializer = this.store.serializerFor('asset-position');
      var p_model = this.store.modelFor('asset-position');
      var norm = p_serializer.normalize(p_model, model.positions.firstObject);
      this.store.push(norm);
      return this._super.apply(this, arguments);
    },

    /**
     * Resets the properties of the controller to their defaults
     * since controllers are singletons and live forever.
     * @param {Controller}  controller
     * @param {Boolean} isExiting
     */
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isPlaying', false);
        controller.set('playPosition', 0);
        controller.set('speed', 5);
      }
    },
    actions: {
      /**
       * When one of the model hook for the report fails this notifies
       * the user about the failure.
       */
      error: function error() {
        this.notifications.error(this.intl.t('playback.errorLoadingReport'));
      },
      assetMapAction: function assetMapAction(asset, action, args) {
        var config = this.modelFor(this.routeName).mapConfig;
        var array = config.get('assetInfoBoxOpenArray');

        switch (action) {
          case 'click':
            array.addObject(args.assetId);
            break;

          case 'close':
            array.removeObject(args.assetId);
            break;

          case 'follow':
            config.set('followAssetId', args.assetId);
            break;

          case 'un-follow':
            config.set('followAssetId', null);
            break;

          case 'show-breadcrumbs':
            config.get('assetBreadcrumbsShownArray').addObject(args.assetId);
            break;

          case 'hide-breadcrumbs':
            config.get('assetBreadcrumbsShownArray').removeObject(args.assetId);
            break;

          default:
            this.send('showNotAvailableFeature');
        }
      },
      locationMapAction: function locationMapAction(location, action) {
        var config = this.modelFor(this.routeName).mapConfig;
        var array = config.get('locationInfoBoxOpenArray');

        switch (action) {
          case 'click':
            array.addObject(location.get('location.id'));
            break;

          case 'close':
            array.removeObject(location.get('location.id'));
            break;

          default:
            this.send('showNotAvailableFeature');
        }
      },
      mapAction: function mapAction(map, action, args) {
        var config = this.modelFor(this.routeName).mapConfig;

        switch (action) {
          case 'clearFollowedAsset':
            config.set('followAssetId', null);
            break;

          case 'hideAssetsBreadcrumbs':
            config.get('assetBreadcrumbsShownArray').removeObjects(args.assetIdArray);
            break;

          case 'toggleOverlayGroupExpand':
            {
              var ogArray = config.get('overlayGroupsExpandedArray');

              if (args.expanded) {
                ogArray.addObject(args.groupId);
              } else {
                ogArray.removeObject(args.groupId);
              }

              break;
            }

          case 'toggleOverlaysOnMap':
            {
              var ovArray = config.get('overlayVisibleArray');

              if (args.visible) {
                ovArray.addObjects(args.overlayIds);
              } else {
                ovArray.removeObjects(args.overlayIds);
              }

              break;
            }

          case 'overlayReordered':
            {
              var overlays = config.get('overlayVisibleArray');
              overlays.removeObjects(args.overlayIds);
              overlays.pushObjects(args.overlayIds);
              break;
            }

          case 'loaded':
            {
              var model = this.modelFor(this.routeName);

              var bounds = _latLngConverter.default.getBounds(model.positions.filterBy('PointIsValid'), 'LatitudeMilliarcSeconds', 'LongitudeMilliarcSeconds');

              model.mapConfig.set('fitBounds', bounds);
              break;
            }

          case 'center':
            break;

          default:
            this.send('showNotAvailableFeature');
        }
      }
    }
  });

  _exports.default = _default;
});