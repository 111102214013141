define("sky-router-3/models/invoice-plan-usage-voice", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var model = _model.default.extend({
    /**
     * The name of the plan
     */
    voicePlanName: (0, _model.attr)('string'),

    /**
    * The PSTN minutes used
    **/
    pstnUsedUnits: (0, _model.attr)('number'),

    /**
    * The PSTN minutes included in the plan
    **/
    pstnPlanUnits: (0, _model.attr)('number'),

    /**
    * The iridium used units for the plan
    **/
    iridiumUsedUnits: (0, _model.attr)('number'),

    /**
    * The iridium included minutes for the plan
    **/
    iridiumPlanUnits: (0, _model.attr)('number'),

    /**
    * The list of MSISDNs included in the plan
    **/
    msisdnList: (0, _model.attr)('string'),

    /**
    * The invoice date for the invoice
    **/
    invoiceDate: (0, _model.attr)('date'),

    /**
    * The pstn usage percentage for the plan
    **/
    actualPstnUsagePct: function () {
      var planUnits = this.pstnPlanUnits;
      var usedUnits = this.pstnUsedUnits;
      return planUnits === 0 ? 1 : usedUnits / planUnits;
    }.property('pstnUsedUnits', 'pstnPlanUnits'),

    /**
    * The pstn usage percentage for the plan calulated as a max of 100%
    **/
    pstnUsagePct: function () {
      return Math.min(1, this.actualPstnUsagePct);
    }.property('actualPstnUsagePct'),

    /**
    * The pstn usage pct as a string
    **/
    pstnUsagePctString: function () {
      return Math.round(this.pstnUsagePct * 100) + '%';
    }.property('pstnUsagePct'),

    /**
    * The count of pstn minutes used that are over the plan included minutes
    **/
    pstnOverUnits: function () {
      return this.pstnUsedUnits < this.pstnPlanUnits ? 0 : this.pstnUsedUnits - this.pstnPlanUnits;
    }.property('pstnUsedUnits', 'pstnPlanUnits'),

    /**
    * The styling of the pstn usage bar based on the usage percentage
    **/
    pstnStyle: function () {
      if (this.pstnPlanUnits === 0) {
        return 'usage-zero-plan';
      } else if (this.actualPstnUsagePct < .80) {
        return 'usage-normal';
      } else if (this.actualPstnUsagePct >= .80 && this.actualPstnUsagePct <= 1) {
        return 'usage-high';
      } else {
        return 'usage-over';
      }
    }.property('pstnPlanUnits', 'actualPstnUsagePct'),

    /**
    * The iridium usage percentage for the plan
    **/
    actualIridiumUsagePct: function () {
      var planUnits = this.iridiumPlanUnits;
      var usedUnits = this.iridiumUsedUnits;
      return planUnits === 0 ? 1 : usedUnits / planUnits;
    }.property('iridiumUsedUnits', 'iridiumPlanUnits'),

    /**
    * The iridium usage percentage for the plan calulated as a max of 100%
    **/
    iridiumUsagePct: function () {
      return Math.min(1, this.actualIridiumUsagePct);
    }.property('actualIridiumUsagePct'),

    /**
    * The iridium usage pct as a string
    **/
    iridiumUsagePctString: function () {
      return Math.round(this.iridiumUsagePct * 100) + '%';
    }.property('iridiumUsagePct'),

    /**
    * The count of iridium minutes used that are over the plan included minutes
    **/
    iridiumOverUnits: function () {
      return this.iridiumUsedUnits < this.iridiumPlanUnits ? 0 : this.iridiumUsedUnits - this.iridiumPlanUnits;
    }.property('iridiumUsedUnits', 'iridiumPlanUnits'),

    /**
    * The styling of the iridium usage bar based on the usage percentage
    **/
    iridiumStyle: function () {
      if (this.iridiumPlanUnits === 0) {
        return 'usage-zero-plan';
      } else if (this.actualIridiumUsagePct < .80) {
        return 'usage-normal';
      } else if (this.actualIridiumUsagePct >= .80 && this.actualIridiumUsagePct <= 1) {
        return 'usage-high';
      } else {
        return 'usage-over';
      }
    }.property('iridiumPlanUnits', 'actualIridiumUsagePct')
  });

  var _default = model;
  _exports.default = _default;
});