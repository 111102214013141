define("sky-router-3/pods/components/portal/track/map/line/line-asset-destination/component", ["exports", "sky-router-3/utils/lat-lng-converter", "moment", "sky-router-3/utils/computed-promise"], function (_exports, _latLngConverter, _moment, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    asset: null,
    destination: null,
    hoverLocation: null,
    map: null,
    destinationValid: Ember.computed('destinationPoint', function () {
      return this.destinationPoint && !isNaN(this.destinationPoint.lat());
    }),
    destinationPoint: Ember.computed('destination.latitudeMilliarcSeconds', 'destination.longitudeMilliarcSeconds', {
      get: function get() {
        return _latLngConverter.default.modelToGoogleLatLng(this.get('destination'));
      },
      set: function set(key, value) {
        var mLat = _latLngConverter.default.decimalDegToMilliArcSecond(value.lat());

        var mLng = _latLngConverter.default.decimalDegToMilliArcSecond(value.lng());

        this.set('destination.latitudeMilliarcSeconds', mLat);
        this.set('destination.longitudeMilliarcSeconds', mLng);
        return value;
      }
    }),
    destinationTimeZone: (0, _computedPromise.default)('destination.latitudeMilliarcSeconds', 'destination.longitudeMilliarcSeconds', function () {
      return this.api.getTimeZonePosition(this.get('destination.latitudeMilliarcSeconds'), this.get('destination.longitudeMilliarcSeconds'), this.get('eta'));
    }, null),

    /**
     * Gets the distance from the current location of the asset to the destination.
     * @type {Number} Distance in centimeters.
     */
    distance: Ember.computed('asset.positionLatest.latitudeMilliarcSeconds', 'asset.positionLatest.longitudeMilliarcSeconds', 'destination.latitudeMilliarcSeconds', 'destination.longitudeMilliarcSeconds', function () {
      var assetPosition = this.asset.get('positionLatest');

      if (assetPosition) {
        var to = _latLngConverter.default.modelToGoogleLatLng(this.destination);

        var from = _latLngConverter.default.modelToGoogleLatLng(assetPosition);

        return google.maps.geometry.spherical.computeDistanceBetween(from, to) * 100;
      }

      return NaN;
    }),

    /**
     * Returns the duration in seconds for the asset to get to the destination
     * @return {Number} seconds to get to destination
     */
    duration: Ember.computed('distance', 'asset.speedCms', function () {
      var assetPosition = this.asset.get('positionLatest');

      if (assetPosition) {
        return this.distance / this.asset.positionLatest.speedCms;
      }

      return NaN;
    }),

    /**
     * Gets the estimated time of arrival based on the curren time + duration
     * @return {Date} Date the asset may arrive
     */
    eta: Ember.computed('duration', function () {
      if (!isNaN(this.duration)) {
        return (0, _moment.default)(this.asset.positionLatest.utc).add(this.duration, 'seconds').toDate();
      }

      return null;
    }),
    actions: {
      onHover: function onHover(hovered, location) {
        if (hovered) {
          this.set('hoverLocation', location.latLng);
        } else {
          this.set('hoverLocation', null);
        }
      },
      infoBoxSet: function infoBoxSet(open) {
        this.destination.set('infoBoxOpen', open);
      },
      delete: function _delete(destination) {
        destination.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});