define("sky-router-3/utils/object/assign-safe", ["exports", "core-js/es/object/from-entries"], function (_exports, _fromEntries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assignSafe;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * This function performs an Object.assign but does not explode on trying to set a read-only property
   * @param  {Object} target  Target to apply sources properties to
   * @param  {Array<Object>} sources Any number of sources to be applied to target
   * @return {Object}        The updated target object;
   */
  function assignSafe(target) {
    var proto = Object.getPrototypeOf(target);

    for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    var safe = sources.map(function (source) {
      var entries = Object.entries(source);
      var reduced = entries.reduce(function (accumulator, currentValue) {
        var descriptor = Object.getOwnPropertyDescriptor(proto, currentValue[0]);

        if (!descriptor || descriptor.set) {
          accumulator.push(currentValue);
        }

        return accumulator;
      }, []);
      return Object.fromEntries(reduced);
    });
    return Object.assign.apply(Object, [target].concat(_toConsumableArray(safe)));
  }
});