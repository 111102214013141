define("sky-router-3/pods/portal/manage/burst-services/index/new/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    description: (0, _emberCpValidations.validator)('presence', true),
    code: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    typesSorted: Ember.computed.sort('model', 'typesSortedBy'),
    typesSortedBy: ['serviceName']
  });

  _exports.default = _default;
});