define("sky-router-3/pods/components/portal/track/map/geofence-polygon/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a geofence as a google.maps.Polygon()
   * @see https://developers.google.com/maps/documentation/javascript/3.exp/reference#Polygon
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    tagName: '',
    intl: Ember.inject.service(),

    /**
     * @event geofenceAction
     * @description generic action that contains all of the events that are fired.
     *              combining all the actions into one action helps reduce code
     *              duplication.
     * @param the sender of the action, aka. this component.
     * @param the string action that is being performed
     * 'click'  : Fired when the geofence is clicked
     */
    geofenceAction: function geofenceAction() {},

    /**
     * @event 'onContextMenuOpen'
     * Triggered after the context menu has been opened by
     * this component.
     */

    /**
     * @event 'addLocation'
     * Forwards the addLocation event from the context menu.
     * See that code for more info.
     */

    /**
     * @event 'deleteGeofence'
     * Fired when the user wishes to delete this geofence
     * @param { {sky-router-3/models/geofence}geofence } - the geofence object to be deleted
     */

    /**
     * Ember-data store injection because it is needed when a new
     * point is added to the geofence.
     */
    store: Ember.inject.service(),

    /**
     * Google maps object
     */
    googlePropertyObject: Ember.computed.alias('instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'path',
      setFunc: 'setPath'
    }, {
      property: 'options',
      setFunc: 'setOptions'
    }, {
      event: 'rightclick',
      eFunc: '_onRightClick'
    }, {
      event: 'click',
      eFunc: '_onClick'
    }],

    /**
     * Incoming geofence model
     * @type {sky-router-3/models/geofence}
     */
    geofence: null,

    /**
     * Incoming map object
     */
    map: null,
    mapZoom: null,

    /**
     * When true the geofence should be editable
     */
    isEditing: false,

    /**
     * When the geofence is being created
     */
    isCreating: Ember.computed.equal('geofence.points.length', 0),

    /**
     * Instance of the google map polygon
     * @type {google.maps.Polygon}
     */
    instance: null,
    //#region CONTEXT MENU

    /**
     * Show or hide the context menu
     */
    contextMenuShow: false,

    /**
     * The event that triggered the context menu to be shown
     * @type {google.maps.MouseEvent|google.maps.PolyMouseEvent}
     */
    contextMenuEvent: null,

    /**
     * True if the context menu event is from a geofence
     * vertex click
     * @type {boolean}
     */
    contextMenuFromVertex: function () {
      var event = this.get('contextMenuEvent');
      return event && event.vertex !== undefined;
    }.property('contextMenuEvent'),
    //#endregion

    /**
     * This propery is a dummy property that can be changed or set using
     * `notifyPropertyChange` that will force the path property to be recomputed
     * This has to exist because the points.@each.isDeleted doesn't appear to work.
     * @type {Boolean}
     */
    pathForceRecalculation: false,

    /**
     * Gets the center for the geofence from the first set of lat/lng cords
     * present in the points collection.
     */
    path: Ember.computed('geofence.points.@each.latitudeDegree', 'geofence.points.@each.longitudeDegree', {
      get: function get() {
        // TODO: IF EDITING, DON'T SET THIS
        var points = this.get('geofence.points');
        var gPoints = points.sortBy('ordinal').map(function (point) {
          return new google.maps.LatLng(point.get('latitudeDegree'), point.get('longitudeDegree'));
        });
        var latLngArray = new google.maps.MVCArray(gPoints); // Attach the lister for changes to the placement of the points in the array
        // Since the polygon object doesn't have a path_changed event.
        // We will use it to set the changes into the geofence.points.

        google.maps.event.addListener(latLngArray, 'set_at', Ember.run.bind(this, this._onMoveMapPoint, latLngArray)); // Attach the lister for changes to the addition of the points in the array

        google.maps.event.addListener(latLngArray, 'insert_at', Ember.run.bind(this, this._onAddMapPoint, latLngArray)); //Attach the listener for changes to the removal of the points in the array

        google.maps.event.addListener(latLngArray, 'remove_at', Ember.run.bind(this, this._onRemoveMapPoint)); // Return the final array

        return latLngArray;
      },
      set: function set(key, value, previousValue) {
        // Set the new value into the previousValue array that way the
        // listeners are not compromised.
        // Try and update existing points so that records are not destroyed
        for (var i = 0; i < previousValue.length; i++) {
          var newLatLng = value.getAt(i);

          if (newLatLng) {
            previousValue.setAt(i, newLatLng);
          }
        } // Add any new points onto the end


        for (var n = previousValue.length - 1; n < value.length; n++) {
          var _newLatLng = value.getAt(n);

          if (_newLatLng) {
            previousValue.push(_newLatLng);
          }
        } // Handle removals


        while (value.length < previousValue.length) {
          previousValue.pop();
        }

        return previousValue;
      }
    }),
    center: Ember.computed('geofence.points.@each.latitudeDegree', 'geofence.points.@each.longitudeDegree', function () {
      return _latLngConverter.default.getBoundsDecimal(this.geofence.get('points')).getCenter();
    }),
    area: Ember.computed('path', function () {
      return google.maps.geometry.spherical.computeArea(this.path);
    }),

    /**
     * Returns if the name label should be visible on the map or not.
     * This method calculates the viewport in meters and the size of the geofence in meters.
     * Then it sees if the geofence takes up 0.5% of the viewport or greater.
     * That number seems to be a decent breaking point for circle geofences.
     * Different values cause it to appear at higher or lower zoom levels.
     * If not here, names will be larger than the geofence itself on the visible map, which is not good.
     * @return {Boolean}
     */
    isNameLabelVisible: Ember.computed('map', 'path', 'mapZoom', 'area', function () {
      var bounds = this.map.getBounds();

      if (bounds) {
        var mapArea = _latLngConverter.default.getBoundsArea(bounds);

        return this.area > mapArea * .005;
      }

      return false;
    }),

    /**
     * options object that is pushed into the circle instance
     */
    options: function () {
      return {
        fillColor: "#".concat(this.get('geofence.color')),
        fillOpacity: 0.25,
        strokeColor: '#000000',
        strokeOpacity: 0.5,
        geodesic: true,
        editable: this.get('isEditing'),
        draggable: this.get('isEditing'),
        zIndex: this.get('isEditing') ? 100000000 : 0
      };
    }.property('geofence.color', 'isEditing'),

    /**
     * Init the circle instance
     */
    init: function init() {
      this.set('instance', new google.maps.Polygon());

      this._super.apply(this, arguments);
    },

    /**
     * Destroy the circle instance
     */
    willDestroyElement: function willDestroyElement() {
      this.instance.setMap(null);
    },
    //#region PATH TO GEOFENCE-POINT OBSERVERS

    /**
     * Triggered when the user adds a point to the geofence
     * It creates a new geofence-point object and assigns it
     * to the geofence.
     *
     * @param {google.maps.MVCArray} array of map points that correspond to geofence points
     * @param {number} index in the MVCArray where the object was added.
     */
    _onAddMapPoint: function _onAddMapPoint(gPoints, index) {
      // Adjust the ordinality of the points that occur
      // after the one we just inserted.
      var points = this.get('geofence.points');
      points.forEach(function (point) {
        if (point.get('ordinal') > index) {
          point.incrementProperty('ordinal');
        }
      }); // Create the point object, the point is automatically added
      // to the geofence points because we specified the geofence.

      var latLng = gPoints.getAt(index);
      points.addObject(Ember.Object.create({
        ordinal: index + 1,
        latitudeDegree: latLng.lat(),
        longitudeDegree: latLng.lng()
      }));
    },

    /**
     * Triggered when the user moves a point on the map
     * It goes through and updates the associated geofence-point object
     *
     * @param {number} index in the MVCArray that was altered
     * @param {google.maps.LatLng<>} the latLng array passed to the bind
     */
    _onMoveMapPoint: function _onMoveMapPoint(latLngArray, index) {
      var newPoint = latLngArray.getAt(index);
      var point = this.get('geofence.points').findBy('ordinal', index + 1);
      point.setProperties({
        latitudeDegree: newPoint.lat(),
        longitudeDegree: newPoint.lng()
      });
    },

    /**
     * Triggered when the user removes a point on the map
     * It goes through and finds the record missing and removes it
     * from the geofence points collection for the geofence.
     *
     * @param {number} index in the MVCArray that was altered
     */
    _onRemoveMapPoint: function _onRemoveMapPoint(index) {
      // Get the record for the point that was just removed
      var points = this.get('geofence.points');
      var dPoint = points.findBy('ordinal', index + 1); // Adjust the ordinality of the points that occur
      // after the one we just removed.

      points.forEach(function (point) {
        if (point.get('ordinal') > index) {
          point.decrementProperty('ordinal');
        }
      });
      points.removeObject(dPoint);
    },
    //#endregion

    /**
     * Triggered when the user right clicks on the polygon
     */
    _onRightClick: function _onRightClick(gObject, mouseEvent) {
      // show the context menu
      this.onContextMenuShown();
      this.set('contextMenuShow', true);
      this.set('contextMenuEvent', mouseEvent);
    },

    /**
     * Closes a context menu if it is open.
     */
    _onClick: function _onClick()
    /*gObject, mouseEvent*/
    {
      if (this.geofenceAction) {
        this.geofenceAction(this, 'click');
      }

      this.send('closeContextMenu');
    },
    actions: {
      /**
       * Closes the context menu, triggered from the menu itself
       */
      closeContextMenu: function closeContextMenu() {
        this.set('contextMenuShow', false);
        this.set('contextMenuEvent', null);
      },

      /**
       * deletes a geofence point from the path MVCArray
       * for this object. It lets the MVCArray listener
       * actually perform the update to the geofence-points
       * collection.
       *
       * After the delete is completed it closes the context menu
       */
      deleteGeofencePoint: function deleteGeofencePoint(pathIndex) {
        var pArray = this.get('instance').getPath();

        if (pArray.length === 3) {
          this.notifications.error(this.intl.t('track.trackGeofencePointsValidationPointMin', {
            count: 3
          }));
        } else {
          pArray.removeAt(pathIndex);
          this.send('closeContextMenu');
        }
      },

      /**
       * Fired when the geofence is to be deleted.
       */
      onGeofenceDelete: function onGeofenceDelete() {
        this.deleteGeofence({
          geofence: this.get('geofence')
        });
      },

      /**
       * Fired when the user has finished building a brand new geofence.
       * @return {Object} object args containing
       *                        'manager' - the drawing manager
       *                         'polygon' - the created polygon
       */
      onNewPolygonCreated: function onNewPolygonCreated(args) {
        // Set the path
        this.set('path', args.polygon.getPath()); // Destroy the passed in polygon object

        args.polygon.setMap(null);
        args.polygon = null;
      }
    }
  });

  _exports.default = _default;
});