define("sky-router-3/pods/public/login/two-factor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qjThVTC8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    \"],[6,[37,1],null,[[\"class\",\"route\"],[\"pull-right\",\"public.login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"login.loginTitle\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"panel-title\"],[12],[10,\"span\"],[14,0,\"fa fa-volume-control-phone\"],[12],[13],[2,\"  \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"two-factor.authNeeded\"],null]],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"username\",\"password\",\"onvalidated\"],[[35,3,[\"username\"]],[35,3,[\"password\"]],[30,[36,2],[[32,0],\"loginUser\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"action\",\"model\",\"two-factor-auth\"]}",
    "moduleName": "sky-router-3/pods/public/login/two-factor/template.hbs"
  });

  _exports.default = _default;
});