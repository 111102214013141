define("sky-router-3/pods/components/portal/manage/device-parameters/controls/normal-reporting-stationary/component", ["exports", "ember-cp-validations", "sky-router-3/utils/computed-promise"], function (_exports, _emberCpValidations, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'secondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.secondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.secondsSetting.validationMinNumber')
    })],
    'timeoutSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.timeoutSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.timeoutSetting.validationMinNumber')
    })],
    'speedSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.speedSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.speedSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,
    secondsSetting: null,
    speedSetting: null,
    speedSettingVisible: false,
    timeoutSetting: null,
    timeoutSettingVisible: false,

    /**
     * Controls the NPR grouping
     */
    timeReportingSelected: Ember.computed('secondsSetting.value', 'timeReportingOptions', function () {
      var options = this.get('timeReportingOptions');
      var option = options.findBy('value', this.get('secondsSetting.value'));

      if (!option) {
        option = options.findBy('value', '-1');
      }

      return option;
    }),
    timeReportingCustomShown: Ember.computed.equal('timeReportingSelected.value', '-1'),
    timeReportingOptions: Ember.computed(function () {
      var intl = this.get('intl');
      return [{
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '45',
        label: intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '2700',
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '-1',
        label: intl.t('parameter.misc.custom')
      }];
    }),

    /**
     * Controls the NPR grouping
     */
    timeoutReportingSelected: Ember.computed('timeoutReportingOptions', 'timeoutSetting.value', function () {
      var options = this.get('timeoutReportingOptions');
      var option = options.findBy('value', this.get('timeoutSetting.value'));

      if (!option) {
        option = options.findBy('value', '-1');
      }

      return option;
    }),
    timeoutReportingCustomShown: Ember.computed.equal('timeoutReportingSelected.value', '-1'),
    timeoutReportingOptions: Ember.computed(function () {
      var intl = this.get('intl');
      return [{
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 7
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '2700',
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '7200',
        label: intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '-1',
        label: intl.t('parameter.misc.custom')
      }];
    }),
    // GOVERNS THE SPEED UNIT INPUT
    speedMinCm: Ember.computed('speedSetting.validationMinNumber', 'speedSetting.value', function () {
      return this.speedSetting.validationMinNumber / 0.036;
    }),
    speedMaxCm: Ember.computed('speedSetting.validationMaxNumber', 'speedSetting.value', function () {
      return this.speedSetting.validationMaxNumber / 0.036;
    }),
    speedCm: Ember.computed('speedSetting.value', {
      get: function get() {
        return this.speedSetting.value / 0.036;
      },
      set: function set(key, value) {
        this.set('speedSetting.value', Math.floor(value * 0.036));
        return value;
      }
    }),
    speedUnits: (0, _computedPromise.default)(function () {
      return this.store.findAll('unit-speed');
    }, []),
    speedUnitSelected: Ember.computed('sessionAccount.user', 'speedUnits.[]', function () {
      return this.sessionAccount.user.get('speedLand');
    }),
    actions: {
      timeReportingChange: function timeReportingChange(selected) {
        if (selected.value === '-1') {
          this.set('secondsSetting.value', '');
        } else {
          this.set('secondsSetting.value', selected.value);
        }
      },
      timeoutReportingChange: function timeoutReportingChange(selected) {
        if (selected.value === '-1') {
          this.set('timeoutSetting.value', '');
        } else {
          this.set('timeoutSetting.value', selected.value);
        }
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('secondsSetting').rollbackAttributes();
        } else {
          this.onEnabled();
        }
      }
    }
  });

  _exports.default = _default;
});