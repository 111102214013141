define("sky-router-3/pods/portal/report/deviceinventory/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['displayedColumns'],

    /**
     * The displayed columns selected by the user
     * @type {String}
     */
    displayedColumns: null,

    /**
     * The translation serivce
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Flag that represents the page is currently in the process of exporting an excel report
     * @type {Boolean}
     */
    exportingExcelFile: false,

    /**
     * Flag that represents the page is currently in the process of exporting a CSV report
     * @type {Boolean}
     */
    exportingCSVFile: false,

    /**
     * Flag that represents the page is currently in the process of exporting a PDF report
     * @type {Boolean}
     */
    exportingPDFFile: false,

    /**
     * Flag that is used to disable all export buttons when an export is in progress
     * @type {Boolean}
     */
    exportsDisabled: false,

    /**
     * Watches the model displayed columns array and resets the displayed columns list variable when the checked values change
     * @function
     * @private
     */
    displayedColumnsList: function () {
      var list = this.get('model.displayedColumns').filterBy('checked', true).mapBy('value').join(',');
      this.set('displayedColumns', list);
      return list;
    }.property('model.displayedColumns.@each.checked'),

    /**
     * Makes the API call to retrive the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName) {
      var _this = this;

      // Toggle the exporting flag for the selected export.
      var busyFlag = 'exporting' + exportType + 'File';
      this.toggleProperty(busyFlag); // Disable export buttons

      this.toggleProperty('exportsDisabled'); // Get report parameters

      var visibleColumnsList = encodeURIComponent(this.get('displayedColumnsList'));
      var sortString = encodeURIComponent(this.get('model.sortString')); // Call the API

      this.get('api').getDeviceInventoryReport(visibleColumnsList, sortString, exportType).then(function (response) {
        // Toggle the exporting flag for the selected export. This should style the export button as not exporting
        _this.toggleProperty(busyFlag); // Enable the export buttons


        _this.toggleProperty('exportsDisabled'); // Create link to download export file


        _this.send('sendResponseToClient', response, fileName);
      }).catch(function () {
        _this.notifications.error(_this.intl.t('report.exportFailed', {
          exportType: exportType
        })); // Sets the export button to not exporting


        _this.set(busyFlag, false); // Enable the export buttons


        _this.set('exportsDisabled', false);
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        // Triggers the report to generate in the component
        this.toggleProperty('model.generateReportClicked');
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport('Excel', this.intl.t('nav.deviceInventory') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport('CSV', this.intl.t('nav.deviceInventory') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport('PDF', this.intl.t('nav.deviceInventory') + '-' + this.getUTCDateString() + '.pdf');
      }
    }
  });

  _exports.default = _default;
});