define("sky-router-3/helpers/make-iso", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeIso = makeIso;
  _exports.default = void 0;

  function makeIso(params
  /*, hash*/
  ) {
    if (params.length && params[0] && params[0].toISOString) {
      return params[0].toISOString();
    }
  }

  var _default = Ember.Helper.helper(makeIso);

  _exports.default = _default;
});