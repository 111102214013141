define("sky-router-3/pods/portal/report/devicehistory/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the device history report. It handles creating the
   * report parameter option which for this report only includes an array of devices for use in a drop down list
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.deviceHistory');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.devicehistory'],

    /**
     * The model for the device inventory reports route that contains an array of the user's available devices.
     **/
    model: function model(params, transition) {
      var store = this.get('store'); // Clear the query params if this report was accessed through a device link

      if (transition.to.queryParams.asset) {
        params.sDate = null;
        params.eDate = null;
        params.entryTypes = null;
        params.sourceTypes = null;
      } // Return the hashed model values


      return Ember.RSVP.hash({
        // Retrieves the list of devices from the db and then creates a list of the values to be used in a drop down list
        devices: store.findAll('asset', {
          reload: true
        }),
        // Retrieves the list of entry types from the db and then creates a checkbox list of the values to use for checkbox dropdown lists
        entryTypes: store.findAll('deviceHistoryEntryType'),
        // Retrieves the list of source types from the db and then creates a checkbox list of the values to use for checkbox dropdown lists
        sourceTypes: store.findAll('deviceHistorySourceType').then(function (values) {
          return values.uniqBy('name').map(function (x) {
            return Ember.Object.create({
              id: x.get('name'),
              name: x.get('name')
            });
          });
        })
      });
    },
    actions: {
      /**
       * Sets the report to automatically generate after render if a paramter imei was sent in the query string tp this route
       */
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          _this.controller.send('generateReport');
        });
      }
    }
  });

  _exports.default = _default;
});