define("sky-router-3/pods/components/input-debounced/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * inboarded from dead plugin that was in use.
   * https://github.com/offirgolan/ember-debounced-input-helpers
   */
  var _default = Ember.TextField.extend({
    /**
     * Number of milliseconds to wait.
     * @type {Number}
     */
    wait: 500,

    /**
     * Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to false.
     * @type {Boolean}
     */
    immediate: false,

    /**
     * Usually debounced properties are one way, if you plan to manually update val, this will keep val and value in sync
     * @type {Boolean}
     */
    sync: false,

    /**
     * Bound value to be debounced
     */
    val: null,

    /**
     * Raw value
     */
    value: Ember.computed('_value', 'val', 'sync', {
      get: function get() {
        if (this.sync) {
          return this.val;
        }

        return this._value;
      },
      set: function set(key, value) {
        this._valuePid = Ember.run.debounce(this, this._setVal, this.wait, this.immediate);
        return this.set('_value', value);
      }
    }),
    _setVal: function _setVal() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('val', this.value);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('value', this.val);
    },

    /**
     * Cleanup by canceling any current debounce
     */
    willDestroy: function willDestroy() {
      Ember.run.cancel(this._valuePid);
    }
  });

  _exports.default = _default;
});