define("sky-router-3/pods/portal/manage/device-profiles/index/rename/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WYdUAW3e",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"profiles.renameTitle\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n                \"],[1,[30,[36,2],null,[[\"class\",\"value\"],[\"form-control\",[35,1,[\"name\"]]]]]],[2,\"\\n                \"],[1,[30,[36,4],null,[[\"errors\"],[[35,3,[\"attrs\",\"model\",\"name\",\"messages\"]]]]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n                \"],[6,[37,6],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,5],[\"cancel\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-undo\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonCancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n                \"],[6,[37,6],null,[[\"class\",\"action\",\"isLoading\",\"disabled\"],[\"btn-warning\",[30,[36,5],[\"save\"],null],[35,1,[\"isSaving\"]],[35,3,[\"isInvalid\"]]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-save\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonSave\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"input\",\"validations\",\"input-errors\",\"route-action\",\"bs-button\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/device-profiles/index/rename/template.hbs"
  });

  _exports.default = _default;
});