define("sky-router-3/pods/portal/operations/flightplans/index/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0bPDqOyV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"help-block hidden-xs\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"flightPlans.view.help1\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"flightPlans.view.help2\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"flightPlans.view.help3\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,[30,[36,0],[\"flightPlans.view.help4\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sky-router-3/pods/portal/operations/flightplans/index/index/template.hbs"
  });

  _exports.default = _default;
});