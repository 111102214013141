define("sky-router-3/pods/portal/manage/location/type/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return this.store.findAll('location-type', {
        reload: true
      });
    },
    actions: {
      deleteType: function deleteType(type) {
        var _this = this;

        if (confirm(this.intl.t('locations.manageLocationTypesDeleteConfirm'))) {
          type.destroyRecord().catch(function () {
            _this.notifications.error(_this.intl.t('locations.manageLocationTypesDeleteFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});