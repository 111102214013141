define("sky-router-3/pods/portal/manage/burst-services/geo-area-group/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    // model() {
    //     return this.store.query('asset-parameter-set-type', {
    //         onlyAssignableTypes: true
    //     });
    // },
    actions: {
      cancel: function cancel(controller) {
        controller.set('name', '');
        controller.set('description', '');
        this.transitionTo('portal.manage.burst-services.geo-area-group');
      },
      create: function create(controller) {
        var _this = this;

        this.send('blockApp', true);
        var x = this.store.createRecord('burst-service-geo-area-group', {
          name: controller.get('name'),
          description: controller.get('description')
        }).save().then(function (geoAreaGroup) {
          _this.transitionTo('portal.manage.burst-services.geo-area-edit', geoAreaGroup.id);

          _this.notifications.success(_this.intl.t('profiles.newSuccess'));
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          controller.set('name', '');
          controller.set('description', '');

          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});