define("sky-router-3/pods/portal/manage/events/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.manage.events');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['manage.event'],
    model: function model() {
      return this.store.findAll('asset-position-type');
    },
    actions: {
      willTransition: function willTransition() {
        this.modelFor(this.routeName).forEach(function (type) {
          type.rollbackAttributes();
        });
      },
      save: function save(evt) {
        var _this = this;

        this.send('blockApp', true);
        this.get('api').patch("AssetPositionTypes/".concat(evt.get('id'), "/Admin"), {
          data: {
            Name: evt.get('name'),
            IsEmergency: evt.get('isEmergency')
          }
        }).then(function () {
          return evt.reload();
        }).catch(function () {
          return _this.notifications.error('Operation Failed');
        }).finally(function () {
          return _this.send('blockApp', false);
        });
      },
      revert: function revert(evt) {
        var _this2 = this;

        this.send('blockApp', true);
        this.get('api').del("AssetPositionTypes/".concat(evt.get('id'), "/Admin")).then(function () {
          evt.rollbackAttributes();
          evt.reload();
        }).catch(function () {
          return _this2.notifications.error('Operation Failed');
        }).finally(function () {
          return _this2.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});