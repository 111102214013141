define("sky-router-3/pods/portal/report/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rE/KiRj9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[10,\"iframe\"],[14,\"src\",\"/CustomerPortal/\"],[14,0,\"iframe-content\"],[14,3,\"ember-frame\"],[14,\"seamless\",\"seamless\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/report/invoices/template.hbs"
  });

  _exports.default = _default;
});