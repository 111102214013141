define("sky-router-3/pods/components/bs-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Defines a bootstrap style button with available loading async wait
   *
   * @property type - the type of button options are standard html5 type options
   * @property disabled - disables or enables the button
   * @property block - shows the button as a block level element
   * @property isLoading - When marked as true it disables the button and shows the loading animation
   * @property value - value to place inside the button, can use {yield} if you wish instead
   *
   * @example {{#bs-button class='btn-primary' type='submit' block=true isLoading=isLoading disabled=false}}buttonText{{/bs-button}}
   */
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['block:btn-block', 'isLoading:loading'],
    attributeBindings: ['type', '_isDiabled:disabled', 'title'],
    type: 'button',

    /**
     * bounds attribute because the binding both of them doesn't work
     */
    _isDiabled: Ember.computed.or('disabled', 'isLoading'),
    disabled: false,
    block: false,
    isLoading: false,
    value: '',
    title: '',
    click: function click() {
      if (this.action) {
        this.action();
      }
    }
  });

  _exports.default = _default;
});