define("sky-router-3/validators/valid-password", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ValidPassword = _base.default.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Regex the password is tested against.
     */
    regex: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*\W)[\S]{8,64}$/,

    /**
     * Validation function
     */
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      if (this.get('regex').test(value)) {
        return true;
      }

      return this.createErrorMessage('password', value, options);
    }
  });

  var _default = ValidPassword;
  _exports.default = _default;
});