define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-0/component", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.value1': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    store: Ember.inject.service(),
    geofences: function () {
      return this.get('store').findAll('geofence');
    }.property(),
    geofenceSelected: (0, _computedFindBy.default)('geofences', 'id', 'model.value1', true),
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('model.relationalOperator', 2);
    },
    actions: {
      selectGeofence: function selectGeofence(option) {
        this.set('model.value1', option.get('id'));
      }
    }
  });

  _exports.default = _default;
});