define("sky-router-3/pods/portal/operations/routes/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * The API service
     * @return {sky-router-3/services/api}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Disable the form if the user does not have permission to save the form.
     * @return {Boolean}
     */
    disabled: Ember.computed('sessionAccount.user.permissions', function () {
      return !this.sessionAccount.hasPermission('ops.routes.edit');
    }),
    actions: {
      onAddWaypoint: function onAddWaypoint(waypoints) {
        var waypoint = this.store.createFragment('route-waypoint'); // Add the waypoint right before the destination waypoint in the ordered list

        waypoints.insertAt(waypoints.length - 1, waypoint);
      }
    }
  });

  _exports.default = _default;
});