define("sky-router-3/pods/components/portal/manage/device-parameters/controls/distance-reporting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pLmrky3p",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"parameter.distance.title\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"parameter.distance.help\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[[35,11,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,12],null,[[\"checked\",\"onChange\",\"disabled\"],[[35,11,[\"valueBoolean\"]],[30,[36,10],[[32,0],\"enabledChanged\"],null],[35,6]]]]],[1,[30,[36,0],[\"parameter.misc.enableFeature\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,11,[\"valueBoolean\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,2],[[35,1,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n            \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.distance.distanceLabel\"],null]]],\"parameters\":[]}]]],[2,\"\\n            \"],[1,[30,[36,7],null,[[\"class\",\"value\",\"disabled\",\"type\",\"min\",\"max\"],[\"form-control\",[35,1,[\"value\"]],[35,6],\"number\",[35,1,[\"validationMinNumber\"]],[30,[36,5],[[35,4],[35,1,[\"validationMaxNumber\"]]],null]]]]],[2,\"\\n            \"],[1,[30,[36,9],null,[[\"errors\"],[[35,8,[\"attrs\",\"meterSetting\",\"value\",\"messages\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"meterSetting\",\"if\",\"input-label\",\"meterValidationMax\",\"or\",\"disabled\",\"input\",\"validations\",\"input-errors\",\"action\",\"enabledSetting\",\"input-checkbox\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/distance-reporting/template.hbs"
  });

  _exports.default = _default;
});