define("sky-router-3/pods/components/support-ticket/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ic1vPNrN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"settings.headerReportIssue\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"settings.reportIssueDesc\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"settings.reportIssueDesc1\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"settings.reportIssueExperiencing\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"class\",\"maxlength\"],[[35,2],\"form-control\",\"1000\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"settings.reportIssueSteps\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"value\",\"class\",\"rows\",\"maxlength\"],[[35,4],\"form-control\",\"5\",\"1000\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"help-block warn\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"settings.reportIssueWarn\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,6],null,[[\"class\",\"action\"],[\"btn-danger\",[30,[36,5],[[32,0],[32,0,[\"createTicket\"]],[35,2],[35,4]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"misc.submitForm\"],null]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"errorDescription\",\"textarea\",\"errorReproduce\",\"action\",\"bs-button\"]}",
    "moduleName": "sky-router-3/pods/components/support-ticket/template.hbs"
  });

  _exports.default = _default;
});