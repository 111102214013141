define("sky-router-3/pods/portal/manage/burst-services/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['search', 'sortProperty', 'sortDesc'],
    search: null,
    sortProperty: 'serviceName',
    sortDesc: false,

    /**
     * Filtering
     */
    filteredBurstServices: Ember.computed.filter('model.burstServices', ['search', 'model.burstServices.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('serviceName'), search) || (0, _stringSearch.default)(item.get('code'), search) || (0, _stringSearch.default)(item.get('serviceDescription'), search);
      }

      return true;
    }),

    /**
     * Sorting
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),
    sortedBurstServices: Ember.computed.sort('filteredBurstServices', 'sortBy'),

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('sortedBurstServices.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100],
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      }
    }
  });

  _exports.default = _default;
});