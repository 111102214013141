define("sky-router-3/services/signalr", ["exports", "sky-router-3/config/environment", "sky-router-3/utils/has-permission"], function (_exports, _environment, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service exists to handle connections to and from a signalR backend
   * Right now this is used to be notified of changes that occur on the server
   * to models that ember has information for. This way the ember application
   * appears to be handling information in real time.
   * @see https://github.com/SignalR/SignalR/blob/master/src/Microsoft.AspNet.SignalR.Client.JS/jquery.signalR.core.js
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * The state of this singalR which is used to help with change notifications.
     */
    signalrState: Ember.inject.service(),

    /**
     * The Ember-Data Store
     * @return {DS.Store}
     */
    store: Ember.inject.service(),

    /**
     * The API service that does things ember-data can't do.
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * The users current session service
     * @return {ember-simple-auth/service}
     */
    session: Ember.inject.service(),

    /**
     * The users account
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Notification service
     */
    notifications: Ember.inject.service(),

    /**
     * The connection object that represents a signalR connection,
     */
    _connection: null,

    /**
     * The proxy object that represents the connection proxy
     */
    _notificationProxy: null,

    /**
     * This holds a value that indicates that signalr is currently restarting
     * and isn't firing some events.
     * @type {Boolean}
     */
    isRestarting: false,

    /**
     * This holds a value that indicates that singlarR is currently stopping
     * and isn't firing some events.
     * @type {Boolean}
     */
    isStopping: false,

    /**
     * Holds a value that indicates that the internal SignalR connection
     * has been stopped.
     * @type {Boolean}
     */
    isStopped: true,

    /**
     * Initializes the signalr connection
     */
    init: function init() {
      var connection = this._initConnection();

      var notificationProxy = this._initNotificationProxy(connection); // Set the properties


      this.setProperties({
        isStopped: true,
        isStopping: false,
        isRestarting: false,
        _connection: connection,
        _notificationProxy: notificationProxy
      });

      this._super.apply(this, arguments);
    },

    /**
     * Starts up the connection to the signalR server
     * @returns {Ember.RSVP.Promise} promise to start the connection
     */
    start: function start() {
      var _this = this;

      // Get the connection and the proxy
      var connection = this.get('_connection'); // Set the connection query strings for authentication

      connection.qs = {
        'X-A': _environment.default.API.key,
        'X-T': this.get('session.data.authenticated.token')
      }; // Start the connection

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_environment.default.API.signalR.disabled) {
          resolve();
          return;
        }

        connection.start().then(function () {
          _this.set('isStopped', false);

          _this.trigger('started', connection);

          _this.signalrState.setup().then(resolve);
        }, function () {
          reject();
        });
      });
    },

    /**
     * Shutdown the signalR connection.
     */
    stop: function stop() {
      var connection = this.get('_connection');

      if (connection && !this.get('isStopped') && !this.get('isStopping')) {
        try {
          this.set('isStopping', true);
          connection.stop();
        } catch (error) {
          console.error(error);
        } finally {
          this.set('isStopping', false);
          this.set('isStopped', true);
        }
      }
    },

    /**
     * Restart the signalR connection as gracefully as possible.
     */
    restart: function restart() {
      this.set('isRestarting', true);
      this.stop();
      this.set('isRestarting', false);
      this.start();
    },

    /**
     * Creates the signalR conection and attaches all the events.
     */
    _initConnection: function _initConnection() {
      var _this2 = this;

      var connection = $.hubConnection(_environment.default.API.signalR.namespace, {
        useDefaultPath: false
      }); // Enable logging if its requested

      connection.logging = _environment.default.API.signalR.logging; // Add the connection events as defined in
      // http://www.asp.net/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client#connectionlifetime

      connection.starting(function () {
        return _this2.trigger('starting');
      });
      connection.connectionSlow(function () {
        return _this2.trigger('connectionSlow');
      });
      connection.disconnected(function () {
        if (!_this2.get('isRestarting') && !_this2.get('isStopping')) {
          _this2.trigger('disconnected');
        }
      });
      connection.reconnecting(function () {
        return _this2.trigger('reconnecting');
      });
      connection.reconnected(function () {
        return _this2.trigger('reconnected');
      });
      connection.error(function (error) {
        return _this2.trigger('error', error);
      });
      return connection;
    },
    _initNotificationProxy: function _initNotificationProxy(connection) {
      var proxy = connection.createHubProxy('changeNotificationHub'); // Attach the methods

      proxy.on('notifyOfChange', Ember.run.bind(this, this._handleNotifyOfChange)); // Return the final proxy

      return proxy;
    },

    /**
     * Logs errors to console.
     */
    _onError: Ember.on('error', function (error) {
      console.error(error);
    }),

    /**
     * This method takes in a notification in the form of
     *
     * @param {object} notification
     * {
     *   DataPartID, - The type of record that was changed. Each ID signifies a
     *   				different type of model. See description for each one.
     *   RecordID,   - An identifier that can be used in conjunction with the
     *   				DataPartID to figure out how to query the API for the
     *   				changed record.
     *   UserMasterID - The main company user of the owning account. This means
     *   				that if a sub-user caused the change the owning account id
     *   				will be present here. In the case of an asset change the
     *   				UserMasterID will be the id of the owner of the asset.
     *   DateChanged - The date the change occured.
     * }
     *
     * DataPartID dictates exactly what the RecordID signifies. Here is a table
     * that describes what each DataPartID means.
     * - 1. 'Assets'
     * 		- RecordID: IMEI of the asset that was changed.
     * 		- Meaning: Some property to do with the asset has been changed or the
     * 					asset has been added or removed from their account.
     * 		- What Happens: The record is reloaded.
     * - 2. 'Events'
     * 		- RecordID: IMEI of the asset that produced the event.
     * 		- Meaning: A new event has come in for the asset provided. A query
     * 					against the API is needed to find the new record that was
     * 					produced.
     * 	    - What Happens: Ember looks up the asset and then queries for any
     * 	    				events that have occured since the `PositionLatest`
     * 	    				event.
     * - 3. 'Messages'
     * 		- RecordID: Private Internal Record ID
     * 		- Meaning: This recordId pertains to the database internal identifier
     * 					for a mail folder the user has.
     * 	    - What Happens: A query is sent out to get the folder public identifier
     * 	    				and then the folder is reloaded and an event is fired
     * 	    				to inform the user that a new message has come in.
     * - 4. 'Geofences'
     * 		- RecordID: GeofenceId
     * 		- Meaning: Some property to do with the geofence has been changed or the
     * 				   geofence has been deleted.
     * 	    - What Happens: The record is reloaded.
     * - 6. 'Locations'
     * 		- RecordID: LocationId
     * 		- Meaning: Some property to do with the location has been changed or the
     * 				   location has been deleted.
     * 	    - What Happens: The record is reloaded.
     * - 7. 'Overlays'
     * - 8. 'User'
     * 		- RecordID: UserMasterId
     * 		- Meaning: Some property to do with the record has been changed or the
     * 				   record has been deleted.
     * 	    - What Happens: The record is reloaded.
     * - 9. 'Group'
     * - 10. 'Shares'
     * 		- RecordID: IMEI of the asset that was changed
     * 		- Meaning: A asset share has been added or removed from the users account.
     * 	    - What Happens: Reload the asset.
     * - 11. 'SystemNotification'
     * 		- RecordID: UserMasterId
     * 		- Meaning: Some property to do with the record has been changed or the
     * 				   record has been deleted.
     * 	    - What Happens: The record is reloaded.
     * - 12. 'FlightPlans'
     * 	    - RecordID: FlightPlanId
     * 	    - Meaning: A flight plan was updated or added.
     * 	    - What Happens: The record is reloaded
     * - 13. 'LocationTypes'
     * 		- RecordID: LocationTypeId
     * 		- Meaning: A Location type was updated or deleted.
     * 		- What Happens: The record is reloaded or unloaded.
     * - 14. 'Permissions'
     * 		- RecordID: UserMasterId
     * 		- Meaning: This user in question has had permissions removed from themselves
     * 		- What Happens: The application should reload to get the correct user permissions
     * - 15. 'DeviceHistory'
     *      - RecordID: DeviceHistoryId
     *      - Meaning: A new device history record has been created.
     *      - What Happens: The event is downloaded and then pushed to be displayed.
     * - 16. 'PositionMetadata'
     *      - RecordID: PositionMetadataId
     *      - Meaning: A new position metadata record has been created.
     *      - What Happens: The metadata notification is downloaded and then pushed to be displayed.
     * - 17. 'UserAPIKey'
     *      - RecordID: UserAPIKey.APIKey_ID
     *      - Meaning: new user api key has been enabled
     *      - What Happens: user api key list is refreshed
     * - 18. 'Form'
     *      - RecordID: Form Model Identifier
     *      - Meaning: form has been added, deleted, or modified.
     *      - What Happens: The record is reloaded or unloaded.
     * - 19. 'Trip'
     *      - RecordID: Form Model Identifier
     *      - Meaning: form has been added, deleted, or modified.
     *      - What Happens: The record is reloaded or unloaded.
     * - 20. 'TripPlan'
     *      - RecordID: Form Model Identifier
     *      - Meaning: form has been added, deleted, or modified.
     *      - What Happens: The record is reloaded or unloaded.
     */
    _handleNotifyOfChange: function _handleNotifyOfChange(notification) {
      var _this3 = this;

      var user = this.get('sessionAccount.user');
      var store = this.get('store');
      var permissions = user.get('permissions');

      switch (notification.DataPartID) {
        case 1: // Asset

        case 10:
          // Shares
          this._reloadRecord(store, 'asset', notification.RecordID).then(function () {
            if ((0, _hasPermission.default)(permissions, 'track')) {
              store.query('asset-position', {
                count: 1,
                imeis: [notification.RecordID]
              });
              store.query('flight-plan', {
                countPerAsset: 1
              });
            }
          }).catch(function () {
            // It is too much work to clear an asset out, just reload the page.
            _this3._reloadPage();
          });

          break;

        case 2:
          // Event
          if ((0, _hasPermission.default)(permissions, 'track')) {
            store.findRecord('asset', notification.RecordID).then(function (asset) {
              var query = {};

              var lastKnownId = _this3.signalrState.getLatestPositionId(asset.id); // Recover based on last known position or based on the most recent event.


              if (lastKnownId) {
                query.afterPositionId = lastKnownId;
                query.imei = notification.RecordID;
              } else {
                query.count = 1;
                query.imeis = [notification.RecordID];
              }

              _this3._retryQuery(store, 'asset-position', query).then(function (positions) {
                for (var i = 0; i < positions.length; i++) {
                  var position = positions.objectAt(i);

                  _this3.signalrState.setLatestPositionId(asset.id, position.id);

                  position.set('isHistorical', false);
                }

                _this3.trigger('onNewAssetPositions', positions);
              });
            });
          }

          break;

        case 3:
          // Messages
          if ((0, _hasPermission.default)(permissions, 'communicate')) {
            this.get('api').mailFolderFromPrivateId(notification.RecordID).then(function (response) {
              if (response.StringResult) {
                var record = store.peekRecord('mail-folder', response.StringResult);

                if (record) {
                  record.reload();
                }

                _this3.trigger('onMailFolderChange', response.StringResult);
              }
            });
          }

          break;

        case 4:
          // Geofences
          if ((0, _hasPermission.default)(permissions, 'track')) {
            this._reloadRecord(store, 'geofence', notification.RecordID).catch(function () {
              _this3._unloadrecordIfExists(store, 'geofence', notification.RecordID);
            });
          }

          break;

        case 6:
          // Locations
          if ((0, _hasPermission.default)(permissions, 'track') || (0, _hasPermission.default)(permissions, 'manage.location')) {
            this._reloadRecord(store, 'location', notification.RecordID).catch(function () {
              _this3._unloadrecordIfExists(store, 'location', notification.RecordID);
            });
          }

          break;

        case 8:
          // users
          this._reloadRecord(store, 'user', notification.RecordID).catch(function () {
            _this3._reloadPage();
          });

          break;

        case 9:
          // groups
          this._reloadRecord(store, 'group', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'group', notification.RecordID);
          }).finally(function () {
            store.unloadAll('asset');
            store.findAll('asset');

            _this3.trigger('onGroupsReloaded');
          });

          break;

        case 11:
          // System Notification
          this._reloadRecord(store, 'system-notification', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'system-notification', notification.RecordID);
          });

          break;

        case 12:
          // FlightPlans
          if ((0, _hasPermission.default)(permissions, 'track')) {
            this._reloadRecord(store, 'flight-plan', notification.RecordID).then(function (e) {
              _this3.trigger('onFlightPlanEntryNotification', e);
            }).catch(function () {
              _this3._unloadrecordIfExists(store, 'flight-plan', notification.RecordID);
            });
          }

          break;

        case 13:
          // LocationTypes
          this._reloadRecord(store, 'location-type', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'location-type', notification.RecordID);
          });

          break;

        case 14:
          // User Permissions
          this._reloadPage();

          break;

        case 15:
          // Asset History Entries
          store.findRecord('device-history', notification.RecordID).then(function (entry) {
            // TODO: Fix potential memory leak by ember data store holding on to record
            // after we are done using it.
            _this3.trigger('onNewDeviceHistoryEntry', entry);
          });
          break;

        case 16:
          // Tracking Metadata Entries
          store.findRecord('position-metadata', notification.RecordID).then(function (entry) {
            _this3.trigger('onNewPositionMetadataEntry', entry);
          });
          break;

        case 17:
          this._reloadRecord(store, 'api-key', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'api-key', notification.RecordID);
          });

          break;

        case 18:
          this._reloadRecord(store, 'form', notification.RecordID).then(function (entry) {
            _this3.trigger('onNewFormEntry', entry.record);
          }).catch(function () {
            _this3._unloadrecordIfExists(store, 'form', notification.RecordID);
          });

          break;

        case 19:
          if ((0, _hasPermission.default)(permissions, 'ops.trips')) {
            this._reloadRecord(store, 'trip', notification.RecordID).then(function (entry) {
              _this3.trigger('onNewTripEntry', entry);
            }).catch(function () {
              _this3._unloadrecordIfExists(store, 'trip', notification.RecordID);
            });
          }

          break;

        case 20:
          if ((0, _hasPermission.default)(permissions, 'ops.trips')) {
            this._reloadRecord(store, 'trip-plan', notification.RecordID).then(function (entry) {
              _this3.trigger('onNewTripPlanEntry', entry);
            }).catch(function () {
              _this3._unloadrecordIfExists(store, 'trip-plan', notification.RecordID);
            });
          }

          break;

        case 21:
          if ((0, _hasPermission.default)(permissions, 'ops.trips')) {
            this._reloadRecord(store, 'trip-plan-leg', notification.RecordID).then(function (entry) {
              _this3.trigger('onNewTripPlanLegEntry', entry);
            }).catch(function () {
              _this3._unloadrecordIfExists(store, 'trip-plan-leg', notification.RecordID);
            });
          }

          break;

        case 22:
          this._reloadRecord(store, 'asset-icon', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'asset-icon', notification.RecordID);
          });

          break;

        case 23:
          this._reloadRecord(store, 'geofence-asset', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'geofence-asset', notification.RecordID);
          });

          break;

        case 24:
          this._reloadRecord(store, 'compound-alert', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'compound-alert', notification.RecordID);
          });

          break;

        case 25:
          this._reloadRecord(store, 'global-traveler-message', notification.RecordID).catch(function () {
            _this3._unloadrecordIfExists(store, 'global-traveler-message', notification.RecordID);
          });

          break;
      }
    },

    /**
     * This helper function recursively retries a store query a specified amount of times
     * to avoid SignalR alerting a pull of a record that's not fully committed yet
     * @param  {DS.Store} store
     * @param  {String} model The model name
     * @param  {Json} params The params for the Query
     * @param {Number} delay The millisecond delay between tries. This gets multiplied
     *                       by the currentTry to extend the delay time.
     * @param  {Number} maxTries The maximum amount of tries before returning
     * @param {Number} currentTry The current try count. Should always be set to 1.
     **/
    _retryQuery: function _retryQuery(store, model, params) {
      var _this4 = this;

      var maxTries = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;
      var delay = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 500;
      var currentTry = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 1;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.later(_this4, function () {
          var _this5 = this;

          store.query(model, params).then(function (items) {
            if (items.length > 0 || currentTry >= maxTries) {
              resolve(items);
            } else {
              resolve(_this5._retryQuery(store, model, params, maxTries, delay, ++currentTry));
            }
          }).catch(reject);
        }, delay * currentTry);
      });
    },

    /**
     * This is a helper function that just reloads a record by its type and id.
     * If this session is the one performing an operation on the record do nothing
     * because it may override the users intended operation.
     * @param  {DS.Store} store
     * @param  {String} type String type from the model folder
     * @param  {String} id   Identifier for the record to reload.
     * @return {Ember.RSVP.Promise}      Promise to reload the record or add the record.
     * {
     *    record // The record that was loaded
     *    wasPresent // Was the record present or fetched new from the server
     * }
     */
    _reloadRecord: function _reloadRecord(store, type, id) {
      var _this6 = this;

      // Check to see if a new record is currently being saved in order to fix
      // a bug where signalR announces a new record in the middle of a model.save()
      // event. Which then causes duplicate records / orphaned records when the
      // initial .save() comes back.
      var currentlySaving = store.peekAll(type).any(function (item) {
        return item.get('isNew') && item.get('isSaving');
      }); // Unfortunatly we can't tell if the incoming record belongs to the one
      // being saved since the one being saved doesn't have an ID yet. So we'll
      // just delay the GET until hopefully the .save() completes.

      if (currentlySaving) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.later(_this6, function () {
            _this6._reloadRecord(store, type, id).then(resolve).catch(reject);
          }, 10000);
        });
      } else {
        var record = store.peekRecord(type, id);

        if (record) {
          if (record.get('isSaving')) {
            return Ember.RSVP.resolve({
              record: record,
              wasPresent: true
            });
          }

          return record.reload().then(function (record) {
            return {
              record: record,
              wasPresent: true
            };
          });
        }

        return store.findRecord(type, id).then(function (record) {
          return {
            record: record,
            wasPresent: false
          };
        });
      }
    },

    /**
     * Helper method that removes a record from the store if it exists.
     * If this session is the one performing an operation on the record do nothing
     * because it may override the users intended operation.
     * @param  {DS.Store} store
     * @param  {String} type String type from the model folder
     * @param  {String} id   Identifier for the record to reload.
     */
    _unloadrecordIfExists: function _unloadrecordIfExists(store, type, id) {
      var record = store.peekRecord(type, id);

      if (!!record && !record.get('isSaving')) {
        store.unloadRecord(record);
      }
    },

    /**
     * This method notifies the user that the page must be reloaded then it reloads
     * the page.
     */
    _reloadPage: function _reloadPage() {
      if (!this._reloadingPage) {
        this._reloadingPage = true;
        Ember.run.later(function () {
          return document.location.reload(true);
        }, 10000);
        this.notifications.warning(this.intl.t('misc.newInfoReloadingPageNotice'), {
          clearDuration: 10000
        });
      }
    },
    _reloadingPage: false
  });

  _exports.default = _default;
});