define("sky-router-3/pods/portal/settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Main settings route that houses all the users site-wide and page specific settings.
   * This parent route is mainly used to display a list of sub-settings pages where
   * the actual settings for a user are changed. See each individual sub page for more info
   */
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.settings');
    },

    /**
     * The session account service that holds all the information about the
     * users session.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Model for the main route of settings
     * @return {[type]} [description]
     */
    model: function model() {
      return this.get('sessionAccount');
    }
  });

  _exports.default = _default;
});