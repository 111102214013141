define("sky-router-3/pods/portal/manage/personnel/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        record: params.personnel_id === 'new' ? this.store.createRecord('personnel') : this.store.findRecord('personnel', params.personnel_id),
        types: this.store.findAll('personnel-type')
      });
    },
    afterModel: function afterModel(model) {
      if (model.record.isNew) {
        model.record.set('type', model.types.firstObject);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.modelFor(this.routeName).record.rollbackAttributes();
      },
      cancel: function cancel() {
        this.transitionTo('portal.manage.personnel');
      },
      save: function save() {
        var _this = this;

        this.send('blockApp', true);
        this.modelFor(this.routeName).record.save().then(function () {
          _this.transitionTo('portal.manage.personnel');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});