define("sky-router-3/pods/components/input-groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M7t5pXo0",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[6,[37,3],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,2],[[32,0],\"setAllGroups\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"components.addAllGroups\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,2],[[32,0],\"setAllActiveGroups\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"components.addAllActiveGroups\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,2],[[32,0],\"removeAllGroups\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-minus\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"components.removeAllGroups\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,2],[[32,0],\"removeInactiveGroups\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-group-inactive\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"components.removeInactiveGroups\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],null,[[\"options\",\"selected\",\"searchEnabled\",\"searchField\",\"closeOnSelect\",\"placeholder\",\"onChange\"],[[35,6],[35,5],true,\"name\",false,[35,4],[30,[36,2],[[32,0],\"groupsChanged\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\" \"],[6,[37,0],[[32,1,[\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-group-inactive\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"t\",\"action\",\"bs-button\",\"placeholder\",\"selected\",\"groups\",\"input-select-multiple\"]}",
    "moduleName": "sky-router-3/pods/components/input-groups/template.hbs"
  });

  _exports.default = _default;
});