define("sky-router-3/utils/string-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stringSearch;

  /**
   * This searches a string for another case insensitive
   * @param {Object} str - An object that can be converted to a string to search in
   * @param {String} search - The string to find inside the other string
   */
  function stringSearch(str, search) {
    if (!str && !search) {
      return true;
    }

    if (!str || !search || typeof search !== "string") {
      return false;
    }

    if (typeof str !== "string") {
      str = str.toString();
    }

    return str.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  }
});