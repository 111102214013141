define("sky-router-3/helpers/display-gars", ["exports", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/geoconversiontools/gars-conversions"], function (_exports, _latLngConverter, _garsConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper takes in a milliArcSecond value and turns it into the units which are
   * displayed to an end user. Those units may be in any format. The format is determined
   * by the users profile.
   *
   * @param {array} params - an array of incoming values that are passed to the helper via space
   * In this case there should be 2 elements which are the milliArcSecond lat/lon values to convert.
   *
   * @returns {string} - the value of the units in a displayable string.
   *
   * @example {{display-gars event.latitudeMilliarcSeconds event.longitudeMilliarcSeconds}}
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params
    /*, hash */
    ) {
      var latvalue = _latLngConverter.default.milliArcSecondsToDecimalDeg(params[0]);

      var lonvalue = _latLngConverter.default.milliArcSecondsToDecimalDeg(params[1]);

      var gars = (0, _garsConversions.convertGeodeticToGARS)(latvalue, lonvalue);

      if (gars) {
        return gars;
      }

      return this.intl.t('map.mapMGRSInvalid');
    }
  });

  _exports.default = _default;
});