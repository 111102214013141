define("sky-router-3/electron/reload", [], function () {
  "use strict";

  function setupLivereload() {
    var process = window ? window.processNode : null; // Exit immediately if we're not running in Electron

    if (!window.ELECTRON || process && process.env && process.env.DO_NOT_SETUP_LIVERELOAD) {
      return;
    } // Reload the page when anything in `dist` changes


    var fs;
    var path;
    var devtron;

    try {
      fs = window.requireNode('fs');
      path = window.requireNode('path');
    } catch (e) {
      console.warn('ember-electron tried to require fs and path to enable live-reload features, but failed.');
      console.warn('Automatic reloading is therefore disabled.');
      console.warn(e);
      return;
    }
    /**
     * @private
     * Watch a given directory for changes and reload
     * on change
     *
     * @param sub directory
     */


    function watch() {
      var _path;

      for (var _len = arguments.length, sub = new Array(_len), _key = 0; _key < _len; _key++) {
        sub[_key] = arguments[_key];
      }

      var dirname = (_path = path).join.apply(_path, [__dirname].concat(sub));

      fs.watch(dirname, {
        recursive: true
      }, function () {
        return window.location.reload();
      });
    }
    /**
     * @private
     * Install Devtron in the current window.
     */


    function installDevtron() {
      try {
        devtron = window.requireNode('devtron');

        if (devtron) {
          devtron.install();
        }
      } catch (e) {// no-op
      }
    }
    /**
     * @private
     * Install Ember-Inspector in the current window.
     */


    function installEmberInspector() {
      var location;

      try {
        var eiLocation = window.requireNode.resolve('ember-inspector');
        location = path.join(eiLocation, 'dist', 'chrome');
      } catch (error) {
        location = path.join(__dirname, '..', 'node_modules', 'ember-inspector', 'dist', 'chrome');
      }

      fs.lstat(location, function (err, results) {
        if (err) {
          console.warn('Error loading Ember Inspector', err);
          return;
        }

        if (results && results.isDirectory && results.isDirectory()) {
          var BrowserWindow = window.requireNode('electron').remote.BrowserWindow;
          var added = BrowserWindow.getDevToolsExtensions && BrowserWindow.getDevToolsExtensions().hasOwnProperty('Ember Inspector');

          try {
            if (!added) {
              BrowserWindow.addDevToolsExtension(location);
            }
          } catch (err) {// no-op
          }
        }
      });
    }

    document.addEventListener('DOMContentLoaded', function ()
    /* e */
    {
      var dirname = __dirname || (process && (process || {}).cwd ? process.cwd() : null);

      if (!dirname) {
        return;
      }

      fs.stat(dirname, function (err
      /* , stat */
      ) {
        if (!err) {
          watch(); // On linux, the recursive `watch` command is not fully supported:
          // https://nodejs.org/docs/latest/api/fs.html#fs_fs_watch_filename_options_listener
          //
          // However, the recursive option WILL watch direct children of the
          // given directory.  So, this hack just manually sets up watches on
          // the expected subdirs -- that is, `assets` and `tests`.

          if (process && process.platform === 'linux') {
            watch('assets');
            watch('tests');
          }
        }
      });
      installDevtron();
      installEmberInspector();
    });
  }

  setupLivereload();
});