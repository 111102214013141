define("sky-router-3/pods/portal/tool-bar-mobile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dNYqTfLm",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"navbar-button pull-right\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"navigateBack\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-arrow-left\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"navbar-text-lg text-center\"],[12],[2,\"\\n    \"],[1,[35,1,[\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\"]}",
    "moduleName": "sky-router-3/pods/portal/tool-bar-mobile/template.hbs"
  });

  _exports.default = _default;
});