define("sky-router-3/pods/components/portal/track/map/overlays/marine-traffic/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/browser-detect"], function (_exports, _googleObjectMapping, _computedJsonObject, _latLngConverter, _browserDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var upperVal = 0;
  var offset = 250; //goes through marine traffic data 100 points at a time

  var lowerVal = -offset;
  var count = 0;
  var statusArray = ["Under way using engine", "At anchor", "Not under command", "Restricted maneuverability", "Constrained by her draught", "Moored", "Aground", "Engaged in fishing", "Under way sailing", "Reserved for future amendment of navigational status for ships carrying DG, HS, or MP, or IMO hazard or pollutant category C, high-speed craft (HSC)", "Reserved for future amendment of navigational status for ships carrying dangerous goods (DG), harmful substances (HS) or marine pollutants (MP), or IMO hazard or pollutant category A, wing in ground (WIG)", "Power-driven vessel towing astern (regional use)", "Power-driven vessel pushing ahead or towing alongside (regional use)", "Reserved for future use", "AIS-SART (active), MOB-AIS, EPIRB-AIS", "undefined"];
  statusArray[95] = "Base Station", statusArray[96] = "Class B", statusArray[97] = "SAR Aircraft", statusArray[98] = "Aid to Navigation", statusArray[99] = "Class B";
  /**
   * Ship location and information based on the Marine Traffic API
   */

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each record downloaded.
     */
    style: function style(feature) {
      var sizeX = 22;
      var sizeY = 52;
      var speed = feature.getProperty('SPEED');
      var speedColor = "#98fb98";
      var opacityNum = 0.7;
      var boat = 'M0 20, 10 0, 20 20, 20 50,10 40,0 50,0 20'; //'M0-5l10-20L20-5v30L10,15L0,25V-5'

      var pathShape = boat; //default pathShape

      if (speed == 0) {
        pathShape = "M 0, 0 m -15, 0 a 15,15 0 1,0 30,0 a 15,15 0 1,0 -30,0"; //circle

        opacityNum = 0.4;
        sizeX = 0;
        sizeY = 0;
      } else if (speed <= 10 && speed > 5) //medium
        speedColor = "#fcfd89"; //yellow #fcfd89 or #FBFD3B
      else if (speed > 10) //fast
          speedColor = "#fd6262"; //red #fd6262 or #FD3B3B


      var icon = {
        path: pathShape,
        fillColor: speedColor,
        fillOpacity: opacityNum,
        strokeColor: '#243C24',
        strokeWeight: 2,
        scale: 0.4,
        rotation: feature.getProperty('HEADING'),
        size: new google.maps.Size(sizeX, sizeY),
        //22 52
        anchor: new google.maps.Point(sizeX / 2, sizeY / 2) //11 26

      };
      return {
        optimized: !(0, _browserDetect.isIE)(),
        // undocumented IE FIX
        icon: icon
      };
    },

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getFeatureInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout()
    /*dataObject, mouseEvent*/
    {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
    },
    _click: function _click(dataObject, mouseEvent) {
      window.open('https://www.marinetraffic.com/en/ais/details/ships/mmsi:' + mouseEvent.feature.getProperty('MMSI'), '_blank');
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getFeatureInfo: function _getFeatureInfo(feature) {
      return {
        mmsi: feature.getProperty('MMSI'),
        course: feature.getProperty('COURSE'),
        heading: feature.getProperty('HEADING'),
        timestamp: feature.getProperty('TIMESTAMP'),
        speed: feature.getProperty('SPEED'),
        number: feature.getProperty('NUMBER'),
        country: feature.getProperty('COUNTRY'),
        ship_name: feature.getProperty('SHIPNAME'),
        flag: feature.getProperty('FLAG'),
        status: statusArray[feature.getProperty('STATUS')]
      };
    },

    /**
     * Creates a bounds listener so that the overlay boots when the map finally loads.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.autoReloadGeoJson));
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function loadGeoJson() {
      var _this = this;

      Ember.run.join(function () {
        var map = _this.get('map');

        var bounds = map.getBounds();

        if (!bounds) {
          return;
        }

        var url = _this.get('dataObject.url');

        if (!url) {
          return;
        }

        upperVal = upperVal + offset;
        lowerVal = lowerVal + offset;

        if (upperVal >= 28480) {
          upperVal = 250;
          lowerVal = 0;
        }

        var opts = {
          data: {
            bbox: _latLngConverter.default.getBBoxString(bounds),
            count: 250,
            upper: upperVal,
            lower: lowerVal
          }
        };

        _this.get('api').request(url, opts).then(function (x) {
          var obj = _this.get('googlePropertyObject');

          if (obj) {
            var numOfNew = x.features.length;
            obj.forEach(function (y) {
              count++; //ensures that if a ship that already exists gets new location data
              //delete old asset and replace it with updated asset

              for (var i = 0; i < numOfNew; i++) {
                //for every new ship to be added in region
                if (x.features[i].properties.MMSI == y.f.MMSI) obj.remove(y);
              } //ensure there are no more than 150 assets rendered on the map
              //at any time.


              if (count + numOfNew > 150) {
                obj.remove(y);
                count--;
              }
            });
            obj.addGeoJson(x);
            console.log("num of ships on map: " + (count + numOfNew));
            count = 0;
          }
        });
      });
    },

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this2 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this2.loadGeoJson();

        _this2.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 100); //100
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      this.mapBoundsListener.remove();
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    }
  });

  _exports.default = _default;
});