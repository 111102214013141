define("sky-router-3/pods/components/input-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component based checkbox control with action for on change
   * @example {{input-checkbox checked=what onChange=(action 'changed')}}
   */
  var _default = Ember.Checkbox.extend({
    onChange: function onChange() {},
    change: function change() {
      this._super.apply(this, arguments);

      this.onChange(this.get('checked'));
    }
  });

  _exports.default = _default;
});