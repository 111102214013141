define("sky-router-3/models/asset-product", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model describes an asset product I.E. D1000 / HE7200
   */
  var model = _model.default.extend({
    /**
     * Name of the product that is suitable for display
     * to the user.
     */
    name: (0, _model.attr)('string'),

    /**
     * Indicates the product is GSM capabile.
     */
    gsm: (0, _model.attr)('boolean'),

    /**
     * Indicates the product is Iridium capable
     */
    iridium: (0, _model.attr)('boolean'),

    /**
     * Indicates if the product supports pinging
     */
    supportPing: (0, _model.attr)('boolean'),

    /**
     * Indicates if the product supports messaging
     */
    supportMessages: (0, _model.attr)('boolean'),

    /**
     * The number of geofences the device can be assigned to.
     */
    geofenceLimit: (0, _model.attr)('number'),

    /**
     * Does the device support geofenes?
     */
    supportDeviceGeofences: (0, _model.attr)('boolean'),

    /**
     * Does the device support AVR style geofences?
     */
    supportAVRGeofences: (0, _model.attr)('boolean'),

    /**
     * Does the device support circle based device side geofences
     */
    supportCircleGeofences: (0, _model.attr)('boolean'),

    /**
     * Does the device support polygon based device side geofences
     */
    supportPolyGeofences: (0, _model.attr)('boolean'),

    /**
     * Does the device support deleting all geofences from the unit at once?
     */
    supportDeleteAllGeofences: (0, _model.attr)('boolean'),

    /**
     * Does the device support sending its current parameters to SkyRouter?
     */
    supportGetParameters: (0, _model.attr)('boolean'),

    /**
     * Does the device support compound rules
     */
    supportCompoundRules: (0, _model.attr)('boolean'),

    /**
     * Does the device support the ability to remotely disable quick position?
     */
    supportCancelEmergency: (0, _model.attr)('boolean')
  });

  var _default = model;
  _exports.default = _default;
});