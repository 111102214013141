define("sky-router-3/pods/components/portal/manage/device-parameters/controls/nal-geofence-check/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    checkSecondsSetting: null,

    /**
     * Controls the NPR grouping
     */
    checkSelected: function () {
      return this.get('checkOptions').findBy('value', this.get('checkSecondsSetting.value'));
    }.property('checkSecondsSetting.value'),
    checkOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '30',
        label: intl.t('parameter.nal.geofence.agressive')
      }, {
        value: '60',
        label: intl.t('parameter.nal.geofence.normal')
      }, {
        value: '180',
        label: intl.t('parameter.nal.geofence.ocasionally')
      }, {
        value: '300',
        label: intl.t('parameter.nal.geofence.infrequently')
      }, {
        value: '1800',
        label: intl.t('parameter.nal.geofence.rarely')
      }, {
        value: '3600',
        label: intl.t('parameter.nal.geofence.saver')
      }];
    }.property(),
    actions: {
      checkChanged: function checkChanged(selected) {
        this.set('checkSecondsSetting.value', selected.value);
      }
    }
  });

  _exports.default = _default;
});