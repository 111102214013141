define("sky-router-3/pods/portal/manage/device-icons/edit/controller", ["exports", "ember-cp-validations", "sky-router-3/utils/computed-find-by", "sky-router-3/models/asset-icon"], function (_exports, _emberCpValidations, _computedFindBy, _assetIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.name': (0, _emberCpValidations.validator)('presence', true),
    termsAgree: (0, _emberCpValidations.validator)('acceptance'),
    imageFile: (0, _emberCpValidations.validator)('file', {
      allowEmpty: false,
      inputId: 'imageFile',
      sizeLimit: 100000,
      allowTypes: /image\/*/
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    canSave: Ember.computed('model.isNew', 'validations.isValid', 'validations.attrs.model.name.isValid', 'validations.attrs.termsAgree.isValid', function () {
      if (this.model.isNew || this.imageFile) {
        return this.validations.isValid;
      }

      return this.validations.attrs.model.name.isValid;
    }),
    orientationSelected: (0, _computedFindBy.default)('orientations', 'id', 'model.orientation', true),
    orientations: Ember.computed(function () {
      return [{
        id: 0,
        icon: 'assets/images/assets/orientations/top.svg',
        label: this.intl.t('deviceIcons.orientations.top')
      }, {
        id: 1,
        icon: 'assets/images/assets/orientations/side.svg',
        label: this.intl.t('deviceIcons.orientations.side')
      }, {
        id: 2,
        icon: 'assets/images/assets/orientations/front.svg',
        label: this.intl.t('deviceIcons.orientations.front')
      }];
    }),
    sizeSelected: (0, _computedFindBy.default)('sizes', 'id', 'model.size', true),
    sizes: _assetIcon.ICON_SIZE_ARRAY,
    actions: {
      save: function save(icon) {
        this.send('saveIcon', document.getElementById('imageFile').files[0], icon);
      },
      selectOrientation: function selectOrientation(orientation) {
        this.model.set('orientation', orientation.id);
      },
      selectSize: function selectSize(size) {
        this.model.set('size', size.id);
      }
    }
  });

  _exports.default = _default;
});