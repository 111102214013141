define("sky-router-3/pods/portal/manage/device-compound-rules/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['search', 'sortProperty', 'sortDesc'],
    search: null,
    sortProperty: 'name',
    sortDesc: false,

    /**
     * Filtering
     */
    filteredRules: Ember.computed.filter('model', ['search', 'model.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search);
      }

      return !item.get('isReadOnly');
    }),

    /**
     * Sorting
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),
    sortedRules: Ember.computed.sort('filteredRules', 'sortBy'),
    priorities: [{
      label: 'Low',
      value: 0
    }, {
      label: 'Low-Medium',
      value: 2
    }, {
      label: 'Medium (default)',
      value: 5
    }, {
      label: 'Medium-High',
      value: 7
    }, {
      label: 'High',
      value: 8
    }, {
      label: 'Very High',
      value: 10
    }],
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      }
    }
  });

  _exports.default = _default;
});