define("sky-router-3/pods/components/portal/settings/security/two-factor-auth/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Passed in user who this component is editing
     */
    user: null,

    /**
     * Is this control allowed to setup two factor auth for the passed in user?
     */
    allowSetup: true,

    /**
     * Store reset 2FA modal state
     * @type {Boolean}
     */
    confirmTwoFactorReset: false,

    /**
     * Possible frequency options
     * @type {Array}
     */
    frequencyOptions: function () {
      var intl = this.get('intl');
      return [{
        id: 0,
        label: intl.t('two-factor.settings.frequencyOptions.disabledTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.disabled')
      }, {
        id: 1,
        label: intl.t('two-factor.settings.frequencyOptions.monthlyTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.monthly')
      }, {
        id: 2,
        label: intl.t('two-factor.settings.frequencyOptions.onceTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.once')
      }, {
        id: 3,
        label: intl.t('two-factor.settings.frequencyOptions.dailyTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.daily')
      }];
    }.property(),
    frequencySelected: function () {
      var opts = this.get('frequencyOptions');
      return opts.findBy('id', this.get('user.authyUser.twoFactorFrequency')) || opts[1];
    }.property('user.authyUser.twoFactorFrequency'),
    willDestroyElement: function willDestroyElement() {
      this.get('user.authyUser').then(function (u) {
        if (u) {
          u.rollbackAttributes();
        }
      });
    },
    actions: {
      update: function update() {
        this.get('user.authyUser').then(function (u) {
          return u.save();
        });
      },
      changeFrequency: function changeFrequency(opt) {
        this.set('user.authyUser.twoFactorFrequency', opt.id);
      },
      setup: function setup(form) {
        var record = this.get('store').createRecord('authyUser', {
          countryCode: form.countryCode,
          phoneNumber: form.phone,
          twoFactorFrequency: form.frequency
        });
        return record.save({
          adapterOptions: {
            data: {
              Password: form.password,
              Email: this.get('user.email')
            }
          }
        });
      },
      completeSetup: function completeSetup() {
        this.get('user').reload();
      },
      resetTwoFactor: function resetTwoFactor() {
        this.set('confirmTwoFactorReset', true);
      },
      closeReset: function closeReset() {
        this.set('confirmTwoFactorReset', false);
      }
    }
  });

  _exports.default = _default;
});