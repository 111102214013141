define("sky-router-3/pods/portal/manage/contacts/edit/route", ["exports", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map"], function (_exports, _latLngConverter, _create, _stateTrackMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        contact: params.contact_id == 'new' ? this.store.createRecord('contact') : this.store.findRecord('contact', params.contact_id),
        mapConfig: (0, _create.default)(_stateTrackMap.default)
      });
    },
    afterModel: function afterModel(model) {
      model.mapConfig.set('center', {
        lat: _latLngConverter.default.milliArcSecondsToDecimalDeg(model.contact.get('latitudeMilliarcseconds')),
        lng: _latLngConverter.default.milliArcSecondsToDecimalDeg(model.contact.get('longitudeMilliarcseconds'))
      });
    },
    actions: {
      /**
       * Make sure the user can't leave the route without rolling back
       */
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.contact.get('isNew')) {
          model.contact.destroyRecord();
        } else {
          model.contact.rollbackAttributes();
        }
      },

      /**
       * Saves the contact and redirects the user
       */
      save: function save() {
        var _this = this;

        this.send('blockApp', true);
        this.modelFor(this.routeName).contact.save().then(function () {
          _this.transitionTo('portal.manage.contacts');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});