define("sky-router-3/pods/components/portal/manage/device-parameters/controls/start-movement/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Passed in settings
     * @type {[type]}
     */
    enabledSetting: null,
    disabled: false
  });

  _exports.default = _default;
});