define("sky-router-3/pods/components/portal/settings/time-zone/component", ["exports", "sky-router-3/utils/constants", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _constants, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'user.dateFormat': (0, _emberCpValidations.validator)('presence', true),
    'user.timeFormat': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * The user that this component is working on
     * @type {sky-router-3/models/user}
     */
    user: null,

    /**
     * Generates the timezone array used by the model
     */
    timeZones: _constants.timeZones,

    /**
     * Gets the currently selected timezone for the user
     */
    selectedTimeZone: (0, _computedFindBy.default)('timeZones', 'id', 'user.timeZone', true),

    /**
     * Is the displayed datetime format custom?
     * @return {[type]} [description]
     */
    formatIsCustom: Ember.computed('formatSelected', function () {
      return this.formatSelected.date == '';
    }),
    formatSelected: Ember.computed('user.dateFormat', 'user.timeFormat', function () {
      var _this = this;

      return this.formats.find(function (format) {
        return format.date == _this.user.get('dateFormat') && format.time == _this.user.get('timeFormat');
      }) || this.formats[0];
    }),
    formats: Ember.computed(function () {
      return [{
        label: this.intl.t('settings.timezone.formats.custom'),
        date: '',
        time: ''
      }, {
        label: this.intl.t('settings.timezone.formats.ampm'),
        date: 'MM-dd-yyyy',
        time: ' hh:mm:ss tt'
      }, {
        label: this.intl.t('settings.timezone.formats.24hour'),
        date: 'MM-dd-yyyy',
        time: ' HH:mm:ss'
      }, {
        label: this.intl.t('settings.timezone.formats.international'),
        date: 'dd-MM-yyyy',
        time: ' HH:mm:ss'
      }, {
        label: this.intl.t('settings.timezone.formats.sortable'),
        date: 'yyyy-MM-dd',
        time: ' HH:mm:ss'
      }, {
        label: this.intl.t('settings.timezone.formats.iso8601'),
        date: 'yyyy-MM-dd',
        time: 'THH:mm:sszzz'
      }];
    }),
    actions: {
      /**
       * Fired from the UI this tries to pull all the users time
       * settings from their machine and load them into the users
       * profile.
       */
      loadTimeSettingsFromComputer: function loadTimeSettingsFromComputer() {
        var offset = new Date().getTimezoneOffset() * -1;

        if (offset % 60) {
          this.set('user.timeZone', offset);
        } else {
          this.set('user.timeZone', offset / 60);
        }
      },
      changeTimezone: function changeTimezone(zone) {
        this.set('user.timeZone', zone.id);
      },
      changeTimeFormat: function changeTimeFormat(format) {
        this.user.set('dateFormat', format.date);
        this.user.set('timeFormat', format.time);
      }
    }
  });

  _exports.default = _default;
});