define("sky-router-3/pods/playback/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.playbackGenerate');
    },
    model: function model() {
      return this.store.findAll('asset');
    }
  });

  _exports.default = _default;
});