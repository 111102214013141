define("sky-router-3/models/asset-compound-rule-revision", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * When a conflict occurs this priority is used to determine
     * which rule is actioned upon.
     * Value values are 0-10 where 10 is most important
     * @type {number}
     */
    priority: (0, _model.attr)('number', {
      defaultValue: 5
    }),

    /**
     * How many seconds the conditions must be true before the rule
     * is activated. Max Value: 1,275 seconds
     * @type {number}
     */
    entranceDelay: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Once a rule is activated how many seconds the conditions can be false
     * before the rule is deactivated.
     * Max Value: 1275 seconds
     * @type {number}
     */
    exitDelay: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * A optional comment description associated with revision.
     * @type {string}
     */
    comment: (0, _model.attr)('string'),

    /**
     * The date this revision was created
     * @type {date}
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * The user that created this revision
     * @type {sky-router-3/models/user}
     */
    creator: (0, _model.belongsTo)('user'),

    /**
     * The rule associated with this revision
     * @type {sky-router-3/models/asset-compound-rule}
     */
    rule: (0, _model.belongsTo)('asset-compound-rule', {
      inverse: 'revisions'
    }),

    /**
     * The rule outputs associated with this revision
     * @type {Array<sky-router-3/models/asset-compound-rule-revision-output>}
     */
    outputs: (0, _model.hasMany)('asset-compound-rule-revision-output', {
      async: false
    }),

    /**
     * Condition groups associated with this revision.
     * @type {Array<sky-router-3/models/asset-compound-rule-revision-condition-group>}
     */
    conditionGroups: (0, _model.hasMany)('asset-compound-rule-revision-condition-group', {
      async: false
    })
  });

  _exports.default = _default;
});