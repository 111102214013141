define("sky-router-3/pods/portal/report/invoiceplanusage/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * The invoice date for the sbd plans
     * @type {String}
     */
    sbdInvoiceDate: function () {
      var date = this.get('model.dataPlans.firstObject.invoiceDate');
      return date === undefined ? '' : date.getMonth() + 1 + '/' + date.getFullYear();
    }.property('model.dataPlans'),

    /**
     * The invoice date for the voice plans
     * @type {String}
     */
    voiceInvoiceDate: function () {
      var date = this.get('model.voicePlans.firstObject.invoiceDate');
      return date === undefined ? '' : date.getMonth() + 1 + '/' + date.getFullYear();
    }.property('model.voicePlans')
  });

  _exports.default = _default;
});