define("sky-router-3/pods/components/portal/track/map/shape-rectangle/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component handles drawing tasks on a map.
   * @see https://developers.google.com/maps/documentation/javascript/reference/polygon#Polygon
   * @example {{drawing-manager map=map mode=google.maps.drawing.OverlayType.CIRCLE circleCompleted='circle' polygonCompleted='polygon' }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    googlePropertyObject: Ember.computed.alias('_instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'options',
      setFunc: 'setOptions'
    }, {
      event: 'bounds_changed',
      property: 'bounds',
      setFunc: 'setBounds',
      eFunc: '_onBoundsChanged'
    }],
    onBoundsChanged: function onBoundsChanged()
    /*bounds*/
    {},
    _onBoundsChanged: function _onBoundsChanged(rectangle) {
      this.onBoundsChanged(rectangle.getBounds());
    },

    /**
     * The instance of the drawing manager
     * @type {google.maps.Polygon}
     */
    _instance: null,

    /**
     * Passed in map instance the drawing manager is associated with
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * The LatLngBounds
     * @see https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds
     * @type {LatLngBounds}
     */
    bounds: null,

    /**
     * Options object that is used to configure the instance
     * @type {google.maps.PolygonOptions}
     */
    options: Ember.computed(function () {
      return {
        draggable: true,
        editable: true,
        fillColor: 'yellow',
        fillOpacity: 0.25,
        strokeColor: '#000000',
        strokeOpacity: 0.5,
        geodesic: true
      };
    }),

    /**
     * Init the instance
     */
    didInsertElement: function didInsertElement() {
      this.set('_instance', new google.maps.Rectangle(this.options));
    },

    /**
     * Destroy the instance
     */
    willDestroyElement: function willDestroyElement() {
      this._instance.setMap(null);
    }
  });

  _exports.default = _default;
});