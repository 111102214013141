define("sky-router-3/pods/components/dynamic-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Displays a dynamic form to the user.
   */
  var _default = Ember.Component.extend({
    /**
     * Action placeholders
     * @param {String} jsonString
     */
    onsave: function onsave()
    /*jsonString*/
    {},

    /**
     * Holds the instance to the iFrame document
     * @type {document}
     */
    _formDocument: null,

    /**
     * Form data to use as storage for the form.
     * This is a JSON string
     * @type {string}
     */
    data: null,

    /**
     * The form template file to load
     * @type {sky-router-3/models/form-template-file}
     */
    file: null,

    /**
     * Has the form been loaded?
     * @type {Boolean}
     */
    loaded: false,

    /**
     * Is the form read only?
     * @type {Boolean}
     */
    readOnly: false,

    /**
     * Should the form be auto-saved when being closed?
     * @type {Boolean}
     */
    autoSave: false,

    /**
     * If the component is being unloaded issue a save to ensure everything is properly recorded.
     */
    willDestroy: function willDestroy() {
      if (!this.readOnly && this.autoSave && this.get('_formDocument.formsApi')) {
        this._formDocument.formsApi.save();
      }

      this._formDocument = null;

      this._super.apply(this, arguments);
    },
    actions: {
      templateLoaded: function templateLoaded(event) {
        if (event.target.contentDocument.formsApi) {
          this._formDocument = event.target.contentDocument;
          this.set('loaded', true);

          if (this.data) {
            this._formDocument.formsApi.load(this.data, this.readOnly);
          }

          if (!this.readOnly) {
            this._formDocument.formsApi._performSave = this.onsave;
          }
        }
      }
    }
  });

  _exports.default = _default;
});