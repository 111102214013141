define("sky-router-3/helpers/find-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper returns a value present in the array by the findBy function.
   *
   * @example {{find-by array prop=property value=value}}
   * @see http://emberjs.com/blog/2015/06/12/ember-1-13-0-released.html#toc_new-ember-js-helper-api
   */
  var _default = Ember.Helper.extend({
    /**
     * Actually performs the computation.
     */
    compute: function compute(params, hash) {
      // Validate
      if (!Ember.isArray(params[0])) {
        return undefined;
      }

      var prop = hash.prop === undefined ? params[1] : hash.prop;
      var value = hash.value === undefined ? params[2] : hash.value; // Return

      return params[0].findBy(prop, value);
    }
  });

  _exports.default = _default;
});