define("sky-router-3/pods/portal/settings/security/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.security');
    },

    /**
     * The session account service that holds all the information about the
     * users session.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Model for the main route of settings
     * @return {[type]} [description]
     */
    model: function model() {
      return this.get('sessionAccount');
    },
    actions: {
      willTransition: function willTransition() {
        var _this = this;

        var user = this.modelFor(this.routeName).get('user');

        if (user.get('hasDirtyAttributes')) {
          user.save().then(function () {
            _this.get('sessionAccount.session').authenticate('authenticator:web-api', {
              refresh: true
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});