define("sky-router-3/pods/portal/manage/device-compound-rules/assignment/add/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        asset: this.store.findRecord('asset', params.asset_id),
        assignments: this.store.query('asset-compound-rule-asset', {
          asset_id: params.asset_id
        }),
        rules: this.store.findAll('asset-compound-rule')
      });
    },
    resetController: function resetController(controller) {
      controller.set('selected', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-compound-rules.assignment');
      },
      save: function save(asset, rule) {
        var _this = this;

        this.send('blockApp', true);
        rule.get('revisionLatest').then(function (revision) {
          return _this.store.createRecord('asset-compound-rule-asset', {
            asset: asset,
            rule: rule,
            revision: revision,
            createdDate: new Date(),
            updatedDate: new Date()
          }).save().then(function (assignment) {
            _this.send('send', assignment);

            _this.transitionTo('portal.manage.device-compound-rules.assignment');
          });
        }).catch(function () {
          return _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          return _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});