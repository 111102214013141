define("sky-router-3/models/position-metadata", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model describes the position metadata records that exist in the database.
   */
  var model = _model.default.extend({
    /**
     * Position report associated with this metadata
     */
    assetPosition: (0, _model.belongsTo)('asset-position'),

    /**
     * Asset associated with this entry
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The metadata type
     */
    metadataType: (0, _model.belongsTo)('position-metadata-type'),

    /**
     * The value for the metadata
     */
    metadataValue: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});