define("sky-router-3/pods/public/alert/route", ["exports", "sky-router-3/utils/load-google-map", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map", "sky-router-3/utils/lat-lng-converter"], function (_exports, _loadGoogleMap, _create, _stateTrackMap, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return (0, _loadGoogleMap.default)({
        language: this.get('intl').discoverLocale()
      });
    },
    model: function model(params, transition) {
      var lat = parseFloat(transition.to.queryParams.lat) || 0;
      var lng = parseFloat(transition.to.queryParams.lng) || 0;

      if (Math.abs(lng) > 180 || Math.abs(lat) > 90) {
        lat = _latLngConverter.default.milliArcSecondsToDecimalDeg(lat);
        lng = _latLngConverter.default.milliArcSecondsToDecimalDeg(lng);
      }

      return Ember.RSVP.hash({
        mapConfig: (0, _create.default)(_stateTrackMap.default, {
          center: {
            lat: lat,
            lng: lng
          },
          zoom: 10
        }),
        point: {
          lat: lat,
          lng: lng
        }
      });
    },
    actions: {
      mapAction: function mapAction() {// do nothing
      }
    }
  });

  _exports.default = _default;
});