define("sky-router-3/pods/components/portal/manage/device-profiles/nal-address-book/component", ["exports", "sky-router-3/utils/computed-promise"], function (_exports, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    /**
     * Indicates if the profile will be readOnly
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Object holding revision settings that can have any number of
     * key and values
     * @type {Object}
     */
    settings: null,

    /**
     * Powers the types of dropdowns to present to the user
     */
    types: [{
      id: '0',
      label: 'None'
    }, {
      id: '1',
      label: 'Asset'
    }, {
      id: '2',
      label: 'Contact'
    }, {
      id: '3',
      label: 'E-Mail'
    }],
    assets: (0, _computedPromise.default)(function () {
      return this.store.findAll('asset');
    }, []),
    contacts: (0, _computedPromise.default)(function () {
      return this.store.findAll('contact');
    }, []),

    /**
     * Constructs an object containing all the properties for each row of the address book.
     * @type {type, id, name, email}
     */
    rows: Ember.computed('settings.[]', function () {
      return this.settings.reduce(function (result, setting) {
        var matches = /addressbook_(\d+)_(\w+)/.exec(setting.key);

        if (matches) {
          var index = matches[1];
          var key = matches[2];
          if (!result[index]) result[index] = {};
          result[index][key] = setting;
        }

        return result;
      }, []);
    }),
    // Insert test data
    // didInsertElement() {
    //     this._super(...arguments);
    //     for (let i = 0; i < 99; i++) {
    //         this.settings.pushObject(this.store.createRecord('asset-profile-set-revision-setting', { key: `addressbook_${i}_type` }));
    //         this.settings.pushObject(this.store.createRecord('asset-profile-set-revision-setting', { key: `addressbook_${i}_id` }));
    //         this.settings.pushObject(this.store.createRecord('asset-profile-set-revision-setting', { key: `addressbook_${i}_name` }));
    //         this.settings.pushObject(this.store.createRecord('asset-profile-set-revision-setting', { key: `addressbook_${i}_email` }));
    //     }
    // },
    actions: {
      onSelectType: function onSelectType(row, selected) {
        row.type.value = selected.id;
        row.id.value = null;
        row.name.value = null;
        row.email.value = null;
      },
      onSelectDropdown: function onSelectDropdown(row, selected) {
        row.id.value = selected.id;
        row.name.value = selected.name || selected.fullName;
      }
    }
  });

  _exports.default = _default;
});