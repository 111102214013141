define("sky-router-3/utils/password-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generatePassword;

  /**
   * Generates a random password
   * GOT from here: http://www.frontcoded.com/javascript-generate-passwords.html
   *
   * @param numLc Number of lowercase letters to be used (default 4)
   * @param numUc Number of uppercase letters to be used (default 4)
   * @param numDigits Number of digits to be used (default 4)
   * @param numSpecial Number of special characters to be used (default 2)
   * @returns {*|string|String}
   */
  function generatePassword(numLc, numUc, numDigits, numSpecial) {
    numLc = numLc || 4;
    numUc = numUc || 4;
    numDigits = numDigits || 4;
    numSpecial = numSpecial || 2;
    var lcLetters = 'abcdefghijklmnopqrstuvwxyz';
    var ucLetters = lcLetters.toUpperCase();
    var numbers = '0123456789';
    var special = '!?=#*$@+-.)(&^%~[]{}"\':;/><,';

    var getRand = function getRand(values) {
      return values.charAt(Math.floor(Math.random() * values.length));
    }; //+ Jonas Raoni Soares Silva
    //@ http://jsfromhell.com/array/shuffle [v1.0]


    function shuffle(o) {
      //v1.0
      for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x) {
        ;
      }

      return o;
    }

    var pass = [];

    for (var i = 0; i < numLc; ++i) {
      pass.push(getRand(lcLetters));
    }

    for (var n = 0; n < numUc; ++n) {
      pass.push(getRand(ucLetters));
    }

    for (var d = 0; d < numDigits; ++d) {
      pass.push(getRand(numbers));
    }

    for (var p = 0; p < numSpecial; ++p) {
      pass.push(getRand(special));
    }

    return shuffle(pass).join('');
  }
});