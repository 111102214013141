define("sky-router-3/pods/portal/manage/device-parameters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FZSzAwBi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.device-parameters.index\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.setTitle\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\"],[\"portal.manage.device-parameters.assignment\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.setAssignmentTitle\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"active-link\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/device-parameters/template.hbs"
  });

  _exports.default = _default;
});