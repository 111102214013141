define("sky-router-3/pods/account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qnx8epL1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"alertsystem-container\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"position\"],[\"top-right\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[6,[37,3],null,[[\"class\",\"action\"],[\"btn-primary\",[30,[36,2],[\"logUserOut\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-logout ico-fw\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonLogOut\"],null]]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"notification-container\",\"route-action\",\"bs-button\",\"-outlet\",\"component\"]}",
    "moduleName": "sky-router-3/pods/account/template.hbs"
  });

  _exports.default = _default;
});