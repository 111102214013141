define("sky-router-3/pods/portal/manage/device-compound-rules/assignment/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selected: null,
    options: function () {
      var _this = this;

      return this.get('model.rules').map(function (rule) {
        return {
          rule: rule,
          disabled: _this.get('model.assignments').isAny('revision.rule.id', rule.get('id'))
        };
      });
    }.property('model.assignments.[]', 'model.rules.[]'),
    optionsSorted: Ember.computed.sort('options', 'optionsSortedBy'),
    optionsSortedBy: ['rule.name']
  });

  _exports.default = _default;
});