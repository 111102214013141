define("sky-router-3/transforms/ember-object-array", ["exports", "@ember-data/serializer/transform", "sky-router-3/utils/convert-object"], function (_exports, _transform, _convertObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Serializes and deserializes ember object arrays for ember-data.
   *
   * @example indexArray: DS.attr('array-ember-object'),
   * @see https://stackoverflow.com/questions/12168570/how-to-represent-arrays-within-ember-data-models
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized
    /*, options*/
    ) {
      if (serialized) {
        return Ember.makeArray(serialized.map(function (i) {
          return (0, _convertObject.default)(i, 'camelize', true);
        }));
      }
    },
    serialize: function serialize(deserialized) {
      switch (Ember.typeOf(deserialized)) {
        case 'array':
          return (0, _convertObject.default)(deserialized, 'classify', false);

        default:
          return [];
      }
    }
  });

  _exports.default = _default;
});