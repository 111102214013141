define("sky-router-3/pods/components/portal/track/map/overlays/noaa-sigmet-int/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object"], function (_exports, _googleObjectMapping, _computedJsonObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Array of data features that are present in the data object.
     * @type {Array}
     */
    features: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }, {
      event: 'addfeature',
      eFunc: '_addFeature'
    }, {
      event: 'removefeature',
      eFunc: '_remFeature'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each record downloaded.
     * It is organized so that the ones with the smallest area are on top.
     */
    style: function () {
      var _this = this;

      return function (feature) {
        var color = _this.getStyleColor(feature);

        return {
          strokeWeight: 2,
          strokeColor: color,
          fillColor: color,
          fillOpacity: 0.10,
          zIndex: feature.getProperty('severity') * -1
        };
      };
    }.property(),
    getStyleColor: function getStyleColor(feature) {
      switch (feature.getProperty('hazard')) {
        case 'CONVECTIVE':
          return '#0000FF';

        case 'TURB':
          return '#00FF00';

        case 'IFR':
          return '#500090';

        case 'MTN OBSCN':
          return '#A000A0';

        case 'ASH':
          return '#FF7900';

        case 'ICE':
        case 'ICING':
          return '#00AAFF';

        case 'TS':
          return '#990000';

        case 'TSGR':
          return '#C34242';

        case 'LLWS':
          return '#B7410E';

        case 'MTW':
          return '#995500';

        case 'TC':
          return '#996600';

        case 'SS':
        case 'DS':
          return '#CC9900';

        case 'VA':
          return '#CC0099';

        case 'RDOACT CLD':
          return '#FFFF00';
      }
    },
    labelFeatures: function () {
      var _this2 = this;

      return this.get('features').map(function (feature) {
        var color = _this2.getStyleColor(feature);

        return {
          label: feature.getProperty('hazard'),
          latLng: _this2._calculateGeometryCenter(feature),
          style: "background-color:".concat(color, ";color:white;border-radius:3px;padding:2px;").htmlSafe()
        };
      });
    }.property('features.[]'),

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getWindowInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
      dataObject.overrideStyle(mouseEvent.feature, {
        strokeWeight: 4
      });
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout(dataObject, mouseEvent) {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
      dataObject.revertStyle(mouseEvent.feature);
    },

    /**
     * Launches the FAA link for the TFR.
     */
    _click: function _click(dataObject, mouseEvent) {
      this.set('popupBody', this._getWindowInfo(mouseEvent.feature));
      this.set('popupOpen', true);
    },

    /**
     * Keeps ember in sync with the google maps data object
     */
    _addFeature: function _addFeature(data, evt) {
      this.get('features').addObject(evt.feature);
    },
    _remFeature: function _remFeature(data, evt) {
      this.get('features').removeObject(evt.feature);
    },

    /**
     * Gets the center of the TFR geometry so the info window has somewhere to anchor to.
     */
    _calculateGeometryCenter: function _calculateGeometryCenter(feature) {
      var bounds = new google.maps.LatLngBounds();
      var geometry = feature.getGeometry();

      if (geometry) {
        geometry.forEachLatLng(function (latLng) {
          bounds.extend(latLng);
        });
      }

      var bbox = feature.getProperty('bbox');

      if (bbox) {
        var _bbox$split$map = bbox.split(',').map(parseFloat),
            _bbox$split$map2 = _slicedToArray(_bbox$split$map, 4),
            west = _bbox$split$map2[0],
            south = _bbox$split$map2[1],
            east = _bbox$split$map2[2],
            north = _bbox$split$map2[3];

        bounds.extend({
          lat: south,
          lng: west
        });
        bounds.extend({
          lat: north,
          lng: east
        });
      }

      return bounds.getCenter();
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getWindowInfo: function _getWindowInfo(feature) {
      return {
        icao: feature.getProperty('icaoId'),
        firId: feature.getProperty('firId'),
        firName: feature.getProperty('firName'),
        hazard: feature.getProperty('hazard'),
        validTimeFrom: feature.getProperty('validTimeFrom'),
        validTimeTo: feature.getProperty('validTimeTo'),
        qualifier: feature.getProperty('qualifier'),
        base: feature.getProperty('base'),
        top: feature.getProperty('top'),
        sigmet: feature.getProperty('rawSigmet')
      };
    },
    init: function init() {
      this.set('features', []);

      this._super.apply(this, arguments);
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function loadGeoJson() {
      var _this3 = this;

      Ember.run.join(function () {
        var url = _this3.get('dataObject.url');

        if (!url) {
          return;
        }

        var opts = {
          dataType: 'jsonp',
          jsonp: 'jsonp'
        };

        _this3.get('api').request(url, opts).then(function (x) {
          var obj = _this3.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });

            _this3.closeGeometry(x);

            obj.addGeoJson(x);
          }
        });
      });
    },

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this4 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this4.loadGeoJson();

        _this4.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },

    /**
     * Sometimes this layer comes in with unclosed polygons.
     * Google maps explodes when this happens. Fix it by closing the polygon.
     * Closing the polygon means that the start index must equal the end index and the length must be at least 2.
     */
    closeGeometry: function closeGeometry(geoJSON) {
      geoJSON.features.forEach(function (f) {
        if (f.type === "Feature" && f.geometry && f.geometry.type === "Polygon") {
          f.geometry.coordinates.forEach(function (cords) {
            var start = cords[0];
            var end = cords[cords.length - 1];

            if (start[0] !== end[0] || start[1] !== end[1] || cords.length == 1) {
              cords.push(start);
            }
          });
        }
      });
    },
    actions: {
      closePopup: function closePopup() {
        this.set('popupOpen', false);
        this.set('popupBody', null);
      }
    }
  });

  _exports.default = _default;
});