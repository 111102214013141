define("sky-router-3/utils/convert-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertUnit;

  /**
   * Converts the centimeter value into the unit supplied.
   * @param  {sky-router-3/models/unit-*} unit
   * @param  {Number} cm   centimeter value to converter
   * @param  {Boolean} showUnit tack on the unit at the end of the value
   * @return {String}      Displayable value
   */
  function convertUnit(unit, cm) {
    var showUnit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var value = cm * unit.get('centimeterFactor');
    var decimals = Math.pow(10, unit.get('decimalCount'));
    var computed = Math.floor(value * decimals) / decimals;

    if (showUnit) {
      return computed.toLocaleString() + unit.get('shortName');
    }

    return computed;
  }
});