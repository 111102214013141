define("sky-router-3/pods/portal/report/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report'],

    /**
     * The translation services
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.reports');
    },
    actions: {
      /**
       * Sends the response file to the client user. Creates a hyperlink to the object URL that is added to the DOM,
       * clicked, then removed immediately
       * @param  {HTTP Response} response API response to the requested export
       * @param  {string} fileName The export file name
       * @return {void}
       */
      sendResponseToClient: function sendResponseToClient(response, fileName) {
        // If IE, download the file this way. Security for IE doesn't allow javascript click downloads
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(response, fileName);
        } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(response);
          a.download = fileName; // Append anchor to body.

          document.body.appendChild(a);
          a.click(); // Remove anchor from body

          document.body.removeChild(a);
        }
      }
    }
  });

  _exports.default = _default;
});