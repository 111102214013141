define("sky-router-3/pods/components/portal/manage/device-parameters/controls/bluetooth-security/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z4HBW9m8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,9],[\"parameter.bluetoothSecurity.title\"],null]],[2,\" \"],[10,\"i\"],[14,0,\"fa fa-bluetooth\"],[12],[13],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,9],[\"parameter.bluetoothSecurity.help\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,7,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,10],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,8],null,[[\"checked\",\"disabled\",\"onChange\"],[[35,7,[\"valueBoolean\"]],[35,2],[30,[36,6],[[32,0],\"enabledChanged\"],null]]]]],[2,\" \"],[1,[30,[36,9],[\"parameter.bluetoothSecurity.requireSecurity\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,1],[[35,7,[\"valueBoolean\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"type\",\"class\",\"value\",\"disabled\",\"min\",\"max\"],[\"number\",\"form-control\",[35,0,[\"value\"]],[35,2],[35,0,[\"validationMinNumber\"]],[35,0,[\"validationMaxNumber\"]]]]]],[2,\"\\n            \"],[1,[30,[36,5],null,[[\"errors\"],[[35,4,[\"attrs\",\"pairingCodeSetting\",\"value\",\"messages\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pairingCodeSetting\",\"if\",\"disabled\",\"input\",\"validations\",\"input-errors\",\"action\",\"enabledSetting\",\"input-checkbox\",\"t\",\"input-label\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/bluetooth-security/template.hbs"
  });

  _exports.default = _default;
});