define("sky-router-3/helpers/unique-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is a helper method for the ember array unique by method.
   * @param  {Array} params Array containing just the array to modify
   * @param  {[type]} hash   [description]
   * @return {[type]}        [description]
   */
  var _default = Ember.Helper.helper(function uniqueBy(params, hash) {
    if (!Ember.isArray(params[0])) {
      return undefined;
    }

    return params[0].uniqBy(hash.prop);
  });

  _exports.default = _default;
});