define("sky-router-3/utils/moment-timezone-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTimezoneMinutes;

  /**
   * Converts moment offsetValue to minutes.
   */
  function getTimezoneMinutes(timezone) {
    if (Math.abs(timezone) > 16) {
      return timezone;
    }

    return timezone * 60;
  }
});