define("sky-router-3/pods/portal/settings/notifications/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.notifications');
    },

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.sessionAccount.user,
        userSounds: this.store.findAll('user-sound'),
        assetPositionTypes: this.store.findAll('asset-position-type'),
        alertTriggerEvents: this.store.findAll('alert-trigger-event'),
        pushAlertTrigger: this.store.findRecord('user-push-notification-alert-trigger', this.sessionAccount.user.id)
      });
    },
    actions: {
      /**
       * Saves the changes to the model
       */
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.user.get('hasDirtyAttributes')) {
          model.user.save();
        }

        model.pushAlertTrigger.save();
      },
      reset: function reset() {
        return this.modelFor(this.routeName).pushAlertTrigger.reset();
      }
    }
  });

  _exports.default = _default;
});