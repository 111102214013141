define("sky-router-3/pods/components/portal/track/map/overlays/mgrs-grid/component", ["exports", "sky-router-3/utils/geo-conversion-tools"], function (_exports, _geoConversionTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * @type {google.maps.PolyLine[]}
     */
    gzdlines: null,
    gzdlabels: null,
    onehundredkmlines: null,
    onehundredkmlabels: null,

    /**
     * @type {google.maps.PolyLine[]}
     */
    minorgridlines: null,

    /**
     * Indicates if the line is geodesic or not
     * @type {Boolean}
     */
    geodesic: false,

    /**
     * Incoming HEX color for the grid zone designator paths
     */
    gzdcolor: '#33ee33',
    swMGRSBound: null,
    swGeodeticBound: null,
    neMGRSBound: null,
    neGeodeticBound: null,
    boundsErrors: false,
    visiblegrids: null,

    /**
     * Init the grid instance
     */
    init: function init() {
      this.set('visiblegrids', []);
      this.set('gzdlines', []);
      this.set('gzdlabels', []);
      this.set('onehundredkmlines', []);
      this.set('onehundredkmlabels', []);
      this.updateGrid();

      this._super.apply(this, arguments);
    },
    getPI: function getPI() {
      return Math.PI;
    },
    getRadians: function getRadians(degrees) {
      return degrees * this.getPI() / 180;
    },
    getDegrees: function getDegrees(radians) {
      return radians * 180 / this.getPI();
    },
    gzdoptions: function () {
      return {
        path: [],
        geodesic: this.get('geodesic'),
        clickable: false,
        strokeWeight: 3,
        strokeColor: this.get('gzdcolor'),
        strokeOpacity: 0.7,
        zIndex: 300
      };
    }.property('geodesic', 'gzdcolor'),
    gzdLabelFeatures: function () {
      var _this = this;

      return this.get('gzdlabels').map(function (label) {
        var latLng = label.position;
        var content = label.content;

        var color = _this.get('gzdcolor');

        return {
          content: content,
          latLng: latLng,
          style: "background-color:".concat(color, ";color:white;border-radius:3px;padding:2px;position:absolute;left:8px;top:8px;").htmlSafe()
        };
      });
    }.property('gzdlabels.[]'),
    drawGZDLabels: function drawGZDLabels() {
      var zoomlevel = this.get('mapConfig.zoom');
      var latadjust = 7.375;
      var lonadjust = 5.5;

      switch (zoomlevel) {
        case 5:
          latadjust = 6.5;
          lonadjust = 4.5;
          break;

        case 7:
          latadjust += 0.25;
          break;

        case 8:
          latadjust += 0.375;
          lonadjust += 0.25;
          break;

        case 9:
          latadjust += 0.5;
          lonadjust += 0.375;
          break;

        case 10:
          latadjust += 0.6;
          lonadjust += 0.4375;
          break;
      }

      var boundErrors = this.get('boundsErrors');

      if (!boundErrors) {
        var visible = this.get('visiblegrids');
        var gridlabels = [];
        var northingArray = "CDEFGHJKLMNPQRSTUVWX".split('');
        var swlatlon = this.get('swGeodeticBound');
        var nelatlon = this.get('neGeodeticBound');

        if (swlatlon && nelatlon) {
          for (var i = 0; i < visible.length; i++) {
            var currentgrid = visible[i];
            var gridnumindex = currentgrid.replace(/[^0-9]+/g, '');
            var gridletter = currentgrid.replace(/[^A-Z]+/g, '');
            var gridcharindex = northingArray.indexOf(gridletter);
            var lat = gridcharindex * 8 - 72;
            var lon = gridnumindex * 6 - 186;

            if (lat == 80) {
              lat = 84;

              if (lat > nelatlon.latitude && lat - nelatlon.latitude < latadjust + 4) {
                lat = nelatlon.latitude;
              }
            } else if (lat > nelatlon.latitude && lat - nelatlon.latitude < latadjust) {
              lat = nelatlon.latitude;
            }

            if (gridnumindex == 31 && gridcharindex == 17) {
              // fix for 31V
              if (lon < swlatlon.longitude && swlatlon.longitude - lon < lonadjust - 4) {
                lon = swlatlon.longitude;
              }
            } else if (gridnumindex == 32 && gridcharindex == 17) {
              // fix for 32V
              lon = 2;

              if (lon < swlatlon.longitude && swlatlon.longitude - lon < lonadjust + 4) {
                lon = swlatlon.longitude;
              }
            } else if (gridnumindex >= 31 && gridnumindex < 38 && gridcharindex == 19) {
              // fix for 31X to 37X
              if (gridnumindex > 31) {
                lon = (gridnumindex - 33) * 6 + 8;
              }

              var svalbardadjust = 6;

              if (gridnumindex == 31) {
                svalbardadjust = 2;
              }

              if (gridnumindex == 37) {
                svalbardadjust = 4;
              }

              if (lon < swlatlon.longitude && swlatlon.longitude - lon < lonadjust + svalbardadjust) {
                lon = swlatlon.longitude;
              }
            } else if (lon < swlatlon.longitude && swlatlon.longitude - lon < lonadjust) {
              lon = swlatlon.longitude;
            }

            if (lat == 0) {
              lat += 1e-10;
            }

            if (lon == 0) {
              lon += 1e-10;
            } // fix for 31X to 37X


            if (gridcharindex == 19 && gridnumindex >= 31 && gridnumindex < 38) {
              if (gridnumindex % 2 == 1) {
                gridlabels.push({
                  position: new google.maps.LatLng(lat, lon),
                  content: currentgrid
                });
              }
            } else {
              gridlabels.push({
                position: new google.maps.LatLng(lat, lon),
                content: currentgrid
              });
            }
          }

          this.set('gzdlabels', gridlabels);
        }
      }
    },
    drawGZDLines: function drawGZDLines() {
      var lines = [];
      var gzdOpts = this.get('gzdoptions');
      var maxlng = -180;

      while (maxlng <= 180) {
        var longGridCoords = [];

        if (maxlng <= 0 || maxlng > 36) {
          var _longGridCoords = [{
            lat: -80,
            lng: maxlng
          }, {
            lat: 84,
            lng: maxlng
          }];
          gzdOpts.path = _longGridCoords;
          lines.push(new google.maps.Polyline(gzdOpts));
        } else {
          // special case for norway
          if (maxlng == 6) {
            longGridCoords = [{
              lat: -80,
              lng: maxlng
            }, {
              lat: 56,
              lng: maxlng
            }];
            gzdOpts.path = longGridCoords;
            lines.push(new google.maps.Polyline(gzdOpts));
            longGridCoords = [{
              lat: 64,
              lng: maxlng
            }, {
              lat: 72,
              lng: maxlng
            }];
            gzdOpts.path = longGridCoords;
            lines.push(new google.maps.Polyline(gzdOpts));
            longGridCoords = [{
              lat: 56,
              lng: 2
            }, {
              lat: 64,
              lng: 2
            }];
            gzdOpts.path = longGridCoords;
            lines.push(new google.maps.Polyline(gzdOpts));
          } else {
            longGridCoords = [{
              lat: -80,
              lng: maxlng
            }, {
              lat: 72,
              lng: maxlng
            }];
            gzdOpts.path = longGridCoords;
            lines.push(new google.maps.Polyline(gzdOpts));

            if (maxlng / 6 % 2 == 0) {
              longGridCoords = [{
                lat: 72,
                lng: maxlng - 4
              }, {
                lat: 84,
                lng: maxlng - 4
              }];
              gzdOpts.path = longGridCoords;
              lines.push(new google.maps.Polyline(gzdOpts));
            }
          }
        }

        maxlng += 6;
      }

      var maxlat = -80;

      while (maxlat <= 72) {
        var _latGridCoords = [{
          lat: maxlat,
          lng: -180
        }, {
          lat: maxlat,
          lng: 0
        }, {
          lat: maxlat,
          lng: 180
        }];
        gzdOpts.path = _latGridCoords;
        lines.push(new google.maps.Polyline(gzdOpts));
        maxlat += 8;
      } // fix for top lat of 84


      var latGridCoords = [{
        lat: 84,
        lng: -180
      }, {
        lat: 84,
        lng: 0
      }, {
        lat: 84,
        lng: 180
      }];
      gzdOpts.path = latGridCoords;
      lines.push(new google.maps.Polyline(gzdOpts));
      this.set('gzdlines', lines);
    },
    // current bugs
    // 31X - 33X - 35X - 37X
    // zones "V-W" and above (won't draw 400k/600k lines)
    // northing grid lines are not drawing correcly around equator
    // also around some random spots on the map.
    // also around the W range.
    draw100KMLines: function draw100KMLines() {
      var _this2 = this;

      var boundErrors = this.get('boundsErrors');

      if (!boundErrors) {
        var gridlines = [];
        var tenmillion = 10000000; // 10,000,000 - padding for negative values

        var oneht = 100000; // 100,000 - for calculating the 100k grid

        var gridpadding = 200000; // meters - amount to draw outside of bounds

        var SouthWestGeodetic = this.get('swGeodeticBound');
        var NorthEastGeodetic = this.get('neGeodeticBound');

        var utmnorthingrefstart = _geoConversionTools.default.getUTMCoordinates(SouthWestGeodetic.latitude, SouthWestGeodetic.longitude).output;

        var utmnorthingrefend = _geoConversionTools.default.getUTMCoordinates(NorthEastGeodetic.latitude, NorthEastGeodetic.longitude).output; // get starting longitude
        // this will be starting zone minus one's start longitude. ex:
        // zone 6 bounding longitude = -150
        // zone 5 bound longitude = -156
        // so startlongitude = -156


        var currentstartlongitude = -180 + utmnorthingrefstart.zone * 6 - 12;
        var utmnorthingstart = Math.floor(utmnorthingrefstart.northing / oneht) * oneht - gridpadding;
        var utmnorthingend = Math.floor(utmnorthingrefend.northing / oneht) * oneht + gridpadding; // coordinate reference for hemisphere
        // since 100km grid changes based on latitude

        var coordreference = {}; // check hemisphere

        if (SouthWestGeodetic.latitude >= 0 && NorthEastGeodetic.latitude > 0) {
          // northern hemisphere
          coordreference["startlat"] = SouthWestGeodetic.latitude;
          coordreference["startlon"] = SouthWestGeodetic.longitude;
          coordreference["starthemisphere"] = utmnorthingrefstart.hemisphere;
          coordreference["endlat"] = SouthWestGeodetic.latitude;
          coordreference["endlon"] = NorthEastGeodetic.longitude;
          coordreference["endhemisphere"] = utmnorthingrefstart.hemisphere;
        } else if (NorthEastGeodetic.latitude > 0 && SouthWestGeodetic.latitude < 0) {
          // around equator
          coordreference["startlat"] = 0;
          coordreference["startlon"] = SouthWestGeodetic.longitude;
          coordreference["starthemisphere"] = utmnorthingrefstart.hemisphere;
          coordreference["endlat"] = 0;
          coordreference["endlon"] = NorthEastGeodetic.longitude;
          coordreference["endhemisphere"] = utmnorthingrefend.hemisphere;
        } else if (NorthEastGeodetic.latitude <= 0 && SouthWestGeodetic.latitude < 0) {
          // southern hemisphere
          coordreference["startlat"] = NorthEastGeodetic.latitude;
          coordreference["startlon"] = SouthWestGeodetic.longitude;
          coordreference["starthemisphere"] = utmnorthingrefend.hemisphere;
          coordreference["endlat"] = NorthEastGeodetic.latitude;
          coordreference["endlon"] = NorthEastGeodetic.longitude;
          coordreference["endhemisphere"] = utmnorthingrefstart.hemisphere;
        }

        if (utmnorthingstart < 0) {
          utmnorthingstart += tenmillion;
          coordreference.starthemisphere = 'S';
        }

        if (utmnorthingend > tenmillion) {
          utmnorthingend -= tenmillion;
          coordreference.endhemisphere = 'N';
        }

        var utmeastingrefstart = _geoConversionTools.default.getUTMCoordinates(coordreference.startlat, coordreference.startlon).output;

        var utmeastingrefend = _geoConversionTools.default.getUTMCoordinates(coordreference.endlat, coordreference.endlon).output;

        var utmeastingstart = Math.floor(utmeastingrefstart.easting / oneht) * oneht;
        var utmeastingend = Math.floor(utmeastingrefend.easting / oneht) * oneht + oneht;
        var zonecount = 0;

        if (utmnorthingrefend.zone < utmnorthingrefstart.zone) {
          zonecount = 60 + utmnorthingrefend.zone - utmnorthingrefstart.zone;
        } else if (utmnorthingrefend.zone > utmnorthingrefstart.zone) {
          zonecount = utmnorthingrefend.zone - utmnorthingrefstart.zone;
        } // add one for fencing


        zonecount++;
        var startlatmineasting = 0;
        var startlatmaxeasting = 0;

        if (zonecount > 1) {
          var startlatutmstart = _geoConversionTools.default.getUTMCoordinates(coordreference.startlat, -90).output;

          var startlatutmend = _geoConversionTools.default.getUTMCoordinates(coordreference.startlat, -84.00001).output;

          startlatmineasting = Math.floor(startlatutmstart.easting / oneht) * oneht + oneht;
          startlatmaxeasting = Math.floor(startlatutmend.easting / oneht) * oneht;
        }

        var currentzone = utmnorthingrefstart.zone; // loop through each zone and add utm grid lines for the zone

        for (var zoneindex = 1; zoneindex <= zonecount; zoneindex++) {
          var currentmineasting = startlatmineasting;
          var currentmaxeasting = startlatmaxeasting;

          if (zoneindex == 1) {
            currentmineasting = utmeastingstart;
          }

          if (zoneindex == zonecount) {
            currentmaxeasting = utmeastingend;
          }

          var currentendlongitude = 0;

          if (zonecount > 1) {
            currentstartlongitude = currentstartlongitude + 6;

            if (currentstartlongitude == 180) {
              currentstartlongitude = -180;
            }

            currentendlongitude = currentstartlongitude + 6;
          } // loop through easting grid 100km at a time to build north/south easting lines


          for (var currenteasting = currentmineasting; currenteasting <= currentmaxeasting; currenteasting += oneht) {
            var startutm = _geoConversionTools.default.getGeodeticCoordinatesFromUTM(currentzone, coordreference.starthemisphere, currenteasting, utmnorthingstart);

            var endutm = _geoConversionTools.default.getGeodeticCoordinatesFromUTM(currentzone, coordreference.endhemisphere, currenteasting, utmnorthingend);

            var strokestartlatitude = startutm.output.latitude;
            var strokestartlongitude = startutm.output.longitude;
            var strokeendlatitude = endutm.output.latitude;
            var strokeendlongitude = endutm.output.longitude;
            var start = new google.maps.LatLng(strokestartlatitude, strokestartlongitude);
            var end = new google.maps.LatLng(strokeendlatitude, strokeendlongitude);
            var strokearr = new Array();
            strokearr.push(start);
            strokearr.push(end);

            if (zonecount > 1) {
              strokearr = this.truncatelines(strokearr, "ns", currentstartlongitude, currentendlongitude);
            }

            if (zonecount == 1) {
              for (var si = 0; si < strokearr.length; si++) {
                strokearr[si].show = "yes";
              }
            }

            if (strokearr[0].show === "yes") {
              var strokeoptions = {
                path: [],
                visible: true,
                clickable: false,
                strokeWeight: 3,
                strokeColor: "#FFFF00",
                strokeOpacity: 0.7,
                zIndex: 300
              };
              var startlatdeg = this.getDegrees(strokearr[0].lat());
              var startlngdeg = this.getDegrees(strokearr[0].lng());
              var endlatdeg = this.getDegrees(strokearr[1].lat());
              var endlngdeg = this.getDegrees(strokearr[1].lng());

              if (startlatdeg > 84) {
                startlatdeg = 84;
              }

              if (startlatdeg < -80) {
                startlatdeg = -80;
              }

              if (endlatdeg > 84) {
                endlatdeg = 84;
              }

              if (endlatdeg < -80) {
                endlatdeg = -80;
              }

              var latGridCoords = [{
                lat: startlatdeg,
                lng: startlngdeg
              }, {
                lat: endlatdeg,
                lng: endlngdeg
              }];
              strokeoptions.path = latGridCoords;
              gridlines.push(new google.maps.Polyline(strokeoptions));
            }
          } // loop through northing grid 100km at a time to build east/west northing lines


          var northingrefend = utmnorthingend;

          if (utmnorthingstart >= utmnorthingend) {
            northingrefend = northingrefend + tenmillion;
          }

          var currenthemisphere = coordreference.starthemisphere;

          var _loop = function _loop() {
            if (currentnorthing > tenmillion) {
              currentnorthing = oneht;
              northingrefend -= tenmillion;
              currenthemisphere = coordreference.endhemisphere;
            }

            var mineast = currentmineasting - oneht;
            var maxeast = currentmaxeasting + oneht;
            var northingpoints = new Array();

            for (cureast = mineast; cureast <= maxeast; cureast += oneht) {
              var tempcureast = cureast;

              if (tempcureast <= 100000) {
                tempcureast = 166022;
              }

              if (tempcureast >= 900000) {
                tempcureast = 833978;
              }

              var curpoint = _geoConversionTools.default.getGeodeticCoordinatesFromUTM(currentzone, currenthemisphere, tempcureast, currentnorthing);

              northingpoints.push(new google.maps.LatLng(curpoint.output.latitude, curpoint.output.longitude));
            }

            if (zonecount > 1) {
              northingpoints = _this2.truncatelines(northingpoints, "ew", currentstartlongitude, currentendlongitude);
            } else {
              for (i = 0; i < northingpoints.length; i++) {
                northingpoints[i].show = "yes";
              }
            }

            var strokeoptions = {
              path: [],
              visible: true,
              clickable: false,
              strokeWeight: 3,
              strokeColor: "#FFFF00",
              strokeOpacity: 0.7,
              zIndex: 300
            };
            northingpoints.forEach(function (point) {
              var startlatdeg = _this2.getDegrees(point.lat());

              var startlngdeg = _this2.getDegrees(point.lng());

              if (point.show === "yes" && startlatdeg < 84 && startlatdeg > -80) {
                strokeoptions.path.push({
                  lat: startlatdeg,
                  lng: startlngdeg
                });
              }
            });
            gridlines.push(new google.maps.Polyline(strokeoptions));
          };

          for (var currentnorthing = utmnorthingstart; currentnorthing <= northingrefend; currentnorthing += oneht) {
            var cureast;
            var i;

            _loop();
          }

          currentzone++;

          if (currentzone == 61) {
            currentzone = 1;
          }
        }

        this.set('onehundredkmlines', gridlines);
        this.get('onehundredkmlines').forEach(function (line) {
          return line.setMap(_this2.get('map'));
        });
      }
    },
    setBoundaryParamaters: function setBoundaryParamaters() {
      var mymap = this.get('map');
      var bounds = mymap.getBounds();

      if (bounds) {
        this.set('boundsErrors', false);
        var SouthWest = bounds.getSouthWest();
        var NorthEast = bounds.getNorthEast();
        var swlatlon = {
          latitude: SouthWest.lat(),
          longitude: SouthWest.lng()
        };
        var nelatlon = {
          latitude: NorthEast.lat(),
          longitude: NorthEast.lng()
        };

        if (swlatlon.latitude <= 84 && nelatlon.latitude >= -80) {
          if (swlatlon.latitude < -80) {
            swlatlon.latitude = -80;
          }

          if (nelatlon.latitude > 84) {
            nelatlon.latitude = 84;
          }

          this.set('swGeodeticBound', swlatlon);
          this.set('neGeodeticBound', nelatlon);

          var swmgrs = _geoConversionTools.default.getMGRSCoordinates(swlatlon.latitude, swlatlon.longitude);

          var nemgrs = _geoConversionTools.default.getMGRSCoordinates(nelatlon.latitude, nelatlon.longitude);

          this.set('swMGRSBound', swmgrs);
          this.set('neMGRSBound', nemgrs);
        }
      } else {
        this.set('boundsErrors', true);
      }
    },
    setVisibleGrids: function setVisibleGrids() {
      var visible = [];
      var boundErrors = this.get('boundsErrors');

      if (!boundErrors) {
        var swlatlon = this.get('swGeodeticBound');
        var nelatlon = this.get('neGeodeticBound');

        if (swlatlon && nelatlon) {
          var swmgrs = this.get('swMGRSBound');
          var nemgrs = this.get('neMGRSBound');

          if (swmgrs && swmgrs.errorCodes.length == 0 && nemgrs && nemgrs.errorCodes.length == 0) {
            var northingArray = "CDEFGHJKLMNPQRSTUVWX".split('');
            var swmgrsoutput = swmgrs.output.split('  ');
            var nemgrsoutput = nemgrs.output.split('  ');
            var swgridnum = parseInt(swmgrsoutput[0].replace(/[^0-9]+/g, ''));
            var negridnum = parseInt(nemgrsoutput[0].replace(/[^0-9]+/g, ''));
            var swgzdchar = swmgrsoutput[0].replace(/[^A-Z]+/g, '');
            var negzdchar = nemgrsoutput[0].replace(/[^A-Z]+/g, '');
            var gridnumindex = swgridnum;
            var gridnumdiff = negridnum - swgridnum; // e.g. swgridnum = 59 and negridnum = 2

            if (swgridnum > negridnum) {
              gridnumdiff = 60 + negridnum - swgridnum;
            }

            var gridchardiff = northingArray.indexOf(negzdchar) - northingArray.indexOf(swgzdchar);

            for (var i = 0; i <= gridnumdiff; i++) {
              var gridcharindex = northingArray.indexOf(swgzdchar);

              for (var c = 0; c <= gridchardiff; c++) {
                var gridstring = "" + gridnumindex + northingArray[gridcharindex];
                visible.push(gridstring);
                gridcharindex++;
              }

              gridnumindex++;

              if (gridnumindex > 60) {
                gridnumindex = 1;
              }
            }
          }
        }
      }

      this.set('visiblegrids', visible);
    },
    updateGrid: function updateGrid() {
      var zoomlevel = this.get('mapConfig.zoom');

      if (this.get('onehundredkmlines')) {
        this.get('onehundredkmlines').forEach(function (line) {
          return line.setMap(null);
        });
      } else {
        this.set('onehundredkmlines', []);
      }

      this.setBoundaryParamaters();

      if (!this.get('gzdlines')) {
        this.set('gzdlines', []);
      }

      if (this.get('gzdlines').length == 0) {
        this.drawGZDLines();
      }

      if (zoomlevel >= 5) {
        this.setVisibleGrids();
        this.drawGZDLabels();

        if (zoomlevel >= 7) {//this.drawMinorGridLabels();
        }
      } else {
        this.set('gzdlabels', []);
      }

      if (zoomlevel >= 6) {
        // need to draw 100km lines
        this.draw100KMLines();

        if (zoomlevel >= 10) {
          // need to draw 10km grid lines
          //this.drawTenKMGridLines();
          if (zoomlevel >= 13) {
            // need to draw 1km grid lines
            if (zoomlevel >= 17) {// need to draw 100m grid lines
            }
          }
        }
      } else {// need to clear out grid lines
        }
    },

    /**
     * Destroy the grid instance
     */
    willDestroyElement: function willDestroyElement() {
      if (this.mapBoundsListener) {
        this.mapBoundsListener.remove();
      }

      this.get('onehundredkmlines').forEach(function (line) {
        return line.setMap(null);
      });
      this.get('gzdlines').forEach(function (line) {
        return line.setMap(null);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.debounceUpdate));
    },
    debounceUpdate: function debounceUpdate() {
      Ember.run.debounce(this, this.updateGrid, 250);
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.get('gzdlines').forEach(function (line) {
        return line.setMap(_this3.get('map'));
      });
    },
    truncatelines: function truncatelines(linearray, direction, curstartlng, curendlng) {
      var length = linearray.length - 1;
      linearray[length].show = "no";

      for (var index = 0; index < length; index++) {
        linearray[index].show = "yes";
        var startlat = this.getDegrees(linearray[index].lat());
        var startlng = this.getDegrees(linearray[index].lng());

        if (startlat > 84) {
          startlat = 84;
        }

        if (startlat < -80) {
          startlat = -80;
        }

        var endlat = this.getDegrees(linearray[index + 1].lat());
        var endlng = this.getDegrees(linearray[index + 1].lng());

        if (endlat > 84) {
          endlat = 84;
        }

        if (endlat < -80) {
          endlat = -80;
        }

        var startedge = this.findedge(startlat, startlng, curstartlng, curendlng);
        var endedge = this.findedge(endlat, endlng, curstartlng, curendlng);

        if (direction == "ew" && startlng >= curendlng) {
          if (curendlng == -174 && startlng > 0) {//
          } else if (startlng > curendlng) {
            linearray[index].show = "no";
          }

          if (index < length) {
            linearray[index + 1].show = "no";
            linearray[index].show = "yes";
          }
        } else if ((startedge & endedge) != 0) {
          linearray[index].show = "no";
        } else if (startlng <= curstartlng && endlng <= curstartlng || startlng >= curendlng && endlng >= curendlng) {
          linearray[index].show = "no";
        }

        if ((startedge | endedge) == 0) {//
        } else if (linearray[index].show == "yes") {
          if (this.swap(startlat, startlng, curstartlng, curendlng)) {
            var tmplng = startlng;
            startlng = endlng;
            endlng = tmplng;
            var tmplat = startlat;
            startlat = endlat;
            endlat = tmplat;
            var tempedge = startedge;
            startedge = endedge;
            endedge = tempedge;
          }

          var add = 0;

          if (startedge & 1) {
            var adjustment = (curstartlng - startlng) / (endlng - startlng);

            if (startlng > 0 && endlng < 0) {
              adjustment = (curstartlng * -1 - startlng) / (startlng + (180 - startlng) + (180 + endlng) - startlng);
            }

            startlat += adjustment * (endlat - startlat);

            if (curstartlng == -180) {
              startlng = 180;
            } else {
              startlng = curstartlng;
            }

            startlat = this.getRadians(startlat);
            startlng = this.getRadians(startlng);
            linearray[index] = new google.maps.LatLng(startlat, startlng);
            linearray[index].show = "yes";

            if (direction === "ns") {
              endlat = this.getRadians(endlat);
              endlng = this.getRadians(endlng);
              linearray[1] = new google.maps.LatLng(endlat, endlng);
              linearray[1].show = "yes";
            }
          } else if (startedge & 2) {
            if (direction == "ew") {
              add = 1;
              linearray[index].show = "yes";
            }

            var _adjustment = (curendlng - startlng) / (endlng - startlng);

            if (startlng < 0 && endlng > 0) {
              _adjustment = (curendlng * -1 - startlng) / (startlng - (180 + startlng) - (180 - endlng) - startlng);
            }

            startlat += _adjustment * (endlat - startlat);
            startlng = curendlng;
            startlat = this.getRadians(startlat);
            startlng = this.getRadians(startlng);
            linearray[index + add] = new google.maps.LatLng(startlat, startlng);
            linearray[index + add].show = "yes";

            if (direction === "ns") {
              endlat = this.getRadians(endlat);
              endlng = this.getRadians(endlng);
              linearray[1] = new google.maps.LatLng(endlat, endlng);
              linearray[1].show = "yes";
            }
          }
        }
      }

      if (linearray[index - 1].show == "no") {
        linearray[index].show = "no";
      }

      if (linearray[index].toUrlValue(6) == linearray[index - 1].toUrlValue(6)) {
        linearray[index].show = "no";
      }

      return linearray;
    },
    findedge: function findedge(lat, lng, curstartlng, curendlng) {
      var edgeval = 0;

      if (curstartlng == -180 && lng > 0) {
        edgeval |= 1;
      } else if (curendlng == 180 && lng < 0) {
        edgeval |= 2;
      } else {
        if (lng <= curstartlng) {
          edgeval |= 1;
        }

        if (lng >= curendlng) {
          edgeval |= 2;
        }
      }

      return edgeval;
    },
    swap: function swap(lat, lng, curstartlng, curendlng) {
      if (lng < curstartlng || lng > curendlng) {
        return 0;
      }

      return 1;
    }
  });

  _exports.default = _default;
});