define("sky-router-3/validators/latlng", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Acceptance = _base.default.extend({
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      if (isNaN(value)) {
        return this.createErrorMessage('invalidLatLng', value, options);
      }

      if (options.disallowZero && value == 0) {
        return this.createErrorMessage('invalidLatLng', value, options);
      }

      if (options.latitude && Math.abs(value) > 324000000) {
        // 90deg
        return this.createErrorMessage('invalidLatLng', value, options);
      }

      if (options.longitude && Math.abs(value) > 648000000) {
        // 180deg
        return this.createErrorMessage('invalidLatLng', value, options);
      }

      return true;
    }
  });

  var _default = Acceptance;
  _exports.default = _default;
});