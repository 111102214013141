define("sky-router-3/pods/components/portal/track/map/overlay-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is responsible for showing a groping of overlays.
   * It has the ability to perform a hide-all action on all the overlays in the group
   */
  var _default = Ember.Component.extend({
    /**
     * The group that this overlay group is responsible for displaying
     * @type {sky-router-3/models/overlay-group}
     */
    group: null,

    /**
     * An array containing all the indexes of the overlays that are currently visible
     * @type {Array<int>}
     */
    overlaysVisible: null,

    /**
     * Indicates if the group is expanded or not
     * @type {Boolean}
     */
    expanded: true,

    /**
     * Property that returns true if all the overlays of this group are visible.
     * @return {Boolean}
     */
    countVisible: function () {
      var visible = this.get('overlaysVisible');
      return this.get('group.displayableOverlays').filter(function (i) {
        return visible.includes(i.get('id'));
      }).length;
    }.property('overlaysVisible.[]', 'group.displayableOverlays.[]'),
    actions: {
      toggleGroupCollapse: function toggleGroupCollapse() {
        this.onExpandToggle({
          groupId: this.get('group.id'),
          expanded: !this.get('expanded')
        });
      },
      toggleOverlayVisible: function toggleOverlayVisible(visible) {
        this.onVisibleToggleOverlays({
          overlayIds: this.get('group.overlays').mapBy('id'),
          visible: visible
        });
      }
    }
  });

  _exports.default = _default;
});