define("sky-router-3/pods/portal/operations/incident-report/index/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: {
      skip: {
        refreshModel: true
      },
      take: {
        refreshModel: true
      },
      sortDesc: {
        refreshModel: true
      },
      oldestDate: {
        refreshModel: true
      },
      soonestDate: {
        refreshModel: true
      },
      dateRangeHours: {
        refreshModel: true
      },
      filterAssetIds: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var dateRange = this._get_date_range(params);

      return Ember.RSVP.hash({
        incidentReports: this.store.query('incident-report', {
          skip: params.skip,
          take: params.take,
          sortDesc: params.sortDesc,
          oldestDate: dateRange.oldest,
          newestDate: dateRange.newest,
          assets: params.filterAssetIds
        }),
        assets: this.store.findAll('asset'),
        oldestDate: params.oldestDate,
        soonestDate: params.soonestDate,
        // Export parameters
        sortDesc: params.sortDesc,
        dateRangeHours: params.dateRangeHours,
        filterAssetIds: params.filterAssetIds,
        startInterval: dateRange.oldest,
        endInterval: dateRange.newest
      });
    },
    _get_date_range: function _get_date_range(params) {
      switch (params.dateRangeHours) {
        case -1:
          // Custom
          return {
            oldest: (0, _moment.default)(params.oldestDate || new Date()).toISOString(),
            newest: (0, _moment.default)(params.soonestDate || new Date()).toISOString()
          };

        case 0:
          // All Time
          return {};

        default:
          // Hour window
          return {
            oldest: (0, _moment.default)().subtract(params.dateRangeHours, 'h').toISOString(),
            newest: (0, _moment.default)().add(params.dateRangeHours, 'h').toISOString()
          };
      }
    },

    /**
     * Sends the response file to the client user. Creates a hyperlink to the object URL that is added to the DOM,
     * clicked, then removed immediately
     * @param  {HTTP Response} response API response to the requested export
     * @param  {string} fileName The export file name
     * @return {void}
     */
    sendResponseToClient: function sendResponseToClient(response, fileName) {
      // If IE, download the file this way. Security for IE doesn't allow javascript click downloads
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } else {
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(response);
        a.download = fileName; // Append anchor to body.

        document.body.appendChild(a);
        a.click(); // Remove anchor from body

        document.body.removeChild(a);
      }
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      /**
       * Makes the API call to generate the excel export based on the model params and returns the file to the client
       */
      export: function _export() {
        var _this2 = this;

        var _this = this;

        var params = {
          oldestDate: this.currentModel.startInterval,
          newestDate: this.currentModel.endInterval,
          dateRangeHours: this.currentModel.dateRangeHours
        };

        var dateRange = this._get_date_range(params);

        var sortDesc = this.currentModel.sortDesc;
        var filterAssetIds = this.currentModel.filterAssetIds;
        this.api.request("IncidentReports/Export", {
          data: {
            sortDesc: sortDesc,
            oldestDate: dateRange.oldest,
            newestDate: dateRange.newest,
            assets: filterAssetIds
          },
          dataType: 'binary'
        }).then(function (response) {
          // Create link to download export file
          _this.sendResponseToClient(response, _this2.intl.t('nav.incidentLog') + '-' + _this2.getUTCDateString() + '.xlsx');
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('report.exportFailed', {
            exportType: 'Excel'
          }));
        });
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});