define("sky-router-3/pods/account/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'public.login',

    /**
     * The login session service
     */
    session: Ember.inject.service(),
    touchId: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),
    title: function title() {
      return this.intl.t('login.loginTitle');
    },
    actions: {
      /**
       * Main application logout for users
       */
      logUserOut: function logUserOut() {
        this.touchId.clear();
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});