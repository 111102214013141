define("sky-router-3/pods/components/sort-children/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component looks for the sortByTag in the children of it and sorts them 
   */
  var _default = Ember.Component.extend({
    sortbyTag: 'a',
    didRender: function didRender() {
      var _this = this;

      if (this.element) {
        [].slice.call(this.element.children).sort(function (a, b) {
          var aLink = a.getElementsByTagName(_this.sortbyTag);
          var bLink = b.getElementsByTagName(_this.sortbyTag);

          if (aLink && bLink) {
            var aText = aLink[0].text.trim();
            var bText = bLink[0].text.trim();
            if (aText < bText) return -1;else if (aText > bText) return 1;else return 0;
          }

          return 0;
        }).forEach(function (element) {
          _this.element.appendChild(element);
        });
      }
    }
  });

  _exports.default = _default;
});