define("sky-router-3/pods/portal/track/manual/route", ["exports", "sky-router-3/models/asset-position-type", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _assetPositionType, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),

    /**
     * Required permissions in order to access this route.
     * Part of the `PermissionRequiredMixin`
     * @type {Array}
     */
    permissions: ['track.manual'],
    titleToken: function () {
      return this.intl.t('manualEvent.title');
    }.property(),
    model: function model(params) {
      return Ember.RSVP.hash({
        positionTypes: this.store.findAll('asset-position-type'),
        altitudeUnits: this.store.findAll('unit-altitude'),
        speedUnits: this.store.findAll('unit-speed'),
        assets: this.store.findAll('asset'),
        position: this.store.createRecord('asset-position', {
          latitudeMilliarcSeconds: params.latitude || 3600000,
          longitudeMilliarcSeconds: params.longitude || 3600000,
          utc: new Date()
        }),
        positionSpecified: !!params.latitude,
        maxDate: new Date()
      });
    },
    afterModel: function afterModel(model, transition) {
      var manualReport = model.positionTypes.findBy('id', _assetPositionType.POSITION_TYPES.ManualPosition);
      model.position.set('type', manualReport);
      model.position.set('description', manualReport.get('name'));

      if (model.positionSpecified) {
        transition.trigger(false, 'centerOnPosition', model.position);
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('latitude', null);
        controller.set('longitude', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.position.get('isNew')) {
          model.position.destroyRecord();
        }
      },
      selectAsset: function selectAsset(selection) {
        var model = this.modelFor(this.routeName);
        var position = model.position;
        var asset = model.position;

        if (asset) {
          this.send('hideBreadcrumbs', [position.get('asset.id')]);
        }

        if (!model.positionSpecified) {
          position.set('latitudeMilliarcSeconds', selection.get('positionLatest.latitudeMilliarcSeconds') || 3600000);
          position.set('longitudeMilliarcSeconds', selection.get('positionLatest.longitudeMilliarcSeconds') || 3600000);
          this.send('centerOnPosition', position);
        }

        position.set('asset', selection);
        this.send('showBreadcrumbs', [selection.get('id')]);
      },
      selectDate: function selectDate(date) {
        this.modelFor(this.routeName).position.set('utc', date);
      },
      save: function save(position, dateType) {
        var _this = this;

        this.send('blockApp', true);

        if (dateType == 'now') {
          position.set('utc', new Date());
        }

        position.save().then(function () {
          _this.transitionTo('portal.track');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});