define("sky-router-3/pods/portal/forms/templates/preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XGtqMZ1q",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"visible\",\"title\",\"onClose\",\"size\"],[true,[30,[36,3],[\"forms.templates.preview.title\"],null],[30,[36,2],[\"cancel\"],null],\"modal-lg\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"file\"],[[35,0,[\"activeTemplateFile\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"dynamic-form\",\"route-action\",\"t\",\"bs-modal\"]}",
    "moduleName": "sky-router-3/pods/portal/forms/templates/preview/template.hbs"
  });

  _exports.default = _default;
});