define("sky-router-3/mixins/status-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a mixin that adds an event that is triggered when an iOS user taps
   * the status bar to scroll to the top of the page.
   *
   * This mixin can only be used in routes!
   * APPLICABLE TO CORDOVA ONLY
   */
  var _default = Ember.Mixin.create(Ember.Evented, {
    /**
     * This holds a function reference to `_beforeStatusTapTrigger`
     * that is bound to this classes `this`. To see why this is
     * and works check out.
     * https://stackoverflow.com/questions/11565471/removing-event-listener-which-was-added-with-bind
     */
    _beforeStatusTapBoundRef: null,

    /**
     * Initialize the listener that attaches to the window
     */
    _initStatusTapListener: Ember.on('activate', function () {
      var boundFunc = this._beforeStatusTapTrigger.bind(this);

      this.set('_beforeStatusTapBoundRef', boundFunc);
      window.addEventListener('statusTap', boundFunc);
    }),

    /**
     * Removes the listener that attaches to the window
     */
    _destroyStatusTapListener: Ember.on('deactivate', function () {
      var boundFunc = this.get('_beforeStatusTapBoundRef');
      window.removeEventListener('statusTap', boundFunc);
    }),

    /**
     * Actually makes the call that triggers the new custom event
     * and scrolls to the top of the page.
     */
    _beforeStatusTapTrigger: function _beforeStatusTapTrigger() {
      this.trigger('statusTap');
    }
  });

  _exports.default = _default;
});