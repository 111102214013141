define("sky-router-3/pods/components/loading-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KPhPswaV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\" \"],[10,\"i\"],[15,0,[31,[\"ico-loading \",[30,[36,2],[[35,1],\"ico-spin\"],null],\" \",[34,0]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"class\",\"active\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/loading-icon/template.hbs"
  });

  _exports.default = _default;
});