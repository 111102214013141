define("sky-router-3/pods/portal/manage/users/edit-multiple/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        groups: this.modelFor('portal.manage.users'),
        assignableRoles: this.get('api').getUserPermissionRoles(this.get('sessionAccount.userId')),
        users: this.store.query('user', {
          ids: params.user_ids_csv.split(','),
          includeInactive: true,
          includeSelf: false
        }),
        addGroups: [],
        removeGroups: [],
        addRoles: [],
        removeRoles: [],
        companyName: '',
        companyNameChecked: false,
        departmentName: '',
        departmentNameChecked: false,
        divisionName: '',
        divisionNameChecked: false,
        phone: '',
        phoneChecked: false,
        fax: '',
        faxChecked: false,
        mobilePhone: '',
        mobilePhoneChecked: false,
        locale: 'en-us',
        localeChecked: false,
        isActive: true,
        isActiveChecked: false,
        hideAssetAltitude: false,
        hideAssetAltitudeChecked: false,
        hideAssetSpeed: false,
        hideAssetSpeedChecked: false
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.users');
      },
      save: function save() {
        var _this = this;

        var model = this.modelFor(this.routeName);
        var props = {};

        if (model.hideAssetSpeedChecked) {
          props.hideAssetSpeed = model.hideAssetSpeed;
        }

        if (model.hideAssetAltitudeChecked) {
          props.hideAssetAltitude = model.hideAssetAltitude;
        }

        if (model.isActiveChecked) {
          props.isActive = model.isActive;
        }

        if (model.localeChecked) {
          props.locale = model.locale;
        }

        if (model.mobilePhoneChecked) {
          props.mobilePhone = model.mobilePhone;
        }

        if (model.faxChecked) {
          props.fax = model.fax;
        }

        if (model.phoneChecked) {
          props.phone = model.phone;
        }

        if (model.divisionNameChecked) {
          props.division = model.divisionName;
        }

        if (model.departmentNameChecked) {
          props.department = model.departmentName;
        }

        if (model.companyNameChecked) {
          props.companyName = model.companyName;
        } // Update the user


        var promises = [];
        var api = this.get('api');
        var controller = this.controllerFor(this.routeName);
        controller.set('isSaving', true);
        model.users.forEach(function (u) {
          // Set the user properties
          u.setProperties(props);
          u.get('groups').addObjects(model.addGroups.toArray());
          u.get('groups').removeObjects(model.removeGroups.toArray()); // Save the user and then the permissions

          var promise = u.save().then(function () {
            if (!model.users.isAny('isAdmin')) {
              var addRoles = model.addRoles.mapBy('Id');
              var removeRoles = model.removeRoles.mapBy('Id');

              if (addRoles.length || removeRoles.length) {
                return api.getUserPermissionRoles(u.get('id')).then(function (response) {
                  var roles = response.mapBy('Id');
                  roles.addObjects(addRoles);
                  roles.removeObjects(removeRoles);
                  return api.setUserPermissionRoles(u.get('id'), roles);
                });
              }
            }
          }).catch(function () {
            _this.notifications.error(_this.intl.t('users.updateUserFail', {
              username: u.get('username')
            }));
          });
          promises.push(promise);
        }); // Wait and update the UI

        Ember.RSVP.allSettled(promises).then(function () {
          controller.set('isSaving', false);

          _this.transitionTo('portal.manage.users');
        });
      }
    }
  });

  _exports.default = _default;
});