define("sky-router-3/pods/portal/manage/alerts/edit/route", ["exports", "sky-router-3/models/alert-definition"], function (_exports, _alertDefinition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Holds properties for the model that can be rolled back.
     */
    initialAlertTriggerEvent: null,
    api: Ember.inject.service(),
    options: null,

    /**
     * Gets the model for the route. Since this route serves editing and adding
     * alerts it has logic to deal with it.
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        alert: params.alert_id === "new" ? this.store.createRecord('alert-definition') : this.store.findRecord('alert-definition', params.alert_id),
        subjectTags: this.store.findAll('alert-definition-subject-tag'),
        messageFlags: this.store.findAll('alert-definition-message-tag').then(function (o) {
          return o.sortBy('label');
        }),
        alertTriggerEvents: this.store.findAll('alert-trigger-event'),
        locations: this.store.findAll('location'),
        groups: this.store.findAll('group'),
        users: this.store.findAll('user'),
        personnel: this.store.findAll('personnel'),
        geofences: this.store.findAll('geofence'),
        alertTypes: [{
          id: _alertDefinition.ALERT_DEFINITION_TYPE.email,
          label: 'Email'
        }, {
          id: _alertDefinition.ALERT_DEFINITION_TYPE.sms,
          label: 'SMS'
        }],
        timeBetweenAlerts: [{
          id: 0,
          label: this.intl.t('alerts.timeBetweenAlerts.off')
        }, {
          id: 10,
          label: this.intl.t('alerts.timeBetweenAlerts.seconds', {
            count: 10
          })
        }, {
          id: 30,
          label: this.intl.t('alerts.timeBetweenAlerts.seconds', {
            count: 30
          })
        }, {
          id: 60,
          label: this.intl.t('alerts.timeBetweenAlerts.minute')
        }, {
          id: 120,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 2
          })
        }, {
          id: 300,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 5
          })
        }, {
          id: 600,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 10
          })
        }, {
          id: 900,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 15
          })
        }, {
          id: 1800,
          label: this.intl.t('alerts.timeBetweenAlerts.minutes', {
            count: 30
          })
        }]
      });
    },

    /**
     * Saves the initial values for all relationships on the model since
     * ember data is retarded and doesn't think rollback means rollback attributes
     */
    afterModel: function afterModel(model) {
      this.set('initialAlertTriggerEvent', model.alert.get('alertTriggerEvent'));
    },
    actions: {
      error: function error() {
        this.notifications.error(this.intl.t('alerts.alertDefinitionNotFound'));
        this.transitionTo('portal.manage.alerts');
      },

      /**
       * If the user is leaving this route. Make sure to clear the changes to
       * a half entered or dirty record is not present in the application.
       */
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        model.alert.rollbackAttributes();
        model.alert.set('alertTriggerEvent', this.get('initialAlertTriggerEvent'));
      },
      cancel: function cancel() {
        this.transitionTo('portal.manage.alerts');
      },
      save: function save() {
        var _this = this;

        var alert = this.modelFor(this.routeName).alert;
        alert.save().then(function () {
          _this.set('initialAlertTriggerEvent', alert.get('alertTriggerEvent'));

          _this.transitionTo('portal.manage.alerts');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('alerts.saveAlertFail'));
        });
      },

      /**
       * Searches for an airport based on the search string entered by a user
       **/
      searchAirports: function searchAirports(searchString) {
        return this.get('api').request("Overlays/OurAirports/Airports?search=".concat(searchString));
      },

      /**
       * Prevents the search from triggering until 3 characters are entered
       **/
      checkLength: function checkLength(text, select) {
        if (select.searchText.length >= 3 && text.length < 3) {
          return '';
        } else {
          return text.length >= 3;
        }
      }
    }
  });

  _exports.default = _default;
});