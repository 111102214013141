define("sky-router-3/models/nortac-canned-list", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberCpValidations, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    createdDateUtc: (0, _model.attr)('date'),
    entries: (0, _attributes.fragmentArray)('nortac-canned-list-entry'),
    assets: (0, _model.hasMany)('asset'),
    downloadUrl: (0, _model.attr)('string')
  });

  _exports.default = _default;
});