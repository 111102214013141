define("sky-router-3/models/asset-parameter-set-revision-setting", ["exports", "@ember-data/model", "sky-router-3/utils/computed-bool-string"], function (_exports, _model, _computedBoolString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The revision associated with this setting
     */
    revision: (0, _model.belongsTo)('asset-parameter-set-revision'),

    /**
     * A unique key that can be used to find this value.
     */
    key: (0, _model.attr)('string'),

    /**
     * A value that has been coerced into a string.
     */
    value: (0, _model.attr)('string'),

    /**
     * Computed property helper because a lot of the time the
     * value holds a '1' or '0' which is used like a boolean.
     */
    valueBoolean: (0, _computedBoolString.default)('value'),

    /**
     * When this is not null this regex can be used to validate the value.
     * NOTE: This is what the API uses to validate. It might not be suitable for UI display.
     */
    validationRegex: (0, _model.attr)('string'),

    /**
     * When this is not null this is the minimum numerical (inclusive) value for the setting.
     * NOTE: This is what the API uses to validate. It might not be suitable for UI display.
     */
    validationMinNumber: (0, _model.attr)('number'),

    /**
     * When this is not null this is the maximum numerical (inclusive) value for the setting.
     * NOTE: This is what the API used to validate. It might not be suitable for UI display.
     */
    validationMaxNumber: (0, _model.attr)('number')
  });

  _exports.default = _default;
});