define("sky-router-3/pods/components/portal/settings/event-notification-timeout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X0PPSMSR",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[10,\"strong\"],[12],[1,[30,[36,0],[\"settings.notificationsEventNotificationTimeoutTitle\"],null]],[13],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"settings.notificationsEventNotificationTimeoutDescription\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,7],null,[[\"options\",\"selected\",\"onChange\"],[[35,6],[35,3,[\"eventNotificationTimeout\"]],[30,[36,5],[[32,0],[30,[36,4],[[35,3,[\"eventNotificationTimeout\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],-1],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"settings.notificationsCloseManual\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"settings.notificationsCloseTime\"],[[\"count\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"user\",\"mut\",\"action\",\"eventNotificationOption\",\"input-select\"]}",
    "moduleName": "sky-router-3/pods/components/portal/settings/event-notification-timeout/template.hbs"
  });

  _exports.default = _default;
});