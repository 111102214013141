define("sky-router-3/models/trip-plan-custom-field-type", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _model, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    options: (0, _emberCpValidations.validator)('inline', {
      validate: function validate(value, options, model
      /*, attribute*/
      ) {
        if (model.type == 'select') {
          if (!value.length) {
            return "at least one options must be present for select";
          }
        }

        return true;
      },
      dependentKeys: ['model.type', 'model.options.[]']
    })
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)('string'),
    archived: (0, _model.attr)('boolean'),
    type: (0, _model.attr)('string', {
      defaultValue: 'text'
    }),
    options: (0, _attributes.array)(),
    defaultValue: (0, _model.attr)()
  });

  _exports.default = _default;
});