define("sky-router-3/pods/components/utc-clock/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component shows a valid UTC date that increments by the minute.
   */
  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: 'span',

    /**
     * Holds an instance to the Update timer that can be canceld.
     * @type {object} object suitable for running Ember.run.cancel on
     */
    timerUpdate: null,

    /**
     * Holds an instance to the reload timer that can be canceld.
     * @type {object} object suitable for running Ember.run.cancel on
     */
    timerReload: null,

    /**
     * Indicates how often the server should be contacted to make sure the time
     * is represented properly.
     * @type {Number}
     * @default 300000 - 5 minutes in ms.
     */
    serverContactUpdateMs: 300000,

    /**
     * Indicates how often the display datetime will be updated to the latest time
     * @type {Number}
     * @default 30000 - 30 seconds.
     */
    dateTimeUpdateMs: 30000,

    /**
     * A datetime object representing the current date
     * @type {Moment}
     */
    dateTime: null,

    /**
     * Holds an instance of the current date as represented by the users
     * system clock. This is used to compare the `dateTime` to the users
     * system and see how to update the `dateTime`. This property should
     * be updated every time that `dateTime` is updated.
     * @type {Date}
     */
    dateTimeLocal: null,

    /**
     * Starts the display time updating.
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._reloadServerTime().then(function () {
        _this._scheduleTimeUpdate();

        _this._scheduleServerTimeReload();
      });
    },

    /**
     * Stops the time display from updating anymore.
     * @return {[type]} [description]
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.get('timerUpdate'));
      Ember.run.cancel(this.get('timerReload'));
    },

    /**
     * Loads the time from the server into the `serverTime` property
     */
    _reloadServerTime: function _reloadServerTime() {
      var _this2 = this;

      return this.get('api').getServerTime().then(function (time) {
        var m = (0, _moment.default)(time);
        var d = new Date();

        _this2.set('dateTime', m);

        _this2.set('dateTimeLocal', d);
      }).catch(function () {// DO NOTHING
      });
    },

    /**
     * This takes the datetime stored in the `dateTime` property and compares
     * it to the system clock and updates the `dateTime` to the latest value
     * @return {[type]} [description]
     */
    _updateTime: function _updateTime() {
      var prevousLocalTime = this.get('dateTimeLocal');
      var currentLocalTime = new Date();
      var difference = currentLocalTime - prevousLocalTime;
      var datetime = this.get('dateTime');

      if (!datetime) {
        this._reloadServerTime();
      } else {
        // Add the changed number of milliseconds to the moment
        datetime.add(difference, 'ms');
        this.notifyPropertyChange('dateTime');
      } // Set the new current time that this display is based off of


      this.set('dateTimeLocal', currentLocalTime);
    },

    /**
     * This schedules a timer to update the date/time to update it
     * to the latest value every 30 seconds.
     */
    _scheduleTimeUpdate: function _scheduleTimeUpdate() {
      var _this3 = this;

      var timer = Ember.run.later(this, function () {
        _this3._updateTime();

        _this3._scheduleTimeUpdate();
      }, this.get('dateTimeUpdateMs'));
      this.set('timerUpdate', timer);
    },

    /**
     * Sets a time that reloads the server time back into the datetime.
     * This is recursive and will reload itself. To cancel the timers
     * run Ember.run.cancel on the `timerReload` property.
     */
    _scheduleServerTimeReload: function _scheduleServerTimeReload() {
      var _this4 = this;

      var timer = Ember.run.later(this, function () {
        _this4._reloadServerTime().then(function () {
          _this4._scheduleServerTimeReload();
        });
      }, this.get('serverContactUpdateMs'));
      this.set('timerReload', timer);
    }
  });

  _exports.default = _default;
});