define("sky-router-3/pods/portal/track/layout/controller", ["exports", "sky-router-3/models/site-layout", "ember-cp-validations", "sky-router-3/utils/object/assign-safe"], function (_exports, _siteLayout, _emberCpValidations, _assignSafe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    newLayoutName: (0, _emberCpValidations.validator)('presence', true)
  });
  /**
   * Powers the layout engine
   */

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Holds the value of the new layout name field
     */
    newLayoutName: '',

    /**
     * Blocks the create record button until the operation
     * is finished.
     */
    createInProgress: false,

    /**
     * Blocks the update/delete buttons until the operation
     * is finished
     */
    updateInProgress: false,
    actions: {
      /**
       * When a new layout is created
       *
       * It gets the current state object from the track route
       * and then creates a new layout object and sends it
       * off to the server
       */
      onCreateLayout: function onCreateLayout() {
        var _this = this;

        this.set('createInProgress', true);
        var json = this.model.state.toJSON();
        this.store.createRecord('site-layout', {
          sectionId: _siteLayout.LAYOUT_TYPES.track,
          json: JSON.stringify(json),
          name: this.get('newLayoutName')
        }).save().then(function () {
          _this.set('createInProgress', false);

          _this.set('newLayoutName', null);

          _this.send('reloadLayouts');
        });
      },

      /**
       * When a layout is renamed. It gets the currently selected layout
       * and then saves the changes to the server.
       * @param {sky-router-3/models/site-layout} the selected layout
       *
       */
      onRenameLayout: function onRenameLayout(layout) {
        var _this2 = this;

        if (layout.get('name.length')) {
          this.set('updateInProgress', true);
          layout.save().then(function () {
            _this2.set('updateInProgress', false);

            _this2.notifications.success(_this2.intl.t('track.trackLayoutNameChanged'));
          });
        } else {
          this.notifications.error(this.intl.t('track.trackLayoutNameError'));
        }
      },

      /**
       * When a layout is deleted. It gets the currently selected layout
       * and then destroys the record.
       * @param {sky-router-3/models/site-layout} the selected layout
       */
      onDeleteLayout: function onDeleteLayout(layout) {
        var _this3 = this;

        this.set('updateInProgress', true);
        layout.destroyRecord().then(function () {
          _this3.set('updateInProgress', false);
        });
      },

      /**
       * When a layout is loaded.
       * @param {sky-router-3/models/site-layout} the selected layout
       */
      onLoadLayout: function onLoadLayout(layout) {
        var obj = JSON.parse(layout.get('json'));
        (0, _assignSafe.default)(this.model.state, obj);
      }
    }
  });

  _exports.default = _default;
});