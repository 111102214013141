define("sky-router-3/pods/portal/report/submitted-forms/route", ["exports", "sky-router-3/mixins/permission-required-mixin", "sky-router-3/models/form-template-type"], function (_exports, _permissionRequiredMixin, _formTemplateType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the position metadata report. It handles creating the
   * report parameter option which for this report only includes an array of devices for use in a drop down list
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.submittedForms');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.submittedforms'],

    /**
     * The model for the submitted forms report route
     **/
    model: function model(params) {
      // Get the parameter template file if passed in by the url
      var reportParamFile = params.selectedFile && $.isNumeric(params.selectedFile) ? this.store.findRecord('form-template-file', params.selectedFile).catch(function () {
        return null;
      }) : null; // Return the hashed model values

      return Ember.RSVP.hash({
        /**
         * The form template options for the user
         **/
        files: this.store.query('form-template-file', {
          templateType: _formTemplateType.types.userForm
        }),

        /**
         * The currently selected template to use to query the data
         **/
        selectedFile: reportParamFile
      });
    },

    /**
     * Sets the selected template file to the first one in the list if not set
     */
    afterModel: function afterModel(model) {
      if (model.selectedFile === null) {
        model.selectedFile = model.files.get('firstObject');
      }
    },
    actions: {
      /**
       * Sets the report to automatically generate after render if a paramter imei was sent in the query string to this route
       */
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          _this.controller.send('generateReport');
        });
      }
    }
  });

  _exports.default = _default;
});