define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-5/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.value1': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.model.type.value1ValidationMaxNumber'),
      gte: Ember.computed.alias('model.model.type.value1ValidationMinNumber')
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Passed in output model.
     * @type {asset-compound-rule-revision-output}
     */
    model: null,

    /**
     * Controls the NPR grouping
     */
    timeReportingSelected: function () {
      var options = this.get('timeReportingOptions');
      var option = options.findBy('value', this.get('model.value1'));

      if (!option) {
        option = options.findBy('value', '-1');
      }

      return option;
    }.property('model.value1'),
    timeReportingCustomShown: Ember.computed.equal('timeReportingSelected.value', '-1'),
    timeReportingOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '45',
        label: intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '2700',
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '-1',
        label: intl.t('parameter.misc.custom')
      }];
    }.property(),
    actions: {
      timeReportingChange: function timeReportingChange(selected) {
        if (selected.value === '-1') {
          this.set('model.value1', '');
        } else {
          this.set('model.value1', selected.value);
        }
      }
    }
  });

  _exports.default = _default;
});