define("sky-router-3/pods/components/portal/track/map/overlays/our-airports-settings/component", ["exports", "sky-router-3/utils/computed-alias-not"], function (_exports, _computedAliasNot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     * Checkboxes
     */
    showSmall: (0, _computedAliasNot.default)('settings.generic.hideSmall'),
    showMedium: (0, _computedAliasNot.default)('settings.generic.hideMedium'),
    showLarge: (0, _computedAliasNot.default)('settings.generic.hideLarge'),
    showHeli: (0, _computedAliasNot.default)('settings.generic.hideHeli'),
    showSeaplane: (0, _computedAliasNot.default)('settings.generic.hideSeaplane'),
    showBaloon: (0, _computedAliasNot.default)('settings.generic.hideBaloon'),
    showClosed: (0, _computedAliasNot.default)('settings.generic.hideClosed')
  });

  _exports.default = _default;
});