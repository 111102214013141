define("sky-router-3/pods/components/geofence-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wRarQZx6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"display-svg\",[[24,5,\"fill-opacity:0.6;width:inherit;height:inherit\"]],[[\"@url\"],[[32,0,[\"geofence\",\"iconUrl\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "sky-router-3/pods/components/geofence-icon/template.hbs"
  });

  _exports.default = _default;
});