define("sky-router-3/helpers/get-user-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns the setting from the users model
   *
   * @example {{get-user-setting 'user.displayMGRSCoords'}}
   */
  var _default = Ember.Helper.extend({
    /**
     * The users session service
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Actually performs the computation.
     */
    compute: function compute(params) {
      return this.get('sessionAccount.user').get(params[0]);
    }
  });

  _exports.default = _default;
});