define("sky-router-3/pods/portal/manage/geofences/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    //queryParams: ['search', 'sortProperty', 'sortDesc'],
    search: null,
    sortProperty: 'name',
    sortDesc: false,

    /**
     * Filtering
     */
    filtered: Ember.computed.filter('model.geofences', ['search', 'model.geofences.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search);
      }

      return true;
    }),

    /**
     * Sorting
     */
    sortBy: Ember.computed('sortProperty', 'sortDesc', function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }),
    sorted: Ember.computed.sort('filtered', 'sortBy'),

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('sorted.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100],
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      }
    }
  });

  _exports.default = _default;
});