define("sky-router-3/pods/components/portal/track/map/overlays/noaa-metar-settings/component", ["exports", "sky-router-3/utils/computed-alias-not"], function (_exports, _computedAliasNot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     *
     * @type {Array}
     */
    options: function () {
      var intl = this.get('intl');
      return [{
        value: 0,
        label: intl.t('overlay.metar.densityLow')
      }, {
        value: 1,
        label: intl.t('overlay.metar.densityMedium')
      }, {
        value: 2,
        label: intl.t('overlay.metar.densityHigh')
      }];
    }.property(),
    selected: function () {
      var opts = this.get('options');
      return opts.findBy('value', this.get('settings.generic.density')) || opts[0];
    }.property('settings.generic.density'),

    /**
     * Checkboxes
     */
    showVFR: (0, _computedAliasNot.default)('settings.generic.hideVFR'),
    actions: {
      setSelection: function setSelection(sel) {
        this.set('settings.generic.density', sel.value);
      }
    }
  });

  _exports.default = _default;
});