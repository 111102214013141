define("sky-router-3/pods/portal/manage/alerts/log/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Holds a value that indicates where the "page end" is for records recovered.
     * Records will be recovered from this record backwards to `maxRecordCountToGet`
     * @type {Number}
     * @default Max value for an int
     */
    maxRecordId: 2147483647,

    /**
     * How many records to get with each request.
     * @type {Number}
     */
    maxRecordCountToGet: 25,

    /**
     * The model for this alert
     * @return {[type]} [description]
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        logs: this.store.peekAll('alert-log'),
        alert: params.alertId ? this.store.findRecord('alert-definition', params.alertId) : null,
        logsQuery: this.loadMoreAlertRecords(params.alertId)
      });
    },

    /**
     * Loads more alert records based on the currently loaded records
     * @return {Ember.RSVP.Promise} Promise to load records
     */
    loadMoreAlertRecords: function loadMoreAlertRecords(alertId) {
      var _this = this;

      var obj = {
        maxId: this.get('maxRecordId'),
        count: this.get('maxRecordCountToGet')
      };

      if (alertId) {
        obj.alertDefinitionId = alertId;
      }

      this.controllerFor(this.routeName).set('loadingRecords', true);
      return this.store.query('alert-log', obj).then(function (logs) {
        if (logs.get('length')) {
          _this.set('maxRecordId', logs.sortBy('id').get('firstObject.id'));
        }

        _this.controllerFor(_this.routeName).set('loadingRecords', false);

        return logs;
      });
    },
    actions: {
      error: function error() {
        this.notifications.error(this.intl.t('alerts.alertDefinitionNotFound'));
        this.transitionTo('portal.manage.alerts');
      },

      /**
       * When the user leaves this route we should remove all records so it
       * can be queried again
       * @return {[type]} [description]
       */
      willTransition: function willTransition() {
        this.controllerFor(this.routeName).set('alertId', null);
        this.set('maxRecordId', 2147483647);
        this.store.unloadAll('alert-log');
      },
      loadMoreAlerts: function loadMoreAlerts(alertId) {
        this.loadMoreAlertRecords(alertId);
      }
    }
  });

  _exports.default = _default;
});