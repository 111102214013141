define("sky-router-3/instance-initializers/cordova-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /*global cordova*/

  /**
   * This executes when the app boots and sets up anything required for
   * this app to be encapsulated as a cordova application.
   */
  function initialize()
  /*instance*/
  {
    /**
     * Only action upon the SkyRouterCordova application
     */
    if (navigator.userAgent.indexOf('SkyRouterCordova') !== -1) {
      /**
       * Properly open external links in a new browser window
       */
      $('body').on('click', 'a[target="_blank"]', function (e) {
        cordova.InAppBrowser.open(e.target.href, '_system', 'location=yes');
        e.preventDefault();
      });
    }
  }

  var _default = {
    name: 'cordova-setup',
    initialize: initialize
  };
  _exports.default = _default;
});