define("sky-router-3/instance-initializers/xhr-additions", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * This inserts changes to the behavior of XHR that occurs throughout the whole
   * system.
   */
  function initialize(instance) {
    // Get the session service
    var intl = instance.lookup('service:intl');
    var session = instance.lookup('service:session');
    $.ajaxPrefilter(function (options, originalOptions, xhr) {
      // Force authentication on all requests that are going to our API.
      if (options.url.indexOf(_environment.default.API.namespace) === 0) {
        xhr.setRequestHeader('X-A', _environment.default.API.key);
        var token = session.get('data.authenticated.token');

        if (token) {
          xhr.setRequestHeader('X-T', token);
        }
      } // Set the accept language header for every request to the one the user
      // has selected


      xhr.setRequestHeader("Accept-Language", intl.locale[0]);
    });
  }

  var _default = {
    name: 'xhr-additions',
    initialize: initialize,
    after: 'ember-simple-auth'
  };
  _exports.default = _default;
});