define("sky-router-3/pods/components/portal/manage/device-parameters/controls/start-movement-d320/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'delaySecondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.delaySecondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.delaySecondsSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Passed in settings
     * @type {[type]}
     */
    enabledSetting: null,
    delaySecondsSetting: null,
    showDelayField: true,
    disabled: false,
    actions: {
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('delaySecondsSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});