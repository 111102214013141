define("sky-router-3/transforms/ember-object", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      try {
        if (typeof serialized === "string") {
          if (serialized.indexOf('"') === 0) {
            return Ember.Object.create(JSON.parse(serialized.slice(1, -1)));
          } else {
            return Ember.Object.create(JSON.parse(serialized));
          }
        }
      } catch (fuck) {
        /*do nothing*/
      }
    },
    serialize: function serialize(deserialized) {
      return JSON.stringify(deserialized);
    }
  });

  _exports.default = _default;
});