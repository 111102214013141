define("sky-router-3/pods/components/portal/track/map/line/line-location/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This class represents a line between two locations.
   * It wraps the Google Maps API class `google.maps.Polyline`
   *
   * @example {{line-location start=location end=location color=asset.color map=map}}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('line'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'path',
      setFunc: 'setPath'
    }, {
      property: 'strokeColor',
      setFunc: 'set'
    }, {
      event: 'mouseover',
      eFunc: '_mouseOver'
    }, {
      event: 'mouseout',
      eFunc: '_mouseOut'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Incoming object.
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Incoming location item that represents that start of the line
     * @type {sky-router-3/models/location}
     */
    start: null,

    /**
     * Incoming location item that represents the end of the line
     * @type {sky-router-3/models/location}
     */
    end: null,

    /**
     * Reference to the instance of the Google Polyline
     * @type {google.maps.Polyline}
     */
    line: null,

    /**
     * Incoming HEX color for the line
     * in the format of '#000000'
     */
    color: '#000000',

    /**
     * Does the line fire any mouseover, mouseout, and click events?
     * @type {Boolean}
     */
    clickable: false,

    /**
     * The options object that is used to set options
     * on the polyline from ember.
     */
    options: function () {
      return {
        geodesic: true,
        clickable: this.clickable,
        strokeWeight: 2,
        strokeOpacity: 0.5,
        strokeColor: this.get('color'),
        zIndex: 498
      };
    }.property('color', 'clickable'),

    /**
     * The path which this line takes. It is assumed that the start and end
     * locations are actually promises as they might not have been loaded.
     * @returns {google.maps.LatLngArray}
     */
    path: function () {
      var start = Ember.RSVP.resolve(this.get('start'));
      var end = Ember.RSVP.resolve(this.get('end'));
      return Ember.RSVP.allSettled([start, end]).then(function (values) {
        var ret = [];

        if (!!start && !!end) {
          var s = _latLngConverter.default.modelToGoogleLatLng(values[0].value);

          var e = _latLngConverter.default.modelToGoogleLatLng(values[1].value);

          ret = [s, e];
        }

        return ret;
      });
    }.property('start.latitudeMilliarcSeconds', 'start.longitudeMilliarcSeconds', 'end.latitudeMilliarcSeconds', 'end.longitudeMilliarcSeconds'),

    /**
     * Creates the polyline Google maps object
     * and sets it.
     */
    init: function init() {
      var line = new google.maps.Polyline(this.get('options'));
      this.set('line', line);
      return this._super.apply(this, arguments);
    },

    /**
     * Removes the line from the map and destroys the
     * reference to it.
     */
    willDestroyElement: function willDestroyElement() {
      this.line.setMap(null);
    },

    /**
     * Actions
     */
    onMouseOver: function onMouseOver() {},
    _mouseOver: function _mouseOver(line, polyMouseEvent) {
      this.onMouseOver({
        latLng: polyMouseEvent.latLng
      });
    },
    onMouseOut: function onMouseOut() {},
    _mouseOut: function _mouseOut(line, polyMouseEvent) {
      this.onMouseOut({
        latLng: polyMouseEvent.latLng
      });
    },
    onClick: function onClick() {},
    _click: function _click(line, polyMouseEvent) {
      this.onClick({
        latLng: polyMouseEvent.latLng
      });
    }
  });

  _exports.default = _default;
});