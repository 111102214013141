define("sky-router-3/utils/filter-by-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterByInArray;

  /**
   * Provides a computed property that is similar to filterBy but this allows for
   * checking to see if the key's value is present in an another array.
   *
   * This is shorthand for "if array.includes item's key"
   *
   * @param  {String} dependentKey      The base array to be filtered
   * @param  {String} propertyKey       A key on the base array to be checked for
   *                                    existance in the dependentPropertyKeyArray
   * @param  {string} dependentPropertyKeyArray An array that contains only the values
   *                                            that can map to and item's the propertyKey
   * @return {Array<dependentKey>}      Filtered array of dependentKey
   * @example filteredAssets: filterByInArray('assets', 'id', 'config.filterAssetIds')
   */
  function filterByInArray(dependentKey, propertyKey, dependentPropertyKeyArray) {
    return Ember.computed("".concat(dependentKey, ".[]"), "".concat(dependentKey, ".@each.").concat(propertyKey), "".concat(dependentPropertyKeyArray, ".[]"), function () {
      var depArray = this.get(dependentPropertyKeyArray);

      if (Ember.isEmpty(depArray)) {
        return [];
      }

      return this.get(dependentKey).filter(function (item) {
        return depArray.includes(Ember.get(item, propertyKey));
      });
    });
  }
});