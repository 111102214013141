define("sky-router-3/models/global-traveler-message-recipient", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "sky-router-3/utils/computed-promise"], function (_exports, _model, _fragment, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    isAcknowledged: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    acknowledgeDateUtc: (0, _model.attr)('date'),
    asset: (0, _model.attr)('number'),
    assetRecord: (0, _computedPromise.default)('asset', {
      get: function get() {
        if (this.asset) {
          return this.store.findRecord('global-traveler-asset', this.asset);
        }

        return null;
      },
      set: function set(key, value) {
        this.set('asset', value.get('id'));
        return value;
      }
    }, null)
  });

  _exports.default = _default;
});