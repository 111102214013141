define("sky-router-3/pods/portal/communicate/compose/draft/route", ["exports", "sky-router-3/models/mail-folder"], function (_exports, _mailFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Model that contains the mail message
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset'),
        contacts: this.store.findAll('contact'),
        message: this.store.findRecord('mail-message', params.message_id)
      });
    },

    /**
     * This validates that they are editing a message in the draft folder.
     */
    afterModel: function afterModel(model, transition) {
      if (model.message.get('folder.id') !== _mailFolder.MAIL_FOLDERS.drafts) {
        transition.trigger(false, 'error');
      }
    },
    actions: {
      /**
       * When the model rejects because of an error
       */
      error: function error() {
        this.notifications.error(this.intl.t('communicate.draftNotFound'));
        this.replaceWith('portal.communicate');
      },

      /**
       * Since drafts cannot be updated the old one must be deleted
       * and then a new one created.
       */
      updateDraft: function updateDraft(args) {
        var _this = this;

        var message = this.modelFor(this.routeName).message;
        message.destroyRecord().then(function () {
          var record = _this.store.createRecord('mail-message', args);

          _this.send('saveDraft', record);
        });
      },

      /**
       * Initiates a send of a draft and then sends the command up
       * the chain.
       */
      sendDraft: function sendDraft(args) {
        var message = this.modelFor(this.routeName).message;
        message.setProperties(args);
        this.send('sendMessage', message);
      }
    }
  });

  _exports.default = _default;
});