define("sky-router-3/pods/portal/manage/nortac/canned-list/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    nortacAssets: Ember.computed.filterBy('model.assets', 'product.id', 'NWAVE'),
    actions: {
      addEntry: function addEntry() {
        this.model.list.entries.createFragment('nortac-canned-list-entry');
      },
      removeEntry: function removeEntry(entry) {
        this.model.list.entries.removeObject(entry);
      }
    }
  });

  _exports.default = _default;
});