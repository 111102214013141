define("sky-router-3/services/push-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service handles setting up and receiving push notifications
   * from the firebase cloud messaging service via the SkyRouter cordova application
   * @see https://github.com/phonegap/phonegap-plugin-push/
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),

    /**
     * The initialized instance of the PushNotification system.
     * @type {PushNotification}
     */
    instance: null,

    /**
     * is the push-notification system available
     * @type {Boolean}
     */
    available: false,

    /**
     * After the instance is initialized this will be populated with the identifier
     * provided by FCM service.
     * @type {String} Very long string...
     */
    registration: null,

    /**
     * Are push notifications enabled on the client device?
     * @readonly
     * @type {Boolean}
     */
    isEnabled: false,

    /**
     * Sets up the notification system
     * @return {Promise} Promise that resolves if the user can use push notifications
     */
    setup: function setup() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        // This will happen if we are not running in cordova
        if (typeof PushNotification == 'undefined') {
          reject();
          return;
        } // If we got here we have push notifications available to us


        _this.set('available', true); // Init with OPTIONS
        // @see https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#pushnotificationinitoptions


        _this.instance = PushNotification.init({
          android: {
            forceShow: true
          },
          ios: {
            alert: true,
            sound: true,
            badge: true,
            clearBadge: true
          }
        });

        _this.instance.on('registration', Ember.run.bind(_this, _this.onRegistration, _this.instance));

        _this.instance.on('notification', Ember.run.bind(_this, _this.onNotification, _this.instance));

        _this.instance.on('error', Ember.run.bind(_this, _this.onError, _this.instance)); // Verify that the user has given us permission to se push notificaitons
        // Once we have resolve the promise


        PushNotification.hasPermission(function (data) {
          _this.set('isEnabled', data.isEnabled);

          resolve(data.isEnabled);
        });
      });
    },

    /**
     * This un-subscribes the device from the push notification system.
     * @return {Promise} Promise to remove the registration
     */
    disable: function disable() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this2.instance && _this2.registration) {
          return _this2.api.removeFirebaseRegistration(_this2.registration).then(function () {
            _this2.instance.unregister(resolve, reject);
          }).catch(reject);
        }

        resolve();
      });
    },

    /**
     * Fired when the devices registration ID is created.
     * This ID can be used to send notifications specifically to this device.
     * @see https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#pushonregistration-callback
     * @param  {Object} data Arguments for the registration (NOTE: registrationType will always be FCM)
     * @return void
     */
    onRegistration: function onRegistration(instance, data) {
      console.log("In push-notification.onRegistration() - data.registrationId = ".concat(data.registrationId));
      this.set('registration', data.registrationId);
      this.api.updateFirebaseRegistration(data.registrationId);
    },

    /**
     * Fired when the user clicks on the notification or when the app is woken up
     * to handle the incoming notification (iOS). It contains data about the notificaiton.
     * @see https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#pushonnotification-callback
     * @param  {Object} data Event notification data arguments
     * @return void
     */
    onNotification: function onNotification(instance, data) {
      this.trigger('notification', {
        message: data.message,
        title: data.title,
        data: data.additionalData
      });
      instance.finish();
    },

    /**
     * Fired when an error occurs on the channel.
     * Not sure what this is for right now.
     */
    onError: function onError(instance, error) {
      console.log(error);
    }
  });

  _exports.default = _default;
});