define("sky-router-3/models/asset-parameter-set-asset", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The asset that this deivce revision record is assigned to
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The revision that this device revision record is associated with
     */
    parameterSetRevision: (0, _model.belongsTo)('asset-parameter-set-revision'),

    /**
     * Is the asset in question assigned to a profile set revision.
     * This is changed
     */
    parameterSetRevisionAssigned: (0, _model.attr)('boolean'),

    /**
     * The date this record was updated.
     */
    updatedDate: (0, _model.attr)('date')
  });

  _exports.default = _default;
});