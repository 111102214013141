define("sky-router-3/pods/components/portal/track/asset-list/asset-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Grouping of asset items.
   */
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * Action is triggered when the group collapse toggle
     * is triggered.
     *
     * @event groupExpandToggled
     * @param {string} the group identifier
     * @param {boolean} did the component request to be expanded
     */

    /**
     * Action is triggered when one of the asset item components
     * sends the event.
     * @event assetClicked
     * @param {string} the asset Id as defined in the model
     */

    /**
     * Action is triggered when one of the asset item components
     * sends the event that they wish to change the state
     * of events being shown or hidden in the list
     * @event assetEventsShow
     * @param {boolean} shown or hide
     * @param {string} the asset Id as defined in the model
     * @param {string} the Id of the group as defined in the model
     */

    /**
     * @event 'assetShowBreadcrumbs'
     * Action is triggered either by sub components or by the menu
     * in this components template. It makes an array of assets to show breadcrumbs
     * for and then sends the action upwards.
     * @param {string[]} array of assetIds to show
     */

    /**
     * @event assetFilterGeofences
     * Action is triggered when the user wishes to filter the geofences list based
     * on the assets this group contains. It makes an array of assets to filter
     * upon and then sends the action upwards
     * @param {string[]} array of assetIds to filter geofences on.
     */

    /**
     * @event assetFilterEvents
     * Action is triggered when the user wishes to filter the events list based
     * on the assets this group contains. It makes an array of assets to Filter
     * upon and then sends the action upwards
     * @param {string[]} array of assetIds to filter events on.
     */

    /**
     * The group ID for the group that is assigned to
     * this display group.
     */
    groupId: null,

    /**
     * List of assets that belong to this group
     * @public
     * @property
     */
    assets: null,

    /**
     * The name that belongs to this group. It will
     * be displayed in the UI.
     */
    name: '',

    /**
     * Indicates that the group's asset list is expanded.
     */
    expanded: false,

    /**
     * This holds an array of assetIds that indicate that the
     * asset-item should show its events window. If present
     * in the array they should be shown.
     * @type {string[]} assetIds
     */
    assetsEventsExpanded: null,

    /**
     * This gets an array of assets that support messaging.
     * @return {sky-router-3/models/asset[]}
     */
    assetsSupportingMessages: Ember.computed.filterBy('assets', 'supportsMessages'),

    /**
     * This holds a value that indicates if the user should be allowed to execute
     * the show all breadcrumbs action. This is placed here because some
     * breadcrumb intervals are just too large to be trying to load at once
     * from the API. The default is the timespan has to be less than 5 hours.
     * @return {Boolean}
     */
    breadcrumbLimitOutOfRange: Ember.computed.gt('sessionAccount.user.assetBreadcrumbTimespan', 300),
    actions: {
      toggleGroupCollapse: function toggleGroupCollapse() {
        this.groupExpandToggled(this.groupId, !this.get('expanded'));
      },
      onAssetShowEventsChanged: function onAssetShowEventsChanged(show, assetId) {
        this.assetEventsShow(show, assetId, this.groupId);
      },
      onShowBreadcrumbs: function onShowBreadcrumbs(assetId) {
        this.assetShowBreadcrumbs(Ember.makeArray(assetId));
      },
      onGroupShowBreadcrumbs: function onGroupShowBreadcrumbs() {
        var assetIdArray = this.get('assets').map(function (item) {
          return item.get('id');
        });
        this.assetShowBreadcrumbs(assetIdArray);
      },
      onGroupFilterGeofences: function onGroupFilterGeofences() {
        var assetIdArray = this.get('assets').mapBy('id');
        this.assetFilterGeofences(assetIdArray);
      },
      onAssetFilterGeofences: function onAssetFilterGeofences(assetId) {
        this.assetFilterGeofences([assetId]);
      },
      onGroupFilterEvents: function onGroupFilterEvents() {
        var assetIdArray = this.get('assets').mapBy('id');
        this.assetFilterEvents(assetIdArray);
      }
    }
  });

  _exports.default = _default;
});