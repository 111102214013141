define("sky-router-3/models/geofence", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', true),
    'points': (0, _emberCpValidations.validator)('inline', {
      dependentKeys: ['model.points.[]'],
      validate: function validate(value, options, model
      /*, attribute */
      ) {
        var intl = Ember.getOwner(this).lookup('service:intl');
        var length = value.length;

        if (!length) {
          return intl.t('track.trackGeofencePointsValidationDraw');
        }

        var type = model.type;

        if (type === 3 && length > 8) {
          return intl.t('track.trackGeofencePointsValidationPointLimit', {
            count: 8
          });
        }

        if (type === 3 && length < 3) {
          return intl.t('track.trackGeofencePointsValidationPointMin', {
            count: 3
          });
        }

        return true;
      }
    }),
    'avrReportingInterval': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true,
      gte: 15,
      lt: 86400
    }),
    'lowerAltitudeBoundsCm': (0, _emberCpValidations.validator)('number', {
      lt: Ember.computed.alias('model.upperAltitudeBoundsCm'),
      disabled: Ember.computed.equal('model.upperAltitudeBoundsCm', null),
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.lessThan', {
          lt: 'upper bounds'
        });
      }
    }),
    'upperAltitudeBoundsCm': (0, _emberCpValidations.validator)('number', {
      gt: Ember.computed.alias('model.lowerAltitudeBoundsCm'),
      disabled: Ember.computed.equal('model.lowerAltitudeBoundsCm', null),
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.greaterThan', {
          gt: 'lower bounds'
        });
      }
    })
  });
  /**
   * This model represents a geofence that is a geographical bounded
   * area that has meaning to assets as they travel in or out of it.
   */

  var model = _model.default.extend(VALIDATIONS, {
    /**
     * Name of the geofence
     */
    name: (0, _model.attr)('string'),

    /**
     * Type of the geofence
     *
     * 1: Rectangle (not in use)
     * 2: Circle
     * 3: Polygon
     */
    type: (0, _model.attr)('number', {
      defaultValue: 2
    }),

    /**
     * is the geofence enabled or not
     */
    isEnabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Diameter of the geofence when the type is circle.
     */
    diameterInKM: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Comment the user can specify for the geofence.
     */
    comment: (0, _model.attr)('string'),

    /**
     * Admin only visible
     */
    isAdminOnly: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates the geofence-enter event will fire
     */
    onEnterEventEnabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Indicates the geofence-exit event will fire
     */
    onExitEventEnabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * This is flag field that indicates the mode the avr
     * features for the geofence. These features are only
     * available to devices that support AVR geofences.
     *
     * AVR mode changes the behavior of the geofence and
     * optionally enables or disables some features and other fields
     *
     * Possible values are:
     *   0:  None / Do not use advanced features
     *   1:  No Go Zone - The device will fire emergency style NOGO reports
     *   2:  No Report Zone - The device will not report inside
     *   3:  Change Reporting rate inside geofence
     *   4:  Depot Zone - the device will fire Depot - Enter / Exit
     */
    avrMode: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Interval of reporting when the `avrMode` is set to 'Change Reporting Rate'
     */
    avrReportingInterval: (0, _model.attr)('number', {
      defaultValue: 60
    }),

    /**
     * returns the color the geofence will be internally
     * AKA the fill color
     * @type {string} hex color '#00000'
     */
    color: (0, _model.attr)('string', {
      defaultValue: 'FFFF00'
    }),

    /**
     * Assets that are assigned to this geofence.
     */
    assets: (0, _model.hasMany)('geofence-asset'),

    /**
     * The points of the geofence
     */
    points: (0, _model.attr)('ember-object-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * Where does the geofence start altitude wise
     */
    lowerAltitudeBoundsCm: (0, _model.attr)('number'),

    /**
     * Where does the geofence end altitude wise
     */
    upperAltitudeBoundsCm: (0, _model.attr)('number'),

    /**
     * SVG icon url for the shape representation of the
     * @type {String}
     */
    iconUrl: (0, _model.attr)('string'),

    /**
     * Indicates if the user can edit the geofence record.
     * This has nothing to do with permissions on the user.
     */
    isOwnedByCaller: (0, _model.attr)('boolean'),

    /**
     * Indicates the optional speed limit for an asset inside this geofence.
     */
    speedLimitKph: (0, _model.attr)('number')
  });

  var _default = model;
  _exports.default = _default;
});