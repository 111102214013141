define("sky-router-3/pods/portal/manage/data-feeds/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for managing data feeds. It loads up data feed option for all the users devices
   * and allows them to make changes to the selections
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['manage.datafeed'],

    /**
     * The translation services
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.manage.dataFeeds');
    },

    /**
     * The model for the data feed route that contains the assets, data feeds and data feed selections
     **/
    model: function model() {
      // Return the hashed model values
      return Ember.RSVP.hash({
        // Retrieves the assets available to the calling user
        assets: this.store.findAll('asset').then(function (p) {
          return p.sortBy('name');
        }),
        // Retrieves the external data feed selections for the user
        extDataFeedSelections: this.store.findAll('ext-data-feed-selection'),
        // Retrieves the data feed selections for the user
        dataFeedSelections: this.store.findAll('data-feed-selection')
      });
    },
    actions: {
      /**
       * Saves the data feeds and external data feed user selections to the database
       */
      save: function save() {
        var _this = this;

        this.send('blockApp', true, {
          message: this.intl.t('misc.savingPlaceholder')
        }); // First save the external data feeds

        this.modelFor(this.routeName).extDataFeedSelections.save().then(function () {
          // Then save the internal data feeds
          return _this.modelFor(_this.routeName).dataFeedSelections.save();
        }).then(function () {
          // Show success notification when successfully done
          _this.notifications.success(_this.intl.t('dataFeed.saveSuccess'));
        }) // Any failure will display the generic error message
        .catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      },

      /**
       * Reloads the model
       */
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});