define("sky-router-3/services/touch-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Based on this documentation:
   * https://github.com/sjhoeksma/cordova-plugin-keychain-touch-id
   * @type {Boolean}
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      document.addEventListener('deviceready', Ember.run.bind(this, this.setup), false);

      this._super.apply(this, arguments);
    },

    /**
     * Initialize the state of this service once the cordova plugins have all been loaded
     */
    setup: function setup() {
      var _this = this;

      if (window.plugins && window.Fingerprint && window.cordova.plugins.SecureKeyStore) {
        window.Fingerprint.isAvailable(function () {
          window.cordova.plugins.SecureKeyStore.get(function () {
            _this.set('loginStored', true);

            _this.trigger('login_located');
          }, function () {}, _this.keychainKey);

          _this.set('available', true);
        });
      } else {
        this.set('available', false);
      }

      this.set('isSetup', true);
      this.trigger('setup_complete');
    },

    /**
     * Has this service been initialized.
     * @type {Boolean}
     */
    isSetup: false,

    /**
     * is the iOS touch/face ID system available
     * @type {Boolean}
     */
    available: false,

    /**
     * Keychain keys
     * @type {String}
     */
    keychainKey: 'skyrouter3-userpass',

    /**
     * Is a username stored for the fingerprint
     * @type {Boolean}
     */
    loginStored: false,

    /**
     * Check the user against the fingerprint/touchId
     */
    verify: function verify() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this2.available) {
          reject();
        }

        window.Fingerprint.show({
          clientId: "skyrouter3",
          clientSecret: "BigAppl3sBaby"
        }, function () {
          window.cordova.plugins.SecureKeyStore.get(function (e) {
            resolve(JSON.parse(e));
          }, reject, _this2.keychainKey);
        }, reject);
      });
    },

    /**
     * Save the users information into the
     */
    save: function save(username, password) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this3.available) {
          reject();
        }

        window.cordova.plugins.SecureKeyStore.set(function (e) {
          _this3.set('loginStored', true);

          resolve(e);
        }, reject, _this3.keychainKey, JSON.stringify({
          username: username,
          password: password
        }));
      });
    },

    /**
     * Clears the saved user information from the device
     */
    clear: function clear() {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this4.available) {
          reject();
        }

        window.cordova.plugins.SecureKeyStore.remove(function (e) {
          _this4.set('loginStored', false);

          resolve(e);
        }, reject, _this4.keychainKey);
      });
    }
  });

  _exports.default = _default;
});