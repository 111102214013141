define("sky-router-3/pods/public/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "sky-router-3/utils/browser-detect"], function (_exports, _unauthenticatedRouteMixin, _browserDetect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'portal',
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    touchId: Ember.inject.service(),
    updateCultureForUser: function updateCultureForUser() {
      var _this = this;

      var userid = this.get('sessionAccount.userId');
      this.store.findRecord('user', userid).then(function (user) {
        if (user.get('locale').toUpperCase() !== _this.get('intl.locale.firstObject').toUpperCase()) {
          user.set('locale', _this.get('intl.locale.firstObject'));
          user.save();
        }
      });
    },
    trySaveTouchId: function trySaveTouchId(username, password) {
      var touch = this.get('touchId');

      if (touch.get('available')) {
        var intl = this.get('intl');
        var feature = (0, _browserDetect.isiOS)() ? intl.t('touchid.titleiOS') : intl.t('touchid.titleAndroid');

        if (confirm(intl.t('touchid.confirmationQuestion', {
          feature: feature
        }))) {
          touch.save(username, password).then(function () {
            alert(intl.t('touchid.confirmation', {
              feature: feature
            }));
          });
        } else {
          touch.clear();
        }
      }
    },
    actions: {
      loginUserTouchId: function loginUserTouchId() {
        var _this2 = this;

        var controller = this.controllerFor('public.login.index');
        controller.set('blockForm', true);
        this.get('touchId').verify().then(function (resp) {
          _this2.send('loginUser', resp.username, resp.password, true);
        }).catch(function () {
          controller.set('blockForm', false);
        });
      },
      loginUser: function loginUser(username, password) {
        var _this3 = this;

        var fromTouch = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        if (!username || !password) {
          return;
        }

        var controller = this.controllerFor('public.login.index');
        controller.set('blockForm', true);
        controller.set('loginDisabled', false);
        controller.set('loginBad', false);
        controller.set('loginBlocked', false);
        this.get('session').authenticate('authenticator:web-api', {
          username: username,
          password: password
        }).then(function () {
          if (!fromTouch) {
            _this3.trySaveTouchId(username, password);
          }

          _this3.updateCultureForUser();
        }).catch(function (error) {
          switch (error.reason) {
            case 'lockedOut':
              _this3.transitionTo('public.login.unlock', {
                username: username
              });

              break;

            case 'badAuth':
              _this3.transitionTo('public.login');

              controller.set('loginBad', true);
              break;

            case 'disabled':
              _this3.transitionTo('public.login');

              controller.set('loginDisabled', true);
              break;

            case 'blocked':
              _this3.transitionTo('public.login');

              controller.set('loginBlocked', true);
              break;

            case 'accountSuspended':
              _this3.transitionTo('public.login');

              controller.set('accountSuspended', true);
              break;

            case 'accountDeactivated':
              _this3.transitionTo('public.login');

              controller.set('accountDeactivated', true);
              break;

            case 'needs2FA':
              _this3.transitionTo('public.login.two-factor', {
                username: username,
                password: password
              });

              break;
          }
        }).finally(function () {
          controller.set('blockForm', false);
          controller.set('password', '');
        });
      }
    }
  });

  _exports.default = _default;
});