define("sky-router-3/models/mail-folder", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAIL_FOLDERS = _exports.default = void 0;

  /* eslint-env node */

  /**
   * Model that represents an email box folder.
   *
   * NOTE: Folder identifiers are based on the path the folder is located at.
   * If the name or the parent name is modified then the folder identifier is
   * invalid.
   */
  var _default = _model.default.extend({
    /**
     * Takes the mailbox identifier and makes it lower case.
     * @return {[type]} [description]
     */
    cssClass: function () {
      return this.get('id').replace('.', '-').toLowerCase();
    }.property('id'),

    /**
     * The display name for this folder
     */
    name: (0, _model.attr)('string'),

    /**
     * The count of messages messages in this folder
     */
    count: (0, _model.attr)('number'),

    /**
     * The count of unread messages in this folder
     */
    unread: (0, _model.attr)('number'),

    /**
     * BelongsTo relationship property that points to any parent folders that
     * exist for this folder if there are any.
     */
    parentFolder: (0, _model.belongsTo)('mail-folder', {
      async: true,
      inverse: 'subFolders'
    }),

    /**
     * HasMany relationship property that points to any sub folders that exist
     * for this folder.
     */
    subFolders: (0, _model.hasMany)('mail-folder', {
      async: true,
      inverse: 'parentFolder'
    }),

    /**
     * Indicates if this is a protected folder that cannot be renamed or deleted.
     * This is essentially all the top level folders.
     * @return {Boolean}
     */
    isReservedFolder: function () {
      for (var folder in MAIL_FOLDERS) {
        if (this.get('id') === MAIL_FOLDERS[folder]) {
          return true;
        }
      }

      return false;
    }.property('id')
  });
  /**
   * This is a constant object that contains the keys for folders that are reserved.
   * @type {Object}
   */


  _exports.default = _default;
  var MAIL_FOLDERS = {
    sent: 'INBOX.Sent',
    drafts: 'INBOX.Drafts',
    trash: 'INBOX.Trash',
    inbox: 'INBOX'
  };
  _exports.MAIL_FOLDERS = MAIL_FOLDERS;
});