define("sky-router-3/pods/components/portal/settings/volume-units/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The user this component is dealing with
     * @type {sky-router-3/models/user}
     */
    user: null,

    /**
     * Available volume units
     * @type {Array<sky-router-3/models/unit-volume>}
     */
    volumeUnits: null
  });

  _exports.default = _default;
});