define("sky-router-3/pods/portal/manage/device-profiles/index/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REMOT+ct",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.help\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.help2\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"profiles.helpInstructions\"],null]],[2,\"\\n        \"],[10,\"ol\"],[12],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.helpInstructions1\"],null]],[13],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.helpInstructions2\"],null]],[13],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.helpInstructions3\"],null]],[13],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"profiles.helpInstructions4\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/device-profiles/index/index/template.hbs"
  });

  _exports.default = _default;
});