define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-temperature/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'temperatureCheckRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.temperatureCheckRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.temperatureCheckRate.validationMinNumber')
    })],
    'temperatureMinTemp.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.temperatureMinTemp.validationMaxNumber'),
      gte: Ember.computed.alias('model.temperatureMinTemp.validationMinNumber')
    })],
    'temperatureMaxTemp.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.temperatureMaxTemp.validationMaxNumber'),
      gte: Ember.computed.alias('model.temperatureMaxTemp.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    temperatureCheckRate: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    temperatureMinTemp: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    temperatureMaxTemp: null,

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    temperatureCheckRateSelected: function () {
      var options = this.get('temperatureCheckRateOptions');
      var option = options.findBy('value', this.get('temperatureCheckRate.value'));

      if (!option) {
        option = options.findBy('value', '1');
      }

      return option;
    }.property('temperatureCheckRate.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    temperatureMinTempSelected: function () {
      var options = this.get('temperatureMinTempOptions');
      var option = options.findBy('value', this.get('temperatureMinTemp.value'));

      if (!option) {
        option = options.findBy('value', '10');
      }

      return option;
    }.property('temperatureMinTemp.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    temperatureMaxTempSelected: function () {
      var options = this.get('temperatureMaxTempOptions');
      var option = options.findBy('value', this.get('temperatureMaxTemp.value'));

      if (!option) {
        option = options.findBy('value', '40');
      }

      return option;
    }.property('temperatureMaxTemp.value'),

    /**
    * The options for the temperature check rate dropdown
    */
    temperatureCheckRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '1',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '2',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '3',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '5',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '10',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '15',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }];
    }.property(),

    /**
    * The options for the min temperature dropdown
    */
    temperatureMinTempOptions: function () {
      return [{
        value: '-40',
        label: '-40'
      }, {
        value: '-35',
        label: '-35'
      }, {
        value: '-30',
        label: '-30'
      }, {
        value: '-25',
        label: '-25'
      }, {
        value: '-20',
        label: '-20'
      }, {
        value: '-15',
        label: '-15'
      }, {
        value: '-10',
        label: '-10'
      }, {
        value: '-5',
        label: '-5'
      }, {
        value: '0',
        label: '0'
      }, {
        value: '5',
        label: '+5'
      }, {
        value: '10',
        label: '+10'
      }, {
        value: '15',
        label: '+15'
      }, {
        value: '20',
        label: '+20'
      }, {
        value: '25',
        label: '+25'
      }, {
        value: '30',
        label: '+30'
      }, {
        value: '35',
        label: '+35'
      }, {
        value: '40',
        label: '+40'
      }, {
        value: '45',
        label: '+45'
      }, {
        value: '50',
        label: '+50'
      }];
    }.property(),

    /**
    * The options for the max temperature dropdown
    */
    temperatureMaxTempOptions: function () {
      return [{
        value: '50',
        label: '+50'
      }, {
        value: '45',
        label: '+45'
      }, {
        value: '40',
        label: '+40'
      }, {
        value: '35',
        label: '+35'
      }, {
        value: '30',
        label: '+30'
      }, {
        value: '25',
        label: '+25'
      }, {
        value: '20',
        label: '+20'
      }, {
        value: '15',
        label: '+15'
      }, {
        value: '10',
        label: '+10'
      }, {
        value: '5',
        label: '+5'
      }, {
        value: '0',
        label: '0'
      }, {
        value: '-5',
        label: '-5'
      }, {
        value: '-10',
        label: '-10'
      }, {
        value: '-15',
        label: '-15'
      }, {
        value: '-20',
        label: '-20'
      }, {
        value: '-25',
        label: '-25'
      }, {
        value: '-30',
        label: '-30'
      }, {
        value: '-35',
        label: '-35'
      }, {
        value: '-40',
        label: '-40'
      }];
    }.property(),
    actions: {
      temperatureCheckRateChange: function temperatureCheckRateChange(selected) {
        this.set('temperatureCheckRate.value', selected.value);
      },
      temperatureMinTempChange: function temperatureMinTempChange(selected) {
        this.set('temperatureMinTemp.value', selected.value);
      },
      temperatureMaxTempChange: function temperatureMaxTempChange(selected) {
        this.set('temperatureMaxTemp.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('temperatureCheckRate').rollbackAttributes();
          this.get('temperatureMinTemp').rollbackAttributes();
          this.get('temperatureMaxTemp').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});