define("sky-router-3/pods/portal/manage/contacts/index/controller", ["exports", "sky-router-3/utils/filter-by-in-array"], function (_exports, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['groups'],
    groups: null,
    groupsSelected: (0, _filterByInArray.default)('model.groups', 'id', 'groups'),
    contactsFiltered: Ember.computed('model.contacts.[]', 'groups.[]', function () {
      var _this = this;

      return this.model.contacts.filter(function (contact) {
        if (_this.groups) {
          return contact.hasMany('groups').ids().any(function (id) {
            return _this.groups.includes(id);
          });
        }

        return true;
      });
    }),
    actions: {
      groupsFiltered: function groupsFiltered(groups) {
        this.set('groups', groups.mapBy('id'));
      },
      removeFilter: function removeFilter() {
        this.set('groups', null);
      }
    }
  });

  _exports.default = _default;
});