define("sky-router-3/models/user-two-factor-session", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    user: (0, _model.belongsTo)('user'),
    createdDateUtc: (0, _model.attr)('date'),
    expirationDateUtc: (0, _model.attr)('date'),
    lastSeenDateUtc: (0, _model.attr)('date'),
    lastSeenIPAddress: (0, _model.attr)('string'),
    browser: (0, _model.attr)('string'),
    os: (0, _model.attr)('string'),
    device: (0, _model.attr)('string')
  });

  _exports.default = _default;
});