define("sky-router-3/pods/portal/track/user-event/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.userEvent.type': (0, _emberCpValidations.validator)('presence', true),
    'model.userEvent.name': (0, _emberCpValidations.validator)('presence', true),
    'model.userEvent.description': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    queryParams: ['latitude', 'longitude'],
    expanded: false,
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      }
    }
  });

  _exports.default = _default;
});