define("sky-router-3/pods/playback/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _authenticatedRouteMixin, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _permissionRequiredMixin.default, {
    authenticationRoute: 'public.login',
    intl: Ember.inject.service(),

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.playback'],

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.playback');
    }
  });

  _exports.default = _default;
});