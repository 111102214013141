define("sky-router-3/pods/components/portal/manage/device-parameters/types/wave/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,
    // didInsertElement() {
    //     this.set('backlight', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('trackingMode', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('antennaMode', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('sound', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('led', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('brightness', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('language', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('units', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('vehicleLowPower', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('personalReportRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('personalEmergencyRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('vehicleReportRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('vehicleEmergencyRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('aviationReportRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('aviationEmergencyRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('lowPowerReportRate', this.store.createRecord('asset-parameter-set-revision-setting'));
    // },

    /**
     * Alias'd settings that are easier to get at.
     */
    backlight: (0, _computedFindBy.default)('settings', 'key', 'wave_backlight'),
    trackingMode: (0, _computedFindBy.default)('settings', 'key', 'wave_tracking_mode'),
    antennaMode: (0, _computedFindBy.default)('settings', 'key', 'wave_antenna_mode'),
    sound: (0, _computedFindBy.default)('settings', 'key', 'wave_sound'),
    led: (0, _computedFindBy.default)('settings', 'key', 'wave_led'),
    brightness: (0, _computedFindBy.default)('settings', 'key', 'wave_brightness'),
    language: (0, _computedFindBy.default)('settings', 'key', 'wave_language'),
    units: (0, _computedFindBy.default)('settings', 'key', 'wave_units'),
    vehicleLowPower: (0, _computedFindBy.default)('settings', 'key', 'wave_vehicle_low_power'),
    personalReportRate: (0, _computedFindBy.default)('settings', 'key', 'wave_personal_report_rate'),
    personalEmergencyRate: (0, _computedFindBy.default)('settings', 'key', 'wave_personal_emergency_rate'),
    vehicleReportRate: (0, _computedFindBy.default)('settings', 'key', 'wave_vehicle_report_rate'),
    vehicleEmergencyRate: (0, _computedFindBy.default)('settings', 'key', 'wave_vehicle_emergency_rate'),
    aviationReportRate: (0, _computedFindBy.default)('settings', 'key', 'wave_aviation_report_rate'),
    aviationEmergencyRate: (0, _computedFindBy.default)('settings', 'key', 'wave_aviation_emergency_rate'),
    lowPowerReportRate: (0, _computedFindBy.default)('settings', 'key', 'wave_lowpower_report_rate'),
    backLightSelected: (0, _computedFindBy.default)('backLightOptions', 'value', 'backlight.value', true),
    backLightOptions: Ember.computed(function () {
      return [{
        value: '2',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '3',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 1
        })
      }, {
        value: '5',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '6',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '7',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '8',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }];
    }),
    reportRateOptions: Ember.computed(function () {
      return [{
        value: '0',
        label: 'Off'
      }, {
        value: '2',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '3',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '4',
        label: this.intl.t('parameter.misc.countMinute')
      }, {
        value: '5',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '6',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '7',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '8',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '19',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '10',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '11',
        label: this.intl.t('parameter.misc.countHour')
      }, {
        value: '12',
        label: this.intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '13',
        label: this.intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '14',
        label: this.intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '15',
        label: this.intl.t('parameter.misc.countDay')
      }];
    }),
    personalReportRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'personalReportRate.value', true),
    personalEmergencyRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'personalEmergencyRate.value', true),
    lowPowerReportRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'lowPowerReportRate.value', true),
    vehicleReportRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'vehicleReportRate.value', true),
    vehicleEmergencyRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'vehicleEmergencyRate.value', true),
    aviationReportRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'aviationReportRate.value', true),
    aviationEmergencyRateSelected: (0, _computedFindBy.default)('reportRateOptions', 'value', 'aviationEmergencyRate.value', true),
    actions: {
      slectorChanged: function slectorChanged(key, selection) {
        this.set("".concat(key, ".value"), selection.value);
      }
    }
  });

  _exports.default = _default;
});