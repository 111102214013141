define("sky-router-3/pods/portal/manage/geofences/assignment-by-geofence/add/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        geofence: this.store.findRecord('geofence', params.geofence_id),
        assets: this.store.findAll('asset')
      });
    },
    afterModel: function afterModel(model) {
      if (!model.geofence.isOwnedByCaller) {
        this.transitionTo('portal.manage.geofences.assignment-by-geofence');
      }
    },
    resetController: function resetController(controller) {
      controller.set('selected', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.geofences.assignment-by-geofence');
      }
    }
  });

  _exports.default = _default;
});