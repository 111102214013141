define("sky-router-3/models/system-notification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var systemNotification = _model.default.extend({
    title: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    dateCreatedUTC: (0, _model.attr)('date'),
    isDismissed: (0, _model.attr)('boolean')
  });

  var _default = systemNotification;
  _exports.default = _default;
});