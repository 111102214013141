define("sky-router-3/models/alert-log", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This describes the possible asset categories that are available to assets.
   * This is land, air, marine, other.
   */
  var model = _model.default.extend({
    /**
     * The alert definition associated with this log record
     */
    alertDefinition: (0, _model.belongsTo)('alert-definition'),

    /**
     * The asset position that triggered this alert log record
     */
    assetPosition: (0, _model.belongsTo)('asset-position'),

    /**
     * The date the record was created.
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * The date that the alert was sent out. Not the date it was triggerd.
     * Sometimes there is a delay between when the record was created and when
     * the alert is sent out.
     */
    sentDate: (0, _model.attr)('date'),

    /**
     * The subject the was sent along with the body of the alert.
     * Not all alerts support subject fields
     */
    subject: (0, _model.attr)('string'),

    /**
     * The body of the sent alert.
     */
    body: (0, _model.attr)('string'),

    /**
     * String CSV of recipients of the alert record
     */
    recipients: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});