define("sky-router-3/pods/portal/report/invoiceplanusage/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.invoicePlanUsage');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.invoiceplanusage'],

    /**
     * The model for the invoice plan usage route that contains the SBD and voice plans for the current invoice
     **/
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        // SBD Plans
        dataPlans: this.store.findAll('invoice-plan-usage-data', {
          reload: true
        }).then(function (plans) {
          plans.forEach(function (plan) {
            _this.setPlanProperties(plan);
          });
          return plans;
        }),
        // Voice Plans
        voicePlans: this.store.findAll('invoice-plan-usage-voice', {
          reload: true
        }).then(function (plans) {
          plans.forEach(function (plan) {
            _this.setPlanProperties(plan);
          });
          return plans;
        })
      });
    },

    /**
     * Sets the properties for customer plan
     * @function
     * @param  {Object} 'plan' A customer plan
     */
    setPlanProperties: function setPlanProperties(plan) {
      // Set the usage bar styling
      plan.set('barOptions', {
        strokeWidth: 3,
        trailWidth: 2,
        trailColor: '#ffffff',
        text: {
          style: {
            visibility: 'hidden'
          }
        }
      });
    }
  });

  _exports.default = _default;
});