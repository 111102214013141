define("sky-router-3/pods/portal/operations/burst-services/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    messageStatusList: null,
    messageStatusDetails: null,
    detailedMessageDisplayed: null,
    queryParams: ['skip', 'take'],
    skip: 0,
    take: 10,
    takeSelections: [10, 20, 50],
    pages: Ember.computed('model.messageHistory.meta', 'skip', 'take', function () {
      var meta = this.model.messageHistory.get('meta');
      var pageCount = Math.ceil(meta.CountPossible / this.take);
      var result = [];

      for (var i = 0; i < pageCount; i++) {
        result.push({
          number: i,
          skip: i * this.take,
          isActive: i * this.take == this.skip
        });
      }

      return result;
    }),
    pageLastItemIndex: Ember.computed('model.messageHistory.meta', function () {
      var meta = this.model.messageHistory.get('meta');
      return meta.CountSkipped + meta.CountReturned;
    }),
    pageFirstItemIndex: Ember.computed('model.messageHistory.meta', function () {
      var meta = this.model.messageHistory.get('meta');
      return meta.CountSkipped + 1;
    }),
    pageIndex: Ember.computed('messageStatusList.[]', {
      get: function get() {
        return 0;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [10, 20, 50],
    actions: {
      countChanged: function countChanged(val) {
        this.set('skip', 0);
        this.set('take', val);
      },
      detailsInfo: function detailsInfo(details) {
        this.set('messageStatusDetails', details);
      },
      details: function details(message) {
        var _this = this;

        this.get('api').burstMessageStatus(message.messageId).then(function (rsp) {
          _this.set('messageStatusList', rsp);

          _this.set('detailedMessageDisplayed', message);

          if (_this.messageStatusList.length === 1) {
            _this.messageStatusDetails = _this.messageStatusList[0];
          }
        }).catch(function (e) {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      },
      cancel: function cancel(message) {
        var _this2 = this;

        this.get('api').burstMessageCancel(message.messageId).then(function (rsp) {
          _this2.get('store').findRecord('burst-service-message-history', message.id, {
            reload: true
          });

          _this2.notifications.success(_this2.intl.t('misc.successfulOperation'));
        }).catch(function (e) {
          // Give positive feedback, but don't try and update record at the UI. Most likely it was already completed.
          _this2.notifications.success(_this2.intl.t('misc.successfulOperation'));
        });
      },
      closeDetails: function closeDetails() {
        this.set('messageStatusList', null); // Fetch record and update it in the store, this will 

        this.get('store').findRecord('burst-service-message-history', this.detailedMessageDisplayed.id, {
          reload: true
        });
        this.set('detailedMessageDisplayed', null);
        this.set('messageStatusDetails', null);
      }
    }
  });

  _exports.default = _default;
});