define("sky-router-3/pods/components/checkbox-dropdownlist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hgdjKe9E",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"button\"],[14,0,\"btn btn-default dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[14,5,\"max-height:400px; overflow-y:auto\"],[14,0,\"dropdown-menu dropdown-auto\"],[14,\"role\",\"menu\"],[14,\"data-autoclose\",\"false\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n                \"],[10,\"label\"],[12],[2,\"\\n                    \"],[1,[30,[36,1],null,[[\"checked\",\"onChange\"],[[32,1,[\"checked\"]],[30,[36,0],[[32,0],\"inputChecked\",[32,1]],null]]]]],[1,[32,1,[\"text\"]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"input-checkbox\",\"buttontext\",\"items\",\"-track-array\",\"each\"]}",
    "moduleName": "sky-router-3/pods/components/checkbox-dropdownlist/template.hbs"
  });

  _exports.default = _default;
});