define("sky-router-3/models/location-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-env node */
  var model = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    locations: (0, _model.hasMany)('location', {
      async: true
    }),
    iconUrl: (0, _model.attr)('string'),
    canAlter: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  var _default = model;
  _exports.default = _default;
});