define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/tracking-activity-sense/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'enabledSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.enabledSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.accelerationThreshold.validationMinNumber')
    })],
    'accelerationThreshold.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.accelerationThreshold.validationMaxNumber'),
      gte: Ember.computed.alias('model.accelerationThreshold.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    accelerationThreshold: null,

    /**
    * Property for checking if the feature is enabled
    */
    enabledSet: function () {
      return '0' !== this.get('enabledSetting.value');
    }.property('enabledSetting.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    enabledSettingSelected: function () {
      var options = this.get('enabledSettingOptions');
      var option = options.findBy('value', this.get('enabledSetting.value'));

      if (!option) {
        option = options.findBy('value', '0');
      }

      return option;
    }.property('enabledSetting.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    accelerationThresholdSelected: function () {
      var options = this.get('accelerationThresholdOptions');
      var option = options.findBy('value', this.get('accelerationThreshold.value'));

      if (!option) {
        option = options.findBy('value', '49');
      }

      return option;
    }.property('accelerationThreshold.value'),

    /**
    * The options for the acceleration threshold dropdown
    */
    enabledSettingOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '0',
        label: intl.t('parameter.rockseven.activitySense.off')
      }, {
        value: '1',
        label: intl.t('parameter.rockseven.activitySense.power')
      }, {
        value: '2',
        label: intl.t('parameter.rockseven.activitySense.bump')
      }, {
        value: '3',
        label: intl.t('parameter.rockseven.activitySense.sog')
      }, {
        value: '4',
        label: intl.t('parameter.rockseven.activitySense.bumpAndSog')
      }];
    }.property(),

    /**
    * The options for the acceleration threshold dropdown
    */
    accelerationThresholdOptions: function () {
      return [{
        value: '18',
        label: '18'
      }, {
        value: '19',
        label: '19'
      }, {
        value: '19',
        label: '19'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '21',
        label: '21'
      }, {
        value: '22',
        label: '22'
      }, {
        value: '23',
        label: '23'
      }, {
        value: '24',
        label: '24'
      }, {
        value: '25',
        label: '25'
      }, {
        value: '26',
        label: '26'
      }, {
        value: '27',
        label: '27'
      }, {
        value: '28',
        label: '28'
      }, {
        value: '29',
        label: '29'
      }, {
        value: '30',
        label: '30'
      }, {
        value: '30',
        label: '30'
      }, {
        value: '31',
        label: '31'
      }, {
        value: '32',
        label: '32'
      }, {
        value: '33',
        label: '33'
      }, {
        value: '34',
        label: '34'
      }, {
        value: '35',
        label: '35'
      }, {
        value: '36',
        label: '36'
      }, {
        value: '37',
        label: '37'
      }, {
        value: '38',
        label: '38'
      }, {
        value: '39',
        label: '39'
      }, {
        value: '40',
        label: '40'
      }, {
        value: '41',
        label: '41'
      }, {
        value: '42',
        label: '42'
      }, {
        value: '43',
        label: '43'
      }, {
        value: '44',
        label: '44'
      }, {
        value: '45',
        label: '45'
      }, {
        value: '46',
        label: '46'
      }, {
        value: '47',
        label: '47'
      }, {
        value: '48',
        label: '48'
      }, {
        value: '49',
        label: '49'
      }, {
        value: '50',
        label: '50'
      }, {
        value: '51',
        label: '51'
      }, {
        value: '52',
        label: '52'
      }, {
        value: '53',
        label: '53'
      }, {
        value: '54',
        label: '54'
      }];
    }.property(),
    actions: {
      enabledSettingChange: function enabledSettingChange(selected) {
        this.set('enabledSetting.value', selected.value);

        if (selected.value === '0') {
          this.get('accelerationThreshold').rollbackAttributes();
        }
      },
      accelerationThresholdChange: function accelerationThresholdChange(selected) {
        this.set('accelerationThreshold.value', selected.value);
      }
    }
  });

  _exports.default = _default;
});