define("sky-router-3/pods/components/portal/manage/device-parameters/types/edgesolar/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,

    /**
     * Alias'd settings that are easier to get at.
     */
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'edge_solar_time_reporting_enabled'),
    timeReportingMinutes: (0, _computedFindBy.default)('settings', 'key', 'edge_solar_time_reporting_minutes')
  });

  _exports.default = _default;
});