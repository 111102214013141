define("sky-router-3/pods/components/portal/track/map/map-distance-tool/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    googlePropertyMap: [{
      event: 'click',
      eFunc: 'mapClick'
    }],

    /**
     * This is used to initialize the property mixin.
     */
    googlePropertyObject: Ember.computed.alias('map'),

    /**
     * The map instance this tool is associated with
     * @type {google.maps.map}
     */
    map: null,

    /**
     * Array of points to be shown. Consists of an object
     * {
     * 	point:  <google.maps.latLng>
     * 	latMilli: <number> latitude in milliarcseconds
     * 	lngMilli: <number>, longitude in milliarcseconds
     * 	distance: <number>, distance in centimeters
     * }
     * @return {Array}
     */
    points: null,

    /**
     * Array of google maps latlng points that matches the points array
     * @return {Array<google.maps.latLng>}
     */
    pointsGoogle: Ember.computed.mapBy('points', 'point'),

    /**
     * Initializes the points array
     */
    init: function init() {
      this.set('points', []);

      this._super.apply(this, arguments);
    },

    /**
     * Sets the visible points with every click
     * @param  {google.maps.map} map
     * @param  {google.maps.mouseEvent} event
     */
    mapClick: function mapClick(map, event) {
      var obj = {
        point: event.latLng,
        latMilli: _latLngConverter.default.degreesToMilliArcSeconds(event.latLng.lat()),
        lngMilli: _latLngConverter.default.degreesToMilliArcSeconds(event.latLng.lng())
      };
      this.points.pushObject(obj);
      this.reCalculatePoints(this.points);
    },
    reCalculatePoints: function reCalculatePoints(points) {
      var lats = points.mapBy('point');

      for (var i = 1; i < points.length; i++) {
        var point = points.objectAt(i);
        var prevPoint = points.objectAt(i - 1);
        Ember.set(point, 'distance', google.maps.geometry.spherical.computeDistanceBetween(prevPoint.point, point.point) * 100);
        Ember.set(point, 'distanceTotal', google.maps.geometry.spherical.computeLength(lats.slice(0, i + 1)) * 100);

        if (prevPoint) {
          Ember.set(prevPoint, 'heading', _latLngConverter.default.truncate(google.maps.geometry.spherical.computeHeading(prevPoint.point, point.point), 2));
        }
      }
    },
    actions: {
      clearPoints: function clearPoints() {
        this.set('points', []);
      },
      markerDrag: function markerDrag(point, index, marker, event) {
        Ember.set(point, 'point', event.latLng);
        Ember.set(point, 'latMilli', _latLngConverter.default.degreesToMilliArcSeconds(event.latLng.lat()));
        Ember.set(point, 'lngMilli', _latLngConverter.default.degreesToMilliArcSeconds(event.latLng.lng()));
        this.reCalculatePoints(this.points);
      },
      removePoint: function removePoint(point) {
        this.points.removeObject(point);
        this.reCalculatePoints(this.points); // The map event will fire before this and there is nothing
        // we can do about it for some god damn fucking reason.

        this.points.removeObject(this.points.lastObject);
      }
    }
  });

  _exports.default = _default;
});