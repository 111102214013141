define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-0/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'assetPositionTypeSelected': (0, _emberCpValidations.validator)('presence', true),
    'model.repeat': (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 15,
      lte: 6000000
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Passed in output model.
     * @type {asset-compound-rule-revision-output}
     */
    model: null,

    /**
     * Asset position dropdown
     */
    assetPositionTypes: function () {
      return this.get('store').findAll('asset-position-type');
    }.property(''),
    assetPositionTypeSelected: function () {
      var types = this.get('assetPositionTypes');

      if (types.get('isFulfilled')) {
        return this.get('assetPositionTypes.content').findBy('id', this.get('model.value1'));
      }
    }.property('assetPositionTypes.[]', 'model.value1'),

    /**
     * Controls the repeat block
     */
    repeatSelected: function () {
      var options = this.get('repeatOptions');
      var option = options.findBy('value', this.get('model.repeat'));

      if (!option) {
        option = options.findBy('value', -1);
      }

      return option;
    }.property('model.repeat'),
    repeatCustomShown: Ember.computed.equal('repeatSelected.value', -1),
    repeatOptions: function () {
      var intl = this.get('intl');
      return [{
        value: 0,
        label: intl.t('parameter.misc.noRepeat')
      }, {
        value: 30,
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: 45,
        label: intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: 60,
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: 120,
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: 180,
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: 240,
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: 300,
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: 480,
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: 600,
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: 900,
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: 1200,
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: 1800,
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: 2700,
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: 3600,
        label: intl.t('parameter.misc.countHour')
      }, {
        value: -1,
        label: intl.t('parameter.misc.custom')
      }];
    }.property(),
    actions: {
      repeatChanged: function repeatChanged(option) {
        this.set('model.repeat', option.value === -1 ? '' : option.value);
      },
      eventChanged: function eventChanged(option) {
        this.set('model.value1', option.get('id'));
      }
    }
  });

  _exports.default = _default;
});