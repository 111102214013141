define("sky-router-3/pods/portal/report/groupassociations/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the group association report. It handles creating the
   * report parameter option which for this report only includes an array of association options
   * the user can choose from.
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.groupAssociationReport');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.groupassociation'],

    /**
     * The model for the association reports route that contains an array of association options.
     **/
    model: function model(params) {
      var associations = this.getAssociationOptions();
      var selection = params.selection && $.isNumeric(params.selection) ? associations.findBy('id', parseInt(params.selection)) : associations[0];

      if (selection === undefined) {
        selection = associations[0];
      } // Return the hashed model values


      return Ember.RSVP.hash({
        // The array of association options
        associationOptions: associations,
        selectedOption: selection
      });
    },

    /**
     * Creates an array of group association options
     * @function
     * @return {Array objects} The group association array
     */
    getAssociationOptions: function getAssociationOptions() {
      var intl = this.get('intl');
      return [{
        id: 1,
        text: intl.t('report.groupAssociation.options.groups')
      }, {
        id: 2,
        text: intl.t('report.groupAssociation.options.devices')
      }, {
        id: 3,
        text: intl.t('report.groupAssociation.options.users')
      }];
    }
  });

  _exports.default = _default;
});