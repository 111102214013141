define("sky-router-3/pods/public/login/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    touchId: Ember.inject.service(),

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('login.loginTitle');
    },

    /**
     * Set from query string but don't let what the user entered show up at the top
     */
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (transition.to.queryParams) {
        var controller = this.controllerFor(this.routeName);
        var username = transition.to.queryParams.username;
        var password = transition.to.queryParams.password;
        controller.set('username', username);
        controller.set('password', password);
      } // Auto login user when the boot up the app


      if (this.touchId.isSetup) {
        this.checkForAutoLogin();
      } else {
        this.touchId.one('login_located', function () {
          return _this.checkForAutoLogin();
        });
      }
    },
    checkForAutoLogin: function checkForAutoLogin() {
      if (this.touchId.loginStored) {
        this.send('loginUserTouchId');
      }
    }
  });

  _exports.default = _default;
});