define("sky-router-3/pods/portal/report/business-continuity/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.businesscontinuity'],
    model: function model(params) {
      return Ember.RSVP.hash({
        reports: this.store.findAll('business-continuity')
      });
    },
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    actions: {
      sendEmail: function sendEmail() {
        var _this = this;

        this.send('blockApp', true, {
          message: this.intl.t('report.businessContinuity.emailInProgress')
        });
        this.api.sendBusinessContinuityReportEmail().then(function () {
          _this.send('blockApp', false);

          _this.notifications.success(_this.intl.t('report.businessContinuity.emailSuccess'));
        }).catch(function () {
          _this.send('blockApp', false);

          _this.notifications.error(_this.intl.t('report.businessContinuity.emailFailure'));
        });
      }
    }
  });

  _exports.default = _default;
});