define("sky-router-3/models/driver", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the driver
     */
    name: (0, _model.attr)('string'),

    /**
     * Type of the driver (personnel or user)
     */
    type: (0, _model.attr)('string'),

    /**
     * Type of the driver phone number or cell phone number
     */
    phone: (0, _model.attr)('string'),

    /**
     * Type of the driver employee number
     */
    employeeNumber: (0, _model.attr)('string')
  });

  _exports.default = _default;
});