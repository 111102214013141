define("sky-router-3/pods/components/portal/track/map/overlays/our-airports/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/browser-detect", "sky-router-3/config/environment"], function (_exports, _googleObjectMapping, _computedJsonObject, _latLngConverter, _browserDetect, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Airports based on the OurAirports database
   * http://ourairports.com
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * The config that is powering the map
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each TFR record downloaded.
     * Based on this icon set
     * https://www.aviationweather.gov/airep/help?page=inter
     */
    style: function style(feature) {
      var size = 20;
      var icon = {
        url: "".concat(_environment.default.rootURL, "assets/images/overlays/our-airports/").concat(feature.getProperty('type'), ".svg"),
        scaledSize: new google.maps.Size(size, size),
        size: new google.maps.Size(size, size),
        anchor: new google.maps.Point(size / 2, size / 2)
      };
      return {
        optimized: !(0, _browserDetect.isIE)(),
        // undocumented IE FIX
        icon: icon
      };
    },

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getFeatureInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout()
    /*dataObject, mouseEvent*/
    {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
    },

    /**
     * Launches the FAA link for the TFR.
     */
    _click: function _click(dataObject, mouseEvent) {
      var _this = this;

      this.set('popupBody', this._getFeatureInfo(mouseEvent.feature));
      this.get('api').request("/Overlays/OurAirports/Airports/".concat(mouseEvent.feature.getProperty('id'), "/Frequencies")).then(function (d) {
        _this.set('popupFrequencies', d);
      });
      this.get('api').request("/Overlays/OurAirports/Airports/".concat(mouseEvent.feature.getProperty('id'), "/Runways")).then(function (d) {
        _this.set('popupRunways', d);
      });
      this.set('popupOpen', true);
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getFeatureInfo: function _getFeatureInfo(feature) {
      return {
        id: feature.getProperty('id'),
        ident: feature.getProperty('ident'),
        name: feature.getProperty('name'),
        type: feature.getProperty('type'),
        closed: feature.getProperty('type') === 'closed',
        latitude: _latLngConverter.default.degreesToMilliArcSeconds(feature.getProperty('latitude')),
        longitude: _latLngConverter.default.degreesToMilliArcSeconds(feature.getProperty('longitude')),
        elevation: feature.getProperty('elevation'),
        continent: feature.getProperty('continent'),
        countryCode: feature.getProperty('iso_country'),
        country: feature.getProperty('country'),
        regionCode: feature.getProperty('iso_region'),
        region: feature.getProperty('region'),
        municipality: feature.getProperty('municipality'),
        scheduledService: feature.getProperty('scheduled_service'),
        gpsCode: feature.getProperty('gps_code'),
        iataCode: feature.getProperty('iata_code'),
        localCode: feature.getProperty('local_code'),
        url: feature.getProperty('home_link'),
        urlWiki: feature.getProperty('wiki_link')
      };
    },

    /**
     * Creates a bounds listener so that the overlay boots when the map finally loads.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.autoReloadGeoJson));
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function () {
      var _this2 = this;

      Ember.run.join(function () {
        var map = _this2.get('map');

        var bounds = map.getBounds();

        if (!bounds) {
          return;
        }

        var url = _this2.get('dataObject.url');

        if (!url) {
          return;
        }

        var opts = {
          data: {
            bbox: _latLngConverter.default.getBBoxString(bounds),
            count: 50,
            types: _this2.getTypes()
          }
        };

        if (_this2.get('settings.generic.search')) {
          opts.data.search = _this2.get('settings.generic.search');
        }

        _this2.get('api').request(url, opts).then(function (x) {
          var obj = _this2.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    }.observes('settings.generic.hideSmall', 'settings.generic.hideMedium', 'settings.generic.hideLarge', 'settings.generic.hideHeli', 'settings.generic.hideSeaplane', 'settings.generic.hideBaloon', 'settings.generic.hideClosed', 'settings.generic.search'),

    /**
     * Returns a type array that can be used for URL formulation
     */
    getTypes: function getTypes() {
      var types = [];

      if (!this.get('settings.generic.hideSmall')) {
        types.push('small_airport');
      }

      if (!this.get('settings.generic.hideMedium')) {
        types.push('medium_airport');
      }

      if (!this.get('settings.generic.hideLarge')) {
        types.push('large_airport');
      }

      if (!this.get('settings.generic.hideHeli')) {
        types.push('heliport');
      }

      if (!this.get('settings.generic.hideSeaplane')) {
        types.push('seaplane_base');
      }

      if (!this.get('settings.generic.hideBaloon')) {
        types.push('balloonport');
      }

      if (!this.get('settings.generic.hideClosed')) {
        types.push('closed');
      }

      if (types.get('length') == 7) {
        return [];
      }

      return types;
    },

    /**
     * Forces the reloading of the metar data when the map moves or is zoomed.
     */
    autoReloadGeoJson: function () {
      Ember.run.debounce(this, this.loadGeoJson, 500);
    }.observes('mapConfig.center', 'mapConfig.zoom'),

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this3 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this3.loadGeoJson();

        _this3.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      this.mapBoundsListener.remove();
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },
    actions: {
      closePopup: function closePopup() {
        this.set('popupOpen', false);
        this.set('popupBody', null);
        this.set('popupFrequencies', null);
        this.set('popupRunways', null);
      }
    }
  });

  _exports.default = _default;
});