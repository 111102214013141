define("sky-router-3/pods/portal/communicate/compose/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S890Mcta",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"communicate.headerComposeNewMessage\"],null]],[13],[2,\"\\n\"],[1,[30,[36,9],null,[[\"assets\",\"contacts\",\"subject\",\"to\",\"cc\",\"bcc\",\"body\",\"onSave\",\"onSend\",\"isSaving\"],[[35,8,[\"assets\"]],[35,8,[\"contacts\"]],[35,7],[35,6],[35,5],[35,4],[35,3],[30,[36,2],[\"createDraft\"],null],[30,[36,2],[\"createMessage\"],null],[35,1,[\"isSaving\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"message\",\"route-action\",\"body\",\"bcc\",\"cc\",\"to\",\"subject\",\"model\",\"portal/communicate/compose-message\"]}",
    "moduleName": "sky-router-3/pods/portal/communicate/compose/index/template.hbs"
  });

  _exports.default = _default;
});