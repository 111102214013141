define("sky-router-3/pods/components/scroll-box/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This presents a yeildable container that fires actions when the user
   * scrolls to the bottom or the top of the container.
   */
  var _default = Ember.Component.extend({
    /**
     * Noop actions
     */
    scrollbarVisible: function scrollbarVisible() {},
    scrollAtTop: function scrollAtTop() {},
    scrollAtBottom: function scrollAtBottom() {},

    /**
     * How long to debounce triggering events
     * @type {Number}
     */
    eventDebounce: 10,

    /**
     * Holder to see if the scrollbar became visible or became invisible
     * this holds the old value for comparision.
     * @type {Boolean}
     */
    _scrollbarWasVisible: null,

    /**
     * Attaches scroll listeners to the scrollTarget
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('guid', Ember.guidFor(this));

      this._bindEvent('scroll');

      this._bindEvent('resize');

      this._checkScrollStatus();
    },

    /**
     * Destroys the scroll listeners on the scrollTarget
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._unbindEvent('scroll');

      this._unbindEvent('resize');
    },

    /**
     * Binds an event.
     * @see https://github.com/hhff/ember-infinity/blob/master/addon/components/infinity-loader.js
     * @param  {[type]} eventName [description]
     * @return {[type]}           [description]
     */
    _bindEvent: function _bindEvent(eventName) {
      var _this = this;

      (0, _jquery.default)(this.element).on("".concat(eventName, ".").concat(this.get('guid')), function () {
        Ember.run.debounce(_this, _this._checkScrollStatus, _this.get('eventDebounce'));
      });
    },

    /**
     * Unbinds an event
     * @see https://github.com/hhff/ember-infinity/blob/master/addon/components/infinity-loader.js
     * @param  {[type]} eventName [description]
     * @return {[type]}           [description]
     */
    _unbindEvent: function _unbindEvent(eventName) {
      (0, _jquery.default)(this.element).off("".concat(eventName, ".").concat(this.get('guid')));
    },

    /**
     * Checks to see if the scroll has changed to the point that an event needs to fire
     */
    _checkScrollStatus: function _checkScrollStatus() {
      if (!this.isDestroying && !this.isDestroyed) {
        var scrollPosition = (0, _jquery.default)(this.element).scrollTop();
        var elementHeight = (0, _jquery.default)(this.element).height();
        var scrollHeight = this.element.scrollHeight;
        var scrollBarNowVisible = scrollHeight > elementHeight;

        if (this.get('_scrollbarWasVisible') !== scrollBarNowVisible) {
          this.scrollbarVisible(scrollBarNowVisible);
          this.set('_scrollbarWasVisible', scrollBarNowVisible);
        }

        if (scrollBarNowVisible) {
          if (scrollPosition === 0) {
            this.scrollAtTop();
          } else if (scrollHeight - scrollPosition === elementHeight) {
            this.scrollAtBottom();
          }
        }
      }
    }
  });

  _exports.default = _default;
});