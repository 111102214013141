define("sky-router-3/pods/error/not-found/route", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * This checks the url that 404'd and if it contains upercase characters
     * it redirects the user to the lower case URL since ember's router is
     * case sensitive and all routes are declared in lower case.
     */
    beforeModel: function beforeModel(transition) {
      var url = transition.intent.url;
      var regex = new RegExp("^".concat(_environment.default.baseURL), "i");
      var redirect = false; // Make sure they didn't come here with some sort of
      // capitalization in the base URL.

      if (regex.test(url)) {
        url = url.replace(regex, '/');
        redirect = true;
      } // Check for capitalization


      if (/[A-Z]/.test(url)) {
        url = url.toLowerCase();
        redirect = true;
      }

      if (redirect) {
        this.replaceWith(url);
      }
    }
  });

  _exports.default = _default;
});