define("sky-router-3/pods/portal/operations/routes/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Binding the query parameters to the search field
     * @type {Array}
     */
    queryParams: ['search', 'archived'],

    /**
     * Holds a text value that is being used for searching
     * @type {String}
     */
    search: null,

    /**
     * Filter on archived routes
     * @type {Boolean}
     */
    archived: false,

    /**
     * Is the filter set or not
     * @type {Boolean}
     */
    filterSet: Ember.computed.or('archived', 'search'),

    /**
     * Locations that are filtered by the search
     */
    filteredRoutes: Ember.computed.filter('model', ['search', 'model.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search);
      }

      return true;
    }),

    /**
     * The property by which locations are sorted
     * @type {String}
     */
    sortProperty: 'name',

    /**
     * The direction that the list is sorted
     * @type {Boolean}
     */
    sortDesc: false,

    /**
     * Returns a string used by sortedRoutes to sort the collection
     * @return {Array<string>}
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),

    /**
     * Routes that have been sorted
     */
    sortedRoutes: Ember.computed.sort('filteredRoutes', 'sortBy'),
    actions: {
      sortDescChanged: function sortDescChanged() {
        this.toggleProperty('sortDesc');
      },
      resetFilter: function resetFilter() {
        this.set('archived', false);
        this.set('search', null);
      }
    }
  });

  _exports.default = _default;
});