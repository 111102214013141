define("sky-router-3/models/burst-service-message-history", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * ServiceName (name of the service in SkyRouter)
     */
    burstServiceName: (0, _model.attr)('string'),

    /**
     * ServiceName (name of the service with Iridium)
     */
    serviceName: (0, _model.attr)('string'),

    /**
     * Message Abstract
     */
    abstract: (0, _model.attr)('string'),

    /**
     * messageId (iridium's email_id)
     */
    messageId: (0, _model.attr)('string'),

    /**
     * State Id
     */
    stateId: (0, _model.attr)('number'),

    /**
     * Status details
     */
    messageStatusDetails: (0, _model.attr)('string'),

    /**
     * Creation date
     */
    createdDate: (0, _model.attr)('date'),
    //orgId, userid, stateId
    //state: belongsTo('burst-service-message-state', { async: true }),

    /**
     * State of message
     */
    state: (0, _model.attr)('string'),

    /**
     * UserId that sent the message
     */
    userId: (0, _model.attr)('string')
  });

  _exports.default = _default;
});