define("sky-router-3/pods/components/portal/track/map/overlays/image-tile/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object"], function (_exports, _googleObjectMapping, _computedJsonObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * This 0 based index indicates the position of this overlay in the
     * total number of overlays displayed.
     * @type {Number}
     */
    index: 0,
    _index_current: 0,

    /**
     * The map this overlay is associated with
     * @type {sky-router-3/objects/state-track/state-track-map}
     */
    mapConfig: null,

    /**
     * The overlay settings object that contains information about the overlay
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Holds the opacity setting used by the google map
     * @return {Number}
     */
    opacity: function () {
      return this.get('settings.opacity') * 0.01;
    }.property('settings.opacity'),

    /**
     * Gets the bounds for this layer from the data string in pixels.
     * This is used by the getTileUrl to figure out the bounds of the layer
     * @return {Object}
     */
    pointBounds: function () {
      var map = this.get('map');
      var data = this.get('dataObject');

      if (!!map && !!data) {
        var split = data.bounds.split(',');
        var projection = map.getProjection();
        return {
          sw: projection.fromLatLngToPoint(new google.maps.LatLng(split[1], split[0])),
          ne: projection.fromLatLngToPoint(new google.maps.LatLng(split[3], split[2]))
        };
      }
    }.property('map', 'overlay.dataString'),

    /**
     * Private variables to help prevent recomputes of values used below
     * @type {[type]}
     */
    _currentTileBounds: null,
    _currentTileBoundsZoom: null,

    /**
     * Gets the bounds of this layer at the current zoom level. This returns
     * the bouding tiles coordinates.
     * @return {Object}
     */
    getTileBounds: function getTileBounds(zoom) {
      if (zoom === this._currentTileBoundsZoom) {
        return this._currentTileBounds;
      }

      var scale = 1 << zoom;
      var bounds = this.get('pointBounds');
      this._currentTileBoundsZoom = zoom;
      return this._currentTileBounds = {
        sw: {
          x: Math.floor(bounds.sw.x * scale / 256),
          y: Math.floor(bounds.sw.y * scale / 256)
        },
        ne: {
          x: Math.floor(bounds.ne.x * scale / 256),
          y: Math.floor(bounds.ne.y * scale / 256)
        }
      };
    },

    /**
     * Instance of the ImageMapType layer object
     * @return {google.maps.ImageMapType}
     */
    googlePropertyObject: function () {
      var data = this.get('dataObject');
      var properties = {
        getTileUrl: Ember.run.bind(this, this.getTileUrl, data),
        tileSize: new google.maps.Size(256, 256)
      };
      Ember.assign(properties, data);
      return new google.maps.ImageMapType(properties);
    }.property('overlay.dataString'),

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'opacity',
      setFunc: 'setOpacity'
    }],

    /**
     * Instance of the ImageMapType layer object
     * @return {google.maps.ImageMapType}
     */
    didInsertElement: function didInsertElement() {
      var overlay = this.get('googlePropertyObject');
      this.get('map').overlayMapTypes.insertAt(this.get('index'), overlay);
    },

    /**
     * This is triggered when the the index of the overlay or other property
     * is changed. During the update we check to see if the index changed.
     * If it did then move the overlay to the correct spot.
     */
    didUpdateAttrs: function didUpdateAttrs() {
      var oldIndex = this.get('_index_current');
      var newIndex = this.get('index');

      if (oldIndex !== newIndex) {
        var mapTypes = this.get('map').overlayMapTypes;
        var overlay = mapTypes.getAt(oldIndex);

        if (overlay === this.get('googlePropertyObject')) {
          mapTypes.removeAt(oldIndex);
          mapTypes.insertAt(newIndex, this.get('googlePropertyObject'));
        }
      }

      this.set('_index_current', newIndex);
    },

    /**
     * Responsible for destroying the ImageMapType layer
     */
    willDestroyElement: function willDestroyElement() {
      var overlay = this.get('googlePropertyObject');
      var overlayIndex;
      var loadedOverlays = this.get('map').overlayMapTypes;
      loadedOverlays.forEach(function (item, index) {
        if (item === overlay) {
          overlayIndex = index;
        }
      });
      loadedOverlays.removeAt(overlayIndex);
    },

    /**
     * This function is fired to get the URL for the tile in question
     * @param  {[type]} coord      [description]
     * @param  {[type]} zoom       [description]
     * @param  {[type]} properties [description]
     * @return {[type]}            [description]
     */
    getTileUrl: function getTileUrl(properties, coord, zoom) {
      var normalized = this.getNormalizedCoord(coord, zoom);

      if (!normalized || !this.inOverlayBounds(normalized, zoom, properties)) {
        return null;
      }

      return properties.url.replace("{zoom}", zoom).replace("{x}", normalized.x).replace("{y}", normalized.y).replace("{y_tms}", normalized.y_tms);
    },

    /**
     *  This checks the coordinates requested with the overlay layer to see if
     *  a tile is present that can be downloaded
     * @param  {[type]} coord    [description]
     * @param  {[type]} zoom     [description]
     * @param  {[type]} settings [description]
     * @return {[type]}          [description]
     */
    inOverlayBounds: function inOverlayBounds(coord, zoom, settings) {
      // Check the zoom level
      if (zoom > settings.maxzoom || zoom < settings.minzoom) {
        return false;
      } // Check for bounds


      var bounds = this.getTileBounds(zoom);

      if (coord.x < bounds.sw.x || coord.x > bounds.ne.x) {
        return false;
      }

      if (coord.y < bounds.ne.y || coord.y > bounds.sw.y) {
        return false;
      }

      return true;
    },
    // Normalizes the coords that tiles repeat across the x axis (horizontally)
    // like the standard Google map tiles.
    // Stolen from here: https://developers.google.com/maps/documentation/javascript/examples/maptype-image
    getNormalizedCoord: function getNormalizedCoord(coord, zoom) {
      var y = coord.y;
      var x = coord.x; // tile range in one direction range is dependent on zoom level
      // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc

      var tileRange = 1 << zoom; // don't repeat across y-axis (vertically)

      if (y < 0 || y >= tileRange) {
        return null;
      } // repeat across x-axis


      if (x < 0 || x >= tileRange) {
        x = (x % tileRange + tileRange) % tileRange;
      }

      return {
        x: x,
        y: y,
        y_tms: (1 << zoom) - y - 1 // See https://gist.github.com/tmcw/4954720

      };
    }
  });

  _exports.default = _default;
});