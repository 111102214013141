define("sky-router-3/pods/components/context-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a floating bootstrap dropdown menu that is placed on the screen
   * where the `x` and `y` property state it should go.
   *
   * It does not actually create any content. Any `{{yield}}` content should
   * adhere to the rules of menu items inside a `boostrap.dropdown-menu`.
   *
   * Uses absolute positioning to determine location on screen.
   *
   * @example {{#context-menu x=123 y=432}} <li>my menu item</li> {{/context-menu}}
   */
  var _default = Ember.Component.extend({
    /**
     * Setup the dropdown menu classes.
     */
    classNames: ['context-menu'],
    classNameBindings: ['show:open'],

    /**
     * Bind the style attribute
     */
    attributeBindings: ['style'],

    /**
     * is the context menu shown or hide
     */
    show: false,

    /**
     * The inline style that is used to govern the placement of the box.
     */
    style: function () {
      return "top: ".concat(this.get('y'), "px;left: ").concat(this.get('x'), "px;").htmlSafe();
    }.property('x', 'y'),

    /**
     * Placement of the window in x cord from the top-left of the container.
     * this is used to create a fixed representation of its placement.
     */
    x: 0,

    /**
     * Placement of the window in y cord from the top-left of the container.
     * this is used to create a fixed representation of its placement.
     */
    y: 0
  });

  _exports.default = _default;
});