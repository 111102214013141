define("sky-router-3/models/form-template-file", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The name of the associated template file
     * maybe useful for versioning purposes.
     */
    name: (0, _model.attr)('string'),

    /**
     * The location of the template file
     */
    mD5: (0, _model.attr)('string'),

    /**
     * The filename of the form
     */
    archive: (0, _model.attr)('string'),

    /**
     * The location where the template can be loaded interactivly
     */
    serve: (0, _model.attr)('string'),

    /**
     * Date
     */
    createdDateUtc: (0, _model.attr)('date'),

    /**
     * Is the this the active file for the associated form template
     * @type {Boolean}
     */
    isActive: (0, _model.attr)('boolean'),

    /**
     * Template associated with file
     */
    template: (0, _model.belongsTo)('form-template', {
      inverse: null
    }),

    /**
     * Associated instances with this file
     */
    instances: (0, _model.hasMany)('form')
  });

  _exports.default = _default;
});