define("sky-router-3/pods/portal/communicate/folder/message/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Route for display a message
   */
  var _default = Ember.Route.extend({
    /**
     * The API service that does things that ember-data can't.
     * @return {sky-router-3/services/API}
     */
    api: Ember.inject.service(),

    /**
     * The users session account.
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Model that contains the mail message
     */
    model: function model(params) {
      return this.store.findRecord('mail-message', params.message_id);
    },
    actions: {
      /**
       * When the model rejects because of an error
       */
      error: function error() {
        this.notifications.error(this.intl.t('communicate.errorMessageNotFound'));
        this.replaceWith('portal.communicate');
      },

      /**
       * Marks the mail as read as soon as the route loads.
       * @return {[type]} [description]
       */
      didTransition: function didTransition() {
        this.send('markSeen', true);
      },

      /**
       * Mark the mail message as seen
       */
      markSeen: function markSeen(isSeen) {
        var _this = this;

        var model = this.modelFor(this.routeName);

        if (model.get('seen') !== isSeen) {
          model.set('seen', isSeen);
          return model.save().then(function () {
            _this.send('reloadFolder');
          });
        }
      },

      /**
       * Flag a mail message
       */
      markFlagged: function markFlagged(isFlagged) {
        var model = this.modelFor(this.routeName);

        if (model.get('flagged') !== isFlagged) {
          model.set('flagged', isFlagged);
          return model.save();
        }
      },

      /**
       * Moves a message to another folder and redirects the user to that folders
       * inbox.
       * @param  {sky-router-3/models/mail-message} The message to move
       * @param  {String} newFolderId The identifier for the folder to mvoe this message to.
       * @return {[type]}             [description]
       */
      moveMessage: function moveMessage(message, newFolderId) {
        var _this2 = this;

        if (confirm(this.intl.t('communicate.moveMessageConfirm'))) {
          // Unload the record because its identifier is dependent on what folder it is in.
          var id = message.get('id');
          this.store.unloadRecord(message);
          this.get('api').mailMessageMove(id, newFolderId).then(function () {
            // Reload the current folder so that the counts will be correct.
            _this2.send('reloadFolder'); // Redirect the user to the location of the message now and reload our
            // folder and the source folder so the numbers are up to date.


            _this2.transitionTo('portal.communicate.folder', newFolderId);
          }).catch(function () {
            _this2.notifications.error(_this2.intl.t('communicate.moveMessageFail'));
          });
        }
      },

      /**
       * Sends the user over to the compose page with the correct to and
       * subject for a reply type email.
       */
      reply: function reply() {
        var message = this.modelFor(this.routeName);
        this.transitionTo('portal.communicate.compose', {
          queryParams: {
            to: [message.get('from')],
            subject: "Re: ".concat(message.get('subject'))
          }
        });
      },

      /**
       * Sends the user over to the compose page with the correct to and
       * subject for a reply all email. Which is everyone the message was sent
       * to excluding the users email.
       */
      replyAll: function replyAll() {
        var message = this.modelFor(this.routeName);
        var user = this.get('sessionAccount.user');
        var to = message.get('to').filter(function (address) {
          return address.toLowerCase() !== user.get('emailSkyRouter').toLowerCase();
        });
        to.addObject(message.get('from'));
        this.transitionTo('portal.communicate.compose', {
          queryParams: {
            to: to,
            cc: message.get('cc'),
            subject: "Re: ".concat(message.get('subject'))
          }
        });
      },

      /**
       * Sends the user over to the compose page with the current subject and
       * body.
       */
      forward: function forward() {
        var message = this.modelFor(this.routeName);
        this.transitionTo('portal.communicate.compose', {
          queryParams: {
            subject: message.get('subject'),
            body: message.get('body')
          }
        });
      }
    }
  });

  _exports.default = _default;
});