define("sky-router-3/pods/components/portal/settings/security/change-password/component", ["exports", "ember-cp-validations", "sky-router-3/utils/password-generator"], function (_exports, _emberCpValidations, _passwordGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    password: (0, _emberCpValidations.validator)('valid-password'),
    passwordConfirmation: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password'
    }),
    currentPassword: (0, _emberCpValidations.validator)('presence', true)
  });
  /**
   * This component is responsible for handling changing the users password.
   * It is self contained like this to isolate validation rules and other logic.
   *
   * @example
   * {{portal/settings/security/change-password isSaving=false onChangePassword='changePassword'}}
   */

  var _default = Ember.Component.extend(VALIDATIONS, {
    passwordChanged: function passwordChanged() {},

    /**
     * Service injections.
     */
    sessionAccount: Ember.inject.service(),
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Boolean that indicates if the temp password notice is shown or not
     * @property
     * @public
     * @default {boolean} false
     */
    showTempPassword: false,

    /**
     * Indicates that the form should be blocked because it is being
     * submitted.
     * @public
     * @property
     * @default false
     */
    isSaving: false,

    /**
     * Contains the new users password
     * @private
     * @property
     */
    password: '',

    /**
     * The input field for the passwords type
     * @type {String}
     */
    passwordInputType: 'password',

    /**
     * Field used to confirm the users password
     * @private
     * @property
     */
    passwordConfirmation: '',

    /**
     * Contains the users current password as they enter it
     * @private
     * @property
     */
    currentPassword: '',
    actions: {
      createPassword: function createPassword() {
        var password = (0, _passwordGenerator.default)(4, 4, 4, 4);
        this.set('passwordInputType', 'text');
        this.set('password', password);
        this.set('passwordConfirmation', password);
      },

      /**
       * Fires the password change action and sends the following parameters
       * currentPassword, new password
       */
      submitChangePassword: function submitChangePassword() {
        var _this = this;

        this.set('isSaving', true);
        var id = this.get('sessionAccount.user.id');
        var current = this.get('currentPassword');
        var pword = this.get('password');

        if (current === pword) {
          this.set('isSaving', false);
          this.notifications.error(this.intl.t('components.changePassword.duplicate'));
          return;
        }

        this.get('api').userChangePassword(id, current, pword).then(function () {
          _this.notifications.clearAll();

          _this.notifications.success(_this.intl.t('components.changePassword.success'));

          _this.passwordChanged();
        }).catch(function () {
          _this.notifications.error(_this.intl.t('components.changePassword.fail'));
        }).finally(function () {
          _this.set('isSaving', false);

          _this.set('currentPassword', null);
        });
      }
    }
  });

  _exports.default = _default;
});