define("sky-router-3/pods/portal/manage/device-profiles/edit/controller", ["exports", "sky-router-3/models/asset-profile-set-type"], function (_exports, _assetProfileSetType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Indicates if this form is read only and should not allow edits
     * @type {Boolean}
     */
    isHistorical: function () {
      return this.get('model.set.revisionLatest.id') !== this.get('model.revision.id');
    }.property('model.set.revisionLatest.id', 'model.revision.id'),

    /**
     * Gets the path to the component that displays UI for all the settings
     * this revision has.
     * @return {String}
     */
    profileComponent: function () {
      switch (this.get('model.set.type.id')) {
        case _assetProfileSetType.PROFILE_TYPES.ach:
          return 'portal/manage/device-profiles/types/ach';

        case _assetProfileSetType.PROFILE_TYPES.d2k:
          return 'portal/manage/device-profiles/types/d2k';

        case _assetProfileSetType.PROFILE_TYPES.d2ka:
          return 'portal/manage/device-profiles/types/d2ka';

        case _assetProfileSetType.PROFILE_TYPES.he5x:
          return 'portal/manage/device-profiles/types/he5x';

        case _assetProfileSetType.PROFILE_TYPES.he7x:
          return 'portal/manage/device-profiles/types/he7x';

        case _assetProfileSetType.PROFILE_TYPES.extreme:
          return 'portal/manage/device-profiles/types/extreme';

        case _assetProfileSetType.PROFILE_TYPES.shout_ts:
          return 'portal/manage/device-profiles/types/nal/shout-ts';

        case _assetProfileSetType.PROFILE_TYPES.shout_sp:
          return 'portal/manage/device-profiles/types/nal/shout-sp';
      }
    }.property('model.set.type.id'),

    /**
     * A optional comment the user can enter about the revision
     * @type {string}
     */
    revisionComment: null,

    /**
     * Sorts the revision history provided by the model hook for displaying
     * @type {Array}
     */
    revisionHistorySortBy: ['createdDate:desc'],
    revisionHistorySorted: Ember.computed.sort('model.revisions', 'revisionHistorySortBy')
  });

  _exports.default = _default;
});