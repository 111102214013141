define("sky-router-3/pods/portal/track/user-event/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),
    titleToken: function () {
      return this.intl.t('userEvent.header');
    }.property(),
    model: function model(params) {
      return Ember.RSVP.hash({
        userEventTypes: this.store.findAll('overlay-user-event-type'),
        userEvent: params.user_event_id === 'new' ? this.store.createRecord('overlay-user-event', {
          latitudeMilliarcSeconds: params.latitude || 3600000,
          longitudeMilliarcSeconds: params.longitude || 3600000,
          created: new Date(),
          type: 1
        }) : this.store.findRecord('overlay-user-event', params.user_event_id)
      });
    },
    afterModel: function afterModel(model, transition) {
      model.userEvent.set('type', model.userEventTypes.get('firstObject'));
      transition.trigger(false, 'centerOnPosition', model.userEvent);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('latitude', null);
        controller.set('longitude', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.userEvent.get('isNew')) {
          model.userEvent.destroyRecord();
        }
      },
      selectUserEventType: function selectUserEventType(type) {
        var model = this.modelFor(this.routeName);
        model.userEvent.set('type', type);
      },
      save: function save(userevent) {
        var _this = this;

        this.send('blockApp', true);
        userevent.save().then(function () {
          _this.send('centerOnPosition', userevent);

          _this.transitionTo('portal.track');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});