define("sky-router-3/models/mail-message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    to: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    cc: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    bcc: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),
    body: (0, _model.attr)('string'),
    date: (0, _model.attr)('date'),
    from: (0, _model.attr)('string'),
    subject: (0, _model.attr)('string'),
    seen: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    flagged: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * The folder that this message belongsTo
     */
    folder: (0, _model.belongsTo)('mail-folder', {
      async: true
    })
  });

  _exports.default = _default;
});