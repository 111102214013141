define("sky-router-3/pods/portal/report/positionreporthistory/controller", ["exports", "moment", "sky-router-3/utils/filter-by-in-array", "sky-router-3/utils/computed-csv", "sky-router-3/utils/constants"], function (_exports, _moment, _filterByInArray, _computedCsv, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['devices', 'events', 'sDate', 'eDate', 'columns', 'altitudeOption', 'speedOption', 'minlat', 'maxlat', 'minlng', 'maxlng', 'timeZoneMinutes'],

    /**
     * The list of devices selected by the user
     * @type {String}
     */
    devices: [],
    devicesSelected: (0, _filterByInArray.default)('model.devices', 'id', 'devices'),
    devicesList: (0, _computedCsv.default)('devices'),

    /**
     * The list of events selected by the user
     * @type {String}
     */
    events: [],
    eventsSelected: (0, _filterByInArray.default)('model.events', 'id', 'events'),
    eventsList: (0, _computedCsv.default)('events'),
    columns: ['IMEI', 'Name', 'Time', 'Type', 'Altitude', 'Velocity', 'Heading', 'Latitude', 'Longitude', 'Information'],
    columnsSelected: (0, _filterByInArray.default)('model.columns', 'value', 'columns'),
    columnsList: (0, _computedCsv.default)('columns'),

    /**
     * The start date selected by the user
     * @type {Date}
     */
    sDate: null,
    sDateObject: Ember.computed('sDate', {
      get: function get() {
        var date = (0, _moment.default)(this.sDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().startOf('day').add(-1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('sDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The end date selected by the user
     * @type {Date}
     */
    eDate: null,
    eDateObject: Ember.computed('eDate', {
      get: function get() {
        var date = (0, _moment.default)(this.eDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().endOf('day').add(1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('eDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),

    /**
     * Filter bounding box limits
     * @type {[type]}
     */
    minlat: null,
    minlng: null,
    maxlat: null,
    maxlng: null,
    geoFilterModalVisible: false,
    geoFilterBounds: Ember.computed('minlat', 'minlng', 'maxlat', 'maxlng', function () {
      if (this.minlat && this.minlng && this.maxlat && this.maxlng) {
        return {
          south: parseFloat(this.minlat),
          north: parseFloat(this.maxlat),
          east: parseFloat(this.maxlng),
          west: parseFloat(this.minlng)
        };
      }

      return null;
    }),

    /**
     * The session service
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Used as a flag to disable export buttons when an export is in progress
     * @type {Boolean}
     */
    exportsInProgress: false,

    /**
     * Indicates if the report hints are shown
     * @type {Boolean}
     */
    showHints: false,

    /**
     * The selected altitude option for the report. Default to feet
     */
    altitudeOption: null,
    selectedAltitudeOption: Ember.computed('altitudeOption', 'model.altitudeOptions.[]', 'sessionAccount.user.altitude.id', {
      get: function get() {
        return this.model.altitudeOptions.findBy('id', this.altitudeOption || this.sessionAccount.user.belongsTo('altitude').id());
      },
      set: function set(key, value, preValue) {
        if (value) {
          this.set('altitudeOption', value.get('id'));
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The selected speed option for the report. Default to first
     */
    speedOption: null,
    selectedSpeedOption: Ember.computed('speedOption', 'model.speedOptions.[]', 'sessionAccount.user.speedAir.id', {
      get: function get() {
        return this.model.speedOptions.findBy('id', this.speedOption || this.sessionAccount.user.belongsTo('speedAir').id());
      },
      set: function set(key, value, preValue) {
        if (value) {
          this.set('speedOption', value.get('id'));
          return value;
        }

        return preValue;
      }
    }),
    playbackAssets: Ember.computed('devices.[]', 'model.devices.[]', function () {
      if (this.devices.length) {
        return this.devices.join();
      }

      return this.model.devices.mapBy('id').join();
    }),
    playbackStart: Ember.computed('sDateObject', function () {
      return this.sDateObject.toISOString();
    }),
    playbackEnd: Ember.computed('eDateObject', function () {
      return this.eDateObject.toISOString();
    }),

    /**
     * Makes the API call to retrive the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName) {
      var _this = this;

      // Disable export buttons
      this.set('exportsInProgress', true); // Get report parameters

      var devicesList = encodeURIComponent(this.devicesList);
      var eventsList = encodeURIComponent(this.eventsList);
      var visibleColumnsList = encodeURIComponent(this.columnsSelected.mapBy('value').join());
      var startDate = this.sDateObject.toISOString();
      var endDate = this.eDateObject.toISOString();
      var altitude = this.selectedAltitudeOption.id;
      var speed = this.selectedSpeedOption.id;
      var utcOffset = this.timeZone.offsetMinutes; // Call the API

      this.api.getPositionReportHistoryReport(fileName, devicesList, eventsList, visibleColumnsList, startDate, endDate, altitude, speed, utcOffset, exportType, this.minLat, this.minLng, this.maxLat, this.maxLng).catch(function () {
        _this.notifications.error(_this.intl.t('report.exportFailed', {
          exportType: exportType
        }));
      }).finally(function () {
        // Enable the export buttons
        _this.set('exportsInProgress', false);
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      registerGridApi: function registerGridApi(api) {
        var _this2 = this;

        this._gridApi = api;
        Ember.run.schedule('afterRender', this, function () {
          _this2._gridApi.generateReport(1);
        });
      },

      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        this._gridApi.generateReport(1);
      },

      /**
       * Toggles the hints on or off
       * @return {Void}
       */
      showHints: function showHints() {
        this.toggleProperty('showHints');
      },

      /**
       * Powers the dropdown list for selection
       * @param  {boolean} checked Is the item selected or not
       * @param  {Array<>} items   Items that changed
       */
      selectOptions: function selectOptions(list, selected, items) {
        var ids = items.mapBy('id');

        if (selected) {
          list.addObjects(ids);
        } else {
          list.removeObjects(ids);
        }
      },
      columnChecked: function columnChecked(columnsSelected, selected, items) {
        var ids = items.mapBy('value');

        if (selected) {
          columnsSelected.addObjects(ids);
        } else {
          columnsSelected.removeObjects(ids);
        }

        if (selected && ids.includes('MGRS')) {
          this.send('generateReport');
        }
      },
      geoFilterToggle: function geoFilterToggle() {
        this.toggleProperty('geoFilterModalVisible');
      },
      geoFilterClear: function geoFilterClear() {
        this.set('minlat', null);
        this.set('maxlat', null);
        this.set('minlng', null);
        this.set('maxlng', null);
        this.set('geoFilterModalVisible', false);
      },
      geoFilterSet: function geoFilterSet(bounds) {
        this.set('minlat', bounds.south);
        this.set('minlng', bounds.west);
        this.set('maxlat', bounds.north);
        this.set('maxlng', bounds.east);
        this.set('geoFilterModalVisible', false);
      },
      clearFilters: function clearFilters() {
        this.set('minlat', null);
        this.set('minlng', null);
        this.set('maxlat', null);
        this.set('maxlng', null);
        this.set('devices', []);
        this.set('events', []);
      },
      setStartDate: function setStartDate(type, ammount) {
        this.set('eDateObject', (0, _moment.default)().endOf('day').toDate());
        this.set('sDateObject', (0, _moment.default)().add(ammount, type).toDate());
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport('Excel', this.intl.t('nav.positionReportHistory') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport('CSV', this.intl.t('nav.positionReportHistory') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport('PDF', this.intl.t('nav.positionReportHistory') + '-' + this.getUTCDateString() + '.pdf');
      }
    }
  });

  _exports.default = _default;
});