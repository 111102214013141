define("sky-router-3/pods/components/portal/settings/security/two-factor-auth/reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wuOs/ie8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"visible\",\"onClose\",\"title\",\"titleIco\"],[true,[30,[36,3],[[32,0],[32,0,[\"finish\"]]],null],[30,[36,0],[\"two-factor.settings.reset.title\"],null],\"fa fa-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"modal-body text-center\"],[12],[2,\"\\n        \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"two-factor.settings.reset.heading\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-danger\"],[12],[1,[30,[36,0],[\"two-factor.settings.reset.help\"],null]],[13],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"class\",\"value\",\"type\"],[\"form-control\",[35,1],\"password\"]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n        \"],[6,[37,4],null,[[\"class\",\"action\"],[\"btn-danger\",[30,[36,3],[[32,0],\"reset\"],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-key\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonConfirm\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[6,[37,4],null,[[\"class\",\"action\"],[\"btn-default\",[30,[36,3],[[32,0],[32,0,[\"finish\"]]],null]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"ico-undo\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"misc.buttonCancel\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"password\",\"input\",\"action\",\"bs-button\",\"bs-modal\"]}",
    "moduleName": "sky-router-3/pods/components/portal/settings/security/two-factor-auth/reset/template.hbs"
  });

  _exports.default = _default;
});