define("sky-router-3/pods/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S95bODw2",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[10,\"div\"],[14,1,\"loading-page\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"satellite-wrapper\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/skyrouter3/assets/images/satellite.svg\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"i\"],[14,0,\"icon ico-globe\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-center\"],[12],[1,[30,[36,3],[\"loading.headerLoading\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h5\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-center\"],[12],[1,[30,[36,3],[\"loading.loadingDescription\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notification-container\",\"-outlet\",\"component\",\"t\"]}",
    "moduleName": "sky-router-3/pods/loading/template.hbs"
  });

  _exports.default = _default;
});