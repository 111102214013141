define("sky-router-3/models/user", ["exports", "@ember-data/model", "sky-router-3/mixins/rollback-relationships"], function (_exports, _model, _rollbackRelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var user = _model.default.extend(_rollbackRelationships.default, {
    /**
     * The groups that the user is assigned to
     */
    groups: (0, _model.hasMany)('group'),

    /**
     * Identifies if the user must change their password
     */
    passwordExpired: (0, _model.attr)('boolean'),

    /**
     * This holds an array of strings that signifies the users permissions
     * The string being present means the user has that permission
     */
    permissions: (0, _model.attr)('array'),

    /**
     * Username of the user
     */
    username: (0, _model.attr)('string'),

    /**
     * The users unit lat/long option
     */
    latLongOption: (0, _model.belongsTo)('unitLatLong', {
      async: true
    }),

    /**
     * Display MGRS anywhere lat/lon is displayed
     */
    displayMGRSCoords: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    displayUTMCoords: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    displayGARSCoords: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    displayLSDCoords: Ember.computed({
      get: function get(key) {
        var _this = this;

        if (this._displaylsd === undefined) {
          this.store.findAll('overlay').then(function (overlays) {
            var value = overlays.any(function (overlay) {
              try {
                return JSON.parse(overlay.get('dataString')).name === 'LSD';
              } catch (_unused) {
                return false;
              }
            });

            _this.set(key, value);
          });
        }

        return this._displaylsd;
      },
      set: function set(key, value) {
        this.set('_displaylsd', value);
        return value;
      }
    }),

    /**
     * The users unit distance option for land type assets
     */
    distanceLand: (0, _model.belongsTo)('unitDistance', {
      async: true
    }),

    /**
     * Should the geofences be shown on the track map
     */
    showGeofencesOnMap: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * The users unit distance option for sea type assets
     */
    distanceSea: (0, _model.belongsTo)('unitDistance', {
      async: true
    }),

    /**
     * The users unit distance option for air type assets
     */
    distanceAir: (0, _model.belongsTo)('unitDistance', {
      async: true
    }),

    /**
     * The users unit distance option for other type assets
     */
    distanceOther: (0, _model.belongsTo)('unitDistance', {
      async: true
    }),

    /**
     * The users unit distance for measuring distance between two points
     */
    distanceMeasure: (0, _model.belongsTo)('unitDistance', {
      async: true
    }),

    /**
     * The users unit alitutde options for all assets
     */
    altitude: (0, _model.belongsTo)('unitAltitude', {
      async: true
    }),

    /**
     * The users unit speed option for land type assets
     */
    speedLand: (0, _model.belongsTo)('unitSpeed', {
      async: true
    }),

    /**
     * The users unit speed option for sea type assets
     */
    speedSea: (0, _model.belongsTo)('unitSpeed', {
      async: true
    }),

    /**
     * The users unit speed option for air type assets
     */
    speedAir: (0, _model.belongsTo)('unitSpeed', {
      async: true
    }),

    /**
     * The users unit speed option for other type assets
     */
    speedOther: (0, _model.belongsTo)('unitSpeed', {
      async: true
    }),

    /**
     * The users unit for volume (tank/bucket)
     */
    volume: (0, _model.belongsTo)('unitVolume', {
      async: true
    }),

    /**
     * Indicates if asset tags are to be shown or not.
     * Valid values are:
     *   0: Not shown
     *   1: Shown
     *   2: Show only for active assets
     */
    showAssetTags: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Indicates if the asset tag avoidance algorithm should be used or not
     */
    assetTagAvoidance: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates that when shown asset breadcrumbs should show
     * any events that have occurred in the last N minutes.
     *
     * A negative number means that the specified number of
     * breadcrumbs should be shown instead of by date.
     *
     * Unit for this value is in minutes
     * @default 15
     */
    assetBreadcrumbTimespan: (0, _model.attr)('number', {
      defaultValue: 15
    }),
    assetDisplayNameFormat: (0, _model.attr)('string'),

    /**
     * Indicates that any asset who's positionLatest is of type 'inactive'
     * should color itself gray.
     */
    grayoutInactiveAssets: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Users personal email address
     */
    email: (0, _model.attr)('string'),

    /**
     * The users skyrouter based email address. This is a read only
     * property. As it is a combination of the username and the server
     * they are on.
     */
    emailSkyRouter: (0, _model.attr)('string'),
    //#region CONTACT INFO

    /**
     * Contact information about the user
     */
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var _Ember$get, _Ember$get2;

      return "".concat((_Ember$get = Ember.get(this, 'firstName')) !== null && _Ember$get !== void 0 ? _Ember$get : '', " ").concat((_Ember$get2 = Ember.get(this, 'lastName')) !== null && _Ember$get2 !== void 0 ? _Ember$get2 : '');
    }),
    completeName: Ember.computed('firstName', 'lastName', 'username', function () {
      var _Ember$get3, _Ember$get4, _Ember$get5;

      return "".concat((_Ember$get3 = Ember.get(this, 'username')) !== null && _Ember$get3 !== void 0 ? _Ember$get3 : '', " (").concat((_Ember$get4 = Ember.get(this, 'firstName')) !== null && _Ember$get4 !== void 0 ? _Ember$get4 : '', " ").concat((_Ember$get5 = Ember.get(this, 'lastName')) !== null && _Ember$get5 !== void 0 ? _Ember$get5 : '', ")");
    }),
    companyName: (0, _model.attr)('string'),
    department: (0, _model.attr)('string'),
    division: (0, _model.attr)('string'),
    title: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    phoneExtension: (0, _model.attr)('string'),
    fax: (0, _model.attr)('string'),
    mobilePhone: (0, _model.attr)('string'),
    employeeNumber: (0, _model.attr)('string'),
    //#endregion

    /**
     * The default language culture for the user in the ISO 639-2. EX en-us
     */
    locale: (0, _model.attr)('string', {
      defaultValue: 'en-US'
    }),

    /**
     * The users selected timezone for use in localization of javascript date()
     * objects.
     *
     * Values used to be hours offset from -11 to +14 with 0 being UTC/GMT+0.
     * Anything outside of hour range was considered to be minutes offset (based on momentjs)
     *
     * Values are now essentially ids for the `timeZone` contant.
     * Since they line up the same the logic can stay
     */
    timeZone: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * This takes the timeZone which is the hours offset (or minutes offset) based on the range.
     * This mirrors momentjs's timezone which can be hours or minutes depending on the range.
     */
    timeZoneMinutes: Ember.computed('timeZone', function () {
      if (this.timeZone > -16 && this.timeZone < 16) {
        return this.timeZone * 60;
      }

      return this.timeZone;
    }),

    /**
     * The date formater for use throughout the application
     * @type {String}
     */
    dateFormat: (0, _model.attr)('string', {
      defaultValue: 'MM-dd-yyyy'
    }),

    /**
     * Converts the dateFormat which is used by the server into something the front end can use.
     * @return {String} Converted format from .NET to moment.js format
     */
    dateFormatSystem: Ember.computed('dateFormat', function () {
      return this.dateFormat.replace(/d+/i, function (match
      /*, offset, string */
      ) {
        if (match.length <= 2) return match.toUpperCase(); // day of month = 'D', 'DD'

        return match.toLowerCase(); // Abbreviated Day 'ddd' 'dddd'
      }).replace(/y+/i, function (match
      /*, offset, string */
      ) {
        if (match.length <= 2) return 'YY';
        return match.toUpperCase();
      });
    }),
    timeFormat: (0, _model.attr)('string', {
      defaultValue: ' HH:mm:ss'
    }),

    /**
     * Converts the timeFormat which is used by the server into something the front end can use.
     * @return {String} Converted format from .NET to moment.js format
     */
    timeFormatSystem: Ember.computed('timeFormat', function () {
      return this.timeFormat.replace(/tt/i, 'A') // AM/PM
      .replace(/z+/i, 'Z'); // z, zz -> ZZ (+07:00)
    }),

    /**
     * Indicates that when an event comes in for assets in quick position mode the
     * active map should zoom to that assets location.
     */
    emergencyEventZoom: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * The users login session expiration in minutes. This corresponds to the
     * users session logout timer.
     */
    ticketExpire: (0, _model.attr)('number', {
      defaultValue: 30
    }),

    /**
     * How long recent events are considered 'new' to the user.
     *
     * This is used for badges and other notifications that appear when new
     * events come in and indicates how long to leave them around.
     *
     * Contains a number that indicates how long in seconds. If the number is
     * negative it means that it should stay around until the user manually
     * closes or cancels it.
     */
    eventNotificationTimeout: (0, _model.attr)('number', {
      defaultValue: 10
    }),

    /**
     * Indicates that the user should not be able to see assets current or
     * historical speed
     */
    hideAssetSpeed: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates that the user should not be able to see assets current or
     * historical altitude
     */
    hideAssetAltitude: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates that the user is an administrator account for the the
     * organization that this user belongs to. This property does not convey any
     * permissions or ability for the user. It just identifier them as an admin.
     */
    isAdmin: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates that the user is an driver for the
     * organization that this user belongs to.
     */
    isDriver: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates that the user is the master administrator account for the the
     * organization that this user belongs to. This property does not convey any
     * permissions or ability for the user. It just identifier them as the master
     * record.
     */
    isRootAdmin: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates whether the user is a SkyRouter Lite administrator or subuser. This property being set doesn't
     * convey any permissions or ability for the user.
     */
    isLiteUser: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates whether the user is a SkyRouter Trial administrator or subuser. This property being set doesn't
     * convey any permissions or ability for the user.
     */
    isTrialUser: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates whether the user is a SkyRouter Bivy administrator or subuser. This property being set doesn't
     * convey any permissions or ability for the user.
     */
    isBivyUser: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Indicates if the user account is active and can login to the system.
     */
    isActive: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * Indicates if the current user is currently locked out
     */
    isLockedOut: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * This field holds the last date of known activity for the user
     * this field is held on the server so a query is required in order to
     * get the updated value. Changes to this field are also not "pushed" to
     * connected clients.
     */
    lastActivityDate: (0, _model.attr)('date'),

    /**
     * Reference to the users that are the administrators of this user.
     * If the user is an admin itself then this may loop back.
     * @type {Array<sky-router-3/models/user>}
     */
    administrators: (0, _model.hasMany)('user', {
      inverse: null
    }),

    /**
     * Indicates if the user has elected to receive system notifications via email.
     * @type {boolean}
     */
    receiveSystemNotifications: (0, _model.attr)('boolean'),

    /**
     * Is the IP restriction for this account active.
     * This is the whitelist which will automatically grant them access.
     * This takes precedence over the blacklist.
     * @type {Boolean}
     */
    ipWhitelistEnabled: (0, _model.attr)('boolean'),

    /**
     * CSV of CIDR notation based IP addresses.
     * @type {String}
     */
    ipWhitelistCSV: (0, _model.attr)('string'),

    /**
     * IS the IP restriction for this account active.
     * This is the blacklist which is used to block specific ip addresses and ranges.
     * @type {Boolean}
     */
    ipBlacklistEnabled: (0, _model.attr)('boolean'),

    /**
     * CSV of CIDR notiation based IP addresses.
     * @type {String}
     */
    ipBlackListCSV: (0, _model.attr)('string'),

    /**
     * Record linking to the user two factor authentication settings (Authy)
     * @type {sky-router-3/models/authy-user}
     */
    authyUser: (0, _model.belongsTo)('authyUser', {
      async: true
    }),

    /**
     * Associated API keys for this user.
     * @type {sky-router-3/models/api-key}
     */
    apiKeys: (0, _model.hasMany)('api-key'),

    /**
     * Is the user allowed to receive push notifications to linked applications.
     * @type {Boolean}
     */
    receivePushNotifications: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * A pin code that the user may be required to enter in order to use some features
     * @type {String}
     */
    linkedAppPin: (0, _model.attr)('string'),

    /**
     * The timestamp for the creation of this user
     * @type {date}
     **/
    userCreatedDate: (0, _model.attr)('date')
  });

  var _default = user;
  _exports.default = _default;
});