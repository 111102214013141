define("sky-router-3/utils/moment-offset-converter", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The moment offset converter class is used for manipulating the time zone offset of moment objects.
   * The utcOffset method of the moment class does not have the desired result of sending
   */
  var momentOffsetConverter = {
    /**
     * Takes a date and an offset and forces the UTC date to match that offset.
     * Locally the date will still be incorrect.
     * @param {Date} date   Date to convert to ISO with an offset
     * @param {Number}   offset how many hours or minutes to offset if less than
     *                   16 and greater than -16 then its interpreted as hours.
     * @see https://stackoverflow.com/questions/28593304/same-date-in-different-time-zone/28615654#28615654
     */
    toOffsetISOString: function toOffsetISOString(date, offset) {
      var m = (0, _moment.default)(date);
      m.utcOffset(offset);
      m.add(date.utcOffset() - m.utcOffset(), 'minutes');
      return m.toISOString();
    }
  };
  var _default = momentOffsetConverter;
  _exports.default = _default;
});