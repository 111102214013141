define("sky-router-3/pods/components/checkbox-dropdownlist/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Defines a checkbox drop down list which uses the bootstrap button menu drop down as a base
   * @property items - Array of objects that contain a text (string), value (string) and checked (boolean) properties to populate the drop down with
   * @property buttonText - The label to use for the toggle button of the dropdown
   * @example {{checkbox-dropdownlist id="DisplayedColumnsDropDown" tagName='div' items=model.displayedColumns buttontext='Displayed Columns'}}
   */
  var _default = Ember.Component.extend({
    classNames: ['dropdown'],
    checked: function checked() {},

    /**
     * Array of objects that contain a text (string), value (string) and checked (boolean) properties to populate the drop down with
     * @type {Array
     */
    items: null,

    /**
     * The label to use for the toggle button of the dropdown
     * @type {string}
     */
    buttonText: null,
    actions: {
      inputChecked: function inputChecked(item) {
        this.checked(item);
      }
    }
  });

  _exports.default = _default;
});