define("sky-router-3/models/route-waypoint", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment"], function (_exports, _model, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    latitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Clones the record
     */
    clone: function clone() {
      return this.store.createRecord('route-waypoint', {
        name: this.name,
        description: this.description,
        latitudeMilliarcSeconds: this.latitudeMilliarcSeconds,
        longitudeMilliarcSeconds: this.longitudeMilliarcSeconds
      });
    }
  });

  _exports.default = _default;
});