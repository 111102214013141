define("sky-router-3/pods/portal/manage/devices/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset', {
          reload: false,
          backgroundReload: true
        }),
        voicePlans: this.store.findAll('voice-plan', {
          reload: false,
          backgroundReload: true
        }),
        servicePlans: this.store.findAll('service-plan', {
          reload: false,
          backgroundReload: true
        }),
        quickEditAsset: undefined,
        icons: this.store.findAll('asset-icon'),
        drivers: this.store.findAll('driver')
      });
    }
  });

  _exports.default = _default;
});