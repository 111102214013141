define("sky-router-3/pods/portal/communicate/folder/index/controller", ["exports", "sky-router-3/models/mail-folder", "ember-cp-validations"], function (_exports, _mailFolder, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    editingName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^[^.&<>"\\;:'/()+]+$/,
      messageKey: 'errors.invalidCharacters'
    })]
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * Binding the query parameters to the search field
     * @type {Array}
     */
    queryParams: ['search'],

    /**
     * Creates a filed that is populated with the current folder name.
     * This property then can attached to an imput without messing with the model.
     * @return {String}
     */
    editingName: null,

    /**
     * Indicates if the name is currently being edited.
     * @type {Boolean}
     */
    isEditingName: false,

    /**
     * Holds the search value
     * @type {[type]}
     */
    search: null,

    /**
     * Indicates if the user is currently searching or not.
     */
    isSearching: Ember.computed.bool('search'),

    /**
     * This is the field that is used by the UI to attach a input box to it.
     */
    searchFieldValue: Ember.computed('search', function () {
      return this.get('search');
    }),

    /**
     * Indicates if this folder is an outgoing folder. I.E sent or drafts
     * @return {Boolean}
     */
    isOutgoingFolder: Ember.computed('model.folder.id', function () {
      var id = this.get('model.folder.id');
      return id === _mailFolder.MAIL_FOLDERS.sent || id === _mailFolder.MAIL_FOLDERS.drafts;
    }),

    /**
     * The count of the number of mail items in this folder with the search
     * criteria provided.
     * @return {Number}
     */
    mailCount: Ember.computed('model.folder.count', 'model.properties.searchCount', function () {
      var count = this.get('model.folder.count');
      var searchCount = this.get('model.properties.searchCount');

      if (searchCount === null) {
        return count;
      } else {
        return searchCount;
      }
    }),

    /**
     * Returns a value that indicates if there are more mail message summaries
     * to be downloaded from the server still.
     * @return {Boolean}
     */
    hasMoreMail: Ember.computed('mailCount', 'model.properties.pageIndex', 'model.properties.pageSize', function () {
      var index = this.get('model.properties.pageIndex');
      var size = this.get('model.properties.pageSize');
      var count = this.get('mailCount');
      return index * size <= count;
    }),

    /**
     * Sorting of the message summaries.
     * @type {Array}
     */
    summariesSortedBy: ['date:desc'],
    summariesSorted: Ember.computed.sort('model.summaries', 'summariesSortedBy'),

    /**
     * Are there any unread messages in the folder?
     * @type {Boolean}
     */
    anyUnreadMessages: Ember.computed('model.summaries', function () {
      var msgs = this.get('model.summaries');
      return msgs.isAny('unseen');
    }),

    /**
     * Indicates that the user should see a message about the folder contents
     * changing due to a new message appearing.
     * @type {Boolean}
     */
    showFolderChangeWarning: false,

    /**
     * Holds an array of mail that is highlighted in the UI.
     * This allows for actions to be run on the selected mail
     * @type {Array}
     */
    mailSelected: [],
    actions: {
      startRename: function startRename() {
        this.set('isEditingName', true);
        this.set('editingName', this.get('model.folder.name'));
      },
      cancelRename: function cancelRename() {
        this.set('isEditingName', false);
        this.set('editingName', this.get('model.folder.name'));
      },
      saveRename: function saveRename() {
        this.set('isEditingName', false);
        this.send('renameFolder', this.get('model.folder'), this.get('editingName'));
      },
      clearSearch: function clearSearch() {
        this.set('searchFieldValue', null);
        this.transitionToRoute({
          queryParams: {
            search: null
          }
        });
      },
      selectMail: function selectMail(mails, checked) {
        if (checked) {
          this.mailSelected.addObjects(mails);
        } else {
          this.mailSelected.removeObjects(mails);
        }
      }
    }
  });

  _exports.default = _default;
});