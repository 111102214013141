define("sky-router-3/pods/components/portal/settings/security/two-factor-auth/sessions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * The passed in user
     * @type {sky-router-3/models/user}
     */
    user: null,

    /**
     * Identifier to start searching from
     */
    startId: 2147483647,
    count: 10,
    totalCount: 300,
    hasMoreRecords: function () {
      return this.get('totalCount') > this.get('sessions.length');
    }.property('totalCount', 'sessions.length'),

    /**
     * The current sessions to show the user
     * @return {sky-router-3/models/user-two-factor-session[]}
     */
    sessions: Ember.computed('user.id', function () {
      return this.get('store').peekAll('user-two-factor-session');
    }),
    sessionsSorted: Ember.computed.sort('sessions', 'sessionsSortedBy'),
    sessionsSortedBy: ['id:desc'],
    loadRecords: function loadRecords() {
      var _this = this;

      this.get('store').query('user-two-factor-session', {
        user: this.get('user.id'),
        startId: this.get('startId'),
        count: this.get('count'),
        descending: true
      }).then(function (response) {
        _this.set('totalCount', response.meta.TotalCount);
      });
    },
    didInsertElement: function didInsertElement() {
      this.loadRecords();
    },
    willDestroyElement: function willDestroyElement() {
      this.get('store').unloadAll('user-two-factor-session');
    },
    actions: {
      clear: function clear(session) {
        var _this2 = this;

        session.destroyRecord().catch(function () {
          return _this2.notifications.error(_this2.intl.t('misc.failedOperation'));
        });
      },
      loadMore: function loadMore() {
        this.set('startId', this.get('sessionsSorted.lastObject.id'));
        this.loadRecords();
      }
    }
  });

  _exports.default = _default;
});