define("sky-router-3/instance-initializers/is-destroying-fix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * This fixes issues with ember data and attempting to update / access deleted records
   * See: https://github.com/emberjs/ember.js/issues/16541
   * @return {[type]} [description]
   */
  function initialize()
  /* appInstance */
  {
    Ember.ObjectProxy.reopen({
      unknownProperty: function unknownProperty(key) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        var content = this.content;

        if (!content || content.isDestroying || content.isDestroyed) {
          return;
        }

        return this._super(key);
      },
      willWatchProperty: function willWatchProperty()
      /*key*/
      {
        if (this.isDestroying || this.isDestroyed || this.content != null && (this.content.isDestroying || this.content.isDestroyed)) {
          return;
        }

        return this._super(arguments);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});