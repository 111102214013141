define("sky-router-3/pods/portal/track/controller", ["exports", "sky-router-3/models/asset-position-type", "sky-router-3/utils/string-search", "sky-router-3/utils/lat-lng-converter"], function (_exports, _assetPositionType, _stringSearch, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Controller.extend({
    queryParams: ['highlight', 'highlight_type'],
    geofenceService: Ember.inject.service(),
    highlight: null,
    highlight_type: null,
    highlight_geofence: Ember.computed('highlight', 'highlight_type', {
      get: function get() {
        if (this.highlight && this.highlight_type == 'geofence') {
          return this._highlight_geofence = this.highlight;
        }

        return this._highlight_geofence;
      },
      set: function set(key, value) {
        this._highlight_geofence = value;
        return value;
      }
    }),

    /**
     * The API service
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The translation service.
     */
    intl: Ember.inject.service(),

    /**
     * Indicates that a window on the right side is open and so the
     * splitter needs to be shown to the user.
     */
    anyFarRightContent: Ember.computed.or('model.state.assetListOpen', 'model.state.eventListOpen', 'model.state.locationListOpen', 'model.state.geofenceListOpen'),

    /**
     * Indicates that maps can be removed because the number of maps
     * is above the minimum count
     */
    aboveMinMapCount: Ember.computed.gt('model.state.mapConfigsArray.length', 1),

    /**
     * Indicates that no more maps can be added to the maps grid.
     */
    belowMaxMapCount: Ember.computed.lt('model.state.mapConfigsArray.length', 16),
    //#region Locations list functions and properties

    /**
     * This is the master array for locations within the system.
     * Maps should use this locations array when displaying data.
     *
     * Filters the locations array on several criteria.
     * 1. The type filter - Type filter looks at the location type id
     * 2. The search filter - Search filter looks at the items 'name'
     * property only at the moment. Also it is case insensitive.
     */
    locationsFiltered: function () {
      var _this = this;

      return this.locations.filter(function (location) {
        if (_typeof(location) !== 'object' || location.get('isLoading') || location.get('isDeleted') || !location.get('enabled')) {
          return false;
        }

        if (location.get('isNew')) {
          return true;
        }

        var shared = _this.get('model.state.locationList.filterShared');

        var subuser = _this.get('model.state.locationList.filterSubuser');

        var all = _this.get('model.state.locationList.filterAll');

        if (!all || !shared && location.accountShare || !subuser && location.subuserOwned) {
          return false;
        }

        var types = _this.get('model.state.locationList.filterOnType');

        var search = _this.get('model.state.locationList.filterSearch');

        var typeFilter = !types || types.length === 0 || types.includes(location.get('type.id'));
        var searchFilter = !search || location.get('name').toLowerCase().indexOf(search.toLowerCase()) !== -1;
        return typeFilter && searchFilter;
      });
    }.property('locations.[]', 'model.state.locationList.filterOnType', 'model.state.locationList.filterSearch', 'model.state.locationList.filterShared', 'model.state.locationList.filterSubuser', 'model.state.locationList.filterAll', 'locations.@each.type', 'locations.@each.name', 'locations.@each.isLoading', 'locations.@each.isNew', 'locations.@each.enabled'),

    /**
     * Filters locations based on the settings that would make it not appear on the map itself
     */
    locationsFilteredForMap: Ember.computed('locationsFiltered.[]', 'locationsFiltered.@each.enabled', 'model.locationEditId', function () {
      var _this2 = this;

      return this.locationsFiltered.filter(function (location) {
        if (!location.get('enabled') && _this2.get('model.locationEditId') != location.get('id')) {
          return false;
        }

        return true;
      });
    }),

    /**
     * When a location is marked as editing this is triggered.
     * It zooms the map to the location and sets its info-box open.
     */
    onLocationEditChange: function () {
      var editId = this.get('model.locationEditId');
      var active = this.get('model.state.mapConfigActiveInstance');

      if (editId) {
        this.store.findRecord('location', editId).then(function (location) {
          active.setCenterOnModel(location);
          active.get('locationInfoBoxOpenArray').addObject(editId);
        });
      }
    }.observes('model.locationEditId'),
    //#endregion Locations
    //#region Asset list functions and properties

    /**
     * This is the master array for assets within the system.
     * Maps should use this assets array when displaying data.
     *
     * This is filtered based on several filters that are toggled from
     * the asset-list component.
     * @type {sky-router-3/models/asset}
     */
    assetsFiltered: function () {
      var _this3 = this;

      return this.model.assets.filter(function (asset) {
        // Don't show active assets who have a latest position that is anything but an 'inactive' event
        if (!_this3.get('model.state.assetList.filterShowActive') && !!asset.get('positionLatest') && asset.get('positionLatest').belongsTo('type').id() !== _assetPositionType.POSITION_TYPES.Inactive) {
          return false;
        } // Don't show inactive assets who have a latest position that is an inactive


        if (!_this3.get('model.state.assetList.filterShowInactive') && (!asset.get('positionLatest') || asset.get('positionLatest').belongsTo('type').id() === _assetPositionType.POSITION_TYPES.Inactive)) {
          return false;
        } // If Groups are present in this list then only show those


        if (!Ember.isEmpty(_this3.get('model.state.assetList.filterShowGroups'))) {
          var ids = asset.hasMany('groups').ids();

          if (!_this3.get('model.state.assetList.filterShowGroups').some(function (x) {
            return ids.includes(x);
          })) {
            return false;
          }
        } // If Assets are present in this list then only show those


        if (!Ember.isEmpty(_this3.get('model.state.assetList.filterShowAssets'))) {
          if (!_this3.get('model.state.assetList.filterShowAssets').includes(asset.get('id'))) {
            return false;
          }
        } // Perform the search if there is a search value


        if (_this3.get('model.state.assetList.searchValue')) {
          var search = _this3.get('model.state.assetList.searchValue');

          if ((0, _stringSearch.default)(asset.get('name'), search) || (0, _stringSearch.default)(asset.get('registration'), search) || (0, _stringSearch.default)(asset.get('product.name'), search) || (0, _stringSearch.default)(asset.get('imei'), search)) {
            return true;
          }

          return false;
        }

        return true;
      });
    }.property('model.assets.[]', 'model.assets.@each.positionLatest', 'model.state.assetList.filterShowActive', 'model.state.assetList.filterShowInactive', 'model.state.assetList.filterShowGroups.[]', 'model.state.assetList.filterShowAssets.[]', 'model.state.assetList.searchValue'),

    /**
     * Filters groups by the ones that are active only.
     */
    groupsFiltered: Ember.computed.filterBy('model.groups', 'isActive'),
    //#endregion
    //#region GEOFENCES

    /**
     * This is filtered based on several filters that are toggled from
     * the geofence-list component.
     *
     * This is not the master list
     */
    geofencesFiltered: Ember.computed('geofences.[]', 'model.state.geofenceList.filterShowAdminOnly', 'model.state.geofenceList.filterShowEnabled', 'model.state.geofenceList.filterShowDisabled', 'model.state.geofenceList.filterShowGeofenceTypes.[]', 'model.state.geofenceList.searchValue', 'model.state.geofenceList.filterShowAssets.[]', function () {
      var _this4 = this;

      return this.geofences.filter(function (geofence) {
        // If its new it doesnt matter what is being filtered.
        if (geofence.get('isNew')) {
          return true;
        } // Don't show enabled geofences


        if (!_this4.get('model.state.geofenceList.filterShowEnabled') && geofence.get('isEnabled')) {
          return false;
        } // Don't show disabled geofences


        if (!_this4.get('model.state.geofenceList.filterShowDisabled') && !geofence.get('isEnabled')) {
          return false;
        } // Don't show non-admin geofences if we are only supposed to show admin only ones


        if (_this4.get('model.state.geofenceList.filterShowAdminOnly') && !geofence.get('isAdminOnly')) {
          return false;
        } // Filter on geofence types


        var types = _this4.get('model.state.geofenceList.filterShowGeofenceTypes');

        if (types.length > 0) {
          if (!types.includes(geofence.get('type'))) {
            return false;
          }
        } //Filter based on search


        var searchValue = _this4.get('model.state.geofenceList.searchValue');

        if (searchValue) {
          return (0, _stringSearch.default)(geofence.get('name'), searchValue);
        } // Filter based on assets
        // TODO: this needs to be promise aware,
        // https://github.com/emberjs/data/issues/2754


        var fAssets = _this4.get('model.state.geofenceList.filterShowAssets');

        if (fAssets.length) {
          var gAssets = geofence.get('assets');

          for (var i = 0; i < fAssets.length; i++) {
            if (!gAssets.findBy('id', fAssets.objectAt(i))) {
              return false;
            }
          }
        } // Passed all the tests return


        return true;
      });
    }),

    /**
     * Returns a reference to the geofences filtered array
     * if map is supposed to be displaying geofences.
     *
     * This is the list that the map should be using.
     */
    geofencesForMap: Ember.computed('geofencesFiltered.[]', 'sessionAccount.user.showGeofencesOnMap', 'highlight_geofence', 'model.geofenceEditId', function () {
      var array = [];

      if (this.get('sessionAccount.user.showGeofencesOnMap')) {
        array = this.get('geofencesFiltered');
      } else {
        var geofences = this.get('geofences');

        if (this.get('highlight_geofence')) {
          array.pushObject(geofences.findBy('id', this.get('highlight_geofence')));
        }

        if (this.get('model.geofenceEditId') > 0) {
          array.pushObject(geofences.findBy('id', this.get('model.geofenceEditId')));
        }

        if (this.get('model.geofenceEditId') === null) {
          array.pushObject(geofences.findBy('isNew', true));
        }
      }

      return array;
    }),
    //#endregion
    actions: {
      //#region TOOL-BAR ACTIONS
      //-----------------------------------------------------

      /**
       * Hides all the windows and closes the right bar out
       */
      onHideAllFarRightWindows: function onHideAllFarRightWindows() {
        this.set('model.state.assetListOpen', false);
        this.set('model.state.eventListOpen', false);
        this.set('model.state.locationListOpen', false);
        this.set('model.state.geofenceListOpen', false);
        this.model.state.tripsListOpen = false;
      },
      exclusiveToggleRightWindow: function exclusiveToggleRightWindow(rightWindow) {
        switch (rightWindow) {
          case 'asset':
            this.toggleProperty('model.state.assetListOpen');
            this.set('model.state.eventListOpen', false);
            this.set('model.state.locationListOpen', false);
            this.set('model.state.geofenceListOpen', false);
            this.set('model.state.tripsListOpen', false);
            break;

          case 'event':
            this.set('model.state.assetListOpen', false);
            this.toggleProperty('model.state.eventListOpen');
            this.set('model.state.locationListOpen', false);
            this.set('model.state.geofenceListOpen', false);
            this.set('model.state.tripsListOpen', false);
            break;

          case 'location':
            this.set('model.state.assetListOpen', false);
            this.set('model.state.eventListOpen', false);
            this.toggleProperty('model.state.locationListOpen');
            this.set('model.state.geofenceListOpen', false);
            this.set('model.state.tripsListOpen', false);
            break;

          case 'geofence':
            this.set('model.state.assetListOpen', false);
            this.set('model.state.eventListOpen', false);
            this.set('model.state.locationListOpen', false);
            this.toggleProperty('model.state.geofenceListOpen');
            this.set('model.state.tripsListOpen', false);
            break;

          case 'trip':
            this.set('model.state.assetListOpen', false);
            this.set('model.state.eventListOpen', false);
            this.set('model.state.locationListOpen', false);
            this.set('model.state.geofenceListOpen', false);
            this.toggleProperty('model.state.tripsListOpen');
        }
      },
      // -----------------------------------------------------
      //#endregion
      //#region LOCATION MARKER ACTIONS

      /**
       * This captures actions fired from within location markers
       * @param sender - this is the marker that fired the action
       * @param action - this is the action to be performed
       * 'close' - the marker wishes to close its infobox
       * 'click' - the marker was clicked
       */
      onLocationMarkerAction: function onLocationMarkerAction(sender, action) {
        var instance = this.get('model.state.mapConfigActiveInstance');
        var array = instance.get('locationInfoBoxOpenArray');

        switch (action) {
          case 'close':
            array.removeObject(sender.get('location.id'));
            break;

          case 'click':
            array.addObject(sender.get('location.id'));
            break;

          case 'delete':
            this.send('locationDelete', sender.get('location'));
            break;
        }
      },
      //#endregion
      //#region ASSET MARKER ACTIONS

      /**
       * This is the action handler for any action that originates from an asset-marker
       * See the marker-asset component for more information.
       */
      onAssetMarkerAction: function onAssetMarkerAction(sender, action, options) {
        var instance = this.get('model.state.mapConfigActiveInstance');

        switch (action) {
          case 'click':
            instance.get('assetInfoBoxOpenArray').addObject(options.assetId);
            break;

          case 'close':
            instance.get('assetInfoBoxOpenArray').removeObject(options.assetId);
            break;

          case 'follow':
            instance.set('followAssetId', options.assetId);
            break;

          case 'un-follow':
            instance.set('followAssetId', null);
            break;

          case 'show-breadcrumbs':
            instance.get('assetBreadcrumbsShownArray').addObject(options.assetId);
            this.send('fetchAssetBreadcrumbs', [options.assetId]);
            break;

          case 'hide-breadcrumbs':
            instance.get('assetBreadcrumbsShownArray').removeObject(options.assetId);
            break;

          case 'ping':
            this.send('pingAsset', options.asset);
            break;

          case 'filter-geofences':
            this.notifications.success('Filtered Geofences on asset');
            this.set('model.state.geofenceList.filterShowAssets', [options.assetId]);
            break;

          case 'show-flightpath':
            instance.get('assetFlightPathShownArray').addObject(options.assetId);
            break;

          case 'hide-flightpath':
            instance.get('assetFlightPathShownArray').removeObject(options.assetId);
            break;

          case 'toggle-trip-route':
            {
              var trips = instance.get('tripsVisible');

              if (options.show) {
                trips.addObject(options.tripId);
              } else {
                trips.removeObject(options.tripId);
              }
            }
        }
      },
      onTripsWindowAction: function onTripsWindowAction(sender, action, options) {
        var instance = this.get('model.state.mapConfigActiveInstance');

        switch (action) {
          case 'showTripRoute':
            instance.get('tripsVisible').addObject(options.tripId);
            break;

          case 'centerOnAsset':
            {
              var asset = this.model.assets.findBy('id', options.assetId);

              if (asset.get('positionLatest.pointIsValid')) {
                var _instance = this.model.state.get('mapConfigActiveInstance');

                _instance.setCenterOnModel(asset.get('positionLatest'));

                _instance.assetInfoBoxOpenArray.addObject(options.assetId);
              }
            }
            break;
        }
      },
      //#endregion
      //#region MAP ACTIONS

      /**
       * This is the action handler for any action that originates from a google-map
       * component. See that component for more documentation on what actions are
       * supported and what the arguments are.
       */
      onMapAction: function onMapAction(sender, action, options) {
        var instance = this.get('model.state.mapConfigActiveInstance');

        switch (action) {
          case 'clearFollowedAsset':
            instance.set('followAssetId', null);
            break;

          case 'hideAssetsBreadcrumbs':
            instance.get('assetBreadcrumbsShownArray').removeObjects(options.assetIdArray);
            break;

          case 'hideTripRoutes':
            instance.get('tripsVisible').removeObjects(options.tripIdArray);
            break;

          case 'highlightedPositionClose':
            /**
             * Clears the highlighted event when the user closes the infoBox about it.
             */
            this.send('highlightAssetPosition', null);
            break;

          case 'addLocation':
            /**
             * Centers the map and then transitions to the add a new location screen
             */
            instance.set('center', options.latLng);
            this.transitionToRoute('portal.track.location', 'new');
            break;

          case 'deleteGeofence':
            /**
             * Forwards the delete geofence request up the chain to the route to handle
             * it in its own way.
             */
            this.send('deleteGeofence', options.geofence);
            break;

          case 'toggleOverlayGroupExpand':
            {
              var ogArray = instance.get('overlayGroupsExpandedArray');

              if (options.expanded) {
                ogArray.addObject(options.groupId);
              } else {
                ogArray.removeObject(options.groupId);
              }

              break;
            }

          case 'toggleOverlaysOnMap':
            {
              var ovArray = instance.get('overlayVisibleArray');

              if (options.visible) {
                ovArray.addObjects(options.overlayIds); //analytics('send', 'event', 'Overlay', 'show', options.overlayIds.join());
              } else {
                ovArray.removeObjects(options.overlayIds); //analytics('send', 'event', 'Overlay', 'hide', options.overlayIds.join());
              }

              break;
            }

          case 'overlayReordered':
            {
              var overlays = instance.get('overlayVisibleArray');
              overlays.removeObjects(options.overlayIds);
              overlays.pushObjects(options.overlayIds);
              break;
            }

          case 'loaded':
            {
              // If new map instance then center the map on the assets
              if (instance.isNew) {
                var bounds = _latLngConverter.default.getBounds(this.model.assetPositions.filterBy('pointIsValid'));

                instance.set('fitBounds', bounds);
              } // Center on the followed asset as the page is loaded.
              else if (instance.followAssetId) {
                  var asset = this.model.assets.findBy('id', instance.followAssetId);

                  if (asset && !!asset.positionsSorted.firstObject) {
                    instance.set('center', _latLngConverter.default.modelToGoogleLatLng(asset.positionsSorted.firstObject));
                  }
                }

              break;
            }

          case 'center':
            this.set('highlight', null);
            this.set('highlight_type', null);
            break;
        }
      } //#endregion

    }
  });

  _exports.default = _default;
});