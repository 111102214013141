define("sky-router-3/models/temporal-whitelist", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The email address
     */
    emailAddress: (0, _model.attr)('string'),

    /**
     * The update date time for the temporal email address
     */
    updateDateTime: (0, _model.attr)('string')
  });

  _exports.default = _default;
});