define("sky-router-3/utils/computed-alias-not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedAliasNot;

  /**
   * Creates a computed function that works off a property that does a logical not
   * based on the value and passes through the result to the underlying property
   * as an alias. Both get and set work
   * @param  {String} property the property to observe
   * @return {Ember.computed}
   */
  function computedAliasNot(property) {
    return Ember.computed(property, {
      get: function get() {
        return !this.get(property);
      },
      set: function set(prop, value) {
        this.set(property, !value);
        return value;
      }
    });
  }
});