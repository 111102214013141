define("sky-router-3/models/compound-alert-revision-output-properties", ["exports", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    /**
     * This holds the subtype of the fragment to instantate.
     * @see https://github.com/adopted-ember-addons/ember-data-model-fragments#polymorphism
     */
    $type: (0, _model.attr)('string')
  });

  _exports.default = _default;
});