define("sky-router-3/pods/portal/manage/burst-services/geo-area-edit/controller", ["exports", "ember-cp-validations", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map", "sky-router-3/utils/lat-lng-converter"], function (_exports, _emberCpValidations, _create, _stateTrackMap, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'geoAreaToEdit.name': (0, _emberCpValidations.validator)('presence', true),
    'geoAreaToEdit.diameterInKm': (0, _emberCpValidations.validator)('number', {
      lte: 2000,
      allowString: true,
      message: function message() {
        var intl = Ember.getOwner(this).lookup('service:intl');
        return intl.t('errors.lessThanOrEqualTo', {
          lte: '2000'
        });
      }
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, (_Ember$Controller$ext = {
    geoAreaService: Ember.inject.service(),
    showQuickEdit: Ember.computed('geoAreaToEdit', {
      get: function get() {
        return this.get('geoAreaToEdit') !== undefined;
      }
    }),
    geoAreaToEdit: undefined,
    geoAreaLocationMapToEdit: undefined,
    onClearLocation: function onClearLocation() {},
    onClose: function onClose() {
      this.set('circle', null);
      this.set('locationName', null);
      this.set('_circle', null);
    },

    /**
     * Called when a the filter shape is good to go.
     * This is a public action.
     * @public
     * @param {Array<LatLng>} Points
     */
    onSetLocation: function onSetLocation()
    /*points*/
    {},
    mapConfig: Ember.computed(function () {
      return (0, _create.default)(_stateTrackMap.default);
    }),

    /**
     * Internal path for manipulation.
     * Necessary to move from creating path -> editing path
     * @type {[type]}
     */
    circle: null,
    locationName: null,
    _circle: Ember.computed.reads('circle'),
    geoArea: null,

    /**
     * Sorting
     */
    sortProperty: ['name'],
    sortDesc: false,
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),
    sortedGeoAreas: Ember.computed.sort('model.geoAreaGroup.geoAreas', 'sortBy'),
    defaultColor: Ember.computed('model.geoAreaGroup.color', {
      get: function get() {
        console.error("defaultColor ".concat(this.get('groupColor')));
        return this.get('model.geoAreaGroup.color');
      },
      set: function set(prop, value) {
        this.set('model.geoAreaGroup.color', value);
        return value;
      }
    }),
    location: Ember.computed('locationName', function () {
      return this.get('locationName');
    }),
    center: null,
    radius: null,
    geoAreaLocationModalVisible: false
  }, _defineProperty(_Ember$Controller$ext, "locationName", null), _defineProperty(_Ember$Controller$ext, "geoAreaLocation", Ember.computed('center', 'radius', function () {
    return null;
  })), _defineProperty(_Ember$Controller$ext, "anyDiameterOutOfRange", Ember.computed('model.geoAreaGroup.geoAreas.@each.diameterInKm', function () {
    return this.model.geoAreaGroup.geoAreas.any(function (x) {
      return x.diameterInKm > 2000;
    });
  })), _defineProperty(_Ember$Controller$ext, "actions", {
    geoAreaLocationToggle: function geoAreaLocationToggle() {
      this.toggleProperty('geoAreaLocationModalVisible');
      this.set('circle', null);
      this.set('_circle', null);
    },
    geoAreaLocationClear: function geoAreaLocationClear() {
      this.set('radius', null);
      this.set('center', null);
      this.set('geoFilterModalVisible', false);
    },
    geoAreaLocationSet: function geoAreaLocationSet(circle, locationName) {
      this.set('radius', circle.radius / 500);
      this.set('center', circle.center); // Generate random id for newly created geoAreas, since created objects do not have one until the are saved to the store
      // This is necessary when editing each new geoArea that has not yet been saved. The id will be removed when saving it.
      //  value is high enough to last a few decades, after that I don't care

      var randomId = Math.floor(Math.random() * (1099999 - 1000000 + 1)) + 1000000;
      this.model.geoAreaGroup.geoAreas.addObject(Ember.Object.create({
        id: randomId,
        name: this.locationName,
        latitudeDegree: this.center.lat(),
        longitudeDegree: this.center.lng(),
        diameterInKm: this.radius,
        isDeleted: false,
        color: this.model.geoAreaGroup.color
      }));
      this.geoAreaService.fitMapToGeoAreas(this.model.geoAreaGroup.geoAreas, this.model.mapConfigView);
      circle = null;
      this.set('locationName', null);
      this.set('geoAreaLocationModalVisible', false);
    },
    clearLocation: function clearLocation() {
      this.set('_circle', null);
    },
    setLocation: function setLocation() {
      this.onSetLocation(this._circle.toJSON ? this._circle.toJSON() : this._circle);
    },
    onCreateCircle: function onCreateCircle(args) {
      this.set('_circle', args.circle); //this.set('_circle.name', this.get('locationName'));
      // Destroy the passed in circle object

      args.circle.setMap(null);
      args.circle = null;
    },
    openQuickEdit: function openQuickEdit(geoArea, map) {
      this.set('geoAreaToEdit', geoArea);
      this.geoAreaService.fitMapToGeoAreas([geoArea], map);
      this.set('geoAreaLocationMapToEdit', undefined);
    },
    closeQuickEdit: function closeQuickEdit() {
      this.set('geoAreaToEdit', undefined);
      this.set('geoAreaLocationMapToEdit', undefined);
      this.geoAreaService.fitMapToGeoAreas(geoAreaGroup.geoAreas, mapConfigView);
    },
    exitEdit: function exitEdit() {
      this.set('geoAreaToEdit', undefined);
      this.set('geoAreaLocationMapToEdit', undefined);
    },
    updateGeoAreaPoint: function updateGeoAreaPoint(geoArea, isLat, milliarc) {
      var degrees = _latLngConverter.default.milliArcSecondsToDecimalDeg(milliarc);

      if (isLat) {
        geoArea.set('latitudeDegree', degrees);
      } else {
        geoArea.set('longitudeDegree', degrees);
      }
    }
  }), _Ember$Controller$ext));

  _exports.default = _default;
});