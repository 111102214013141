define("sky-router-3/pods/portal/track/eta/controller", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['asset', 'latitude', 'longitude'],
    assetSelected: (0, _computedFindBy.default)('assets', 'id', 'model.asset', true),
    actions: {
      selectLoation: function selectLoation(location) {
        this.model.name = location.Name;
        this.model.latitudeMilliarcSeconds = location.LatitudeMilliarcSeconds;
        this.model.longitudeMilliarcSeconds = location.LongitudeMilliarcSeconds;
      },
      selectAsset: function selectAsset(asset) {
        this.model.asset = asset.id;
      }
    }
  });

  _exports.default = _default;
});