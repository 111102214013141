define("sky-router-3/helpers/sub-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.subString = subString;
  _exports.default = void 0;

  /**
   * This helper allows the user to display a subset of a string
   *
   * @example {{sub-string string start=0 length=25}}
   */
  function subString(params, hash) {
    if (params[0]) {
      var start = hash.start | 0;
      return params[0].substr(start, hash.length);
    }
  }

  var _default = Ember.Helper.helper(subString);

  _exports.default = _default;
});