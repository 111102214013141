define("sky-router-3/pods/components/portal/track/map/info-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JhKrOqPV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"section\"],[15,0,[31,[\"infobox \",[34,0]]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"class\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/info-box/template.hbs"
  });

  _exports.default = _default;
});