define("sky-router-3/pods/components/input-unit/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    valueDisplay: (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.readOnly('model.max'),
      gte: Ember.computed.readOnly('model.min'),
      disabled: Ember.computed('model.max', 'model.min', 'model.disabled', function () {
        return this.get('model.disabled') || !Ember.isPresent(this.get('model.max')) && !Ember.isPresent(this.get('model.min'));
      })
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Array of avilable units
     * @type {Array<sky-router-3/models/unit-*}
     */
    units: [],

    /**
     * Instance of the selected unit
     * @type {sky-router-3/models/unit-*}
     */
    unitSelected: null,

    /**
     * The centimeter value bound to this component
     * @type {Number}
     */
    valueCM: null,

    /**
     * Should the value be rounded for display
     * @type {Boolean}
     */
    round: false,

    /**
     * Is the input disabled or not
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Min value
     * If not present it won't enforce the validation requirement
     * @type {Number}
     */
    minCM: null,
    min: function () {
      var min = this.get('minCM');

      if (!Ember.isPresent(min)) {
        return undefined;
      }

      var value = min * this.get('unitSelected.centimeterFactor');

      if (this.get('round')) {
        return Math.ceil(value);
      }

      return value;
    }.property('minCM', 'unitSelected.centimeterFactor'),

    /**
     * Max value
     * If not present it won't enforce the validation requirement
     * @type {Number}
     */
    maxCM: null,
    max: function () {
      var max = this.get('maxCM');

      if (!Ember.isPresent(max)) {
        return undefined;
      }

      var value = max * this.get('unitSelected.centimeterFactor');

      if (this.get('round')) {
        return Math.floor(value);
      }

      return value;
    }.property('maxCM', 'unitSelected.centimeterFactor'),

    /**
     * Value that is used for display with the input
     */
    valueDisplay: Ember.computed('valueCM', 'unitSelected.centimeterFactor', {
      set: function set(key, value) {
        if (value == "" || isNaN(value)) {
          return value;
        }

        this.set('valueCM', Math.floor(value / this.get('unitSelected.centimeterFactor')));
        return value;
      },
      get: function get() {
        var value = this.get('valueCM') * this.get('unitSelected.centimeterFactor');

        if (this.get('round')) {
          return Math.round(value);
        }

        return value;
      }
    }),
    actions: {
      selectUnit: function selectUnit(unit) {
        this.onUnitChange(unit);
      }
    }
  });

  _exports.default = _default;
});