define("sky-router-3/utils/computed-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedFindBy;

  /**
   * Takes in a property that represents an array and returns
   * a computed property representing the includes.
   * @param  {String} property String that points to an array property
   * @param  {String} value    The value that the object contains.
   * @param  {Boolean} isProp  Is the value provided a property that must be looked up.
   * @return {Ember.computedProperty}          A computed property that re-evaulates
   *                                             every time the array changes.
   */
  function computedFindBy(property, value) {
    var isProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    return Ember.computed("".concat(property, ".[]"), function () {
      return this.get(property).includes(isProp ? this.get(value) : value);
    });
  }
});