define("sky-router-3/pods/portal/manage/burst-services/index/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    actions: {
      cancel: function cancel(controller) {
        controller.set('name', '');
        controller.set('description', '');
        controller.set('code', '');
        this.transitionTo('portal.manage.burst-services');
      },
      create: function create(controller) {
        var _this = this;

        this.send('blockApp', true);
        this.store.createRecord('burst-service', {
          serviceName: controller.get('name'),
          serviceDescription: controller.get('description'),
          code: controller.get('code')
        }).save().then(function () {
          _this.transitionTo('portal.manage.burst-services.index');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          controller.set('name', '');
          controller.set('description', '');
          controller.set('code', '');

          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});