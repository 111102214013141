define("sky-router-3/pods/portal/communicate/whitelist/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * Only users with communicate whitelist is allowed to access this route
     * and sub routes. This is a property from the `PermissionRequiredMixin`
     * @type {Array}
     */
    permissions: ['communicate.whitelist'],

    /**
     * The model for the device inventory reports route that contains an array of checkbox report column names.
     **/
    model: function model() {
      var store = this.get('store'); // Return the hashed model values

      return Ember.RSVP.hash({
        isTemporalWhitelistActive: store.queryRecord('temporal-whitelist-control', {}).then(function (value) {
          return value.get('isFeatureActive');
        }),
        // CSV of permanent whitelist emails
        emailList: store.queryRecord('whitelist', {}).then(function (value) {
          return value.get('list').split(',').map(function (item) {
            return item.trim();
          });
        }),
        // Array of temporal whitelist email addresses
        temporalEmails: store.query('temporal-whitelist', {})
      });
    },
    actions: {
      /**
       * Changes a temporary whitelisted email to a permanent one
       * @param  {Object} item The temporal email to make permanent
       */
      makeEmailPermanent: function makeEmailPermanent(item) {
        var _this = this;

        this.get('api').makeTempWhiteListPermanent(item.get('id'), item.get('emailAddress'), item.get('updateDateTime')).then(function () {
          // Refresh the model to show the changes
          _this.refresh();
        }).catch(function () {
          _this.notifications.error(_this.intl.t('communicate.makeEmailPermanentError'));
        });
      },

      /**
       * Deletes a temporary whitelist email
       * @param  {Object} item The temporal email to remove
       */
      removeTemporalEmail: function removeTemporalEmail(item) {
        var _this2 = this;

        this.get('api').deleteWhiteListEmail(item.get('emailAddress')).then(function () {
          // Refresh the model to show the changes
          _this2.refresh();
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('communicate.removeTemporalEmail'));
        });
      },

      /**
       * Saves the permanent whitelisted email list
       */
      savePermanentList: function savePermanentList() {
        var _this3 = this;

        // Create csv from array, remove empty items
        var list = this.get('currentModel.emailList').filter(function (e) {
          return String(e).trim();
        }).join(',');
        this.get('api').savePermanentEmailList(list).then(function () {
          _this3.notifications.success(_this3.intl.t('communicate.savePermanentEmailListSuccess')); // Refresh the model to show the changes


          _this3.refresh();
        }).catch(function () {
          _this3.notifications.error(_this3.intl.t('communicate.savePermanentEmailListFailure'));
        });
      },

      /**
       * Saves the Temporal Whitelist control setting
       * @param  {Boolean} value Is the new checkbox value checked or not
       */
      saveTemporaryWhitelistEnabled: function saveTemporaryWhitelistEnabled(value) {
        var _this4 = this;

        this.get('api').saveAutoWhitelistEnabled(value).then(function () {
          _this4.notifications.success(_this4.intl.t('communicate.saveTemporaryWhitelistEnabledSuccess')); // Refresh the model to show the changes


          _this4.refresh();
        }).catch(function () {
          _this4.notifications.error(_this4.intl.t('communicate.saveTemporaryWhitelistEnabledFailure'));
        });
      },

      /**
       * Adds an email address to the permanent whitelist
       */
      addPermanentEmail: function addPermanentEmail(args) {
        this.get('currentModel.emailList').addObject(args.addedToken.value);
      },

      /**
       * Removes an email address from the permanent whitelist
       */
      removePermanentEmail: function removePermanentEmail(args) {
        this.get('currentModel.emailList').removeObject(args.removedToken.value);
      }
    }
  });

  _exports.default = _default;
});