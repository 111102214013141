define("sky-router-3/pods/components/portal/manage/device-parameters/types/he72327/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,

    /**
     * Alias'd settings that are easier to get at.
     */
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_enabled'),
    timeReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_seconds'),
    distanceReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_enabled'),
    distanceReportingMeter: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_meters'),
    quickReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'quick_position_seconds'),
    quickReportingLimit: (0, _computedFindBy.default)('settings', 'key', 'quick_position_limit'),
    autoTakeoffEnabled: (0, _computedFindBy.default)('settings', 'key', 'auto_takeoff_land_enabled'),
    autoTakeoffSpeed: (0, _computedFindBy.default)('settings', 'key', 'auto_takeoff_land_takeoff_knots'),
    autoLandSpeed: (0, _computedFindBy.default)('settings', 'key', 'auto_takeoff_land_land_knots'),
    altitudeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'altitude_reporting_enabled'),
    altitudeReportingInterval: (0, _computedFindBy.default)('settings', 'key', 'altitude_reporting_seconds'),
    altitudeReportingValue: (0, _computedFindBy.default)('settings', 'key', 'altitude_reporting_feet'),
    minEnrouteEnabled: (0, _computedFindBy.default)('settings', 'key', 'min_enroute_enabled'),
    minEnrouteAltitude: (0, _computedFindBy.default)('settings', 'key', 'min_enroute_feet'),
    timeSpecifiedReporting1Enabled: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_1_enabled'),
    timeSpecifiedReporting1Minute: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_1_minute'),
    timeSpecifiedReporting2Enabled: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_2_enabled'),
    timeSpecifiedReporting2Minute: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_2_minute'),
    timeSpecifiedReporting3Enabled: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_3_enabled'),
    timeSpecifiedReporting3Minute: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_3_minute'),
    timeSpecifiedReporting4Enabled: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_4_enabled'),
    timeSpecifiedReporting4Minute: (0, _computedFindBy.default)('settings', 'key', 'time_specified_reporting_4_minute'),
    speedEnabled: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_enabled'),
    speedIntervalSeconds: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_interval_seconds'),
    speedTransitionTime: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_transition_seconds'),
    speedLimitKph: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_limit_kph'),
    startMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'start_movement_enabled'),
    stopMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_enabled'),
    stopMovementReportType: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_report_type'),
    stopMovementReportSeconds: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_report_seconds'),
    eventFlagsPowerOnEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_flags_power_on_enabled'),
    actions: {
      timeSpecifiedEnabled: function timeSpecifiedEnabled() {
        var setting = this.get('timeReportingEnabled');

        if (setting.get('valueBoolean')) {
          setting.set('valueBoolean', false);
          this.notifications.warning(this.intl.t('parameter.he7200.timeBasedReportingDisabled'));
        }
      },
      timeBasedEnabled: function timeBasedEnabled() {
        var time1 = this.get('timeSpecifiedReporting1Enabled');
        var time2 = this.get('timeSpecifiedReporting2Enabled');
        var time3 = this.get('timeSpecifiedReporting3Enabled');
        var time4 = this.get('timeSpecifiedReporting4Enabled');

        if (time1.get('valueBoolean') || time2.get('valueBoolean') || time3.get('valueBoolean') || time4.get('valueBoolean')) {
          time1.set('valueBoolean', false);
          time2.set('valueBoolean', false);
          time3.set('valueBoolean', false);
          time4.set('valueBoolean', false);
          this.notifications.warning(this.intl.t('parameter.he7200.timeSpecifiedReportingDisabled'));
        }
      }
    }
  });

  _exports.default = _default;
});