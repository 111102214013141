define("sky-router-3/pods/portal/forms/templates/preview/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('form-template', params.template_id);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.forms.templates');
      }
    }
  });

  _exports.default = _default;
});