define("sky-router-3/pods/components/portal/communicate/compose-message/component", ["exports", "ember-data", "ember-cp-validations", "sky-router-3/utils/string-search"], function (_exports, _emberData, _emberCpValidations, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    to: (0, _emberCpValidations.validator)('email', {
      allowMultiple: true
    }),
    cc: (0, _emberCpValidations.validator)('email', {
      allowEmpty: true,
      allowMultiple: true
    }),
    bcc: (0, _emberCpValidations.validator)('email', {
      allowEmpty: true,
      allowMultiple: true
    }),
    body: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Indicates if the form is currently saving and should be blocked.
     * @type {Boolean}
     */
    isSaving: false,

    /**
     * List of assets.
     * @type {sky-router-3/models/asset[]}
     */
    assets: null,

    /**
     * Array of all the assets that are capable of messaging.
     * @return {DS.PromiseObject<sky-router-3/models/asset[]>}
     */
    assetsCapableOfMessaging: function () {
      var assets = [];
      var promises = [];
      this.get('assets').forEach(function (asset) {
        var promise = asset.get('product').then(function (product) {
          if (product.get('supportMessages')) {
            assets.pushObject(asset);
          }
        });
        promises.push(promise);
      });
      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.all(promises).then(function () {
          return assets;
        })
      });
    }.property('assets.[]'),
    autocompleteData: function () {
      var data = [];

      if (this.get('assetsCapableOfMessaging.content')) {
        data.pushObjects(this.get('assetsCapableOfMessaging.content').map(function (asset) {
          return {
            display: asset.get('name'),
            value: asset.get('email'),
            description: asset.get('registration'),
            icon: true,
            model: asset
          };
        }));
      }

      data.pushObjects(this.get('contacts').map(function (contact) {
        return {
          display: contact.get('fullName'),
          value: contact.get('email'),
          description: contact.get('email'),
          icon: false,
          model: contact
        };
      }));
      return data;
    }.property('contacts.[]', 'assetsCapableOfMessaging.content.[]'),

    /**
     * User input stored as the filter
     * @type {String}
     */
    autocompleteToRecipientFilter: null,
    filteredToRecipients: Ember.computed.filter('autocompleteData', ['autocompleteToRecipientFilter', 'autocompleteData.[]'], function (option) {
      var filter = this.get('autocompleteToRecipientFilter');

      if (filter) {
        return (0, _stringSearch.default)(option.display, filter) || (0, _stringSearch.default)(option.description, filter);
      }
    }),

    /**
     * User input stored as the filter
     * @type {String}
     */
    autocompleteCCRecipientFilter: null,
    filteredCCRecipients: Ember.computed.filter('autocompleteData', ['autocompleteCCRecipientFilter', 'autocompleteData.[]'], function (option) {
      var filter = this.get('autocompleteCCRecipientFilter');

      if (filter) {
        return (0, _stringSearch.default)(option.display, filter) || (0, _stringSearch.default)(option.description, filter);
      }
    }),

    /**
     * User input stored as the filter
     * @type {String}
     */
    autocompleteBCCRecipientFilter: null,
    filteredBCCRecipients: Ember.computed.filter('autocompleteData', ['autocompleteBCCRecipientFilter', 'autocompleteData.[]'], function (option) {
      var filter = this.get('autocompleteBCCRecipientFilter');

      if (filter) {
        return (0, _stringSearch.default)(option.display, filter) || (0, _stringSearch.default)(option.description, filter);
      }
    }),

    /**
     * To address field. This an array of email addresses.
     * @type {String[]}
     */
    to: [],

    /**
     * The cc address field. This an array of email addresses.
     * @type {String[]}
     */
    cc: [],

    /**
     * The bcc address field.
     * @type {String[]}
     */
    bcc: [],

    /**
     * The subject field of the message.
     * @type {String}
     */
    subject: null,

    /**
     * The body field of the message.
     * @type {String}
     */
    body: null,
    actions: {
      /**
       * Handles changes to the token components and sets the properties.
       */
      saveDraft: function saveDraft() {
        this.onSave({
          to: this.get('to'),
          cc: this.get('cc'),
          bcc: this.get('bcc'),
          subject: this.get('subject'),
          body: this.get('body')
        });
      },
      sendMessage: function sendMessage() {
        this.onSend({
          to: this.get('to'),
          cc: this.get('cc'),
          bcc: this.get('bcc'),
          subject: this.get('subject'),
          body: this.get('body')
        });
      },
      toAddTag: function toAddTag(args) {
        this.set('autocompleteToRecipientFilter', '');
        this.get('to').addObject(args.model.get('email'));
      },
      ccAddTag: function ccAddTag(args) {
        this.set('autocompleteCCRecipientFilter', '');
        this.get('cc').addObject(args.model.get('email'));
      },
      bccAddTag: function bccAddTag(args) {
        this.set('autocompleteBCCRecipientFilter', '');
        this.get('bcc').addObject(args.model.get('email'));
      },
      addToEmail: function addToEmail(value) {
        this.get('to').addObject(value);
        this.set('autocompleteToRecipientFilter', '');
      },
      addCCEmail: function addCCEmail(value) {
        this.get('cc').addObject(value);
        this.set('autocompleteCCRecipientFilter', '');
      },
      addBCCEmail: function addBCCEmail(value) {
        this.get('bcc').addObject(value);
        this.set('autocompleteBCCRecipientFilter', '');
      },
      toRemoveTag: function toRemoveTag(index) {
        this.get('to').removeAt(index);
      },
      ccRemoveTag: function ccRemoveTag(index) {
        this.get('cc').removeAt(index);
      },
      bccRemoveTag: function bccRemoveTag(index) {
        this.get('bcc').removeAt(index);
      },
      autocompleteToField: function autocompleteToField(value) {
        this.set('autocompleteToRecipientFilter', value);
      },
      autocompleteCCField: function autocompleteCCField(value) {
        this.set('autocompleteCCRecipientFilter', value);
      },
      autocompleteBCCField: function autocompleteBCCField(value) {
        this.set('autocompleteBCCRecipientFilter', value);
      }
    }
  });

  _exports.default = _default;
});