define("sky-router-3/pods/components/portal/settings/security/unlock-question/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    securityAnswer: (0, _emberCpValidations.validator)('presence', true),
    securityQuestion: (0, _emberCpValidations.validator)('presence', true),
    currentPassword: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Security question
     * @type {String}
     */
    securityQuestion: '',

    /**
     * Security answer
     * @type {String}
     */
    securityAnswer: '',

    /**
     * The users current password
     * @type {String}
     */
    currentPassword: '',

    /**
     * Block the form input
     */
    blockForm: false,

    /**
     * The API service
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * The users sessionAccount service
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Loads the users security question when the component is loaded.
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      var user = this.get('sessionAccount.user.username');
      this.get('api').userGetUnlockQuestion(user).then(function (response) {
        _this.set('securityQuestion', response.StringResult);
      });
    },
    actions: {
      changeUnlockQuestion: function changeUnlockQuestion() {
        var _this2 = this;

        this.set('blockForm', true);
        this.get('api').userSetUnlockQuestion(this.get('sessionAccount.user.username'), this.get('securityQuestion'), this.get('securityAnswer'), this.get('currentPassword')).then(function () {
          _this2.notifications.success(_this2.intl.t('components.unlockChange.success'));
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('components.unlockChange.fail'));
        }).finally(function () {
          _this2.set('blockForm', false);

          _this2.get('securityAnswer', null);

          _this2.set('currentPassword', null);
        });
      }
    }
  });

  _exports.default = _default;
});