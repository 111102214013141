define("sky-router-3/services/signalr-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This state object stores metadata used to help perform change notification queries.
   * @type {Object}
   */
  var _default = Ember.Service.extend({
    api: Ember.inject.service(),

    /**
     * @private
     * @type {Object}
     */
    _state: {
      assets: {}
    },

    /**
     * This setups the state storage for SignalR which is necessary for knowing
     * when new records come in if they are new or not.
     * @return {Promise} Promise to setup the internal state so that the gets can be called.
     */
    setup: function setup() {
      var _this = this;

      return this.api.request('AssetPositions?count=1').then(function (positions) {
        for (var i = 0; i < positions.length; i++) {
          var position = positions.objectAt(i);

          _this.setLatestPositionId(position.Asset, position.Id);
        }
      });
    },

    /**
     * Get the latest position report id for the asset so that singalR can properly make a query.
     * @param  {Number} assetId
     * @return {Number}
     */
    getLatestPositionId: function getLatestPositionId(assetId) {
      return this._getOrCreateAssetState(assetId).latestPositionId;
    },

    /**
     * Updates the latest position report id if it is newer than the one stored.
     * @param  {Number} assetId
     * @param  {Number} positionId
     */
    setLatestPositionId: function setLatestPositionId(assetId, positionId) {
      var state = this._getOrCreateAssetState(assetId);

      if (state.latestPositionId < positionId) {
        state.latestPositionId = positionId;
      }
    },

    /**
     * Helper that builds the state object.
     * @param  {Number} assetId
     */
    _getOrCreateAssetState: function _getOrCreateAssetState(assetId) {
      var asset = this._state.assets[assetId];

      if (!asset) {
        return this._state.assets[assetId] = {
          latestPositionId: null
        };
      }

      return asset;
    }
  });

  _exports.default = _default;
});