define("sky-router-3/pods/components/portal/report/positionreporthistory-grid/component", ["exports", "sky-router-3/utils/computed-includes"], function (_exports, _computedIncludes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * The session service
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Indicates if the no records found message is to be shown
     * @type {Boolean}
     */
    showNoRecordMessage: false,

    /**
     * The user selected UTC offset
     * @type {int} The time offset
     */
    utcOffset: 0,

    /**
     * The speed option to use for the report results. Default to miles per hour
     * @type {Number}
     */
    speedOption: 1,

    /**
     * The altitude option to use for the report results. Default to feet
     * @type {Number}
     */
    altitudeOption: 1,

    /**
     * The report result set
     **/
    reportData: null,

    /**
     * The total number of records in the result set
     */
    totalRecords: 0,

    /**
     * The start index of the results
     */
    startIndex: 1,

    /**
     * The end index of the results
     */
    endIndex: 0,

    /**
     * The current page of the report results
     */
    currentPage: 1,

    /**
     * The amount of returned records
     */
    resultCount: 0,

    /**
     * The amount of records per paged results
     **/
    take: 300,

    /**
     * The user options for page size
     */
    takeSelections: [300, 500, 1000],

    /**
     * Flags to toggle display of table columns
     */
    showImei: (0, _computedIncludes.default)('displayedColumns', 'IMEI'),
    showName: (0, _computedIncludes.default)('displayedColumns', 'Name'),
    showSerial: (0, _computedIncludes.default)('displayedColumns', 'Serial'),
    showTime: (0, _computedIncludes.default)('displayedColumns', 'Time'),
    showServerTime: (0, _computedIncludes.default)('displayedColumns', 'ServerTime'),
    showLatencySeconds: (0, _computedIncludes.default)('displayedColumns', 'LatencySeconds'),
    showType: (0, _computedIncludes.default)('displayedColumns', 'Type'),
    showAltitude: (0, _computedIncludes.default)('displayedColumns', 'Altitude'),
    showVelocity: (0, _computedIncludes.default)('displayedColumns', 'Velocity'),
    showHeading: (0, _computedIncludes.default)('displayedColumns', 'Heading'),
    showDop: (0, _computedIncludes.default)('displayedColumns', 'DOP'),
    showLatitude: (0, _computedIncludes.default)('displayedColumns', 'Latitude'),
    showLongitude: (0, _computedIncludes.default)('displayedColumns', 'Longitude'),
    showMgrs: (0, _computedIncludes.default)('displayedColumns', 'MGRS'),
    showSource: (0, _computedIncludes.default)('displayedColumns', 'Source'),
    showPositionInformation: (0, _computedIncludes.default)('displayedColumns', 'Information'),

    /**
     * Creates the applicable pages list
     */
    pages: Ember.computed('totalRecords', 'take', function () {
      var pages = [];
      var take = this.take;
      var totalRecords = this.totalRecords;
      var count = Math.ceil(totalRecords / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }),

    /**
     * On init the report should be generated with default parameters or the ones passed to the route
     * @return {void}
     */
    didInsertElement: function didInsertElement() {
      this.onRegisterApi({
        generateReport: Ember.run.bind(this, this.generateReport)
      });
    },

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport(page) {
      var _this = this;

      // Update the UI while we load
      this.set('loading', true); // Get report parameters

      var devicesList = encodeURIComponent(this.devicesList);
      var eventsList = encodeURIComponent(this.eventsList);
      var visibleColumnsList = encodeURIComponent(this.displayedColumns);
      var rowCount = this.take;
      var startDate = this.startDate.toISOString();
      var endDate = this.endDate.toISOString();
      this.api.getPositionReportHistoryData(rowCount, page, devicesList, eventsList, visibleColumnsList, startDate, endDate, this.altitudeOption, this.speedOption, this.utcOffset, this.minLat, this.minLng, this.maxLat, this.maxLng).then(function (response) {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('reportData', response.rows);

          _this.set('totalRecords', response.records);

          _this.set('showNoRecordMessage', response.records === 0 ? true : false);

          _this.set('currentPage', response.page);

          _this.set('resultCount', response.total);

          _this.set('startIndex', (response.page - 1) * rowCount + 1);

          var endIndex = response.page * rowCount;

          if (endIndex > response.records) {
            endIndex = response.records;
          }

          _this.set('endIndex', endIndex);

          _this.set('loading', false);
        }
      }).catch(function () {
        _this.set('loading', false);
      });
    },
    actions: {
      countChanged: function countChanged(selection) {
        this.set('take', selection);
        this.set('currentPage', 1);
        this.set('totalRecords', 0);
        this.generateReport();
      },
      pageSelected: function pageSelected(page) {
        if (page !== this.currentPage) {
          this.generateReport(page);
        }
      }
    }
  });

  _exports.default = _default;
});