define("sky-router-3/models/form", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Users that submitted this form.
     * This may or may not be present if it was submitted by a user.
     * @type {sky-router-3/models/user}
     */
    user: (0, _model.belongsTo)('user'),

    /**
     * Personnel that submitted this form.
     * This may or may not be present if it was submitted by a personnel.
     * @type {sky-router-3/models/personnel}
     */
    personnel: (0, _model.belongsTo)('personnel'),

    /**
     * Was the form submitted by a personnel?
     * If yes then the personnel field will be populated with an identifier.
     * UI's should then utilize the personnel record as the 'sender'
     * If this is false UI's should utilize the User record as the 'sender'
     */
    submittedByPersonnel: (0, _model.attr)('boolean'),

    /**
     * template id for
     */
    template: (0, _model.belongsTo)('form-template'),

    /**
     * The form template file that should be used to display this form.
     */
    templateFile: (0, _model.belongsTo)('form-template-file'),

    /**
     * Name of form
     */
    name: (0, _model.attr)('string'),

    /**
     * data of form in json
     */
    formData: (0, _model.attr)('string', {
      defaultValue: "{}"
    }),

    /**
     * when the user form was created
     */
    createDateUtc: (0, _model.attr)('date'),

    /**
     * when the user form was submitted
     */
    submitDateUtc: (0, _model.attr)('date'),

    /**
     * Has the form been archived and is not retrievable by default
     * @type {Boolean}
     */
    isArchived: (0, _model.attr)('boolean'),

    /**
     * Indicates if the recipient list checker has been run.
     * This will return true if any processing has been done, even if the list is empty
     * @type {[type]}
     */
    recipientsNotified: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});