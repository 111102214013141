define("sky-router-3/pods/portal/track/layout/route", ["exports", "sky-router-3/models/site-layout"], function (_exports, _siteLayout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The translation services
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.track.layout');
    },

    /**
     * Model for this route is a promise array of site-layout records
     * @return {Ember.RSVP.Promise} Promise of layout track records.
     */
    model: function model() {
      return Ember.RSVP.hash({
        layouts: this.store.query('site-layout', {
          sectionId: _siteLayout.LAYOUT_TYPES.track
        }),
        state: this.modelFor('portal.track').state
      });
    },
    actions: {
      /**
       * When the modal is closed transition the user back
       * to the track route.
       */
      onModalClose: function onModalClose() {
        // Revert the Model
        var model = this.modelFor(this.routeName);
        model.layouts.forEach(function (layout) {
          layout.rollbackAttributes();
        });
        this.transitionTo('portal.track');
      },

      /**
       * This reloads the layouts query from the adapter.
       */
      reloadLayouts: function reloadLayouts() {
        var _this = this;

        this.store.query('site-layout', {
          sectionId: _siteLayout.LAYOUT_TYPES.track
        }).then(function (layouts) {
          Ember.set(_this.modelFor(_this.routeName), 'layouts', layouts);
        });
      }
    }
  });

  _exports.default = _default;
});