define("sky-router-3/helpers/has-permission", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper returns if the user has any of the supplied permission or not.
   * If all=true is applied then all the supplied permissions have to be present.
   *
   * @example {{has-permission 'communicate' 'communicate.send' all=true }}
   */
  var _default = Ember.Helper.extend({
    /**
     * The users session service
     * @return {sky-router-3/services/session-account}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Actually performs the computation.
     */
    compute: function compute(params, hash) {
      var permissions = this.get('sessionAccount.user.permissions');

      if (hash.inverse) {
        return !(0, _hasPermission.default)(permissions, params, hash.all);
      }

      return (0, _hasPermission.default)(permissions, params, hash.all);
    }
  });

  _exports.default = _default;
});