define("sky-router-3/models/hept-address", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    code: (0, _model.attr)('number'),
    name: (0, _model.attr)('string'),
    phone: (0, _model.attr)('string'),
    location: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    asset: (0, _model.belongsTo)('asset')
  });

  _exports.default = _default;
});