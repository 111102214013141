define("sky-router-3/pods/portal/report/analytics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/yReuvBD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"iframe\"],[15,\"src\",[31,[[34,0]]]],[14,0,\"analytics-content\"],[14,3,\"ember-frame\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "sky-router-3/pods/portal/report/analytics/template.hbs"
  });

  _exports.default = _default;
});