define("sky-router-3/models/asset-icon", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ICON_SIZE_ARRAY = _exports.ICON_SIZES = _exports.default = void 0;
  var ICON_SIZES = {
    TINY: {
      id: 0,
      sizePx: '15px',
      intl: 'deviceIcons.sizes.tiny'
    },
    SMALL: {
      id: 1,
      sizePx: '20px',
      intl: 'deviceIcons.sizes.small'
    },
    DEFAULT: {
      id: 2,
      sizePx: '30px',
      intl: 'deviceIcons.sizes.default'
    },
    LARGE: {
      id: 3,
      sizePx: '40px',
      intl: 'deviceIcons.sizes.large'
    },
    LARGER: {
      id: 4,
      sizePx: '50px',
      intl: 'deviceIcons.sizes.larger'
    },
    EXTRALARGE: {
      id: 5,
      sizePx: '75px',
      intl: 'deviceIcons.sizes.extralarge'
    },
    JUMBO: {
      id: 6,
      sizePx: '100px',
      intl: 'deviceIcons.sizes.jumbo'
    }
  };
  _exports.ICON_SIZES = ICON_SIZES;
  var ICON_SIZE_ARRAY = [ICON_SIZES.TINY, ICON_SIZES.SMALL, ICON_SIZES.DEFAULT, ICON_SIZES.LARGE, ICON_SIZES.LARGER, ICON_SIZES.EXTRALARGE, ICON_SIZES.JUMBO];
  _exports.ICON_SIZE_ARRAY = ICON_SIZE_ARRAY;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    isGlobal: (0, _model.attr)('boolean'),
    canEdit: (0, _model.attr)('boolean'),
    fileName: (0, _model.attr)('string'),
    fileExtension: (0, _model.attr)('string'),
    fileType: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    svg: (0, _model.attr)('string'),
    orientation: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    size: (0, _model.attr)('number', {
      defaultValue: ICON_SIZES.DEFAULT.id
    }),
    sizePx: Ember.computed('size', function () {
      return ICON_SIZE_ARRAY[this.size].sizePx;
    }),
    canAssign: Ember.computed('isGlobal', 'canEdit', function () {
      return this.isGlobal || this.canEdit;
    }),
    isOwner: Ember.computed('isGlobal', 'canEdit', function () {
      return !this.isGlobal && this.canEdit;
    }),
    isShared: Ember.computed('isGlobal', 'canEdit', function () {
      return !this.isGlobal && !this.canEdit;
    })
  });

  _exports.default = _default;
});