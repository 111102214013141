define("sky-router-3/models/voice-plan", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PLANS = void 0;

  /**
   * A constant object that provides the string values returned by the
   * providers property of the model below.
   * @type {Object}
   */
  var PLANS = {
    unactivated: 14,
    deactivated: 50,
    suspended: 19
  };
  _exports.PLANS = PLANS;

  var _default = _model.default.extend({
    planName: (0, _model.attr)('string'),
    planId: (0, _model.attr)('number'),
    groupId: (0, _model.attr)('number'),
    groupName: (0, _model.attr)('string'),
    assets: (0, _model.hasMany)('asset'),
    isActivatable: Ember.computed('planId', function () {
      switch (this.planId) {
        case PLANS.unactivated:
        case PLANS.deactivated:
        case PLANS.suspended:
          return true;

        default:
          return false;
      }
    })
  });

  _exports.default = _default;
});