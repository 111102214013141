define("sky-router-3/utils/compare-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = compareArray;

  /**
   * Compares 1 array to another array
   * @return {Boolean}
   */
  function compareArray(array1, array2) {
    if (array1 == array2) {
      // Type comparison for null
      return true;
    }

    if (Ember.get(array1, 'length') != Ember.get(array2, 'length')) {
      return false;
    }

    for (var i = 0; i < Ember.get(array1, 'length'); i++) {
      if (array1[i] != array2[i]) {
        return false;
      }
    }

    return true;
  }
});