define("sky-router-3/pods/components/portal/report/map-filter/component", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map"], function (_exports, _create, _stateTrackMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    onClearFilter: function onClearFilter() {},
    onClose: function onClose() {},

    /**
     * Called when a the filter shape is good to go.
     * This is a public action.
     * @public
     * @param {Array<LatLng>} Points
     */
    onSetFilter: function onSetFilter()
    /*points*/
    {},
    mapConfig: Ember.computed(function () {
      return (0, _create.default)(_stateTrackMap.default);
    }),

    /**
     * Internal path for manipulation.
     * Necessary to move from creating path -> editing path
     * @type {[type]}
     */
    bounds: null,
    _bounds: Ember.computed.reads('bounds'),
    actions: {
      mapAction: function mapAction(map, action) {
        if (action == 'loaded' && this._bounds) {
          this.mapConfig.set('fitBounds', this._bounds);
          this.mapConfig.set('zoom', 4);
        } // Do nothing

      },
      clearPath: function clearPath() {
        this.set('_bounds', null);
      },
      setFilter: function setFilter() {
        this.onSetFilter(this._bounds.toJSON ? this._bounds.toJSON() : this._bounds);
      },
      onCreateRectangle: function onCreateRectangle(args) {
        // Set the path
        this.set('_bounds', args.rectangle.getBounds()); // Destroy the passed in polygon object

        args.rectangle.setMap(null);
        args.rectangle = null;
      }
    }
  });

  _exports.default = _default;
});