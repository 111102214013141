define("sky-router-3/models/global-traveler-area", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberCpValidations, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validations = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    coords: (0, _emberCpValidations.validator)('inline', {
      validate: function validate(value, options, model, attribute) {
        return value.length > 0 || "region must be defined";
      },
      dependentKeys: ['model.coords.[]']
    })
  });

  var _default = _model.default.extend(validations, {
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    coords: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    contacts: (0, _model.hasMany)('global-traveler-contact'),
    isArchived: (0, _model.attr)('boolean'),
    org: (0, _model.belongsTo)('global-traveler-org')
  });

  _exports.default = _default;
});