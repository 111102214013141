define("sky-router-3/pods/portal/settings/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This route exists to prevent the user from traveling to just /settings
   * if they land on /settings it forwards them to the contact info route.
   */
  var _default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        this.replaceWith('portal.settings.contact');
      }
    }
  });

  _exports.default = _default;
});