define("sky-router-3/pods/public/login/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    touchId: Ember.inject.service(),

    /**
     * If active the form is disabled and a loading bar is shown
     */
    blockForm: false,

    /**
     * Defines if a login badAuth message will be displayed
     */
    loginBad: false,

    /**
     * Defines if a login disabled message will be displayed
     */
    loginDisabled: false,

    /**
    * The account for the user has been suspended
    */
    accountSuspended: false,

    /**
    * The account for the user has been deactivated
    */
    accountDeactivated: false,

    /**
     * Is the login button disabled when no input is found
     */
    disableLoginButton: function () {
      return Ember.isBlank(this.get('username')) || Ember.isBlank(this.get('password'));
    }.property('username', 'password')
  });

  _exports.default = _default;
});