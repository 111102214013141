define("sky-router-3/pods/components/portal/settings/notification-sounds/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Array of allowed MIME file types for the sounds
     * @type {Array}
     */
    allowedMimeTypes: ['audio/mpeg3', 'audio/mp3'],

    /**
     * The size limit of the audio file the user can upload.
     * @type {Number}
     */
    allowedFileLimit: 2000000,
    // 2mb

    /**
     * Value that indicates that the user has agreed to the terms of uploading
     * a new sound.
     * @type {Boolean}
     */
    newSoundTerms: false,

    /**
     * Subset array of sounds that the user uploaded themselves.
     */
    soundsUploaded: Ember.computed.filterBy('sounds', 'canDelete', true),

    /**
     * Boolean that indicates if the user has reached their sound upload limit.
     * @return {Boolean}
     */
    reachedUploadLimit: Ember.computed.gte('soundsUploaded.length', 10),

    /**
     * Filters the asset positions based on types that support being notifications
     */
    assetPositionTypesFiltered: Ember.computed.filterBy('assetPositionTypes', 'supportAlertAction', true),

    /**
     * Sort the position report types so they are in alphabetical order
     * @type {Array}
     */
    assetPositionTypesSortedBy: ['name'],
    assetPositionTypesSorted: Ember.computed.sort('assetPositionTypesFiltered', 'assetPositionTypesSortedBy'),
    actions: {
      /**
       * Deletes a sound record from the server.
       * @param  {sky-router-3/models/user-sound} sound
       */
      deleteSound: function deleteSound(sound) {
        var _this = this;

        sound.destroyRecord().then(function () {
          _this.modelFor(_this.routeName).assetPositionTypes.update();
        });
      },

      /**
       * Plays a sound model to the user.
       * @param  {sky-router-3/models/user-sound} sound
       */
      playSound: function playSound(sound) {
        $('#sound-' + sound.get('id'))[0].play();
      },

      /**
       * Executes a reset on the api for the asset position type and then
       * reloads all the positions types.
       */
      resetSoundAssignments: function resetSoundAssignments() {
        var api = this.get('api');
        this.assetPositionTypes.forEach(function (type) {
          api.resetAssetPositionType(type.get('id'));
          type.reload();
        });
      },

      /**
       * Saves the position type
       */
      saveAssetPositionType: function saveAssetPositionType(type) {
        type.save();
      },
      setAssetPositionTypeSound: function setAssetPositionTypeSound(type, selection) {
        type.set('sound', selection);
        type.save();
      },

      /**
       * Uploads a sound to the API and loads the sound into Ember-data if
       * successful.
       */
      uploadSound: function uploadSound() {
        var _this2 = this;

        var input = document.getElementById('soundFileUpload');

        if (input.files && input.files.length > 0) {
          var file = input.files[0];
          var desc = this.get('newSoundDescription'); // Validate the terms

          if (!this.get('newSoundTerms')) {
            this.notifications.error('You must agree to the terms');
            return;
          } // Validate the file


          if (file.size > this.get('allowedFileLimit')) {
            this.notifications.error(this.intl.t('settings.notificationsSoundsUploadedFileLimit', {
              size: '2mb'
            }));
            return;
          }

          if (!this.get('allowedMimeTypes').includes(file.type)) {
            this.notifications.error(this.intl.t('settings.notificationsSoundsFileType'));
            return;
          } // Set that we are uploading


          this.set('blockUploadButton', true); // Call the API

          this.get('api').uploadSound(file, desc).then(function (sound) {
            _this2.store.findRecord('user-sound', sound.Id);

            _this2.set('blockUploadButton', false);
          }).catch(function () {
            _this2.notifications.error(_this2.intl.t('settings.notificationsSoundsAddedNewFail'));

            _this2.set('blockUploadButton', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});