define("sky-router-3/pods/components/portal/settings/security/two-factor-auth/setup/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    password: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * 2FA setup fields
     */

    /**
     * The passed in user
     * @type {sky-router-3/models/user}
     */
    user: null,
    showSetup: Ember.computed('user.authyUser.id', function () {
      return !this.user.belongsTo('authyUser').id();
    }),
    showVerify: Ember.computed('showSetup', 'user.authyUser.confirmed', function () {
      return !this.showSetup && !this.get('user.authyUser.confirmed');
    }),
    mode: 'phone',
    // 'phone', 'qr'
    user2FAcode: function () {
      var _this = this;

      this.get('api').getTwoFactorAuthURL().then(function (code) {
        _this.set('user2FAcode', code);
      });
    }.property(),
    phone: null,
    phoneValid: false,
    countryCode: null,
    password: null,
    blockForm: false,
    showTwoFactorSetup: false,
    confirmTwoFactorToken: '',
    confirmPassword: '',
    disableForm: function () {
      return this.get('validations.isInvalid') || !this.get('phoneValid');
    }.property('validations.isInvalid', 'phoneValid'),
    frequency: null,
    frequencyOptions: function () {
      var intl = this.get('intl');
      return [{
        id: 1,
        label: intl.t('two-factor.settings.frequencyOptions.monthlyTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.monthly')
      }, {
        id: 2,
        label: intl.t('two-factor.settings.frequencyOptions.onceTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.once')
      }, {
        id: 3,
        label: intl.t('two-factor.settings.frequencyOptions.dailyTitle'),
        sub: intl.t('two-factor.settings.frequencyOptions.daily')
      }];
    }.property(),
    frequencySelected: function () {
      var opts = this.get('frequencyOptions');
      return opts.findBy('id', this.get('frequency')) || opts[1];
    }.property('frequency'),
    actions: {
      changeFrequency: function changeFrequency(opt) {
        this.set('frequency', opt.id);
      },
      phoneChanged: function phoneChanged(phone, countryData, isValid) {
        this.set('phone', phone);
        this.set('countryCode', countryData.dialCode);
        this.set('phoneValid', isValid);
      },
      startTwoFactorSetup: function startTwoFactorSetup() {
        this.set('showTwoFactorSetup', true);
      },
      submitTwoFactorSetup: function submitTwoFactorSetup() {
        var _this2 = this;

        var data = {
          phone: '',
          countryCode: 0,
          password: this.password,
          frequency: this.get('frequencySelected.id')
        };

        if (this.mode == 'phone') {
          data.phone = this.get('phone');
          data.countryCode = this.get('countryCode');
        }

        this.set('blockForm', true);
        this.formCompleted(data).then(function (record) {
          _this2.set('user.authyUser', record);
        }).catch(function () {
          _this2.notifications.warning(_this2.intl.t('two-factor.settings.activateFailed', {
            htmlSafe: true
          }));
        }).finally(function () {
          _this2.set('blockForm', false);
        });
      },
      verifyTwoFactorSetup: function verifyTwoFactorSetup() {
        var _this3 = this;

        var username = this.get('user.username');
        var password = this.get('confirmPassword');
        var token = this.get('confirmTwoFactorToken');
        this.get('api').userVerifyTwoFactor(username, password, token).then(function () {
          _this3.notifications.success(_this3.intl.t('two-factor.settings.completed', {
            username: username
          }));

          window.location.reload(true);
        }).catch(function () {
          _this3.notifications.error(_this3.intl.t('two-factor.validationInvalidOrPass'));
        });
      },
      closeTwoFactorSetup: function closeTwoFactorSetup() {
        this.set('showTwoFactorSetup', false);
        this.finish();
      },
      swap2FASetup: function swap2FASetup() {
        if (this.mode == 'qr') {
          this.set('mode', 'phone');
        } else {
          this.set('mode', 'qr');
        }
      },
      resetTwoFactor: function resetTwoFactor() {
        this.reset();
      }
    }
  });

  _exports.default = _default;
});