define("sky-router-3/pods/components/event-notifications/component", ["exports", "sky-router-3/models/asset-position-type"], function (_exports, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This displays event notifications
   * This is based off of https://github.com/Blooie/ember-cli-notifications
   */
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['alertevent-container', 'top-left'],
    sessionAccount: Ember.inject.service(),
    signalr: Ember.inject.service(),

    /**
     * Initializes the visible positions array and also attaches the signalr listners.
     * @return {[type]} [description]
     */
    init: function init() {
      this._super();

      this.set('alerts', []);
      this.get('signalr').on('onNewAssetPositions', Ember.run.bind(this, this._onNewAssetPositions));
      this.get('signalr').on('onNewDeviceHistoryEntry', Ember.run.bind(this, this._onNewDeviceHistoryEntry));
      this.get('signalr').on('onNewPositionMetadataEntry', Ember.run.bind(this, this._onNewPositionMetadataEntry));
    },

    /**
     * Removes the event handlers.
     */
    willDestroyElement: function willDestroyElement() {
      this.get('signalr').off('onNewAssetPositions', Ember.run.bind(this, this._onNewAssetPositions));
      this.get('signalr').off('onNewDeviceHistoryEntry', Ember.run.bind(this, this._onNewDeviceHistoryEntry));
      this.get('signalr').off('onNewPositionMetadataEntry', Ember.run.bind(this, this._onNewPositionMetadataEntry));
    },

    /**
     * Alerts that are visible to the user. Right now this component only supports
     * one type of alert but if more come along this property can be expanded
     * to combine multiple arrays.
     * @type {Object[]} consisting of
     * {
     * 		type: {String} 'position|etc..',
     * 		data: {Object} the alert object,
     * 		dismiss: {boolean} this property is set when the alert is about to be dismissed
     * 		timeout: {number} the length the alert will be visible in ms
     * }
     */
    alerts: null,

    /**
     * Gets the millisecond tieout value for the popup
     * @return {Number}
     */
    _timeout: Ember.computed('sessionAccount.user.eventNotificationTimeout', function () {
      return this.get('sessionAccount.user.eventNotificationTimeout') * 1000;
    }),

    /**
     * Takes in an array of new asset-position items and mutates them and then
     * inserts them into the alerts array. It also sets up a timeout
     * for the notification if it is needed.
     */
    _onNewAssetPositions: function _onNewAssetPositions(positions) {
      var _this = this;

      var alerts = this.get('alerts');
      var array = positions.filter(function (position) {
        // Hide reports that are so frequent that they will polute the UI.
        var type = position.belongsTo('type').id();
        return !_assetPositionType.POSITION_TYPES.isNormalType(type) && !position.notified;
      }).map(function (position) {
        position.set('notified', true);
        var timeout = _this._timeout;
        var obj = Ember.Object.create({
          type: 'position',
          data: position,
          timeoutEnabled: !position.get('type.isEmergency') && timeout > 0,
          timeout: timeout,
          timeoutStyle: "animation-duration:".concat(timeout, "ms; --webkit-animation-duration:").concat(timeout, "ms").htmlSafe()
        });

        _this._setupTimeoutForEvent(obj);

        return obj;
      });
      alerts.unshiftObjects(array);
    },

    /**
     * Takes a new device device history entry and inserts it into the alter array.
     * It also sets a timeout for the notification that automatically removes it from the UI
     */
    _onNewDeviceHistoryEntry: function _onNewDeviceHistoryEntry(data) {
      var timeout = this._timeout;
      var obj = Ember.Object.create({
        type: 'device-history',
        data: data,
        timeoutEnabled: timeout > 0,
        timeout: timeout,
        timeoutStyle: "animation-duration:".concat(timeout, "ms; --webkit-animation-duration:").concat(timeout, "ms").htmlSafe()
      });

      this._setupTimeoutForEvent(obj);

      this.get('alerts').unshiftObject(obj);
    },

    /**
     * Takes a new device position metadata entry and inserts it into the alter array.
     * It also sets a timeout for the notification that automatically removes it from the UI
     */
    _onNewPositionMetadataEntry: function _onNewPositionMetadataEntry(data) {
      var timeout = this._timeout;
      var obj = Ember.Object.create({
        type: 'position-metadata',
        data: data,
        timeoutEnabled: timeout > 0,
        timeout: timeout,
        timeoutStyle: "animation-duration:".concat(timeout, "ms; --webkit-animation-duration:").concat(timeout, "ms").htmlSafe()
      });

      this._setupTimeoutForEvent(obj);

      this.get('alerts').unshiftObject(obj);
    },
    _setupTimeoutForEvent: function _setupTimeoutForEvent(alert) {
      // Set the auto removal timeout.
      if (alert.get('timeoutEnabled')) {
        Ember.run.later(this, this._removeAlert, alert, alert.get('timeout'));
      }
    },

    /**
     * Removes an alert from the UI
     */
    _removeAlert: function _removeAlert(alert) {
      var _this2 = this;

      if (!alert) {
        return;
      }

      alert.set('dismiss', true); // Delay removal from DOM for dismissal animation

      Ember.run.later(this, function () {
        _this2.get('alerts').removeObject(alert);
      }, 500);
    },
    actions: {
      /**
       * Removes an alert from the visible alert array. This is called by the
       * template when the user wants to manually dismiss an alert.
       */
      removeAlert: function removeAlert(alert) {
        this._removeAlert(alert);
      },

      /**
       * Forces all the alerts to close
       */
      closeAllAlerts: function closeAllAlerts(alerts) {
        var _this3 = this;

        alerts.forEach(function (a) {
          return _this3._removeAlert(a);
        });
      }
    }
  });

  _exports.default = _default;
});