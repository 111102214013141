define("sky-router-3/components/col-pick", ["exports", "ember-colpick/mixins/col-pick"], function (_exports, _colPick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_colPick.default);

  _exports.default = _default;
});