define("sky-router-3/pods/portal/operations/flightplans/index/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    signalr: Ember.inject.service(),
    queryParams: {
      skip: {
        refreshModel: true
      },
      take: {
        refreshModel: true
      },
      sortProperty: {
        refreshModel: true
      },
      sortDesc: {
        refreshModel: true
      },
      filterStatus: {
        refreshModel: true
      },
      oldestDateEnabled: {
        refreshModel: true
      },
      soonestDateEnabled: {
        refreshModel: true
      },
      filterAssetIds: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        flightPlans: this.store.query('flightPlan', {
          skip: params.skip,
          take: params.take,
          sort: params.sortProperty,
          sortDesc: params.sortDesc,
          activityStatusId: params.filterStatus ? params.filterStatus : undefined,
          oldestDate: params.oldestDateEnabled ? (0, _moment.default)(params.oldestDate, 'x').toISOString() : undefined,
          newestDate: params.soonestDateEnabled ? (0, _moment.default)(params.soonestDate, 'x').toISOString() : undefined,
          assets: params.filterAssetIds,
          search: params.search ? params.search : undefined
        }),
        assets: this.store.findAll('asset'),
        flightPlanStatuses: this.store.findAll('flight-plan-status'),
        oldestDate: params.oldestDate,
        soonestDate: params.soonestDate
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.send('resetFilter');
      }
    },
    activate: function activate() {
      this.get('signalr').on('onFlightPlanEntryNotification', this, this.refresh);
    },
    deactivate: function deactivate() {
      this.get('signalr').off('onFlightPlanEntryNotification', this, this.refresh);
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      },
      delete: function _delete(plan) {
        var _this = this;

        if (confirm(this.intl.t('misc.areYouSureConfirm'))) {
          plan.destroyRecord().catch(function () {
            return _this.notifications.error(_this.intl.t('misc.failedOperation'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});