define("sky-router-3/models/site-layout", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LAYOUT_TYPES = _exports.default = void 0;

  /* eslint-env node */
  var LAYOUT_TYPES = {
    track: 1
  };
  /**
   * This model describes a site layout which is a JSON representation of an object
   * like `state-track` object graph with an associated name.
   */

  _exports.LAYOUT_TYPES = LAYOUT_TYPES;

  var model = _model.default.extend({
    /**
     * This ties to the layout type export above
     */
    sectionId: (0, _model.attr)('number', {
      defaultValue: 1
    }),

    /**
     * Name of this layout
     */
    name: (0, _model.attr)('string'),

    /**
     * This is the serialized representation of the `state-track` model.
     * The JSON does not contain any IDs and represents a static assembly
     * of properties the state model contains.
     */
    json: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});