define("sky-router-3/pods/components/portal/track/location-list/location-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pointer'],

    /**
     * Contains information about the location that this item handles and displays
     */
    item: null
  });

  _exports.default = _default;
});