define("sky-router-3/pods/components/pager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QZi4PFG5",
    "block": "{\"symbols\":[\"page\",\"@currentPage\",\"@onPageChange\"],\"statements\":[[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n    \"],[8,\"bs-button\",[[24,0,\"btn-default\"]],[[\"@disabled\",\"@action\"],[[30,[36,5],[[35,4]],null],[30,[36,3],[[32,0],[32,3],[30,[36,6],[[32,2],1],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"← \"],[1,[30,[36,7],[\"misc.pageBack\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[32,0,[\"pageDisplay\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"bs-button\",[[16,0,[31,[[30,[36,2],[[30,[36,1],[[32,2],[32,1]],null],\"btn-primary\",\"btn-default\"],null]]]]],[[\"@action\",\"@disabled\"],[[30,[36,3],[[32,0],[32,3],[32,1]],null],[30,[36,1],[[32,1],\"...\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1],\"...\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[30,[36,0],[[32,1],1],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[8,\"bs-button\",[[24,0,\"btn-default\"]],[[\"@disabled\",\"@action\"],[[30,[36,5],[[35,10]],null],[30,[36,3],[[32,0],[32,3],[30,[36,0],[[32,2],1],null]],null]]],[[\"default\"],[{\"statements\":[[1,[30,[36,7],[\"misc.pageNext\"],null]],[2,\" →\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"add\",\"eq\",\"if\",\"action\",\"canPageBackwards\",\"not\",\"sub\",\"t\",\"-track-array\",\"each\",\"canPageForwards\"]}",
    "moduleName": "sky-router-3/pods/components/pager/template.hbs"
  });

  _exports.default = _default;
});