define("sky-router-3/utils/computed-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedPromise;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function computedPromise() {
    var _Ember;

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var dependentKeys = args.slice(0, args.length - 2);
    var func = args[args.length - 2];
    var loadingValue = args[args.length - 1];
    var getFunc = func instanceof Function ? func : func.get;
    var setFunc = func instanceof Function ? undefined : func.set;
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependentKeys).concat([{
      get: function get() {
        var _this = this;

        for (var _len2 = arguments.length, getArgs = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          getArgs[_key2] = arguments[_key2];
        }

        Ember.RSVP.resolve(getFunc.apply(this, getArgs)).then(function (value) {
          if (!_this.isDestroyed && !_this.isDestroying) {
            _this.set(getArgs[0], value);
          }
        });
        return loadingValue;
      },
      set: function set(key, value) {
        return setFunc ? setFunc.call(this, key, value) : value;
      }
    }]));
  }
});