define("sky-router-3/models/mail-message-summary", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model is a special type of model that presents a summary view of a message
   * this model is not designed to live in the system for very long and should be
   * removed when not being accessed.
   */
  var _default = _model.default.extend({
    /**
     * The folder this message summary is in.
     */
    folder: (0, _model.belongsTo)('mail-folder', {
      async: true
    }),

    /**
     * Has the message been read
     */
    seen: (0, _model.attr)('boolean'),

    /**
     * Inverse of the `seen` property.
     */
    unseen: Ember.computed.not('seen'),

    /**
     * Has the message been flagged.
     */
    flagged: (0, _model.attr)('boolean'),

    /**
     * Who is the message to.
     */
    to: (0, _model.attr)('array'),

    /**
     * Who was the message from.
     */
    from: (0, _model.attr)('string'),

    /**
     * When was the message created or received.
     */
    date: (0, _model.attr)('date'),

    /**
     * What is the subject of the message.
     */
    subject: (0, _model.attr)('string'),

    /**
     * The full message that this summary belongs to.
     */
    message: (0, _model.belongsTo)('mail-message', {
      async: true
    })
  });

  _exports.default = _default;
});