define("sky-router-3/pods/components/input-unit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R9/2otb1",
    "block": "{\"symbols\":[\"dd\",\"unit\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n    \"],[18,3,[[32,0]]],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"type\",\"class\",\"val\",\"wait\",\"sync\",\"min\",\"disabled\"],[\"number\",\"form-control\",[35,5],250,true,\"0\",[35,4]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n        \"],[8,\"basic-dropdown\",[],[[\"@horizontalPosition\"],[\"auto-right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"Trigger\"]],[[24,0,\"btn btn-default\"],[24,4,\"button\"]],[[\"@htmlTag\"],[\"button\"]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"shortName\"]]],[2,\" \"],[10,\"span\"],[14,0,\"caret\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,[32,1,[\"Content\"]],[[24,0,\"dd\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"li\"],[15,0,[31,[[30,[36,2],[[30,[36,1],[[35,0,[\"id\"]],[32,2,[\"id\"]]],null],\"active\"],null]]]],[12],[11,\"a\"],[24,\"hef\",\"#\"],[4,[38,3],[[32,0],\"selectUnit\",[32,2]],null],[12],[1,[32,2,[\"name\"]]],[2,\" (\"],[1,[32,2,[\"shortName\"]]],[2,\")\"],[13],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"                \"],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,11],null,[[\"errors\"],[[35,10,[\"attrs\",\"valueDisplay\",\"messages\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unitSelected\",\"eq\",\"if\",\"action\",\"disabled\",\"valueDisplay\",\"input-debounced\",\"units\",\"-track-array\",\"each\",\"validations\",\"input-errors\"]}",
    "moduleName": "sky-router-3/pods/components/input-unit/template.hbs"
  });

  _exports.default = _default;
});