define("sky-router-3/utils/browser-detect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMobile = isMobile;
  _exports.isAndroid = isAndroid;
  _exports.isIE = isIE;
  _exports.isiOS = isiOS;
  _exports.isCordova = isCordova;
  var _isIE = null;
  var _isAndroid = null;
  var _isiOS = null;
  var _isCordova = null;
  /**
   * Checks if the user is on a mobile device
   * @return {Boolean}
   */

  function isMobile() {
    return isAndroid() || isiOS() || isCordova();
  }
  /**
   * Checks to see if the browser is android
   */


  function isAndroid() {
    if (_isAndroid != null) {
      return _isAndroid;
    }

    return _isAndroid = /(android)/i.test(navigator.userAgent);
  }
  /**
   * Checks to see if the browser is Internet Explorer or Edge
   */


  function isIE() {
    // Cached
    if (_isIE != null) {
      return _isIE;
    } // IE 11
    else if (navigator.userAgent.indexOf(".NET CLR") > -1) {
        _isIE = true;
      } // IE <= 10
      else if (navigator.appVersion.indexOf("MSIE") !== -1) {
          _isIE = true;
        } // IF Edge
        else if (/Edge\/\d+/.test(navigator.userAgent)) {
            _isIE = true;
          } else {
            _isIE = false;
          }

    return _isIE;
  }
  /**
   * Checks to see if the browser is iOS Safari
   */


  function isiOS() {
    if (_isiOS != null) {
      return _isiOS;
    }

    return _isiOS = ['iPhone', 'iPad', 'iPod'].indexOf(navigator.platform) !== -1;
  }
  /**
   * Checks to see if we are running as a packaged app
   * @return {Boolean}
   */


  function isCordova() {
    if (_isCordova != null) {
      return _isCordova;
    }

    return _isCordova = navigator.userAgent.indexOf('SkyRouterCordova') !== -1;
  }
});