define("sky-router-3/pods/components/portal/manage/device-parameters/controls/dual-mode/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'deviceNetworkModeTimeout.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.deviceNetworkModeTimeout.validationMaxNumber'),
      gte: Ember.computed.alias('model.deviceNetworkModeTimeout.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,
    deviceNetworkMode: null,
    deviceNetworkModeTimeout: null,
    showDeviceNetworkModeTimeout: false,

    /**
     * Powers the network mode dropdown.
     */
    networkModeSelected: Ember.computed('deviceNetworkMode.value', 'networkModeOptions', function () {
      return this.get('networkModeOptions').findBy('id', this.get('deviceNetworkMode.value') || '3');
    }),
    networkModeOptions: Ember.computed(function () {
      return [{
        id: '3',
        label: this.intl.t('parameter.networkSelection.cell_then_iridium')
      }, {
        id: '1',
        label: this.intl.t('parameter.networkSelection.iridium_then_cell')
      }, {
        id: '0',
        label: this.intl.t('parameter.networkSelection.iridiumOnly')
      }, {
        id: '2',
        label: this.intl.t('parameter.networkSelection.cellularOnly')
      }];
    }),
    actions: {
      networkChange: function networkChange(selection) {
        this.set('deviceNetworkMode.value', selection.id);
      }
    }
  });

  _exports.default = _default;
});