define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zRwlRXAz",
    "block": "{\"symbols\":[\"opt\",\"opt\"],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"parameter.rockseven.settings.title\"],null]],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[10,\"li\"],[12],[1,[30,[36,0],[\"parameter.rockseven.settings.help\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,2],[[35,1,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.rockseven.settings.labelGPRSStrategy\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],null,[[\"options\",\"selected\",\"disabled\",\"onChange\"],[[35,7],[35,6],[35,5],[30,[36,4],[[32,0],\"gpsStrategyChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,2],[[35,9,[\"hasDirtyAttributes\"]],\"has-warning\"],null]]]],[12],[2,\"\\n        \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.rockseven.settings.labelDynamicMode\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],null,[[\"options\",\"selected\",\"disabled\",\"onChange\"],[[35,11],[35,10],[35,5],[30,[36,4],[[32,0],\"dynamicModeChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"gprsStrategy\",\"if\",\"input-label\",\"action\",\"disabled\",\"gprsStrategySelected\",\"gprsStrategyOptions\",\"input-select\",\"dynamicMode\",\"dynamicModeSelected\",\"dynamicModeOptions\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/settings/template.hbs"
  });

  _exports.default = _default;
});