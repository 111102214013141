define("sky-router-3/models/compound-alert-revision", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)('presence', true),
    timeBetweenSeconds: (0, _emberCpValidations.validator)('presence', true),
    outputs: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      messageKey: 'compoundrules.output.presentError',
      dependentKeys: ['model.outputs.[]']
    }), (0, _emberCpValidations.validator)('has-many')],
    ruleGroups: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('has-many')],
    assets: (0, _emberCpValidations.validator)('inline', {
      validate: function validate(value, options, model) {
        return model.assets.get('length') || model.groups.get('length') ? true : "Must provide at least one asset or group";
      },
      dependentKeys: ['model.assets.[]', 'model.groups.[]']
    })
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)('string'),
    createdDateUtc: (0, _model.attr)('date'),
    timeBetweenSeconds: (0, _model.attr)('number', {
      defaultValue: 60
    }),
    assets: (0, _model.hasMany)('asset'),
    groups: (0, _model.hasMany)('group'),
    alert: (0, _model.belongsTo)('compound-alert', {
      inverse: 'revisions'
    }),
    outputs: (0, _model.hasMany)('compound-alert-revision-output', {
      async: false
    }),
    ruleGroups: (0, _model.hasMany)('compound-alert-revision-rule-group', {
      async: false
    })
  });

  _exports.default = _default;
});