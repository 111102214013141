define("sky-router-3/pods/portal/report/invoiceusage/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['reportType', 'usageType'],

    /**
     * The report type selected by the user
     * @type {Number}
     */
    reportType: null,

    /**
     * The usage type selected by the user
     * @type {Number}
     */
    usageType: null,

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Flag that represents the page is currently in the process of exporting an excel report
     * @type {Boolean}
     */
    exportingExcelFile: false,

    /**
     * Flag that represents the page is currently in the process of exporting a CSV report
     * @type {Boolean}
     */
    exportingCSVFile: false,

    /**
     * Flag that represents the page is currently in the process of exporting a PDF report
     * @type {Boolean}
     */
    exportingPDFFile: false,

    /**
     * Flag that is used to disable all export buttons when an export is in progress
     * @type {Boolean}
     */
    exportsDisabled: false,

    /**
     * Indicates if the export controls are shown or hidden
     * @type {Boolean}
     */
    showExports: true,

    /**
     * The selected invoice option for the report. Default to first
     */
    selectedInvoiceOption: function () {
      var options = this.get('model.invoiceOptions');
      return options === null || options.length === 0 ? null : options.objectAt(0);
    }.property('model.invoiceOption.[]'),

    /**
     * Property for the selected report type that updates the query string report type variable
     * @return {Object} The selected report type
     */
    selectedReportType: function () {
      var type = this.get('model.selectedReportType');
      this.set('reportType', type.id);
      return type;
    }.property('model.selectedReportType'),

    /**
     * Property for the selected usage type that updates the query string usage type variable
     * @return {Object} The selected report type
     */
    selectedUsageType: function () {
      var type = this.get('model.selectedUsageType');
      this.set('usageType', type.id);
      return type;
    }.property('model.selectedUsageType'),

    /**
     * Makes the API call to retrieve the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {int}    reportType The report type to query
     * @param  {int}    usageType The usage type to query
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(reportType, usageType, exportType, fileName) {
      var _this = this;

      // Toggle the exporting flag for the selected export.
      var busyFlag = 'exporting' + exportType + 'File';
      this.toggleProperty(busyFlag); // Disable export buttons

      this.toggleProperty('exportsDisabled');
      var reportDate = this.get('selectedInvoiceOption').date; // Export the summary version of the usage report

      if (reportType === 1) {
        // Generate the SBD export
        if (usageType === 1) {
          // Call the API
          this.get('api').getInvoiceUsageSBDSummaryReport(reportDate, exportType).then(function (response) {
            // Toggle the exporting flag for the selected export. This should style the export button as not exporting
            _this.toggleProperty(busyFlag); // Enable the export buttons


            _this.toggleProperty('exportsDisabled'); // Create link to download export file


            _this.send('sendResponseToClient', response, fileName);
          }).catch(function () {
            _this.notifications.error(_this.intl.t('report.exportFailed', {
              exportType: exportType
            })); // Sets the export button to not exporting


            _this.set(busyFlag, false); // Enable the export buttons


            _this.set('exportsDisabled', false);
          });
        } // Generate the Voice export
        else {
            // Call the API
            this.get('api').getInvoiceUsageVoiceSummaryReport(reportDate, exportType).then(function (response) {
              // Toggle the exporting flag for the selected export. This should style the export button as not exporting
              _this.toggleProperty(busyFlag); // Enable the export buttons


              _this.toggleProperty('exportsDisabled'); // Create link to download export file


              _this.send('sendResponseToClient', response, fileName);
            }).catch(function () {
              _this.notifications.error(_this.intl.t('report.exportFailed', {
                exportType: exportType
              })); // Sets the export button to not exporting


              _this.set(busyFlag, false); // Enable the export buttons


              _this.set('exportsDisabled', false);
            });
          }
      } // Export the detail version of the usage report
      else {
          // Generate the SBD Summary export
          if (usageType === 1) {
            // Call the API
            this.get('api').getInvoiceUsageSBDReport(reportDate, exportType).then(function (response) {
              // Toggle the exporting flag for the selected export. This should style the export button as not exporting
              _this.toggleProperty(busyFlag); // Enable the export buttons


              _this.toggleProperty('exportsDisabled'); // Create link to download export file


              _this.send('sendResponseToClient', response, fileName);
            }).catch(function () {
              _this.notifications.error(_this.intl.t('report.exportFailed', {
                exportType: exportType
              })); // Sets the export button to not exporting


              _this.set(busyFlag, false); // Enable the export buttons


              _this.set('exportsDisabled', false);
            });
          } // Generate the Voice Summary export
          else {
              // Call the API
              this.get('api').getInvoiceUsageVoiceReport(reportDate, exportType).then(function (response) {
                // Toggle the exporting flag for the selected export. This should style the export button as not exporting
                _this.toggleProperty(busyFlag); // Enable the export buttons


                _this.toggleProperty('exportsDisabled'); // Create link to download export file


                _this.send('sendResponseToClient', response, fileName);
              }).catch(function () {
                _this.notifications.error(_this.intl.t('report.exportFailed', {
                  exportType: exportType
                })); // Sets the export button to not exporting


                _this.set(busyFlag, false); // Enable the export buttons


                _this.set('exportsDisabled', false);
              });
            }
        }
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      /**
       * Set the selected invoice option when the user changes it in the drop down
       * @return {void}
       */
      invoiceOptionChanged: function invoiceOptionChanged(val) {
        this.set('selectedInvoiceOption', val);
      },

      /**
       * Set the selected report type when the user changes it in the drop down
       * @return {void}
       */
      reportTypeChanged: function reportTypeChanged(val) {
        this.set('model.selectedReportType', val);
      },

      /**
       * Set the selected usage type option when the user changes it in the drop down
       * @return {void}
       */
      usageTypeChanged: function usageTypeChanged(val) {
        this.set('model.selectedUsageType', val);
      },

      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        // Triggers the report to generate in the component
        this.toggleProperty('model.generateReportClicked');
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport(this.get('model.selectedReportType').id, this.get('model.selectedUsageType').id, 'Excel', this.intl.t('nav.invoiceUsage') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport(this.get('model.selectedReportType').id, this.get('model.selectedUsageType').id, 'CSV', this.intl.t('nav.invoiceUsage') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport(this.get('model.selectedReportType').id, this.get('model.selectedUsageType').id, 'PDF', this.intl.t('nav.invoiceUsage') + '-' + this.getUTCDateString() + '.pdf');
      }
    }
  });

  _exports.default = _default;
});