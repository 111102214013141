define("sky-router-3/models/overlay-user-event", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    type: (0, _model.belongsTo)('overlay-user-event-type', {
      async: true
    }),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    createdDateUtc: (0, _model.attr)('date'),
    expireDateUtc: (0, _model.attr)('date'),
    latitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});