define("sky-router-3/pods/portal/report/interaction-log/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the device history report. It handles creating the
   * report parameter option which for this report only includes an array of devices for use in a drop down list
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.interactionLog');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.interactionlog'],

    /**
     * The model for the device inventory reports route that contains an array of the user's available devices.
     **/
    model: function model() {
      return Ember.RSVP.hash({
        users: this.store.findAll('user')
      });
    },
    actions: {
      /**
       * Sets the report to automatically generate after render if a paramter imei was sent in the query string tp this route
       */
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          _this.controller.send('generateReport');
        });
      }
    }
  });

  _exports.default = _default;
});