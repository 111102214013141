define("sky-router-3/pods/public/login/unlock/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    answer: (0, _emberCpValidations.validator)('presence', true)
  }, {
    dependentKeys: ['model.intl.locale.firstObject']
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    answer: null,
    blockForm: false,
    unlockFail: false,

    /**
     * locale has to be consumed in order for depndent key in VALIDATIONS
     * to activate properly. This will cause validations to change based on locale.
     */
    init: function init() {
      this.get('intl.locale.firstObject');

      this._super.apply(this, arguments);
    },
    actions: {
      unlockUser: function unlockUser(username, answer) {
        var _this = this;

        this.set('blockForm', true);
        this.set('unlockFail', false);
        this.get('api').userUnlock(username, answer.trim()).then(function (response) {
          if (response.BooleanResult) {
            _this.transitionToRoute('public.login');
          }

          _this.set('answer', '');

          _this.set('unlockFail', true);
        }).catch(function () {
          _this.set('answer', '');

          _this.set('unlockFail', true);
        }).finally(function () {
          _this.set('blockForm', false);
        });
      }
    }
  });

  _exports.default = _default;
});