define("sky-router-3/pods/components/portal/settings/security/two-factor-auth/reset/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    finish: function finish() {},
    intl: Ember.inject.service(),

    /**
     * Passed in user who is we are supposed to be editing
     */
    user: null,
    password: null,
    actions: {
      reset: function reset() {
        var _this = this;

        if (!this.password) {
          return;
        }

        this.user.get('authyUser').then(function (user) {
          user.destroyRecord({
            adapterOptions: {
              data: {
                Password: _this.password
              }
            }
          }).then(function () {
            _this.finish();

            _this.notifications.success(_this.intl.t('two-factor.settings.reset.success', {
              user: _this.user.completeName
            }));
          }).catch(function () {
            _this.set('password', null);

            _this.notifications.warning(_this.intl.t('misc.badPassword'));
          });
        });
      }
    }
  });

  _exports.default = _default;
});