define("sky-router-3/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeZoneUtc = _exports.timeZones = void 0;
  var timeZoneUtc = {
    id: 0,
    label: "+0:00",
    offsetMinutes: 0
  };
  _exports.timeZoneUtc = timeZoneUtc;
  var timeZones = [{
    id: -12,
    label: "-12:00",
    offsetMinutes: -720
  }, {
    id: -11,
    label: "-11:00",
    offsetMinutes: -660
  }, {
    id: -10,
    label: "-10:00",
    offsetMinutes: -600
  }, {
    id: -570,
    label: "-9:30",
    offsetMinutes: -540
  }, {
    id: -9,
    label: "-9:00",
    offsetMinutes: -540
  }, {
    id: -8,
    label: "-8:00",
    offsetMinutes: -480
  }, {
    id: -7,
    label: "-7:00",
    offsetMinutes: -420
  }, {
    id: -6,
    label: "-6:00",
    offsetMinutes: -360
  }, {
    id: -5,
    label: "-5:00",
    offsetMinutes: -300
  }, {
    id: -4,
    label: "-4:00",
    offsetMinutes: -240
  }, {
    id: -210,
    label: "-3:30",
    offsetMinutes: -210
  }, {
    id: -3,
    label: "-3:00",
    offsetMinutes: -180
  }, {
    id: -2,
    label: "-2:00",
    offsetMinutes: -120
  }, {
    id: -1,
    label: "-1:00",
    offsetMinutes: -60
  }, timeZoneUtc, {
    id: 1,
    label: "+1:00",
    offsetMinutes: 60
  }, {
    id: 2,
    label: "+2:00",
    offsetMinutes: 120
  }, {
    id: 3,
    label: "+3:00",
    offsetMinutes: 180
  }, {
    id: 210,
    label: "+3:30",
    offsetMinutes: 210
  }, {
    id: 4,
    label: "+4:00",
    offsetMinutes: 240
  }, {
    id: 270,
    label: "+4:30",
    offsetMinutes: 270
  }, {
    id: 5,
    label: "+5:00",
    offsetMinutes: 300
  }, {
    id: 330,
    label: "+5:30",
    offsetMinutes: 330
  }, {
    id: 345,
    label: "+5:45",
    offsetMinutes: 345
  }, {
    id: 6,
    label: "+6:00",
    offsetMinutes: 360
  }, {
    id: 390,
    label: "+6:30",
    offsetMinutes: 390
  }, {
    id: 7,
    label: "+7:00",
    offsetMinutes: 420
  }, {
    id: 8,
    label: "+8:00",
    offsetMinutes: 480
  }, {
    id: 510,
    label: "+8:30",
    offsetMinutes: 510
  }, {
    id: 525,
    label: "+8:45",
    offsetMinutes: 525
  }, {
    id: 9,
    label: "+9:00",
    offsetMinutes: 540
  }, {
    id: 570,
    label: "+9:30",
    offsetMinutes: 570
  }, {
    id: 10,
    label: "+10:00",
    offsetMinutes: 600
  }, {
    id: 630,
    label: "+10:30",
    offsetMinutes: 630
  }, {
    id: 11,
    label: "+11:00",
    offsetMinutes: 660
  }, {
    id: 12,
    label: "+12:00",
    offsetMinutes: 720
  }, {
    id: 765,
    label: "+12:45",
    offsetMinutes: 765
  }, {
    id: 13,
    label: "+13:00",
    offsetMinutes: 780
  }, {
    id: 14,
    label: "+13:00",
    offsetMinutes: 840
  }];
  _exports.timeZones = timeZones;
});