define("sky-router-3/pods/components/input-autocomplete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    options: null,
    tagName: null,

    /**
     * Action that is fired when the user finishes typing
     * @argument {String} value The search value the user entered
     * @return {Promise} Return a promise to show loading icon.
     */
    filter: function filter()
    /*value*/
    {},
    focusOut: function focusOut() {},
    selectOption: function selectOption() {},

    /**
     * Handles loading the item and if a promise is returned then show the
     * loader while the promise is being handled.
     */
    handleFilter: function handleFilter() {
      var _this = this;

      this.set('_loading', true);
      var result = this.filter(this.value);

      if (result && result.finally) {
        result.finally(function () {
          return _this.set('_loading', false);
        });
      } else {
        this.set('_loading', false);
      }
    },

    /**
     * Opens and closes the dropdown for options
     * @type {Boolean}
     */
    _loading: false,

    /**
     * Indicates that the input is currently in focus
     */
    _inFocus: false,
    actions: {
      /**
       * wait to mark as focused false because the hiding the element can
       * cause the selectOpt event to fail.
       */
      unfocused: function unfocused(dropdown) {
        var _this2 = this;

        Ember.run.later(this, function () {
          if (!_this2.isDestroying && !_this2.isDestroyed) {
            _this2.set('_inFocus', false);

            _this2.focusOut();

            dropdown.actions.close();
          }
        }, 250);
      },
      focused: function focused(dropdown) {
        this.set('_inFocus', true);
        dropdown.actions.open();

        if (this.value) {
          this.set('_loading', true);
          Ember.run.debounce(this, this.handleFilter, 400);
        }
      },
      select: function select(opt) {
        this.selectOption(opt);
        this.focusOut();
      },
      handleFilterEntry: function handleFilterEntry(value) {
        if (value != this.value) {
          this.set('value', value);
          this.set('_loading', true);
          Ember.run.debounce(this, this.handleFilter, 400);
        }
      },
      handleDropdownClosing: function handleDropdownClosing() {
        if (this._inFocus) return false;
      }
    }
  });

  _exports.default = _default;
});