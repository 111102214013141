define("sky-router-3/pods/public/alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAYNHisW",
    "block": "{\"symbols\":[\"instance\"],\"statements\":[[6,[37,6],null,[[\"config\",\"mapAction\",\"showOverlays\",\"contextMenuAllow\",\"allowDistanceTool\"],[[35,2,[\"mapConfig\"]],[30,[36,5],[\"mapAction\"],null],false,false,false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],null,[[\"map\",\"point\",\"draggable\"],[[32,1,[\"map\"]],[35,2,[\"point\"]],false]]]],[2,\"\\n\"],[6,[37,4],null,[[\"map\",\"placement\"],[[32,1,[\"map\"]],\"TOP_CENTER\"]],[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"class\",\"route\"],[\"map-button btn btn-info btn-lg\",\"public.login\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"i\"],[14,0,\"fa fa-sign-in\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"login.loginToSkyRouter\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\",\"model\",\"portal/track/map/marker/marker-point\",\"portal/track/map/map-button\",\"route-action\",\"portal/track/map/map-google\"]}",
    "moduleName": "sky-router-3/pods/public/alert/template.hbs"
  });

  _exports.default = _default;
});