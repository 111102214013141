define("sky-router-3/pods/components/language-switcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zADqg12r",
    "block": "{\"symbols\":[\"lang\"],\"statements\":[[6,[37,3],null,[[\"options\",\"selected\",\"onChange\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"changeLanguage\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"currentSelection\",\"languages\",\"input-select\"]}",
    "moduleName": "sky-router-3/pods/components/language-switcher/template.hbs"
  });

  _exports.default = _default;
});