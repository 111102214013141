define("sky-router-3/models/trip", ["exports", "@ember-data/model", "sky-router-3/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIP_REPORTING_STATUS = _exports.TRIP_STATUS = void 0;
  var TRIP_STATUS = {
    started: '0',
    inAir: '1',
    departed: '2',
    landed: '3',
    onApproach: '4',
    arrived: '5',
    scheduled: '6',
    canceled: '7'
  };
  _exports.TRIP_STATUS = TRIP_STATUS;
  var TRIP_REPORTING_STATUS = {
    normal: '1',
    MER: '2',
    MER_Warning: '3',
    MER_Danger: '4',
    Emergency: '5'
  };
  _exports.TRIP_REPORTING_STATUS = TRIP_REPORTING_STATUS;

  var _default = _model.default.extend({
    /**
     * How long can the offset be before they are declared ahead or behind.
     * @type {Number}
     */
    SCHEDULED_OFFSET_LIMIT: 300,

    /**
     * Asset this trip belongs to
     * @type {sky-router-3/models/asset}
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The current phase of the trip.
     * ex. 'in air', 'arrived'
     * @type {sky-router-3/models/trip-status}
     */
    status: (0, _model.belongsTo)('trip-status'),

    /**
     * The current reporting phase of the trip.
     * ex. 'Normal', 'Missed Expected Report', 'Emergency'
     * @type {sky-router-3/models/trip-status}
     */
    reportingStatus: (0, _model.belongsTo)('trip-reporting-status'),

    /**
     * Gets the style for the reporting status. Alert statuses will
     * be in red and everything else will be in black.
     */
    reportingStatusStyle: Ember.computed('significantPositions', function () {
      var lastEvent = this.significantPositions.get('lastObject');

      if (!lastEvent || lastEvent.get('isReportingStatusNormal')) {
        return "color:".concat('#000000');
      }

      return "color:".concat('#FC320E');
    }),

    /**
     * If this trip is associated with an active trip plan this will be present.
     * NOTE: this is not required.
     * @type {sky-router-3/models/trip-plan}
     */
    leg: (0, _model.belongsTo)('trip-plan-leg'),

    /**
     * The date the trip was started /  created
     * @type {Date}
     */
    createdDateUtc: (0, _model.attr)('date'),

    /**
     * Indicates if the trip is completed or not.
     * @readonly
     * @type {Boolean}
     */
    isCompleted: (0, _model.attr)('boolean'),

    /**
     * Indicates if the plan has started or not
     * @type {Boolean}
     * @readonly
     */
    isStarted: (0, _model.attr)('boolean'),

    /**
     * Indicates if the trip is in process
     * NOTE: Thought started & active were different, they are not
     */
    isActive: Ember.computed.alias('isStarted'),

    /**
     * Indicates if the trip is the current active trip or a recently completed trip.
     */
    isRecent: Ember.computed.or('isStarted', 'isCompleted'),
    isCanceled: Ember.computed.equal('status.id', TRIP_STATUS.canceled),
    isScheduled: Ember.computed.equal('status.id', TRIP_STATUS.scheduled),
    isFuture: (0, _model.attr)('boolean'),
    // essentially the same as isScheduled
    //#region START
    //##################################################

    /**
     * The date the trip was started
     * @type {Date}
     */
    startDateUtc: (0, _model.attr)('date'),

    /**
     * The type of the position that started the trip
     * @type {sky-router-3/models/asset-position-type}
     */
    startPositionType: (0, _model.belongsTo)('asset-position-type'),

    /**
     * The position that started this trip
     * @type {sky-router-3/models/asset-position}
     */
    startPosition: (0, _model.belongsTo)('asset-position'),
    //##################################################
    //#endregion
    //#region STOP
    //##################################################

    /**
     * The date the trip was stopped
     * @type {Date}
     */
    stopDateUtc: (0, _model.attr)('date'),

    /**
     * The type of the position that stopped the trip
     * @type {sky-router-3/models/asset-position-type}
     */
    stopPositionType: (0, _model.belongsTo)('asset-position-type'),

    /**
     * The position that stopped this trip
     * @type {sky-router-3/models/asset-position}
     */
    stopPosition: (0, _model.belongsTo)('asset-position'),
    //##################################################
    //#endregion
    //#region METRICS
    //##################################################
    traveledDistanceCm: (0, _model.attr)('number'),
    averageSpeedCms: (0, _model.attr)('number'),
    averageAltitudeCm: (0, _model.attr)('number'),
    positionCount: (0, _model.attr)('number'),
    timeElapsedSeconds: (0, _model.attr)('number'),

    /**
     * The latest known position for the asset on the trip
     * @type {sky-router-3/models/asset-position}
     */
    latestPosition: (0, _model.belongsTo)('asset-position'),

    /**
     * An array of asset-positions that had a significant impact on the trip.
     * For example a take off is important to flights.
     * @type {Array<sky-router-3/models/asset-position>}
     */
    significantPositions: (0, _model.hasMany)('trip-position', {
      async: false
    }),

    /**
     * Any identification cards that have been seen on this trip.
     * @type {Array<sky-router-3/models/trip-identification-card>}
     */
    identificationCardLog: (0, _model.hasMany)('identification-card-log'),

    /**
     * Gets a link to the KML for this trip.
     * If the trip is not finished yet this returns the trip up to the latest position.
     * @return {String}
     */
    kmlHref: Ember.computed('asset.id', 'endDate', 'endPosition.id', 'latestPosition.{id,utc}', 'startDate', 'startDateUtc', 'startPosition.id', 'stopDateUtc', function () {
      var sid = this.get('startPosition.id');
      var sdt = this.get('startDateUtc');
      var eid = this.get('endPosition.id') || this.get('latestPosition.id');
      var edt = this.get('stopDateUtc') || this.get('latestPosition.utc');
      var imei = this.get('asset.id');

      if (eid && edt) {
        return "".concat(_environment.default.API.namespace, "/kml/trip?id=").concat(imei, "&sdt=").concat(sdt.toISOString(), "&edt=").concat(edt.toISOString(), "&tstartid=").concat(sid, "&tstopid=").concat(eid);
      }

      return null;
    }),
    _now: new Date(),
    _nowInterval: null,
    calculatedElapseTimeSeconds: Ember.computed('startDateUtc', 'isActive', 'leg', 'leg.elapseTimeBySchedule', 'tripElapseTimeSeconds', '_now', function () {
      var isActive = this.get('isActive');
      var tripElapseTime = this.get('tripElapseTimeSeconds');
      var elapseTimeBySchedule = this.get('leg.elapseTimeBySchedule');

      if (isActive && elapseTimeBySchedule) {
        var startDate = this.get('startDateUtc');
        var now = this.get('_now');
        console.log("Trip now: ".concat(now));
        var calcElapse = (now - startDate) / 1000;
        return calcElapse;
      }

      return tripElapseTime;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var me = this;
      var isActive = this.get('isActive');

      if (isActive) {
        if (!this._nowInterval) {
          this._nowInterval = setInterval(function () {
            return me.set('_now', new Date());
          }, 1000);
        }
      } else {
        if (this._nowInterval) {
          clearInterval(this._nowInterval);
          this._nowInterval = null;
        }
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._nowInterval) {
        clearInterval(this._nowInterval);
        this._nowInterval = null;
      }
    }
  });

  _exports.default = _default;
});