define("sky-router-3/pods/components/portal/track/asset-list/asset-item/component", ["exports", "sky-router-3/models/asset-position-type"], function (_exports, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pointer'],
    sessionAccount: Ember.inject.service(),

    /**
     * Action is triggered when the user clicks on anything that isn't a menu
     * @event 'clicked'
     * @param {string} the id of the asset that is displayed by this component
     */

    /**
     * Action is triggered when the user shows or hides the events associated
     * with this asset.
     * @event 'showEventsChanged'
     * @param {boolean} true if the component wants the list to be shown
     * @param {string} the id of the asset that is displayed by this component
     */

    /**
     * @event 'followAsset'
     * Action is triggered when the user wishes to follow the asset
     * @param {string} the id of the asset that is displayed by this component
     */

    /**
     * @event 'showBreadcrumbs'
     * Requests showing of the assets breadcrumbs on the active map
     * @param {string} the id of the asset that is displayed by the component
     */

    /**
     * @event 'filterGeofences'
     * Requests that the displayed geofences be filtered based on this asset
     * being assigned to the geofence.
     * @param {string} the id of the asset that is displayed by the component
     */

    /**
     * @event 'pingAsset'
     * Requests that the asset be pinged
     * @param {string} the id of the asset that is displayed by the component
     */

    /**
     * @event 'removeFromAllGeofences'
     * Requests that the asset be removed from all geofences
     * @param {string} the id of the asset that is displayed by the component
     */

    /**
     * @event 'resendAllGeofences'
     * Request that the asset be resent all the geofences that it is assigned to
     * @param {string} the id of the asset that is displayed by the component
     */

    /**
     *  Incoming asset that is to be displayed in the UI
     */
    asset: null,

    /**
     * Filters sorted asset positions to only hide normal position reports.
     * @return {sky-router-3/models/asset-position[]}
     */
    positionsFiltered: Ember.computed.filter('asset.positionsSorted', function (position) {
      return !_assetPositionType.POSITION_TYPES.isNormalType(position.belongsTo('type').id());
    }),

    /**
     * Returns the last 25 position reports from `positionsFiltered`
     * @return {sky-router-3/models/asset-position[]}
     */
    positions: function () {
      var positions = this.get('positionsFiltered');
      var startIndex = Math.max(positions.get('length') - 25, 0);
      var sliced = positions.slice(startIndex);
      return sliced.reverse();
    }.property('positionsFiltered.[]'),

    /**
     * Returns true if there are any positions to be shown for this asset.
     * @type {Boolean}
     */
    anyPositions: Ember.computed.bool('positionsFiltered.length'),

    /**
     * Incoming parameter that indicates if the events list should
     * be shown for this asset or not.
     */
    showEvents: false,
    assetDisplayName: Ember.computed('asset.name', 'asset.registration', 'asset.serial', 'asset.driverName', 'sessionAccount.user.assetDisplayNameFormat', function () {
      if (!this.asset) return '';
      var _this$asset = this.asset,
          name = _this$asset.name,
          registration = _this$asset.registration,
          serial = _this$asset.serial,
          driverName = _this$asset.driverName;
      var format = this.get('sessionAccount.user.assetDisplayNameFormat');
      if (!format || format.trim().length < 1) return name;
      var displayName = format.replaceAll('{{AN}}', name).replaceAll('{{REG}}', registration).replaceAll('{{SN}}', serial).replaceAll('{{DN}}', driverName);
      if (format.trim().length < 1) return name;
      return displayName;
    })
  });

  _exports.default = _default;
});