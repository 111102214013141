define("sky-router-3/pods/components/icon-asset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "svrS7lbg",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,7],[[35,0,[\"fileExtension\"]],\"svg\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,6],[[35,0,[\"svg\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"display-svg\",[[16,5,[34,1]],[16,0,[31,[\"asset-icon \",[34,5],\" \",[30,[36,3],[[35,4],\"inactive\"],null],\" \",[30,[36,3],[[35,2],\"active\"],null]]]]],[[\"@url\"],[[34,0,[\"url\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[15,5,[34,1]],[15,0,[31,[\"asset-icon \",[34,5],\" \",[30,[36,3],[[35,4],\"inactive\"],null],\" \",[30,[36,3],[[35,2],\"active\"],null]]]],[12],[2,\"\\n            \"],[2,[35,0,[\"svg\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[31,[[34,0,[\"url\"]]]]],[15,5,[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"style\",\"isActive\",\"if\",\"isInactive\",\"class\",\"is-empty\",\"eq\"]}",
    "moduleName": "sky-router-3/pods/components/icon-asset/template.hbs"
  });

  _exports.default = _default;
});