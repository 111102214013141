define("sky-router-3/pods/portal/forms/templates/edit/route", ["exports", "sky-router-3/models/form-template-type", "sky-router-3/models/form-template-email-attachment-type"], function (_exports, _formTemplateType, _formTemplateEmailAttachmentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        template: params.template_id === 'new' ? this.store.createRecord('form-template') : this.store.findRecord('form-template', params.template_id),
        types: this.store.findAll('form-template-type'),
        users: this.store.findAll('user'),
        personnel: this.store.findAll('personnel'),
        emailAttachmentTypes: this.store.findAll('form-template-email-attachment-type')
      });
    },
    afterModel: function afterModel(model) {
      if (model.template.get('isNew')) {
        model.template.set('type', model.types.findBy('id', _formTemplateType.types.userForm)), model.template.set('emailAttachmentType', model.emailAttachmentTypes.findBy('id', _formTemplateEmailAttachmentType.emailAttachmentTypes.pdf));
      }
    }
  });

  _exports.default = _default;
});