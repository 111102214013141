define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-airborne-fleet/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'airborneVRotate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.airborneVRotate.validationMaxNumber'),
      gte: Ember.computed.alias('model.airborneVRotate.validationMinNumber')
    })],
    'airborneVStall.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.airborneVStall.validationMaxNumber'),
      gte: Ember.computed.alias('model.airborneVStall.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    airborneVRotate: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    airborneVStall: null,

    /**
     * Gets the selected option, sets a default if option not found in the list
     */
    airborneVRotateSelected: function () {
      var options = this.get('airborneVRotateOptions');
      var option = options.findBy('value', this.get('airborneVRotate.value'));

      if (!option) {
        option = options.findBy('value', '6');
      }

      return option;
    }.property('airborneVRotate.value'),

    /**
     * Gets the selected option, sets a default if option not found in the list
     */
    airborneVStallSelected: function () {
      var options = this.get('airborneVStallOptions');
      var option = options.findBy('value', this.get('airborneVStall.value'));

      if (!option) {
        option = options.findBy('value', '88');
      }

      return option;
    }.property('airborneVStall.value'),

    /**
     * The options for the V-Rotate dropdown
     */
    airborneVRotateOptions: function () {
      return [{
        value: '0',
        label: '0'
      }, {
        value: '2',
        label: '2'
      }, {
        value: '4',
        label: '4'
      }, {
        value: '6',
        label: '6'
      }, {
        value: '8',
        label: '8'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '12',
        label: '12'
      }, {
        value: '14',
        label: '14'
      }, {
        value: '16',
        label: '16'
      }, {
        value: '18',
        label: '18'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '22',
        label: '22'
      }, {
        value: '24',
        label: '24'
      }, {
        value: '26',
        label: '26'
      }, {
        value: '28',
        label: '28'
      }, {
        value: '30',
        label: '30'
      }, {
        value: '32',
        label: '32'
      }, {
        value: '34',
        label: '34'
      }, {
        value: '36',
        label: '36'
      }, {
        value: '38',
        label: '38'
      }, {
        value: '40',
        label: '40'
      }, {
        value: '42',
        label: '42'
      }, {
        value: '44',
        label: '44'
      }, {
        value: '46',
        label: '46'
      }, {
        value: '48',
        label: '48'
      }, {
        value: '50',
        label: '50'
      }, {
        value: '52',
        label: '52'
      }, {
        value: '54',
        label: '54'
      }, {
        value: '56',
        label: '56'
      }, {
        value: '58',
        label: '58'
      }, {
        value: '60',
        label: '60'
      }, {
        value: '62',
        label: '62'
      }, {
        value: '64',
        label: '64'
      }, {
        value: '66',
        label: '66'
      }, {
        value: '68',
        label: '68'
      }, {
        value: '70',
        label: '70'
      }, {
        value: '72',
        label: '72'
      }, {
        value: '74',
        label: '74'
      }, {
        value: '76',
        label: '76'
      }, {
        value: '78',
        label: '78'
      }, {
        value: '80',
        label: '80'
      }, {
        value: '82',
        label: '82'
      }, {
        value: '84',
        label: '84'
      }, {
        value: '86',
        label: '86'
      }, {
        value: '88',
        label: '88'
      }, {
        value: '90',
        label: '90'
      }, {
        value: '92',
        label: '92'
      }, {
        value: '94',
        label: '94'
      }, {
        value: '96',
        label: '96'
      }, {
        value: '98',
        label: '98'
      }, {
        value: '100',
        label: '100'
      }, {
        value: '102',
        label: '102'
      }, {
        value: '104',
        label: '104'
      }, {
        value: '106',
        label: '106'
      }, {
        value: '108',
        label: '108'
      }, {
        value: '110',
        label: '110'
      }, {
        value: '112',
        label: '112'
      }, {
        value: '114',
        label: '114'
      }, {
        value: '116',
        label: '116'
      }, {
        value: '118',
        label: '118'
      }, {
        value: '120',
        label: '120'
      }, {
        value: '122',
        label: '122'
      }, {
        value: '124',
        label: '124'
      }, {
        value: '126',
        label: '126'
      }, {
        value: '128',
        label: '128'
      }, {
        value: '130',
        label: '130'
      }, {
        value: '132',
        label: '132'
      }, {
        value: '134',
        label: '134'
      }, {
        value: '136',
        label: '136'
      }, {
        value: '138',
        label: '138'
      }, {
        value: '140',
        label: '140'
      }, {
        value: '142',
        label: '142'
      }, {
        value: '144',
        label: '144'
      }, {
        value: '146',
        label: '146'
      }, {
        value: '148',
        label: '148'
      }, {
        value: '150',
        label: '150'
      }, {
        value: '152',
        label: '152'
      }, {
        value: '154',
        label: '154'
      }, {
        value: '156',
        label: '156'
      }, {
        value: '158',
        label: '158'
      }, {
        value: '160',
        label: '160'
      }, {
        value: '162',
        label: '162'
      }, {
        value: '164',
        label: '164'
      }, {
        value: '166',
        label: '166'
      }, {
        value: '168',
        label: '168'
      }, {
        value: '170',
        label: '170'
      }, {
        value: '172',
        label: '172'
      }, {
        value: '174',
        label: '174'
      }, {
        value: '176',
        label: '176'
      }, {
        value: '178',
        label: '178'
      }, {
        value: '180',
        label: '180'
      }, {
        value: '182',
        label: '182'
      }, {
        value: '184',
        label: '184'
      }, {
        value: '186',
        label: '186'
      }, {
        value: '188',
        label: '188'
      }, {
        value: '190',
        label: '190'
      }, {
        value: '192',
        label: '192'
      }, {
        value: '194',
        label: '194'
      }, {
        value: '196',
        label: '196'
      }, {
        value: '198',
        label: '198'
      }, {
        value: '200',
        label: '200'
      }, {
        value: '202',
        label: '202'
      }, {
        value: '204',
        label: '204'
      }, {
        value: '206',
        label: '206'
      }, {
        value: '208',
        label: '208'
      }, {
        value: '210',
        label: '210'
      }, {
        value: '212',
        label: '212'
      }, {
        value: '214',
        label: '214'
      }, {
        value: '216',
        label: '216'
      }, {
        value: '218',
        label: '218'
      }, {
        value: '220',
        label: '220'
      }, {
        value: '222',
        label: '222'
      }, {
        value: '224',
        label: '224'
      }, {
        value: '226',
        label: '226'
      }, {
        value: '228',
        label: '228'
      }, {
        value: '230',
        label: '230'
      }, {
        value: '232',
        label: '232'
      }, {
        value: '234',
        label: '234'
      }, {
        value: '236',
        label: '236'
      }, {
        value: '238',
        label: '238'
      }, {
        value: '240',
        label: '240'
      }, {
        value: '242',
        label: '242'
      }, {
        value: '244',
        label: '244'
      }, {
        value: '246',
        label: '246'
      }, {
        value: '248',
        label: '248'
      }, {
        value: '250',
        label: '250'
      }, {
        value: '252',
        label: '252'
      }];
    }.property(),

    /**
     * The options for the V-Stall dropdown
     */
    airborneVStallOptions: function () {
      return [{
        value: '0',
        label: '0'
      }, {
        value: '2',
        label: '2'
      }, {
        value: '4',
        label: '4'
      }, {
        value: '6',
        label: '6'
      }, {
        value: '8',
        label: '8'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '12',
        label: '12'
      }, {
        value: '14',
        label: '14'
      }, {
        value: '16',
        label: '16'
      }, {
        value: '18',
        label: '18'
      }, {
        value: '20',
        label: '20'
      }, {
        value: '22',
        label: '22'
      }, {
        value: '24',
        label: '24'
      }, {
        value: '26',
        label: '26'
      }, {
        value: '28',
        label: '28'
      }, {
        value: '30',
        label: '30'
      }, {
        value: '32',
        label: '32'
      }, {
        value: '34',
        label: '34'
      }, {
        value: '36',
        label: '36'
      }, {
        value: '38',
        label: '38'
      }, {
        value: '40',
        label: '40'
      }, {
        value: '42',
        label: '42'
      }, {
        value: '44',
        label: '44'
      }, {
        value: '46',
        label: '46'
      }, {
        value: '48',
        label: '48'
      }, {
        value: '50',
        label: '50'
      }, {
        value: '52',
        label: '52'
      }, {
        value: '54',
        label: '54'
      }, {
        value: '56',
        label: '56'
      }, {
        value: '58',
        label: '58'
      }, {
        value: '60',
        label: '60'
      }, {
        value: '62',
        label: '62'
      }, {
        value: '64',
        label: '64'
      }, {
        value: '66',
        label: '66'
      }, {
        value: '68',
        label: '68'
      }, {
        value: '70',
        label: '70'
      }, {
        value: '72',
        label: '72'
      }, {
        value: '74',
        label: '74'
      }, {
        value: '76',
        label: '76'
      }, {
        value: '78',
        label: '78'
      }, {
        value: '80',
        label: '80'
      }, {
        value: '82',
        label: '82'
      }, {
        value: '84',
        label: '84'
      }, {
        value: '86',
        label: '86'
      }, {
        value: '88',
        label: '88'
      }, {
        value: '90',
        label: '90'
      }, {
        value: '92',
        label: '92'
      }, {
        value: '94',
        label: '94'
      }, {
        value: '96',
        label: '96'
      }, {
        value: '98',
        label: '98'
      }, {
        value: '100',
        label: '100'
      }, {
        value: '102',
        label: '102'
      }, {
        value: '104',
        label: '104'
      }, {
        value: '106',
        label: '106'
      }, {
        value: '108',
        label: '108'
      }, {
        value: '110',
        label: '110'
      }, {
        value: '112',
        label: '112'
      }, {
        value: '114',
        label: '114'
      }, {
        value: '116',
        label: '116'
      }, {
        value: '118',
        label: '118'
      }, {
        value: '120',
        label: '120'
      }, {
        value: '122',
        label: '122'
      }, {
        value: '124',
        label: '124'
      }];
    }.property(),
    actions: {
      airborneVRotateChange: function airborneVRotateChange(selected) {
        this.set('airborneVRotate.value', selected.value);
      },
      airborneVStallChange: function airborneVStallChange(selected) {
        this.set('airborneVStall.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('airborneVRotate').rollbackAttributes();
          this.get('airborneVStall').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});