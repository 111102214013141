define("sky-router-3/services/cordova-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Interfaces with the cordova settings client to store and retreive settings
   * @see https://github.com/apla/me.apla.cordova.app-preferences
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    _preferences: null,
    _isAvailable: false,
    init: function init() {
      document.addEventListener('deviceready', Ember.run.bind(this, this.setup), false);

      this._super.apply(this, arguments);
    },

    /**
     * Initialize the state of this service once the cordova plugins have all been loaded.
     */
    setup: function setup() {
      if (window.plugins && window.plugins.appPreferences) {
        this._preferences = window.plugins.appPreferences;
        this._isAvailable = true;
        this.trigger('ready', this);
      }
    },

    /**
     * Adds a key to the store
     * NOTE: If accessing before app is fully booted you need to do so after
     * the 'ready' event has been fired.
     */
    add: function add(key, value) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this._isAvailable) {
          reject();
        }

        _this._preferences.store(resolve, reject, key, value);
      });
    },

    /**
     * Gets a key from the store
     * NOTE: If accessing before app is fully booted you need to do so after
     * the 'ready' event has been fired.
     */
    fetch: function fetch(key) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this2._isAvailable) {
          reject();
        }

        _this2._preferences.fetch(resolve, reject, key);
      });
    },

    /**
     * Removes a key from the store
     * NOTE: If accessing before app is fully booted you need to do so after
     * the 'ready' event has been fired.
     */
    remove: function remove(key) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this3._isAvailable) {
          reject();
        }

        _this3._preferences.remove(resolve, reject, key);
      });
    },

    /**
     * Shows the system preference panes
     * NOTE: If accessing before app is fully booted you need to do so after
     * the 'ready' event has been fired.
     */
    show: function show() {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this4._isAvailable) {
          reject();
        }

        _this4._preferences.show(resolve, reject);
      });
    },

    /**
     * Checks the host setting and sees if a redirect is needed
     */
    checkHostSetting: function checkHostSetting() {
      this.fetch('host').then(function (h) {
        var host = h || "skyrouter.com";

        if (/^[a-zA-Z]*\.?skyrouter.com$/.test(host) && host !== window.location.host) {
          window.location = "https://".concat(host);
        }
      });
    }
  });

  _exports.default = _default;
});