define("sky-router-3/pods/components/portal/track/map/overlays/mgrs-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sW1f9jPS",
    "block": "{\"symbols\":[\"label\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"position\",\"map\"],[[32,1,[\"latLng\"]],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,5,[32,1,[\"style\"]]],[12],[1,[32,1,[\"content\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"map\",\"portal/track/map/static-label\",\"gzdLabelFeatures\",\"-track-array\",\"each\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/overlays/mgrs-grid/template.hbs"
  });

  _exports.default = _default;
});