define("sky-router-3/pods/components/portal/track/map/overlays/noaa-airep-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FrWjMN7l",
    "block": "{\"symbols\":[\"opt\",\"opt\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"overlay.airep.hazardType\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],null,[[\"options\",\"selected\",\"onChange\"],[[35,4],[35,3],[30,[36,2],[[32,0],\"setTypeSelection\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,2,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"overlay.airep.reportType\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],null,[[\"options\",\"selected\",\"onChange\"],[[35,7],[35,6],[30,[36,2],[[32,0],\"setaTypeSelected\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"overlay.airep.maxAge\"],null]]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,9],null,[[\"class\",\"value\",\"type\",\"min\",\"max\",\"step\"],[\"form-control\",[35,8],\"number\",\"1\",\"24\",\"0.5\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"action\",\"typeSelected\",\"typeOptions\",\"input-select\",\"aTypeSelected\",\"aTypeOptions\",\"age\",\"input\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/overlays/noaa-airep-settings/template.hbs"
  });

  _exports.default = _default;
});