define("sky-router-3/pods/components/portal/manage/device-parameters/types/he5100/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,

    /**
     * Alias'd settings that are easier to get at.
     */
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_time_interval_reporting_enabled'),
    timeReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'gsm_time_interval_reporting_seconds'),
    distanceReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_distance_reporting_enabled'),
    distanceReportingMeter: (0, _computedFindBy.default)('settings', 'key', 'gsm_distance_reporting_meters'),
    gsmRoamingEnabled: (0, _computedFindBy.default)('settings', 'key', 'gsm_roaming_enabled'),
    gsmPollingSeconds: (0, _computedFindBy.default)('settings', 'key', 'gsm_messagepolling_seconds'),
    eventReportingOnlyEnabled: (0, _computedFindBy.default)('settings', 'key', 'event_reporting_only'),
    quickReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'quick_position_seconds'),
    quickReportingLimit: (0, _computedFindBy.default)('settings', 'key', 'quick_position_limit'),
    speedEnabled: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_enabled'),
    speedIntervalSeconds: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_interval_seconds'),
    speedTransitionTime: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_transition_seconds'),
    speedLimitKph: (0, _computedFindBy.default)('settings', 'key', 'speed_reporting_limit_kph'),
    startMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'start_movement_enabled'),
    startMovementDelaySeconds: (0, _computedFindBy.default)('settings', 'key', 'start_movement_delay_seconds'),
    stopMovementEnabled: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_enabled'),
    stopMovementDelaySeconds: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_delay_seconds'),
    stopMovementReportSeconds: (0, _computedFindBy.default)('settings', 'key', 'stop_movement_report_seconds'),
    obdOdometerEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_odometer_enabled'),
    obdFueldEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_fuel_enabled'),
    obdDtcEnabled: (0, _computedFindBy.default)('settings', 'key', 'obd_dtc_enabled')
  });

  _exports.default = _default;
});