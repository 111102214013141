define("sky-router-3/pods/portal/operations/flightplans/index/controller", ["exports", "moment", "sky-router-3/utils/filter-by-in-array"], function (_exports, _moment, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    queryParams: ['skip', 'take', 'sortProperty', 'sortDesc', 'search', 'filterAssetIds', 'filterStatus', 'oldestDate', 'oldestDateEnabled', 'soonestDate', 'soonestDateEnabled'],
    sortProperty: 'startDate',
    sortDesc: true,
    search: null,
    skip: 0,
    take: 10,
    takeSelections: [10, 20, 50, 100, 200, 300],

    /**
     * Powers the assets filter
     * @type {Array}
     */
    filterAssetIds: [],
    filterAssetsSelected: (0, _filterByInArray.default)('model.assets', 'id', 'filterAssetIds'),

    /**
     * Powers the filght plan status filter
     * @type {Array}
     */
    filterStatus: null,
    filterStatusSelected: function () {
      return this.get('model.flightPlanStatuses').findBy('id', this.get('filterStatus'));
    }.property('model.flightPlanStatuses.[]', 'filterStatus'),

    /**
     * Powers the oldest date filter
     */
    oldestDate: null,
    oldestDateEnabled: false,
    oldestDateObject: Ember.computed('oldestDate', function () {
      if (this.oldestDate) {
        return new Date(parseInt(this.oldestDate));
      }

      return new Date();
    }),

    /**
     * Powers the oldest date filter
     */
    soonestDate: null,
    soonestDateEnabled: false,
    soonestDateObject: Ember.computed('soonestDate', function () {
      if (this.soonestDate) {
        return new Date(parseInt(this.soonestDate));
      }

      return new Date();
    }),
    filterSet: function () {
      return this.get('filterStatus') || this.get('filterAssetIds.length') || this.get('oldestDateEnabled') || this.get('soonestDateEnabled');
    }.property('filterAssetIds', 'filterStatus', 'oldestDateEnabled', 'soonestDateEnabled'),
    actions: {
      filterAssetsChanged: function filterAssetsChanged(selection) {
        this.set('filterAssetIds', selection.mapBy('id'));
      },
      filterStatusChanged: function filterStatusChanged(selection) {
        this.set('filterStatus', selection ? selection.get('id') : null);
      },
      soonestDateChanged: function soonestDateChanged(date) {
        this.set('soonestDate', date.getTime());
      },
      oldestDateChanged: function oldestDateChanged(date) {
        this.set('oldestDate', date.getTime());
      },
      changeSort: function changeSort() {
        this.toggleProperty('sortDesc');
      },
      resetFilter: function resetFilter() {
        this.set('filterStatus', null);
        this.set('filterAssetIds', []);
        this.set('oldestDateEnabled', false);
        this.set('soonestDateEnabled', false);
        this.set('soonestDate', null);
        this.set('oldestDate', null);
      },
      loadPrevousPage: function loadPrevousPage() {
        this.set('skip', Math.max(this.get('skip') - this.get('take'), 0));
      },
      loadNextPage: function loadNextPage() {
        this.set('skip', this.get('skip') + this.get('model.flightPlans.length'));
      },
      countChanged: function countChanged(val) {
        this.set('skip', 0);
        this.set('take', val);
      }
    }
  });

  _exports.default = _default;
});