define("sky-router-3/pods/portal/manage/device-profiles/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        sets: this.store.findAll('asset-profile-set'),
        types: this.store.findAll('asset-profile-set-type')
      });
    },
    actions: {
      deleteSet: function deleteSet(set) {
        var _this = this;

        if (confirm(this.intl.t('profiles.deleteConfirm', {
          name: set.get('name')
        }))) {
          set.destroyRecord().then(function () {
            _this.notifications.warning(_this.intl.t('profiles.deleteSuccess'), {
              clearDuration: 15000
            });
          }).catch(function () {
            _this.notifications.error(_this.intl.t('profiles.deleteFail'));
          });
        }
      }
    }
  });

  _exports.default = _default;
});