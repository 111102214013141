define("sky-router-3/pods/components/position-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rzqT6Jb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[30,[36,6],[[35,5,[\"id\"]],[35,4,[\"id\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"type\",\"data\",\"options\"],[\"line\",[35,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"altitudeGraph.selectAsset\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"options\",\"data\",\"ember-chart\",\"unit\",\"asset\",\"and\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/position-graph/template.hbs"
  });

  _exports.default = _default;
});