define("sky-router-3/helpers/html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.html = html;
  _exports.default = void 0;

  /**
   * Takes in an HTML string and outputs it as a safe string
   * This is usefull for sending HTML to ember-intl without creating the translation
   * in a .js file.
   */
  function html(params
  /*, hash*/
  ) {
    return params[0].htmlSafe();
  }

  var _default = Ember.Helper.helper(html);

  _exports.default = _default;
});