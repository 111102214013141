define("sky-router-3/pods/components/portal/manage/device-compound-rules/condition/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'condition.type': (0, _emberCpValidations.validator)('presence', true),
    'condition.typeAttribute': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * @type {sky-router-3/models/asset-compound-rule-revision-condition}
     */
    condition: null,

    /**
     * @type {Array<sky-router-3/models/asset-compound-rule-condition-type>}
     */
    conditionTypes: null,
    actions: {
      setConditionType: function setConditionType(condition, option) {
        condition.set('type', option);
        condition.set('typeAttribute', null);
      },
      setConditionAttributeType: function setConditionAttributeType(condition, option) {
        condition.set('typeAttribute', option);
        condition.set('value1', option.get('value1DefaultValue'));
        condition.set('value2', option.get('value2DefaultValue'));
      }
    }
  });

  _exports.default = _default;
});