define("sky-router-3/models/alert-definition-subject-tag", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The alert-definition-subject-tag is a label / value combo that is used as key
   * value replacement in the subject line of alert definition messages. When the
   * message is generated the subject is scanned for these tags and the tag is then
   * replaced with information about the alert.
   */
  var _default = _model.default.extend({
    /**
     * Holds the display label for this tag
     */
    label: (0, _model.attr)('string'),

    /**
     * Holds the value that should be saved into the subject of an alert definition
     */
    value: (0, _model.attr)('string')
  });

  _exports.default = _default;
});