define("sky-router-3/pods/components/portal/manage/device-parameters/types/d2000m/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,

    /**
     * Alias'd settings that are easier to get at.
     */
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_enabled'),
    timeReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_seconds'),
    distanceReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_enabled'),
    distanceReportingFeet: (0, _computedFindBy.default)('settings', 'key', 'distance_reporting_feet'),
    quickReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'quick_position_seconds'),
    quickReportingLimit: (0, _computedFindBy.default)('settings', 'key', 'quick_position_limit'),
    serial1Enabled: (0, _computedFindBy.default)('settings', 'key', 'serial_1_enabled'),
    serial1Bits: (0, _computedFindBy.default)('settings', 'key', 'serial_1_bits'),
    serial1Parity: (0, _computedFindBy.default)('settings', 'key', 'serial_1_parity'),
    serial1Baudrate: (0, _computedFindBy.default)('settings', 'key', 'serial_1_baudrate'),
    serial1TransmitSeconds: (0, _computedFindBy.default)('settings', 'key', 'serial_1_transmit_interval_seconds'),
    serial1Command: (0, _computedFindBy.default)('settings', 'key', 'serial_1_command'),
    speedLimitEnabled: (0, _computedFindBy.default)('settings', 'key', 'speed_limit_enabled'),
    speedLimitKph: (0, _computedFindBy.default)('settings', 'key', 'speed_limit_kph'),
    perimeterRangeEnabled: (0, _computedFindBy.default)('settings', 'key', 'perimeter_range_reporting_enabled'),
    perimeterRangeInterval: (0, _computedFindBy.default)('settings', 'key', 'perimeter_range_reporting_interval'),
    perimeterRangeFeet: (0, _computedFindBy.default)('settings', 'key', 'perimeter_range_reporting_feet')
  });

  _exports.default = _default;
});