define("sky-router-3/pods/components/portal/track/map/marker/marker-breadcrumb-circle/component", ["exports", "sky-router-3/mixins/google-marker-auto-hide", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleMarkerAutoHide, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is responsible for creating the circle around the breadcrumb
   * object if it is necessary. We have to do it this way because Google maps
   * doesn't allow for complex symbols without using a custom overlay.
   *
   * This component does nothing else than display a circle marker image.
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, _googleMarkerAutoHide.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('marker'),
    googlePropertyMap: [{
      property: 'position',
      setFunc: 'setPosition'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'icon',
      setFunc: 'setIcon'
    }],

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * The instance of the Google maps marker that defines this event
     * @type {google.maps.Marker}
     */
    marker: null,

    /**
     * Event that governs the display of this marker
     */
    event: null,

    /**
     * This generates the circle portion of the breadcrumb icon.
     */
    icon: function () {
      return {
        path: 'M22.7,14.6a7.3,7.3,0,1,1-7.3-7.3A7.3,7.3,0,0,1,22.7,14.6Z',
        anchor: new google.maps.Point(15, 15),
        fillColor: this.get('event.type.color'),
        fillOpacity: 1,
        strokeColor: '#000000',
        strokeOpacity: 0.5
      };
    }.property('event.type'),

    /**
     * Sets the markers position based on the event point
     */
    position: function () {
      return _latLngConverter.default.modelToGoogleLatLng(this.get('event'));
    }.property('event.latitudeMilliarcSeconds', 'event.longitudeMilliarcSeconds'),

    /**
     * Initializes the marker for this asset.
     */
    init: function init() {
      this.set('marker', new google.maps.Marker({
        zIndex: 499
      }));

      this._super.apply(this, arguments);
    },

    /**
     * Removes the marker from the map and destroys it.
     */
    willDestroyElement: function willDestroyElement() {
      this.marker.setMap(null);
    }
  });

  _exports.default = _default;
});