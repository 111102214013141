define("sky-router-3/instance-initializers/notification-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /**
   * This executes when the app is booted to change the default clear duration
   * for system notification to a little longer time span.
   */
  function initialize(app) {
    ['controller', 'component', 'route'].forEach(function (injectionTarget) {
      app.inject(injectionTarget, 'notifications', 'service:notifications');
    });
  }

  var _default = {
    name: 'notification-setup',
    initialize: initialize
  };
  _exports.default = _default;
});