define("sky-router-3/pods/portal/manage/overlays/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['editableOnly'],
    editableOnly: false,
    sortBy: ['editable:desc', 'name'],
    sorted: Ember.computed.sort('model.overlays', 'sortBy'),
    intl: Ember.inject.service(),
    actions: {
      editGroup: function editGroup(group) {
        this.set('editGroup', group);
      },
      saveGroupEdit: function saveGroupEdit() {
        this.editGroup.save();
        this.set('editGroup', null);
      },
      cancelGroupEdit: function cancelGroupEdit() {
        this.editGroup.rollbackAttributes();
        this.set('editGroup', null);
      },
      createGroup: function createGroup(name, description) {
        var _this = this;

        this.set('newGroupTitle', null);
        this.set('newGroupDescription', null);
        this.store.createRecord('overlay-group', {
          name: name,
          description: description
        }).save().catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      },
      deleteGroup: function deleteGroup(group) {
        var _this2 = this;

        group.destroyRecord().catch(function () {
          _this2.notifications.error(_this2.intl.t('misc.failedOperation'));
        });
      }
    }
  });

  _exports.default = _default;
});