define("sky-router-3/pods/portal/manage/data-feeds/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Bool for determining if models are being saved
     */
    feedsSaving: Ember.computed.or('model.dataFeedSelections.isSaving', 'model.extDataFeedSelections.isSaving'),
    actions: {
      /**
       * The action result of a user selecting a toggle all checkbox. Every device will get this data feed checked or
       * unchecked, depending on the value result parameter
       * @param {number}  'id'                  The data share id
       * @param {Boolean} 'isExternalDataFeed'  A flag that represents an external data feed or not
       * @param {Boolean} 'checked'             A flag that represents the checkbox being changed is checked or not
       */
      addAssetsToFeeds: function addAssetsToFeeds(id, isExternalDataFeed, checked) {
        var assets = this.get('model.assets').mapBy('imei'); // Get the appropriate feed selection array from the model

        var selections = isExternalDataFeed ? this.get('model.extDataFeedSelections') : this.get('model.dataFeedSelections'); // Get the assets for the paramter data feed id

        var selectedFeedAssets = selections.findBy('id', id).get('assets');

        if (checked) {
          // Add every asset to the selected feed assets for this data feed
          selectedFeedAssets.addObjects(assets);
        } else {
          // Remove every asset from the selected feed assets for this data feed
          selectedFeedAssets.removeObjects(assets);
        }
      },

      /**
       * The action result of a user selecting a single data feed checkbox.
       * @param {Array}   'assets'              The array of user assets
       * @param {Object}  'modifiedAsset'       The asset that should be added or removed from the data feed list
       * @param {Boolean} 'checked'             A flag that represents the checkbox being changed is checked or not
       */
      addSingleAssetToFeed: function addSingleAssetToFeed(assets, modifiedAsset, checked) {
        if (checked) {
          assets.addObject(modifiedAsset);
        } else {
          assets.removeObject(modifiedAsset);
        }
      }
    }
  });

  _exports.default = _default;
});