define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-8/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    operations: [{
      label: '>',
      id: 0
    }, {
      label: '<',
      id: 1
    }],
    speedUnits: null,
    speedUnitSelected: Ember.computed.oneWay('sessionAccount.user.speedOther'),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('model.relationalOperator') === 2) {
        this.set('model.relationalOperator', 0);
      }
    },
    init: function init() {
      var _this = this;

      this.get('store').findAll('unit-speed').then(function (u) {
        return _this.set('speedUnits', u);
      });

      this._super.apply(this, arguments);
    },
    actions: {
      setOperation: function setOperation(op) {
        this.set('model.relationalOperator', op.id);
      }
    }
  });

  _exports.default = _default;
});