define("sky-router-3/pods/portal/manage/geofences/assignment-by-geofence/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selected: [],
    options: Ember.computed('model.assets', 'model.geofence', 'model.geofence.assets.[]', function () {
      var _this = this;

      return this.model.assets.map(function (asset) {
        return {
          asset: asset,
          disabled: asset.geofences.isAny('geofence.id', _this.model.geofence.id)
        };
      });
    }),
    optionsSorted: Ember.computed.sort('options', 'optionsSortedBy'),
    optionsSortedBy: ['asset.name'],
    actions: {
      setSelected: function setSelected(newSelected) {
        if (this.geofenceCountLimitReached && newSelected.length > this.selected.length) {
          return;
        }

        this.set('selected', newSelected);
      },
      saveSelected: function saveSelected(geofence, selected) {
        this.send('createAssignments', selected.map(function (s) {
          return {
            asset: s.asset,
            geofence: geofence
          };
        }));
        this.transitionToRoute('portal.manage.geofences.assignment-by-geofence');
      }
    }
  });

  _exports.default = _default;
});