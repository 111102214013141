define("sky-router-3/models/form-template-email-attachment-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.emailAttachmentTypes = void 0;
  var emailAttachmentTypes = {
    pdf: '0',
    json: '1'
  };
  _exports.emailAttachmentTypes = emailAttachmentTypes;

  var _default = _model.default.extend({
    /**
     * The name of the status
     */
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });

  _exports.default = _default;
});