define("sky-router-3/pods/components/input-label/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component generates a form label that will attach the
   * proper 'for' attribute to the nearest 'input' element's id.
   *
   * Note: the forms makeup is expected to be static, this label
   * will not update if the input moves or changes id's.
   *
   * @example {{#input-label}}Label{{/input-label}}
   */
  var _default = Ember.Component.extend({
    /**
     * Component attributes
     */
    tagName: 'label',

    /**
     * Looks for the closest input to this element and then sets
     * the id that this label is associated to, to that input.
     * It also looks for children to attach to.
     * @function
     */
    didInsertElement: function didInsertElement() {
      var id = (0, _jquery.default)(this.element).siblings('input:first, textarea:first').attr('id');

      if (!id) {
        id = (0, _jquery.default)(this.element).find('input:first, textarea:first').attr('id');
      }

      (0, _jquery.default)(this.element).attr('for', id);
    }
  });

  _exports.default = _default;
});