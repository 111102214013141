define("sky-router-3/pods/components/portal/track/map/asset-tag/component", ["exports", "sky-router-3/models/asset-category", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter", "sky-router-3/pods/components/portal/track/map/lib/asset-tag", "sky-router-3/utils/color-functions"], function (_exports, _assetCategory, _googleObjectMapping, _latLngConverter, _assetTag, _colorFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This creates the bubble of information that often is beside assets.
   * It uses the `google.maps.OverlayView` plugin to do its work.
   *
   * The bubble of information updates every time the asset has a new
   * position report to display the new information.
   *
   * The bubble also takes on the color of the asset.
   *
   * The bubble also should avoid collisions with other bubbles if avoidance is enabled
   *
   * The bubble also shows a line from the bubble to the asset
   *
   * @see https://developers.google.com/maps/documentation/javascript/reference#OverlayView
   *
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    classNames: ['asset-tag'],
    attributeBindings: ['style'],

    /**
     * Contains the mappings between properties that exist in the map object and properties
     * that exist in the ember object that need to be mapped together. This is used to initialize the property mixin
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'position',
      setFunc: 'setPosition'
    }, {
      property: 'avoidance',
      setFunc: 'setAvoidance'
    }],

    /**
     * This is used to initialize the property mixin.
     */
    googlePropertyObject: Ember.computed.alias('_instance'),

    /**
     * Incoming map object
     */
    map: null,

    /**
     * Incoming asset that this object is associated with
     */
    asset: null,

    /**
     * Indicates if the tags should avoid one another or not.
     * Avoidance is very CPU intensive.
     * @type {Boolean}
     */
    avoidance: false,
    //#region TAG COLORING

    /**
     * The background color of the tag div. The input color must be in 6 character
     * hex format. A transparency is added onto the color
     */
    bgColor: function () {
      return this.get('asset.color');
    }.property('asset.color'),

    /**
     * The text color of the tag which should
     * be opposite of the background color.
     */
    textColor: function () {
      return (0, _colorFunctions.offsetTextColor)(this.get('asset.color'));
    }.property('asset.color'),

    /**
     * This is the style of the component that is bound to the attribute
     * @return {SafeString}
     */
    style: function () {
      var text = this.get('textColor');
      var bg = this.get('bgColor');
      return "background-color: ".concat(bg, "; color: ").concat(text, "; border-color: ").concat(text).htmlSafe();
    }.property('textColor', 'bgColor'),
    //#endregion

    /**
     * gets the position based on the assets current location
     */
    position: function () {
      return _latLngConverter.default.modelToGoogleLatLng(this.get('asset.positionLatest'));
    }.property('asset.positionLatest'),

    /**
     * Should the alititude property be shown
     */
    showAltitude: function () {
      return [_assetCategory.ASSET_CATEGORY.air, _assetCategory.ASSET_CATEGORY.other].includes(this.get('asset.category.id'));
    }.property('asset.category.id'),
    showSpeed: Ember.computed.gt('asset.positionLatest.speedCms', 0),

    /**
     * Holds an instance of the tag class
     */
    _instance: null,

    /**
     * Initializes the tag class defined below
     * so that its ready to be projected on the map
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_instance', new _assetTag.default({
        offset: new google.maps.Size(20, -20)
      }));
    },

    /**
     * Sets the tag content to this components element
     */
    didInsertElement: function didInsertElement() {
      this.get('_instance').setContent(this.get('element'));
    },

    /**
     * Destroys the tag class instance
     */
    willDestroyElement: function willDestroyElement() {
      this._instance.setMap(null);
    }
  });

  _exports.default = _default;
});