define("sky-router-3/helpers/or-strict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orStrict = orStrict;
  _exports.default = void 0;

  /**
   * Performs a logic or but allowed 0 to be a valid value;
   * @example (or 0 123), (or undefined 123)
   */
  function orStrict(params
  /*, hash*/
  ) {
    return params[0] !== undefined && params[0] !== null ? params[0] : params[1];
  }

  var _default = Ember.Helper.helper(orStrict);

  _exports.default = _default;
});