define("sky-router-3/pods/portal/manage/geofences/assignment-by-device/add/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        asset: this.store.findRecord('asset', params.asset_id),
        geofences: this.store.findAll('geofence')
      });
    },
    resetController: function resetController(controller) {
      controller.set('selected', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.geofences.assignment-by-device');
      }
    }
  });

  _exports.default = _default;
});