define("sky-router-3/pods/components/portal/track/map/overlays/noaa-metar-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zbSb94So",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"overlay.metar.stationDensity\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],null,[[\"options\",\"selected\",\"onChange\"],[[35,6],[35,5],[30,[36,4],[[32,0],\"setSelection\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"checked\"],[[35,0]]]]],[2,\" \"],[1,[30,[36,2],[\"overlay.metar.showVfrStations\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showVFR\",\"input-checkbox\",\"t\",\"input-label\",\"action\",\"selected\",\"options\",\"input-select\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/overlays/noaa-metar-settings/template.hbs"
  });

  _exports.default = _default;
});