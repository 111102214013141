define("sky-router-3/utils/geoconversiontools/utm-conversions", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants", "sky-router-3/utils/geoconversiontools/transmercator-conversions"], function (_exports, _geoconstants, _transmercatorConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var utmconversions = {
    getUTMDefaultValues: function getUTMDefaultValues() {
      return {
        UTM_a: 6378137.0,
        UTM_f: 1 / 298.257223563,
        UTM_Override: 0
      };
    },
    setUTMParameters: function setUTMParameters(a, f, utmoverride) {
      var utmparams = this.getUTMDefaultValues();
      var errors = [];

      if (a <= 0.0) {
        errors.push({
          code: 0x0080,
          desc: 'Semi-major axis less than or equal to zero'
        });
      }

      if (1 / f < 250 || 1 / f > 350) {
        errors.push({
          code: 0x0100,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (utmoverride < 0 || utmoverride > 60) {
        errors.push({
          code: 0x0040,
          desc: 'Zone outside of valid range (1 to 60) and within 1 of natural zone'
        });
      }

      if (errors.length == 0) {
        utmparams.UTM_a = a;
        utmparams.UMT_f = f;
        utmparams.UTM_Override = utmoverride;
      }

      return {
        parameters: utmparams,
        errorCodes: errors
      };
    },
    convertGeodeticToUTM: function convertGeodeticToUTM(latitude, longitude, utmparams) {
      var utmconstants = _geoconstants.default.getUTMConstants();

      var utmresult = {
        zone: '',
        hemisphere: '',
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];

      if (latitude < utmconstants.MIN_LAT || latitude > utmconstants.MAX_LAT) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-80.5 to 84.5 degrees)'
        });
      }

      if (longitude < -utmconstants.PI || longitude > utmconstants.PI) {
        errors.push({
          code: 0x0002,
          desc: 'Longitude outside of valid range (-180 to 360 degrees)'
        });
      }

      if (errors.length == 0) {
        if (latitude > -1.0e-9 && latitude < 0) {
          latitude = 0.0;
        }

        if (longitude < 0) {
          longitude += 2 * utmconstants.PI + 1.0e-10;
        }

        var lat_degrees = _geoconstants.default.getDegrees(latitude);

        var lon_degrees = _geoconstants.default.getDegrees(longitude);

        var temp_zone = 0;

        if (longitude < utmconstants.PI) {
          temp_zone = 31 + lon_degrees / 6.0;
        } else {
          temp_zone = lon_degrees / 6.0 - 29;
        }
        /* UTM special cases */


        if (lat_degrees > 55 && lat_degrees < 64 && lon_degrees > -1 && lon_degrees < 3) {
          temp_zone = 31;
        }

        if (lat_degrees > 55 && lat_degrees < 64 && lon_degrees > 2 && lon_degrees < 12) {
          temp_zone = 32;
        }

        if (lat_degrees > 71 && lon_degrees > -1 && lon_degrees < 9) {
          temp_zone = 31;
        }

        if (lat_degrees > 71 && lon_degrees > 8 && lon_degrees < 21) {
          temp_zone = 33;
        }

        if (lat_degrees > 71 && lon_degrees > 20 && lon_degrees < 33) {
          temp_zone = 35;
        }

        if (lat_degrees > 71 && lon_degrees > 32 && lon_degrees < 42) {
          temp_zone = 37;
        }

        if (utmparams.UTM_Override > 0) {
          if (temp_zone == 1 && utmparams.UTM_Override == 60 || temp_zone == 60 && utmparams.UTM_Override == 1) {
            temp_zone = utmparams.UTM_Override;
          } else if (lat_degrees > 71 && lon_degrees > -1 && lon_degrees < 42) {
            if (temp_zone - 2 <= utmparams.UTM_Override && utmparams.UTM_Override <= temp_zone + 2) {
              temp_zone = utmparams.UTM_Override;
            } else {
              errors.push({
                code: 0x0040,
                desc: 'Zone outside of valid range (1 to 60) and within 1 of natural zone'
              });
            }
          } else if (temp_zone - 1 <= utmparams.UTM_Override && utmparams.UTM_Override <= temp_zone + 1) {
            temp_zone = utmparams.UTM_Override;
          } else {
            errors.push({
              code: 0x0040,
              desc: 'Zone outside of valid range (1 to 60) and within 1 of natural zone'
            });
          }
        }

        if (errors.length == 0) {
          var origin_latitude = 0;
          var central_meridian = 0;
          var false_easting = 500000;
          var false_northing = 0;
          var scale = 0.9996;
          utmresult.zone = parseInt(temp_zone);

          if (utmresult.zone >= 31) {
            central_meridian = (6 * utmresult.zone - 183) * utmconstants.PI / 180.0;
          } else {
            central_meridian = (6 * utmresult.zone + 177) * utmconstants.PI / 180.0;
          }

          utmresult.hemisphere = 'N';

          if (latitude < 0) {
            false_northing = 10000000;
            utmresult.hemisphere = 'S';
          }

          var tmparamsresult = _transmercatorConversions.default.setTMParameters(utmparams.UTM_a, utmparams.UTM_f, origin_latitude, central_meridian, false_easting, false_northing, scale);

          if (tmparamsresult.errorCodes.length == 0) {
            var tmresult = _transmercatorConversions.default.convertGeodeticToTM(latitude, longitude, tmparamsresult.parameters);

            if (tmresult.errorCodes.length == 0) {
              utmresult.easting = tmresult.output.easting;
              utmresult.northing = tmresult.output.northing;
            } else if (tmresult.errorCodes.length == 1 && tmresult.errorCodes[0].code & 512) {
              utmresult.easting = tmresult.output.easting;
              utmresult.northing = tmresult.output.northing;
            } else {
              errors.push(tmresult.errorCodes);
            }
          } else {
            errors.push(tmparamsresult.errorCodes);
          }
        }
      }

      return {
        output: utmresult,
        parameters: utmparams,
        errorCodes: errors
      };
    },
    convertUTMToGeodetic: function convertUTMToGeodetic(zone, hemisphere, easting, northing, utmparams) {
      var utmconstants = _geoconstants.default.getUTMConstants();

      var utmresult = {
        latitude: 0e0,
        longitude: 0e0
      };
      var errors = [];
      var origin_latitude = 0;
      var central_meridian = 0;
      var false_easting = 500000;
      var false_northing = 0;
      var scale = 0.9996;

      if (zone < 1 || zone > 60) {
        errors.push({
          code: 0x0040,
          desc: 'Zone outside of valid range (1 to 60) and within 1 of natural zone'
        });
      }

      if (hemisphere != 'S' && hemisphere != 'N') {
        errors.push({
          code: 0x0020,
          desc: 'Invalid hemisphere ("N" or "S")'
        });
      }

      if (easting < utmconstants.MIN_EASTING || easting > utmconstants.MAX_EASTING) {
        errors.push({
          code: 0x0004,
          desc: 'Easting outside of valid range (100,000 to 900,000 meters)'
        });
      }

      if (northing < utmconstants.MIN_NORTHING || northing > utmconstants.MAX_NORTHING) {
        errors.push({
          code: 0x0008,
          desc: 'Northing outside of valid range (0 to 10,000,000 meters)'
        });
      }

      if (errors.length == 0) {
        if (zone >= 31) {
          central_meridian = (6 * zone - 183) * utmconstants.PI / 180.0
          /*+ 0.00000005*/
          ;
        } else {
          central_meridian = (6 * zone + 177) * utmconstants.PI / 180.0
          /*+ 0.00000005*/
          ;
        }

        if (hemisphere == 'S') {
          false_northing = 10000000;
        }

        var tmparamsresult = _transmercatorConversions.default.setTMParameters(utmparams.UTM_a, utmparams.UTM_f, origin_latitude, central_meridian, false_easting, false_northing, scale);

        if (tmparamsresult.errorCodes.length == 0) {
          var tmresult = _transmercatorConversions.default.convertTMToGeodetic(easting, northing, tmparamsresult.parameters);

          if (tmresult.errorCodes.length == 0) {
            utmresult.latitude = tmresult.output.latitude;
            utmresult.longitude = tmresult.output.longitude;
          } else if (tmresult.errorCodes.length == 1 && tmresult.errorCodes[0].code & 512) {
            utmresult.latitude = tmresult.output.latitude;
            utmresult.longitude = tmresult.output.longitude;
          } else {
            errors.push(tmresult.errorCodes);
          }
        } else {
          errors.push(tmresult.errorCodes);
        }
      }

      return {
        output: utmresult,
        parameters: utmparams,
        errorCodes: errors
      };
    }
  };
  var _default = utmconversions;
  _exports.default = _default;
});