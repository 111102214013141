define("sky-router-3/helpers/make-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper returns a value present which is a CSV of the supplied
   * @example {{make-csv selectedUsers 'id'}}
   */
  var _default = Ember.Helper.extend({
    /**
     * Actually performs the computation.
     */
    compute: function compute(params, hash) {
      // Validate
      if (!Ember.isArray(params[0])) {
        return undefined;
      } // Return


      if (params[0] && (hash.mapBy || params[1])) {
        return params[0].mapBy(hash.mapBy || params[1]).join(',');
      }

      return params[0].join(',');
    }
  });

  _exports.default = _default;
});