define("sky-router-3/models/alert-definition-message-tag", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The alert-definition-message-tag is a laeel/value combo that is used to turn
   * off or on parts of an alert message when the message is generated.
   */
  var _default = _model.default.extend({
    /**
     * Holds the label for this particular value. The label is dependent on
     * the culture that the user has selected upon login
     */
    label: (0, _model.attr)('string'),

    /**
     * Holds the value of the label.
     * In this case it holds a numeric bit flag that can be used to collapse
     * all of the message tags a alert-definition has into a singular field.
     * Its not really used in the UI.
     */
    value: (0, _model.attr)('number')
  });

  _exports.default = _default;
});