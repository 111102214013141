define("sky-router-3/models/broadcast-coverage-area", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Iridium BCA code
     */
    code: (0, _model.attr)('string'),

    /**
     * Region
     */
    region: (0, _model.attr)('string'),

    /**
     * Sub-Region
     */
    subRegion: (0, _model.attr)('string'),

    /**
     * Indicates if BCA is visible (favourite) in Burst Messenger
     */
    isVisible: (0, _model.attr)('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});