define("sky-router-3/pods/components/portal/manage/device-parameters/types/adt/component", ["exports", "sky-router-3/utils/computed-find-by", "sky-router-3/utils/computed-promise", "ember-cp-validations"], function (_exports, _computedFindBy, _computedPromise, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({// TODO: DO VALIDATIONS!
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,
    // didInsertElement() {
    //     this.set('standardReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('distressReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('altitudeReportingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('altitudeReportingSeconds', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('altitudeReportingFeet', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('maxPitch', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('maxRoll', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('maxSpeed', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('takeOffEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('takeOffSpeedCms', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('landingEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('landingSpeedCms', this.store.createRecord('asset-parameter-set-revision-setting'));
    //
    //     this.set('powerOnEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    //     this.set('powerOffEnabled', this.store.createRecord('asset-parameter-set-revision-setting'));
    // },

    /**
     * Alias'd settings that are easier to get at.
     */
    standardReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'adt_standard_reporting_seconds'),
    distressReportingSeconds: (0, _computedFindBy.default)('settings', 'key', 'adt_distress_reporting_seconds'),
    altitudeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'adt_altitude_reporting_enabled'),
    altitudeReportingCm: (0, _computedFindBy.default)('settings', 'key', 'adt_altitude_reporting_cm'),
    maxPitchClimb: (0, _computedFindBy.default)('settings', 'key', 'adt_max_pitch_climb_degrees'),
    maxPitchCruise: (0, _computedFindBy.default)('settings', 'key', 'adt_max_pitch_cruise_degrees'),
    maxPitchDescent: (0, _computedFindBy.default)('settings', 'key', 'adt_max_pitch_descent_degrees'),
    maxRollClimb: (0, _computedFindBy.default)('settings', 'key', 'adt_max_roll_climb_degrees'),
    maxRollCruise: (0, _computedFindBy.default)('settings', 'key', 'adt_max_roll_cruise_degrees'),
    maxRollDescent: (0, _computedFindBy.default)('settings', 'key', 'adt_max_roll_descent_degrees'),
    minClimbSpeed: (0, _computedFindBy.default)('settings', 'key', 'adt_min_climb_speed'),
    minCruiseSpeed: (0, _computedFindBy.default)('settings', 'key', 'adt_min_cruise_speed'),
    maxClimbSpeed: (0, _computedFindBy.default)('settings', 'key', 'adt_max_climb_speed'),
    maxCruiseSpeed: (0, _computedFindBy.default)('settings', 'key', 'adt_max_cruise_speed'),
    maxDescentSpeed: (0, _computedFindBy.default)('settings', 'key', 'adt_max_descent_speed'),
    maxDescentRate: (0, _computedFindBy.default)('settings', 'key', 'adt_max_descent_rate'),
    takeOffEnabled: (0, _computedFindBy.default)('settings', 'key', 'adt_detect_takeoff_enabled'),
    takeOffSpeedCms: (0, _computedFindBy.default)('settings', 'key', 'adt_detect_takeoff_cms'),
    landingEnabled: (0, _computedFindBy.default)('settings', 'key', 'adt_detect_landing_enabled'),
    landingSpeedCms: (0, _computedFindBy.default)('settings', 'key', 'adt_detect_landing_cms'),
    climbAltitude: (0, _computedFindBy.default)('settings', 'key', 'adt_climb_altitude'),
    cruiseAltitude: (0, _computedFindBy.default)('settings', 'key', 'adt_cruise_altitude'),
    descentAltitude: (0, _computedFindBy.default)('settings', 'key', 'adt_descent_altitude'),
    powerOnEnabled: (0, _computedFindBy.default)('settings', 'key', 'adt_poweron_enabled'),
    powerOffEnabled: (0, _computedFindBy.default)('settings', 'key', 'adt_poweroff_enabled'),
    speedUnits: (0, _computedPromise.default)(function () {
      return this.store.findAll('unit-speed');
    }, []),
    speedUnitSelected: Ember.computed('sessionAccount.user', 'speedUnits.[]', function () {
      return this.sessionAccount.user.get('speedAir');
    }),
    altitudeUnits: (0, _computedPromise.default)(function () {
      return this.store.findAll('unit-distance');
    }, []),
    altitudeUnitSelected: Ember.computed('altitudeUnits.[]', 'sessionAccount.user', function () {
      return this.sessionAccount.user.get('altitude');
    }),
    // Standard reporting
    standardReportingSelected: Ember.computed('standardReportingOptions', 'standardReportingSeconds.value', function () {
      return this.standardReportingOptions.findBy('value', this.standardReportingSeconds.get('value'));
    }),
    standardReportingOptions: Ember.computed(function () {
      return [{
        value: '120',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '480',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: this.intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }];
    }),
    // Distress reporting
    distressReportingSelected: Ember.computed('distressReportingOptions', 'distressReportingSeconds.value', function () {
      return this.distressReportingOptions.findBy('value', this.distressReportingSeconds.get('value'));
    }),
    distressReportingOptions: Ember.computed(function () {
      return [{
        value: '30',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '45',
        label: this.intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: '60',
        label: this.intl.t('parameter.misc.countMinute')
      }];
    }),
    actions: {
      reportingRateChanged: function reportingRateChanged(property, selected) {
        this.set(property, selected.value);
      }
    }
  });

  _exports.default = _default;
});