define("sky-router-3/models/nortac-address-book-entry", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    label: (0, _emberCpValidations.validator)('presence', true),
    value: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _fragment.default.extend(VALIDATIONS, {
    type: (0, _model.attr)('string'),
    label: (0, _model.attr)('string'),
    value: (0, _model.attr)('string')
  });

  _exports.default = _default;
});