define("sky-router-3/pods/portal/manage/geofences/edit/points/controller", ["exports", "sky-router-3/utils/lat-lng-converter"], function (_exports, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * What the `orderdByPoints` is ordered by
     */
    orderedPointsBy: ['ordinal:asc'],

    /**
     * Sorts the geofence points into the order they are represented
     * on the map. Which is indicated by the `ordinal` property
     */
    orderedPoints: Ember.computed.sort('model.geofence.points', 'orderedPointsBy'),
    actions: {
      updateGeofencePoint: function updateGeofencePoint(point, isLat, milliarc) {
        var degrees = _latLngConverter.default.milliArcSecondsToDecimalDeg(milliarc);

        if (isLat) point.set('latitudeDegree', degrees);else point.set('longitudeDegree', degrees);
      }
    }
  });

  _exports.default = _default;
});