define("sky-router-3/pods/public/login/activate/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    user: (0, _emberCpValidations.validator)('presence', true),
    email: (0, _emberCpValidations.validator)('email', {
      allowEmpty: true
    })
  }, {
    dependentKeys: ['model.intl.locale.firstObject']
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    api: Ember.inject.service(),
    user: '',
    email: '',

    /**
     * locale has to be consumed in order for depndent key in VALIDATIONS
     * to activate properly. This will cause validations to change based on locale.
     */
    intl: Ember.inject.service(),
    init: function init() {
      this.get('intl.locale.firstObject');

      this._super.apply(this, arguments);
    },

    /**
     * Indicates which message pannel to show to the user.
     */
    state: 'none',
    // Other states are 'success', 'fail',

    /**
     * Indicates that server is processing and the form should be blocked
     */
    isLoading: false,

    /**
     * Block the form because the user has not entered the correct information
     */
    blockSubmit: Ember.computed('user', 'email', function () {
      return !this.user || !this.email || this.validations.isInvalid;
    }),

    /**
     * This will reset all the controller properties back to their defaults
     * It is called by the route when the user navigates away from this page
     *
     */
    reset: function reset() {
      this.set('user', '');
      this.set('email', '');
      this.set('state', 'none');
    },
    actions: {
      submit: function submit() {
        var _this = this;

        this.set('isLoading', true);
        this.api.userResetPassword(this.user.trim(), this.email.trim()).then(function (result) {
          return _this.set('state', result.BooleanResult ? 'success' : 'fail');
        }).catch(function () {
          return _this.set('state', 'fail');
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});