define("sky-router-3/pods/components/portal/track/map/overlays/usgs-volcano-settings/component", ["exports", "sky-router-3/utils/computed-alias-not"], function (_exports, _computedAliasNot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     * Checkboxes
     */
    showRed: (0, _computedAliasNot.default)('settings.generic.hideRed'),
    showOrange: (0, _computedAliasNot.default)('settings.generic.hideOrange'),
    showYellow: (0, _computedAliasNot.default)('settings.generic.hideYellow'),
    showGreen: (0, _computedAliasNot.default)('settings.generic.hideGreen'),
    showWhite: (0, _computedAliasNot.default)('settings.generic.hideWhite')
  });

  _exports.default = _default;
});