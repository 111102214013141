define("sky-router-3/pods/components/portal/manage/device-parameters/types/extreme/component", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'emergencyPhone.value': [(0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^$|^00.*/,
      message: Ember.computed('model.intl.locale', function () {
        return this.get('model.intl').t('errors.startWithZero');
      })
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Indicates if the control will be readOnly
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Object holding revision settings that can have any number of
     * key and values
     * @type {Object}
     */
    settings: null,
    frequency: (0, _computedFindBy.default)('settings', 'key', 'extreme_reportingRate'),
    emergency: (0, _computedFindBy.default)('settings', 'key', 'extreme_emergencyDestination'),
    emergencyPhone: (0, _computedFindBy.default)('settings', 'key', 'extreme_emergencyPhone'),

    /**
     * Powers the frequency dropdown
     */
    frequencySelected: function () {
      return this.get('frequencyOptions').findBy('key', this.get('frequency.value'));
    }.property('frequency.value'),
    frequencyOptions: function () {
      var intl = this.get('intl');
      return [{
        key: '0',
        label: intl.t('parameter.extreme.report.noReport')
      }, {
        key: '1',
        label: intl.t('parameter.extreme.report.startup')
      }, {
        key: '2',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        key: '3',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        key: '4',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        key: '5',
        label: intl.t('parameter.misc.countHour')
      }, {
        key: '6',
        label: intl.t('parameter.misc.countHours', {
          count: 4
        })
      }, {
        key: '7',
        label: intl.t('parameter.misc.countHours', {
          count: 8
        })
      }, {
        key: '8',
        label: intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        key: '9',
        label: intl.t('parameter.misc.countHours', {
          count: 18
        })
      }, {
        key: '10',
        label: intl.t('parameter.misc.countDay')
      }, {
        key: '11',
        label: intl.t('parameter.misc.countDays', {
          count: 3
        })
      }, {
        key: '12',
        label: intl.t('parameter.misc.countWeek')
      }];
    }.property(),

    /**
     * Powers the emergency dropdown
     */
    emergencySelected: function () {
      return this.get('emergencyOptions').findBy('key', this.get('emergency.value'));
    }.property('emergency.value'),
    emergencyOptions: function () {
      var intl = this.get('intl');
      return [{
        key: '0',
        label: intl.t('parameter.extreme.emergency.actionMessageOnly')
      }, {
        key: '1',
        label: intl.t('parameter.extreme.emergency.actionCallOnly')
      }, {
        key: '2',
        label: intl.t('parameter.extreme.emergency.actionCallMessage')
      }];
    }.property(),
    actions: {
      frequencyChange: function frequencyChange(frequencyOption) {
        this.set('frequency.value', frequencyOption.key);
      },
      emergencyChange: function emergencyChange(emergencyOption) {
        this.set('emergency.value', emergencyOption.key);
      }
    }
  });

  _exports.default = _default;
});