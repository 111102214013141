define("sky-router-3/models/overlay", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model is responsible for holding implementation information for a
   * specific overlay.  This information is held in the `dataString` property
   * that can take any form. For the most part it is JSON though.
   */
  var _default = _model.default.extend({
    /**
     * The name of the overlay
     */
    name: (0, _model.attr)('string'),

    /**
     * The overlay group that this overlay belongs to
     */
    group: (0, _model.belongsTo)('overlay-group', {
      async: false
    }),

    /**
     * The type of overlay that this is
     */
    type: (0, _model.belongsTo)('overlay-type', {
      async: false
    }),

    /**
     * An string that holds information about the overlay.
     * The structure of the information is dependent on the type of the overlay.
     * See the overlay documentation for more information.
     */
    dataString: (0, _model.attr)('string'),

    /**
     * Alias property to the type for easier access
     */
    canDisplay: Ember.computed.alias('type.canDisplay'),
    description: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('user'),
    globalToOrg: (0, _model.attr)('boolean'),
    global: (0, _model.attr)('boolean'),
    editable: (0, _model.attr)('boolean'),
    clickable: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});