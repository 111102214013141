define("sky-router-3/models/trip-plan-leg-personnel", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "sky-router-3/utils/computed-promise"], function (_exports, _model, _fragment, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    personnelId: (0, _model.attr)('number'),
    personnel: (0, _computedPromise.default)('personnelId', {
      get: function get() {
        if (this.personnelId) {
          return this.store.findRecord('personnel', this.personnelId);
        }

        return null;
      },
      set: function set(key, value) {
        if (value) this.set('personnelId', value.get('id'));
        return value;
      }
    }, null),
    roleName: (0, _model.attr)('string'),
    roleId: (0, _model.attr)('number'),
    role: (0, _computedPromise.default)('roleId', {
      get: function get() {
        if (this.roleId) {
          return this.store.findRecord('trip-plan-leg-personnel-role', this.roleId);
        }

        return null;
      },
      set: function set(key, value) {
        if (value) this.set('roleId', value.get('id'));
        return value;
      }
    }, null)
  });

  _exports.default = _default;
});