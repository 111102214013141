define("sky-router-3/mixins/google-object-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin links Google maps properties to ember properties and listens for changes in each one
   * If there are changes it syncs the properties on the object. The main entry point is the
   * `googlePropertyMap` object
   *
   * Inspiration for this mixin comes from https://github.com/huafu/ember-google-map/blob/master/addon/mixins/google-object.js
   */
  var _default = Ember.Mixin.create({
    /**
     * The main object that defines mapped properties between ember and Google maps.
     * It should be formatted in the following way with strings representing function names
     * { event: '' , property: '', getFunc: '', setFunc: '', eFunc: '' }
     *
     * If the property will only be read from ember and not set into ember then
     * `event` and `getFunc` do not have to be supplied.
     *
     * If the event will trigger a function only and not a property then pass
     * `event` and `eFunc`. eFunc will be called with the arguments of the
     * `googlePropertyObject` and any other arguments the event carries.
     */
    googlePropertyMap: null,

    /**
     * The object that the property may is based off of. This needs to be a live map object
     * as soon as this object is declared the bindings will attach and run
     */
    googlePropertyObject: null,

    /**
     * Holds an array of listeners that this mixin created on the Google object.
     * This array can be used to remove the listeners after the object has been
     * destroyed.
     */
    _googleListeners: null,

    /**
     * Holds an array of object that contains the property being listend and
     * the callback function to execute when the listener changes.
     * @type {Object} { prop: {string}, callback: {function} }
     */
    _emberListeners: null,

    /**
     * Observes the Google property object changes and handles removing old listeners
     * and adding new ones.
     * @see http://emberjs.com/deprecations/v1.x/#toc_beforeobserver
     */
    googlePropertyObjectObserver: function () {
      if (this._googlePropertyObject !== this.googlePropertyObject) {
        this._googlePropertyObject = this.googlePropertyObject;
        this.destoryPropertyObservers();
        this.addPropertyObservers();
      }
    }.observes('googlePropertyObject'),
    setupGooglePropertyObserver: Ember.on('init', function () {
      this.googlePropertyObjectObserver();
    }),

    /**
     * When the mixin will be destroyed it makes sure the observers are destroyed also
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.destoryPropertyObservers();
      this._googlePropertyObject = null;
    },

    /**
     * Attaches / updates the bindings and also initializes the values of the Google object
     * from the values of the ember object. This is called automatically when the
     * `googlePropertyObject` changes
     * @function
     * @public
      */
    addPropertyObservers: function addPropertyObservers() {
      var _this = this;

      var gProperties = this.get('googlePropertyMap');
      var gObject = this.get('googlePropertyObject');
      this._googleListeners = [];
      this._emberListeners = [];

      if (gProperties && gObject) {
        gProperties.forEach(function (prop) {
          // Attach the ember listener
          if (prop.property) {
            var eObject = _this.getObjectFromProperty(prop.property);

            var callback = Ember.run.bind(_this, _this.setGoogleFromEmber, gObject, prop.property, prop.setFunc, prop.getFunc);

            var pName = _this.getCleanPropertyName(prop.property); // Add the observer for the property


            Ember.addObserver(eObject, pName, _this, callback);

            _this._emberListeners.pushObject({
              prop: prop.property,
              callback: callback
            }); // If this has a custom observer for property changes then attach that


            if (prop.observes) {
              Ember.addObserver(eObject, prop.observes, _this, callback);

              _this._emberListeners.pushObject({
                prop: prop.observes,
                callback: callback
              });
            } // Initialize it with the values ember contains


            _this.setGoogleFromEmber(gObject, prop.property, prop.setFunc, prop.getFunc);
          } // Attach the Google maps listener for property events


          if (prop.event && prop.getFunc) {
            var listener = google.maps.event.addListener(gObject, prop.event, Ember.run.bind(_this, _this.setEmberFromGoogle, gObject, prop.property, prop.getFunc));

            _this._googleListeners.pushObject(listener);
          } // Attach the Google maps listener that calls a function only


          if (prop.event && prop.eFunc) {
            var fListener = google.maps.event.addListener(gObject, prop.event, Ember.run.bind(_this, _this[prop.eFunc], gObject));

            _this._googleListeners.pushObject(fListener);
          }
        });
      }
    },

    /**
     * Removes the observers and listeners that link ember to the Google object.
     * This is called automatically when the element will be removed if this
     * mixin is used in a component. Otherwise it must be called manually.
     * @function
     * @public
     */
    destoryPropertyObservers: function destoryPropertyObservers() {
      var _this2 = this;

      // Detach the ember listeners
      if (this._emberListeners) {
        this._emberListeners.forEach(function (p) {
          var eObject = _this2.getObjectFromProperty(p.prop);

          var pName = _this2.getCleanPropertyName(p.prop);

          Ember.removeObserver(eObject, pName, _this2, p.callback);
        });
      }

      this._emberListeners = null; // Detach the Google listeners

      if (this._googleListeners) {
        this._googleListeners.forEach(google.maps.event.removeListener);
      }

      this._googleListeners = null;
    },

    /**
     * Sets any property that can be gathered from a Google function. For example it can
     * set the `config.zoom` property from the map.getZoom function.
     * @private
     * @function setEmberFromGoogle
     * @augments {object} the Google object
     * @augments {string} the string value of the property to set
     * @augments {string} the function that gets the value from the Google object
     */
    setEmberFromGoogle: function setEmberFromGoogle(gObject, property, getFunction) {
      var _this3 = this;

      if (!gObject) {
        return;
      }

      var value = this.getObjectFunction(gObject, getFunction).call(gObject);
      var currentValue = this.get(property);

      if (!!currentValue && currentValue.then) {
        currentValue.then(function (promisedValue) {
          if (value !== promisedValue) {
            _this3.set(property, value);
          }
        });
      } else if (value !== currentValue) {
        this.set(property, value);
      }
    },

    /**
     * Sets into the Google object any property that can be set by a function on the object
     * for example it can set the `config.zoom` property with the map.setZoom function.
     *
     * Also it allows for ember properties to be promises to be resolved with the value to set.
     *
     * @private
     * @function setMapFromProp
     * @argument {object} gObject the Google object
     * @argument {string} property the string value of the property to set
     * @argument {string} setFunction the function that sets the value into the map
     * @argument {string} getFunction Gets the current value from the map
     */
    setGoogleFromEmber: function setGoogleFromEmber(gObject, property, setFunction, getFunction) {
      // This has to be run in the actions loop so ember data has a chance to load records
      Ember.run.schedule('actions', this, function (gObject, property, setFunction, getFunction) {
        if (!gObject) {
          return;
        } // Get the ember property and the Google set function


        var eValue = this.get(property);
        var sFunc = this.getObjectFunction(gObject, setFunction);
        var gFunc = getFunction ? this.getObjectFunction(gObject, getFunction) : undefined; // Check for promises

        if (!!eValue && eValue.then) {
          eValue.then(function (tValue) {
            // Don't set to the same value
            if (gFunc && gFunc.call(gObject) == tValue) {
              return;
            }

            sFunc.call(gObject, tValue);
          });
        } else if (eValue !== undefined) {
          // Don't set to the same value
          if (gFunc && gFunc.call(gObject) == eValue) {
            return;
          }

          sFunc.call(gObject, eValue);
        }
      }, gObject, property, setFunction, getFunction);
    },

    /**
     * Gets the object that holds the property in question. It expects a string
     * in the form of `object.property` or `object.object.property`. It will return
     * the ember object that the property is contained in
     * @function getObjectFromProperty
     * @param {string} the path of the property to get the object for
     * @private
     */
    getObjectFromProperty: function getObjectFromProperty(property) {
      var arr = property.split('.');

      if (arr.length === 1) {
        return this;
      }

      arr.pop();
      return this.get(arr.join('.'));
    },

    /**
     * Gets only the property name from a property object path.
     * @example takes `object.property` and returns `property`
     * @function getCleanPropertyName
     * @param {string} the path of the property
     * @private
     */
    getCleanPropertyName: function getCleanPropertyName(property) {
      return property.split('.').pop();
    },

    /**
     * Gets the object function from the object and asserts that it exists
     * @private
     * @param {google.object}
     * @param {string} gFunc - the name of the function to get on the Google object
     */
    getObjectFunction: function getObjectFunction(gObject, gFunc) {
      var func = gObject[gFunc];
      console.assert(!!func, 'Google object function not defined in object, check your definitions', {
        object: gObject,
        func: gFunc
      });
      return func;
    }
  });

  _exports.default = _default;
});