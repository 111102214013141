define("sky-router-3/pods/components/portal/track/map/map-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFOaN7Xa",
    "block": "{\"symbols\":[\"mapConfig\"],\"statements\":[[6,[37,18],[[30,[36,17],[[30,[36,17],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[16,0,[31,[\"col-sm-\",[30,[36,2],[[30,[36,1],[[32,1],[35,5,[\"lastObject\"]]],null],[35,4],[35,3]],null],\" \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"active\"],null]]]],[4,[38,6],[[32,0],[32,0,[\"onActiveMapChange\"]],[32,1]],[[\"on\",\"preventDefault\"],[\"mouseDown\",false]]],[12],[2,\"\\n            \"],[1,[30,[36,16],null,[[\"config\",\"locations\",\"locationMarkerAction\",\"waypointMarkerAction\",\"locationEditId\",\"assets\",\"assetMarkerAction\",\"assetBreadcrumbTimespan\",\"geofences\",\"geofenceEditId\",\"mapAction\",\"assetTagSetting\",\"overlays\",\"trips\"],[[32,1],[35,15],[32,0,[\"locationMarkerAction\"]],[32,0,[\"waypointMarkerAction\"]],[35,14],[35,13],[32,0,[\"assetMarkerAction\"]],[35,12],[35,11],[35,10],[32,0,[\"mapAction\"]],[35,9],[35,8],[35,7]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"activeConfig\",\"eq\",\"if\",\"columnWidth\",\"lastColumnWidth\",\"configs\",\"action\",\"trips\",\"overlays\",\"assetTagSetting\",\"geofenceEditId\",\"geofences\",\"assetBreadcrumbTimespan\",\"assets\",\"locationEditId\",\"locations\",\"portal/track/map/map-google\",\"-track-array\",\"each\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/map-grid/template.hbs"
  });

  _exports.default = _default;
});