define("sky-router-3/models/compound-alert-revision-rule-group", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    rules: (0, _emberCpValidations.validator)('has-many')
  });

  var _default = _model.default.extend(VALIDATIONS, {
    logic: (0, _model.attr)('string', {
      defaultValue: 'OR'
    }),
    rules: (0, _model.hasMany)('compound-alert-revision-rule', {
      async: false
    })
  });

  _exports.default = _default;
});