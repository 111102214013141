define("sky-router-3/mixins/before-unload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a mixin that adds an 'on' event that is triggered before the window is unloaded.
   * I.E. when the user closes the tab or browser window.
   *
   * This mixin can only be used in routes!
   *
   * @example function(){}.on('beforeUnload')
   */
  var _default = Ember.Mixin.create(Ember.Evented, {
    /**
     * This holds a function reference to `_beforeUnloadTrigger`
     * that is bound to this classes `this`. To see why this is
     * and works check out.
     * https://stackoverflow.com/questions/11565471/removing-event-listener-which-was-added-with-bind
     */
    _beforeUnloadBoundRef: null,

    /**
     * Initialize the listener that attaches to the window
     */
    _initUnloadListener: Ember.on('activate', function () {
      var boundFunc = this._beforeUnloadTrigger.bind(this);

      this.set('_beforeUnloadBoundRef', boundFunc);
      window.addEventListener('beforeunload', boundFunc);
      window.addEventListener('pagehide', boundFunc); // ios support
    }),

    /**
     * Removes the listener that attaches to the window
     */
    _destroyUnloadListener: Ember.on('deactivate', function () {
      var boundFunc = this.get('_beforeUnloadBoundRef');
      window.removeEventListener('beforeunload', boundFunc);
      window.removeEventListener('pagehide', boundFunc);
    }),

    /**
     * Actually makes the call that triggers the new custom event
     */
    _beforeUnloadTrigger: function _beforeUnloadTrigger() {
      this.trigger('beforeUnload');
    }
  });

  _exports.default = _default;
});