define("sky-router-3/services/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service allows for special events to circumvent the normal ember flow.
   * This is an anti-pattern but ultimatly necessary as components are wrapped many level deep.
   * This should be restricted to VERY FEW use cases.
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    /**
     * @event 'blockApp'
     * This event allows components to hook into the globalBlockApp method
     */

    /**
     * @event 'mapClick'
     * @argument 'latitude'
     * @argument 'longitude'
     * This event allows components to hook into when the map is clicked by a user.
     */
  });

  _exports.default = _default;
});