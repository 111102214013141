define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-4/component", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.value1': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Passed in output model.
     * @type {asset-compound-rule-revision-output}
     */
    model: null,

    /**
     * Port selection
     */
    flagSelected: (0, _computedFindBy.default)('flags', 'value', 'model.value1', true),
    flags: function () {
      var intl = this.get('intl');
      return [{
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '1'
        }),
        value: '21'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '2'
        }),
        value: '22'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '3'
        }),
        value: '23'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '4'
        }),
        value: '24'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '5'
        }),
        value: '25'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '6'
        }),
        value: '26'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '7'
        }),
        value: '27'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '8'
        }),
        value: '28'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '9'
        }),
        value: '29'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '10'
        }),
        value: '30'
      }, {
        label: intl.t('compoundrules.output.flag.flagNum', {
          number: '11'
        }),
        value: '31'
      }];
    }.property(),
    actions: {
      flagChanged: function flagChanged(opt) {
        this.set('model.value1', opt.value);
      }
    }
  });

  _exports.default = _default;
});