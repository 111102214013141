define("sky-router-3/pods/components/portal/track/map/map-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component creates a button that can be placed on a Google map
   * The yielded content is the contents of the button
   *
   * @example {{map-button onClick='clickAction' placement=google.maps.ControlPosition.BOTTOM_LEFT}}
   * @see https://developers.google.com/maps/documentation/javascript/controls
   */
  var _default = Ember.Component.extend({
    /**
     * Passed in map object to attach button to
     * @type {goole.maps.Map}
     */
    map: null,

    /**
     * Indicates where the button will be placed on the map
     * This value should be passed in.
     *
     * It is the end value of the enum google.maps.ControlPosition
     */
    placement: 'BOTTOM_LEFT',

    /**
     * The order of the button when placed at the control
     * position specified
     */
    index: 1,

    /**
     * Adds the button to the map upon initialization
     */
    didInsertElement: function didInsertElement() {
      this._parent = this.element.parentNode;
      var map = this.get('map');

      if (map) {
        map.controls[google.maps.ControlPosition[this.get('placement')]].push(this.element);
      }
    },

    /**
     * Removes the button when the element will be destroyed
     */
    willDestroyElement: function willDestroyElement() {
      var _this = this;

      var map = this.get('map');

      if (map) {
        var array = map.controls[google.maps.ControlPosition[this.get('placement')]];
        array.forEach(function (elm, index) {
          if (elm === _this.element) {
            array.removeAt(index);
          }
        });
      }

      this._parent.appendChild(this.element);
    }
  });

  _exports.default = _default;
});