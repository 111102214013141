define("sky-router-3/pods/components/portal/portal-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['portal-legend'],

    /**
     * Holds the selected tab index for the legend tablist
     * @type {Number}
     */
    selectedIndex: null
  });

  _exports.default = _default;
});