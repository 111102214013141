define("sky-router-3/transforms/google-lat-lng", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*global google */
  // Fix eslint tests

  /**
   * Transformation attribute for google.maps.latLng class allowing it to be stored in ember data
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return new google.maps.LatLng(serialized.lat, serialized.lng);
      }

      return new google.maps.LatLng(0, 0);
    },
    serialize: function serialize(deserialized) {
      var obj = {
        lat: 0,
        lng: 0
      };

      if (deserialized) {
        if (typeof deserialized.lat === 'function') {
          obj.lat = deserialized.lat();
          obj.lng = deserialized.lng();
        }

        if (typeof deserialized.lat === 'number') {
          return deserialized;
        }
      }

      return obj;
    }
  });

  _exports.default = _default;
});