define("sky-router-3/pods/components/button-archive/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wz9u6IN2",
    "block": "{\"symbols\":[\"@model\",\"@onArchive\"],\"statements\":[[8,\"bs-button\",[],[[\"@class\",\"@action\",\"@isLoading\"],[[31,[[30,[36,1],[[32,1,[\"isArchived\"]],\"btn-success\",\"btn-warning\"],null]]],[30,[36,3],[[32,0],[32,2],[32,1],[30,[36,2],[[32,1,[\"isArchived\"]]],null]],null],[32,1,[\"isSaving\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"ico-archive\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isArchived\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"misc.unarchive\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"misc.archive\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"not\",\"action\"]}",
    "moduleName": "sky-router-3/pods/components/button-archive/template.hbs"
  });

  _exports.default = _default;
});