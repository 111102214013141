define("sky-router-3/pods/portal/manage/geofences/assignment-by-device/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search'],
    search: null,

    /**
     * Filtering
     */
    filtered: Ember.computed.filter('model.assets', ['search', 'model.assets.[]', 'model.assets.@each.geofences.[]'], function (item) {
      var search = this.get('search');

      if (search) {
        return (0, _stringSearch.default)(item.get('name'), search) || (0, _stringSearch.default)(item.get('registration'), search) || (0, _stringSearch.default)(item.get('imei'), search) || item.get('geofences').any(function (g) {
          return (0, _stringSearch.default)(g.get('name'), search);
        });
      }

      return true;
    }),

    /**
     * Grid Paging Properties
     */
    pageIndex: Ember.computed('filtered.[]', {
      get: function get() {
        return 0; // Reset paging when filtered devices list changes
      },
      set: function set(key, value) {
        return value;
      }
    }),
    pageSize: 20,
    pageSizes: [20, 50, 100]
  });

  _exports.default = _default;
});