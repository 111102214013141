define("sky-router-3/pods/components/portal/operations/trips/custom-field-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7IInfUpP",
    "block": "{\"symbols\":[\"@field\",\"&attrs\",\"@type\"],\"statements\":[[11,\"div\"],[24,0,\"form-group\"],[17,2],[12],[2,\"\\n    \"],[10,\"i\"],[12],[1,[32,3,[\"name\"]]],[2,\" - \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,3,[\"type\"]],\"checkbox\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"input-checkbox\",[],[[\"@checked\",\"@disabled\"],[[32,1,[\"value\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"value\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/operations/trips/custom-field-display/template.hbs"
  });

  _exports.default = _default;
});