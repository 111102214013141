define("sky-router-3/pods/components/portal/report/playback-grid/component", ["exports", "sky-router-3/config/environment", "moment", "sky-router-3/utils/moment-offset-converter"], function (_exports, _environment, _moment, _momentOffsetConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    positionColumns: 'ID,IMEI,Name,Time,Type,Altitude,Velocity,Heading,Latitude,Longitude',

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * The session service
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Holds a reference to the API namespace which is the part of
     * the path the api lives at after the rootURL of the app
     */
    namespace: _environment.default.API.namespace,

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Indicates if the no records found message is to be shown
     * @type {Boolean}
     */
    showNoRecordMessage: false,

    /**
     * Flag for the user to toggle the query to show completed trips only or not
     * @type {Boolean}
     */
    completedTripsOnly: false,

    /**
     * The user selected utc offset. UTC or local
     * @type {Number}
     */
    utcOffset: 0,

    /**
     * Does the user have permission to download KML files?
     **/
    hasKmlPermission: Ember.computed(function () {
      return this.get('sessionAccount').hasPermission('report.playback.download.kml');
    }),

    /**
     * Indicates whether or not the loading message is shown for the report generation
     */
    loading: false,

    /**
     * The report result set
     **/
    reportData: null,

    /**
     * The total number of records in the result set
     */
    totalRecords: 0,

    /**
     * The start index of the results
     */
    startIndex: 1,

    /**
     * The end index of the results
     */
    endIndex: 0,

    /**
     * The current page of the report results
     */
    currentPage: 1,

    /**
     * The amount of returned records
     */
    resultCount: 0,

    /**
     * The amount of records per paged results
     **/
    take: 300,

    /**
     * The user options for page size
     */
    takeSelections: [300, 500, 1000],

    /**
     * Creates the applicable pages list
     */
    pages: Ember.computed('totalRecords', 'take', function () {
      var pages = [];
      var take = this.get('take');
      var totalRecords = this.get('totalRecords');
      var count = Math.ceil(totalRecords / take);

      for (var i = 1; i <= count; i++) {
        pages.push(i);
      }

      return pages;
    }),
    didInsertElement: function didInsertElement() {
      this.onRegisterApi({
        generateReport: Ember.run.bind(this, this.generateReport)
      });
      this.generateReport(1);
    },

    /**
     * Makes API call to generate report, then sets report details from response.
     * @private
     * @function
     */
    generateReport: function generateReport(page) {
      var _this = this;

      // Get report parameters
      var imei = this.get('selectedDevice.imei');
      this.set('loading', true);
      this.get('api').getPlaybackTripsReportData(this.take, page, imei, this.startDate, this.endDate, this.completedTripsOnly, this.utcOffset).then(function (data) {
        // Create the hyperlink dates
        data.rows.forEach(function (item) {
          var startDate = _moment.default.utc(item.TripStartDate).subtract(1, 'seconds');

          var endDate = _moment.default.utc(item.TripStopDate).add(1, 'seconds'); // Do the selected offset for the start and end dates


          item.startDatePlayback = _momentOffsetConverter.default.toOffsetISOString(startDate, _this.utcOffset);
          item.endDatePlayback = _momentOffsetConverter.default.toOffsetISOString(endDate, _this.utcOffset);
        });

        _this.set('reportData', data.rows);

        _this.set('totalRecords', data.records);

        _this.set('showNoRecordMessage', data.records === 0 ? true : false);

        _this.set('page', data.page);

        _this.set('resultCount', data.total);

        _this.set('startIndex', (data.page - 1) * _this.take + 1);

        var endIndex = data.page * _this.take;

        if (endIndex > data.records) {
          endIndex = data.records;
        }

        _this.set('endIndex', endIndex);
      }).finally(function () {
        _this.set('loading', false);
      });
    },

    /**
     * Makes the API call to retrive the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @param  {Ember.Object} records The trip record
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName, record) {
      var _this2 = this;

      // Get report parameters
      var devicesList = record.IMEI;
      var eventsList = '';
      var visibleColumnsList = this.get('positionColumns'); // ID is not a field for the Position History Report so it needs to be removed.

      visibleColumnsList = visibleColumnsList.replace('ID,', ''); // Alters the visibility of the user setting columns

      if (this.get('sessionAccount.user.hideAssetSpeed')) {
        visibleColumnsList = visibleColumnsList.replace('Velocity', '').replace(',,', ',');
      }

      if (this.get('sessionAccount.user.hideAssetAltitude')) {
        visibleColumnsList = visibleColumnsList.replace('GPS_Height', '').replace(',,', ',');
      }

      var utcOffset = this.get('utcOffset');

      var startDate = _moment.default.utc(record.TripStartDate).subtract(1, 'seconds');

      var endDate = _moment.default.utc(record.TripStopDate).add(1, 'seconds'); // Do the selected offset for the start and end dates


      startDate = _momentOffsetConverter.default.toOffsetISOString(startDate, utcOffset);
      endDate = _momentOffsetConverter.default.toOffsetISOString(endDate, utcOffset); // Set the distance option to feet. User has no option to choose speed units from the playback report

      var altitudeOption = 1; // Set the speed option to MPH. User has no option to choose speed units from the playback report

      var speedOption = 2; // Call the API

      this.get('api').getPositionReportHistoryReport(fileName, devicesList, eventsList, visibleColumnsList, startDate, endDate, altitudeOption, speedOption, utcOffset, exportType).catch(function () {
        _this2.notifications.error(_this2.intl.t('report.exportFailed', {
          exportType: exportType
        }));
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('MM-DD-YYYY HH:m:s');
    },
    actions: {
      countChanged: function countChanged(selection) {
        this.set('take', selection);
        this.generateReport(1);
      },
      pageSelected: function pageSelected(page) {
        if (page !== this.currentPage) {
          this.generateReport();
        }
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel(record) {
        this.exportReport('Excel', this.intl.t('nav.positionReportHistory') + '-' + this.getUTCDateString() + '.xlsx', record);
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV(record) {
        this.exportReport('CSV', this.intl.t('nav.positionReportHistory') + '-' + this.getUTCDateString() + '.csv', record);
      }
    }
  });

  _exports.default = _default;
});