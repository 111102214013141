define("sky-router-3/pods/playback/view/controller", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * Indicates if the playback report is currently playing.
     * @type {Boolean}
     */
    isPlaying: Ember.computed({
      get: function get() {
        return false;
      },
      set: function set(key, value) {
        if (value) {
          this.schedulePlayEvent();
        } else {
          Ember.run.cancel(this.get('schedulePlayEventTimer'));
        }

        return value;
      }
    }),

    /**
     * The current index in the model.positions array that the playback report
     * is currently at.
     * @type {Number}
     */
    playPosition: Ember.computed({
      get: function get() {
        return 0;
      },
      set: function set(key, _value, _prevValue) {
        var _this = this;

        var value = parseInt(_value);
        var prevValue = parseInt(_prevValue); // Get the positions that were added or removed

        if (value > prevValue) {
          var positions = this.model.positions.slice(prevValue, value + 1); // We are adding records, so parse them and push them.

          var serializer = this.store.serializerFor('asset-position');
          var model = this.store.modelFor('asset-position');
          var norm = serializer.normalizeArrayResponse(this.store, model, positions);
          this.store.push(norm);
        } else {
          var _positions = this.model.positions.slice(value + 1, prevValue + 1); // If we are scrubbing backwards then remove records from the store


          _positions.forEach(function (pos) {
            var record = _this.store.peekRecord('asset-position', pos.Id);

            if (record) {
              _this.store.unloadRecord(record);
            }
          });
        }

        return value;
      }
    }),

    /**
     * Gets the date timestamp of the event currently at the top playhead location.
     * @return {Date}
     */
    playPositionTimestamp: Ember.computed('playPosition', function () {
      var position = this.get('model.positions')[this.get('playPosition')];
      return position ? new Date(position.Utc) : new Date();
    }),
    playPositionDisplay: Ember.computed('playPosition', function () {
      return parseInt(this.playPosition) + 1;
    }),

    /**
     * Gets a CSV of asset names and registration numbers
     */
    assetNames: Ember.computed('model.assets.[]', function () {
      return this.get('model.assets').reduce(function (value, item, index, array) {
        if (!value) {
          value = '';
        }

        var fullName = item.get('fullName');

        if (index !== array.get('length') - 1) {
          return value += "".concat(fullName, ", ");
        }

        return value += fullName;
      });
    }),

    /**
     * The speed at which the playback is currently running.
     * This is a range of values from 1 - 10
     * @type {Number}
     */
    speed: 5,

    /**
     * Can the user step the play forward by 1 tick
     */
    canStepForward: Ember.computed('model.positions.[]', 'playPosition', function () {
      var length = this.get('model.positions.length');
      var position = this.get('playPosition');
      return position < length - 1;
    }),

    /**
     * Can the user step the play backwards by 1 tick
     * @return {[type]} [description]
     */
    canStepBackward: Ember.computed.gt('playPosition', 0),

    /**
     * The timer for playing an event
     * @type {Timer}
     */
    schedulePlayEventTimer: null,

    /**
     * This is the main timer method for the playing of events. It must
     * be called after ever successful event play. It looks at the speed and
     * schedules the next event to be played.
     */
    schedulePlayEvent: function schedulePlayEvent() {
      var _this2 = this;

      var speed = this.get('timerSpeed');
      var timer = Ember.run.later(this, function () {
        if (_this2.playEvent()) {
          _this2.schedulePlayEvent();
        } else {
          _this2.set('isPlaying', false);
        }
      }, speed);
      this.set('schedulePlayEventTimer', timer);
    },

    /**
     * Increments the play position if it can. Returns true if successful
     * or false if not.
     * @return {Boolean}
     */
    playEvent: function playEvent() {
      var position = this.get('playPosition');
      var limit = this.get('model.positions.length');

      if (position < limit) {
        this.incrementProperty('playPosition');
        return true;
      } else {
        return false;
      }
    },

    /**
     * Returns the MS timer value for the speed value supplied.
     * @return {[type]} [description]
     */
    timerSpeed: Ember.computed('speed', function () {
      switch (this.speed) {
        case '1':
          return 3000;

        case '2':
          return 1500;

        case '3':
          return 1300;

        case '4':
          return 1000;

        case '5':
        default:
          return 700;

        case '6':
          return 600;

        case '7':
          return 500;

        case '8':
          return 400;

        case '9':
          return 300;

        case '10':
          return 200;
      }
    }),

    /**
     * Gives a link for downloading this playback in google earth
     * @return {String}
     */
    googleEarthUrl: Ember.computed('model.asset', 'model.positions.[]', function () {
      var sid = this.get('model.positions.firstObject.Id');
      var sdt = this.get('model.positions.firstObject.Utc');
      var eid = this.get('model.positions.lastObject.Id');
      var edt = this.get('model.positions.lastObject.Utc');
      var imeis = this.get('model.assets').mapBy('imei').join();
      return "".concat(_environment.default.API.namespace, "/kml/trip?id=").concat(imeis, "&sdt=").concat(sdt, "&edt=").concat(edt, "&tstartid=").concat(sid, "&tstopid=").concat(eid);
    }),

    /**
     * Filters geofences based on toggle flags
     * @return {Array<sky-router-3/models/geofence}
     */
    geofences: Ember.computed('model.showGeofences', 'model.geofences.[]', function () {
      if (this.get('model.showGeofences')) {
        return this.get('model.geofences');
      } else {
        return [];
      }
    }),

    /**
     * returns true if all displayed assets are marked to connect breadcrumbs.
     * When set it will apply it to all assets.
     */
    connectBreadcrumbs: Ember.computed({
      get: function get() {
        return this.get('model.assets').isEvery('connectBreadcrumbs');
      },
      set: function set(key, value) {
        this.get('model.assets').invoke('set', 'connectBreadcrumbs', value);
        return value;
      }
    }),
    positionsWindowSubset: Ember.computed('model.positionsPlayed.[]', function () {
      var startIndex = Math.max(0, this.model.positionsPlayed.length - 10);
      return this.model.positionsPlayed.slice(startIndex).reverse();
    }),
    altitudeAssetSelected: Ember.computed.oneWay('model.assets.firstObject'),
    speedAssetSelected: Ember.computed.oneWay('model.assets.firstObject'),
    actions: {
      /**
       * User wants to play the report
       */
      play: function play() {
        this.set('isPlaying', true);
      },

      /**
       * User want to stop the playback where it is.
       */
      stop: function stop() {
        this.set('isPlaying', false);
      },

      /**
       * Step the play position forward by 1 tick
       */
      stepForward: function stepForward() {
        this.incrementProperty('playPosition');
      },

      /**
       * Step the play position backwards by 1 tick
       */
      stepBackward: function stepBackward() {
        this.decrementProperty('playPosition');
      }
    }
  });

  _exports.default = _default;
});