define("sky-router-3/services/geo-area-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    fitMapToGeoAreas: function fitMapToGeoAreas(geoAreas, map) {
      if (geoAreas == null) {
        map.set('zoom', 4);
        return;
      }

      if (geoAreas.length == 0) {
        map.set('zoom', 4);
        return;
      }

      var minLat = 90.0,
          maxLat = -90.0,
          minLng = 180.0,
          maxLng = -180.0;
      geoAreas.forEach(function (geoArea) {
        var radiusInDegrees = geoArea.diameterInKm * 500 / 111000;
        var latAdjustment = radiusInDegrees;
        var lngAdjustment = radiusInDegrees / Math.cos(geoArea.latitudeDegree * Math.PI / 180);
        var minGeoAreaLat = geoArea.latitudeDegree - latAdjustment;
        var maxGeoAreaLat = geoArea.latitudeDegree + latAdjustment;
        var minGeoAreaLng = geoArea.longitudeDegree - lngAdjustment;
        var maxGeoAreaLng = geoArea.longitudeDegree + lngAdjustment;
        minLat = Math.min(minLat, minGeoAreaLat);
        maxLat = Math.max(maxLat, maxGeoAreaLat);
        minLng = Math.min(minLng, minGeoAreaLng);
        maxLng = Math.max(maxLng, maxGeoAreaLng);
      });
      var bounds = new google.maps.LatLngBounds(new google.maps.LatLng(minLat, minLng), new google.maps.LatLng(maxLat, maxLng));
      map.set('fitBounds', bounds);
    }
  });

  _exports.default = _default;
});