define("sky-router-3/pods/components/portal/track/map/overlays/global-notams-settings/component", ["exports", "sky-router-3/utils/computed-alias-not"], function (_exports, _computedAliasNot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     * Options based on https://www.aviationweather.gov/help/webservice?page=gairmetjson
     * @type {Array}
     */
    notamConditions: function () {
      return [{
        value: 'all',
        label: this.intl.t('overlay.notams.conditions.allConditions')
      }, {
        value: 'A',
        label: this.intl.t('overlay.notams.conditions.conditionA')
      }, {
        value: 'C',
        label: this.intl.t('overlay.notams.conditions.conditionC')
      }, {
        value: 'H',
        label: this.intl.t('overlay.notams.conditions.conditionH')
      }, {
        value: 'L',
        label: this.intl.t('overlay.notams.conditions.conditionL')
      }, {
        value: 'X',
        label: this.intl.t('overlay.notams.conditions.conditionX')
      }];
    }.property(),
    notamConditionSelected: function () {
      var opts = this.get('notamConditions');
      return opts.findBy('value', this.get('settings.generic.notamcondition')) || opts[0];
    }.property('settings.generic.notamcondition'),

    /**
     * Checkboxes
     */
    showSubjectA: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectA'),
    showSubjectC: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectC'),
    showSubjectF: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectF'),
    showSubjectG: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectG'),
    showSubjectI: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectI'),
    showSubjectL: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectL'),
    showSubjectM: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectM'),
    showSubjectN: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectN'),
    showSubjectO: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectO'),
    showSubjectP: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectP'),
    showSubjectR: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectR'),
    showSubjectS: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectS'),
    showSubjectW: (0, _computedAliasNot.default)('settings.generic.notamhideSubjectW'),
    actions: {
      setCondition: function setCondition(sel) {
        this.set('settings.generic.notamcondition', sel.value);
      }
    }
  });

  _exports.default = _default;
});