define("sky-router-3/models/whitelist", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * The csv list of permanently whitelisted email addresses
     */
    list: (0, _model.attr)('string')
  });

  _exports.default = _default;
});