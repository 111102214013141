define("sky-router-3/pods/portal/report/data-usage/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /* Gets min date where data should be displayed differently */
    minDate: Ember.computed({
      get: function get() {
        var d = new Date();
        return new Date(d.setMonth(d.getUTCMonth() - 1)).toISOString().slice(0, 10);
      }
    }),

    /* Hides or shows the 'sendEmail' button */
    showDataUsage: Ember.computed({
      get: function get() {
        return this.get('model.dataUsageSummary').get('firstObject');
      }
    }) //,
    // dataUsage: computed({
    //     get() {
    //         return this.get('model.dataUsage');
    //     }
    // })

  });

  _exports.default = _default;
});