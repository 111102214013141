define("sky-router-3/pods/components/portal/track/map/map-context-menu/component", ["exports", "sky-router-3/pods/components/context-menu/component", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/map-lat-lng-to-point", "sky-router-3/pods/components/portal/track/map/lib/computed-elevation"], function (_exports, _component, _latLngConverter, _mapLatLngToPoint, _computedElevation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Inherits from the default context menu to present one specifically for
   * the map. It allows for map latLng objects to be used and displayed in a consistent way
   *
   * @example {{portal/track/map/map-context-menu map=map show=false event=event latLng=latLng closeMenu='onCloseMenu'}}
   */
  var _default = _component.default.extend({
    intl: Ember.inject.service(),

    /**
     * @event 'closeMenu'
     * This event is triggered anytime the menu wants to be closed. Aka the user
     * clicks outside context menu.
     */

    /**
     * @event 'addLocation'
     * This event is triggered when user clicks the 'add location here' event.
     * @param {object} { {google.maps.LatLng}latLng } - latLng of where the event occurred.
     */

    /**
     * Adds listeners to the map that watch to see if the user does anything
     * that should cause the context menu to be closed. If an event like that
     * is detected an action `closeMenu` is fired.
     */
    _attachListeners: function _attachListeners() {
      var map = this.get('map');
      var listeners = [];
      listeners.push(google.maps.event.addDomListenerOnce(document, 'click', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'click', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'dblclick', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'dragstart', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'center_changed', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'zoom_changed', Ember.run.bind(this, this.closeMenu)));
      listeners.push(google.maps.event.addListenerOnce(map, 'resize', Ember.run.bind(this, this.closeMenu)));
      this.set('_listeners', listeners);
    },

    /**
     * Array of Google maps listeners that were attached to the context menu
     * @type {array<google.maps.listener>}
     */
    _listeners: null,

    /**
     * Kills any listeners that were present in the _listeners object
     */
    willDestroyElement: function willDestroyElement() {
      var listeners = this.get('_listeners');

      if (listeners) {
        listeners.forEach(function (item) {
          google.maps.event.removeListener(item);
        });
      }
    },

    /**
     * Indicates if the context menu is open or not.
     * @public
     * @property
     */
    show: false,

    /**
     * Observes the show property and opens and closes the context
     * menu.
     */
    _watchContextShow: function () {
      if (this.get('show')) {
        this._attachListeners();
      } else {
        this._removeListeners();
      }
    }.observes('show'),
    didInsertElement: function didInsertElement() {
      this._watchContextShow();
    },

    /**
     * The event that triggered the context menu to open.
     * @public
     * @property
     * @default
     * @type {google.maps.MouseEvent|google.maps.PolyMouseEvent}
     * @see https://developers.google.com/maps/documentation/javascript/reference#MouseEvent
     */
    event: null,

    /**
     * Instance of the Google map
     * @public
     * @property
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * The latitude milliarcSeconds of the latLng point
     * @type {number}
     */
    latMilli: Ember.computed('event', function () {
      var event = this.get('event');

      if (event && event.latLng) {
        return _latLngConverter.default.decimalDegToMilliArcSecond(event.latLng.lat());
      }

      return 0;
    }),

    /**
     * the longitude milliarcSeconds of the latLng point
     * @type {number}
     */
    lngMilli: Ember.computed('event', function () {
      var event = this.get('event');

      if (event && event.latLng) {
        return _latLngConverter.default.decimalDegToMilliArcSecond(event.latLng.lng());
      }

      return 0;
    }),

    /**
     * Returns the point that the event is at
     * @return {Object} Object containing x and y properties.
     */
    point: Ember.computed('event', function () {
      var event = this.get('event');

      if (event) {
        if (Ember.isPresent(event.pixel)) {
          return event.pixel;
        }

        if (Ember.isPresent(event.latLng)) {
          return (0, _mapLatLngToPoint.default)(this.get('map'), event.latLng);
        }
      }

      return null;
    }),

    /**
     * Elevation above 0 MSL based on a sphereoid
     *  NOTE: this costs money each time it is used. Be careful on usage
     */
    elevationCM: (0, _computedElevation.default)('event.latLng', true, true),

    /**
     * The x cord of the context-menu
     * @type {number}
     */
    x: Ember.computed('point', function () {
      return this.get('point.x');
    }),

    /**
     * the y cord of the context-menu
     * @type {number}
     */
    y: Ember.computed('point', function () {
      return this.get('point.y');
    }),
    actions: {
      /**
       * Triggers the event `onAddLocation`
       */
      addLocationClicked: function addLocationClicked(latLng) {
        this.addLocation({
          latLng: latLng
        });
      }
    }
  });

  _exports.default = _default;
});