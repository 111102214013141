define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/mailbox/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'mailboxCheckRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.mailboxCheckRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.mailboxCheckRate.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    mailboxCheckRate: null,

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    mailboxCheckRateSelected: function () {
      var options = this.get('mailboxCheckRateOptions');
      var option = options.findBy('value', this.get('mailboxCheckRate.value'));

      if (!option) {
        option = options.findBy('value', '480');
      }

      return option;
    }.property('mailboxCheckRate.value'),

    /**
    * The options for the mailbox check rate dropdown 
    */
    mailboxCheckRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '5',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '10',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '15',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '20',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinutes', {
          count: 60
        })
      }, {
        value: '90',
        label: intl.t('parameter.misc.countMinutes', {
          count: 90
        })
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 120
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 180
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 240
        })
      }, {
        value: '360',
        label: intl.t('parameter.misc.countMinutes', {
          count: 360
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 480
        })
      }, {
        value: '720',
        label: intl.t('parameter.misc.countMinutes', {
          count: 720
        })
      }];
    }.property(),
    actions: {
      mailboxCheckRateChange: function mailboxCheckRateChange(selected) {
        this.set('mailboxCheckRate.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('mailboxCheckRate').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});