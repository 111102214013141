define("sky-router-3/pods/components/portal/settings/push-notifications/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    eventsSelected: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default2 = Ember.Component.extend(VALIDATIONS, {
    user: null,
    events: null,
    eventsSelected: [],
    actions: {
      eventsSelected: function eventsSelected(events) {
        if (!events.length) {
          this.set('user.receivePushNotifications', false);
        }

        this.set('eventsSelected', events);
      },
      addAll: function addAll() {
        this.eventsSelected.addObjects(this.events);
      },
      default: function _default() {
        var _this = this;

        this.set('blockForm', true);
        this.onreset().then(function () {
          return _this.set('blockForm', false);
        });
      }
    }
  });

  _exports.default = _default2;
});