define("sky-router-3/pods/components/portal/operations/trips/progress-bar/bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents a progress bar used for the progress-bar component
   */
  var _default = Ember.Object.extend({
    statusId: null,
    status: null,
    positionType: null,
    //positions: [], // NOT USED FOR ANYTHING RIGHT NOW
    startDate: null,
    endDate: null,
    overalDuration: 0,
    calculatedElapseTime: null,
    seconds: Ember.computed('startDate', 'endDate', 'calculatedElapseTime', function () {
      return this.calculatedElapseTime ? this.calculatedElapseTime : (this.endDate - this.startDate) / 1000;
    }),
    size: Ember.computed('startDate', 'endDate', 'overalDuration', function () {
      return Math.round((this.endDate - this.startDate) / this.overalDuration * 100);
    }),
    style: Ember.computed('size', function () {
      return Ember.String.htmlSafe("width:".concat(this.size, "%;"));
    })
  });

  _exports.default = _default;
});