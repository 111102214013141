define("sky-router-3/pods/portal/manage/burst-services/geo-area-edit/route", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _create, _stateTrackMap, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),
    permissions: ['manage.burstservices'],
    geoAreaService: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        mapConfig: (0, _create.default)(_stateTrackMap.default),
        mapConfigView: (0, _create.default)(_stateTrackMap.default),
        geoAreaGroup: this.store.findRecord('burst-service-geo-area-group', params.geoAreaGroup_id) // geoAreas: this.store.findAll('burst-service-geo-area')

      });
    },
    afterModel: function afterModel(model) {
      this.geoAreaService.fitMapToGeoAreas(model.geoAreaGroup.geoAreas, model.mapConfigView);
    },
    actions: {
      mapAction: function mapAction() {// do nothing
      },
      deleteGeoArea: function deleteGeoArea(geoArea) {
        if (confirm(this.intl.t('burstServices.deleteConfirm', {
          name: geoArea.get('name')
        }))) {
          var geoAreaGroup = this.modelFor(this.routeName).geoAreaGroup;
          var mapConfigView = this.modelFor(this.routeName).mapConfigView;
          geoAreaGroup.geoAreas.removeObject(geoArea);
          this.geoAreaService.fitMapToGeoAreas(geoAreaGroup.geoAreas, mapConfigView);
        }
      },
      cancel: function cancel() {
        var model = this.modelFor(this.routeName);
        model.geoAreaGroup.rollbackAttributes();
        this.transitionTo('portal.manage.burst-services.geo-area-group');
      },
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);
        model.geoAreaGroup.rollbackAttributes();
        this.set('geoAreaToEdit', null);
        this.set('geoAreaLocationMapToEdit', null);
      },
      save: function save() {
        var _this = this;

        this.send('blockApp', true);
        var geoAreaGroup = this.modelFor(this.routeName).geoAreaGroup; // Remove the id from newly created (and not saved geoAreas) - they are assigned by the database

        geoAreaGroup.geoAreas.forEach(function (geo) {
          return geo.id = geo.id > 1000000 ? null : geo.id;
        });
        geoAreaGroup.save().then(function () {
          _this.notifications.success(_this.intl.t('profiles.newSuccess'));

          _this.transitionTo('portal.manage.burst-services.geo-area-group');
        }).catch(function (error) {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});