define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-21/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KEUyjbTL",
    "block": "{\"symbols\":[\"op\"],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.condition.accelerometer.help\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.condition.accelerometer.help2\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n            \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compoundrules.condition.accelerometer.typeLabel\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],null,[[\"options\",\"disabled\",\"selected\",\"onChange\"],[[35,9],[35,2],[35,8],[30,[36,7],[[32,0],\"setExceedence\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n                \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"compoundrules.condition.accelerometer.accelLabel\"],null]]],\"parameters\":[]}]]],[2,\"\\n                \"],[1,[30,[36,4],null,[[\"class\",\"val\",\"sync\",\"type\",\"min\",\"max\",\"step\",\"disabled\"],[\"form-control\",[35,3],false,\"number\",\"0\",\"4.00\",\"0.1\",[35,2]]]]],[2,\"\\n                \"],[1,[30,[36,6],null,[[\"errors\"],[[35,5,[\"attrs\",\"typeCustom\",\"messages\"]]]]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"disabled\",\"typeCustom\",\"input-debounced\",\"validations\",\"input-errors\",\"action\",\"typeSelected\",\"types\",\"input-select\",\"typeCustomVisible\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-21/template.hbs"
  });

  _exports.default = _default;
});