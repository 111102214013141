define("sky-router-3/pods/portal/track/manual-position/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.position.asset': (0, _emberCpValidations.validator)('presence', true) //'model.position.headingDegrees': validator('presence', true),
    //'model.position.description': validator('presence', true),
    // 'model.position.utc': validator('date', {
    //     onOrBefore: 'now',
    //     precision: 'day'
    // })

  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    queryParams: ['expanded', 'latitude', 'longitude'],
    expanded: false,
    latitude: null,
    longitude: null,
    dateType: 'now',
    rotateBinding: Ember.computed('model.position.headingDegrees', function () {
      return "transform:rotate(".concat(this.model.position.get('headingDegrees'), "deg)").htmlSafe();
    }),
    // altitudeUnitSelected: computed('sessionAccount.user.altitude', function(){
    //     return this.sessionAccount.user.get('altitude')
    // }),
    // speedUnitSelected: computed('sessionAccount.user.speedOther', function(){
    //     return this.sessionAccount.user.get('speedOther')
    // }),
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      },
      selectType: function selectType(position, type) {
        position.set('type', type);
        position.set('description', type.name);
      }
    }
  });

  _exports.default = _default;
});