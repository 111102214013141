define("sky-router-3/pods/portal/communicate/folder/message/controller", ["exports", "sky-router-3/models/mail-folder"], function (_exports, _mailFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Indicates if the message resides in the trash folder
     * @type {Boolean}
     */
    canBeMovedToTrash: function () {
      var id = this.get('model.folder.id');
      return id !== _mailFolder.MAIL_FOLDERS.trash && id !== _mailFolder.MAIL_FOLDERS.sent && id !== _mailFolder.MAIL_FOLDERS.drafts;
    }.property('model.folder.id'),

    /**
     * Alias to the trash folder so that the UI can use it.
     * @type {string}
     */
    trashFolderId: _mailFolder.MAIL_FOLDERS.trash,

    /**
     * Indicates if the user can actually move the message to another folder.
     * The sent and drafts folders are special folders so things can't be moved
     * in and out of them.
     * @type {Boolean}
     */
    canMoveMessage: function () {
      var id = this.get('model.folder.id');
      return id !== _mailFolder.MAIL_FOLDERS.sent && id !== _mailFolder.MAIL_FOLDERS.drafts;
    }.property('model.folder.id'),

    /**
     * Indicates if the user has started moving the mail message.
     * @type {Boolean}
     */
    isMovingMessage: false,

    /**
     * The ember store service
     */
    store: Ember.inject.service(),

    /**
     * Holds a reference to the folder that the mail message should be moved to.
     * @type {sky-router-3/models/mail-folder}
     */
    moveToFolder: null,

    /**
     * Array of folders that the user can move this message to.
     * Note: This is not promise safe and assumes that all the info is loaded
     * in previous routes.
     * @return {sky-router-3/models/mail-folder[]}
     */
    moveableFolders: function () {
      var currentFolderId = this.get('model.folder.id');
      return this.store.peekAll('mail-folder').filter(function (folder) {
        return (folder.get('id') === _mailFolder.MAIL_FOLDERS.inbox || !folder.get('isReservedFolder')) && folder.get('id') !== currentFolderId;
      });
    }.property('model.folder.id'),
    actions: {
      startMovingMessage: function startMovingMessage() {
        this.set('isMovingMessage', true);
        this.set('moveToFolder', null);
      },
      cancelMovingMessage: function cancelMovingMessage() {
        this.set('isMovingMessage', false);
        this.set('moveToFolder', null);
      },
      performMoveMessage: function performMoveMessage() {
        this.send('moveMessage', this.get('model'), this.get('moveToFolder.id'));
        this.send('cancelMovingMessage');
      }
    }
  });

  _exports.default = _default;
});