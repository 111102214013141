define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/tracking-enabled/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'secondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.secondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.secondsSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    onEnabled: function onEnabled() {},

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
    * The options for the time reporting dropdown
    */
    timeReportingOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '360',
        label: intl.t('parameter.misc.countMinutes', {
          count: 6
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '720',
        label: intl.t('parameter.misc.countMinutes', {
          count: 12
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '5400',
        label: intl.t('parameter.misc.countMinutes', {
          count: 90
        })
      }, {
        value: '7200',
        label: intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '10800',
        label: intl.t('parameter.misc.countHours', {
          count: 3
        })
      }, {
        value: '14400',
        label: intl.t('parameter.misc.countHours', {
          count: 4
        })
      }, {
        value: '21600',
        label: intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '28800',
        label: intl.t('parameter.misc.countHours', {
          count: 8
        })
      }, {
        value: '43200',
        label: intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '86400',
        label: intl.t('parameter.misc.countHours', {
          count: 24
        })
      }, {
        value: '-1',
        label: intl.t('parameter.misc.burst')
      }];
    }.property()
  });

  _exports.default = _default;
});