define("sky-router-3/pods/portal/manage/alerts/edit/controller", ["exports", "sky-router-3/models/alert-definition", "ember-cp-validations"], function (_exports, _alertDefinition, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.alert.name': (0, _emberCpValidations.validator)('presence', true),
    'model.alert.alertTriggerEvent': (0, _emberCpValidations.validator)('presence', true),
    'model.alert.recipients': [(0, _emberCpValidations.validator)('email', {
      disabled: Ember.computed.alias('model.isSmsType'),
      allowMultiple: true,
      allowEmpty: true,
      dependentKeys: ['model.alert.recipients.[]']
    }), (0, _emberCpValidations.validator)('phone', {
      disabled: Ember.computed.not('model.isSmsType'),
      dependentKeys: ['model.alert.recipients.[]']
    })],
    'model.alert.airportId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.model.alert.alertTriggerEvent.isAirportSelection')
    }),
    'model.alert.locationId': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.model.alert.alertTriggerEvent.isLocationSelection')
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    /**
     * The translation service.
     */
    intl: Ember.inject.service(),

    /**
     * Setup the validations that are used in the UI.
     */
    alert: Ember.computed.alias('model.alert'),

    /**
     * Indicates if the alert type has a subject field
     */
    hasSubjectField: Ember.computed.equal('model.alert.type', _alertDefinition.ALERT_DEFINITION_TYPE.email),
    isSmsType: Ember.computed.equal('model.alert.type', _alertDefinition.ALERT_DEFINITION_TYPE.sms),

    /**
     * Gets a list of supported alert options as not everything can be alerted on.
     */
    eventOptions: Ember.computed.filterBy('model.alertTriggerEvents', 'supportAlertAction'),

    /**
     * Initial airport list made up of the existing alert airport
     */
    airportOptions: function () {
      return [{
        id: this.get('model.alert.airportId'),
        name: this.get('model.alert.airportName')
      }];
    }.property('model.alert.airportId'),

    /**
     * The selected airport object created from the airport properties. This is to set the initial value on
     * load of an existing airport alert
     */
    selectedAirport: function () {
      return Ember.Object.create({
        id: this.get('model.alert.airportId'),
        name: this.get('model.alert.airportName')
      });
    }.property('model.alert.airportId'),

    /**
     * The selected location object created from the location properties. This is to set the initial value on
     * load of an existing location alert
     */
    selectedLocation: function () {
      return this.get('model.locations').findBy('id', String(this.get('model.alert.locationId')));
    }.property('model.alert.locationId'),

    /**
     * The currently selected alert type
     * @return {Object}
     */
    selectedAlertType: function () {
      return this.get('model.alertTypes').findBy('id', this.get('model.alert.type'));
    }.property('model.alert.type'),

    /**
     * The currently selected
     * @return {[type]} [description]
     */
    selectedTimeBetweenAlerts: function () {
      return this.get('model.timeBetweenAlerts').findBy('id', this.get('model.alert.secondsBetweenAlerts'));
    }.property('model.alert.secondsBetweenAlerts'),
    actions: {
      changeSelectedType: function changeSelectedType(selected) {
        this.set('model.alert.type', selected.id);
      },
      addObjectsToArray: function addObjectsToArray(arr, objects) {
        arr.addObjects(objects);
      },
      clearArray: function clearArray(arr) {
        arr.clear();
      },
      toggleFlag: function toggleFlag(alertFlags, flag, checked) {
        if (checked) {
          alertFlags.addObject(flag);
        } else {
          alertFlags.removeObject(flag);
        }
      },
      addRecipient: function addRecipient(args) {
        this.get('model.alert.recipients').addObject(args.addedToken.value);
      },
      removeRecipient: function removeRecipient(args) {
        this.get('model.alert.recipients').removeObject(args.removedToken.value);
      },
      changeTimeBetweenAlerts: function changeTimeBetweenAlerts(selected) {
        this.set('model.alert.secondsBetweenAlerts', selected.id);
      },

      /**
       * Sets the alert trigger event and clears the airport fields when the
       **/
      alertTriggerEventChanged: function alertTriggerEventChanged(selected) {
        this.set('model.alert.alertTriggerEvent', selected);
        this.set('model.alert.airportId', null);
        this.set('model.alert.airportName', null);
        this.set('model.alert.locationId', null);
      },

      /**
       * Set the alert properties on selection change
       **/
      airportChanged: function airportChanged(selected) {
        this.set('model.alert.airportName', selected.name);
        this.set('model.alert.airportId', selected.id);
      },

      /**
       * Set the alert properties on selection change
       **/
      locationChanged: function locationChanged(selected) {
        this.set('model.alert.locationId', selected.id);
      }
    }
  });

  _exports.default = _default;
});