define("sky-router-3/pods/components/portal/settings/contact-info/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'user.firstName': (0, _emberCpValidations.validator)('presence', true),
    'user.lastName': (0, _emberCpValidations.validator)('presence', true),
    'user.email': (0, _emberCpValidations.validator)('email')
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * The user that this component is editing
     * @type {sky-router-3/models/user}
     */
    user: null,

    /**
     * Allows the editing of the users selected language
     * @type {Boolean}
     */
    editLanguage: false,

    /**
     * Allow the editing of the company field
     * @type {Boolean}
     */
    editCompany: false
  });

  _exports.default = _default;
});