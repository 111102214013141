define("sky-router-3/pods/portal/manage/personnel/index/controller", ["exports", "sky-router-3/utils/string-search"], function (_exports, _stringSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search', 'includeDisabled'],

    /**
     * Filtering
     */
    search: null,
    includeDisabled: false,
    filteredModel: Ember.computed.filter('model', ['search', 'model.[]'], function (item) {
      if (this.search) {
        return (0, _stringSearch.default)(item.name, this.search) || (0, _stringSearch.default)(item.notes, this.search);
      }

      return true;
    }),

    /**
     * The property by which locations are sorted
     * @type {String}
     */
    sortProperty: 'name',

    /**
     * The direction that the list is sorted
     * @type {Boolean}
     */
    sortDesc: false,

    /**
     * Returns a string used by sortedLocations to sort the collection
     * @return {Array<string>}
     */
    sortBy: function () {
      return [this.get('sortProperty') + (this.get('sortDesc') ? ':desc' : ':asc')];
    }.property('sortProperty', 'sortDesc'),

    /**
     * The sorted users output list
     */
    sortedPersonnel: Ember.computed.sort('filteredModel', 'sortBy')
  });

  _exports.default = _default;
});