define("sky-router-3/models/asset-profile-set-revision-setting", ["exports", "@ember-data/model", "sky-router-3/utils/computed-bool-string"], function (_exports, _model, _computedBoolString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    revision: (0, _model.belongsTo)('asset-profile-set-revision'),
    key: (0, _model.attr)('string'),
    value: (0, _model.attr)('string'),
    valueBoolean: (0, _computedBoolString.default)('value')
  });

  _exports.default = _default;
});