define("sky-router-3/pods/components/portal/track/map/overlays/global-notams/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object", "sky-router-3/utils/lat-lng-converter", "sky-router-3/utils/color-functions"], function (_exports, _googleObjectMapping, _computedJsonObject, _latLngConverter, _colorFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Array of data features that are present in the data object.
     * @type {Array}
     */
    features: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }, {
      event: 'addfeature',
      eFunc: '_addFeature'
    }, {
      event: 'removefeature',
      eFunc: '_remFeature'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each record downloaded.
     * It is organized so that the ones with the smallest area are on top.
     */
    style: function () {
      var _this = this;

      return function (feature) {
        var color = _this._getColor(feature);

        return {
          strokeWeight: 3,
          strokeColor: color,
          fillColor: color,
          fillOpacity: 0.05
        };
      };
    }.property(),
    labelFeatures: function () {
      var _this2 = this;

      return this.get('features').map(function (feature) {
        var color = _this2._getColor(feature);

        var text = (0, _colorFunctions.offsetTextColor)(color);
        return {
          label: feature.getProperty('name'),
          latLng: _this2._calculateGeometryCenter(feature),
          style: "background-color:".concat(color, ";color:").concat(text, ";border-radius:3px;padding:2px;white-space:nowrap;").htmlSafe()
        };
      });
    }.property('features.[]'),
    _getColor: function _getColor(feature) {
      switch (feature.getProperty('entity')) {
        case 'A':
          return '#00FFFF';

        case 'C':
          return '#00FF00';

        case 'F':
          return '#008000';

        case 'G':
          return '#500090';

        case 'I':
          return '#A000A0';

        case 'L':
          return '#FF7900';

        case 'M':
          return '#B7410E';

        case 'N':
          return '#e2f700';

        case 'O':
          return '#00f777';

        case 'P':
          return '#75aeff';

        case 'R':
          return '#ff1414';

        case 'S':
          return '#00AAFF';

        case 'W':
          return '#FF7900';
      }
    },

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoOpen', true);
      this.set('infoFeature', this._getWindowInfo(mouseEvent.feature));
      this.set('infoCenter', mouseEvent.latLng);
      dataObject.overrideStyle(mouseEvent.feature, {
        strokeWeight: 4
      });
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout(dataObject, mouseEvent) {
      this.set('infoOpen', false);
      this.set('infoFeature', null);
      dataObject.revertStyle(mouseEvent.feature);
    },

    /**
     * Launches the FAA link for the TFR.
     */
    _click: function _click(dataObject, mouseEvent) {
      this.set('popupBody', this._getWindowInfo(mouseEvent.feature));
      this.set('popupOpen', true);
    },

    /**
     * Keeps ember in sync with the google maps data object
     */
    _addFeature: function _addFeature(data, evt) {
      this.get('features').addObject(evt.feature);
    },
    _remFeature: function _remFeature(data, evt) {
      this.get('features').removeObject(evt.feature);
    },

    /**
     * Gets the center of the TFR geometry so the info window has somewhere to anchor to.
     */
    _calculateGeometryCenter: function _calculateGeometryCenter(feature) {
      var _feature$getGeometry;

      var bounds = new google.maps.LatLngBounds();
      (_feature$getGeometry = feature.getGeometry()) === null || _feature$getGeometry === void 0 ? void 0 : _feature$getGeometry.forEachLatLng(function (latLng) {
        bounds.extend(latLng);
      });
      return bounds.getCenter();
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getWindowInfo: function _getWindowInfo(feature) {
      return {
        notamid: feature.getProperty('notamid'),
        name: feature.getProperty('name'),
        qcode: feature.getProperty('qcode'),
        message: feature.getProperty('message'),
        country: feature.getProperty('country'),
        facility: feature.getProperty('facility'),
        created: feature.getProperty('created'),
        active: feature.getProperty('active'),
        expires: feature.getProperty('expires'),
        lowerbound: feature.getProperty('lowerbound'),
        upperbound: feature.getProperty('upperbound'),
        subarea: feature.getProperty('subarea'),
        subject: feature.getProperty('subject'),
        condition: feature.getProperty('condition'),
        raw: feature.getProperty('raw')
      };
    },
    init: function init() {
      this.set('features', []);

      this._super.apply(this, arguments);
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function () {
      var _this3 = this;

      Ember.run.join(function () {
        var map = _this3.get('map');

        var bounds = map.getBounds();

        if (!bounds) {
          return;
        }

        var url = _this3.get('dataObject.url');

        if (!url) {
          return;
        }

        var opts = {
          data: {
            bbox: _latLngConverter.default.getBBoxString(bounds),
            subjects: _this3.getSubjects().join(','),
            condition: _this3.get('settings.generic.notamcondition') || 'all'
          }
        };

        _this3.get('api').request(url, opts).then(function (x) {
          var obj = _this3.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    }.observes('settings.generic.notamcondition', 'settings.generic.notamhideSubjectA', 'settings.generic.notamhideSubjectC', 'settings.generic.notamhideSubjectF', 'settings.generic.notamhideSubjectG', 'settings.generic.notamhideSubjectI', 'settings.generic.notamhideSubjectL', 'settings.generic.notamhideSubjectM', 'settings.generic.notamhideSubjectN', 'settings.generic.notamhideSubjectO', 'settings.generic.notamhideSubjectP', 'settings.generic.notamhideSubjectR', 'settings.generic.notamhideSubjectS', 'settings.generic.notamhideSubjectW'),
    getSubjects: function getSubjects() {
      var subjects = [];

      if (!this.get('settings.generic.notamhideSubjectA')) {
        subjects.push('A');
      }

      if (!this.get('settings.generic.notamhideSubjectC')) {
        subjects.push('C');
      }

      if (!this.get('settings.generic.notamhideSubjectF')) {
        subjects.push('F');
      }

      if (!this.get('settings.generic.notamhideSubjectG')) {
        subjects.push('G');
      }

      if (!this.get('settings.generic.notamhideSubjectI')) {
        subjects.push('I');
      }

      if (!this.get('settings.generic.notamhideSubjectL')) {
        subjects.push('L');
      }

      if (!this.get('settings.generic.notamhideSubjectM')) {
        subjects.push('M');
      }

      if (!this.get('settings.generic.notamhideSubjectN')) {
        subjects.push('N');
      }

      if (!this.get('settings.generic.notamhideSubjectO')) {
        subjects.push('O');
      }

      if (!this.get('settings.generic.notamhideSubjectP')) {
        subjects.push('P');
      }

      if (!this.get('settings.generic.notamhideSubjectR')) {
        subjects.push('R');
      }

      if (!this.get('settings.generic.notamhideSubjectS')) {
        subjects.push('S');
      }

      if (!this.get('settings.generic.notamhideSubjectW')) {
        subjects.push('W');
      }

      if (subjects.length == 0) {
        subjects.push('none');
      }

      return subjects;
    },

    /**
     * Forces the reloading of the metar data when the map moves or is zoomed.
     */
    autoReloadGeoJson: function () {
      Ember.run.debounce(this, this.loadGeoJson, 500);
    }.observes('mapConfig.center', 'mapConfig.zoom'),

    /**
     * Creates a bounds listener so that the overlay boots when the map finally loads.
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.mapBoundsListener = this.get('map').addListener('bounds_changed', Ember.run.bind(this, this.autoReloadGeoJson));
    },

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this4 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this4.loadGeoJson();

        _this4.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },
    actions: {
      closePopup: function closePopup() {
        this.set('popupOpen', false);
        this.set('popupBody', null);
      }
    }
  });

  _exports.default = _default;
});