define("sky-router-3/models/asset-position", ["exports", "@ember-data/model", "sky-router-3/utils/computed-promise"], function (_exports, _model, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PROVIDERS = _exports.default = void 0;

  /* eslint-env node */

  /**
   * A constant object that provides the string values returned by the
   * providers property of the model below.
   * @type {Object}
   */
  var PROVIDERS = {
    gsm: 'Cell',
    iridium: 'Iridium',
    adsb: 'ADSB',
    quickLynk: 'QuickLynk'
  };
  /**
   * This model represents asset position report events which are locations the
   * asset has been over time. An asset may have many of these events or it may
   * have none.
   *
   * Each event is representable on a map as a location and includes
   * contextual information about what was happening to the asset at the time
   * the event occurred.
   */

  _exports.PROVIDERS = PROVIDERS;

  var model = _model.default.extend({
    api: Ember.inject.service(),

    /**
     * The date the record was loaded from the server in local time.
     * @type {Date}
     */
    loadedDate: Ember.computed('isLoaded', function () {
      return new Date();
    }),

    /**
     * When this is set to true notifications for this event appearing in ember
     * should not fire because the event is not new to the system as a whole.
     * @type {Boolean}
     */
    isHistorical: true,

    /**
     * The asset this position event belongs to.
     */
    asset: (0, _model.belongsTo)('asset', {
      async: true,
      inverse: 'positions'
    }),

    /**
     * The position report type associated with this position event
     */
    type: (0, _model.belongsTo)('asset-position-type', {
      async: true
    }),

    /**
     * The geographical location of this position event
     */
    latitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    altitudeCentimeters: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    pointIsValid: (0, _model.attr)('boolean', {
      defaultValue: true
    }),

    /**
     * The date/time in UTC for this event. This is the time the device
     * generated the event. It should be the date used to determine order
     * of events in regards to the position of assets.
     */
    utc: (0, _model.attr)('date'),

    /**
     * The date/time in UTC that this event arrived at the servers for processing
     * Sometimes assets lose connection to the server and will backlog events
     * that get sent at a later time. This date indicates when the server actually
     * got the event. This can be used to determine if an event is truely new to
     * the system.
     * @param  {[type]} 'date' [description]
     * @return {[type]}        [description]
     */
    arrivedUtc: (0, _model.attr)('date'),

    /**
     * Provider of the message. "Iridium", "GSM", "nal", etc..
     * Be careful of using this for display as the capitalization
     * and wording may change.
     */
    provider: (0, _model.attr)('string'),

    /**
     * returns if the event is iridium produced or not
     * @return {Boolean}
     */
    isIridium: function () {
      return this.get('provider') === PROVIDERS.iridium;
    }.property('provider'),

    /**
     * returns if the event is GSM produced or not
     * @return {Boolean}
     */
    isGSM: function () {
      return this.get('provider') === PROVIDERS.gsm;
    }.property('provider'),

    /**
     * returns if the event is ADSB produced or not
     * @return {Boolean}
     */
    isADSB: function () {
      return this.get('provider') === PROVIDERS.adsb;
    }.property('provider'),

    /**
     * returns if the event is quickLynk produced or not
     * @return {Boolean}
     */
    isQuickLynk: function () {
      return this.get('provider') === PROVIDERS.quickLynk;
    }.property('provider'),

    /**
     * A string that holds the description for the position event.
     * This may reflect the type name or it may not. This should
     * be used when displaying what this event is to the user.
     *
     * It should NOT be used to compare events.
     */
    description: (0, _model.attr)('string'),

    /**
     * Holds the speed information about this position event.
     */
    speedCms: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Holds the direction the asset was traveling in when the
     * event occurred. Values range from 0.0 to 360.0
     */
    headingDegrees: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * The horizontal dilution of precision for the position. This indicates the quality of the position.
     * 1 = Ideal, 1-2 = Excellent, 2-5 = Good, 5-10 = Moderate, 10+ = Bad
     */
    hdop: (0, _model.attr)('number'),

    /**
     * A object that holds generic properties that ember doesn't know the names of.
     * The key must be present in the list of available properties in the database.
     * @example { 'key': 'value', 'key': 'value'}
     */
    properties: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    /**
     * Gets a timezone object based on the position and time of this report
     * @type {Object}
     */
    timeZone: (0, _computedPromise.default)('latitudeMilliarcSeconds', 'longitudeMilliarcSeconds', function () {
      return this.api.getTimeZonePosition(this.get('latitudeMilliarcSeconds'), this.get('longitudeMilliarcSeconds'), this.get('utc'));
    }, null)
  });

  var _default = model;
  _exports.default = _default;
});