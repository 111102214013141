define("sky-router-3/helpers/display-milliarc", ["exports", "sky-router-3/utils/lat-lng-converter"], function (_exports, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This helper takes in a milliArcSecond value and turns it into the units which are
   * displayed to an end user. Those units may be in any format. The format is determined
   * by the users profile.
   *
   * @param {array} params - an array of incoming values that are passed to the helper via space
   * In this case there should be only 1 element which is the milliArcSecond value to convert.
   *
   * @returns {string} - the value of the units in a displayable string.
   *
   * @example {{display-milliArc event.latitudeMilliarcSeconds}}
   */
  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service(),
    compute: function compute(params
    /*, hash */
    ) {
      switch (this.get('sessionAccount').get('user.latLongOption.id')) {
        case "1":
          {
            // Degrees to 5 decimal places
            var value = Math.floor(_latLngConverter.default.milliArcSecondsToDecimalDeg(params[0]) * 100000) / 100000;
            return "".concat(value, "&deg;").htmlSafe();
          }

        case "2":
          // Deg, Minutes, Seconds with 3 decimals in the seconds
          return _latLngConverter.default.milliArcSecondsToDegMinSec(params[0]);

        case "3":
          // Degress, Decimal Minutes with 3 decimal places in the seconds
          return _latLngConverter.default.milliArcSecondsToDegDecimalMinutes(params[0]);
      }
    },

    /**
     * Watches for changes to the users lat/lon settings and recomputes
     */
    userPropertiesChanged: function () {
      this.recompute();
    }.observes('sessionAccount.user.latLongOption.id')
  });

  _exports.default = _default;
});