define("sky-router-3/pods/components/portal/track/map/asset-tag/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pBw+slM0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"strong\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[30,[36,2],null,[[\"cm\",\"asset\"],[[35,0,[\"positionLatest\",\"speedCms\"]],[35,0]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[1,[30,[36,1],null,[[\"cm\"],[[35,0,[\"positionLatest\",\"altitudeCentimeters\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"asset\",\"display-alt\",\"display-speed\",\"showSpeed\",\"if\",\"showAltitude\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/asset-tag/template.hbs"
  });

  _exports.default = _default;
});