define("sky-router-3/pods/components/input-token-autocomplete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kwYT2cLB",
    "block": "{\"symbols\":[\"result\",\"tag\",\"&default\"],\"statements\":[[6,[37,6],null,[[\"tags\",\"addTag\",\"removeTagAtIndex\",\"onKeyUp\",\"readOnly\"],[[35,5],[30,[36,0],[[32,0],\"add\"],null],[30,[36,0],[[32,0],\"removeAtIndex\"],null],[30,[36,0],[[32,0],\"keyUp\"],null],[35,4]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[35,7],[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"dropdown open\"],[12],[2,\"\\n        \"],[10,\"ul\"],[14,0,\"dropdown-menu dropdown-auto dropdown-scroll\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"li\"],[14,0,\"single-result\"],[12],[2,\"\\n                    \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"select\",[32,1]],null],[12],[2,\"\\n                        \"],[18,3,[[32,1]]],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"options\",\"-track-array\",\"each\",\"disabled\",\"tags\",\"tag-input\",\"focus\",\"and\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/input-token-autocomplete/template.hbs"
  });

  _exports.default = _default;
});