define("sky-router-3/pods/portal/operations/contracts/edit/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /**
     * The translation service
     */
    intl: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * Gets the model for the route. Since this route serves editing and adding
     * contracts it has logic to deal with it.
     */
    model: function model(params) {
      return Ember.RSVP.hash({
        contract: params.contract_id === "new" ? this.store.createRecord('contract') : this.store.findRecord('contract', params.contract_id),
        assets: this.store.findAll('asset'),
        groups: this.store.findAll('group'),
        contacts: this.store.findAll('contact')
      });
    },
    afterModel: function afterModel(model) {
      var date = (0, _moment.default)(new Date()).utc();
      var start = (0, _moment.default)(model.contract.get('startDate'));
      var end = (0, _moment.default)(model.contract.get('endDate'));
      model.startDateDisabled = date.isAfter(start);
      model.endDateDisabled = date.isAfter(end);

      if (model.contract.isNew) {
        model.contract.set('startDate', new Date());
      }
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.contract.get('isNew')) {
          model.contract.destroyRecord();
        } else {
          this.store.unloadAll('contract');
          this.store.unloadAll('asset');
          this.store.unloadAll('group');
        }
      },
      cancel: function cancel() {
        this.transitionTo('portal.operations.contracts');
      },
      save: function save() {
        var _this = this;

        this.send('blockApp', true);
        this.modelFor(this.routeName).contract.save().then(function () {
          _this.transitionTo('portal.operations.contracts');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('contracts.saveFail'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});