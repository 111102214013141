define("sky-router-3/helpers/make-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeArray = makeArray;
  _exports.default = void 0;

  /**
   * Converts the incoming parameter into an array. If an array is already passed then nothing is done.
   * @param params {array} array containing 1 object that is the incoming parameter.
   */
  function makeArray(params, hash) {
    var value = params[0];
    var arr;

    if (!value) {
      arr = [];
    } else if (Ember.isArray(value)) {
      arr = value;
    } else if (hash.split) {
      arr = value.split(hash.split);
    } else {
      arr = [value];
    }

    if (hash.mapBy) {
      arr = arr.mapBy(hash.mapBy);
    }

    return arr;
  }

  var _default = Ember.Helper.helper(makeArray);

  _exports.default = _default;
});