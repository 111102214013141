define("sky-router-3/utils/convert-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertObject;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Converts an object from properties that have starting capital letters to those that do not.
   * @see https://stackoverflow.com/questions/12539574/whats-the-best-way-most-efficient-to-turn-all-the-keys-of-an-object-to-lower
   * @param  {[type]} obj [description]
   * @return {[type]}     [description]
   */
  function convertObject(obj) {
    var convertType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'camelize';
    var useEmberObject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var convert = convertType == 'camelize' ? Ember.String.camelize : Ember.String.classify;
    var initialIsArray = Array.isArray(obj);
    var initial = null;

    if (initialIsArray) {
      initial = [];
    } else {
      if (useEmberObject) {
        initial = Ember.Object.create();
      } else {
        initial = {};
      }
    }

    return Object.keys(obj).reduce(function (newObj, key) {
      var val = obj[key];

      if (val !== null && _typeof(val) === 'object') {
        val = convertObject(val, convertType, useEmberObject);
      }

      if (Array.isArray(val)) {
        val = val.map(function (subObj) {
          return convertObject(subObj, convertType, useEmberObject);
        });
      }

      newObj[convert(key)] = val;
      return newObj;
    }, initial);
  }
});