define("sky-router-3/pods/components/icon-asset/component", ["exports", "sky-router-3/models/asset-position-type"], function (_exports, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is responsible for creating an SVG representation of an asset.
   * It takes in several properties that manipulates the appearance of the icon.
   */
  var _default = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),

    /**
     * Width & height of the SVG as a square
     * @type {String}
     */
    size: '100%',
    _icon: undefined,
    _isInactive: undefined,

    /**
     * The asset to display
     * @type {sky-router-3/models/asset}
     */
    asset: null,
    icon: Ember.computed('_icon', 'asset.icon', 'asset.icon.id', {
      get: function get() {
        var _this$get;

        return (_this$get = this.get('_icon')) !== null && _this$get !== void 0 ? _this$get : this.asset.get('icon');
      },
      set: function set(key, value) {
        return this.set('_icon', value);
      }
    }),

    /**
     * Indicates if the device is in the inactive state
     * @return {Boolean}
     */
    isInactive: Ember.computed('_isInactive', 'asset.positionLatest', {
      get: function get() {
        var inactive = this.get('_isInactive');

        if (inactive !== undefined) {
          return inactive;
        }

        var latest = this.get('asset.positionLatest');
        return !latest || latest.belongsTo('type').id() === _assetPositionType.POSITION_TYPES.Inactive;
      },
      set: function set(key, value) {
        return this.set('_isInactive', value);
      }
    }),

    /**
     * The color the asset will take on.
     * It needs to be in CSS format'#FFFFFF'.
     * @type {String}
     */
    color: function () {
      if (this.get('sessionAccount.user.grayoutInactiveAssets') && this.get('isInactive')) {
        return '#999999';
      }

      return this.get('asset.color');
    }.property('asset.color', 'sessionAccount.user.grayoutInactiveAssets', 'isInactive'),

    /**
     * Rotation of the asset in degrees.
     * @type {Number}
     */
    rotation: function () {
      var position = this.get('asset.positionLatest');

      if (position) {
        return position.get('headingDegrees');
      }

      return 0;
    }.property('asset.positionLatest.headingDegrees'),

    /**
     * Indicates if portions of the assets template that may change if the asset
     * is active will be active or not. Aka, helicopter blades spinning.
     * @type {Boolean}
     */
    isActive: function () {
      var latest = this.get('asset.positionLatest');

      if (latest) {
        var id = latest.belongsTo('type').id();
        return id !== _assetPositionType.POSITION_TYPES.Inactive && id !== _assetPositionType.POSITION_TYPES.PowerOff;
      }

      return false;
    }.property('asset.positionLatest'),
    style: Ember.computed('rotation', 'color', 'size', 'icon.orientation', function () {
      var transform = '';

      switch (this.get('icon.orientation')) {
        case 0:
          // Top down
          transform = "transform:rotate(".concat(this.rotation, "deg);");
          break;

        case 1:
          // Side view with front being to the right
          if (this.rotation == 0) {
            transform = '';
          } else if (this.rotation < 180) {
            transform = "transform:rotate(".concat(this.rotation - 90, "deg)");
          } else if (this.rotation >= 180) {
            transform = "transform:scaleX(-1) rotate(".concat(270 - this.rotation, "deg)");
          }

          break;

        default:
        case 2:
          // Front view, do nothing
          break;
      }

      return Ember.String.htmlSafe("width:".concat(this.size, ";max-height:").concat(this.size, ";fill:").concat(this.color, ";").concat(transform));
    })
  });

  _exports.default = _default;
});