define("sky-router-3/pods/portal/communicate/compose/route", ["exports", "sky-router-3/models/mail-folder"], function (_exports, _mailFolder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.compose');
    },
    actions: {
      /**
       * Saves a message draft to the server
       * @param  {sky-router-3/models/mail-message} message
       */
      saveDraft: function saveDraft(message) {
        var _this = this;

        message.save().then(function (message) {
          // Saving or updating a draft causes issues with its identifier
          // we have to back out of the route and unload the record so that
          // the application state will remain clean.
          _this.store.unloadRecord(message);

          _this.transitionTo('portal.communicate.folder', _mailFolder.MAIL_FOLDERS.drafts);
        }).catch(function () {
          _this.notifications.error(_this.intl.t('communicate.draftSaveFailed'));
        });
      },

      /**
       * Sends a message and navigates the user back to the index page.
       * @param  {sky-router-3/models/mail-message} message
       */
      sendMessage: function sendMessage(message) {
        var _this2 = this;

        this.get('api').mailMessageSend(message.toJSON()).then(function () {
          // If the message is a message that was already in a Folder
          // then make sure the message is now destroyed because it is
          // no longer needed. Also reload the stats on the folder.
          //if (!message.get('isNew')) {
          var folder = message.get('folder');
          message.destroyRecord().then(function () {
            folder.then(function (f) {
              if (f) {
                f.reload();
              }
            }); // Bring them back to the inbox since
            // the message is now in the sent folder but we have no way
            // of directing them to it.

            _this2.replaceWith('portal.communicate.folder', _mailFolder.MAIL_FOLDERS.sent);
          });
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('communicate.sentMessageFailed'));
        });
      }
    }
  });

  _exports.default = _default;
});