define("sky-router-3/validators/email", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extends the base validator to allow for validation of email addresses.
   *
   * This object will attempt to lookup the localized error message using the key 'errors.email'
   *
   * The options object used when creating this validator is formatted like This
   *
   * {
   *    allowEmpty: true|false,
   *    allowMultiple: true|false,
   * }
   * Both properties default to false.
   */
  var Email = _base.default.extend({
    /**
     * Regex used to validate email addresses,
     * From here: http://www.regular-expressions.info/email.html
     */
    regex: /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,

    /**
     * Rejex used to validate email addresses. This is the same as above but with the addition
     * of a delimiter and mlutiple capture on the ';' or ',' key.
     */
    regexMultiple: /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:$|[,;]))+$/,

    /**
     * Runs the validator
     */
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      if (Ember.isEmpty(value)) {
        if (this.options.allowEmpty) {
          return true;
        }

        return this.createErrorMessage('email', value, options);
      } // If the value is an array then turn it back to a CSV to run the check on


      if (Ember.isArray(value)) {
        value = value.join(';');
      } // Run the regex


      var reg = this.options.allowMultiple ? this.regexMultiple : this.regex;

      if (!reg.test(value)) {
        return this.createErrorMessage('email', value, options);
      }

      return true;
    }
  });

  var _default = Email;
  _exports.default = _default;
});