define("sky-router-3/pods/portal/track/altitude/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    queryParams: {
      asset: {
        refreshModel: true
      }
    },
    titleToken: function () {
      return this.intl.t('altitudeGraph.title');
    }.property(),
    beforeModel: function beforeModel(transition) {
      if (this.get('sessionAccount.user.hideAssetAltitude')) {
        transition.abort();
        this.transitionTo('portal.track');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        assets: this.store.findAll('asset'),
        asset: params.asset ? this.store.findRecord('asset', params.asset) : null,
        altitudeUnit: this.get('sessionAccount.user.altitude')
      });
    },
    afterModel: function afterModel(model) {
      if (model.asset) {
        var timespan = this.get('sessionAccount.user.assetBreadcrumbTimespan');

        if (timespan < 0) {
          return this.store.query('asset-position', {
            imeis: [model.asset.get('id')],
            count: Math.abs(timespan)
          });
        } else if (timespan > 0) {
          return this.store.query('asset-position', {
            imeis: [model.asset.get('id')],
            limit: 200,
            afterDate: (0, _moment.default)().subtract(timespan, 'm').toISOString()
          });
        }
      }
    },
    actions: {
      selectAsset: function selectAsset(asset) {
        this.transitionTo({
          queryParams: {
            asset: asset.get('id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});