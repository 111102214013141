define("sky-router-3/pods/portal/manage/device-parameters/assignment/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        assignment: this.store.findRecord('asset-parameter-set-asset', params.asset_id),
        sets: this.store.query('asset-parameter-set', {
          imei: params.asset_id
        })
      });
    },
    resetController: function resetController(controller) {
      controller.set('selectedSet', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-parameters.assignment');
      },
      save: function save() {
        var _this = this;

        this.send('blockApp', true);
        var selectedSet = this.controllerFor(this.routeName).get('selectedSet');
        var assignment = this.modelFor(this.routeName).assignment;
        selectedSet.get('revisionLatest').then(function (revision) {
          _this.send('blockApp', false);

          _this.send('setRevision', assignment, revision);

          _this.transitionTo('portal.manage.device-parameters.assignment');
        }).catch(function () {
          _this.send('blockApp', false);

          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      }
    }
  });

  _exports.default = _default;
});