define("sky-router-3/pods/portal/manage/geofences/edit/speed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OmsGLD/S",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[10,\"img\"],[14,\"src\",\"/skyrouter3/assets/images/events/event_speeding.svg\"],[14,5,\"height:1.5em\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"geofence.edit.speed.titleLong\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"geofence.edit.speed.help1\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"warn\"],[12],[1,[30,[36,0],[\"geofence.edit.speed.help2\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"info\"],[12],[2,[30,[36,0],[\"geofence.edit.speed.help3\"],null]],[2,\".\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"checkbox\"],[12],[2,\"\\n\"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,10],null,[[\"checked\"],[[35,7]]]]],[1,[30,[36,0],[\"parameter.misc.enableFeature\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,11],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"geofence.edit.speed.title\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],null,[[\"round\",\"disabled\",\"valueCM\",\"minCM\",\"maxCM\",\"units\",\"unitSelected\",\"onUnitChange\"],[true,[30,[36,8],[[35,7]],null],[35,6],\"15\",\"9999900\",[35,5,[\"speedUnits\"]],[35,2],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"unitSelected\",\"mut\",\"action\",\"model\",\"speedLimitCms\",\"enabled\",\"not\",\"input-unit\",\"input-checkbox\",\"if\"]}",
    "moduleName": "sky-router-3/pods/portal/manage/geofences/edit/speed/template.hbs"
  });

  _exports.default = _default;
});