define("sky-router-3/pods/portal/manage/users/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      includeInactive: {
        refreshModel: true
      }
    },
    model: function model(options) {
      return this.store.query('user', {
        includeSelf: false,
        includeInactive: options.includeInactive
      });
    },
    resetController: function resetController(controller) {
      controller.set('selectedUsers', []);
    }
  });

  _exports.default = _default;
});