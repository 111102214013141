define("sky-router-3/utils/intl/missing-message", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  function missingMessage(key, locales, options) {
    var message = "Missing Translation for ".concat(key);

    if (_environment.default.APP.reportErrors) {
      var api = Ember.getOwner(this).lookup('service:api');
      api.logException(new Error(message)).catch(function () {});
    }

    if (locales.firstObject === 'en-us') {
      return message;
    }

    return this.lookup(key, 'en-us', options);
  }
});