define("sky-router-3/pods/components/portal/track/map/map-google/component", ["exports", "sky-router-3/utils/browser-detect", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter", "sky-router-3/models/asset-position-type", "jquery", "sky-router-3/utils/geoconversiontools/gars-conversions"], function (_exports, _browserDetect, _googleObjectMapping, _latLngConverter, _assetPositionType, _jquery, _garsConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Defines a google map component that meets the map interface.
   *
   * @example
   * {{map-google locations=model.locations config=model.mapConfig }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    classNames: ['map'],
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    intl: Ember.inject.service(),
    events: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * This groups the actions together for this map object
     * @event 'mapAction'
     * @property {string} actionName - the name of the sub-action to run
     *
     *      'clearFollowedAsset' - Stop following the asset that is currently being followed.
     *      'hideAssetsBreadcrumbs' - Hide breadcrumbs for the list of assets supplied
     *      'highlightedPositionClosed' - Called when the user has close the infoBox associated with a highlighted event.
     *      'addLocation' - When the user wants to add a location at a specific point
     *      'geofenceDelete' -When a user wishes to delete a geofence
     *      'loaded', - When the map is loaded for the first time
     *
     * @property {object} this map component
     * @property {object} individual argument object based on actions possible
     *
     *      'clearFollowedAsset' - No arguments
     *      'hideAssetsBreadcrumbs' - {string[]} of assetIds to hide breadcrumbs for
     *      'highlightedPositionClose' - No arguments
     *      'addLocation' - { {google.maps.latLng}latLng } where to add the location
     *      'geofenceDelete' - { {sky-router-3/models/geofence}geofence } the geofence instance to delete
     *      'loaded' - No arguments
     */
    mapAction: function mapAction() {},
    assetMarkerAction: function assetMarkerAction() {},
    locationMarkerAction: function locationMarkerAction() {},

    /**
     * Contains the mappings between properties that exist in the map object and properties
     * that exist in the ember object that need to be mapped together. This is used to initialize the property mixin
     */
    googlePropertyMap: [{
      event: 'center_changed',
      property: 'config.center',
      getFunc: 'getCenter',
      setFunc: 'setCenter',
      eFunc: 'changeCenter'
    }, {
      event: 'heading_changed',
      property: 'config.heading',
      getFunc: 'getHeading',
      setFunc: 'setHeading'
    }, {
      event: 'zoom_changed',
      property: 'config.zoom',
      getFunc: 'getZoom',
      setFunc: 'setZoom'
    }, {
      event: 'maptypeid_changed',
      property: 'config.mapTypeId',
      getFunc: 'getMapTypeId',
      setFunc: 'setMapTypeId'
    }, {
      event: 'rightclick',
      eFunc: 'contextMenuOpen'
    }, {
      event: 'click',
      eFunc: 'mapClicked'
    }],

    /**
     * This is used to initialize the property mixin.
     */
    googlePropertyObject: Ember.computed.alias('map'),

    /**
     * Holds a reference to the Google maps object that is created
     * when the map is initialized.
     * @private
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Holds a reference to the HTML node that the map element is
     * attached to. It is used to initialize the map before this
     * components element is known so that the map doesn't have
     * to be initialized in the wrong place in the run loop.
     */
    mapNode: null,

    /**
     * Holds the configuration object for this map.
     * The config object holds everything this map needs to take
     * the input object and show them on the map. It also contains
     * the current state of the map object that can be serialized
     * and de-serialized. When this config is restored the maps is
     * expected to return to the exact sate that it was when the
     * config was saved.
     * @public
     * @property
     */
    config: null,

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedLayerTabIndex: null,

    /**
     * Creates the options object that is used by Google maps during initialization
     * and refresh of the maps settings. The object is generated from the map config
     * and static sources. This is the main object that governs the creation of the map.
     * @function getInitializationOptions
     * @private
     * @returns {object} map-options object that can be used for map initialization
     */
    getMapOptions: function getMapOptions() {
      return {
        backgroundColor: 'transparent',
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        zoomControl: !(0, _browserDetect.isMobile)(),
        zoom: 2,
        controlSize: 26
      };
    },
    //#region Initialization Functions
    //-------------------------------------------------------------------------------------

    /**
     * Main initialization for the map object. This is the main entry-point for the map code
     * It must be run before the rest of the code. It creates a DOM element the map will be
     * attached to and then instantiates the map against that new node. It then saves the
     * node for latter insertion once this components element is determined.
     */
    init: function init() {
      var _this = this;

      // Create the node
      var node = document.createElement("div");
      $(node).addClass('map-canvas'); // Create the map

      var map = new google.maps.Map(node, this.getMapOptions());
      this.set('map', map);
      this.set('mapNode', node); // Attach the loaded listener

      google.maps.event.addListenerOnce(map, 'idle', function () {
        _this.fitBounds();

        _this.mapAction(_this, 'loaded');

        _this.set('ready', true);
      });
      return this._super.apply(this, arguments);
    },

    /**
     * This takes the `mapNode` from the initialization process and places it inside the
     * this components element. This completes the bootstrapping of the map.
     */
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)(this.element).prepend(this.mapNode);
    },
    //-------------------------------------------------------------------------------------
    //#endregion Initialization Functions
    //#region MAP MANIUPLATION

    /**
     * Moves the map to fit in the bounds supplied. It looks at the
     * config bounds property as everything in ember must be passed as
     * a data state. This clears the bounds state afterwards
     */
    fitBounds: function () {
      var bounds = this.get('config.fitBounds');

      if (bounds) {
        this.get('map').fitBounds(bounds);
        this.set('config.fitBounds', null);
      }
    }.observes('config.fitBounds'),
    changeCenter: function changeCenter(map) {
      this.mapAction(this, 'center', map.getCenter().toJSON());
    },
    //#endregion

    /**
     * Indicates that the users mouse clicks on the map triggers the distance tool to operate
     * @type {Boolean}
     */
    showDistanceTool: false,
    allowDistanceTool: true,
    //#region CONTEXT MENU

    /**
     * This is triggered when a bare spot on the map has been right clicked.
     * It opens the context menu for the map. Also attaches a handler that
     * listens for clicks elsewhere on the map to close the context menu.
     * @param {google.maps.map} map - the google.map object
     * @param {google.maps.MouseEvent} event - event object
     * @see https://developers.google.com/maps/documentation/javascript/reference#MouseEvent
     */
    contextMenuOpen: function contextMenuOpen(map, event) {
      // Run in next loop because gmaps 3.33 misidentified the context MENU
      // as the event source which caused the actualcontext menu to appear
      if (this.contextMenuAllow) {
        Ember.run.next(this, function () {
          this.set('contextMenuShow', true);
          this.set('contextMenuEvent', event);
        });
      }
    },

    /**
     * Indicates if the context menu is open or not.
     * @public
     * @property
     */
    contextMenuShow: false,
    contextMenuAllow: true,

    /**
     * The event that triggered the context menu to open.
     * @public
     * @property
     * @default
     * @type {google.maps.MouseEvent}
     */
    contextMenuEvent: null,
    //#endregion
    //#region LOCATIONS

    /**
     * Holds the passed in EmberData locations array that represents the location
     * data that is stored on the server.
     */
    locations: [],

    /**
     * Contains the id of the location in edit mode. If no location is in edit
     * mode then this is 0. When it edit mode the marker becomes movable and
     * a form is shown to the user.
     */
    locationEditId: 0,
    //#endregion
    //#region ASSETS

    /**
     * Holds the passed in EmberData assets array that represents the assets
     * that the user is able to see on the map. The items in the array may change
     * at any point as the asset are filtered.
     * @type {sky-router-3/models/asset}
     */
    assets: [],

    /**
     * Returns a reference to the asset that is currently being followed by this map
     * @type {sky-router-3/models/asset}
     */
    followedAsset: function () {
      var asset = this.get('config.followAssetId');

      if (asset) {
        return this.get('store').peekRecord('asset', this.get('config.followAssetId'));
      }

      return null;
    }.property('config.followAssetId'),

    /**
     * This watches for changes to the followed asset position and updates the map
     * center to its position.
     */
    followedAssetPositionChangedWatcher: function () {
      Ember.run.debounce(this, this.updateMapCenterToFollowedAsset, 150);
    }.observes('followedAsset.positions.[]'),

    /**
     * Helps prevent extra work by ember when the asset is loading in a lot of positions
     */
    updateMapCenterToFollowedAsset: function updateMapCenterToFollowedAsset() {
      var position = this.get('followedAsset.positionLatest');

      if (!!position && position.get('pointIsValid')) {
        var center = _latLngConverter.default.modelToGoogleLatLng(position);

        this.set('config.center', center);
      }
    },

    /**
     * Passthrough property to the marker-asset
     * @type {Number}
     */
    assetBreadcrumbTimespan: 0,

    /**
     * Passthrough property to the marker-asset
     * @type {Number}
     */
    assetBreadcrumbLimit: 200,

    /**
     * Holds an array of references to assets that currently have their breadcrumbs
     * shown.
     * @type {sky-router-3/models/asset}
     */
    assetsShowingBreadcrumbs: function () {
      var assetsShowingCrumbs = this.get('config.assetBreadcrumbsShownArray');
      return this.get('assets').filter(function (item) {
        return assetsShowingCrumbs.includes(item.get('id'));
      });
    }.property('config.assetBreadcrumbsShownArray.[]', 'assets.[]'),

    /**
     * Holds a value that indicates how asset tags are supposed to be shown.
     * Valid values are:
     *   0: Not shown
     *   1: Shown
     *   2: Show only for active assets
     * @type {Number}
     */
    assetTagSetting: 0,

    /**
     * Holds an array of all the assets that asset tags should be shown
     * for. This array is updated every time an asset moves its position.
     *
     * @type {sky-router-3/models/asset[]}
     */
    assetsShowingTags: function () {
      var tagSetting = this.get('assetTagSetting');
      var array = [];

      switch (tagSetting) {
        case 0:
          // Not shown
          array = [];
          break;

        case 1:
          // All (that have valid points)
          array = this.get('assets').filter(function (item) {
            return item.get('positionLatest.pointIsValid');
          });
          break;

        case 2:
          // Active assets only
          array = this.get('assets').filter(function (item) {
            return item.get('positionLatest.pointIsValid') && item.get('positionLatest').belongsTo('type').id() !== _assetPositionType.POSITION_TYPES.Inactive;
          });
      }

      return array;
    }.property('assets.[]', 'assetTagSetting', 'assets.@each.positionLatest'),
    //#endregion
    //#region EVENTS
    mapClicked: function mapClicked(map, mouseEvent) {
      this.events.trigger('mapClick', {
        latitudeMilliarcSeconds: _latLngConverter.default.degreesToMilliArcSeconds(mouseEvent.latLng.lat()),
        longitudeMilliarcSeconds: _latLngConverter.default.degreesToMilliArcSeconds(mouseEvent.latLng.lng())
      });
    },

    /**
     * Resolves a reference to the position event signified
     * in the highlightedPositionId from the config.
     *
     * @returns {Ember.RSVP.Promise<sky-router-3/models/position>}
     */
    highlightedPosition: function () {
      var positionId = this.get('config.highlightedPositionId');

      if (positionId) {
        return this.get('store').peekRecord('asset-position', positionId);
      }
    }.property('config.highlightedPositionId'),
    //#endregion
    //#region GEOArea

    /**
     * Holds the passed in EmberData geofences array that represents the geofences
     * that the user is able to see on the map. The items in the array may change
     * at any point as the geofences are filtered.
     * type {sky-router-3/models/burst-service-geo-area}
     */
    geoAreas: [],

    /**
     * This indicates that a geoArea is currently being edited when the value != 0
     */
    geoAreaEditId: 0,
    //#endregion
    //#region GEOFENCES

    /**
     * Holds the passed in EmberData geofences array that represents the geofences
     * that the user is able to see on the map. The items in the array may change
     * at any point as the geofences are filtered.
     * @type {sky-router-3/models/geofence}
     */
    geofences: [],

    /**
     * This indicates that a geofence is currently being edited when the value != 0
     */
    geofenceEditId: 0,
    //#endregion
    //#region overlays
    //#region CENTER POINT ON MAP
    searchUtmHemisphereValue: Ember.computed.reads('searchUtmHemisphereOptions.firstObject'),
    searchUtmHemisphereOptions: [{
      value: 'N'
    }, {
      value: 'S'
    }],
    //#endregion

    /**
     * Incomoing array of overlays to potentially display on the map.
     * @type {sky-router-3/models/overlay}
     */
    overlays: null,

    /**
     * Indicates if the overlay button is shown or not
     * @type {Boolean}
     */
    showOverlays: true,
    showOverlayModal: false,

    /**
     * Returns an array of all the visible overlays.
     * @return {Array<sky-router-3/models/overlay}
     */
    overlaysVisible: function () {
      var overlays = this.get('overlays');
      var results = [];
      this.get('config.overlayVisibleArray').forEach(function (id) {
        var olay = overlays.findBy('id', id);

        if (olay) {
          results.pushObject(olay);
        }
      });
      return results;
    }.property('config.overlayVisibleArray.[]', 'overlays.[]'),

    /**
     * Returns all the overlay groups that map to overlays that this application
     * supports displaying.
     * @return {sky-router-3/models/overlay-group}
     */
    overlayGroups: function () {
      var overlays = this.get('overlays');

      if (overlays) {
        var supportedOverlays = this.get('overlays').filterBy('type.canDisplay', true);
        return supportedOverlays.mapBy('group').uniq().sortBy('name');
      }

      return null;
    }.property('overlays.[]'),

    /**
     * Holds a groupping of overlays by sort group. Not all overlays can be
     * sorted between each other. Some overlay types cannot be placed ontop of
     * other types. Because of that types have sortGroups which can group
     * several different overlay types into a sortable group.
     * @return {Array<object>} Array of objects of the form { name: <string>, overlays: <array>}
     */
    overlaySortGroups: function () {
      var results = [];
      this.get('overlaysVisible').forEach(function (o) {
        var name = o.get('type.orderGroup');

        if (name) {
          var group = results.findBy('name', name);

          if (!group) {
            group = Ember.Object.create({
              name: name,
              overlays: []
            });
            results.pushObject(group);
          }

          group.get('overlays').pushObject(o);
        }
      });
      return results;
    }.property('overlaysVisible.[]'),
    //#endregion

    /**
     * Gets a list of visible trips
     * @return {[type]} [description]
     */
    tripsVisible: Ember.computed('config.tripsVisible.[]', 'trips.[]', function () {
      var _this2 = this;

      return (this.trips || []).filter(function (t) {
        return _this2.config.tripsVisible.includes(t.get('id'));
      });
    }),

    /**
     * Catches any action that occurs in a waypoint marker
     */
    onWaypointMarkerAction: function onWaypointMarkerAction() {},
    onWaypointDragged: function onWaypointDragged() {},
    actions: {
      /**
       * Closes the context menu. This is triggered by both the
       * context menu itself and also when the context menu on any
       * other component is opened. For instance geofence windows
       */
      closeContextMenu: function closeContextMenu() {
        this.set('contextMenuShow', false);
        this.set('contextMenuEvent', null);
      },

      /**
       * Handles when a spiderfier based marker is clicked.
       */
      onSpiderfierMarkerClicked: function onSpiderfierMarkerClicked(component) {
        switch (component.get('type')) {
          case 'asset':
            this.assetMarkerAction(component, 'click', {
              assetId: component.get('asset.id')
            });
            break;

          case 'location':
            this.locationMarkerAction(component, 'click');
            break;

          case 'waypoint':
            this.waypointMarkerAction(component, 'click');
            break;
        }
      },
      //#region LOCATIONS

      /**
       * Catches any action that occurs in a location marker
       */
      onLocationMarkerAction: function onLocationMarkerAction(component, action) {
        this.locationMarkerAction(component, action);
      },

      /**
       * Sends an action up the chain for adding a location to the map
       * @param {object} Object containing a google.maps.latLng object
       */
      onAddLocation: function onAddLocation(args) {
        this.mapAction(this, 'addLocation', args);
      },
      //#endregion
      //#region ASSETS

      /**
       * Forwards marker actions that occur farther down the chain.
       */
      onAssetMarkerAction: function onAssetMarkerAction(component, action, options) {
        this.assetMarkerAction(component, action, options);
      },

      /**
       * Fired from the map when the user is following an asset.
       */
      onClearFollowing: function onClearFollowing() {
        this.mapAction(this, 'clearFollowedAsset');
      },

      /**
       * Fired from the map and sends an action that request the hide of
       * a number of breadcrumbs.
       */
      onHideBreadcrumbs: function onHideBreadcrumbs(assetIdArray) {
        this.mapAction(this, 'hideAssetsBreadcrumbs', {
          assetIdArray: Ember.makeArray(assetIdArray)
        });
      },
      onHideTripRoutes: function onHideTripRoutes(tripIdArray) {
        this.mapAction(this, 'hideTripRoutes', {
          tripIdArray: Ember.makeArray(tripIdArray)
        });
      },

      /**
       * Fired when the user has closed the infoBox that was open because
       * a specific event was highlighted.
       */
      onHighlightedPositionClose: function onHighlightedPositionClose() {
        this.mapAction(this, 'highlightedPositionClose');
      },
      //#endregion
      //#region GEOFENCE

      /**
       * Forwards the delete geofence action into the `mapAction` event group
       */
      onDeleteGeofence: function onDeleteGeofence(args) {
        this.mapAction(this, 'deleteGeofence', args);
      },
      //#endregion
      //#region GEOAREA

      /**
       * Forwards the delete geoArea action into the `mapAction` event group
       */
      onDeleteGeoArea: function onDeleteGeoArea(args) {
        this.mapAction(this, 'deleteGeoArea', args);
      },
      //#endregion
      onShowDistanceTool: function onShowDistanceTool() {
        this.set('showDistanceTool', true);
        this.notifications.info(this.intl.t('track.trackDistanceInstructions'), {
          clearDuration: 15000
        });
      },
      onHideDistanceTool: function onHideDistanceTool() {
        this.set('showDistanceTool', false);
      },
      overlayGroupToggle: function overlayGroupToggle(args) {
        this.mapAction(this, 'toggleOverlayGroupExpand', args);
      },
      overlaysToggleVisibleOnMap: function overlaysToggleVisibleOnMap(args) {
        this.mapAction(this, 'toggleOverlaysOnMap', args);
      },
      overlayVisibilityChanged: function overlayVisibilityChanged(args) {
        this.mapAction(this, 'toggleOverlaysOnMap', {
          overlayIds: [args.overlayId],
          visible: args.visible
        });
      },
      overlaySortGroupReorered: function overlaySortGroupReorered(itemModels, draggedModel) {
        this.mapAction(this, 'overlayReordered', {
          overlayIds: itemModels.mapBy('id'),
          overlayReordered: draggedModel.get('id')
        });
      },
      toggleOverlayModal: function toggleOverlayModal(toggle) {
        this.set('showOverlayModal', toggle);
      },
      togglePointModal: function togglePointModal(toggle) {
        this.set('showPointModal', toggle);
      },
      centerOn: function centerOn(coordType, coord) {
        var _this3 = this;

        try {
          switch (coordType) {
            case 'utm':
              {
                var _latLngConverter$utmT = _latLngConverter.default.utmToLatLng(coord.zone, coord.hemisphere, coord.easting, coord.northing),
                    _latLngConverter$utmT2 = _slicedToArray(_latLngConverter$utmT, 2),
                    lng = _latLngConverter$utmT2[0],
                    lat = _latLngConverter$utmT2[1];

                this.map.setCenter({
                  lat: lat,
                  lng: lng
                });
              }
              break;

            case 'gars':
              {
                var box = (0, _garsConversions.convertGARStoGeodetic)(coord);

                if (box.error) {
                  this.notifications.error(box.error);
                } else {
                  this.config.set('fitBounds', new google.maps.LatLngBounds(box.sw, box.ne), 1);
                }
              }
              break;

            case 'latlng':
              this.map.setCenter({
                lat: _latLngConverter.default.milliArcSecondsToDecimalDeg(coord.lat),
                lng: _latLngConverter.default.milliArcSecondsToDecimalDeg(coord.lng)
              });
              break;

            case 'lsd':
              this.api.request("Overlays/LSD/".concat(coord, "/Center")).then(function (response) {
                _this3.map.setCenter({
                  lat: response.Latitude,
                  lng: response.Longitude
                });
              });
              break;
          }
        } catch (_unused) {
          this.notifications.error("Invalid Coordinates");
        }
      }
    }
  });

  _exports.default = _default;
});