define("sky-router-3/pods/components/ivy-tabs-tabpanel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u1ZaUKq0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"active\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/ivy-tabs-tabpanel/template.hbs"
  });

  _exports.default = _default;
});