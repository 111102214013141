define("sky-router-3/pods/portal/manage/overlays/route", ["exports", "sky-router-3/models/overlay-type", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _overlayType, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['manage.overlay'],
    intl: Ember.inject.service(),
    queryParams: {
      editableOnly: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        groups: this.store.findAll('overlay-group'),
        overlays: this.store.query('overlay', {
          contracts: _overlayType.SUPPORTED_CONTRACTS,
          editableOnly: params.editableOnly
        })
      });
    }
  });

  _exports.default = _default;
});