define("sky-router-3/utils/descriptors/typed", ["exports", "sky-router-3/utils/object/assign-safe", "sky-router-3/utils/object/create"], function (_exports, _assignSafe, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = typed;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * This decorator checks the type of the object attempting to be set and enforces a type onto the object.
   * This helps with re-inflating data back into native classes so that the properties can be properly tracked (get/set)
   * @param  {Type} classType Class Type reference
   * @return {function}           Decorator that can be applied to properties.
   * @example
   *    \@typed(StateTrackMap)
   *     maps = [];
   */
  function typed(classType) {
    var weak = new WeakMap();
    return function (object, property, descriptor) {
      return {
        get: function get() {
          // Check if there is another decorator attached below us in the chain
          // i.e. "tracked"
          if (typeof descriptor.get == 'function') {
            return descriptor.get.call(this);
          } // If we haven't initialized before but there is one ready, return that


          if (!weak.has(this) && typeof descriptor.initializer == 'function') {
            weak.set(this, descriptor.initializer.call(this));
          }

          return weak.get(this);
        },
        set: function set(value) {
          var typedValue; // Passed in object is already the correct object

          if (value instanceof classType) {
            typedValue = value;
          } // Passed in object is an array of objects
          else if (Ember.isArray(value)) {
              typedValue = value.map(function (o) {
                if (o instanceof classType) {
                  // Array contains a valid object
                  return o;
                }

                return (0, _assignSafe.default)((0, _create.default)(classType), o); // Array object needs to be reconstructed
              });
            } // Passed in object is just a POJO
            else if (_typeof(value) == 'object' && value != null) {
                typedValue = (0, _assignSafe.default)((0, _create.default)(classType), value);
              } // Apply the converted value to the lower level object
          // This may be the object itself, or it may be another setter in the chain


          if (typeof descriptor.set == 'function') {
            descriptor.set.call(this, typedValue);
          } else {
            weak.set(this, typedValue);
          }
        }
      };
    };
  }
});