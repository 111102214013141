define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-10/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    types: function () {
      var intl = this.get('intl');
      return [{
        label: intl.t('compoundrules.condition.altitudeChange.asc'),
        factor: 1
      }, {
        label: intl.t('compoundrules.condition.altitudeChange.desc'),
        factor: -1
      }];
    }.property(),
    speedUnits: null,
    speedUnitSelected: function () {
      return this.get('speedUnits') ? this.get('speedUnits').findBy('id', '6') : null;
    }.property('speedUnits.[]'),

    /**
     * Gets ascending or descending depending on if the model.value is negative or not
     */
    typeSelected: function () {
      var types = this.get('types');
      return this.get('model.value1') >= 0 ? types[0] : types[1];
    }.property('model.value1'),

    /**
     * Value is stored in CMs
     */
    valueCM: Ember.computed('model.value1', {
      get: function get() {
        return Math.abs(parseInt(this.get('model.value1')));
      },
      set: function set(key, value) {
        this.set('model.value1', value * this.get('typeSelected.factor'));
        return value;
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('model.relationalOperator') === 2) {
        if (this.get('model.value1') >= 0) {
          this.set('model.relationalOperator', 0);
        } else {
          this.set('model.relationalOperator', 1);
        }
      }
    },
    init: function init() {
      var _this = this;

      this.get('store').findAll('unit-speed').then(function (u) {
        return _this.set('speedUnits', u);
      });

      this._super.apply(this, arguments);
    },
    actions: {
      setType: function setType(op) {
        var val = this.get('model.value1');

        if (val > 0 && op.factor < 0 || val < 0 && op.factor > 0) {
          this.set('model.value1', val * -1);
        }

        if (op.factor === 1) {
          this.set('model.relationalOperator', 0);
        } else {
          this.set('model.relationalOperator', 1);
        }
      }
    }
  });

  _exports.default = _default;
});