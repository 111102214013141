define("sky-router-3/pods/portal/forms/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['archived'],
    archived: false,
    search: null,
    sorted: Ember.computed.sort('model', 'sortedBy'),
    sortedBy: ['submitDateUtc:desc'],
    selected: [],
    selectedForArchive: Ember.computed.filterBy('selected', 'isArchived', false),
    selectedForDelete: Ember.computed.filterBy('selected', 'isArchived'),
    actions: {
      select: function select(form) {
        if (this.selected.includes(form)) {
          this.selected.removeObject(form);
        } else {
          this.selected.addObject(form);
        }
      },
      selectAll: function selectAll(forms, selected) {
        if (selected) {
          this.selected.addObjects(forms);
        } else {
          this.selected.clear();
        }
      },
      archive: function archive(forms, isArchived) {
        var _this = this;

        var promises = forms.toArray().map(function (x) {
          _this.selected.removeObject(x);

          x.set('isArchived', isArchived);
          return x.save();
        });
        Ember.RSVP.allSettled(promises).then(function () {
          _this.send('refresh');
        });
      },
      delete: function _delete(forms) {
        var _this2 = this;

        var promises = forms.toArray().map(function (x) {
          _this2.selected.removeObject(x);

          return x.destroyRecord();
        });
        Ember.RSVP.allSettled(promises).then(function () {
          _this2.send('refresh');
        });
      }
    }
  });

  _exports.default = _default;
});