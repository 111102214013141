define("sky-router-3/pods/components/utc-clock/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d2NLQgj5",
    "block": "{\"symbols\":[],\"statements\":[[2,\"UTC \"],[1,[30,[36,1],[[35,0]],[[\"showUTC\",\"hideSeconds\"],[true,true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dateTime\",\"display-date\"]}",
    "moduleName": "sky-router-3/pods/components/utc-clock/template.hbs"
  });

  _exports.default = _default;
});