define("sky-router-3/pods/components/portal/track/map/shape-circle/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component handles drawing tasks on a map.
   * @see https://developers.google.com/maps/documentation/javascript/reference/polygon#Polygon
   * @example {{drawing-manager map=map mode=google.maps.drawing.OverlayType.CIRCLE circleCompleted='circle' polygonCompleted='polygon' }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    googlePropertyObject: Ember.computed.alias('_instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'center',
      setFunc: 'setCenter',
      event: 'center_changed',
      getFunc: 'getCenter',
      eFunc: '_onCircleChanged'
    }, {
      property: 'radius',
      setFunc: 'setRadius',
      event: 'radius_changed',
      getFunc: 'getRadius',
      eFunc: '_onCircleChanged'
    }],
    onCircleChanged: function onCircleChanged()
    /*bounds*/
    {},
    _onCircleChanged: function _onCircleChanged(circle) {
      this.onCircleChanged(circle);
    },

    /**
     * The instance of the drawing manager
     * @type {google.maps.Circle}
     */
    _instance: null,

    /**
     * Passed in map instance the drawing manager is associated with
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * The LatLngBounds
     * @see https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLngBounds
     * @type {LatLngBounds}
     */
    circle: null,
    groupColor: null,

    /**
     * Options object that is used to configure the instance
     * @type {google.maps.CircleOptions}
     */
    options: Ember.computed(function () {
      return {
        draggable: true,
        editable: true,
        fillColor: this.groupColor != null ? "#".concat(this.groupColor) : 'green',
        fillOpacity: 0.25,
        strokeColor: '#000000',
        strokeOpacity: 0.5,
        geodesic: true
      };
    }),

    /**
     * Gets the radius of the circle in kilometers
     */
    radius: Ember.computed('circle.radius', {
      get: function get() {
        return this.get('circle.radius');
      },
      set: function set(key, value) {
        this.set('circle.radius', value);
        return value;
      }
    }),
    center: Ember.computed('circle.center', {
      get: function get() {
        var center = this.get('circle').center;

        if (center) {
          var lat = center.lat();
          var lng = center.lng();
          return new google.maps.LatLng(lat, lng);
        }
      },
      set: function set(key, value) {
        var center = this.get('circle').center;

        if (center) {
          center.lat, value.lat();
          center.lng, value.lng();
        } else if (value) {
          this.set('circle.center.lat', value.lat());
          this.set('circle.center.lng', value.lng());
        }

        return value;
      }
    }),

    /**
     * Init the instance
     */
    didInsertElement: function didInsertElement() {
      this.set('_instance', new google.maps.Circle(this.options));
    },

    /**
     * Destroy the instance
     */
    willDestroyElement: function willDestroyElement() {
      this._instance.setMap(null);
    }
  });

  _exports.default = _default;
});