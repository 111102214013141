define("sky-router-3/pods/components/pager-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jftLtVW0",
    "block": "{\"symbols\":[\"page\",\"@pageSizes\",\"@pageSize\",\"&default\",\"&attrs\",\"@items\",\"@pageIndex\",\"@onPageChange\"],\"statements\":[[18,4,[[35,1]]],[2,\"\\n\"],[11,\"nav\"],[24,0,\"pager-container\"],[17,5],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"pager-container-left\"],[12],[1,[30,[36,4],[\"common.paging\"],[[\"start\",\"end\",\"total\"],[[35,3],[35,2],[32,6,[\"length\"]]]]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pager-container-middle\"],[12],[2,\"\\n        \"],[8,\"pager\",[],[[\"@currentPage\",\"@numberOfPages\",\"@onPageChange\"],[[32,7],[34,5],[32,8]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pager-container-right\"],[12],[2,\"\\n\"],[6,[37,6],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"input-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[32,2],[32,3],[30,[36,0],[[32,0],[32,0,[\"pageSizeChange\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[32,1]],[2,\"\\n            \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"itemsForDisplay\",\"endRecordIndex\",\"startRecordIndex\",\"t\",\"numberOfPages\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/pager-container/template.hbs"
  });

  _exports.default = _default;
});