define("sky-router-3/models/asset-profile-set-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PROFILE_TYPES = void 0;
  // import { belongsTo, hasMany } from 'ember-data/relationships';

  /**
   * Types of available profiles
   * @type {Object}
   */
  var PROFILE_TYPES = {
    ach: "1",
    d2k: "2",
    d2ka: "3",
    extreme: "4",
    he5x: "5",
    he7x: "6",
    shout_ts: "8",
    shout_sp: "9"
  };
  _exports.PROFILE_TYPES = PROFILE_TYPES;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string')
  });

  _exports.default = _default;
});