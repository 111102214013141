define("sky-router-3/utils/convert-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertDate;

  /**
   * Converts a date for display
   * @param {date} - the initial date object to translate
   * @param {boolean} showUTC     - The UTC offset of the date will be show instead of the user defined offset.
   * @param {string} format       - Overrides the default formatting based on the users locale setting.
   * @param {boolean} hideSeconds - When flipped to true this will not show the seconds in the formatted time.
   * @param {boolean} fromNow     - Indicates that instead of showing the static date it should display a phrase that
   *                                  indicates how far away from the current date it is.
   * @param {date} fromDate       - The date at which to calculate the time different and show the user.
   * @param {boolean} calendar    - Show the date based on a calendar style display from the current date
   * @param {boolean} calendarFrom- The date the calendar calculation will reference off of.
   * @param {boolean} hideTime    - Will the time be hidden from the output
   * @param {boolean} hideDate    - Will the date be hidden from the output
   * @param {boolean} hideYear    - Will the year be hidden from the output
   */
  function convertDate(date, user) {
    var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    // Create the js moment object
    var dd = (0, _moment.default)(date);
    var tz = 0;
    var output = "";
    var timeFormat = "";
    var dateFormat = ""; // Validate

    if (!dd.isValid()) {
      return;
    } // Get the users time format from their settings


    if (!hash.hideDate) {
      dateFormat = user.get('dateFormatSystem');

      if (hash.hideYear) {
        dateFormat = dateFormat.replace(/[:/-][yY]+/, '');
      }
    }

    if (!hash.hideTime) {
      timeFormat = user.get('timeFormatSystem');

      if (hash.hideSeconds) {
        timeFormat = timeFormat.replace(/:s+/, '');
      }
    } // Apply the users timezone offset.


    if (hash.showUTC) {
      dd.utc();
    } else {
      var tzPresent = hash.timeZone !== undefined && hash.timeZone !== null;
      tz = tzPresent ? hash.timeZone : user.get('timeZone');
      dd.utcOffset(tz);
    } // If the wanted the fromNow then return that.


    if (hash.fromNow) {
      if (hash.fromDate) {
        var fromDate = (0, _moment.default)(hash.fromDate);
        return dd.fromNow(fromDate);
      }

      return dd.fromNow();
    } // If they want a calendar then show that


    if (hash.calendar) {
      var intl = Ember.getOwner(user).lookup("service:intl");
      var calendarFormat = {
        lastDay: intl.t('calendar.lastDay', {
          timeFormat: timeFormat
        }),
        sameDay: intl.t('calendar.sameDay', {
          timeFormat: timeFormat
        }),
        nextDay: intl.t('calendar.nextDay', {
          timeFormat: timeFormat
        }),
        lastWeek: intl.t('calendar.lastWeek', {
          timeFormat: timeFormat
        }),
        nextWeek: intl.t('calendar.nextWeek', {
          timeFormat: timeFormat
        }),
        sameElse: hash.format || "".concat(dateFormat, " ").concat(timeFormat)
      };

      if (hash.calendarFrom) {
        var referenceTime = (0, _moment.default)(hash.calendarFrom);
        output = dd.calendar(referenceTime, calendarFormat);
      } else {
        output = dd.calendar(calendarFormat);
      }
    } else {
      // Output the date with the custom format or the default format of (en)DD/MM/YYYY HH:MM:SS PM
      output = dd.format(hash.format || "".concat(dateFormat).concat(timeFormat));
    } // Show the timezone to the user if requested
    // This will generate a (GMT+12:45) or (GMT-10:15) or (GMT+0)


    if (hash.showTimeZone) {
      var tzMinutes = "";
      var operator = tz >= 0 ? "+" : "";

      if (Math.abs(tz) > 16) {
        var min = Math.abs(tz % 60);
        tz /= 60;

        if (min > 0) {
          tzMinutes = ":" + min;
        }
      }

      tz = Math.trunc(tz);
      return "".concat(output, " (GMT").concat(operator).concat(tz).concat(tzMinutes, ")");
    }

    return output;
  }
});