define("sky-router-3/pods/components/portal/track/map/marker/marker-following/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This marker component is responsible for showing an asset following
   * circle around an asset.
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('marker'),
    googlePropertyMap: [{
      property: 'position',
      setFunc: 'setPosition'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'icon',
      setFunc: 'setIcon'
    }],

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * The instance of the Google maps marker that defines this location
     * @type {google.maps.Marker}
     */
    marker: null,

    /**
     * Icon for the marker, the path object correlates to the source image /source/assets/FollowAsset.ai
     */
    icon: Ember.computed('rotation', function () {
      return {
        path: 'M45.4,23.3c0,12.1-9.8,21.8-21.9,21.8 S1.5,35.4,1.5,23.3S11.3,1.5,23.4,1.5S45.4,11.3,45.4,23.3z M23.5,1.3v10 M23.5,35.3v10 M1.5,23.3h10 M35.5,23.3h10',
        scale: 3,
        anchor: new google.maps.Point(24, 24),
        strokeColor: '#00AF10',
        strokeOpacity: 0.5,
        rotation: this.get('rotation')
      };
    }),

    /**
     * Incoming position from outside the component.
     */
    position: null,

    /**
     * Incoming rotation from outside the component.
     * It will dictate how this marker rotates in degrees.
     */
    rotation: null,

    /**
     * Initializes the marker for this asset.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var marker = new google.maps.Marker({
        clickable: false
      });
      this.set('marker', marker);
    },

    /**
     * Removes the marker from the map and destroys it.
     */
    willDestroyElement: function willDestroyElement() {
      this.marker.setMap(null);
    }
  });

  _exports.default = _default;
});