define("sky-router-3/adapters/application-caching", ["exports", "sky-router-3/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    shouldReloadRecord: function shouldReloadRecord()
    /*store, snapshot*/
    {
      return false; // Default is false
    },
    // Reload the record if for some reason the snapshot is null.
    // Theory is that it can become null if an http exception occurs while downloading the record.
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord(store, snapshot) {
      return !Ember.get(snapshot, 'id'); // Default is true
    },
    shouldReloadAll: function shouldReloadAll(store, snapshotRecordArray) {
      return !snapshotRecordArray.length;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll()
    /*store, snapshotRecordArray*/
    {
      return true; // Default is true
    }
  });

  _exports.default = _default;
});