define("sky-router-3/services/session-account", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents the sessionAccount object that persists throughout the application while
   * the user is logged in. This holds all information about a user and the state
   * of the application.
   **/
  var _default = Ember.Service.extend({
    /**
     * Injects the session service that holds authentication information.
     * @return {sky-router-3/models/session}
     */
    session: Ember.inject.service(),

    /**
     * The ember data store
     * @return {DS.store}
     */
    store: Ember.inject.service(),

    /**
     * Loads the user from the session information into the user property.
     * this must happen before the application starts to do anything as the
     * user is expected to be present.
     * @return {Ember.RSVP.Promise} Promise that is resolved when the job is completed.
     *                                      It can be rejected when the user cannot
     *                                      be recovered.
     */
    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this.get('session.isAuthenticated')) {
          var userId = _this.get('session.data.authenticated.userId');

          return _this.get('store').findRecord('user', userId).then(function (user) {
            _this.set('user', user);

            resolve(user);
          }, reject);
        } else {
          reject();
        }
      });
    },

    /**
     * Holds the users account information that is stored in sessionAccount
     * This is the users settings and preferences and other information that they don not directly have access to
     */
    user: null,

    /**
     * The currently authenticated users numerical (UserMaster) identifier
     * @property {string}
     */
    userId: Ember.computed.alias('session.data.authenticated.userId'),

    /**
     * Indicates if the user should be shown the advanced settings or not.
     * This is located here because it should be reset every time the user logs in
     * and out. This behavior matches chrome and Spotify and several other well known
     * applications.
     */
    showAdvancedSettings: false,

    /**
     * Checks to see if the user has the permission in question
     * @param  {String}  permission
     * @return {Boolean}            [description]
     */
    hasPermission: function hasPermission() {
      var userPermissions = this.get('user.permissions');

      for (var _len = arguments.length, permissions = new Array(_len), _key = 0; _key < _len; _key++) {
        permissions[_key] = arguments[_key];
      }

      return (0, _hasPermission2.default)(userPermissions, permissions, false);
    }
  });

  _exports.default = _default;
});