define("sky-router-3/pods/components/portal/operations/trips/waypoint-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    /**
     * Action: Called when a route is to be added
     */
    reorderedWaypoints: function reorderedWaypoints()
    /* waypoints */
    {},
    addedWayppoint: function addedWayppoint()
    /* waypoint, waypoints */
    {},
    removedWaypoint: function removedWaypoint()
    /* waypoint, waypoints */
    {},
    onAddWaypoint: function onAddWaypoint()
    /* waypoints */
    {},

    /**
     * Is this list disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in waypoints
     * @type {Array<sky-router-3/models/trip-plan-leg-waypoint>}
     */
    waypoints: null,
    actions: {
      selectAutocomplete: function selectAutocomplete(waypoint, location) {
        waypoint.set('name', location.Name);
        waypoint.set('description', location.Description);
        waypoint.set('latitudeMilliarcSeconds', location.LatitudeMilliarcSeconds);
        waypoint.set('longitudeMilliarcSeconds', location.LongitudeMilliarcSeconds);
      },

      /**
       * Moves the items around in the waypoints array
       * @param  {Array} reorderedWaypoints    Re-ordered array missing origin & destination
       * @param  {Waypoint} draggedWaypoint The waypoint that was moved
       */
      waypointsReordered: function waypointsReordered(reorderedWaypoints, draggedWaypoint) {
        var newLocation = reorderedWaypoints.indexOf(draggedWaypoint) + 1;
        this.waypoints.removeObject(draggedWaypoint);
        this.waypoints.insertAt(newLocation, draggedWaypoint);
        this.reorderedWaypoints(this.waypoints); // Action
      },
      addWaypoint: function addWaypoint(waypoints) {
        var waypoint = this.onAddWaypoint(waypoints);
        this.addedWayppoint(waypoint, waypoints); // Action
      },
      removeWaypoint: function removeWaypoint(waypoint, waypoints) {
        waypoints.removeObject(waypoint);
        this.removedWaypoint(waypoint, waypoints); // Action
      }
    }
  });

  _exports.default = _default;
});