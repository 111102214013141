define("sky-router-3/mixins/rollback-relationships", ["exports", "sky-router-3/utils/compare-array"], function (_exports, _compareArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _savedRelationships: null,
    willDestroy: function willDestroy() {
      this._savedRelationships = null;
      return this._super.apply(this, arguments);
    },

    /**
     * generates a saveable or comparable relationship object
     */
    generationRelationships: function generationRelationships() {
      var _this = this;

      var relationships = {};
      this.eachRelationship(function (name, descriptor) {
        relationships[name] = {
          descriptor: descriptor
        };

        switch (descriptor.kind) {
          case 'hasMany':
            relationships[name]['ids'] = _this.hasMany(name).ids();
            break;

          case 'belongsTo':
            relationships[name]['id'] = _this.belongsTo(name).id();
            break;
        }
      });
      return relationships;
    },

    /**
     * Reverts the state of the relationships back to the initial state by getting
     * the relationships that have been dirtied along with the origional state of
     * said dirty relationships.
     */
    rollbackRelationships: function rollbackRelationships() {
      var _this2 = this;

      var dirtyRelationships = this.dirtyRelationships();
      Object.keys(dirtyRelationships).forEach(function (name) {
        var rel = dirtyRelationships[name];
        var descriptor = dirtyRelationships[name].descriptor;

        switch (descriptor.kind) {
          case 'hasMany':
            // Clear the relationsihp back to nothing
            var hasMany = _this2.set(descriptor.key, []); // Loop through and add all the records back we need to add back


            rel.ids.forEach(function (id) {
              if (descriptor.options.async == false) {
                hasMany.pushObject(_this2.store.findRecord(descriptor.type, id));
              } else {
                _this2.store.findRecord(descriptor.type, id).then(function (r) {
                  return hasMany.pushObject(r);
                });
              }
            });
            break;

          case 'belongsTo':
            if (descriptor.options.async == false) {
              _this2.set(descriptor.key, _this2.store.findRecord(descriptor.type, rel.id));
            } else {
              _this2.store.findRecord(descriptor.type, rel.id).then(function (r) {
                return _this2.set(descriptor.key, r);
              });
            }

            _this2.belongsTo(name).reload();

            break;
        }
      });
    },

    /**
     * Compares the state of the relationships to the current state of the relationships.
     * @return {Boolean} True if relationships have been modified.
     */
    hasDirtyRelationships: function hasDirtyRelationships() {
      return Object.keys(this.dirtyRelationships()).length > 0;
    },

    /**
     * Generates a object where the keys are the relationships that are dirty
     * { 'relationship' = { descriptor, ids, id } }
     * @return {[type]} [description]
     */
    dirtyRelationships: function dirtyRelationships() {
      var _this3 = this;

      var dirtyRelationships = {};

      if (this._savedRelationships) {
        var currentRelations = this.generationRelationships();
        Object.keys(this._savedRelationships).forEach(function (name) {
          if (currentRelations[name].id != _this3._savedRelationships[name].id || !(0, _compareArray.default)(currentRelations[name].ids, _this3._savedRelationships[name].ids)) {
            dirtyRelationships[name] = _this3._savedRelationships[name];
          }
        });
      }

      return dirtyRelationships;
    },

    /**
     * Clears the saved relationships when they no longer become valid.
     */
    _reloadRelationships: Ember.on('didDelete', 'didUpdate', 'didCreate', 'didLoad', function () {
      this._savedRelationships = this.generationRelationships();
    })
  });

  _exports.default = _default;
});