define("sky-router-3/utils/geoconversiontools/transmercator-conversions", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants"], function (_exports, _geoconstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var tmconversions = {
    getTMDefaultValues: function getTMDefaultValues() {
      return {
        TranMerc_a: 6378137.0,
        TranMerc_f: 1 / 298.257223563,
        TranMerc_es: 0.0066943799901413800,
        TranMerc_ebs: 0.0067394967565869,
        TranMerc_Origin_Lat: 0.0,
        TranMerc_Origin_Long: 0.0,
        TranMerc_False_Northing: 0.0,
        TranMerc_False_Easting: 0.0,
        TranMerc_Scale_Factor: 1.0,
        TranMerc_ap: 6367449.1458008,
        TranMerc_bp: 16038.508696861,
        TranMerc_cp: 16.832613334334,
        TranMerc_dp: 0.021984404273757,
        TranMerc_ep: 3.1148371319283e-005,
        TranMerc_Delta_Easting: 40000000.0,
        TranMerc_Delta_Northing: 40000000.0
      };
    },
    sphtmd: function sphtmd(latitude, tmparams) {
      return tmparams.TranMerc_ap * latitude - tmparams.TranMerc_bp * Math.sin(2.0 * latitude) + tmparams.TranMerc_cp * Math.sin(4.0 * latitude) - tmparams.TranMerc_dp * Math.sin(6.0 * latitude) + tmparams.TranMerc_ep * Math.sin(8.0 * latitude);
    },
    sphsn: function sphsn(latitude, tmparams) {
      return tmparams.TranMerc_a / Math.sqrt(1.0 - tmparams.TranMerc_es * Math.pow(Math.sin(latitude), 2));
    },
    sphsr: function sphsr(latitude, tmparams) {
      return tmparams.TranMerc_a * (1.0 - tmparams.TranMerc_es) / Math.pow(Math.sqrt(1.0 - tmparams.TranMerc_es * Math.pow(Math.sin(latitude), 2)), 3);
    },
    setTMParameters: function setTMParameters(a, f, originlat, centralmer, falseeasting, falsenorthing, scalefactor) {
      var tmparams = this.getTMDefaultValues();

      var tmconstants = _geoconstants.default.getTMConstants();

      var errors = [];

      if (Math.abs(originlat) > tmconstants.PI_OVER_2) {
        errors.push({
          code: 0x0010,
          desc: 'Origin latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      if (centralmer < -tmconstants.PI || centralmer > tmconstants.TWO_PI) {
        errors.push({
          code: 0x0020,
          desc: 'Central meridian outside of valid range (-180 to 360 degrees)'
        });
      }

      if (a <= 0.0) {
        errors.push({
          code: 0x0040,
          desc: 'Semi-major axis less than or equal to zero'
        });
      }

      if (1 / f < 250 || 1 / f > 350) {
        errors.push({
          code: 0x0080,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (scalefactor < tmconstants.MIN_SCALE_FACTOR || scalefactor > tmconstants.MAX_SCALE_FACTOR) {
        errors.push({
          code: 0x0100,
          desc: 'Scale factor outside of valid range (0.3 to 3.0)'
        });
      }

      if (errors.length == 0) {
        tmparams.TranMerc_a = a;
        tmparams.TranMerc_f = f;
        tmparams.TranMerc_Origin_Lat = originlat;

        if (centralmer > tmconstants.PI) {
          centralmer -= 2 * tmconstants.PI;
        }

        tmparams.TranMerc_Origin_Long = centralmer;
        tmparams.TranMerc_False_Northing = falsenorthing;
        tmparams.TranMerc_False_Easting = falseeasting;
        tmparams.TranMerc_Scale_Factor = scalefactor; // eccentricity squared

        tmparams.TranMerc_es = 2 * tmparams.TranMerc_f - tmparams.TranMerc_f * tmparams.TranMerc_f;
        tmparams.TranMerc_ebs = 1 / (1 - tmparams.TranMerc_es) - 1;
        tmparams.TranMerc_b = tmparams.TranMerc_a * (1 - tmparams.TranMerc_f); // true meridianal constants

        var tn1 = (tmparams.TranMerc_a - tmparams.TranMerc_b) / (tmparams.TranMerc_a + tmparams.TranMerc_b);
        var tn2 = tn1 * tn1;
        var tn3 = tn2 * tn1;
        var tn4 = tn3 * tn1;
        var tn5 = tn4 * tn1;
        tmparams.TranMerc_ap = tmparams.TranMerc_a * (1e0 - tn1 + 5e0 * (tn2 - tn3) / 4e0 + 81e0 * (tn4 - tn5) / 64e0);
        tmparams.TranMerc_bp = 3e0 * tmparams.TranMerc_a * (tn1 - tn2 + 7e0 * (tn3 - tn4) / 8e0 + 55e0 * tn5 / 64e0) / 2e0;
        tmparams.TranMerc_cp = 15e0 * tmparams.TranMerc_a * (tn2 - tn3 + 3e0 * (tn4 - tn5) / 4e0) / 16.0;
        tmparams.TranMerc_dp = 35e0 * tmparams.TranMerc_a * (tn3 - tn4 + 11e0 * tn5 / 16e0) / 48e0;
        tmparams.TranMerc_ep = 315e0 * tmparams.TranMerc_a * (tn4 - tn5) / 512e0;
        var tmtestoutput = this.convertGeodeticToTM(tmconstants.MAX_LAT, tmconstants.MAX_DELTA_LONG + centralmer, tmparams);

        if (tmtestoutput.errorCodes.length == 0 || tmtestoutput.errorCodes.length == 1 && tmtestoutput.errorCodes[0].code == 0x0200) {
          tmparams.TranMerc_Delta_Northing = tmtestoutput.output.northing;
          tmparams.TranMerc_Delta_Easting = tmtestoutput.output.easting;
          tmtestoutput = this.convertGeodeticToTM(0, tmconstants.MAX_DELTA_LONG + centralmer, tmparams);

          if (tmtestoutput.errorCodes.length == 0 || tmtestoutput.errorCodes.length == 1 && tmtestoutput.errorCodes[0].code == 0x0200) {
            tmparams.TranMerc_Delta_Easting = tmtestoutput.output.easting;
            tmparams.TranMerc_Delta_Northing += 1;
            tmparams.TranMerc_Delta_Easting += 1;
          } else {
            errors.push(tmtestoutput.errorCodes);
          }
        } else {
          errors.push(tmtestoutput.errorCodes);
        }
      }

      return {
        parameters: tmparams,
        errorCodes: errors
      };
    },

    /***************************************************
     * Converts geodetic (lat, long) coordinates into  *
     * TransversMercator coordinates (pre-req for UTM) *
     * @param {Number} latitude                        *
     * @param {Number} longitude                       *
     * @param {Object} tparams                         *
     ***************************************************/
    convertGeodeticToTM: function convertGeodeticToTM(latitude, longitude, tmparams) {
      var tmconstants = _geoconstants.default.getTMConstants();

      var tmresult = {
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];

      if (latitude < -tmconstants.MAX_LAT || latitude > tmconstants.MAX_LAT) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      if (longitude > tmconstants.PI) {
        longitude -= 2 * tmconstants.PI;
      }

      if (longitude < tmparams.TranMerc_Origin_Long - tmconstants.MAX_DELTA_LONG || longitude > tmparams.TranMerc_Origin_Long + tmconstants.MAX_DELTA_LONG) {
        var check_long = longitude;
        var check_origin = tmparams.TranMerc_Origin_Long;

        if (longitude < 0) {
          check_long = longitude + 2 * tmconstants.PI;
        }

        if (tmparams.TranMerc_Origin_Long < 0) {
          check_origin = tmparams.TranMerc_Origin_Long + 2 * tmconstants.PI;
        }

        if (check_long < check_origin - tmconstants.MAX_DELTA_LONG || check_long > check_origin + tmconstants.MAX_DELTA_LONG) {
          errors.push({
            code: 0x0002,
            desc: 'Longitude outside of valid range (-180 to 360 degrees, and within +/-90 of Central Meridian)'
          });
        }
      }

      if (errors.length == 0) {
        // delta of longitude away from meridian
        var dlam = longitude - tmparams.TranMerc_Origin_Long; // warning of distortion if longitude is more
        // than 9 degrees from central meridian

        if (Math.abs(dlam) > 9.0 * tmconstants.PI / 180) {
          errors.push({
            code: 0x0200,
            desc: 'Distortion will result if longitude is more than 9 degrees from the Central Meridian'
          });
        }

        if (dlam > tmconstants.PI) {
          dlam -= 2 * tmconstants.PI;
        }

        if (dlam < -tmconstants.PI) {
          dlam += 2 * tmconstants.PI;
        }

        if (Math.abs(dlam) < 2e-10) {
          dlam = 0.0;
        } //sin of lat


        var s1 = Math.sin(latitude); //cosine of lat and cos(lat)^x

        var c1 = Math.cos(latitude);
        var c2 = c1 * c1;
        var c3 = c2 * c1;
        var c5 = c3 * c2;
        var c7 = c5 * c2; //tangent of lat and tan(lat)^x

        var tan1 = Math.tan(latitude);
        var tan2 = tan1 * tan1;
        var tan3 = tan2 * tan1;
        var tan4 = tan3 * tan1;
        var tan5 = tan4 * tan1;
        var tan6 = tan5 * tan1;
        var eta1 = tmparams.TranMerc_ebs * c2;
        var eta2 = eta1 * eta1;
        var eta3 = eta2 * eta1;
        var eta4 = eta3 * eta1;
        /* radius of curvature in prime vertical */

        var sn = this.sphsn(latitude, tmparams);
        /* True Meridianal Distances */

        var tmd = this.sphtmd(latitude, tmparams);
        /*  Origin  */

        var tmdo = this.sphtmd(tmparams.TranMerc_Origin_Lat, tmparams);
        /* northing */

        var t1 = (tmd - tmdo) * tmparams.TranMerc_Scale_Factor;
        var t2 = sn * s1 * c1 * tmparams.TranMerc_Scale_Factor / 2e0;
        var t3 = sn * s1 * c3 * tmparams.TranMerc_Scale_Factor * (5e0 - tan2 + 9e0 * eta1 + 4e0 * eta2) / 24e0;
        var t4 = sn * s1 * c5 * tmparams.TranMerc_Scale_Factor * (61e0 - 58e0 * tan2 + tan4 + 270e0 * eta1 - 330e0 * tan2 * eta1 + 445e0 * eta2 + 324e0 * eta3 - 680e0 * tan2 * eta2 + 88e0 * eta4 - 600e0 * tan2 * eta3 - 192e0 * tan2 * eta4) / 720e0;
        var t5 = sn * s1 * c7 * tmparams.TranMerc_Scale_Factor * (1385e0 - 3111e0 * tan2 + 543e0 * tan4 - tan6) / 40320e0;
        tmresult.northing = tmparams.TranMerc_False_Northing + t1 + Math.pow(dlam, 2e0) * t2 + Math.pow(dlam, 4e0) * t3 + Math.pow(dlam, 6e0) * t4 + Math.pow(dlam, 8e0) * t5;
        /* easting */

        var t6 = sn * c1 * tmparams.TranMerc_Scale_Factor;
        var t7 = sn * c3 * tmparams.TranMerc_Scale_Factor * (1e0 - tan2 + eta1) / 6e0;
        var t8 = sn * c5 * tmparams.TranMerc_Scale_Factor * (5e0 - 18e0 * tan2 + tan4 + 14e0 * eta1 - 58e0 * tan2 * eta1 + 13e0 * eta2 + 4e0 * eta3 - 64e0 * tan2 * eta2 - 24e0 * tan2 * eta3) / 120e0;
        var t9 = sn * c7 * tmparams.TranMerc_Scale_Factor * (61e0 - 479e0 * tan2 + 179e0 * tan4 - tan6) / 5040e0;
        tmresult.easting = tmparams.TranMerc_False_Easting + dlam * t6 + Math.pow(dlam, 3e0) * t7 + Math.pow(dlam, 5e0) * t8 + Math.pow(dlam, 7e0) * t9;
      }

      return {
        output: tmresult,
        parameters: tmparams,
        errorCodes: errors
      };
    },
    convertTMToGeodetic: function convertTMToGeodetic(easting, northing, tmparams) {
      var tmconstants = _geoconstants.default.getTMConstants();

      var tmresult = {
        latitude: 0e0,
        longitude: 0e0
      };
      var errors = [];

      if (easting < tmparams.TranMerc_False_Easting - tmparams.TranMerc_Delta_Easting || easting > tmparams.TranMerc_False_Easting + tmparams.TranMerc_Delta_Easting) {
        errors.push({
          code: 0x0004,
          desc: 'Easting outside of valid range (depending on ellipsoid and projection parameters)'
        });
      }

      if (northing < tmparams.TranMerc_False_Northing - tmparams.TranMerc_Delta_Northing || northing > tmparams.TranMerc_False_Northing + tmparams.TranMerc_Delta_Northing) {
        errors.push({
          code: 0x0008,
          desc: 'Northing outside of valid range (depending on ellipsoid and projection parameters)'
        });
      }

      if (errors.length == 0) {
        var tmdo = this.sphtmd(tmparams.TranMerc_Origin_Lat, tmparams);
        var tmd = tmdo + (northing - tmparams.TranMerc_False_Northing) / tmparams.TranMerc_Scale_Factor;
        var sr = this.sphsr(0e0, tmparams);
        var ftphi = tmd / sr;
        var t10 = 0;

        for (var i = 0; i < 5; i++) {
          t10 = this.sphtmd(ftphi, tmparams);
          sr = this.sphsr(ftphi, tmparams);
          ftphi = ftphi + (tmd - t10) / sr;
        }
        /* Radius of Curvature in the meridian */


        sr = this.sphsr(ftphi, tmparams);
        /* Radius of Curvature in the meridian */

        var sn = this.sphsn(ftphi, tmparams);
        /* Cosine term */

        var c = Math.cos(ftphi);
        /* Tangent Value  */

        var tan1 = Math.tan(ftphi);
        var tan2 = tan1 * tan1;
        var tan4 = tan2 * tan2;
        var eta1 = tmparams.TranMerc_ebs * Math.pow(c, 2);
        var eta2 = eta1 * eta1;
        var eta3 = eta2 * eta1;
        var eta4 = eta3 * eta1;
        var de = easting - tmparams.TranMerc_False_Easting;
        if (Math.abs(de) < 0.0001) de = 0.0;
        /* Latitude */

        t10 = tan1 / (2e0 * sr * sn * Math.pow(tmparams.TranMerc_Scale_Factor, 2));
        var t11 = tan1 * (5e0 + 3e0 * tan2 + eta1 - 4e0 * Math.pow(eta1, 2) - 9e0 * tan2 * eta1) / (24e0 * sr * Math.pow(sn, 3) * Math.pow(tmparams.TranMerc_Scale_Factor, 4));
        var t12 = tan1 * (61e0 + 90e0 * tan2 + 46e0 * eta1 + 45e0 * tan4 - 252e0 * tan2 * eta1 - 3e0 * eta2 + 100e0 * eta3 - 66e0 * tan2 * eta2 - 90e0 * tan4 * eta1 + 88e0 * eta4 + 225e0 * tan4 * eta2 + 84e0 * tan2 * eta3 - 192e0 * tan2 * eta4) / (720e0 * sr * Math.pow(sn, 5) * Math.pow(tmparams.TranMerc_Scale_Factor, 6));
        var t13 = tan1 * (1385e0 + 3633e0 * tan2 + 4095e0 * tan4 + 1575e0 * Math.pow(tan1, 6)) / (40320e0 * sr * Math.pow(sn, 7) * Math.pow(tmparams.TranMerc_Scale_Factor, 8));
        tmresult.latitude = ftphi - Math.pow(de, 2) * t10 + Math.pow(de, 4) * t11 - Math.pow(de, 6) * t12 + Math.pow(de, 8) * t13;
        var t14 = 1e0 / (sn * c * tmparams.TranMerc_Scale_Factor);
        var t15 = (1e0 + 2e0 * tan2 + eta1) / (6e0 * Math.pow(sn, 3) * c * Math.pow(tmparams.TranMerc_Scale_Factor, 3));
        var t16 = (5e0 + 6e0 * eta1 + 28e0 * tan2 - 3e0 * eta2 + 8e0 * tan2 * eta1 + 24e0 * tan4 - 4e0 * eta3 + 4e0 * tan2 * eta2 + 24e0 * tan2 * eta3) / (120e0 * Math.pow(sn, 5) * c * Math.pow(tmparams.TranMerc_Scale_Factor, 5));
        var t17 = (61e0 + 662e0 * tan2 + 1320e0 * tan4 + 720e0 * Math.pow(tan1, 6)) / (5040e0 * Math.pow(sn, 7) * c * Math.pow(tmparams.TranMerc_Scale_Factor, 7));
        /* Difference in Longitude */

        var dlam = de * t14 - Math.pow(de, 3) * t15 + Math.pow(de, 5) * t16 - Math.pow(de, 7) * t17;
        /* Longitude */

        tmresult.longitude = tmparams.TranMerc_Origin_Long + dlam;

        if (Math.abs(tmresult.latitude) > 90.0 * tmconstants.PI / 180.0) {
          errors.push({
            code: 0x0008,
            desc: 'Northing outside of valid range (depending on ellipsoid and projection parameters)'
          });
        }

        if (tmresult.longitude > tmconstants.PI) {
          tmresult.longitude -= 2 * tmconstants.PI;

          if (Math.abs(tmresult.longitude) > tmconstants.PI) {
            errors.push({
              code: 0x0004,
              desc: 'Easting outside of valid range (depending on ellipsoid and projection parameters)'
            });
          }
        } else if (tmresult.longitude < -tmconstants.PI) {
          tmresult.longitude += 2 * tmconstants.PI;

          if (Math.abs(tmresult.longitude) > tmconstants.PI) {
            errors.push({
              code: 0x0004,
              desc: 'Easting outside of valid range (depending on ellipsoid and projection parameters)'
            });
          }
        }

        if (Math.abs(dlam) > 9.0 * tmconstants.PI / 180 * Math.cos(tmresult.latitude)) {
          /* Distortion will result if Longitude is more than 9 degrees from the Central Meridian at the equator */

          /* and decreases to 0 degrees at the poles */

          /* As you move towards the poles, distortion will become more significant */
          errors.push({
            code: 0x0200,
            desc: 'Distortion will result if longitude is more than 9 degrees from the Central Meridian'
          });
        }
      }

      return {
        output: tmresult,
        parameters: tmparams,
        errorCodes: errors
      };
    }
  };
  var _default = tmconversions;
  _exports.default = _default;
});