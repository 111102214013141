define("sky-router-3/models/trip-plan-leg-waypoint", ["exports", "@ember-data/model", "sky-router-3/models/asset-position-type", "sky-router-3/utils/computed-promise", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _model, _assetPositionType, _computedPromise, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_ICONS = void 0;
  var ROUTE_ICONS = {
    0: '/skyrouter3/assets/images/waypoint.svg',
    unknown: _assetPositionType.POSITION_ICONS['unknown']
  };
  _exports.ROUTE_ICONS = ROUTE_ICONS;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    latitudeMilliarcSeconds: (0, _emberCpValidations.validator)('latlng', {
      latitude: true,
      disallowZero: true
    }),
    longitudeMilliarcSeconds: (0, _emberCpValidations.validator)('latlng', {
      longitude: true,
      disallowZero: true
    })
  });

  var _default = _fragment.default.extend(VALIDATIONS, {
    api: Ember.inject.service(),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    latitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    longitudeMilliarcSeconds: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    altitudeCentimeters: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Gets a timezone object based on the position of this waypoint
     * @type {Object}
     */
    timeZone: (0, _computedPromise.default)(function () {
      return this.api.getTimeZonePosition(this.get('latitudeMilliarcSeconds'), this.get('longitudeMilliarcSeconds'));
    }, undefined)
  });

  _exports.default = _default;
});