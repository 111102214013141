define("sky-router-3/pods/portal/track/info/controller", ["exports", "sky-router-3/utils/lat-lng-converter", "sky-router-3/pods/components/portal/track/map/lib/computed-elevation"], function (_exports, _latLngConverter, _computedElevation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['latitude', 'longitude'],

    /**
     * Elevation above 0 MSL based on a sphereoid
     *  NOTE: this costs money each time it is used. Be careful on usage
     */
    elevationCM: (0, _computedElevation.default)('googleLatLng', true, true),
    googleLatLng: Ember.computed('latitude', 'longitude', function () {
      return {
        lat: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.latitude),
        lng: _latLngConverter.default.milliArcSecondsToDecimalDeg(this.longitude)
      };
    })
  });

  _exports.default = _default;
});