define("sky-router-3/pods/portal/report/submitted-forms/controller", ["exports", "sky-router-3/utils/constants", "moment"], function (_exports, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['sDate', 'eDate', 'timeZoneMinutes', 'selectedFile'],

    /**
     * The start date selected by the user
     * @type {Date}
     */
    sDate: null,
    sDateObject: Ember.computed('sDate', {
      get: function get() {
        var date = (0, _moment.default)(this.sDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().startOf('day').add(-1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('sDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The end date selected by the user
     * @type {Date}
     */
    eDate: null,
    eDateObject: Ember.computed('eDate', {
      get: function get() {
        var date = (0, _moment.default)(this.eDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().endOf('day').add(1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('eDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The user selected template file
     */
    selectedFile: null,

    /**
     * Translation service
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Translation service
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * A flag that is flipped when an export is in progress or completes
     * @type {Boolean}
     */
    exportInProgress: false,

    /**
     * Toggle to show UTC time or the user's local time
     * @type {Boolean}
     */
    showUTCTime: false,

    /**
     * When this is toggled the report will generate
     * @type {Boolean}
     */
    generateReport: false,

    /**
     * The API route for the report data
     */
    apiRoute: 'SubmittedFormsReport',

    /**
     * The name of the report
     */
    reportName: function () {
      return this.intl.t('nav.submittedForms');
    }.property(),

    /**
     * The dynamic columns for the report
     */
    reportCols: null,

    /**
     * Property for the selectedFile that updates the query string selectedFile
     * @return {Object} The selected file
     */
    fileOption: function () {
      var file = this.get('model.selectedFile');
      var fileId = file ? file.id : null;
      this.set('selectedFile', fileId);
      return fileId;
    }.property('model.selectedFile'),

    /**
     * Filter the files by those that have current instances
     */
    fileOptions: Ember.computed.filter('model.files.[]', function (file) {
      return file.hasMany('instances').ids().length > 0;
    }),

    /**
     * Makes the API call to retrieve the export reports. It toggles the enabled flags for the export buttons until finished.
     * An error message is displayed to the user if there was an exception during the request.
     * @param  {string} exportType The name of the current export type
     * @param  {string} fileName   The file name of the export to send to the user
     * @return {void}
     */
    exportReport: function exportReport(exportType, fileName) {
      var _this = this;

      // Disable export buttons
      this.set('exportInProgress', true); // Get report parameters

      var selectedFile = this.get('selectedFile');
      var utcOffset = this.timeZone.utcOffset;
      var startDate = this.sDateObject.toISOString();
      var endDate = this.eDateObject.toISOString(); // Call the API

      this.api.getSubmittedFormsReportExport(fileName, selectedFile, startDate, endDate, utcOffset, exportType).catch(function () {
        _this.notifications.error(_this.intl.t('report.exportFailed', {
          exportType: exportType
        }));
      }).finally(function () {
        _this.set('exportInProgress', false);
      });
    },

    /**
     * Creates a UTC date string to be used in the export file name
     * @return {string} The
     */
    getUTCDateString: function getUTCDateString() {
      return (0, _moment.default)().utc().format('YYYY-MM-DD HH.mm.ss');
    },
    actions: {
      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        var _this2 = this;

        var file = this.get('model.selectedFile');
        if (file === undefined) return; // Call the API

        this.get('api').getSubmittedFormsColumns(parseInt(file.id)).then(function (response) {
          // Set the report columns
          _this2.set('reportCols', response); // Triggers the report to generate in the component


          _this2.toggleProperty('generateReport');
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('report.loadFailed'));
        });
      },

      /**
       * The event handler for the Export Excel click button. This will make the API call for the export
       * @function
       * @private
       */
      exportExcel: function exportExcel() {
        this.exportReport('Excel', this.intl.t('nav.submittedForms') + '-' + this.getUTCDateString() + '.xlsx');
      },

      /**
       * The event handler for the Export CSV click button. This will make the API call for the export
       * @function
       * @private
       */
      exportCSV: function exportCSV() {
        this.exportReport('CSV', this.intl.t('nav.submittedForms') + '-' + this.getUTCDateString() + '.csv');
      },

      /**
       * The event handler for the Export PDF click button. This will make the API call for the export
       * @function
       * @private
       */
      exportPDF: function exportPDF() {
        this.exportReport('PDF', this.intl.t('nav.submittedForms') + '-' + this.getUTCDateString() + '.zip');
      },
      fileChanged: function fileChanged(selection) {
        this.set('model.selectedFile', selection);
      }
    }
  });

  _exports.default = _default;
});