define("sky-router-3/pods/components/portal/track/map/shape-polygon/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component handles drawing tasks on a map.
   * @see https://developers.google.com/maps/documentation/javascript/reference/polygon#Polygon
   * @example {{drawing-manager map=map mode=google.maps.drawing.OverlayType.CIRCLE circleCompleted='circle' polygonCompleted='polygon' }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    /**
     * Action that receives a MVCArray<LatLng>
     */
    onPathChanged: function onPathChanged() {},
    onClick: function onClick() {},

    /**
     * Google mapping properties for the mixin
     */
    googlePropertyObject: Ember.computed.alias('_instance'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'options',
      setFunc: 'setOptions'
    }, {
      propert: 'editable',
      setFunc: 'setEditable'
    }, {
      property: '_path',
      setFunc: 'setPath'
    }, {
      event: 'click',
      eFunc: 'onClick'
    }],

    /**
     * The instance of the drawing manager
     * @type {google.maps.Polygon}
     */
    _instance: null,

    /**
     * Passed in map instance the drawing manager is associated with
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * The array ot latitude longitude
     * @see https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng
     * @type {Array<LatLng|LatLngLiteral>}
     */
    path: null,

    /**
     * Is the object editable or not
     */
    editable: true,

    /**
     * Options object that is used to configure the instance
     * @type {google.maps.PolygonOptions}
     */
    options: Ember.computed(function () {
      return {
        draggable: this.editable,
        editable: this.editable,
        fillColor: 'yellow',
        fillOpacity: 0.25,
        strokeColor: '#000000',
        strokeOpacity: 0.5,
        geodesic: true
      };
    }),

    /**
     * Init the instance
     */
    didInsertElement: function didInsertElement() {
      var instance = new google.maps.Polygon(this.options);
      this.set('_instance', instance);
    },

    /**
     * Destroy the instance
     */
    willDestroyElement: function willDestroyElement() {
      this._instance.setMap(null);
    },

    /**
     * Internal watchable array that can keep track of the path as it changes
     */
    _path: Ember.computed('path.[]', {
      get: function get() {
        // TODO: IF EDITING, DON'T SET THIS
        var latLngArray = new google.maps.MVCArray(this.path.map(function (p) {
          return new google.maps.LatLng(p);
        })); // Attach the lister for changes to the placement of the points in the array
        // Since the polygon object doesn't have a path_changed event.
        // We will use it to set the changes into the geofence.points.

        google.maps.event.addListener(latLngArray, 'set_at', Ember.run.bind(this, this.onPathChanged, latLngArray)); // Attach the lister for changes to the addition of the points in the array

        google.maps.event.addListener(latLngArray, 'insert_at', Ember.run.bind(this, this.onPathChanged, latLngArray)); //Attach the listener for changes to the removal of the points in the array

        google.maps.event.addListener(latLngArray, 'remove_at', Ember.run.bind(this, this.onPathChanged, latLngArray)); // Return the final array

        return latLngArray;
      }
    })
  });

  _exports.default = _default;
});