define("sky-router-3/pods/components/two-factor-auth/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['text-center'],
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    onvalidated: function onvalidated() {},
    username: null,
    password: null,
    token: null,
    blockForm: false,
    isInvalid: false,
    isValid: false,
    actions: {
      validate: function validate(token) {
        var _this = this;

        var username = this.get('username');
        var password = this.get('password');
        this.set('blockForm', true);
        this.get('api').post("AuthyUsers/Validate", {
          data: {
            username: username,
            password: password,
            token: token
          }
        }).then(function (result) {
          _this.set('isInvalid', false);

          _this.set('isValid', true);

          _this.notifications.clearAll();

          _this.onvalidated(username, password, {
            twoFactorTokenName: result.TwoFactorTokenName,
            twoFactorToken: result.TwoFactorToken,
            twoFactorExpirationDate: result.twoFactorExpirationDate
          });
        }).catch(function () {
          _this.set('isInvalid', true);

          _this.set('isValid', false);
        }).finally(function () {
          _this.set('blockForm', false);
        });
      },
      send: function send(type) {
        var _this2 = this;

        var force = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('blockForm', true);
        this.get('api').post("AuthyUsers/Send", {
          data: {
            username: this.get('username'),
            password: this.get('password'),
            type: type === "sms" ? 0 : 1,
            force: force
          }
        }).then(function (result) {
          var intl = _this2.get('intl');

          if (result.Ignored) {
            _this2.notifications.warning(intl.t('two-factor.sendWarning'), {
              clearDuration: 15000,
              onClick: function onClick() {
                _this2.notifications.clearAll();

                _this2.send('send', type, true);
              }
            });
          } else if (result.Success) {
            _this2.notifications.success(intl.t('two-factor.sendSuccess', {
              phone: result.phone
            }));
          } else {
            _this2.notifications.error(intl.t('misc.failedOperation'));
          }
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this2.set('blockForm', false);
        });
      }
    }
  });

  _exports.default = _default;
});