define("sky-router-3/pods/components/portal/manage/device-parameters/controls/distance-reporting/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'meterSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: Ember.computed.alias('model.meterSetting.validationMinNumber'),
      lte: Ember.computed.or('model.meterValidationMax', 'model.meterSetting.validationMaxNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * Passed in settings
     * @type {[type]}
     */
    enabledSetting: null,
    meterSetting: null,
    disabled: false,
    meterValidationMax: null,
    actions: {
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('meterSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});