define("sky-router-3/pods/components/permission-role-tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DnTpP3I7",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"model\",\"cascadeUp\",\"onChecked\",\"disabled\"],[[35,3],[35,2],[30,[36,1],[[32,0],\"treeChanged\"],null],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"action\",\"cascadeCheckUp\",\"tree\",\"checkbox-tree\"]}",
    "moduleName": "sky-router-3/pods/components/permission-role-tree/template.hbs"
  });

  _exports.default = _default;
});