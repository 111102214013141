define("sky-router-3/models/trip-plan-custom-field", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "sky-router-3/utils/computed-promise"], function (_exports, _model, _fragment, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    type: (0, _model.attr)('number'),
    value: (0, _model.attr)(),
    typeInstance: (0, _computedPromise.default)('type', function () {
      if (this.type) {
        return this.store.findRecord('trip-plan-custom-field-type', this.type);
      }

      return undefined;
    }, null)
  });

  _exports.default = _default;
});