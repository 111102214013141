define("sky-router-3/models/incident-report", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the model for an incident report log entry
   */
  var model = _model.default.extend({
    /**
     * The asset for the incident report.
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The trip id if applicable
     */
    positionTrackingTripHistory: (0, _model.belongsTo)('trip'),

    /**
    * The GPS type for the position
    */
    positionReportType: (0, _model.belongsTo)('asset-position-type'),

    /**
    * The position record associated with the log record
    */
    assetPosition: (0, _model.belongsTo)('asset-position', {
      async: true
    }),

    /**
    * Was the log entry acknowledged or not?
    */
    acknowledged: (0, _model.attr)('boolean'),

    /**
    * The start timestamp for the log record.
    */
    incidentStartDateUtc: (0, _model.attr)('date'),

    /**
    * The end timestamp for the log record.
    */
    incidentStopDateUtc: (0, _model.attr)('date'),

    /**
    * The user id for the acknowledgment
    */
    ackUser: (0, _model.belongsTo)('user'),

    /**
    * The timestamp for the acknowledgment
    */
    ackDateUtc: (0, _model.attr)('date'),

    /**
    * The note for the acknowledgment
    */
    note: (0, _model.attr)('string'),

    /**
    * The user id for the latest edit of the acknowledgment
    */
    ackEditUser: (0, _model.belongsTo)('user'),

    /**
    * The timestamp for the edit of the acknowledgment
    */
    ackEditDateUtc: (0, _model.attr)('date'),

    /**
    * Has the original acknowledgement been edited?
    */
    isEdited: Ember.computed('ackEditUser', function () {
      return this.get('ackEditUser.id') !== undefined;
    }),

    /**
    * The created date for the log record
    */
    CreatedDateUtc: (0, _model.attr)('date')
  });

  var _default = model;
  _exports.default = _default;
});