define("sky-router-3/models/invoice-plan-usage-data", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var model = _model.default.extend({
    /**
     * The name of the plan
     */
    sbdPlanName: (0, _model.attr)('string'),

    /**
    * The used SBD units for the invoice
    **/
    sbdUsedUnits: (0, _model.attr)('number'),

    /**
    * The units included in the plan
    **/
    sbdPlanUnits: (0, _model.attr)('number'),

    /**
    * The invoice date for the invoice
    **/
    invoiceDate: (0, _model.attr)('date'),

    /**
    * The usage percentage for the plan
    **/
    actualUsagePct: function () {
      var planUnits = this.sbdPlanUnits;
      var usedUnits = this.sbdUsedUnits;
      return planUnits === 0 ? 1 : usedUnits / planUnits;
    }.property('sbuUsedUnits', 'sbdPlanUnits'),

    /**
    * The usage percentage for the plan calulated as a max of 100%
    **/
    sbdUsagePct: function () {
      return Math.min(1, this.actualUsagePct);
    }.property('actualUsagePct'),

    /**
    * The sbd usage pct as a string
    **/
    sbdUsagePctString: function () {
      return Math.round(this.sbdUsagePct * 100) + '%';
    }.property('sbdUsagePct'),

    /**
    * The count of units used that are over the plan included units
    **/
    sbdOverUnits: function () {
      return this.sbdUsedUnits < this.sbdPlanUnits ? 0 : this.sbdUsedUnits - this.sbdPlanUnits;
    }.property('sbuUsedUnits', 'sbdPlanUnits'),
    sbdRemainingUnits: function () {
      return this.sbdUsedUnits > this.sbdPlanUnits ? 0 : this.sbdPlanUnits - this.sbdUsedUnits;
    }.property('sbuUsedUnits', 'sbdPlanUnits'),

    /**
    * The styling of the usage bar based on the usage percentage
    **/
    sbdStyle: function () {
      if (this.sbdPlanUnits === 0) {
        return 'usage-zero-plan';
      } else if (this.actualUsagePct < .80) {
        return 'usage-normal';
      } else if (this.actualUsagePct >= .80 && this.actualUsagePct <= 1) {
        return 'usage-high';
      } else {
        return 'usage-over';
      }
    }.property('sbdPlanUnits', 'actualUsagePct')
  });

  var _default = model;
  _exports.default = _default;
});