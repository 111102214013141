define("sky-router-3/pods/portal/track/eta/route", ["exports", "sky-router-3/utils/object/create", "sky-router-3/objects/state-track/state-track-map-destination"], function (_exports, _create, _stateTrackMapDestination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    events: Ember.inject.service(),
    mapConfig: null,
    model: function model(params, transition) {
      return (0, _create.default)(_stateTrackMapDestination.default, {
        latitudeMilliarcSeconds: transition.to.queryParams.latitude,
        longitudeMilliarcSeconds: transition.to.queryParams.longitude
      });
    },
    afterModel: function afterModel(model) {
      this.mapConfig = this.modelFor('portal.track').state.mapConfigActiveInstance;
      this.mapConfig.assetDestinations.addObject(model);
      model.isEditing = true;
    },
    activate: function activate() {
      this.events.on('mapClick', this, this.setupInitialCoords);
    },
    deactivate: function deactivate() {
      this.events.off('mapClick', this, this.setupInitialCoords);
      var model = this.modelFor(this.routeName); // If leaving while model is invalid, delete it

      if (!model.asset || !model.latitudeMilliarcSeconds || !model.longitudeMilliarcSeconds) {
        this.mapConfig.assetDestinations.removeObject(model);
      } else {
        model.isEditing = false;
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('assets', this.modelFor('portal.track').assets);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('latitude', null);
        controller.set('longitude', null);
      }
    },
    setupInitialCoords: function setupInitialCoords(coords) {
      var model = this.modelFor(this.routeName);

      if (!model.latitudeMilliarcSeconds) {
        model.latitudeMilliarcSeconds = coords.latitudeMilliarcSeconds;
        model.longitudeMilliarcSeconds = coords.longitudeMilliarcSeconds;
      }
    },
    actions: {
      delete: function _delete(destination) {
        this.mapConfig.assetDestinations.removeObject(destination);
        this.transitionTo('portal.track');
      },
      save: function save() {
        this.transitionTo('portal.track');
      }
    }
  });

  _exports.default = _default;
});