define("sky-router-3/models/trip-plan", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'asset.id': (0, _emberCpValidations.validator)('presence', true),
    'type.id': (0, _emberCpValidations.validator)('presence', true),
    legs: (0, _emberCpValidations.validator)('has-many')
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)('string'),
    notes: (0, _model.attr)('string'),

    /**
     * The type of trip plan this is.
     * 0 - air
     * 1 - land
     * 2 - Marine
     * 3 - Other
     * @type {Number}
     */
    type: (0, _model.belongsTo)('trip-plan-type'),

    /**
     * Any legs that are associated with the trip plan.
     * Trips are comprised of many legs
     */
    legs: (0, _model.hasMany)('trip-plan-leg'),

    /**
     * The asset associated with this trip plan.
     * This is REQUIRED
     * @type {sky-router-3/models/asset}
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The date the plan was created
     */
    createdUtc: (0, _model.attr)('date')
  });

  _exports.default = _default;
});