define("sky-router-3/pods/portal/manage/device-icons/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * gets or sets the color value used by the UI
     */
    color: 'ff0000',
    colorCss: Ember.computed('color', function () {
      return "#".concat(this.color);
    }),
    iconsSorted: Ember.computed.sort('model', 'iconsSortedBy'),
    iconsSortedBy: ['isGlobal', 'name']
  });

  _exports.default = _default;
});