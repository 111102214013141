define("sky-router-3/validators/dropdown", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extends the base validator to allow for validation of phone numbers.
   *
   * This object will attempt to lookup the localized error message using the key 'errors.badPhone'
   *
   * The options object used when creating this validator is formatted like This
   *
   * {
   *    allowBlank: true|false
   * }
   * Both properties default to false.
   */
  var Phone = _base.default.extend({
    /**
     * Regex used to validate email addresses,
     * From here: http://www.regular-expressions.info/email.html
     */
    regex: /^(\s*\+[^,+]*)(,\s*\+[^,+]*)*$/,

    /**
     * Runs the validator
     */
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      if (Ember.isEmpty(value)) {
        if (this.options.allowBlank) {
          return true;
        }

        return this.createErrorMessage('badPhone', value, options);
      } // If the value is an array then turn it back to a CSV to run the check on


      if (Ember.isArray(value)) {
        value = value.join(',');
      } // Run the regex


      if (!this.regex.test(value)) {
        return this.createErrorMessage('badPhone', value, options);
      }

      return true;
    }
  });

  var _default = Phone;
  _exports.default = _default;
});