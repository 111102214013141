define("sky-router-3/validators/acceptance", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Acceptance = _base.default.extend({
    validate: function validate(value, options
    /*, model, attribute*/
    ) {
      if (value || options.disabled) {
        return true;
      }

      return this.createErrorMessage('accepted', value, options);
    }
  });

  var _default = Acceptance;
  _exports.default = _default;
});