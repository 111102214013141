define("sky-router-3/pods/components/portal/settings/volume-units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XQnzGsdo",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"settings.unitsVolumeUnitsTitle\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"settings.unitsVolumeUnitsDescription\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group col-md-6\"],[12],[2,\"\\n            \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"settings.unitsVolume\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],null,[[\"options\",\"selected\",\"onChange\"],[[35,5],[35,2,[\"volume\"]],[30,[36,4],[[32,0],[30,[36,3],[[35,2,[\"volume\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"user\",\"mut\",\"action\",\"volumeUnits\",\"input-select\"]}",
    "moduleName": "sky-router-3/pods/components/portal/settings/volume-units/template.hbs"
  });

  _exports.default = _default;
});