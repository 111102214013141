define("sky-router-3/models/api-key", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Is the key enabled
     */
    enabled: (0, _model.attr)('boolean'),

    /**
     * The application this API key can be used to authenticate for
     */
    app: (0, _model.belongsTo)('api-key-app'),

    /**
     * Has the key been activated
     */
    isActivated: (0, _model.attr)('boolean'),

    /**
     * IP Address the key was activated from
     */
    activatedByIp: (0, _model.attr)('string'),

    /**
     * Information about the device that activated the key
     */
    activatedBy: (0, _model.attr)('string'),

    /**
     * The date the key was activated
     */
    activatedDateUtc: (0, _model.attr)('date'),
    createdDateUtc: (0, _model.attr)('date'),

    /**
     * The ApiKey returned for burst services only
     */
    key: (0, _model.attr)('string')
  });

  _exports.default = _default;
});