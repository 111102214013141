define("sky-router-3/pods/components/portal/track/map/line/line-trip/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/lat-lng-converter"], function (_exports, _googleObjectMapping, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This class represents a line between waypoints.
   * It wraps the Google Maps API class `google.maps.Polyline`
   *
   * @example {{line-trip-waypoint map=map waypoints=[]}}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('line'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'path',
      setFunc: 'setPath'
    }],

    /**
     * Incoming object.
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Passed in trip waypoints for display
     */
    waypoints: null,

    /**
     * The passed in asset this trip is referencing
     */
    asset: null,

    /**
     * Reference to the instance of the Google Polyline
     * @type {google.maps.Polyline}
     */
    line: null,

    /**
     * The options object that is used to set options
     * on the polyline from ember.
     */
    options: Ember.computed('asset.color', function () {
      return {
        geodesic: true,
        clickable: false,
        strokeWeight: 2,
        strokeColor: this.get('asset.color') || '#000000',
        zIndex: 498,
        icons: [{
          offset: '0%',
          repeat: '100%',
          icon: {
            path: google.maps.SymbolPath.CIRCLE
          }
        }, {
          offset: '10%',
          repeat: '25%',
          icon: {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
          }
        }]
      };
    }),

    /**
     * The path which this line takes
     */
    path: Ember.computed('waypoints.[]', 'waypoints.@each.latitudeMilliarcSeconds', 'waypoints.@each.longitudeMilliarcSeconds', function () {
      if (this.get('waypoints')) {
        return this.get('waypoints').map(function (point) {
          return _latLngConverter.default.modelToGoogleLatLng(point);
        });
      }

      return [];
    }),

    /**
     * Creates the polyline Google maps object
     * and sets it.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var line = new google.maps.Polyline(this.get('options'));
      this.set('line', line);
    },

    /**
     * Removes the line from the map and destroys the
     * reference to it.
     */
    willDestroyElement: function willDestroyElement() {
      this.line.setMap(null);
    }
  });

  _exports.default = _default;
});