define("sky-router-3/pods/components/bs-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+VIYdrJw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[1,[34,0]],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"isLoading\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/bs-button/template.hbs"
  });

  _exports.default = _default;
});