define("sky-router-3/models/contract", ["exports", "@ember-data/model", "moment"], function (_exports, _model, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Name of the contract
     */
    name: (0, _model.attr)('string'),

    /**
     * starting date of contract
     */
    startDate: (0, _model.attr)('date'),

    /**
     * ending date of contract
     */
    endDate: (0, _model.attr)('date'),

    /**
     * specifies whether or not this contract is deleted
     */
    deleted: (0, _model.attr)('boolean'),

    /**
     * Notes associated with the contract
     */
    notes: (0, _model.attr)('string'),

    /**
     * A custom field containing key-value pairs
     */
    properties: (0, _model.attr)('ember-object-array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),

    /**
     * The contact for the contract
     * @type {sky-router-3/models/contact}
     */
    contact: (0, _model.belongsTo)('contact'),

    /**
     * Assets assigned to this contract
     * @type {sky-router-3/models/asset}
     */
    assets: (0, _model.hasMany)('asset'),

    /**
     * Groups assigned to this contract
     * @type {sky-router-3/models/group}
     */
    groups: (0, _model.hasMany)('group'),

    /**
     * Indicates if the contract has started because the start date is in the past
     */
    isActive: Ember.computed('startDate', 'endDate', function () {
      var date = (0, _moment.default)(new Date()).utc();
      var start = (0, _moment.default)(this.get('startDate'));
      var end = (0, _moment.default)(this.get('endDate'));
      return date.isBetween(start, end, 'days', '[]');
    }),

    /**
     * Is the enddate for the group in the past
     */
    isExpired: Ember.computed('endDate', function () {
      var date = (0, _moment.default)(new Date()).utc();
      var end = (0, _moment.default)(this.get('endDate'));
      return end.isBefore(date);
    })
  });

  _exports.default = _default;
});