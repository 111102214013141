define("sky-router-3/pods/portal/settings/notifications/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * The sessionAccount service
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),
    eventOptions: Ember.computed.filterBy('model.alertTriggerEvents', 'supportAlertAction')
  });

  _exports.default = _default;
});