define("sky-router-3/pods/components/portal/track/trip-list/component", ["exports", "sky-router-3/utils/computed-promise"], function (_exports, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    assets: null,
    // Passed in assets from the callee
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    api: Ember.inject.service(),

    /**
     * Config that holds all the properties
     * @type {sky-router-3/object/state-track/state-track-trip-list}
     */
    config: null,
    // Data
    trips: (0, _computedPromise.default)('config.completed', 'config.future', 'config.sortProperty', 'config.sortDesc', 'config.active', function () {
      return this.store.query('trip', {
        skip: 0,
        take: 1000,
        completed: this.config.completed,
        future: this.config.future,
        active: this.config.active,
        latestOnly: true,
        sort: this.config.sortProperty,
        sortDesc: this.config.sortDesc
      });
    }, [{
      loading: true
    }]),
    // Auto refresh every 5 minutes so the query and sort are up to date
    didInsertElement: function didInsertElement() {
      this.setupTripRefresh();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this._tripRefresh);
      this._tripRefresh = null;
    },
    setupTripRefresh: function setupTripRefresh() {
      var _this = this;

      this._tripRefresh = Ember.run.later(this, function () {
        _this.notifyPropertyChange('trips');

        _this.setupTripRefresh();
      }, 300000);
    },
    actions: {
      toggleCurrentSortDesc: function toggleCurrentSortDesc() {
        this.toggleProperty('config.sortDesc');
      },
      showTripLine: function showTripLine(trip) {
        this.onWindowAction(this, 'showTripRoute', {
          tripId: trip.get('id')
        });
      },
      centerOnAsset: function centerOnAsset(asset) {
        this.onWindowAction(this, 'centerOnAsset', {
          assetId: asset.get('id')
        });
      },
      ontzpick: function ontzpick(selection) {
        this.set('config.tz', selection.id);
        this.set('config.tzOffset', selection.offset);
      },
      openTrip: function openTrip(trip) {
        var _this2 = this;

        if (confirm('Are you sure? This will start the trip at the most recent location for the asset.')) {
          this.api.post("Trips/".concat(trip.id, "/Open")).catch(function () {
            _this2.notifications.error(_this2.intl.t('misc.failedOperation'));
          });
        }
      },
      closeTrip: function closeTrip(trip) {
        var _this3 = this;

        var isCanceled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (confirm(this.intl.t('misc.areYouSureConfirm'))) {
          this.api.post("Trips/".concat(trip.id, "/Close?cancel=").concat(isCanceled)).catch(function () {
            _this3.notifications.error(_this3.intl.t('misc.failedOperation'));
          });
        }
      },
      markOverdue: function markOverdue(leg, isOverdue) {
        var _this4 = this;

        if (confirm(this.intl.t('misc.areYouSureConfirm'))) {
          leg.set('manualIsOverdue', isOverdue ? 1 : 0);
          leg.save().catch(function () {
            _this4.notifications.error(_this4.intl.t('misc.failedOperation'));
          });
        }
      },
      refresh: function refresh() {
        this.notifyPropertyChange('trips');
      },
      resetFilter: function resetFilter() {
        this.config.resetToDefault();
      }
    }
  });

  _exports.default = _default;
});