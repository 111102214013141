define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-3/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0A9H/0yT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,3],[\"compoundrules.output.qpos.help\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"name\",\"value\",\"groupValue\",\"disabled\"],[\"emergency\",\"1\",[35,1,[\"value1\"]],[35,0]]]]],[2,\" \"],[1,[30,[36,3],[\"compoundrules.output.qpos.auto\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"name\",\"value\",\"groupValue\",\"disabled\"],[\"emergency\",\"0\",[35,1,[\"value1\"]],[35,0]]]]],[2,\" \"],[1,[30,[36,3],[\"compoundrules.output.qpos.manual\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"model\",\"radio-button\",\"t\",\"input-label\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-3/template.hbs"
  });

  _exports.default = _default;
});