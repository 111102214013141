define("sky-router-3/pods/components/input-duration/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onChange: function onChange()
    /*total minutes*/
    {},
    onUpdate: function onUpdate(prop, currentHours, currentMinutes, currentSeconds, event) {
      if (prop == 'hours') {
        currentHours = parseInt(event.target.value);
      } else if (prop == 'minutes') {
        currentMinutes = parseInt(event.target.value);
      } else {
        currentSeconds = parseInt(event.target.value);
      }

      this.onChange(currentHours * 3600 + currentMinutes * 60 + currentSeconds);
    },

    /**
     * Passed in seconds to use to power the input
     * @type {Number}
     */
    totalSeconds: 0,

    /**
     * Indicates if the seconds value will be shown or not
     * @type {Boolean}
     */
    showSeconds: true,

    /**
     * Is the input disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Scheduled time handler
     */
    _hours: Ember.computed('totalSeconds', function () {
      return Math.floor((this.totalSeconds || 0) / 3600);
    }),
    _minutes: Ember.computed('totalSeconds', function () {
      return Math.floor((this.totalSeconds || 0) / 60 % 60);
    }),
    _seconds: Ember.computed('totalSeconds', function () {
      return (this.totalSeconds || 0) % 60;
    })
  });

  _exports.default = _default;
});