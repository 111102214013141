define("sky-router-3/pods/playback/index/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    assets: (0, _emberCpValidations.validator)('presence', true),
    startDate: (0, _emberCpValidations.validator)('presence', true),
    endDate: (0, _emberCpValidations.validator)('presence', true),
    dateRangeValid: (0, _emberCpValidations.validator)('acceptance')
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    /**
     * The selected asset identifier
     * @type {sky-router-3/models/asset}
     */
    assets: [],
    assetIds: Ember.computed('assets.[]', function () {
      return this.get('assets').mapBy('id').join(',');
    }),

    /**
     * Start date input field
     * @type {String}
     */
    startDate: null,

    /**
     * Input field
     * @type {String}
     */
    endDate: null,

    /**
     * Holds a value that indicates if the date range is valid
     * @return {Boolean}
     */
    dateRangeValid: Ember.computed('endDate', 'startDate', function () {
      var diff = this.get('endDate') - this.get('startDate');
      return diff > 0 && diff < 1209600000; // Dates are less than 2 weeks apart in ms.
    })
  });

  _exports.default = _default;
});