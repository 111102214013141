define("sky-router-3/pods/portal/settings/misc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bOiBGBGb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"page-header\"],[12],[1,[30,[36,0],[\"settings.headerMiscellaneous\"],null]],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationTitle\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"text-danger\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationDescription1\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationDescription2\"],null]],[2,\"\\n        \"],[10,\"ul\"],[12],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationDescription2list1\"],null]],[13],[2,\"\\n            \"],[10,\"li\"],[12],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationDescription2list2\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"settings.miscellaneousResetLocalApplicationDescription3\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"resetLocalApp\"],null],[12],[1,[30,[36,0],[\"misc.buttonResetLocalApplication\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"well\"],[12],[2,\"\\n    \"],[8,\"support-ticket\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "moduleName": "sky-router-3/pods/portal/settings/misc/template.hbs"
  });

  _exports.default = _default;
});