define("sky-router-3/pods/components/portal/track/map/marker-spiderfier/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DjwBgM9y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/marker-spiderfier/template.hbs"
  });

  _exports.default = _default;
});