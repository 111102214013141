define("sky-router-3/pods/components/portal/track/map/line/line-point/component", ["exports", "sky-router-3/mixins/google-object-mapping"], function (_exports, _googleObjectMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This class represents a line between two or more google maps latLong objects.
   * It wraps the Google Maps API class `google.maps.Polyline`
   *
   * @example {{line-point points=points geodesic=true color=asset.color map=map}}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('line'),
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'points',
      setFunc: 'setPath'
    }, {
      property: 'strokeColor',
      setFunc: 'set'
    }],

    /**
     * Incoming object.
     * @type {google.maps.Map}
     */
    map: null,

    /**
     * Incoming array of points to show with the line
     * @type {Array<google.maps.latLng}
     */
    points: null,

    /**
     * Reference to the instance of the Google Polyline
     * @type {google.maps.Polyline}
     */
    line: null,

    /**
     * Incoming HEX color for the line
     * in the format of '#000000'
     */
    color: '#000000',

    /**
     * Indicates if the line is geodesic or not
     * @type {Boolean}
     */
    geodesic: false,

    /**
     * The options object that is used to set options
     * on the polyline from ember.
     */
    options: Ember.computed('color', function () {
      return {
        geodesic: this.get('geodesic'),
        clickable: false,
        strokeWeight: 2,
        strokeOpacity: 0.5,
        strokeColor: this.get('color'),
        zIndex: 498
      };
    }),

    /**
     * Creates the polyline Google maps object
     * and sets it.
     */
    init: function init() {
      this._super.apply(this, arguments);

      var line = new google.maps.Polyline(this.get('options'));
      this.set('line', line);
    },

    /**
     * Removes the line from the map and destroys the
     * reference to it.
     */
    willDestroyElement: function willDestroyElement() {
      this.line.setMap(null);
    }
  });

  _exports.default = _default;
});