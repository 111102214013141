define("sky-router-3/pods/portal/report/index/route", ["exports", "sky-router-3/utils/has-permission"], function (_exports, _hasPermission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service(),
    actions: {
      didTransition: function didTransition() {
        var perm = this.get('sessionAccount.user.permissions');

        if ((0, _hasPermission.default)(perm, 'report.positionreporthistory')) {
          this.replaceWith('portal.report.positionreporthistory');
        } else if ((0, _hasPermission.default)(perm, 'report.deviceinventory')) {
          this.replaceWith('portal.report.deviceinventory');
        } else if ((0, _hasPermission.default)(perm, 'report.groupassociations')) {
          this.replaceWith('portal.report.groupassociations');
        } else if ((0, _hasPermission.default)(perm, 'report.playback')) {
          this.replaceWith('portal.report.playback');
        } else {
          this.replaceWith('portal');
        }
      }
    }
  });

  _exports.default = _default;
});