define("sky-router-3/pods/portal/manage/devices/edit/controller", ["exports", "ember-cp-validations", "sky-router-3/objects/state-track/state-track-map", "sky-router-3/utils/object/create", "sky-router-3/utils/lat-lng-converter"], function (_exports, _emberCpValidations, _stateTrackMap, _create, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.asset.type': (0, _emberCpValidations.validator)('presence', true),
    'model.asset.color': (0, _emberCpValidations.validator)('presence', true),
    'model.asset.name': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /^[a-zA-Z0-9._-]{3,}$/,
      messageKey: 'errors.invalidCharacters'
    }), (0, _emberCpValidations.validator)('asset-name', {
      assetId: Ember.computed.alias('model.model.asset.id'),
      debounce: 200
    })],
    inactiveDisplayMinutes: (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true,
      gt: 0
    }),
    assetPhoto: (0, _emberCpValidations.validator)('file', {
      allowEmpty: true,
      inputId: 'assetPhoto',
      sizeLimit: 10000000,
      allowTypes: /image\/*/
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    store: Ember.inject.service(),
    geoAreaService: Ember.inject.service(),

    /**
     * Map to display/set Comcenter manual position
     */
    mapConfig: Ember.computed(function () {
      return (0, _create.default)(_stateTrackMap.default);
    }),
    manualPosition: Ember.computed('model.asset.manualPosLatitude', 'model.asset.manualPosLongitude', 'model.asset.manualPosGeofenceDiameterInKm', function () {
      this.set('model.manualPosition.latitudeDegree', this.get('model.asset.manualPosLatitude'));
      this.set('model.manualPosition.longitudeDegree', this.get('model.asset.manualPosLongitude'));
      this.set('model.manualPosition.diameterInKm', this.get('model.asset.manualPosGeofenceDiameterInKm'));

      if (this.get('model.manualPosition.diameterInKm') == null) {
        this.geoAreaService.fitMapToGeoAreas(null, this.get('model.mapConfig'));
      } else {
        this.geoAreaService.fitMapToGeoAreas([this.get('model.manualPosition')], this.get('model.mapConfig'));
      }

      return this.get('model.manualPosition');
    }),

    /**
     * Gets or sets a value that indicates if the timer is enabled or not.
     * -1 for disabled 1 for enabled.
     */
    inactiveTimerEnabled: Ember.computed('model.asset.inactiveTimerMinutes', {
      get: function get() {
        return this.get('model.asset.inactiveTimerMinutes') > 0 ? '1' : '0';
      },
      set: function set(prop, value) {
        var newValue = this.get('model.asset.inactiveTimerMinutes') * -1;
        this.set('model.asset.inactiveTimerMinutes', newValue);
        return value;
      }
    }),

    /**
     * Gets or sets the inactive display value.
     */
    inactiveDisplayMinutes: Ember.computed('model.asset.inactiveTimerMinutes', {
      get: function get() {
        return Math.abs(this.get('model.asset.inactiveTimerMinutes'));
      },
      set: function set(prop, value) {
        if (this.get('inactiveTimerEnabled')) {
          this.set('model.asset.inactiveTimerMinutes', value);
        } else {
          this.set('model.asset.inactiveTimerMinutes', value * -1);
        }

        return value;
      }
    }),
    expectedReportEnabled: Ember.computed('model.asset.missedReportingIntervalMinutes', {
      get: function get() {
        return this.get('model.asset.missedReportingIntervalMinutes') > 0;
      },
      set: function set(prop, value) {
        var newValue = this.get('model.asset.missedReportingIntervalMinutes') * -1;
        this.set('model.asset.missedReportingIntervalMinutes', newValue);
        return value;
      }
    }),
    expectedReportMinutes: Ember.computed('model.asset.missedReportingIntervalMinutes', {
      get: function get() {
        return Math.abs(this.get('model.asset.missedReportingIntervalMinutes'));
      },
      set: function set(prop, value) {
        if (this.get('expectedReportEnabled')) {
          this.set('model.asset.missedReportingIntervalMinutes', value);
        } else {
          this.set('model.asset.missedReportingIntervalMinutes', value * -1);
        }

        return value;
      }
    }),
    expectedReportWarnEnabled: Ember.computed('model.asset.missedReportingIntervalWarnMinutes', {
      get: function get() {
        return this.get('model.asset.missedReportingIntervalWarnMinutes') > 0;
      },
      set: function set(prop, value) {
        var newValue = this.get('model.asset.missedReportingIntervalWarnMinutes') * -1;
        this.set('model.asset.missedReportingIntervalWarnMinutes', newValue);
        return value;
      }
    }),
    expectedReportWarnMinutes: Ember.computed('model.asset.missedReportingIntervalWarnMinutes', {
      get: function get() {
        return Math.abs(this.get('model.asset.missedReportingIntervalWarnMinutes'));
      },
      set: function set(prop, value) {
        if (this.get('expectedReportWarnEnabled')) {
          this.set('model.asset.missedReportingIntervalWarnMinutes', value);
        } else {
          this.set('model.asset.missedReportingIntervalWarnMinutes', value * -1);
        }

        return value;
      }
    }),
    expectedReportDangerEnabled: Ember.computed('model.asset.missedReportingIntervalDangerMinutes', {
      get: function get() {
        return this.get('model.asset.missedReportingIntervalDangerMinutes') > 0;
      },
      set: function set(prop, value) {
        var newValue = this.get('model.asset.missedReportingIntervalDangerMinutes') * -1;
        this.set('model.asset.missedReportingIntervalDangerMinutes', newValue);
        return value;
      }
    }),
    expectedReportDangerMinutes: Ember.computed('model.asset.missedReportingIntervalDangerMinutes', {
      get: function get() {
        return Math.abs(this.get('model.asset.missedReportingIntervalDangerMinutes'));
      },
      set: function set(prop, value) {
        if (this.get('expectedReportDangerEnabled')) {
          this.set('model.asset.missedReportingIntervalDangerMinutes', value);
        } else {
          this.set('model.asset.missedReportingIntervalDangerMinutes', value * -1);
        }

        return value;
      }
    }),
    driverOptions: Ember.computed('model.drivers', {
      get: function get() {
        var _this = this;

        var list = this.model.drivers.map(function (d) {
          return d;
        });
        var sorted = list.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        }); // Hidden choice to clear up selection

        sorted.unshift({
          type: 'manual entry',
          name: 'Unassign',
          id: -1,
          hidden: true
        }); // Set Initial value in the drop down if it's a designated driver

        var driver = this.model.drivers.find(function (driver) {
          return driver.id == _this.model.asset.get('driverId');
        }); // number == string 
        // If not set, it was a manually entered driver, so set to it

        if (!driver && this.model.asset.driverName && this.model.asset.driverName != '') {
          sorted.push({
            type: 'manual entry',
            name: this.model.asset.driverName
          });
          this.set('model.driverSelected', {
            type: 'manual entry',
            name: this.model.asset.driverName
          });
        } else {
          this.set('model.driverSelected', driver);
        }

        return sorted;
      }
    }),

    /**
     * Is the 'active' event checkbox disabled or enabled
     * @type {Boolean}
     */
    activeCheckboxDisabled: Ember.computed.lt('model.asset.inactiveTimerMinutes', 0),

    /**
     * gets or sets the color value used by the UI
     */
    color: Ember.computed('model.asset.color', {
      get: function get() {
        return this.get('model.asset.color').substring(1);
      },
      set: function set(prop, value) {
        this.set('model.asset.color', "#".concat(value));
        return value;
      }
    }),

    /**
     * Indicates if the HEPT canned messages block should be shown.
     * @return {Boolean}
     */
    shouldShowHeptCannedMessages: function () {
      var product = this.get('model.asset').belongsTo('product').id();
      return product.toLowerCase().indexOf('hept') === 0;
    }.property('model.asset'),

    /**
     * Indicates if the HEPT address block should be shown.
     * @return {Boolean}
     */
    shouldShowHeptAddress: function () {
      var product = this.get('model.asset').belongsTo('product').id();
      return product.toLowerCase().indexOf('hept') === 0;
    }.property('model.asset'),

    /**
     * Indicates if the HEPT address block should be shown.
     * @return {Boolean}
     */
    shouldShowNortacConfiguration: function () {
      var product = this.get('model.asset').belongsTo('product').id();
      return product.toLowerCase().indexOf('nwave') === 0;
    }.property('model.asset'),

    /**
     * Gets the available asset types based on the category of the asset
     * @return {Array<sky-router-3/models/asset-type}
     */
    assetTypes: function () {
      return this.get('store').query('asset-type', {
        category: this.get('model.asset.category.id')
      }).then(function (s) {
        return s.sortBy('maker');
      });
    }.property('model.asset.category.id'),

    /**
     * Gets a filtered list of alert trigger events by trip start
     * @return {Array<sky-router-3/models/alert-trigger-event}
     */
    startEvents: function () {
      return this.get('model.events').filterBy('canStartTrip');
    }.property('model.events'),

    /**
     * Gets a filtered list of alert trigger events by trip stop
     * @return {Array<sky-router-3/models/alert-trigger-event}
     */
    stopEvents: function () {
      return this.get('model.events').filterBy('canStopTrip');
    }.property('model.events'),

    /**
     * Allows the asset type dropdown to load entries on demand
     * and to debounce the loading of those entries.
     * @return a promise which contains the entries found.
     */
    _performAssetTypesMatching: function _performAssetTypesMatching(term, resolve, reject) {
      return this.get('store').query('asset-type', {
        category: this.get('model.asset.category.id'),
        search: term
      }).then(function (s) {
        return s.sortBy('maker');
      }).then(resolve).catch(reject);
    },

    /**
     * Assignable icons are either global, created and owned by the current
     * user, or shared from another account and currently assigned to the asset
     * being edited.
     **/
    assignableIcons: Ember.computed('model.icons', {
      get: function get() {
        var icons = this.get('model.icons').filterBy('canAssign', true);
        var selected = this.get('model.asset.icon');
        if (!icons.includes(selected)) icons.push(selected);
        return icons;
      }
    }),
    actions: {
      updateDriver: function updateDriver(selected) {
        if (selected.name != 'Unassign') {
          this.set('model.asset.driverName', selected.name);
          this.set('model.asset.driverId', selected.id);
          this.set('model.driverSelected', selected);
        } else {
          this.set('model.asset.driverName', null);
          this.set('model.asset.driverId', null);
          this.set('model.driverSelected', null);
        }
      },
      createOnEnter: function createOnEnter(select, e) {
        if (e.keyCode === 13 && select.isOpen) {
          if (select.searchText && !select.searchText.startsWith(' ')) {
            // Check to see if name entered is already one of the driver Options
            if (!this.driverOptions.find(function (d) {
              return d.name == select.searchText;
            })) {
              this.driverOptions.pushObject({
                name: select.searchText,
                type: "manual entry"
              });
              select.actions.choose({
                name: select.searchText,
                type: "manual entry"
              });
            }
          } else {
            select.actions.choose({
              name: 'Unassign',
              type: "manual entry",
              id: -1,
              hidden: true
            });
          }
        }
      },
      addProperty: function addProperty(key) {
        if (key) {
          var prop = Ember.Object.create({
            key: key.trim(),
            value: null
          });
          this.model.asset.get('properties').pushObject(prop);
          this.set('addPropertyKey', null);
        }
      },
      removeProperty: function removeProperty(prop) {
        this.model.asset.get('properties').removeObject(prop);
      },
      searchTypes: function searchTypes(term) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._performAssetTypesMatching, term, resolve, reject, 200);
        });
      },
      changeCategory: function changeCategory(selected) {
        this.set('model.asset.category', selected);
        this.set('model.asset.type', null);
      },
      saveController: function saveController(asset) {
        var _this3 = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this3.send('save', asset, document.getElementById('assetPhoto'));
          }
        });
      },
      showProviderInfo: function showProviderInfo() {
        this.set('providerInfo', true);
      },
      hideProviderInfo: function hideProviderInfo() {
        this.set('providerInfo', false);
      },
      startEventsSelected: function startEventsSelected(events) {
        this.set('model.asset.tripStartEvents', events);
      },
      stopEventsSelected: function stopEventsSelected(events) {
        this.set('model.asset.tripStopEvents', events);
      },
      updateManualPosition: function updateManualPosition(obj, isLat, milliarc) {
        var degrees = _latLngConverter.default.milliArcSecondsToDecimalDeg(milliarc);

        if (isLat) {
          obj.set('latitudeDegree', degrees);
        } else {
          obj.set('longitudeDegree', degrees);
        }
      }
    }
  });

  _exports.default = _default;
});