define("sky-router-3/pods/components/portal/track/map/overlay-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This component is responsible for showing all of the overlay
   * information to the user about a particular overlay.
   * @type {Number}
   */
  var _default = Ember.Component.extend({
    /**
     * Indicates if the overlay is visible on the map currently
     * @type {Boolean}
     */
    visible: false,

    /**
     * Indicates if the overlay opacity slider is visible
     * @type {Boolean}
     */
    showOpacitySlider: false,

    /**
     * Indicates if the overlay settings window is shown
     * @type {Boolean}
     */
    showSettingsWindow: false,

    /**
     * The passed in overlay that this component is responsible
     * for displaying information for.
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,
    actions: {
      toggleOpacityControls: function toggleOpacityControls() {
        this.toggleProperty('showOpacitySlider');
      },
      toggleSettingsWindow: function toggleSettingsWindow() {
        this.toggleProperty('showSettingsWindow');
      },
      visibleToggle: function visibleToggle(visible) {
        this.onVisibleChange({
          overlayId: this.get('overlay.id'),
          visible: visible
        });
      }
    }
  });

  _exports.default = _default;
});