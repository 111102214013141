define("sky-router-3/pods/components/portal/track/geofence-list/component", ["exports", "sky-router-3/utils/filter-by-in-array"], function (_exports, _filterByInArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['track-window'],

    /**
     * This action fires anytime that an event happens inside this component.
     * Actions are grouped for brevity and defined by a first parameter `action`.
     *
     * @event 'onWindowAction'
     * @param {string} action - the name of the action that was fired.
     *
     *      sort - sort the list
     *      filter - filter the list
     *      search - search the list
     *      delete - delete a geofence
     *      click - when a geofence item is clicked
     *
     * @param {object} options object structured the following way.
     *
     *      sort - { {string}property, {boolean}desc }
     *      filter - {
     *      	 {boolean} showAdminOnly,
     *       	 {boolean} showEnabled,
     *       	 {bolean} showDisabled,
     *       	 {array[string]} filterTypes - filter on geofence type,
     *      }
     *      search - { {string} searchValue }
     *      delete - { {sky-router-3/models/geofence} geofence } - geofence to delete
     *      click - { {sky-router-3/models/geofence} geofence } - geofence that was clicked
     */

    /**
     * Incoming state config that is used to convey configuration information
     * that is stored in local storage.
     * @type {sky-router-3/objects/state-track/state-track-geofence-list}
     */
    config: null,

    /**
     * Incoming array of geofences to be displayed in the UI. These have already
     * been filtered but need to be sorted.
     * @type {sky-router-3/models/geofence}
     */
    geofences: null,

    /**
     * Properties by which geofences are sorted by. The default is name.
     *
     * Any property can have ':asc' or ':desc' after the property name that
     * will cause the sort be based on that direction.
     *
     * See Ember.computed.sort for more info on the format of this array.
     */
    geofencesSortedBy: function () {
      return [this.get('config.sortProperty') + (this.get('config.sortDesc') ? ':desc' : ':asc')];
    }.property('config.sortProperty', 'config.sortDesc'),

    /**
     * Sorts the incoming array of geofences into the order they will be displayed in
     * @private
     */
    geofencesSorted: Ember.computed.sort('geofences', 'geofencesSortedBy'),

    /**
     * Array of assets for use with select dropdown for filtering
     * @type {sky-router-3/models/asset}
     */
    assets: null,

    /**
     * Indicates that the user clicked the search button icon forcing
     * the window open.
     * @type {Boolean}
     */
    _showSearch: false,

    /**
     * Contains a const array of geofence types for use in select dropdown
     * @type {array[object]}
     */
    geofenceTypes: [{
      id: 2,
      name: 'Circle'
    }, {
      id: 3,
      name: 'Polygon'
    }],

    /**
     * Should the search box be shown to the user
     * @type {Boolean}
     */
    showSearch: Ember.computed.or('_showSearch', 'config.searchValue'),

    /**
     * Gets an array of assets that are currently filtered by the config.
     * It is used by the select to mark assets as selected.
     * @return {sky-router-3/models/asset[]}
     */
    filteredAssets: (0, _filterByInArray.default)('assets', 'id', 'config.filterShowAssets'),

    /**
     * Gets an array of geofence types that are currently filtered by the config.
     * It is used by the select to mark types as selected.
     * @return {Array}
     */
    filteredGeofenceTypes: (0, _filterByInArray.default)('geofenceTypes', 'id', 'config.filterShowGeofenceTypes'),

    /**
     * Returns a boolean that indicates if filters are not using their defaults
     * @return {Boolean}
     */
    filtersSet: function () {
      return this.get('config.filterShowAdminOnly') !== false || this.get('config.filterShowEnabled') !== true || this.get('config.filterShowDisabled') !== true || this.get('config.filterShowGeofenceTypes.length') !== 0 || this.get('config.filterShowAssets.length') !== 0;
    }.property('config.filterShowAdminOnly', 'config.filterShowEnabled', 'config.filterShowDisabled', 'config.filterShowGeofenceTypes.[]', 'config.filterShowAssets.[]'),
    actions: {
      toggleSearch: function toggleSearch() {
        this.toggleProperty('_showSearch');
      },
      searchChanged: function searchChanged(searchValue) {
        this.onWindowAction('search', {
          searchValue: searchValue
        });
      },
      searchCleared: function searchCleared() {
        this.set('_showSearch', false);
      },
      filterChanged: function filterChanged() {
        this.onWindowAction('filter', {
          showAdminOnly: this.get('config.filterShowAdminOnly'),
          showEnabled: this.get('config.filterShowEnabled'),
          showDisabled: this.get('config.filterShowDisabled'),
          filterTypes: this.get('config.filterShowGeofenceTypes'),
          filterAssets: this.get('config.filterShowAssets')
        });
      },
      resetFilters: function resetFilters() {
        this.onWindowAction('filter', {
          showAdminOnly: false,
          showEnabled: true,
          showDisabled: true,
          filterTypes: [],
          filterAssets: []
        });
      },
      sortChanged: function sortChanged() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: this.get('config.sortDesc')
        });
      },
      orderChanged: function orderChanged() {
        this.onWindowAction('sort', {
          property: this.get('config.sortProperty'),
          desc: !this.get('config.sortDesc')
        });
      },
      deleteGeofence: function deleteGeofence(geofence) {
        this.onWindowAction('delete', {
          geofence: geofence
        });
      },
      geofenceClicked: function geofenceClicked(geofence) {
        this.onWindowAction('click', {
          geofence: geofence
        });
      },
      assetFilterChanged: function assetFilterChanged(selections) {
        this.set('config.filterShowAssets', selections.mapBy('id'));
        this.send('filterChanged');
      },
      gofenceTypesFilterChanged: function gofenceTypesFilterChanged(selections) {
        this.set('config.filterShowGeofenceTypes', selections.mapBy('id'));
        this.send('filterChanged');
      }
    }
  });

  _exports.default = _default;
});