define("sky-router-3/pods/portal/operations/flightplans/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model(params) {
      return Ember.RSVP.hash({
        plan: params.plan_id === 'new' ? this.store.createRecord('flight-plan') : this.store.findRecord('flight-plan', params.plan_id),
        assets: this.store.findAll('asset'),
        locations: this.get('api').getFlightPlanLocations()
      });
    },
    afterModel: function afterModel(model) {
      model.planAssetId = model.plan.belongsTo('asset').id();
    },
    actions: {
      cancel: function cancel() {
        this.modelFor(this.routeName).plan.rollbackAttributes();
        this.transitionTo('portal.operations.flightplans');
      },
      save: function save() {
        var _this = this;

        this.modelFor(this.routeName).plan.save().then(function () {
          _this.transitionTo('portal.operations.flightplans');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      }
    }
  });

  _exports.default = _default;
});