define("sky-router-3/utils/geoconversiontools/mgrs-conversions", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants", "sky-router-3/utils/geoconversiontools/ups-conversions", "sky-router-3/utils/geoconversiontools/utm-conversions"], function (_exports, _geoconstants, _upsConversions, _utmConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mgrsconversions = {
    getMGRSDefaultValues: function getMGRSDefaultValues() {
      return {
        MGRS_a: 6378137.0,
        MGRS_f: 1 / 298.257223563,
        MGRS_Ellipsoid_Code: ['C', 'C']
      };
    },
    getLatitudeBandTable: function getLatitudeBandTable() {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      return [{
        letter: mgrsconstants.LETTER_C,
        min_northing: 1100000.0,
        north: -72.0,
        south: -80.5,
        northing_offset: 0.0
      }, {
        letter: mgrsconstants.LETTER_D,
        min_northing: 2000000.0,
        north: -64.0,
        south: -72.0,
        northing_offset: 2000000.0
      }, {
        letter: mgrsconstants.LETTER_E,
        min_northing: 2800000.0,
        north: -56.0,
        south: -64.0,
        northing_offset: 2000000.0
      }, {
        letter: mgrsconstants.LETTER_F,
        min_northing: 3700000.0,
        north: -48.0,
        south: -56.0,
        northing_offset: 2000000.0
      }, {
        letter: mgrsconstants.LETTER_G,
        min_northing: 4600000.0,
        north: -40.0,
        south: -48.0,
        northing_offset: 4000000.0
      }, {
        letter: mgrsconstants.LETTER_H,
        min_northing: 5500000.0,
        north: -32.0,
        south: -40.0,
        northing_offset: 4000000.0
      }, {
        letter: mgrsconstants.LETTER_J,
        min_northing: 6400000.0,
        north: -24.0,
        south: -32.0,
        northing_offset: 6000000.0
      }, {
        letter: mgrsconstants.LETTER_K,
        min_northing: 7300000.0,
        north: -16.0,
        south: -24.0,
        northing_offset: 6000000.0
      }, {
        letter: mgrsconstants.LETTER_L,
        min_northing: 8200000.0,
        north: -8.0,
        south: -16.0,
        northing_offset: 8000000.0
      }, {
        letter: mgrsconstants.LETTER_M,
        min_northing: 9100000.0,
        north: 0.0,
        south: -8.0,
        northing_offset: 8000000.0
      }, {
        letter: mgrsconstants.LETTER_N,
        min_northing: 0.0,
        north: 8.0,
        south: 0.0,
        northing_offset: 0.0
      }, {
        letter: mgrsconstants.LETTER_P,
        min_northing: 800000.0,
        north: 16.0,
        south: 8.0,
        northing_offset: 0.0
      }, {
        letter: mgrsconstants.LETTER_Q,
        min_northing: 1700000.0,
        north: 24.0,
        south: 16.0,
        northing_offset: 0.0
      }, {
        letter: mgrsconstants.LETTER_R,
        min_northing: 2600000.0,
        north: 32.0,
        south: 24.0,
        northing_offset: 2000000.0
      }, {
        letter: mgrsconstants.LETTER_S,
        min_northing: 3500000.0,
        north: 40.0,
        south: 32.0,
        northing_offset: 2000000.0
      }, {
        letter: mgrsconstants.LETTER_T,
        min_northing: 4400000.0,
        north: 48.0,
        south: 40.0,
        northing_offset: 4000000.0
      }, {
        letter: mgrsconstants.LETTER_U,
        min_northing: 5300000.0,
        north: 56.0,
        south: 48.0,
        northing_offset: 4000000.0
      }, {
        letter: mgrsconstants.LETTER_V,
        min_northing: 6200000.0,
        north: 64.0,
        south: 56.0,
        northing_offset: 6000000.0
      }, {
        letter: mgrsconstants.LETTER_W,
        min_northing: 7000000.0,
        north: 72.0,
        south: 64.0,
        northing_offset: 6000000.0
      }, {
        letter: mgrsconstants.LETTER_X,
        min_northing: 7900000.0,
        north: 84.5,
        south: 72.0,
        northing_offset: 6000000.0
      }];
    },
    getUPSConstantTable: function getUPSConstantTable() {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      return [{
        letter: mgrsconstants.LETTER_A,
        ltr2_low_value: mgrsconstants.LETTER_J,
        ltr2_high_value: mgrsconstants.LETTER_Z,
        ltr3_high_value: mgrsconstants.LETTER_Z,
        false_easting: 800000.0,
        false_northing: 800000.0
      }, {
        letter: mgrsconstants.LETTER_B,
        ltr2_low_value: mgrsconstants.LETTER_A,
        ltr2_high_value: mgrsconstants.LETTER_R,
        ltr3_high_value: mgrsconstants.LETTER_Z,
        false_easting: 2000000.0,
        false_northing: 800000.0
      }, {
        letter: mgrsconstants.LETTER_Y,
        ltr2_low_value: mgrsconstants.LETTER_J,
        ltr2_high_value: mgrsconstants.LETTER_Z,
        ltr3_high_value: mgrsconstants.LETTER_P,
        false_easting: 800000.0,
        false_northing: 1300000.0
      }, {
        letter: mgrsconstants.LETTER_Z,
        ltr2_low_value: mgrsconstants.LETTER_A,
        ltr2_high_value: mgrsconstants.LETTER_J,
        ltr3_high_value: mgrsconstants.LETTER_P,
        false_easting: 2000000.0,
        false_northing: 1300000.0
      }];
    },
    getGridValues: function getGridValues(zone) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var ltr2_low_value = 0;
      var ltr2_high_value = 0;
      var pattern_offset = 0e0;
      var set_number;
      set_number = zone % 6;

      if (set_number == 0) {
        set_number = 6;
      }

      if (set_number == 1 || set_number == 4) {
        ltr2_low_value = mgrsconstants.LETTER_A;
        ltr2_high_value = mgrsconstants.LETTER_H;
      } else if (set_number == 2 || set_number == 5) {
        ltr2_low_value = mgrsconstants.LETTER_J;
        ltr2_high_value = mgrsconstants.LETTER_R;
      } else if (set_number == 3 || set_number == 6) {
        ltr2_low_value = mgrsconstants.LETTER_S;
        ltr2_high_value = mgrsconstants.LETTER_Z;
      }
      /* False northing at A for second letter of grid square */


      if (set_number % 2 == 0) {
        pattern_offset = 500000.0;
      } else {
        pattern_offset = 0.0;
      }

      return {
        ltr2LowValue: ltr2_low_value,
        ltr2HighValue: ltr2_high_value,
        patternOffset: pattern_offset
      };
    },
    getLatitudeLetter: function getLatitudeLetter(latitude) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var latbandtable = this.getLatitudeBandTable();
      var latletter = -1;
      var errors = [];
      var temp = 0.0;
      var lat_deg = latitude * mgrsconstants.RAD_TO_DEG;

      if (lat_deg >= 72 && lat_deg < 84.5) {
        latletter = mgrsconstants.LETTER_X;
      } else if (lat_deg > -80.5 && lat_deg < 72) {
        temp = (latitude + 80.0 * mgrsconstants.DEG_TO_RAD) / (8.0 * mgrsconstants.DEG_TO_RAD) + 1.0e-12;

        if (temp < 0.1) {
          temp = 0;
        }

        latletter = latbandtable[parseInt(temp)].letter;
      } else {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }

      return {
        letter: latletter,
        errorCodes: errors
      };
    },
    getLatitudeBandMinNorthing: function getLatitudeBandMinNorthing(letter) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var latitudebandtable = this.getLatitudeBandTable();
      var result = {
        minnorthing: 0e0,
        northingoffset: 0e0
      };
      var errors = [];

      if (letter >= mgrsconstants.LETTER_C && letter <= mgrsconstants.LETTER_H) {
        result.minnorthing = latitudebandtable[letter - 2].min_northing;
        result.northingoffset = latitudebandtable[letter - 2].northing_offset;
      } else if (letter >= mgrsconstants.LETTER_J && letter <= mgrsconstants.LETTER_N) {
        result.minnorthing = latitudebandtable[letter - 3].min_northing;
        result.northingoffset = latitudebandtable[letter - 3].northing_offset;
      } else if (letter >= mgrsconstants.LETTER_P && letter <= mgrsconstants.LETTER_X) {
        result.minnorthing = latitudebandtable[letter - 4].min_northing;
        result.northingoffset = latitudebandtable[letter - 4].northing_offset;
      } else {
        errors.push({
          code: 0x0004,
          desc: 'An MGRS string error: string too long, too short, or badly formed'
        });
      }

      return {
        output: result,
        errorCodes: errors
      };
    },
    makeMGRSString: function makeMGRSString(zone, letters, easting, northing, precision) {
      var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
      var mgrsstring = "";
      var divisor = Math.pow(10.0, 5 - precision);

      if (zone > 0) {
        mgrsstring += zone;
      } else {
        mgrsstring += "  ";
      }

      for (var i = 0; i < letters.length; i++) {
        var letter = alphabet[parseInt(letters[i])];

        if (letter === 'I' && i === 1) {
          letter = 'J';
        } else if (letter === 'I' && i === 2) {
          letter = 'H';
        }

        if (letter === 'O' && i === 1) {
          letter = 'P';
        } else if (letter === 'O' && i === 2) {
          letter = 'N';
        }

        mgrsstring += letter;

        if (i == 0) {
          mgrsstring += "  ";
        }
      }

      easting = easting % 100000.0;
      if (easting >= 99999.5) easting = 99999.0;
      easting = (easting / divisor).toString().substr(0, precision);
      easting = easting.toString().padStart(precision, "0");
      mgrsstring += "  " + easting;
      northing = northing % 100000.0;
      if (northing >= 99999.5) northing = 99999.0;
      northing = (northing / divisor).toString().substr(0, precision);
      northing = northing.toString().padStart(precision, "0");
      mgrsstring += "  " + northing;
      return mgrsstring;
    },
    breakMGRSString: function breakMGRSString(mgrsstring) {
      var result = {
        zone: 0,
        letters: [],
        easting: 0e0,
        northing: 0e0,
        precision: 0
      };
      var errors = [];
      var mgrsparts = mgrsstring.split(',');

      if (mgrsparts.length == 4) {
        var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
        result.zone = parseInt(mgrsparts[0].replace(/[^0-9]+/g, ''));
        var band = mgrsparts[0].replace(/[^A-Z]+/g, '');
        var eastletter = mgrsparts[1][0];
        var northletter = mgrsparts[1][1];

        if (band === 'I' || band === 'O') {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        } else {
          result.letters.push(alphabet.indexOf(band));
        }

        if (eastletter === 'I' || eastletter === 'O') {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        } else {
          result.letters.push(alphabet.indexOf(eastletter));
        }

        if (northletter === 'I' || northletter === 'O') {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        } else {
          result.letters.push(alphabet.indexOf(northletter));
        }

        if (mgrsparts[2].length + mgrsparts[3].length <= 10) {
          result.precision = parseInt((mgrsparts[2].length + mgrsparts[3].length) / 2);

          if (result.precision > 0) {
            var easting = parseInt(mgrsparts[2]);
            var northing = parseInt(mgrsparts[3]);
            var multiplier = Math.pow(10.0, 5 - result.precision);
            result.easting = easting * multiplier;
            result.northing = northing * multiplier;
          }
        } else {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        }
      } else {
        errors.push({
          code: 0x0004,
          desc: 'An MGRS string error: string too long, too short, or badly formed'
        });
      }

      return {
        output: result,
        errorCodes: errors
      };
    },
    setMGRSParameters: function setMGRSParameters(a, f, ellipsoidcode) {
      var mgrsparams = this.getMGRSDefaultValues();
      var errors = [];

      if (a <= 0.0) {
        errors.push({
          code: 0x0010,
          desc: 'Semi-major axis less than or equal to zero'
        });
      }

      if (1 / f < 250 || 1 / f << 350) {
        errors.push({
          code: 0x0020,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (errors.length == 0) {
        mgrsparams.MGRS_a = a;
        mgrsparams.MGRS_f = f;
        mgrsparams.MGRS_Ellipsoid_Code = ellipsoidcode;
      }

      return {
        parameters: mgrsparams,
        errorCodes: errors
      };
    },
    convertGeodeticToMGRS: function convertGeodeticToMGRS(latitude, longitude, precision, mgrsparams) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var mgrsresult = "";
      var errors = [];
      /* Latitude out of range */

      if (latitude < -mgrsconstants.PI_OVER_2 || latitude > mgrsconstants.PI_OVER_2) {
        errors.push({
          code: 0x0001,
          desc: 'Latitude outside of valid range (-90 to 90 degrees)'
        });
      }
      /* Longitude out of range */


      if (longitude < -mgrsconstants.PI || longitude > 2 * mgrsconstants.PI) {
        errors.push({
          code: 0x0002,
          desc: 'Longitude outside of valid range (-180 to 360 degrees)'
        });
      }

      if (precision < 0 || precision > mgrsconstants.MAX_PRECISION) {
        errors.push({
          code: 0x0008,
          desc: 'The precision must be between 0 and 5 inclusive'
        });
      }

      if (errors.length == 0) {
        if (latitude < mgrsconstants.MIN_UTM_LAT || latitude > mgrsconstants.MAX_UTM_LAT) {
          var upsparamsresult = _upsConversions.default.setUPSParameters(mgrsparams.MGRS_a, mgrsparams.MGRS_f);

          if (upsparamsresult.errorCodes.length == 0) {
            var upsresult = _upsConversions.default.convertGeodeticToUPS(latitude, longitude, upsparamsresult.parameters);

            if (upsresult.errorCodes.length == 0) {
              var mgrsupsresult = this.convertUPSToMGRS(upsresult.output, precision);

              if (mgrsupsresult.errorCodes.length == 0) {
                mgrsresult = mgrsupsresult.output;
              } else {
                errors.push(mgrsupsresult.errorCodes);
              }
            } else {
              errors.push(upsresult.errorCodes);
            }
          } else {
            errors.push(upsparamsresult.errorCodes);
          }
        } else {
          var utmparamsresult = _utmConversions.default.setUTMParameters(mgrsparams.MGRS_a, mgrsparams.MGRS_f, 0);

          if (utmparamsresult.errorCodes.length == 0) {
            var utmresult = _utmConversions.default.convertGeodeticToUTM(latitude, longitude, utmparamsresult.parameters);

            if (utmresult.errorCodes.length == 0) {
              var mgrsutmresult = this.convertUTMToMGRS(utmresult.output, precision, latitude, longitude, mgrsparams);

              if (mgrsutmresult.errorCodes.length == 0) {
                mgrsresult = mgrsutmresult.output;
              } else {
                errors.push(mgrsutmresult.errorCodes);
              }
            } else {
              errors.push(utmresult.errorCodes);
            }
          } else {
            errors.push(utmparamsresult.errorCodes);
          }
        }
      }

      return {
        output: mgrsresult,
        parameters: mgrsparams,
        errorCodes: errors
      };
    },
    convertUPSToMGRS: function convertUPSToMGRS(upsdata, precision) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var upsconstanttable = this.getUPSConstantTable();
      var mgrsoutput = "";
      var errors = [];
      var easting = upsdata.easting;
      var northing = upsdata.northing;

      if (upsdata.hemisphere !== 'N' || upsdata.hemisphere !== 'S') {
        errors.push({
          code: 0x0008,
          desc: 'The precision must be between 0 and 5 inclusive'
        });
      }

      if (easting < mgrsconstants.MIN_EAST_NORTH || easting > mgrsconstants.MAX_EAST_NORTH) {
        errors.push({
          code: 0x0008,
          desc: 'The precision must be between 0 and 5 inclusive'
        });
      }

      if (northing < mgrsconstants.MIN_EAST_NORTH || northing > mgrsconstants.MAX_EAST_NORTH) {
        errors.push({
          code: 0x0008,
          desc: 'The precision must be between 0 and 5 inclusive'
        });
      }

      if (precision < 0 || precision > mgrsconstants.MAX_PRECISION) {
        errors.push({
          code: 0x0008,
          desc: 'The precision must be between 0 and 5 inclusive'
        });
      }

      if (errors.length == 0) {
        var ltr2LowValue, false_easting, false_northing, grid_easting, grid_northing;
        var index = 0;
        var letters = [];
        var divisor = Math.pow(10.0, 5 - precision);
        easting = Math.round(easting / divisor) * divisor;
        northing = Math.round(easting / divisor) * divisor;

        if (upsdata.hemisphere === 'N') {
          if (easting >= mgrsconstants.TWOMIL) {
            letters.push(mgrsconstants.LETTER_Z);
          } else {
            letters.push(mgrsconstants.LETTER_Y);
          }

          index = letters[0] - 22;
          ltr2LowValue = upsconstanttable[index].ltr2_low_value;
          false_easting = upsconstanttable[index].false_easting;
          false_northing = upsconstanttable[index].false_northing;
        } else {
          if (easting >= mgrsconstants.TWOMIL) {
            letters.push(mgrsconstants.LETTER_B);
          } else {
            letters.push(mgrsconstants.LETTER_A);
          }

          ltr2LowValue = upsconstanttable[letters[0]].ltr2_low_value;
          false_easting = upsconstanttable[letters[0]].false_easting;
          false_northing = upsconstanttable[letters[0]].false_northing;
        }

        grid_easting = easting;
        grid_easting = grid_easting - false_easting;
        letters.push(ltr2LowValue + grid_easting / mgrsconstants.ONEHT);

        if (easting < mgrsconstants.TWOMIL) {
          if (letters[1] > mgrsconstants.LETTER_L) {
            letters[1] = letters[1] + 3;
          }

          if (letters[1] > mgrsconstants.LETTER_U) {
            letters[1] = letters[1] + 2;
          }
        } else {
          if (letters[1] > mgrsconstants.LETTER_C) {
            letters[1] = letters[1] + 2;
          }

          if (letters[1] > mgrsconstants.LETTER_H) {
            letters[1] = letters[1] + 1;
          }

          if (letters[1] > mgrsconstants.LETTER_L) {
            letters[1] = letters[1] + 3;
          }
        }

        grid_northing = northing;
        grid_northing = grid_northing - false_northing;
        letters.push(grid_northing / mgrsconstants.ONEHT);

        if (letters[2] > mgrsconstants.LETTER_H) {
          letters[2] = letters[2] + 1;
        }

        if (letters[2] > mgrsconstants.LETTER_N) {
          letters[2] = letters[2] + 1;
        }

        mgrsoutput = this.makeMGRSString(0, letters, easting, northing, precision);
      }

      return {
        output: mgrsoutput,
        errorCodes: errors
      };
    },
    convertUTMToMGRS: function convertUTMToMGRS(utmdata, precision, latitude, longitude, mgrsparams) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var mgrsoutput = "";
      var errors = [];
      var easting = utmdata.easting;
      var northing = utmdata.northing;
      var letters = [];
      var divisor = Math.pow(10.0, 5 - precision);
      var rounded_easting = Math.round(easting / divisor) * divisor;
      var zone = utmdata.zone;
      /* Special check for rounding to (truncated) eastern edge of zone 31V */

      if (zone == 31 && latitude >= 56.0 * mgrsconstants.DEG_TO_RAD && latitude < 64.0 * mgrsconstants.DEG_TO_RAD && (longitude >= 3.0 * mgrsconstants.DEG_TO_RAD || rounded_easting >= 500000.0)) {
        var utmparamsresult = _utmConversions.default.setUTMParameters(mgrsparams.MGRS_a, mgrsparams.MGRS_f, 32);

        if (utmparamsresult.errorCodes.length == 0) {
          var utmresult = _utmConversions.default.convertGeodeticToUTM(latitude, longitude, utmparamsresult.parameters);

          if (utmresult.errorCodes.length == 0) {
            easting = Math.round(utmresult.output.easting / divisor) * divisor;
          } else {
            errors.push(utmresult.errorCodes);
          }
        } else {
          errors.push(utmparamsresult.errorCodes);
        }
      } else {
        easting = rounded_easting;
      }

      if (errors.length == 0) {
        northing = Math.round(northing / divisor) * divisor;

        if (latitude <= 0.0 && northing == 1.0e7) {
          latitude = 0.0;
          northing = 0.0;
        }

        var gridvalues = this.getGridValues(zone);
        var latletter = this.getLatitudeLetter(latitude);

        if (latletter.errorCodes.length == 0) {
          letters.push(latletter.letter);
          var grid_easting = easting;
          if (letters[0] == mgrsconstants.LETTER_V && zone == 31 && grid_easting == 500000.0) grid_easting = grid_easting - 1.0;
          /* SUBTRACT 1 METER */

          letters.push(gridvalues.ltr2LowValue + (grid_easting / mgrsconstants.ONEHT - 1));

          if (gridvalues.ltr2LowValue == mgrsconstants.LETTER_J && letters[1] > mgrsconstants.LETTER_N) {//letters[1] = letters[1] + 1;
          }

          var grid_northing = northing;

          while (grid_northing >= mgrsconstants.TWOMIL) {
            grid_northing = grid_northing - mgrsconstants.TWOMIL;
          }

          grid_northing = grid_northing + gridvalues.patternOffset;

          if (grid_northing >= mgrsconstants.TWOMIL) {
            grid_northing = grid_northing - mgrsconstants.TWOMIL;
          }

          letters.push(grid_northing / mgrsconstants.ONEHT);

          if (letters[2] > mgrsconstants.LETTER_H) {
            letters[2] = letters[2] + 1;
          }

          if (letters[2] > mgrsconstants.LETTER_N) {
            letters[2] = letters[2] + 1;
          }

          mgrsoutput = this.makeMGRSString(zone, letters, grid_easting, northing, precision);
        } else {
          errors.push(latletter.errorCodes);
        }
      }

      return {
        output: mgrsoutput,
        errorCodes: errors
      };
    },
    convertMGRSToGeodetic: function convertMGRSToGeodetic(mgrsstring, mgrsparams) {
      mgrsstring = mgrsstring.replace(/\s\s+/g, ',');
      var geodeticresult = {
        latitude: 0e0,
        longitude: 0e0
      };
      var errors = [];
      var zone_exists = true; // valid mgrs has two spaces between each grid designation and easting/northing
      // IE: 11S  MS  40000  40000

      if (mgrsstring.split(',').length !== 4) {
        zone_exists = false;
      }

      if (zone_exists) {
        var mgrsutmresult = this.convertMGRSToUTM(mgrsstring);

        if (mgrsutmresult.errorCodes.length == 0) {
          var utmparamsresult = _utmConversions.default.setUTMParameters(mgrsparams.MGRS_a, mgrsparams.MGRS_f, 0);

          if (utmparamsresult.errorCodes.length == 0) {
            var utmresult = _utmConversions.default.convertUTMToGeodetic(mgrsutmresult.output.zone, mgrsutmresult.output.hemisphere, mgrsutmresult.output.easting, mgrsutmresult.output.northing, utmparamsresult.parameters);

            if (utmresult.errorCodes.length == 0) {
              geodeticresult.latitude = utmresult.output.latitude;
              geodeticresult.longitude = utmresult.output.longitude;
            } else {
              errors.push(utmresult.errorCodes);
            }
          } else {
            errors.push(utmparamsresult.errorCodes);
          }
        } else {
          errors.push(mgrsutmresult.errorCodes);
        }
      } else {
        var mgrsupsresult = this.convertMGRSToUPS(mgrsstring);

        if (mgrsupsresult.errorCodes.length == 0) {
          var upsparamsresult = _upsConversions.default.setUPSParameters(mgrsparams.MGRS_a, mgrsparams.MGRS_f);

          if (upsparamsresult.errorCodes.length == 0) {
            var upsresult = _upsConversions.default.convertUPSToGeodetic(mgrsupsresult.output.hemisphere, mgrsupsresult.output.easting, mgrsupsresult.output.northing, upsparamsresult.parameters);

            if (upsresult.errorCodes.length == 0) {
              geodeticresult.latitude = upsresult.output.latitude;
              geodeticresult.longitude = upsresult.output.longitude;
            } else {
              errors.push(upsresult.errorCodes);
            }
          } else {
            errors.push(upsparamsresult.errorCodes);
          }
        } else {
          errors.push(mgrsupsresult.errorCodes);
        }
      }

      return {
        output: geodeticresult,
        parameters: mgrsparams,
        errorCodes: errors
      };
    },
    convertMGRSToUPS: function convertMGRSToUPS(mgrsstring) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var upsconstants = this.getUPSConstantTable();
      var upsresult = {
        hemisphere: 'X',
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];
      var mgrsparsingresult = this.breakMGRSString(mgrsstring);

      if (mgrsparsingresult.errorCodes.length == 0) {
        if (mgrsparsingresult.output.zone > 0) {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        } else {
          var index = mgrsparsingresult.output.letters[0];

          if (mgrsparsingresult.output.letters[0] >= mgrsconstants.LETTER_Y) {
            upsresult.hemisphere = 'N';
            index -= 22;
          } else {
            upsresult.hemisphere = 'S';
          }

          var ltr2_low_value = upsconstants[index].ltr2_low_value;
          var ltr2_high_value = upsconstants[index].ltr2_high_value;
          var ltr3_high_value = upsconstants[index].ltr3_high_value;
          var false_easting = upsconstants[index].false_easting;
          var false_northing = upsconstants[index].false_northing;
          /* Check that the second letter of the MGRS string is within
           * the range of valid second letter values
           * Also check that the third letter is valid */

          if (mgrsparsingresult.output.letters[1] < ltr2_low_value || mgrsparsingresult.output.letters[1] > ltr2_high_value || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_D || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_E || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_M || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_N || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_V || mgrsparsingresult.output.letters[1] == mgrsconstants.LETTER_W || mgrsparsingresult.output.letters[2] > ltr3_high_value) {
            errors.push({
              code: 0x0004,
              desc: 'An MGRS string error: string too long, too short, or badly formed'
            });
          } else {
            var grid_northing = mgrsparsingresult.output.letters[2] * mgrsconstants.ONEHT + false_northing;
            if (mgrsparsingresult.output.letters[2] > mgrsconstants.LETTER_I) grid_northing = grid_northing - mgrsconstants.ONEHT;
            if (mgrsparsingresult.output.letters[2] > mgrsconstants.LETTER_O) grid_northing = grid_northing - mgrsconstants.ONEHT;
            var grid_easting = (mgrsparsingresult.output.letters[1] - ltr2_low_value) * mgrsconstants.ONEHT + false_easting;

            if (ltr2_low_value != mgrsconstants.LETTER_A) {
              if (mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_L) grid_easting = grid_easting - 300000.0;
              if (mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_U) grid_easting = grid_easting - 200000.0;
            } else {
              if (mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_C) grid_easting = grid_easting - 200000.0;
              if (mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_I) grid_easting = grid_easting - mgrsconstants.ONEHT;
              if (mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_L) grid_easting = grid_easting - 300000.0;
            }

            upsresult.easting = grid_easting + mgrsparsingresult.output.easting;
            upsresult.northing = grid_northing + mgrsparsingresult.output.northing;
          }
        }
      } else {
        errors.push(mgrsparsingresult.errorCodes);
      }

      return {
        output: upsresult,
        errorCodes: errors
      };
    },
    convertMGRSToUTM: function convertMGRSToUTM(mgrsstring) {
      var mgrsconstants = _geoconstants.default.getMGRSConstants();

      var utmresult = {
        zone: 0,
        hemisphere: 'N',
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];
      var mgrsparsingresult = this.breakMGRSString(mgrsstring);

      if (mgrsparsingresult.errorCodes.length == 0) {
        if (mgrsparsingresult.output.zone != 0) {
          if (mgrsparsingresult.output.letters[0] == mgrsconstants.LETTER_X && (mgrsparsingresult.output.zone == 32 || mgrsparsingresult.output.zone == 34 || mgrsparsingresult.output.zone == 36)) {
            errors.push({
              code: 0x0004,
              desc: 'An MGRS string error: string too long, too short, or badly formed'
            });
          } else {
            utmresult.zone = mgrsparsingresult.output.zone;

            if (mgrsparsingresult.output.letters[0] < mgrsconstants.LETTER_N) {
              utmresult.hemisphere = 'S';
            }

            var gridvalues = this.getGridValues(utmresult.zone);

            if (mgrsparsingresult.output.letters[1] < gridvalues.ltr2LowValue || mgrsparsingresult.output.letters[1] > gridvalues.ltr2HighValue || mgrsparsingresult.output.letters[2] > mgrsconstants.LETTER_V) {
              errors.push({
                code: 0x0004,
                desc: 'An MGRS string error: string too long, too short, or badly formed'
              });
            } else {
              var row_letter_northing = mgrsparsingresult.output.letters[2] * mgrsconstants.ONEHT;
              var grid_easting = (mgrsparsingresult.output.letters[1] - gridvalues.ltr2LowValue + 1) * mgrsconstants.ONEHT;
              if (gridvalues.ltr2LowValue == mgrsconstants.LETTER_J && mgrsparsingresult.output.letters[1] > mgrsconstants.LETTER_O) grid_easting = grid_easting - mgrsconstants.ONEHT;
              if (mgrsparsingresult.output.letters[2] > mgrsconstants.LETTER_O) row_letter_northing = row_letter_northing - mgrsconstants.ONEHT;
              if (mgrsparsingresult.output.letters[2] > mgrsconstants.LETTER_I) row_letter_northing = row_letter_northing - mgrsconstants.ONEHT;
              if (row_letter_northing >= mgrsconstants.TWOMIL) row_letter_northing = row_letter_northing - mgrsconstants.TWOMIL;
              var latitudeBandMinNorthing = this.getLatitudeBandMinNorthing(mgrsparsingresult.output.letters[0]);

              if (latitudeBandMinNorthing.errorCodes.length == 0) {
                var grid_northing = row_letter_northing - gridvalues.patternOffset;
                if (grid_northing < 0) grid_northing += mgrsconstants.TWOMIL;
                grid_northing += latitudeBandMinNorthing.output.northingoffset;
                if (grid_northing < latitudeBandMinNorthing.output.minnorthing) grid_northing += mgrsconstants.TWOMIL;
                utmresult.easting = grid_easting + mgrsparsingresult.output.easting;
                utmresult.northing = grid_northing + mgrsparsingresult.output.northing;
              } else {
                errors.push(latitudeBandMinNorthing.errorCodes);
              }
            }
          }
        } else {
          errors.push({
            code: 0x0004,
            desc: 'An MGRS string error: string too long, too short, or badly formed'
          });
        }
      } else {
        errors.push(mgrsparsingresult.errorCodes);
      }

      return {
        output: utmresult,
        errorCodes: errors
      };
    }
  };
  var _default = mgrsconversions;
  _exports.default = _default;
});