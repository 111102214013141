define("sky-router-3/mixins/action-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This mixin defines custom action helpers
   */
  var _default = Ember.Mixin.create({
    actions: {
      /**
      * Toggles a property from true to false
      */
      actionToggleProperty: function actionToggleProperty(property) {
        console.assert(typeof property === 'string', 'Property must be a quoted property name');
        this.toggleProperty(property);
      },

      /**
       * Sets a property value
       */
      actionSetProperty: function actionSetProperty(property, value) {
        console.assert(typeof property === 'string', 'Property must be a quoted property name');
        this.set(property, value);
      }
    }
  });

  _exports.default = _default;
});