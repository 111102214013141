define("sky-router-3/pods/components/qr-code/component", ["exports", "qrcode"], function (_exports, _qrcode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: ['canvas'],

    /**
     * Options based on https://www.npmjs.com/package/qrcode#qr-code-options
     */
    width: 128,
    heigh: 128,
    errorCorrectionLevel: 'H',
    text: '',
    didInsertElement: function didInsertElement() {
      this.createQrCode();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this.createQrCode();
    },
    createQrCode: function createQrCode() {
      _qrcode.default.toCanvas(this.element, this.text, this);
    }
  });

  _exports.default = _default;
});