define("sky-router-3/pods/portal/track/message/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    model: function model(params) {
      return this.store.peekAll('asset').filter(function (y) {
        return params.assets.indexOf(y.get('id')) !== -1;
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('subject', null);
        controller.set('message', null);
      }
    },
    actions: {
      onModalClose: function onModalClose() {
        this.transitionTo('portal.track');
      },
      sendMessage: function sendMessage(subject, body) {
        var _this = this;

        var msg = {
          Subject: subject,
          Body: body,
          To: this.modelFor(this.routeName).mapBy('email')
        };
        this.send('blockApp', true);
        this.get('api').mailMessageSend(msg).then(function () {
          _this.transitionTo('portal.track');

          _this.notifications.success(_this.intl.t('misc.successfulOperation'));
        }).catch(function () {
          _this.notifications.error(_this.intl.t('communicate.sentMessageFailed'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});