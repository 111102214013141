define("sky-router-3/pods/components/input-phone/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    isValid: (0, _emberCpValidations.validator)('inclusion', {
      in: [true],
      messageKey: 'errors.phone'
    })
  });
  /**
   * Component that utilizes the intl-tel-input jquery plugin to create
   * a nice phone number input field.
   * @see https://github.com/jackocnr/intl-tel-input
   */

  var _default = Ember.Component.extend(VALIDATIONS, {
    countrychange: function countrychange() {},
    phonechange: function phonechange() {},

    /**
     * Identifier used for nested element
     */
    input: Ember.computed('elementId', function () {
      return document.getElementById(this.inputId);
    }),
    inputId: Ember.computed('elementId', function () {
      return "input-phone-".concat(this.elementId);
    }),

    /**
     * The intlTelInput Instance
     */
    iti: null,

    /**
     * Indicates if the number is a valid number or not
     * @type {Boolean}
     */
    isValid: null,

    /**
     * Indicates if validations are shown or not
     * @type {Boolean}
     */
    validate: true,
    phone: Ember.computed({
      get: function get() {
        return this.iti.getNumber();
      },
      set: function set(key, value) {
        var _this = this;

        if (value) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            if (_this.iti.getNumber() != value) {
              _this.iti.setNumber(value);
            }
          });
        }

        return value;
      }
    }),
    country: Ember.computed({
      get: function get() {
        return this.iti.getSelectedCountryData().iso2;
      },
      set: function set(key, value) {
        var _this2 = this;

        if (value) {
          Ember.run.scheduleOnce('afterRender', this, function () {
            _this2.iti.setCountry(value);
          });
        }

        return value;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.iti = window.intlTelInput(this.input, {
        separateDialCode: true,
        utilsScript: '/skyrouter3/assets/scripts/intlTelInputUtils.js'
      });
      this.input.addEventListener('countrychange', function (e, countryData) {
        _this3.countrychange(countryData);

        _this3.setValid();
      });

      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this.iti.destroy();
      this.iti = null;

      this._super.apply(this, arguments);
    },
    setPhone: function setPhone() {
      if (this.iti) {
        var phone = this.iti.getNumber();
        var valid = this.iti.isValidNumber();
        var country = this.iti.getSelectedCountryData();
        this.notifyPropertyChange('phone');
        this.notifyPropertyChange('country');
        this.setValid();
        this.phonechange(phone, country, valid);
      }
    },
    setValid: function setValid() {
      if (this.iti) {
        this.set('isValid', this.iti.isValidNumber());
      }
    },
    actions: {
      inputUp: function inputUp() {
        Ember.run.debounce(this, this.setPhone, 200);
      }
    }
  });

  _exports.default = _default;
});