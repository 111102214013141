define("sky-router-3/pods/portal/settings/security/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),

    /**
     * Supports the setting to clear the TouchID saved password
     */
    touchId: Ember.inject.service(),
    actions: {
      clearTouchId: function clearTouchId() {
        var _this = this;

        this.get('touchId').clear().then(function () {
          _this.notifications.success(_this.intl.t('touchid.clearCredentials'));
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      }
    }
  });

  _exports.default = _default;
});