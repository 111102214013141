define("sky-router-3/pods/components/input-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1klvxsCt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"input-search \",[34,3],\" \",[30,[36,2],[[35,1],\"search-populated\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"type\",\"val\",\"wait\",\"sync\",\"placeholder\",\"autofocus\"],[\"search\",[35,1],[35,7],[35,6],[35,5],[35,4]]]]],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"addon button button-close\"],[4,[38,0],[[32,0],\"clearSearch\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"addon ico-search\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"if\",\"class\",\"autoFocus\",\"placeholder\",\"allowUpdates\",\"delay\",\"input-debounced\"]}",
    "moduleName": "sky-router-3/pods/components/input-search/template.hbs"
  });

  _exports.default = _default;
});