define("sky-router-3/pods/components/context-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oxh7cb+h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"show\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/context-menu/template.hbs"
  });

  _exports.default = _default;
});