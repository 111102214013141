define("sky-router-3/pods/portal/report/interaction-log/controller", ["exports", "moment", "sky-router-3/utils/constants", "sky-router-3/utils/computed-find-by"], function (_exports, _moment, _constants, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),

    /**
     * Query parameters
     * @type {Array}
     */
    queryParams: ['user', 'sDate', 'eDate', 'timeZoneMinutes'],

    /**
     * The list of selected users
     * @type {String}
     */
    user: null,
    userSelected: (0, _computedFindBy.default)('model.users', 'id', 'user', true),

    /**
     * The start date selected by the user
     * @type {Date}
     */
    sDate: null,
    sDateObject: Ember.computed('sDate', {
      get: function get() {
        var date = (0, _moment.default)(this.sDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().startOf('day').add(-1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('sDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),

    /**
     * The end date selected by the user
     * @type {Date}
     */
    eDate: null,
    eDateObject: Ember.computed('eDate', {
      get: function get() {
        var date = (0, _moment.default)(this.eDate);

        if (!date.isValid()) {
          date = (0, _moment.default)().endOf('day').add(1, 'days');
        }

        return date.toDate();
      },
      set: function set(key, value, preValue) {
        if (value && (0, _moment.default)(value).isValid()) {
          this.set('eDate', value.toISOString());
          return value;
        }

        return preValue;
      }
    }),
    timeZoneMinutes: null,
    timeZone: Ember.computed('sessionAccount.user.timeZoneMinutes', 'timeZoneMinutes', {
      get: function get() {
        return _constants.timeZones.findBy('offsetMinutes', parseInt(this.timeZoneMinutes || this.sessionAccount.user.get('timeZoneMinutes')));
      },
      set: function set(key, value, preValue) {
        if (value && value.offsetMinutes != undefined) {
          this.set('timeZoneMinutes', value.offsetMinutes);
          return value;
        }

        return preValue;
      }
    }),

    /**
     * When this is toggled the report will generate
     * @type {Boolean}
     */
    generateReport: false,
    actions: {
      /**
       * The event handler for the Generate Report click button. The child reportviewer component listens for this
       * event and either loads or refreshes the report.
       * @function
       * @private
       */
      generateReport: function generateReport() {
        // Triggers the report to generate in the component
        this.toggleProperty('generateReport');
      },
      selectUser: function selectUser(user) {
        this.set('user', user ? user.id : null);
      }
    }
  });

  _exports.default = _default;
});