define("sky-router-3/models/route", ["exports", "@ember-data/model", "sky-router-3/models/asset-position-type", "sky-router-3/utils/lat-lng-converter", "ember-cp-validations", "ember-data-model-fragments/attributes"], function (_exports, _model, _assetPositionType, _latLngConverter, _emberCpValidations, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ROUTE_ICONS = void 0;
  var ROUTE_ICONS = {
    0: '/skyrouter3/assets/images/waypoint.svg',
    unknown: _assetPositionType.POSITION_ICONS['unknown']
  };
  _exports.ROUTE_ICONS = ROUTE_ICONS;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', true),
    'waypoints.length': (0, _emberCpValidations.validator)('number', {
      gte: 2,
      messageKey: 'routes.requiredPoints'
    })
  });

  var _default = _model.default.extend(VALIDATIONS, {
    name: (0, _model.attr)('string'),
    createdDateUtc: (0, _model.attr)('date'),
    isArchived: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    waypoints: (0, _attributes.fragmentArray)('route-waypoint'),
    originWaypoint: Ember.computed.alias('waypoints.firstObject'),
    destinationWaypoint: Ember.computed.alias('waypoints.lastObject'),
    distance: Ember.computed('waypoints.@each.latitudeMilliarcSeconds', 'waypoints.@each.longitudeMilliarcSeconds', function () {
      if (this.waypoints.length) {
        return _latLngConverter.default.getPathDistance(this.waypoints) * 100;
      }

      return 0;
    })
  });

  _exports.default = _default;
});