define("sky-router-3/models/compound-alert", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    isEnabled: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    isArchived: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    revisions: (0, _model.hasMany)('compound-alert-revision'),
    revisionLatest: (0, _model.belongsTo)('compound-alert-revision')
  });

  _exports.default = _default;
});