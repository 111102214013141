define("sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-0/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bouPlScl",
    "block": "{\"symbols\":[\"fence\"],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.condition.insideGeofence.help\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"warn\"],[12],[1,[30,[36,0],[\"compoundrules.condition.insideGeofence.help2\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"options\",\"selected\",\"disabled\",\"searchField\",\"searchEnabled\",\"onChange\"],[[35,4],[35,3],[35,2],\"name\",true,[30,[36,1],[[32,0],\"selectGeofence\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"geofence-icon\",[[24,0,\"vertical-middle\"],[24,5,\"width:1.5em;height:1.5em\"]],[[\"@geofence\"],[[32,1]]],null],[2,\" \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[1,[30,[36,7],null,[[\"errors\"],[[35,6,[\"attrs\",\"model\",\"value1\",\"messages\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"disabled\",\"geofenceSelected\",\"geofences\",\"input-select\",\"validations\",\"input-errors\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/conditions/attribute-type-0/template.hbs"
  });

  _exports.default = _default;
});