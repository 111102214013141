define("sky-router-3/pods/portal/manage/groups/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.group.name': (0, _emberCpValidations.validator)('presence', true),
    'model.group.startDateUtc': (0, _emberCpValidations.validator)('presence', true),
    'model.group.endDateUtc': (0, _emberCpValidations.validator)('date', {
      after: Ember.computed.alias('model.model.group.startDateUtc'),
      precision: 'day',
      allowBlank: true
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    showGroupEnd: Ember.computed('model.group.endDateUtc', function () {
      return !!this.model.group.endDateUtc;
    }),
    actions: {
      setGroupExpire: function setGroupExpire(shouldExpire) {
        var date = shouldExpire ? new Date() : null;
        this.model.group.set('endDateUtc', date);
      }
    }
  });

  _exports.default = _default;
});