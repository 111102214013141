define("sky-router-3/models/compound-alert-revision-output-properties-sms", ["exports", "@ember-data/model", "sky-router-3/models/compound-alert-revision-output-properties", "ember-data-model-fragments/attributes", "ember-cp-validations", "ember-data"], function (_exports, _model, _compoundAlertRevisionOutputProperties, _attributes, _emberCpValidations, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    message: (0, _emberCpValidations.validator)('presence', true),
    recipients: (0, _emberCpValidations.validator)('inline', {
      validate: function validate(value, options, model
      /*, attribute*/
      ) {
        return model.recipientUsers.length || model.recipientPersonnel.length || model.recipientPhones.length ? true : 'Must provide at least one recipient';
      },
      dependentKeys: ['model.recipientUsers.[]', 'model.recipientPersonnel.[]', 'model.recipientPhones.[]']
    })
  });

  var _default = _compoundAlertRevisionOutputProperties.default.extend(VALIDATIONS, {
    /**
     * Overrides the lower property so that we don't have to set it in the serializer
     *
     */
    $type: (0, _model.attr)('string', {
      defaultValue: function defaultValue() {
        return 'compound-alert-revision-output-properties-sms';
      }
    }),

    /**
     * Holds the recipients of the alert email output
     */
    recipientUsers: (0, _attributes.array)(),
    recipientPersonnel: (0, _attributes.array)(),
    recipientPhones: (0, _attributes.array)(),
    recipientUsersInstance: Ember.computed('recipientUsers.[]', 'store', function () {
      var _this = this;

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(this.recipientUsers.map(function (u) {
          return _this.store.find('user', u);
        }))
      });
    }),
    recipientPersonnelInstance: Ember.computed('recipientPersonnel.[]', 'store', function () {
      var _this2 = this;

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(this.recipientPersonnel.map(function (u) {
          return _this2.store.find('personnel', u);
        }))
      });
    }),
    // The core message that may contain {{}} compound-alert-tags
    message: (0, _model.attr)('string'),
    // Message body properties that are on by default
    showAssetInfo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    showPositionInfo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    showTimeInfo: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    // Message body properties that are off by default
    showMapInfo: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    showGeocodedLocation: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Copies the message by creating a deep copy so that when a revision is saved a clone can be made.
     * This has to be done because ember-data-model-fragments gets mad about downing a shallow clone
     * and because this is polymorphic it would be a TON of work for the compound-alert-route to do the cloning
     */
    copy: function copy() {
      return this.store.createFragment(this.$type, {
        recipientUsers: this.recipientUsers.toArray(),
        recipientPersonnel: this.recipientPersonnel.toArray(),
        recipientPhones: this.recipientPhones.toArray(),
        message: this.message,
        showAssetInfo: this.showAssetInfo,
        showPositionInfo: this.showPositionInfo,
        showTimeInfo: this.showTimeInfo,
        showMapInfo: this.showMapInfo,
        showGeocodedLocation: this.showGeocodedLocation
      });
    }
  });

  _exports.default = _default;
});