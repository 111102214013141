define("sky-router-3/pods/components/portal/manage/device-parameters/types/he3000/component", ["exports", "sky-router-3/utils/computed-find-by", "ember-cp-validations"], function (_exports, _computedFindBy, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'reportMinutes.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.reportMinutes.validationMaxNumber'),
      gte: Ember.computed.alias('model.reportMinutes.validationMinNumber')
    })],
    'reportSecondaryMinutes.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.reportSecondaryMinutes.validationMaxNumber'),
      gte: Ember.computed.alias('model.reportSecondaryMinutes.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Indicates if the control will be readOnly
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Object holding revision settings that can have any number of
     * key and values
     * @type {Object}
     */
    settings: null,
    reportMinutes: (0, _computedFindBy.default)('settings', 'key', 'gtts_reporting_minutes'),
    reportRounding: (0, _computedFindBy.default)('settings', 'key', 'gtts_reporting_rounding'),
    reportSecondaryMinutes: (0, _computedFindBy.default)('settings', 'key', 'gtts_secondary_minutes'),
    conserveReportsRadius: (0, _computedFindBy.default)('settings', 'key', 'gtts_geofence_radius'),
    conserveReportsEnabled: (0, _computedFindBy.default)('settings', 'key', 'gtts_geofence_enabled')
  });

  _exports.default = _default;
});