define("sky-router-3/pods/components/input-duration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q1ku+mv3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-inline\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"value\",\"change\",\"type\",\"min\",\"max\",\"placeholder\",\"pattern\",\"disabled\"],[\"form-control\",[30,[36,5],[[35,3]],null],[30,[36,4],[[32,0],[32,0,[\"onUpdate\"]],\"hours\",[35,3],[35,2],[35,1]],null],\"number\",\"0\",\"99\",\"HH\",\"\\\\d*\",[35,0]]]]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[30,[36,7],[\"common.hours\"],null]],[13],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"value\",\"change\",\"type\",\"min\",\"max\",\"placeholder\",\"pattern\",\"disabled\"],[\"form-control\",[30,[36,5],[[35,2]],null],[30,[36,4],[[32,0],[32,0,[\"onUpdate\"]],\"minutes\",[35,3],[35,2],[35,1]],null],\"number\",\"0\",\"59\",\"MM\",\"\\\\d*\",[35,0]]]]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[30,[36,7],[\"common.minutes\"],null]],[13],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,6],null,[[\"class\",\"value\",\"change\",\"type\",\"min\",\"max\",\"placeholder\",\"pattern\",\"disabled\"],[\"form-control\",[30,[36,5],[[35,1]],null],[30,[36,4],[[32,0],[32,0,[\"onUpdate\"]],\"seconds\",[35,3],[35,2],[35,1]],null],\"number\",\"0\",\"59\",\"MM\",\"\\\\d*\",[35,0]]]]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[30,[36,7],[\"misc.seconds\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"_seconds\",\"_minutes\",\"_hours\",\"action\",\"readonly\",\"input\",\"t\",\"showSeconds\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/input-duration/template.hbs"
  });

  _exports.default = _default;
});