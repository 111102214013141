define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/tracking-category/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'transmitRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.transmitRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.transmitRate.validationMinNumber')
    })],
    'burstFixRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.burstFixRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.burstFixRate.validationMinNumber')
    })],
    'burstTransmitRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.burstTransmitRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.burstTransmitRate.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    transmitRate: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    burstFixRate: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    burstTransmitRate: null,

    /**
     * Passed in reporting type string
     */
    reportingType: null,

    /**
    * The path to the icon to be used in the template
    **/
    iconPath: null,
    isBurst: Ember.computed.equal('transmitRate.value', '-2'),

    /**
    * Set the title translation path by the reporting type
    */
    titlePath: function () {
      return "parameter.rockseven.".concat(this.get('reportingType'), ".title");
    }.property(),

    /**
    * Set the help translation path by the reporting type
    */
    helpPath: function () {
      return "parameter.rockseven.".concat(this.get('reportingType'), ".help");
    }.property(),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    transmitRateSelected: function () {
      var options = this.get('transmitRateOptions');
      var option = options.findBy('value', this.get('transmitRate.value'));

      if (!option) {
        option = options.findBy('value', '120');
      }

      return option;
    }.property('transmitRate.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    burstFixRateSelected: function () {
      var options = this.get('burstFixRateOptions');
      var option = options.findBy('value', this.get('burstFixRate.value'));

      if (!option) {
        option = options.findBy('value', '30');
      }

      return option;
    }.property('burstFixRate.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    burstTransmitRateSelected: function () {
      var options = this.get('burstTransmitRateOptions');
      var option = options.findBy('value', this.get('burstTransmitRate.value'));

      if (!option) {
        option = options.findBy('value', '10');
      }

      return option;
    }.property('burstTransmitRate.value'),

    /**
    * The options for the transmit rate dropdown
    */
    transmitRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '-1',
        label: intl.t('parameter.misc.continuous')
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '180',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '240',
        label: intl.t('parameter.misc.countMinutes', {
          count: 4
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '360',
        label: intl.t('parameter.misc.countMinutes', {
          count: 6
        })
      }, {
        value: '480',
        label: intl.t('parameter.misc.countMinutes', {
          count: 8
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '720',
        label: intl.t('parameter.misc.countMinutes', {
          count: 12
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        value: '5400',
        label: intl.t('parameter.misc.countMinutes', {
          count: 90
        })
      }, {
        value: '7200',
        label: intl.t('parameter.misc.countHours', {
          count: 2
        })
      }, {
        value: '10800',
        label: intl.t('parameter.misc.countHours', {
          count: 3
        })
      }, {
        value: '14400',
        label: intl.t('parameter.misc.countHours', {
          count: 4
        })
      }, {
        value: '21600',
        label: intl.t('parameter.misc.countHours', {
          count: 6
        })
      }, {
        value: '28800',
        label: intl.t('parameter.misc.countHours', {
          count: 8
        })
      }, {
        value: '43200',
        label: intl.t('parameter.misc.countHours', {
          count: 12
        })
      }, {
        value: '86400',
        label: intl.t('parameter.misc.countHours', {
          count: 24
        })
      }, {
        value: '-2',
        label: intl.t('parameter.misc.burst')
      }];
    }.property(),

    /**
    * The options for the burst fix rate dropdown
    */
    burstFixRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '5',
        label: intl.t('parameter.misc.countSeconds', {
          count: 5
        })
      }, {
        value: '10',
        label: intl.t('parameter.misc.countSeconds', {
          count: 10
        })
      }, {
        value: '15',
        label: intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '20',
        label: intl.t('parameter.misc.countSeconds', {
          count: 20
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinutes', {
          count: 1
        })
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1200',
        label: intl.t('parameter.misc.countMinutes', {
          count: 20
        })
      }];
    }.property(),

    /**
    * The options for the burst transmit rate dropdown
    */
    burstTransmitRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '1',
        label: intl.t('parameter.misc.countMinutes', {
          count: 1
        })
      }, {
        value: '2',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '5',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '10',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '15',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinutes', {
          count: 60
        })
      }];
    }.property(),
    actions: {
      transmitRateChange: function transmitRateChange(selected) {
        this.set('transmitRate.value', selected.value);
      },
      burstFixRateChange: function burstFixRateChange(selected) {
        this.set('burstFixRate.value', selected.value);
      },
      burstTransmitRateChange: function burstTransmitRateChange(selected) {
        this.set('burstTransmitRate.value', selected.value);
      }
    }
  });

  _exports.default = _default;
});