define("sky-router-3/helpers/latlng-convert", ["exports", "sky-router-3/utils/lat-lng-converter"], function (_exports, _latLngConverter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function latlngConvert(params, hash) {
    var milliarc = 0; // Convert from the input

    switch (hash.from) {
      case 'decimal':
        milliarc = _latLngConverter.default.decimalDegToMilliArcSecond(params[0]);
        break;

      case 'milliarc':
      default:
        milliarc = params[0];
        break;
    } // Convert to the output


    switch (hash.to) {
      case 'decimal':
        return _latLngConverter.default.milliArcSecondsToDecimalDeg(milliarc);

      case 'milliarc':
      default:
        return milliarc;
    }
  });

  _exports.default = _default;
});