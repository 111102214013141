define("sky-router-3/pods/error/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0bf7xn9Q",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"fa-stack fa-5x\"],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-search fa-stack-1x\"],[12],[13],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-ban fa-stack-2x text-danger\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[10,\"strong\"],[12],[1,[30,[36,0],[\"error.headerError\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[\"error.errorDescription1\"],null]],[6,[37,1],null,[[\"route\"],[\"portal\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"error.errorDescription2\"],null]]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"link-to\"]}",
    "moduleName": "sky-router-3/pods/error/not-found/template.hbs"
  });

  _exports.default = _default;
});