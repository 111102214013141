define("sky-router-3/pods/components/portal/manage/device-parameters/types/rockair/component", ["exports", "sky-router-3/utils/computed-find-by"], function (_exports, _computedFindBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Are the inputs of this component disabled.
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in settings.
     * @type {sky-router-3/models/asset-parameter-set-revision-setting[]}
     */
    settings: null,
    // Tracking ***********************************************************************************
    timeReportingEnabled: (0, _computedFindBy.default)('settings', 'key', 'time_interval_reporting_enabled'),
    // Tracking > Satellite
    satTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_transmit_rate_sat'),
    satBurstFixRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_fix_rate_sat'),
    satBurstTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_transmit_rate_sat'),
    // Tracking > Cellular
    cellTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_transmit_rate_cell'),
    cellBurstFixRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_fix_rate_cell'),
    cellBurstTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_transmit_rate_cell'),
    // Tracking > Distress
    distressTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_transmit_rate_distress'),
    distressBurstFixRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_fix_rate_distress'),
    distressBurstTransmitRate: (0, _computedFindBy.default)('settings', 'key', 'rock_burst_transmit_rate_distress'),
    // Tracking > Activity sense
    activitySenseEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_activity_sense_enabled'),
    accelerationThreshold: (0, _computedFindBy.default)('settings', 'key', 'rock_acceleration_threshold'),
    // Tracking > Advanced
    autoResumeEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_auto_resume_enabled'),
    autoResumeDistance: (0, _computedFindBy.default)('settings', 'key', 'rock_auto_resume_distance'),
    // Alerts *************************************************************************************
    // Alerts > Collision
    collisionEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_collision_enabled'),
    collisionDuration: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_collision_duration'),
    collisionThreshold: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_collision_threshold'),
    // Alerts > Airborne
    ascentDescentAlertMode: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_ascent_descent_alert_mode'),
    ascentAlertSpeed: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_ascent_alert_speed'),
    ascentAlertTime: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_ascent_alert_time'),
    descentAlertSpeed: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_descent_alert_speed'),
    descentAlertTime: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_descent_alert_time'),
    flightAlertStatus: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_flight_alert_status'),
    airborneVRotate: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_vrotate'),
    airborneVStall: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_airborne_vstall'),
    // Alerts > Geofence
    geofenceEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_geofence_enabled'),
    geofenceDistance: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_geofence_distance'),
    geofenceCheckRate: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_geofence_check_rate'),
    geofenceSetCentre: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_geofence_set_centre'),
    // Alerts > Power Loss
    powerLoss: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_power_loss'),
    // Alerts > Temperature
    temperatureEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_temperature_enabled'),
    temperatureCheckRate: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_temperature_check_rate'),
    temperatureMinTemp: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_temperature_min_temp'),
    temperatureMaxTemp: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_temperature_max_temp'),
    // Mailbox ***********************************************************************************
    mailboxCheckEnabled: (0, _computedFindBy.default)('settings', 'key', 'rock_mailbox_check_enabled'),
    mailboxCheckRate: (0, _computedFindBy.default)('settings', 'key', 'rock_mailbox_check_rate'),
    // Settings ***********************************************************************************
    gprsStrategy: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_system_gprs_strategy'),
    dynamicMode: (0, _computedFindBy.default)('settings', 'key', 'rock_alerts_gps_dynamic_mode')
  });

  _exports.default = _default;
});