define("sky-router-3/pods/components/portal/track/map/marker/marker-trip-waypoint/component", ["exports", "sky-router-3/utils/lat-lng-converter", "sky-router-3/mixins/google-object-mapping", "sky-router-3/models/trip-plan-leg-waypoint"], function (_exports, _latLngConverter, _googleObjectMapping, _tripPlanLegWaypoint) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Creates a waypoint marker that is used to display a waypoint
   * @example {{portal/track/map/marker/marker-route-waypoint waypoint=waypoint map=map }}
   */
  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    tagName: '',

    /**
     * @event markerAction
     * @description generic action that contains all of the events that are fired.
     *              combining all the actions into one action helps reduce code
     *              duplication.
     * @param the sender of the action, aka. this component.
     * @param the string action that is being performed
     * 'close'  : Fired when the marker wishes to close its infoBox
     * 'click'  : Fired when the marker is clicked
     * 'delete' : Fired when the marker delete button has been clicked
     */
    // Mixin properties
    googlePropertyObject: Ember.computed.alias('marker'),
    googlePropertyMap: [{
      event: 'position_changed',
      property: 'position',
      getFunc: 'getPosition',
      setFunc: 'setPosition'
    }, {
      event: 'mouseover',
      eFunc: '_markerMouseOver'
    }, {
      event: 'mouseout',
      eFunc: '_markerMouseOut'
    }, {
      property: 'isEditing',
      setFunc: 'setDraggable'
    }, {
      property: 'animation',
      setFunc: 'setAnimation'
    }, {
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'icon',
      setFunc: 'setIcon'
    }, {
      event: 'drag',
      eFunc: 'onDrag'
    }],

    /**
     * Holds a reference to the map that this marker belongs to.
     * It is used to register the marker with the map and un-register it.
     */
    map: null,

    /**
     * The instance of the Google maps marker that defines this location
     * @type {google.maps.Marker}
     */
    marker: null,

    /**
     * The waypoint item that this marker runs off of
     */
    waypoint: null,

    /**
     * Defines if this marker is in edit mode. When in edit mode the marker is
     * movable and a form is shown. This is not inside the config because it
     * is global to every map.
     */
    isEditing: true,

    /**
     * Set whether or not the waypoint is the origin
     */
    isOrigin: false,

    /**
     * Set whether or not the waypoint is the destination
     */
    isDestination: false,

    /**
     * Type key identifier used to determine the type of marker that this is.
     * @type {String}
     */
    type: 'waypoint',

    /**
     * Holds a reference to the marker spiderfier that is responsible
     * for group markers on a map a certain way. This is OPTIONAL.
     *
     * Markers should register and un-register themselves from the
     * spiderfier when they want to use them.
     *
     * @default null
     * @type {portal/track/map/marker-spiderfier}
     */
    spiderfier: null,

    /**
     * The selected tab used by the tab panel
     * @type {Number}
     */
    selectedTabIndex: null,

    /**
     * Defines the animation that the marker is currently displaying
     */
    animation: function animation() {
      return null;
    },

    /**
     * Get the icon for the waypoint to use
     * @return {google.maps.icon} [description]
     */
    icon: {
      url: _tripPlanLegWaypoint.ROUTE_ICONS[0],
      anchor: new google.maps.Point(12, 12),
      scaledSize: new google.maps.Size(24, 24),
      size: new google.maps.Size(24, 24)
    },

    /**
     * Gets the Google maps position of the waypoint point.
     * Also sets the position from a `Google.maps.LatLng` object.
     */
    position: Ember.computed('waypoint.latitudeMilliarcSeconds', 'waypoint.longitudeMilliarcSeconds', {
      set: function set(key, value) {
        if (this.get('isEditing')) {
          var mLat = _latLngConverter.default.decimalDegToMilliArcSecond(value.lat());

          var mLng = _latLngConverter.default.decimalDegToMilliArcSecond(value.lng());

          this.set('waypoint.latitudeMilliarcSeconds', mLat);
          this.set('waypoint.longitudeMilliarcSeconds', mLng);
        }

        return value;
      },
      get: function get() {
        return _latLngConverter.default.modelToGoogleLatLng(this.get('waypoint'));
      }
    }),

    /**
     * Set to true if the users mouse is hovered over the location or the
     * info-box that is tied to the location.
     */
    _markerHovered: false,

    /**
     * Incoming property that indicates that the box should be forced open
     */
    infoBoxOpen: false,

    /**
     * Holds the opened status of the associated infoBox that can be used
     * inside a template.
     * @property
     * @private
     */
    _infoBoxOpen: Ember.computed.or('_markerHovered', 'infoBoxOpen'),

    /**
     * Initializes the marker for this location and after the render is complete.
     * It then check to see if the marker needs to be registered with anything
     * other passed in components like a clusterer or a spiderfier.
     */
    init: function init() {
      var marker = new google.maps.Marker({
        zIndex: 200,
        // IE11/Firefox doesn't like SVG markers with optimized: true.
        // It throws an 'indexSizeError'
        // TODO: this needs a second look
        optimized: false
      });
      var spiderfier = this.get('spiderfier');
      this.set('marker', marker);

      if (spiderfier) {
        spiderfier.registerMarker(marker, this);
      }

      this._super.apply(this, arguments);
    },

    /**
     * Removes the marker from the map and destroys it. It also tells any
     * other components that are looking at the marker to unregister the marker
     * from it.
     */
    willDestroyElement: function willDestroyElement() {
      var marker = this.get('marker');
      var spiderfier = this.get('spiderfier');

      if (spiderfier) {
        spiderfier.unregisterMarker(marker);
      }

      marker.setMap(null);
    },

    /**
     * Sets the marker in hover-mode if the marker is not behind an open infoBox
     *
     * We have to do with here instead of in the infobox because of how ember
     * and google maps both attach events. Ember attaches events at the 'body'
     * and google at each individual part. So we want google to not handle the
     * event but we want ember to do so. This makes for a very annoying situation.
     * @private
     */
    _markerMouseOver: function _markerMouseOver()
    /*marker, mouseEvent*/
    {
      var hoveredInfoBoxes = $('.toolwindow:hover').length;

      if (hoveredInfoBoxes === 0) {
        this.set('_markerHovered', true);
      }
    },

    /**
     * Starts a timer to close turn off the marker hovered mode.
     * @private
     */
    _markerMouseOut: function _markerMouseOut() {
      this.set('_markerHovered', false);
    },
    actions: {
      infoBoxCloseClicked: function infoBoxCloseClicked() {
        this.markerAction(this, 'close');
      }
    }
  });

  _exports.default = _default;
});