define("sky-router-3/pods/portal/manage/geofences/edit/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params, transition) {
      return this.modelFor(transition.to.parent.name);
    }
  });

  _exports.default = _default;
});