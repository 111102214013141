define("sky-router-3/pods/components/portal/manage/device-parameters/controls/rockseven/alerts-geofence/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'geofenceDistance.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.geofenceDistance.validationMaxNumber'),
      gte: Ember.computed.alias('model.geofenceDistance.validationMinNumber')
    })],
    'geofenceCheckRate.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.geofenceCheckRate.validationMaxNumber'),
      gte: Ember.computed.alias('model.geofenceCheckRate.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Is the form disabled
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Passed in value
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    enabledSetting: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    geofenceDistance: null,

    /**
     * Passed in values
     * @type {sky-router-3/models/asset-parameter-set-revision-setting}
     */
    geofenceCheckRate: null,

    /**
    * Property for checking if the feature is enabled
    */
    enabledSet: function () {
      return '0' !== this.get('enabledSetting.value');
    }.property('enabledSetting.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    enabledSettingSelected: function () {
      var options = this.get('enabledSettingOptions');
      var option = options.findBy('value', this.get('enabledSetting.value'));

      if (!option) {
        option = options.findBy('value', '0');
      }

      return option;
    }.property('enabledSetting.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    geofenceDistanceSelected: function () {
      var options = this.get('geofenceDistanceOptions');
      var option = options.findBy('value', this.get('geofenceDistance.value'));

      if (!option) {
        option = options.findBy('value', '25');
      }

      return option;
    }.property('geofenceDistance.value'),

    /**
    * Gets the selected option, sets a default if option not found in the list
    */
    geofenceCheckRateSelected: function () {
      var options = this.get('geofenceCheckRateOptions');
      var option = options.findBy('value', this.get('geofenceCheckRate.value'));

      if (!option) {
        option = options.findBy('value', '5');
      }

      return option;
    }.property('geofenceCheckRate.value'),

    /**
    * The options for the acceleration threshold dropdown
    */
    enabledSettingOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '0',
        label: intl.t('parameter.rockseven.alertsGeofence.off')
      }, {
        value: '1',
        label: intl.t('parameter.rockseven.alertsGeofence.simple')
      }, {
        value: '2',
        label: intl.t('parameter.rockseven.alertsGeofence.geofence')
      }];
    }.property(),

    /**
    * The options for the geofence distance dropdown
    */
    geofenceDistanceOptions: function () {
      return [{
        value: '25',
        label: '25 m'
      }, {
        value: '50',
        label: '50 m'
      }, {
        value: '100',
        label: '100 m'
      }, {
        value: '250',
        label: '250 m'
      }, {
        value: '1000',
        label: '1 km'
      }, {
        value: '2000',
        label: '2 km'
      }, {
        value: '3000',
        label: '3 km'
      }];
    }.property(),

    /**
    * The options for the geofence check rate dropdown
    */
    geofenceCheckRateOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '1',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '2',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '3',
        label: intl.t('parameter.misc.countMinutes', {
          count: 3
        })
      }, {
        value: '5',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '10',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '15',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }];
    }.property(),
    actions: {
      enabledSettingChange: function enabledSettingChange(selected) {
        this.set('enabledSetting.value', selected.value);

        if (selected.value === '0') {
          this.get('geofenceDistance').rollbackAttributes();
          this.get('geofenceCheckRate').rollbackAttributes();
        }
      },
      geofenceDistanceChange: function geofenceDistanceChange(selected) {
        this.set('geofenceDistance.value', selected.value);
      },
      geofenceCheckRateChange: function geofenceCheckRateChange(selected) {
        this.set('geofenceCheckRate.value', selected.value);
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('geofenceDistance').rollbackAttributes();
          this.get('geofenceCheckRate').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});