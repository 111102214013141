define("sky-router-3/pods/portal/settings/contact/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('pageTitle.settings.contact');
    },

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * Model is the current user
     * @return {Ember.RSVP.Promise} the user object
     */
    model: function model() {
      return this.get('sessionAccount.user');
    },
    actions: {
      reset: function reset() {
        this.modelFor(this.routeName).rollbackAttributes();
      },
      willTransition: function willTransition(transition) {
        var controller = this.controllerFor(this.routeName);
        var user = this.modelFor(this.routeName);

        if (controller.get('validations.isValid')) {
          if (user.get('hasDirtyAttributes')) {
            user.save();
          }
        } else {
          if (confirm(this.intl.t('settings.contactInfoInvalidReset'))) {
            this.send('reset');
          } else {
            transition.abort();
          }
        }
      }
    }
  });

  _exports.default = _default;
});