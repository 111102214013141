define("sky-router-3/models/alert-trigger-event", ["exports", "@ember-data/model", "sky-router-3/models/asset-position-type"], function (_exports, _model, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ALERT_EVENT_POSITION_ICONS = _exports.METADATA_ICONS = _exports.ALERT_EVENT_TYPES = _exports.default = void 0;

  /* eslint-env node */
  var ALERT_EVENT_TYPES = {
    TakeOffAirport: '99',
    LandingAirport: '100',
    TakeOffLocation: '135',
    LandingLocation: '136'
  };
  _exports.ALERT_EVENT_TYPES = ALERT_EVENT_TYPES;
  var METADATA_ICONS = {
    1: '/skyrouter3/assets/images/events/event_takeoff.svg',
    2: '/skyrouter3/assets/images/events/event_landing.svg',
    55: '/skyrouter3/assets/images/events/event_takeoff.svg',
    56: '/skyrouter3/assets/images/events/event_landing.svg',
    unknown: '/skyrouter3/assets/images/events/event_miscellaneousevent.svg'
  };
  _exports.METADATA_ICONS = METADATA_ICONS;
  var ALERT_EVENT_POSITION_ICONS = {
    1: _assetPositionType.POSITION_ICONS[0],
    12: _assetPositionType.POSITION_ICONS[13],
    13: _assetPositionType.POSITION_ICONS[14],
    14: _assetPositionType.POSITION_ICONS[17],
    15: _assetPositionType.POSITION_ICONS[18],
    16: _assetPositionType.POSITION_ICONS[19],
    17: _assetPositionType.POSITION_ICONS[24],
    20: _assetPositionType.POSITION_ICONS[26],
    21: _assetPositionType.POSITION_ICONS[27],
    22: _assetPositionType.POSITION_ICONS[28],
    23: _assetPositionType.POSITION_ICONS[29],
    24: _assetPositionType.POSITION_ICONS[30],
    26: _assetPositionType.POSITION_ICONS[31],
    27: _assetPositionType.POSITION_ICONS[32],
    28: _assetPositionType.POSITION_ICONS[33],
    29: _assetPositionType.POSITION_ICONS[34],
    31: _assetPositionType.POSITION_ICONS[36],
    35: _assetPositionType.POSITION_ICONS[39],
    36: _assetPositionType.POSITION_ICONS[40],
    40: _assetPositionType.POSITION_ICONS[44],
    48: _assetPositionType.POSITION_ICONS[45],
    39: _assetPositionType.POSITION_ICONS[46],
    42: _assetPositionType.POSITION_ICONS[51],
    61: _assetPositionType.POSITION_ICONS[56],
    62: _assetPositionType.POSITION_ICONS[57],
    64: _assetPositionType.POSITION_ICONS[59],
    65: _assetPositionType.POSITION_ICONS[60],
    66: _assetPositionType.POSITION_ICONS[61],
    67: _assetPositionType.POSITION_ICONS[62],
    69: _assetPositionType.POSITION_ICONS[138],
    70: _assetPositionType.POSITION_ICONS[139],
    71: _assetPositionType.POSITION_ICONS[140],
    72: _assetPositionType.POSITION_ICONS[141],
    73: _assetPositionType.POSITION_ICONS[142],
    74: _assetPositionType.POSITION_ICONS[143],
    76: _assetPositionType.POSITION_ICONS[144],
    77: _assetPositionType.POSITION_ICONS[145],
    78: _assetPositionType.POSITION_ICONS[146],
    79: _assetPositionType.POSITION_ICONS[147],
    80: _assetPositionType.POSITION_ICONS[148],
    81: _assetPositionType.POSITION_ICONS[149],
    82: _assetPositionType.POSITION_ICONS[150],
    83: _assetPositionType.POSITION_ICONS[151],
    84: _assetPositionType.POSITION_ICONS[152],
    60: _assetPositionType.POSITION_ICONS[153],
    59: _assetPositionType.POSITION_ICONS[154],
    58: _assetPositionType.POSITION_ICONS[155],
    57: _assetPositionType.POSITION_ICONS[156],
    104: _assetPositionType.POSITION_ICONS[254],
    19: _assetPositionType.POSITION_ICONS[255],
    25: _assetPositionType.POSITION_ICONS[300],
    138: _assetPositionType.POSITION_ICONS[304],
    139: _assetPositionType.POSITION_ICONS[305],
    140: _assetPositionType.POSITION_ICONS[306],
    141: _assetPositionType.POSITION_ICONS[307],
    142: _assetPositionType.POSITION_ICONS[308],
    116: _assetPositionType.POSITION_ICONS[260],
    155: _assetPositionType.POSITION_ICONS[413],
    156: _assetPositionType.POSITION_ICONS[414],
    168: _assetPositionType.POSITION_ICONS[426],
    170: _assetPositionType.POSITION_ICONS[428],
    171: _assetPositionType.POSITION_ICONS[429],
    unknown: _assetPositionType.POSITION_ICONS['unknown']
  };
  /**
   * This model describes the alert trigger events that exist in the database.
   */

  _exports.ALERT_EVENT_POSITION_ICONS = ALERT_EVENT_POSITION_ICONS;

  var model = _model.default.extend({
    /**
     * The value for the metadata
     */
    name: (0, _model.attr)('string'),

    /**
     * The event type of the triggering event
     * 1: Position Report Type
     * 2: Flight Tracking Metadata
     */
    eventType: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * The event id
     */
    eventId: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Does the event support alerts
     **/
    supportAlertAction: (0, _model.attr)('boolean'),

    /**
     * Is the event flagged as a trip start
     **/
    isTripStart: (0, _model.attr)('boolean'),
    canStartTrip: (0, _model.attr)('boolean'),

    /**
     * Is the event flagged as a trip stop
     **/
    isTripStop: (0, _model.attr)('boolean'),
    canStopTrip: (0, _model.attr)('boolean'),

    /**
     * Presents an URL for the event icon svg file
     * @return {string} URL
     */
    iconUrl: function () {
      var url = '';

      if (this.get('eventType') === 1) {
        url = ALERT_EVENT_POSITION_ICONS[this.get('eventId')];

        if (!url) {
          return ALERT_EVENT_POSITION_ICONS['unknown'];
        }
      } else {
        url = METADATA_ICONS[this.get('eventId')];

        if (!url) {
          return METADATA_ICONS['unknown'];
        }
      }

      return url;
    }.property('id'),

    /**
     * Is the triggering event an airport related one?
     * @return {boolean}
     */
    isAirportSelection: function () {
      var id = this.get('id');

      if (id === ALERT_EVENT_TYPES.TakeOffAirport || id === ALERT_EVENT_TYPES.LandingAirport) {
        return true;
      }

      return false;
    }.property('id'),

    /**
     * Is the triggering event a location related one?
     * @return {boolean}
     */
    isLocationSelection: function () {
      var id = this.get('id');

      if (id === ALERT_EVENT_TYPES.TakeOffLocation || id === ALERT_EVENT_TYPES.LandingLocation) {
        return true;
      }

      return false;
    }.property('id')
  });

  var _default = model;
  _exports.default = _default;
});