define("sky-router-3/pods/components/portal/settings/security/login-expiration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tK5m8kcr",
    "block": "{\"symbols\":[\"x\"],\"statements\":[[10,\"p\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"settings.autoLogout.help\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"settings.autoLogout.legend\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],null,[[\"options\",\"selected\",\"onChange\"],[[35,4],[30,[36,5],[[35,4]],[[\"prop\",\"value\"],[\"value\",[35,3,[\"ticketExpire\"]]]]],[30,[36,2],[[32,0],\"setExpire\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"action\",\"user\",\"options\",\"find-by\",\"input-select\"]}",
    "moduleName": "sky-router-3/pods/components/portal/settings/security/login-expiration/template.hbs"
  });

  _exports.default = _default;
});