define("sky-router-3/pods/portal/manage/device-parameters/index/rename/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('asset-parameter-set', params.set_id);
    },
    actions: {
      willTransition: function willTransition() {
        this.modelFor(this.routeName).rollbackAttributes();
      },
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-parameters');
      },
      save: function save() {
        var _this = this;

        this.modelFor(this.routeName).save().then(function () {
          _this.transitionTo('portal.manage.device-parameters');
        }).catch(function () {
          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        });
      }
    }
  });

  _exports.default = _default;
});