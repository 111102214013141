define("sky-router-3/models/user-sound", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A model that represents a sound file
   */
  var _default = _model.default.extend({
    /**
     * The filename of the sound file ex. myFile.mp3
     */
    fileName: (0, _model.attr)('string'),

    /**
     * The description of the sound file which is optional.
     */
    description: (0, _model.attr)('string'),

    /**
     * The content type of the sound file ex. "audio/mpeg3"
     */
    contentType: (0, _model.attr)('string'),

    /**
     * A URL which this file is accessible at.
     */
    url: (0, _model.attr)('string'),

    /**
     * A URL which the .wav version of this file is accessible at.
     */
    urlWave: (0, _model.attr)('string'),

    /**
     * Does the user haver permission to delete the sound or not.
     * For instance the default sounds cannot be deleted.
     */
    canDelete: (0, _model.attr)('boolean'),

    /**
     * Opposite of canDelete
     */
    cannotDelete: Ember.computed.not('canDelete'),

    /**
     * [hasMany description]
     * @param  {[type]}  'asset-position-type' [description]
     * @return {Boolean}                       [description]
     */
    assetPositionTypes: (0, _model.hasMany)('asset-position-type', {
      async: true
    })
  });

  _exports.default = _default;
});