define("sky-router-3/models/asset-compound-rule-revision-output", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * How often this output action should repeat in seconds.
     * Not all output types support this.
     * @type {number}
     */
    repeat: (0, _model.attr)('number', {
      defaultValue: 0
    }),

    /**
     * Revision associated with this output
     * @type {sky-router-3/models/asset-compound-rule-revision}
     */
    revision: (0, _model.belongsTo)('asset-compound-rule-revision'),

    /**
     * The output type of this revision output.
     * This dicates what the value fields mean and their validation
     * @type {sky-router-3/models/asset-compound-rule-output-type}
     */
    type: (0, _model.belongsTo)('asset-compound-rule-output-type'),

    /**
     * Optional value field that is utilized based on the output-type selected.
     * @type {unknown}
     */
    value1: (0, _model.attr)(),

    /**
     * Optional value field that is utilized based on the output-type selected.
     * @type {unknown}
     */
    value2: (0, _model.attr)()
  });

  _exports.default = _default;
});