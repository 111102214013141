define("sky-router-3/pods/portal/track/manual-position/route", ["exports", "sky-router-3/models/asset-position-type", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _assetPositionType, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    intl: Ember.inject.service(),

    /**
     * Required permissions in order to access this route.
     * Part of the `PermissionRequiredMixin`
     * @type {Array}
     */
    permissions: ['track.manual'],
    titleToken: function () {
      return this.intl.t('manualEvent.title');
    }.property(),
    model: function model(params) {
      return Ember.RSVP.hash({
        //positionTypes: this.store.findAll('asset-position-type'),
        //altitudeUnits: this.store.findAll('unit-altitude'),
        //speedUnits: this.store.findAll('unit-speed'),
        assets: this.store.findAll('asset'),
        geofence: this.store.createRecord('geofence', {
          latitudeMilliarcSeconds: params.latitude || 3600000,
          longitudeMilliarcSeconds: params.longitude || 3600000 // utc: new Date()

        }),
        position: this.store.createRecord('asset-position', {
          latitudeMilliarcSeconds: params.latitude || 3600000,
          longitudeMilliarcSeconds: params.longitude || 3600000 // utc: new Date()

        }),
        positionSpecified: !!params.latitude,
        maxDate: new Date(),
        asset: null
      });
    },
    afterModel: function afterModel(model, transition) {
      // const manualReport = model.positionTypes.findBy('id', POSITION_TYPES.ManualPosition);
      // model.position.set('type', manualReport);
      // model.position.set('description', manualReport.get('name'));
      if (model.positionSpecified) {
        transition.trigger(false, 'centerOnPosition', model.position);
      }
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('latitude', null);
        controller.set('longitude', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        var model = this.modelFor(this.routeName);

        if (model.position.get('isNew')) {
          model.position.destroyRecord();
        }
      },
      selectAsset: function selectAsset(selection) {
        var model = this.modelFor(this.routeName);
        var position = model.position;
        var asset = model.position;
        model.asset = selection;
        model.geofence.name = selection.name;

        if (asset) {
          this.send('hideBreadcrumbs', [position.get('asset.id')]);
        } // if (!model.positionSpecified) {
        //     position.set('latitudeMilliarcSeconds', selection.get('positionLatest.latitudeMilliarcSeconds') || 3600000);
        //     position.set('longitudeMilliarcSeconds', selection.get('positionLatest.longitudeMilliarcSeconds') || 3600000);
        //     asset.set('manualPosLatitude', selection.get('position.latitudeMilliarcSeconds') || 3600000);
        //     asset.set('manualPosLongitude', selection.get('positionLatest.longitudeMilliarcSeconds') || 3600000);
        //     this.send('centerOnPosition', position);
        // }
        //model.asset = this.store.findRecord('asset', selection.id),


        model.asset.set('manualPosLatitude', model.position.latitudeMilliarcSeconds || 3600000);
        model.asset.set('manualPosLongitude', model.position.longitudeMilliarcSeconds || 3600000);
        position.set('asset', selection); //this.send('showBreadcrumbs', [selection.get('id')]);
      },
      // selectDate(date) {
      //     this.modelFor(this.routeName).position.set('utc', date);
      // },
      didTransition: function didTransition() {
        this.send('setGeofenceEditing', this.modelFor(this.routeName).geofence.id);
      },
      save: function save() {
        var _this = this;

        var model = this.modelFor(this.routeName);
        this.send('blockApp', true);
        model.position.destroyRecord();
        model.geofence.destroyRecord();
        model.asset.save().then(function () {
          _this.transitionTo('portal.manage.devices.edit', model.asset.get('id'));
        }).catch(function (e) {
          console.log(e);

          _this.notifications.error(_this.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});