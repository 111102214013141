define("sky-router-3/models/data-usage-summary", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Initial SBD Blocks Balance
     */
    blocksBalance: (0, _model.attr)('number'),

    /**
     * Total number of blocks used
     */
    blocksUsedTotal: (0, _model.attr)('number'),

    /**
     * Blocks remaining for the billing period
     */
    blocksRemaining: (0, _model.attr)('number')
  });

  _exports.default = _default;
});