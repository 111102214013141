define("sky-router-3/pods/portal/report/deviceinventory/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for running the device inventory report. It handles creating the
   * report parameter option which for this report only includes an array of displayed column names
   * for use in a drop down list
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.deviceInventory');
    },

    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.deviceinventory'],

    /**
     * The model for the device inventory reports route that contains an array of checkbox report column names.
     **/
    model: function model(params) {
      // Return the hashed model values
      return Ember.RSVP.hash({
        // Retrieves the list of column names and then checks any that were passed in the query params
        displayedColumns: this.setColumnsList(params),

        /**
         * The sort string that is built from the user's jqgrid sort actions
         * @type {String}
         */
        sortString: 'name'
      });
    },

    /**
     * Creates an array of column name checkbox list items for use in drop down lists
     * @function
     * @return {Array objects} Checkbox array list of column names
     */
    setColumnsList: function setColumnsList(params) {
      var checkBoxItems = [];
      var intl = this.get('intl');
      var columnNames = ['AssetName', 'AssetId', 'DeviceType', 'DeviceSerialNumber', 'DeviceIMEI', 'DevicePhoneNumber', 'ServicePlan', 'Status', 'DateActivated', 'LastReportDate'];
      var paramColArray = null;

      if (params.displayedColumns !== null && params.displayedColumns !== "") {
        paramColArray = params.displayedColumns.split(',');
      }

      for (var i = 0; i < columnNames.length; i++) {
        var item = {};
        item["value"] = columnNames[i];
        item["text"] = intl.t('report.deviceInventory.columns.' + columnNames[i]); // If query string includes displayed columns, use those to set the checked list

        if (params.displayedColumns !== null && params.displayedColumns !== "") {
          item["checked"] = paramColArray.includes(columnNames[i]) ? true : false;
        } // else set all checked flags to true
        else {
            item["checked"] = true;
          }

        checkBoxItems.push(item);
      }

      return checkBoxItems;
    }
  });

  _exports.default = _default;
});