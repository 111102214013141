define("sky-router-3/models/overlay-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SUPPORTED_CONTRACTS = void 0;

  /* eslint-env node */

  /**
   * This constant array contains all of the contracts that this applicaiton
   * supports at the moment.
   * @type {Array}
   */
  var SUPPORTED_CONTRACTS = ['kml-layer', 'image-tile', 'nasa-fire', 'noaa-gairmet', 'noaa-metar', 'noaa-airep', 'noaa-sigmet', 'noaa-sigmet-int', 'notam-tfrs', 'our-airports', 'our-navaids', 'usgs-volcano', 'user-contacts', 'marine-traffic', 'mgrs-grid', 'global-notams', 'user-events', 'sat-finder', 'gars'];
  _exports.SUPPORTED_CONTRACTS = SUPPORTED_CONTRACTS;

  /**
   * This model is responsible for defining what an overlay type can and cannot
   * do and what it can and cannot support. It is the basis for all information
   * about the capibilities of an overlay.
   *
   * Each overlay type is defined by a contract that is unique per type. the
   * contract is used by the client application to map client side implementation
   * to the server side implementation. This application does not support every
   * contract type. The contract type indicates if the overlay has settings,
   * supports opacity, and other settings.
   *
   * In this application the contract type also is used to find the ember component
   * that is responsible for displaying this overlay on the map.
   */
  var _default = _model.default.extend({
    /**
     * This a string that is unique to this overlay type that
     * can be used by clients to identify this type of overlay.
     * It is used to determine if the local application supports it or not.
     */
    contract: (0, _model.attr)('string'),

    /**
     * short description of the overlay type
     */
    description: (0, _model.attr)('string'),

    /**
     * Indicates if the overlay type has settings or not.
     * This is an implementation detail on the client side
     * so the value doesn't come from the server
     * @return {Boolean}
     */
    hasSettings: function () {
      switch (this.get('contract')) {
        case 'global-notams':
        case 'noaa-gairmet':
        case 'noaa-metar':
        case 'noaa-airep':
        case 'our-airports':
        case 'our-navaids':
        case 'usgs-volcano':
          return true;
      }

      return false;
    }.property('contract'),

    /**
     * Indicates if a component exists to show a context menu to the user on a map.
     * @return {Boolean}
     */
    hasContextMenu: Ember.computed('contract', function () {
      return Ember.getOwner(this).hasRegistration("component:".concat(this.componentForContextMenu));
    }),

    /**
     * Indicates if the overlay type supports opacity setting or not.
     * This is an implementation detail on the client side
     * so the value doesn't come from the server
     * @return {Boolean}
     */
    supportsOpacity: function () {
      switch (this.get('contract')) {
        case 'image-tile':
          return true;
      }

      return false;
    }.property('contract'),

    /**
     * Indicates if this application supports displaying this type of overlay.
     * Since the set of overlays that this map supports is probably smaller
     * than the total number of overlays.
     * This is an implementation detail on the client side
     * so the value doesn't come from the server
     * @return {Boolean}
     */
    canDisplay: function () {
      return SUPPORTED_CONTRACTS.indexOf(this.get('contract')) > -1;
    }.property('contract'),

    /**
     * This defines a group of overlays that can be ordered between eachother.
     * groups are defined by a title which is unique per-group. This is done
     * because not all overlay types can be cross ordered. For instance tile
     * layers and KML overlays cannot be ordered between eachother.
     *
     * A null value indicates that the overlay type cannot be ordered at all.
     * @return {String} String that describes the order group title.
     */
    orderGroup: function () {
      switch (this.get('contract')) {
        case 'image-tile':
          return "Tile";
      }

      return null;
    }.property('contract'),

    /**
     * Returns the path to the component that is used to render this overlay
     * type to the client.
     * @return {String}
     */
    component: function () {
      return 'portal/track/map/overlays/' + this.get('contract');
    }.property('contract'),

    /**
     * Returns the path to the component that is used to render the settings
     * window to the client if one is available.
     * NOTE: not all types have settings.
     * @return {String}
     */
    componentForSettings: function () {
      return "portal/track/map/overlays/".concat(this.get('contract'), "-settings");
    }.property('contract'),

    /**
     * Returns the path to the component that is used to render any context menu
     * to the client if one is available.
     * NOTE: not all types have settings.
     * @return {String}
     */
    componentForContextMenu: function () {
      return "portal/track/map/overlays/".concat(this.get('contract'), "-context");
    }.property('contract')
  });

  _exports.default = _default;
});