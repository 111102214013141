define("sky-router-3/pods/components/portal/operations/trips/time-est-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GuUptljb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[30,[36,7],[[35,1],[30,[36,6],[[35,3],-1],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"label label-success\"],[12],[1,[30,[36,0],[\"operations.timeAhead\"],[[\"minutes\"],[[30,[36,2],[[35,1]],null]]]]],[2,\" \"],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[35,1],[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"label label-warning\"],[12],[1,[30,[36,0],[\"operations.timeBehind\"],[[\"minutes\"],[[30,[36,2],[[35,1]],null]]]]],[2,\" \"],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"label label-info\"],[12],[1,[30,[36,0],[\"operations.onTime\"],null]],[2,\" \"],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"secondDiff\",\"display-duration\",\"onTimeDiff\",\"gt\",\"if\",\"mult\",\"lt\"]}",
    "moduleName": "sky-router-3/pods/components/portal/operations/trips/time-est-label/template.hbs"
  });

  _exports.default = _default;
});