define("sky-router-3/pods/portal/report/analytics/route", ["exports", "sky-router-3/mixins/permission-required-mixin"], function (_exports, _permissionRequiredMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is the route for viewing the analytics reports. It embeds the tableau
   * server reports into SkyRouter by retrieving the URL from the API that contains
   * an authenticated token for the calling user
   */
  var _default = Ember.Route.extend(_permissionRequiredMixin.default, {
    /**
     * Permissions required in order to access the route
     * This is part of the PermissionRequiredMixin
     * @type {Array}
     */
    permissions: ['report.analytics'],

    /**
     * The translation services
     */
    intl: Ember.inject.service(),

    /**
     * Inject the API so we can talk to the server
     * @return {sky-router-3/services/api}
     */
    api: Ember.inject.service(),

    /**
     * Sets the breadcrumb title for the page
     */
    titleToken: function titleToken() {
      return this.intl.t('nav.analytics');
    },

    /**
     * The model for the analytic reports route
     */
    model: function model() {
      // Return the hashed model values
      return this.get('api').getAnalyticsToken();
    },
    actions: {
      error: function error()
      /*error, transition*/
      {
        this.notifications.error(this.intl.t('misc.failedOperation'));
      }
    }
  });

  _exports.default = _default;
});