define("sky-router-3/pods/components/portal/track/map/overlays/noaa-gairmet/component", ["exports", "sky-router-3/mixins/google-object-mapping", "sky-router-3/utils/computed-json-object"], function (_exports, _googleObjectMapping, _computedJsonObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_googleObjectMapping.default, {
    api: Ember.inject.service(),

    /**
     * The map this overlay is associated with
     * @type {googe.maps.Map}
     */
    map: null,

    /**
     * The overlay model that this overlay type is responsible for rendering
     * @type {sky-router-3/models/overlay}
     */
    overlay: null,

    /**
     * The overlay settings object that holds generic information about overlay
     * settings
     * @type {sky-router-3/models/overlay-setting}
     */
    settings: null,

    /**
     * Array of data features that are present in the data object.
     * @type {Array}
     */
    features: null,

    /**
     * Google property mapping
     * @type {Array}
     */
    googlePropertyMap: [{
      property: 'map',
      setFunc: 'setMap'
    }, {
      property: 'style',
      setFunc: 'setStyle'
    }, {
      event: 'mouseover',
      eFunc: '_mouseover'
    }, {
      event: 'mouseout',
      eFunc: '_mouseout'
    }, {
      event: 'click',
      eFunc: '_click'
    }, {
      event: 'addfeature',
      eFunc: '_addFeature'
    }, {
      event: 'removefeature',
      eFunc: '_remFeature'
    }],

    /**
     * Holds the passed in data settings for the overlay
     * @type {Object}
     */
    dataObject: (0, _computedJsonObject.default)('overlay.dataString'),

    /**
     * Style function that is called for each record downloaded.
     */
    style: function style(feature) {
      var color;

      switch (feature.getProperty('hazard')) {
        case 'ICE':
          color = '#00FFFF';
          break;

        case 'TURB-HI':
          color = '#00FF00';
          break;

        case 'TURB-LO':
          color = '#008000';
          break;

        case 'IFR':
          color = '#500090';
          break;

        case 'MT_OBSC':
          color = '#A000A0';
          break;

        case 'SFC_WND':
          color = '#FF7900';
          break;

        case 'LLWS':
          color = '#B7410E';
          break;

        case 'M_FZLVL':
          color = '#00AAFF';
          break;

        case 'FZLVL':
          color = '#00AAFF';
          break;
      }

      return {
        strokeWeight: 3,
        strokeColor: color,
        fillColor: color,
        fillOpacity: 0.20
      };
    },
    labelFeatures: function () {
      var _this = this;

      return this.get('features').map(function (feature) {
        var _feature$getGeometry;

        var color;

        var latLng = _this._calculateGeometryCenter(feature);

        var label = feature.getProperty('hazard');

        switch (feature.getProperty('hazard')) {
          case 'ICE':
            color = '#00FFFF';
            break;

          case 'TURB-HI':
            color = '#00FF00';
            break;

          case 'TURB-LO':
            color = '#008000';
            break;

          case 'IFR':
            color = '#500090';
            break;

          case 'MT_OBSC':
            color = '#A000A0';
            break;

          case 'SFC_WND':
            color = '#FF7900';
            break;

          case 'LLWS':
            color = '#B7410E';
            break;

          case 'M_FZLVL':
            color = '#00AAFF';
            label += ":".concat(feature.getProperty('base'), "-").concat(feature.getProperty('top'));
            break;

          case 'FZLVL':
            color = '#00AAFF';
            latLng = (_feature$getGeometry = feature.getGeometry()) === null || _feature$getGeometry === void 0 ? void 0 : _feature$getGeometry.getAt(0);
            label += " @ ".concat(feature.getProperty('level'));
            break;
        }

        return {
          label: label,
          latLng: latLng,
          style: "background-color:".concat(color, ";color:white;border-radius:3px;padding:2px;").htmlSafe()
        };
      });
    }.property('features.[]'),

    /**
     * Opens the info window when the user mouses over the zone.
     */
    _mouseover: function _mouseover(dataObject, mouseEvent) {
      this.set('infoWindowOpen', true);
      this.set('infoWindowFeature', this._getWindowFeatureInfo(mouseEvent.feature));
      this.set('infoWindowCenter', mouseEvent.latLng);
      dataObject.overrideStyle(mouseEvent.feature, {
        strokeWeight: 6
      });
    },

    /**
     * Closes the info window.
     */
    _mouseout: function _mouseout(dataObject, mouseEvent) {
      this.set('infoWindowOpen', false);
      this.set('infoWindowFeature', null);
      dataObject.revertStyle(mouseEvent.feature);
    },

    /**
     * Launches the FAA link for the TFR.
     */
    _click: function _click(dataObject, mouseEvent) {
      var dueTo = mouseEvent.feature.getProperty('dueTo');

      if (dueTo) {
        this.set('popupWindowBody', dueTo);
        this.set('popupWindowOpen', true);
      }
    },

    /**
     * Keeps ember in sync with the google maps data object
     */
    _addFeature: function _addFeature(data, evt) {
      this.get('features').addObject(evt.feature);
    },
    _remFeature: function _remFeature(data, evt) {
      this.get('features').removeObject(evt.feature);
    },

    /**
     * Gets the center of the TFR geometry so the info window has somewhere to anchor to.
     */
    _calculateGeometryCenter: function _calculateGeometryCenter(feature) {
      var _feature$getGeometry2;

      var bounds = new google.maps.LatLngBounds();
      (_feature$getGeometry2 = feature.getGeometry()) === null || _feature$getGeometry2 === void 0 ? void 0 : _feature$getGeometry2.forEachLatLng(function (latLng) {
        bounds.extend(latLng);
      });
      return bounds.getCenter();
    },

    /**
     * Builds an object out of the information stored with the geoJSON for display
     * in the info window.
     */
    _getWindowFeatureInfo: function _getWindowFeatureInfo(feature) {
      return {
        level: feature.getProperty('level'),
        base: feature.getProperty('base'),
        baseFt: feature.getProperty('base') * 100,
        topFt: feature.getProperty('top') * 100,
        isFzl: feature.getProperty('base') === 'FZL',
        fzlbase: feature.getProperty('fzlbase'),
        fzltop: feature.getProperty('fzltop'),
        severity: feature.getProperty('severity'),
        validTime: new Date(feature.getProperty('validTime')),
        hazard: feature.getProperty('hazard'),
        product: feature.getProperty('product'),
        dueTo: feature.getProperty('dueTo')
      };
    },
    init: function init() {
      this.set('features', []);

      this._super.apply(this, arguments);
    },

    /**
     * Loads the layer from the server and schedules a realod event.
     */
    didInsertElement: function didInsertElement() {
      this.set('googlePropertyObject', new google.maps.Data());
      this.loadGeoJson();
      this.scheduleReload();

      this._super.apply(this, arguments);
    },

    /**
     * Loads the geoJSON from the server based on the
     */
    loadGeoJson: function () {
      var _this2 = this;

      Ember.run.join(function () {
        var url = _this2.get('dataObject.url');

        if (!url) {
          return;
        }

        var type = [];

        if (!_this2.get('settings.generic.hideIcing')) {
          type.push('icing');
        }

        if (!_this2.get('settings.generic.hideTurbulenceLow')) {
          type.push('turb-lo');
        }

        if (!_this2.get('settings.generic.hideIfr')) {
          type.push('ifr');
        }

        if (!_this2.get('settings.generic.hideMountain')) {
          type.push('mtn');
        }

        if (!_this2.get('settings.generic.hideTurbulenceHigh')) {
          type.push('turb-hi');
        }

        if (!_this2.get('settings.generic.hideFreezingLevel')) {
          type.push('fzlvl');
        }

        if (!_this2.get('settings.generic.hideLowLevelWindShear')) {
          type.push('llws');
        }

        if (!_this2.get('settings.generic.hideSurfaceWind')) {
          type.push('sfc-wind');
        }

        var opts = {
          data: {
            fore: _this2.get('settings.generic.forecast') || 0,
            type: type.join(',')
          },
          dataType: 'jsonp',
          jsonp: 'jsonp'
        };

        _this2.get('api').request(url, opts).then(function (x) {
          var obj = _this2.get('googlePropertyObject');

          if (obj) {
            obj.forEach(function (y) {
              return obj.remove(y);
            });
            obj.addGeoJson(x);
          }
        });
      });
    }.observes('settings.generic.forecast', 'settings.generic.hideIcing', 'settings.generic.hideTurbulenceLow', 'settings.generic.hideIfr', 'settings.generic.hideMountain', 'settings.generic.hideTurbulenceHigh', 'settings.generic.hideFreezingLevel', 'settings.generic.hideLowLevelWindShear', 'settings.generic.hideSurfaceWind'),

    /**
     * Recursive timer to reload the layer
     */
    scheduleReload: function scheduleReload() {
      var _this3 = this;

      this.scheduleReloadTimer = Ember.run.later(this, function () {
        _this3.loadGeoJson();

        _this3.scheduleReload();
      }, this.get('dataObject.refreshSeconds') * 1000);
    },

    /**
     * Kills the reload timer and clears the object from the map.
     */
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduleReloadTimer);
      var obj = this.get('googlePropertyObject');

      if (obj) {
        obj.setMap(null);
        obj = null;
      }
    },
    actions: {
      closePopup: function closePopup() {
        this.set('popupWindowOpen', false);
        this.set('popupWindowBody', null);
      }
    }
  });

  _exports.default = _default;
});