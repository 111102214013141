define("sky-router-3/pods/portal/manage/device-parameters/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    model: function model(params) {
      window.scrollTo(0, 0);
      return Ember.RSVP.hash({
        revision: this.store.queryRecord('asset-parameter-set-revision', {
          id: params.revision_id,
          includeSettings: true
        })
      });
    },
    afterModel: function afterModel(model) {
      var setId = model.revision.belongsTo('parameterSet').id();
      return Ember.RSVP.allSettled([this.store.findRecord('asset-parameter-set', setId).then(function (x) {
        return Ember.set(model, 'set', x);
      }), this.store.query('asset-parameter-set-revision', {
        setId: setId
      }).then(function (x) {
        return Ember.set(model, 'revisions', x);
      })]);
    },
    resetController: function resetController(controller) {
      controller.set('revisionComment', null);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('portal.manage.device-parameters');
      },
      revert: function revert(parameterSet, revisionId) {
        var _this = this;

        this.store.queryRecord('asset-parameter-set-revision', {
          id: revisionId,
          includeSettings: true
        }).then(function (rev) {
          return _this.send('save', parameterSet, rev, _this.intl.t('profiles.edit.revertedComment'));
        });
      },
      save: function save(parameterSet, currentRev, comment) {
        var _this2 = this;

        if (!confirm(this.intl.t('parameter.edit.saveConfirm'))) {
          return;
        } // Block the screen


        this.send('blockApp', true, {
          message: this.intl.t('misc.savingPlaceholder')
        }); // Create a new revision because revisions cannot be updated. Only created.

        var revision = this.store.createRecord('asset-parameter-set-revision', {
          parameterSet: parameterSet,
          comment: comment,
          settings: currentRev.get('settings').map(function (item) {
            return _this2.store.createRecord('asset-parameter-set-revision-setting', {
              key: item.get('key'),
              value: item.get('value'),
              revision: item.get('revision')
            });
          })
        }); // Save the revision and unload the new record so that it can be cleanly loaded next time.

        revision.save().then(function () {
          _this2.notifications.success(_this2.intl.t('parameter.edit.saveSuccess'));

          _this2.transitionTo('portal.manage.device-parameters');

          _this2.store.unloadRecord(revision);
        }).catch(function () {
          _this2.notifications.error(_this2.intl.t('misc.failedOperation'));
        }).finally(function () {
          _this2.send('blockApp', false);
        });
      }
    }
  });

  _exports.default = _default;
});