define("sky-router-3/pods/portal/manage/contacts/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    /**
     * Returns all the contacts for the user
     * @return {Ember.RSVP.Promise}
     */
    model: function model() {
      return Ember.RSVP.hash({
        contacts: this.store.findAll('contact'),
        groups: this.store.query('group', {
          activeOnly: false
        })
      });
    },
    actions: {
      /**
       * Deletes a contact
       * @param  {sky-router-3/models/contact} contact
       */
      delete: function _delete(contact) {
        if (confirm(this.intl.t('misc.areYouSureConfirm'))) {
          contact.destroyRecord();
        }
      }
    }
  });

  _exports.default = _default;
});