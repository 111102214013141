define("sky-router-3/pods/portal/manage/global-traveler/orgs/edit/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'model.org.name': (0, _emberCpValidations.validator)('presence', true),
    'model.org.iconDataUrl': (0, _emberCpValidations.validator)('file', {
      allowEmpty: true,
      inputId: 'orgphoto',
      sizeLimit: 10000000,
      allowTypes: /image\/*/
    })
  });

  var _default = Ember.Controller.extend(VALIDATIONS, {
    setFile: function setFile(org, nativeEvent) {
      if (nativeEvent.currentTarget.files) {
        // Based on this https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        var file = nativeEvent.currentTarget.files[0];
        var reader = new FileReader();
        reader.addEventListener('load', function () {
          org.iconDataUrl = reader.result;
        }, false);
        reader.readAsDataURL(file);
      } else {
        org.iconDataUrl = null;
      }
    },
    actions: {
      deleteImage: function deleteImage(org) {
        org.iconDataUrl = null;
      }
    }
  });

  _exports.default = _default;
});