define("sky-router-3/utils/geoconversiontools/ups-conversions", ["exports", "sky-router-3/utils/geoconversiontools/geoconstants", "sky-router-3/utils/geoconversiontools/polar-conversions"], function (_exports, _geoconstants, _polarConversions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var upsconversions = {
    getUPSDefaultValues: function getUPSDefaultValues() {
      var upsconstants = _geoconstants.default.getUPSConstants();

      return {
        UPS_a: 6378137.0,
        UPS_f: 1 / 298.257223563,
        UPS_False_Easting: 2000000,
        UPS_False_Northing: 2000000,
        UPS_Origin_Latitude: upsconstants.MAX_ORIGIN_LAT,
        UPS_Origin_Longitude: 0.0
      };
    },
    setUPSParameters: function setUPSParameters(a, f) {
      var upsparams = this.getUPSDefaultValues();
      var errors = [];

      if (a <= 0.0) {
        errors.push({
          code: 0x0020,
          desc: 'Semi-major axis less than or equal to zero'
        });
      }

      if (1 / f < 250 || 1 / f > 350) {
        errors.push({
          code: 0x0040,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (errors.length == 0) {
        upsparams.UPS_a = a;
        upsparams.UPS_f = f;
      }

      return {
        parameters: upsparams,
        errorCodes: errors
      };
    },
    convertGeodeticToUPS: function convertGeodeticToUPS(latitude, longitude, upsparams) {
      var upsconstants = _geoconstants.default.getUPSConstants();

      var upsresult = {
        hemisphere: '',
        easting: 0e0,
        northing: 0e0
      };
      var errors = [];

      if (latitude < -upsconstants.MAX_LAT || latitude > upsconstants.MAX_LAT) {
        errors.push({
          code: 0x0040,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (latitude < 0 && latitude > upsconstants.MIN_SOUTH_LAT) errors.push({
        code: 0x0040,
        desc: 'Inverse flattening outside of valid range (250 to 350)'
      });
      if (latitude >= 0 && latitude < upsconstants.MIN_NORTH_LAT) errors.push({
        code: 0x0040,
        desc: 'Inverse flattening outside of valid range (250 to 350)'
      });

      if (longitude < -upsconstants.PI || longitude > 2 * upsconstants.PI) {
        errors.push({
          code: 0x0040,
          desc: 'Inverse flattening outside of valid range (250 to 350)'
        });
      }

      if (errors.length == 0) {
        if (latitude < 0) {
          upsparams.UPS_Origin_Latitude = -upsconstants.MAX_ORIGIN_LAT;
          upsresult.hemisphere = 'S';
        } else {
          upsparams.UPS_Origin_Latitude = upsconstants.MAX_ORIGIN_LAT;
          upsresult.hemisphere = 'N';
        }

        var polarparamsresult = _polarConversions.default.setPolarParameters(upsparams.UPS_a, upsparams.UPS_f, upsparams.UPS_Origin_Latitude, upsparams.UPS_Origin_Longitude, upsparams.UPS_False_Easting, upsparams.UPS_False_Northing);

        if (polarparamsresult.errorCodes.length == 0) {
          var polarresult = _polarConversions.default.convertGeodeticToPolarStereographic(latitude, longitude, polarparamsresult);

          if (polarresult.errorCodes.length == 0) {
            upsresult.easting = polarresult.output.easting;
            upsresult.northing = polarresult.output.northing;
          } else {
            errors.push(polarresult.errorCodes);
          }
        } else {
          errors.push(polarparamsresult.errorCodes);
        }
      }

      return {
        output: upsresult,
        parameters: upsparams,
        errorCodes: errors
      };
    },
    converUPSToGedetic: function converUPSToGedetic(hemisphere, easting, northing, upsparams) {
      var upsconstants = _geoconstants.default.getUPSConstants();

      var upsresult = {
        latitude: 0e0,
        longitude: 0e0
      };
      var errors = [];

      if (hemisphere != 'N' && hemisphere != 'S') {
        errors.push({
          code: 0x0004,
          desc: 'Invalid hemisphere ("N" or "S")'
        });
      }

      if (easting < upsconstants.MIN_EAST_NORTH || easting > upsconstants.MAX_EAST_NORTH) {
        errors.push({
          code: 0x0008,
          desc: 'Easting outside of valid range, (0 to 4,000,000m)'
        });
      }

      if (northing < upsconstants.MIN_EAST_NORTH || northing > upsconstants.MAX_EAST_NORTH) {
        errors.push({
          code: 0x0010,
          desc: 'Northing outside of valid range, (0 to 4,000,000m)'
        });
      }

      if (hemisphere == 'N') {
        upsparams.UPS_Origin_Latitude = upsconstants.MAX_ORIGIN_LAT;
      }

      if (hemisphere == 'S') {
        upsparams.UPS_Origin_Latitude = -upsconstants.MAX_ORIGIN_LAT;
      }

      if (errors.length == 0) {
        var polarparamsresult = _polarConversions.default.setPolarParameters(upsparams.UPS_a, upsparams.UPS_f, upsparams.UPS_Origin_Latitude, upsparams.UPS_Origin_Longitude, upsparams.UPS_False_Easting, upsparams.UPS_False_Northing);

        if (polarparamsresult.errorCodes.length == 0) {
          var polarresult = _polarConversions.default.convertPolarStereographicToGeodetic(easting, northing, polarparamsresult);

          if (polarresult.errorCodes.length == 0) {
            if (polarresult.output.latitude < 0 && polarresult.output.latitude > upsconstants.MIN_SOUTH_LAT) {
              errors.push({
                code: 0x0001,
                desc: 'Latitude outside of valid range (North Pole: 83.5 to 90, South Pole: -79.5 to -90)'
              });
            }

            if (polarresult.output.latitude >= 0 && polarresult.output.latitude < upsconstants.MIN_NORTH_LAT) {
              errors.push({
                code: 0x0001,
                desc: 'Latitude outside of valid range (North Pole: 83.5 to 90, South Pole: -79.5 to -90)'
              });
            }

            if (errors.length == 0) {
              upsresult.latitude = polarresult.output.latitude;
              upsresult.longitude = polarresult.output.longitude;
            }
          } else {
            errors.push(polarresult.errorCodes);
          }
        } else {
          errors.push(polarparamsresult.errorCodes);
        }
      }

      return {
        output: upsresult,
        parameters: upsparams,
        errorCodes: errors
      };
    }
  };
  var _default = upsconversions;
  _exports.default = _default;
});