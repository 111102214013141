define("sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ogH1Mkbb",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"ul\"],[14,0,\"help-block\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.output.reportingRate.help\"],[[\"htmlSafe\"],[true]]]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[1,[30,[36,0],[\"compoundrules.output.reportingRate.help2\"],null]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"warn\"],[12],[1,[30,[36,0],[\"compoundrules.output.reportingRate.help3\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.timeReporting.reporting\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],null,[[\"options\",\"selected\",\"disabled\",\"onChange\"],[[35,9],[35,8],[35,2],[30,[36,7],[[32,0],\"timeReportingChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"parameter.timeReporting.reportingCustom\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"class\",\"val\",\"sync\",\"type\",\"disabled\"],[\"form-control\",[35,3,[\"value1\"]],true,\"number\",[35,2]]]]],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"errors\"],[[35,5,[\"attrs\",\"model\",\"value1\",\"messages\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"input-label\",\"disabled\",\"model\",\"input-debounced\",\"validations\",\"input-errors\",\"action\",\"timeReportingSelected\",\"timeReportingOptions\",\"input-select\",\"timeReportingCustomShown\",\"if\"]}",
    "moduleName": "sky-router-3/pods/components/portal/manage/device-compound-rules/outputs/output-type-1/template.hbs"
  });

  _exports.default = _default;
});