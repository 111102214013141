define("sky-router-3/utils/color-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.offsetTextColor = _exports.parseColor = void 0;

  /**
   * Takes a string color and converts it into an object
   * of {R, G, B}.
   *
   * Grabbed from here:
   * https://stackoverflow.com/questions/11068240/what-is-the-most-efficient-way-to-parse-a-css-color-in-javascript
   */
  var parseColor = function parseColor(colorString) {
    return {
      R: parseInt(colorString.substring(1, 3), 16),
      G: parseInt(colorString.substring(3, 5), 16),
      B: parseInt(colorString.substring(5, 7), 16)
    };
  };
  /**
   * The text color of the tag which should
   * be opposite of the background color.
   *
   * Based off this algo:
   * https://stackoverflow.com/questions/1855884/determine-font-color-based-on-background-color
   */


  _exports.parseColor = parseColor;

  var offsetTextColor = function offsetTextColor(backgroundColor) {
    var color = parseColor(backgroundColor);
    var a = 1 - (0.299 * color.R + 0.587 * color.G + 0.114 * color.B) / 255;
    return a < 0.5 ? '#000000' : '#FFFFFF';
  };

  _exports.offsetTextColor = offsetTextColor;
});