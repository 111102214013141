define("sky-router-3/pods/portal/manage/nortac/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        books: this.store.findAll('nortac-address-book'),
        lists: this.store.findAll('nortac-canned-list')
      });
    },
    actions: {
      delete: function _delete(item) {
        item.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});