define("sky-router-3/models/manual-position", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Main model that describes a device that communicates and
   * belongs to the system.
   */
  var model = _model.default.extend({
    latitudeMilliarcSeconds: (0, _model.attr)('number'),
    longitudeMilliarcSeconds: (0, _model.attr)('number')
  });

  var _default = model;
  _exports.default = _default;
});