define("sky-router-3/pods/components/portal/track/map/geo-area-circle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8ApOd0p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[6,[37,11],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"show\",\"map\",\"event\",\"closeMenu\",\"addLocation\"],[[35,9],[35,1],[35,8],[30,[36,7],[[32,0],\"closeContextMenu\"],null],[30,[36,7],[[32,0],[32,0,[\"addLocation\"]]],null]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[14,0,\"divider\"],[12],[13],[2,\"\\n            \"],[10,\"li\"],[14,0,\"dropdown-header\"],[12],[1,[35,4,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[30,[36,13],[[35,12],[35,0,[\"name\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"position\",\"map\"],[[35,2],[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"geofence-name-label\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"geoArea\",\"map\",\"center\",\"portal/track/map/static-label\",\"geofence\",\"isEditing\",\"unless\",\"action\",\"contextMenuEvent\",\"contextMenuShow\",\"portal/track/map/map-context-menu\",\"if\",\"isNameLabelVisible\",\"and\"]}",
    "moduleName": "sky-router-3/pods/components/portal/track/map/geo-area-circle/template.hbs"
  });

  _exports.default = _default;
});