define("sky-router-3/models/asset-compound-rule-output-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),

    /**
     * Validation for value1 if it exists
     */
    value1DefaultValue: (0, _model.attr)(),
    value1ValidationRegex: (0, _model.attr)('string'),
    value1ValidationMinNumber: (0, _model.attr)('number'),
    value1ValidationMaxNumber: (0, _model.attr)('number'),

    /**
     * Validation for value2 if it exists
     */
    value2DefaultValue: (0, _model.attr)(),
    value2ValidationRegex: (0, _model.attr)('string'),
    value2ValidationMinNumber: (0, _model.attr)('number'),
    value2ValidationMaxNumber: (0, _model.attr)('number')
  });

  _exports.default = _default;
});