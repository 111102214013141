define("sky-router-3/pods/components/permission-role-tree/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onChange: function onChange() {},
    disabled: false,

    /**
     * Holds array of all available permission roles
     * @type {Object}
     */
    permissionRoles: null,

    /**
     * Holds array of currently selected permission roles
     * @type {Object}
     */
    permissionRolesSelected: null,

    /**
     * Indicates if clicking a child also checks the parent
     * @type {Boolean}
     */
    cascadeCheckUp: true,

    /**
     * @example
     * [{
     *     id: "ALLOW_Communicate",
     *     label: "Communicate",
     *     checked: true,
     *     children: [{
     *         id: "ALLOW_Manage_SendDeviceMessage",
     *         label: "Send Device Message",
     *         checked: true,
     *         children: []
     *     }, {
     *         id: "ALLOW_Manage_SendEmail",
     *         label: "Send Email",
     *         checked: false,
     *         children: []
     *     }]
     * }]
     * @return {[type]} [description]
     */
    tree: function () {
      var _this = this;

      var assignable = this.get('permissionRoles');
      var current = this.get('permissionRolesSelected');
      var tree = [];
      assignable.forEach(function (role) {
        _this.convertTree(assignable, current, tree, role, 1);
      });
      return tree;
    }.property('permissionRoles.[]', 'permissionRolesSelected.[]'),

    /**
     * Converts a the flat assignable array to a tree
     * @param  {Array} currentRoles
     * @param  {Array} tree         The tree to update
     * @param  {Object} role        The array being updated
     * @param  {Number} depth       The depth that the conversion is currently dealing with
     */
    convertTree: function convertTree(availableRoles, currentRoles, tree, role, depth) {
      var idParts = role.Id.split('_');
      var id = idParts.slice(0, depth + 1).join('_');
      var node = tree.findBy('id', id);

      if (!node) {
        node = {
          id: id,
          label: role.Label.split('_').slice(depth)[0],
          checked: currentRoles.isAny('Id', id),
          checkable: availableRoles.isAny('Id', id),
          children: []
        };
        tree.addObject(node);
      }

      if (idParts.get('length') > depth + 1) {
        this.convertTree(availableRoles, currentRoles, node.children, role, depth + 1);
      }
    },

    /**
     * Collapses the tree into an array containing only checked options
     * @param  {Object} tree        Tree that is output from permissionTree
     * @return {Array<object>}      Array of permission roles in the form o
     *                              { id: {string}, label: {label} }
     */
    collapseTree: function collapseTree(tree) {
      var _this2 = this;

      var array = [];
      var availableRoles = this.get('permissionRoles');
      var cascade = this.get('cascadeCheckUp');
      tree.forEach(function (node) {
        if (node.checked && node.checkable) {
          array.addObject(availableRoles.findBy('Id', node.id));
        }

        if (node.children && (node.checked || !cascade)) {
          array.addObjects(_this2.collapseTree(node.children));
        }
      });
      return array;
    },
    actions: {
      treeChanged: function treeChanged() {
        var selectedRoles = this.collapseTree(this.get('tree'));
        this.onChange(selectedRoles);
      }
    }
  });

  _exports.default = _default;
});