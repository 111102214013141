define("sky-router-3/pods/components/portal/manage/device-parameters/controls/stop-movement/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'intervalSecondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.intervalSecondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.intervalSecondsSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),

    /**
     * Passed in settings
     * @type {[type]}
     */
    enabledSetting: null,
    reportingTypeSetting: null,
    intervalSecondsSetting: null,
    showRepeatFeature: true,
    disabled: false,

    /**
     * Controls the interval block
     */
    intervalSelected: function () {
      var options = this.get('intervalOptions');
      var option = options.findBy('value', this.get('intervalSecondsSetting.value'));

      if (!option) {
        option = options.findBy('value', '-1');
      }

      return option;
    }.property('intervalSecondsSetting.value'),
    intervalCustomShown: Ember.computed.equal('intervalSelected.value', '-1'),
    intervalOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '15',
        label: intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '45',
        label: intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '300',
        label: intl.t('parameter.misc.countMinutes', {
          count: 5
        })
      }, {
        value: '600',
        label: intl.t('parameter.misc.countMinutes', {
          count: 10
        })
      }, {
        value: '900',
        label: intl.t('parameter.misc.countMinutes', {
          count: 15
        })
      }, {
        value: '1800',
        label: intl.t('parameter.misc.countMinutes', {
          count: 30
        })
      }, {
        value: '2700',
        label: intl.t('parameter.misc.countMinutes', {
          count: 45
        })
      }, {
        value: '3600',
        label: intl.t('parameter.misc.countHour')
      }, {
        key: '1440',
        label: intl.t('parameter.misc.countHours', {
          count: 4
        })
      }, {
        key: '28800',
        label: intl.t('parameter.misc.countHours', {
          count: 8
        })
      }, {
        value: '-1',
        label: intl.t('parameter.misc.custom')
      }];
    }.property(),
    actions: {
      intervalChange: function intervalChange(selected) {
        if (selected.value === '-1') {
          this.set('intervalSecondsSetting.value', '');
        } else {
          this.set('intervalSecondsSetting.value', selected.value);
        }
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('reportingTypeSetting').rollbackAttributes();
          this.get('intervalSecondsSetting').rollbackAttributes();
        }
      },
      typeChanged: function typeChanged(enabled) {
        if (!enabled) {
          this.get('intervalSecondsSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});