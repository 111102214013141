define("sky-router-3/router", ["exports", "sky-router-3/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // Regular authentication protected resources
    this.route('portal', {
      path: '/'
    }, function () {
      this.route('track', function () {
        this.route('geofence', {
          path: '/geofence/:geofence_id'
        });
        this.route('location', {
          path: '/location/:location_id'
        });
        this.route('layout');
        this.route('message');
        this.route('altitude');
        this.route('manual');
        this.route('info');
        this.route('emergency', {
          path: '/emergency/:asset_id'
        });
        this.route('user-event', {
          path: '/user-event/:user_event_id'
        });
        this.route('eta', {
          path: '/eta/:eta_id'
        });
        this.route('trip', {
          path: '/trip/:trip_id'
        });
      });
      this.route('communicate', function () {
        this.route('folder', {
          path: ':folder_id'
        }, function () {
          this.route('message', {
            path: ':message_id'
          });
        });
        this.route('compose', function () {
          this.route('draft', {
            path: '/draft/:message_id'
          });
        });
        this.route('whitelist');
      });
      this.route('manage', function () {
        this.route('devices', function () {
          this.route('edit', {
            path: ':asset_id'
          });
        });
        this.route('location', function () {
          this.route('type', function () {
            this.route('new');
          });
          this.route('edit', {
            path: ':location_id'
          });
          this.route('edit-multiple', {
            path: '/multiple/:location_ids_csv'
          });
          this.route('import');
        });
        this.route('groups', function () {
          this.route('edit', {
            path: ':group_id'
          });
        });
        this.route('users', function () {
          this.route('edit', {
            path: ':user_id'
          });
          this.route('edit-multiple', {
            path: '/multiple/:user_ids_csv'
          });
        });
        this.route('alerts', function () {
          this.route('log');
          this.route('edit', {
            path: '/edit/:alert_id'
          });
        });
        this.route('data-feeds');
        this.route('burst-services', function () {
          this.route('broadcast-coverage-area', function () {});
          this.route('geo-area-group', function () {
            this.route('new');
          });
          this.route('geo-area-edit', {
            path: '/geo-area-group/edit/:geoAreaGroup_id'
          });
          this.route('messenger', function () {
            this.route('index');
            this.route('history');
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('edit', {
              path: 'edit/:burst_id'
            });
            this.route('new');
          });
        });
        this.route('device-parameters', function () {
          this.route('assignment', function () {
            this.route('edit', {
              path: '/:asset_id'
            });
          });
          this.route('edit', {
            path: '/:revision_id'
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('rename', {
              path: 'rename/:set_id'
            });
            this.route('new');
          });
        });
        this.route('device-profiles', function () {
          this.route('assignment', function () {
            this.route('edit', {
              path: '/:asset_id'
            });
          });
          this.route('edit', {
            path: '/:revision_id'
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('rename', {
              path: '/rename/:set_id'
            });
            this.route('new');
          });
        });
        this.route('device-compound-rules', function () {
          this.route('assignment', function () {
            this.route('add', {
              path: '/:asset_id'
            });
          });
          this.route('edit', {
            path: '/:revision_id'
          });
          this.route('index', {
            path: '/'
          }, function () {
            this.route('rename', {
              path: 'rename/:rule_id'
            });
          });
        });
        this.route('events');
        this.route('personnel', function () {
          this.route('edit', {
            path: '/:personnel_id'
          });
        });
        this.route('contacts', function () {
          this.route('edit', {
            path: ':contact_id'
          });
        });
        this.route('nortac', function () {
          this.route('address-book', {
            path: '/address-book/:book_id'
          });
          this.route('canned-list', {
            path: '/canned-list/:list_id'
          });
        });
        this.route('device-icons', function () {
          this.route('edit', {
            path: ':icon_id'
          });
        });
        this.route('overlays', function () {
          this.route('edit', {
            path: ':overlay_id'
          });
        });
        this.route('geofences', function () {
          this.route('assignment-by-geofence', function () {
            this.route('add', {
              path: '/:geofence_id'
            });
          });
          this.route('assignment-by-device', function () {
            this.route('add', {
              path: '/:asset_id'
            });
          });
          this.route('edit', {
            path: '/:geofence_id'
          }, function () {
            this.route('points');
            this.route('avr');
            this.route('3d');
            this.route('speed');
          });
        });
        this.route('identification-cards', function () {
          this.route('edit', {
            path: '/:card_id'
          });
        });
        this.route('compound-alerts', function () {
          this.route('edit', {
            path: '/edit/:revision_id'
          });
        });
        this.route('global-traveler', function () {
          this.route('assets', function () {
            this.route('edit', {
              path: '/:asset_id'
            });
            this.route('new');
          });
          this.route('areas', function () {
            this.route('edit', {
              path: '/:area_id'
            });
          });
          this.route('contacts', function () {
            this.route('edit', {
              path: '/:contact_id'
            });
          });
          this.route('orgs', function () {
            this.route('edit', {
              path: '/:org_id'
            });
          });
        });
        this.route('trips');
      });
      this.route('settings', function () {
        this.route('security');
        this.route('contact');
        this.route('map');
        this.route('units');
        this.route('misc');
        this.route('notifications');
      });
      this.route('report', function () {
        this.route('positionreporthistory');
        this.route('groupassociations');
        this.route('business-continuity');
        this.route('deviceinventory');
        this.route('playback');
        this.route('invoiceusage');
        this.route('invoiceplanusage');
        this.route('devicehistory');
        this.route('interaction-log');
        this.route('compound-alert-log');
        this.route('invoices'); //this.route('analytics');

        this.route('submitted-forms');
        this.route('report-generator', function () {
          this.route('history');
          this.route('report', {
            path: '/:report_name/:report_id'
          });
          this.route('setup');
        });
      });
      this.route('forms', function () {
        this.route('view', {
          path: ':form_id'
        });
        this.route('templates', function () {
          this.route('edit', {
            path: ':template_id'
          });
          this.route('preview', {
            path: ':template_id/preview'
          });
        });
      });
      this.route('operations', function () {
        this.route('trips', function () {
          this.route('plan', {
            path: '/plan/:plan_id'
          });
        });
        this.route('flightplans', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('status', {
              path: '/:plan_id/status'
            });
          });
          this.route('edit', {
            path: '/:plan_id'
          });
        });
        this.route('routes', function () {
          this.route('edit', {
            path: '/:route_id'
          });
        });
        this.route('contracts', function () {
          this.route('edit', {
            path: '/:contract_id'
          });
        });
        this.route('incident-report', function () {});
        this.route('global-traveler', function () {
          this.route('new');
        });
        this.route('burst-services', function () {
          this.route('messenger');
        });
      });
    }); // Playback report route that lives outside portal so that navigation and
    // signaR don't operate.

    this.route('playback', function () {
      this.route('view', {
        path: '/:asset_ids/:after/:before'
      });
    }); // Public resources that do not require login

    this.route('public', function () {
      this.route('login', function () {
        this.route('reset');
        this.route('forgot');
        this.route('unlock', {
          path: '/unlock/:user_id'
        });
        this.route('two-factor', {
          path: '/two-factor/:user_id'
        });
        this.route('activate');
      });
      this.route('alert');
    }); // Account settings - used for changing the password

    this.route('account', function () {
      this.route('password');
    }); // Default 404 redirect

    this.route('error/not-found', {
      path: '/*path'
    });
    this.route('controller');
  });
  var _default = Router;
  _exports.default = _default;
});