define("sky-router-3/pods/components/portal/track/event-list/event-item/component", ["exports", "moment", "sky-router-3/models/asset-position-type"], function (_exports, _moment, _assetPositionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['event-item', 'inline-list', 'pointer'],
    classNameBindings: ['isNew:new', 'eventClassType'],
    clicked: function clicked() {},
    eventClassType: function () {
      var position = this.get('model');

      if (position) {
        return "position-type-".concat(position.belongsTo('type').id());
      }
    }.property('model.type'),

    /**
     * The users sessionAccount.
     * @return {sky-router-3/services/sessionAccount}
     */
    sessionAccount: Ember.inject.service(),

    /**
     * The event that this component models.
     * @type {sky-router-3/models/asset-position}
     */
    model: null,

    /**
     * Contains the instance of the cancelable isNew timer which can be used
     * to stop the timer in the case that the event item is being destroyed.
     * The timer must be destroyed if this element is destroyed or an exception
     * will occur.
     * @type {Boolean}
     */
    isNewTimer: null,

    /**
     * Indicates if the event is new or not. If it is new it returns true and
     * sets a timer to remove its isNew status after the 'eventNotificationTimeout'.
     * If the users eventNotificationTimeout is negative it means they need to
     * manually ack the event. In that case it defaults to 20 seconds timeout.
     * @return {Boolean} True if the event is new or not.
     */
    isNew: function () {
      var timeoutSeconds = this.get('sessionAccount.user.eventNotificationTimeout');
      var positionEvent = this.get('model');

      if (!positionEvent.get('isHistorical')) {
        var loadedDate = positionEvent.get('loadedDate');
        timeoutSeconds = timeoutSeconds > 0 ? timeoutSeconds : 20;
        var timeoutDate = (0, _moment.default)().subtract(timeoutSeconds, 's');

        if (loadedDate > timeoutDate) {
          var timer = Ember.run.later(this, this.set, 'isNew', false, timeoutSeconds * 1000);
          this.set('isNewTimer', timer);
          return true;
        }
      }

      return false;
    }.property('model.loadedDate'),

    /**
     * Allows for setting of the style attribute for the ICON.
     * @return {String} String representing style attributes.
     */
    iconStyle: function () {
      var typeId = this.get('model').belongsTo('type').id();
      var str = '';

      if (_assetPositionType.POSITION_TYPES.isNormalType(typeId)) {
        var degree = this.get('model.headingDegrees');
        str = "transform: rotate(".concat(degree, "deg);");
      }

      return str.htmlSafe();
    }.property('model.headingDegrees'),

    /**
     * Destroys the isNew timer when the element is removed from the UI.
     * This way the exception 'set on a destroyed object' doesn't occur.
     */
    willDestroyElement: function willDestroyElement() {
      var timer = this.get('isNewTimer');

      if (timer) {
        Ember.run.cancel(timer);
      }
    },

    /**
     * Fired when the event is clicked.
     */
    click: function click() {
      this.clicked(this.get('model.id'));
    }
  });

  _exports.default = _default;
});