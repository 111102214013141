define("sky-router-3/pods/portal/operations/trips/index/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    signalr: Ember.inject.service(),
    queryParams: {
      skip: {
        refreshModel: true
      },
      take: {
        refreshModel: true
      },
      sortProperty: {
        refreshModel: true
      },
      sortDesc: {
        refreshModel: true
      },
      oldestDate: {
        refreshModel: true
      },
      soonestDate: {
        refreshModel: true
      },
      dateRangeHours: {
        refreshModel: true
      },
      filterAssetIds: {
        refreshModel: true
      },
      completed: {
        refreshModel: true
      },
      future: {
        refreshModel: true
      },
      automatic: {
        refreshModel: true
      }
    },
    activate: function activate() {
      this.signalr.on('onNewTripEntry', this, this._notification_trip_updated);
    },
    deactivate: function deactivate() {
      this.signalr.off('onNewTripEntry', this, this._notification_trip_updated);
    },
    model: function model(params) {
      var dateRange = this._get_date_range(params);

      return Ember.RSVP.hash({
        trips: this.store.query('trip', {
          skip: params.skip,
          take: params.take,
          sort: params.sortProperty,
          sortDesc: params.sortDesc,
          oldestDate: dateRange.oldest,
          newestDate: dateRange.newest,
          assets: params.filterAssetIds,
          completed: params.completed,
          future: params.future,
          automatic: params.automatic
        }),
        assets: this.store.findAll('asset'),
        oldestDate: params.oldestDate,
        soonestDate: params.soonestDate
      });
    },
    _notification_trip_updated: function _notification_trip_updated(_ref) {
      var wasPresent = _ref.wasPresent;

      if (!wasPresent) {
        this.refresh();
      }
    },
    _get_date_range: function _get_date_range(params) {
      switch (params.dateRangeHours) {
        case -1:
          // Custom
          return {
            oldest: (0, _moment.default)(params.oldestDate || new Date()).toISOString(),
            newest: (0, _moment.default)(params.soonestDate || new Date()).toISOString()
          };

        case 0:
          // All Time
          return {};

        default:
          // Hour window
          return {
            oldest: (0, _moment.default)().subtract(params.dateRangeHours, 'h').toISOString(),
            newest: (0, _moment.default)().add(params.dateRangeHours, 'h').toISOString()
          };
      }
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});