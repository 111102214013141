define("sky-router-3/models/compound-alert-revision-rule", ["exports", "@ember-data/model", "ember-cp-validations"], function (_exports, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OPERATORS = void 0;
  var OPERATORS = [{
    id: 'gt',
    label: 'Greater Than'
  }, {
    id: 'lt',
    label: 'Less Than'
  }, {
    id: 'gte',
    label: 'Greater or Equal to'
  }, {
    id: 'lte',
    label: 'Less Than or Equal to'
  }, {
    id: 'eq',
    label: 'Equals'
  }, {
    id: 'new',
    label: 'Does Not Equal'
  }];
  _exports.OPERATORS = OPERATORS;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    type: (0, _emberCpValidations.validator)('presence', true),
    operator: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.type.valueRequired')
    }),
    value: [// Enabled if value is required and we have a regex to validate against
    (0, _emberCpValidations.validator)('inline', {
      validate: function validate(value, options, model
      /*, attribute*/
      ) {
        var regex = model.get('type.valueValidationRegex');
        return (value || '').match(regex) ? true : "Does not match ".concat(regex);
      },
      disabled: Ember.computed('model.type.{valueRequired,valueValidationRegex}', function () {
        return !this.model.get('type.valueRequired') || !this.model.get('type.valueValidationRegex');
      }),
      dependentKeys: ['type.valueRequired', 'model.type.valueValidationRegex']
    }), // Enabled if value is required and we have ranges to validate
    (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: Ember.computed.alias('model.type.valueValidationMin'),
      lte: Ember.computed.alias('model.type.valueValidationMax'),
      disabled: Ember.computed('model.type.{valueRequired,valueValidationMin,valueValidationMax}', function () {
        return !this.model.get('type.valueRequired') || this.model.get('type.valueValidationMin') === null && this.model.get('type.valueValidationMax') === null;
      }),
      dependentKeys: ['type.valueRequired', 'model.type.valueValidationMin', 'model.type.valueValidationMax']
    })]
  });

  var _default = _model.default.extend(VALIDATIONS, {
    /**
     * The type of this rule. 
     * This governs everything from how the input should be shown to the user to what fields are required
     * It also holds the linked identifier if this is a reference type
     */
    type: (0, _model.belongsTo)('compound-alert-rule-type'),

    /**
     * The operator to apply  between this property and the next one.
     * @values OR|AND
     * @type {string}
     */
    logic: (0, _model.attr)('string', {
      defaultValue: 'OR'
    }),

    /**
     * Logic operator to apply to the value
     * @values lte|gte|gt|lt|eq|con|dnc|neq
     * @type {string}
     */
    operator: (0, _model.attr)('string', {
      defaultValue: 'eq'
    }),

    /*
     * Field that holds value that may be used to against the logic operator.
     * This field may be empty if a type is simply an '='.
     * Its up to the type to determine if this field is useful.
     */
    value: (0, _model.attr)('string'),

    /**
     * This is a database backed POJO that holds basic properties for rendering the rule input.
     * AKA 'unitsSelected'
     */
    properties: (0, _model.attr)({
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    //#region LOCAL PROPERTIES / ATTRIBUTES
    isSelected: false,
    unitsSelected: Ember.computed('properties.{unitId,unitType}', 'store', {
      get: function get() {
        if (this.properties.unitType && this.properties.unitId) {
          return this.store.findRecord(this.properties.unitType, this.properties.unitId);
        }

        return undefined;
      },
      set: function set(key, value) {
        this.set('properties.unitType', value.constructor.modelName);
        this.set('properties.unitId', value.get('id'));
        return value;
      }
    })
  });

  _exports.default = _default;
});