define("sky-router-3/pods/components/portal/settings/security/ip-restrictions/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // validates 255.255.255.255/32 format addresses
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'user.ipWhitelistCSV': (0, _emberCpValidations.validator)('format', {
      regex: /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2])),?)+$/,
      allowBlank: true
    }),
    'user.ipBlackListCSV': (0, _emberCpValidations.validator)('format', {
      regex: /^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2])),?)+$/,
      allowBlank: true
    })
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    /**
     * The translation service
     */
    intl: Ember.inject.service(),

    /**
     * The user that this component is editing
     * @type {sky-router-3/models/user}
     */
    user: null,
    actions: {
      setTokens: function setTokens(key, args) {
        this.set(key, args.currentTokens.mapBy('value').join());
      }
    }
  });

  _exports.default = _default;
});