define("sky-router-3/models/group", ["exports", "@ember-data/model", "sky-router-3/mixins/rollback-relationships"], function (_exports, _model, _rollbackRelationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * The group model contains information that links users to different bits of
   * information that they are allowed to see. Groups can have expirations and other
   * associated properties that govern what users can see what.
   */
  var model = _model.default.extend(_rollbackRelationships.default, {
    /**
     * The name of the group
     */
    name: (0, _model.attr)('string'),

    /**
     * Assets assigned to this group
     * @type {sky-router-3/models/asset}
     */
    assets: (0, _model.hasMany)('asset', {
      async: true
    }),

    /**
     * User defined notes for the asset
     * @type {String}
     */
    notes: (0, _model.attr)('string'),

    /**
     * Users that belong to the group
     * @type {sky-router-3/models/user}
     */
    users: (0, _model.hasMany)('user'),

    /**
     * Alerts that belong to the gorup
     * @type {sky-router-3/models/alert-definition}
     */
    alertDefinitions: (0, _model.hasMany)('alert-definition'),

    /**
     * Personnel that belong to this group
     * @type {sky-router-3/models/personnel}
     */
    personnel: (0, _model.hasMany)('personnel'),

    /**
     * Form templates that are available to users and personnel in this group
     * @type {sky-router-3/models/form-template}
     */
    formTemplates: (0, _model.hasMany)('form-template'),

    /**
     * Contacts that are associated with this group
     * @type {sky-router-3/models/contact}
     */
    contacts: (0, _model.hasMany)('contact'),

    /**
     * What date does this group stop being valid
     */
    endDateUtc: (0, _model.attr)('date'),

    /**
     * What date does this group start being valid
     */
    startDateUtc: (0, _model.attr)('date'),

    /**
     * When was the group created.
     */
    createdDateUtc: (0, _model.attr)('date'),

    /**
     * Indicates if the group alive and enforced on the users that belong to it.
     * @return {Boolean}
     */
    isActive: function () {
      var date = new Date();
      return this.get('startDateUtc') < date && (!this.get('endDateUtc') || this.get('endDateUtc') > date);
    }.property('startDateUtc', 'endDateUtc')
  });

  var _default = model;
  _exports.default = _default;
});