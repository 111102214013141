define("sky-router-3/models/device-history", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This model describes the device history status types that exist in the database.
   */
  var model = _model.default.extend({
    /**
     * Asset associated with this entry
     */
    asset: (0, _model.belongsTo)('asset'),

    /**
     * The date the entry was generated
     */
    entryDate: (0, _model.attr)('date'),

    /**
     * The type of entry this is, 'parameter', 'profile', etc..
     */
    entryType: (0, _model.belongsTo)('device-history-entry-type'),

    /**
     * The type of status this is. 'Sent', 'queued', etc..
     */
    statusType: (0, _model.belongsTo)('device-history-status-type'),

    /**
     * The type of the source this is. 'Iridum', 'User', etc..
     */
    sourceType: (0, _model.belongsTo)('device-history-source-type'),

    /**
     * The user that created the entry. This is not always populated.
     */
    originatingUser: (0, _model.belongsTo)('user'),

    /**
     * Any associated text. Not always present
     */
    text: (0, _model.attr)('string')
  });

  var _default = model;
  _exports.default = _default;
});