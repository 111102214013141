define("sky-router-3/pods/components/portal/settings/event-notification-timeout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The options available for event notification timeout
     * @type {Array}
     */
    eventNotificationOption: [5, 10, 20, -1]
  });

  _exports.default = _default;
});