define("sky-router-3/pods/components/portal/manage/device-profiles/nal-canned-messages/component", ["exports", "sky-router-3/utils/computed-find-by", "sky-router-3/utils/computed-promise"], function (_exports, _computedFindBy, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    /**
     * The passed in settings object
     * @type {Array<sky-router-3/models/asset-profile-set-revision-setting>}
     */
    settings: null,

    /**
     * Is this form disabled from the outside
     * @type {Boolean}
     */
    disabled: false,

    /**
     * Holds a mapping of all the settings that have to be created
     * @type {Array}
     */
    cannedMessages: [{
      key: 'nal_cannedmsg_1'
    }, {
      key: 'nal_cannedmsg_2'
    }, {
      key: 'nal_cannedmsg_3'
    }, {
      key: 'nal_cannedmsg_4'
    }, {
      key: 'nal_cannedmsg_5'
    }, {
      key: 'nal_cannedmsg_6'
    }, {
      key: 'nal_cannedmsg_7'
    }, {
      key: 'nal_cannedmsg_8'
    }, {
      key: 'nal_cannedmsg_9'
    }, {
      key: 'nal_cannedmsg_10'
    }, {
      key: 'nal_cannedmsg_11'
    }, {
      key: 'nal_cannedmsg_12'
    }, {
      key: 'nal_cannedmsg_13'
    }, {
      key: 'nal_cannedmsg_14'
    }, {
      key: 'nal_cannedmsg_15'
    }, {
      key: 'nal_cannedmsg_16'
    }, {
      key: 'nal_cannedmsg_17'
    }, {
      key: 'nal_cannedmsg_18'
    }, {
      key: 'nal_cannedmsg_19'
    }, {
      key: 'nal_cannedmsg_20'
    }],

    /**
     * Initialize the component and create the computed properties and validations
     * for all the phone number setting blocks.
     */
    init: function init() {
      var _this = this;

      this.get('cannedMessages').forEach(function (prop) {
        Ember.defineProperty(_this, "".concat(prop.key, "_command"), (0, _computedFindBy.default)('settings', 'key', "".concat(prop.key, "_command")));
        Ember.defineProperty(_this, "".concat(prop.key, "_label"), (0, _computedFindBy.default)('settings', 'key', "".concat(prop.key, "_label")));
        Ember.defineProperty(_this, "".concat(prop.key, "_text"), (0, _computedFindBy.default)('settings', 'key', "".concat(prop.key, "_text")));
        Ember.defineProperty(_this, "".concat(prop.key, "_enabled"), (0, _computedFindBy.default)('settings', 'key', "".concat(prop.key, "_enabled")));
      });

      this._super.apply(this, arguments);
    },
    positionTypes: (0, _computedPromise.default)(function () {
      return this.store.findAll('asset-position-type');
    }, null),
    actions: {
      setCommand: function setCommand(message, selected) {
        this.set("".concat(message.key, "_command.value"), selected.id);
        this.set("".concat(message.key, "_label.value"), selected.name);
      }
    }
  });

  _exports.default = _default;
});