define("sky-router-3/pods/portal/communicate/compose/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    /**
     * parameters that are tied to the query string
     * @type {Array}
     */
    queryParams: ['to', 'cc', 'bcc', 'subject', 'body'],

    /**
     * To address field that is bound to the query parameters. This is a list
     * of email addresses seperated by the ';' or ',' character.
     * @type {String}
     */
    to: [],

    /**
     * The cc address field that is bound to the query parameters.
     * @type {String}
     */
    cc: [],

    /**
     * The cc address field that is bound to the query parameters. T
     * @type {String}
     */
    bcc: [],

    /**
     * The subject field of the message.
     * @type {String}
     */
    subject: null,

    /**
     * The body field of the message.
     * @type {String}
     */
    body: null,

    /**
     * The message being submitted. 
     * This is only assigned when the message is about to be sent.
     * It is set on the controller so that the template can get access to the
     * `isSaving` property of the model.
     * @type {sky-router-3/models/message}
     */
    message: null
  });

  _exports.default = _default;
});