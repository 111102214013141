define("sky-router-3/transforms/array", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Serializes and deserializes plain arrays for ember-data.
   *
   * @example indexArray: DS.attr('array'),
   * @see https://stackoverflow.com/questions/12168570/how-to-represent-arrays-within-ember-data-models
   */
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return Ember.makeArray(serialized);
    },
    serialize: function serialize(deserialized) {
      switch (Ember.typeOf(deserialized)) {
        case 'array':
          return deserialized;

        case 'string':
          return deserialized.split(',');

        default:
          return [];
      }
    }
  });

  _exports.default = _default;
});