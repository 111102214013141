define("sky-router-3/pods/components/display-dop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LU6bdZXS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[15,0,[31,[[34,1]]]],[12],[13],[2,\"\\n\"],[1,[34,2]],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[1,[34,0]],[2,\" DOP\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"dop\",\"icon\",\"text\",\"attach-tooltip\"]}",
    "moduleName": "sky-router-3/pods/components/display-dop/template.hbs"
  });

  _exports.default = _default;
});