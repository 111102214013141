define("sky-router-3/models/burst-service-message", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Service Name message will be sent to
     */
    serviceName: (0, _model.attr)('string'),

    /**
     * Message Abstract
     */
    abstract: (0, _model.attr)('string'),

    /**
     * Message Body (base64 encoded text)
     */
    message: (0, _model.attr)('string'),

    /**
     * Optional bca lists (code should be passed here)
     */
    bcas: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    }),

    /**
     * GeoAreas list (optinal) - list of lat/lon/diameter that will be sent in the message
     */
    geoAreas: (0, _model.attr)('array', {
      defaultValue: function defaultValue() {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});