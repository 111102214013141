define("sky-router-3/pods/public/login/reset/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.intl.t('login.resetPassword');
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        controller.reset();
      }
    }
  });

  _exports.default = _default;
});