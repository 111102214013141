define("sky-router-3/models/trip-plan-type", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.types = void 0;
  var types = {
    air: '0',
    land: '1',
    marine: '2',
    other: '3'
  };
  _exports.types = types;

  var _default = _model.default.extend({
    /**
     * The name of the status
     */
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string')
  });

  _exports.default = _default;
});