define("sky-router-3/pods/components/portal/manage/device-parameters/controls/speeding-reporting/component", ["exports", "ember-cp-validations", "sky-router-3/utils/computed-promise"], function (_exports, _emberCpValidations, _computedPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALIDATIONS = (0, _emberCpValidations.buildValidations)({
    'intervalSecondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.intervalSecondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.intervalSecondsSetting.validationMinNumber')
    })],
    'transitionSecondsSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.transitionSecondsSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.transitionSecondsSetting.validationMinNumber')
    })],
    'speedLimitKphSetting.value': [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lte: Ember.computed.alias('model.speedLimitKphSetting.validationMaxNumber'),
      gte: Ember.computed.alias('model.speedLimitKphSetting.validationMinNumber')
    })]
  });

  var _default = Ember.Component.extend(VALIDATIONS, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),

    /**
     * Passed in settings
     * @type {[type]}
     */
    intervalSecondsSetting: null,
    transitionSecondsSetting: null,
    speedLimitKphSetting: null,
    showTransitionField: true,
    disabled: false,

    /**
     * Controls the interval block
     */
    intervalSelected: function () {
      var options = this.get('intervalOptions');
      var option = options.findBy('value', this.get('intervalSecondsSetting.value'));

      if (!option) {
        option = options.findBy('value', '-1');
      }

      return option;
    }.property('intervalSecondsSetting.value'),
    intervalCustomShown: Ember.computed.equal('intervalSelected.value', '-1'),
    intervalOptions: function () {
      var intl = this.get('intl');
      return [{
        value: '15',
        label: intl.t('parameter.misc.countSeconds', {
          count: 15
        })
      }, {
        value: '30',
        label: intl.t('parameter.misc.countSeconds', {
          count: 30
        })
      }, {
        value: '45',
        label: intl.t('parameter.misc.countSeconds', {
          count: 45
        })
      }, {
        value: '60',
        label: intl.t('parameter.misc.countMinute')
      }, {
        value: '120',
        label: intl.t('parameter.misc.countMinutes', {
          count: 2
        })
      }, {
        value: '-1',
        label: intl.t('parameter.misc.custom')
      }];
    }.property(),
    speedMinCm: Ember.computed('speedLimitKphSetting.validationMinNumber', 'speedLimitKphSetting.value', function () {
      return this.speedLimitKphSetting.validationMinNumber / 0.036;
    }),
    speedMaxCm: Ember.computed('speedLimitKphSetting.validationMaxNumber', 'speedLimitKphSetting.value', function () {
      return this.speedLimitKphSetting.validationMaxNumber / 0.036;
    }),
    speedCm: Ember.computed({
      get: function get() {
        return this.speedLimitKphSetting.value / 0.036;
      },
      set: function set(key, value) {
        this.set('speedLimitKphSetting.value', Math.floor(value * 0.036));
        return value;
      }
    }),
    speedUnits: (0, _computedPromise.default)(function () {
      return this.store.findAll('unit-speed');
    }, []),
    speedUnitSelected: Ember.computed('sessionAccount.user', 'speedUnits.[]', function () {
      return this.sessionAccount.user.get('speedLand');
    }),
    actions: {
      intervalChange: function intervalChange(selected) {
        if (selected.value === '-1') {
          this.set('intervalSecondsSetting.value', '');
        } else {
          this.set('intervalSecondsSetting.value', selected.value);
        }
      },
      enabledChanged: function enabledChanged(enabled) {
        if (!enabled) {
          this.get('intervalSecondsSetting').rollbackAttributes();
          this.get('transitionSecondsSetting').rollbackAttributes();
          this.get('speedLimitKphSetting').rollbackAttributes();
        }
      }
    }
  });

  _exports.default = _default;
});