define("sky-router-3/models/burst-service", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    /**
     * Service Name
     */
    serviceName: (0, _model.attr)('string'),

    /**
     * Servie Code (this is the name register with Iridium SPNet)
     */
    code: (0, _model.attr)('string'),

    /**
     * Description
     */
    serviceDescription: (0, _model.attr)('string'),

    /**
     * IsDeleted
     */
    isDeleted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),

    /**
     * Creation Date
     */
    createdDate: (0, _model.attr)('date'),

    /**
     * Update Date
     */
    updatedDate: (0, _model.attr)('date')
  });

  _exports.default = _default;
});