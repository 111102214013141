define("sky-router-3/pods/components/input-token-autocomplete/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['emberTagInputTemplate'],
    removeTag: function removeTag() {},
    onChange: function onChange() {},
    addTag: function addTag() {},
    selectOption: function selectOption() {},

    /**
     * Passed in array of selectable options
     * @type {[type]}
     */
    options: null,

    /**
     * Array of strings that represent selected elements
     * @type {Array<string>}
     */
    tags: [],
    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        this.send('submitInput');
      }
    },
    focusIn: function focusIn() {
      this.set('focus', true);
    },
    focusOut: function focusOut() {
      var _this = this;

      Ember.run.later(this, function () {
        if (!_this.isDestorying && !_this.isDestroyed) {
          _this.set('focus', false);
        }
      }, 500);
    },
    actions: {
      add: function add() {
        return false;
      },
      removeAtIndex: function removeAtIndex(index) {
        this.removeTag(index);
      },
      keyUp: function keyUp(value) {
        this.set('focus', true);
        this.onChange(value);
      },
      submitInput: function submitInput() {
        var element = this.element.querySelector('input');
        this.addTag(element.value);
        element.value = '';
      },
      select: function select(result) {
        this.selectOption(result);
        this.element.querySelector('input').value = '';
      }
    }
  });

  _exports.default = _default;
});