define("sky-router-3/components/col-pick-input", ["exports", "ember-colpick/mixins/col-pick"], function (_exports, _colPick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend(_colPick.default, {
    flat: false
  });

  _exports.default = _default;
});